import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantItemProps } from "./RequestParticipantItem.types";
import {
  getStyles,
  IRequestParticipantItemStyleProps,
  IRequestParticipantItemStyles,
} from "./RequestParticipantItem.styles";
import { RequestParticipantItemComponent } from "./RequestParticipantItem.base";

/**
 * RequestParticipantItem description
 */
export const RequestParticipantItem = styled<
  IRequestParticipantItemProps,
  IRequestParticipantItemStyleProps,
  IRequestParticipantItemStyles
>(RequestParticipantItemComponent, getStyles);
