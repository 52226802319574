import * as React from "react";
import { UnauthenticatedLayout } from "src/controls/UnauthenticatedLayout";
import { LoginControl } from "../LoginControl";
import { ILoginPageProps } from "./LoginPage.types";

export class LoginPage extends React.Component<ILoginPageProps> {
  render() {
    return (
      <UnauthenticatedLayout {...this.props}>
        <LoginControl
          {...this.props}
          onEnterpriseChanged={this.props.onEnterpriseChanged}
          onLanguageChanged={
            this.props.onLanguageChanged === undefined
              ? (window as any).onLanguageChanged
              : this.props.onLanguageChanged
          }
        />
      </UnauthenticatedLayout>
    );
  }
}
