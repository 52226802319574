import {
  ContextualMenuItemType,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ResponsiveMode,
  Spinner,
  Stack,
  TextField,
} from "@fluentui/react";
import { SeverityLevel } from "@microsoft/applicationinsights-web";
import * as React from "react";
import { Link, Redirect } from "react-router-dom";
import Housekeeper from "src/services/Housekeeper";
import { TelemetryService } from "src/services/TelemetryService";
import { CredentialsSchema } from "../../../models/ICredentials";
import { ISessionProfile } from "../../../models/ISessionProfile";
import AuthService from "../../../services/AuthService";
import i18n from "../../../services/i18n";
import { validate } from "../../../utils/validator";
import { getLoginControlClassNames } from "./LoginControl.styles";
import { ILoginControlProps, ILoginControlState } from "./LoginControl.types";

export class LoginControlComponent extends React.Component<
  ILoginControlProps,
  ILoginControlState
> {
  constructor(props: ILoginControlProps) {
    super(props);
    this.state = {
      credentials: {
        username: (localStorage.getItem("lastUsername") ?? "")
          .toLowerCase()
          .trim(),
        password: "",
      },
      message: "",
      errors: [],
      progress: false,
      redirect: false,
      on500: false,
      error500: null,
    };
    this.onUsernameChange = this.onUsernameChange.bind(this);
    this.onPasswordChange = this.onPasswordChange.bind(this);
  }

  onUsernameChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    const { credentials } = this.state;
    credentials.username = newValue ?? "";
    this.setState({ credentials: credentials });
  }

  onPasswordChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    const { credentials } = this.state;
    credentials.password = newValue ?? "";
    this.setState({ credentials: credentials });
  }

  componentDidMount() {
    const ctrl = document.getElementsByName("name");
    if (ctrl.length > 0) ctrl[0].focus();
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getLoginControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return !this.state.redirect ? (
      <div className={classNames.root}>
        <Dialog
          hidden={false}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: i18n.t("login:title"),
            subText: i18n.t("login:instructions"),
            showCloseButton: false,
            responsiveMode: ResponsiveMode.large,
            titleProps: {
              className: classNames.title,
            },
            topButtonsProps: [
              {
                className: "LocaleLanguage",
                iconProps: {
                  iconName: "LocaleLanguage",
                  className: "LocaleLanguage",
                },
                menuIconProps: {
                  style: { display: "none" },
                  iconName: undefined,
                },
                menuProps: {
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "english",
                      text: i18n.t("language:en"),
                      "data-automation-id": "nvx:login:language:en",
                      style: {
                        fontWeight:
                          i18n.getLanguage() === "en" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("en"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: "french",
                      text: i18n.t("language:fr"),
                      "data-automation-id": "nvx:login:language:fr",
                      style: {
                        fontWeight:
                          i18n.getLanguage() === "fr" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("fr"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    } /*,
                    {
                      key: "spanish",
                      text: i18n.t('language:es'),
                      style: {
                        fontWeight:
                          this.props.language === "es" ? "bold" : "normal"
                      },
                      onClick: () => this.props.onLanguageChanged("es")
                    } */,
                  ],
                },
              },
            ],
          }}
          modalProps={{
            titleAriaId: "1",
            subtitleAriaId: "3",
            isBlocking: true,
            containerClassName: classNames.root,
            styles: { main: { minWidth: 270 } },
          }}
        >
          {this.state.progress ? (
            <Stack
              horizontalAlign={"center"}
              verticalAlign={"center"}
              verticalFill={true}
              className={classNames.spinner}
            >
              <Spinner
                label={i18n.t("global:pleasewait")}
                ariaLive="assertive"
                labelPosition="right"
              />
            </Stack>
          ) : (
            <Stack>
              {this.state.message.length === 0 ? (
                <div style={{ height: "32px" }}></div>
              ) : (
                <MessageBar
                  data-automation-id="nvx:login:message"
                  messageBarType={MessageBarType.error}
                >
                  {this.state.message}
                </MessageBar>
              )}
              <div data-automation-id="nvx:login:username:container">
                <TextField
                  autoFocus={this.state.credentials.username === ""}
                  data-automation-id="nvx:login:username"
                  type="text"
                  label={i18n.t("login:username")}
                  name="username"
                  iconProps={{ iconName: "UserOptional" }}
                  errorMessage={this.state.errors.username}
                  value={this.state.credentials.username}
                  onChange={this.onUsernameChange}
                />
              </div>
              <div data-automation-id="nvx:login:password:container">
                <TextField
                  data-automation-id="nvx:login:password"
                  autoFocus={this.state.credentials.username !== ""}
                  type="password"
                  label={i18n.t("login:password")}
                  name="password"
                  iconProps={{ iconName: "PasswordField" }}
                  errorMessage={this.state.errors.password}
                  value={this.state.credentials.password}
                  onChange={this.onPasswordChange}
                  onKeyPress={(e) => e.key === "Enter" && this.login__(e)}
                />
              </div>
            </Stack>
          )}
          <DialogFooter>
            <div>
              <div className={classNames.leftActions} style={{ float: "left" }}>
                <div style={{ lineHeight: "14px", fontSize: "14px" }}>
                  {i18n.t("login:forgotinscructions")}
                </div>
                <div style={{ lineHeight: "14px", fontSize: "14px" }}>
                  {i18n.t("login:linkprefix")}
                  <Link
                    data-automation-id={
                      this.props.mobile
                        ? "nvx:login:forgotpassword"
                        : "nvx:login:forgotpassword:mobile"
                    }
                    to={{
                      pathname: "/oublie-mot-de-passe",
                      state: { username: this.state.credentials.username },
                    }}
                  >
                    {i18n.t("login:linkhere")}
                  </Link>{" "}
                  {i18n.t("login:linksuffix")}
                </div>
              </div>
              <div style={{ float: "right" }}>
                <PrimaryButton
                  data-automation-id="logincommand"
                  onClick={this.login}
                  disabled={this.state.progress ? true : undefined}
                  text={i18n.t("login:logincommand")}
                />
              </div>
            </div>
          </DialogFooter>
          <DialogFooter
            className={classNames.bottomActions}
            styles={{
              actions: {
                height: "83px",
              },
            }}
          >
            <div style={{ lineHeight: "14px", fontSize: "14px" }}>
              {i18n.t("login:forgotinscructions")}
            </div>
            <div style={{ lineHeight: "14px", fontSize: "14px" }}>
              {i18n.t("login:linkprefix")}
              <Link
                data-automation-id={
                  this.props.mobile
                    ? "nvx:login:forgotpassword:mobile"
                    : "nvx:login:forgotpassword"
                }
                to={{
                  pathname: "/oublie-mot-de-passe",
                  state: { username: this.state.credentials.username },
                }}
              >
                {i18n.t("login:linkhere")}
              </Link>{" "}
              {i18n.t("login:linksuffix")}
            </div>
          </DialogFooter>
        </Dialog>
      </div>
    ) : this.state.on500 ? (
      <Redirect
        to={{
          pathname: "/erreur/500",
          state: this.state.error500,
        }}
      />
    ) : (
      <Redirect to={this.getRedirectUrl()} />
    );
  }

  private getRedirectUrl = () => {
    if (window.location.search === null || window.location.search.length === 0)
      return "/";
    const urlSearchParams = new URLSearchParams(window.location.search);
    const returnUrl = urlSearchParams.get("returnUrl");
    return returnUrl ?? "/";
  };

  private login__ = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    e.preventDefault();
    this.executeLogin();
  };

  private login = (e: React.MouseEvent<HTMLInputElement, MouseEvent>): void => {
    e.preventDefault();
    this.executeLogin();
  };

  private executeLogin = (): void => {
    const errors: any = validate(CredentialsSchema, this.state.credentials);
    if (Object.keys(errors).length === 0) {
      const _this = this;
      AuthService.login(this.state.credentials)
        .then((x) => {
          if (x.status === 200 && x.data) {
            localStorage.removeItem("resetId");
            localStorage.removeItem("resetSasToken");
            // TODO: keep company if in array if on new token
            localStorage.removeItem("enterprise");
            const s: ISessionProfile = x.data as any;
            if (s.redirectTo !== undefined) {
              this.props.appInsights?.trackException &&
                this.props.appInsights.trackException({
                  id: "9014",
                  exception: new Error(
                    `Acccunt ${this.state.credentials.username} is not an audience for this platform. User has been redirected.`
                  ),
                  severityLevel: SeverityLevel.Warning,
                });

              localStorage.clear();
              // TODO: nice to have : wrap nice redirection spinner page.
              document.location.href = s.redirectTo;
            } else if (s.locked || !s.token) {
              this.props.appInsights?.trackException &&
                this.props.appInsights.trackException({
                  id: "9015",
                  exception: new Error(
                    `Account ${this.state.credentials.username} is locked.`
                  ),
                  severityLevel: SeverityLevel.Warning,
                });
              this.setState({
                errors: [],
                message: i18n.t("login:error:locked"),
                progress: false,
              });
            } else {
              localStorage.setItem("userInfo", JSON.stringify(s));
              localStorage.setItem(
                "lastUsername",
                s.username.toLowerCase().trim()
              );
              if (s != null) {
                i18n.setLanguage(s.lang);
              }
              Housekeeper.init();
              if (s && s != null) {
                if (s.enterprises && s.enterprises.length !== 0) {
                  _this.props.onEnterpriseChanged(s.enterprises[0], true);
                }
                _this.props.onLanguageChanged(s.lang);
              }
              TelemetryService.trackLogin(this.props.appInsights, s);
              _this.setState({ redirect: true, progress: false });
            }
          } else {
            this.props.appInsights?.trackException &&
              this.props.appInsights.trackException({
                id: "9010",
                exception: new Error("error"),
                severityLevel: SeverityLevel.Critical,
              });
            this.setState({
              errors: [],
              message: i18n.t("login:error"),
              progress: false,
            });
          }
        })
        .catch((x) => {
          this.props.appInsights?.trackException &&
            this.props.appInsights.trackException({
              id: "9010",
              exception: new Error(x.response.data),
              severityLevel: SeverityLevel.Critical,
            });
          if (x.response.status > 400 && x.response.status < 499) {
            this.setState({
              errors: [],
              message: i18n.t("login:error"),
              progress: false,
            });
          } else {
            this.setState({
              errors: [],
              redirect: true,
              on500: true,
              error500: x.response.data,
              message: i18n.t("login:error"),
              progress: false,
            });
          }
        });
      _this.setState({
        redirect: false,
        errors: [],
        message: "",
        progress: true,
      });
    } else {
      this.setState({
        message: "",
        errors: errors,
        progress: false,
      });
    }
  };
}
