import {
  FontSizes,
  FontWeights,
  IBasePickerStyleProps,
  IBasePickerStyles,
  IButtonStyles,
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";
import {
  IGridViewStyleProps,
  IGridViewStyles,
} from "../GridView/GridView.styles";

export interface IUsersPickerDialogSubComponentStyles {
  commandButton?: IButtonStyles;
  iconButton?: IButtonStyles;
  picker?: IStyleFunctionOrObject<IBasePickerStyleProps, IBasePickerStyles>;
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
}

export interface IUsersPickerDialogStyles
  extends IComponentBaseStyles<IUsersPickerDialogSubComponentStyles> {
  container?: IStyle;
  header?: IStyle;
  body?: IStyle;
}

export interface IUsersPickerDialogStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getUsersPickerDialogClassNames = (
  styles?: IStyleFunctionOrObject<
    IUsersPickerDialogStyleProps,
    IUsersPickerDialogStyles
  >,
  props?: IUsersPickerDialogStyleProps
): [
  IProcessedStyleSet<IUsersPickerDialogStyles>,
  IUsersPickerDialogSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUsersPickerDialogStyleProps,
  IUsersPickerDialogStyles
> = (props: IUsersPickerDialogStyleProps): IUsersPickerDialogStyles => {
  return {
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    header: [
      // tslint:disable-next-line:deprecation
      props.theme.fonts.xLargePlus,
      {
        flex: "1 1 auto",
        borderTop: `4px solid ${props.theme.palette.themePrimary}`,
        color: props.theme.palette.neutralPrimary,
        display: "flex",
        fontSize: FontSizes.xLarge,
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
      },
    ],
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: {
          margin: "14px 0",
        },
        "p:first-child": {
          marginTop: 0,
        },
        "p:last-child": {
          marginBottom: 0,
        },
      },
    },
    subComponentStyles: {
      commandButton: {
        root: {
          width: "100px",
          color: props.theme.palette.neutralPrimary,
          marginLeft: "auto",
          marginTop: "10px",
          marginRight: "2px",
        },
        flexContainer: {
          padding: "6px",
        },
        rootHovered: {
          color: props.theme.palette.neutralDark,
        },
      },
      iconButton: {
        root: {
          width: "42px",
          color: props.theme.palette.neutralPrimary,
          marginLeft: "auto",
          marginTop: "4px",
          marginRight: "2px",
        },
        rootHovered: {
          color: props.theme.palette.neutralDark,
        },
      },
      picker: {
        input: {
          padding: "3px",
        },
      },
    },
  };
};
