import * as React from "react";
import { getPaymentDetailInfoClassNames } from "./PaymentDetailInfo.styles";
import {
  IPaymentDetailInfoProps,
  IPaymentDetailInfoState,
} from "./PaymentDetailInfo.types";
import { LabelField } from "src/components/LabelField";
import { Link, Text, Icon } from "@fluentui/react";
import i18n from "src/services/i18n";

export class PaymentDetailInfoComponent extends React.Component<
  IPaymentDetailInfoProps,
  IPaymentDetailInfoState
> {
  render(): JSX.Element {
    const { styles, mobile, paymentDetails } = this.props;
    const [classNames, subComponentStyles] = getPaymentDetailInfoClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { enterprise } = paymentDetails;
    const { fax, phone, name, address } = enterprise;
    const { street, city, state, postalcode } = address;

    return (
      <div className={classNames.root}>
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("billing:payments:newbillnumber:label")}
          content={paymentDetails.name}
        />
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("billing:payments:employer")}
          content={name}
        />
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("enterprise:addresses:address")}
          iconProps={{ iconName: "MapPin" }}
          content={
            <div>
              <div>{street}</div>
              <div>
                {city} ({state})
              </div>
              <div>{postalcode}</div>
            </div>
          }
        />
        <div>
          <div style={{ marginBottom: mobile ? "0" : "6px" }}>
            <div
              style={{
                float: "left",
                width: "32px",
                paddingRight: "5px",
              }}
            >
              <Icon iconName="Phone" />
            </div>
            <div style={{ float: "left", width: "90px" }}>
              <Text>{i18n.t("enterprise:info:phone")}</Text>
            </div>
            <div style={{ float: "left" }}>
              <Link href={i18n.PhoneLink(phone)} className="ms-Link">
                {phone}
              </Link>
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
        <div>
          <div style={{ marginBottom: "6px" }}>
            <div
              style={{
                float: "left",
                width: "32px",
                paddingRight: "5px",
              }}
            >
              <Icon iconName="Fax" />
            </div>
            <div style={{ float: "left", width: "90px" }}>
              <Text>{i18n.t("enterprise:info:fax")}</Text>
            </div>
            <div style={{ float: "left" }}>
              <Text>{fax}</Text>
            </div>
            <div className="clear-both"></div>
          </div>
        </div>
      </div>
    );
  }
}
