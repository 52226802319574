import {
  FontWeights,
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { IAlert } from "src/models/Alerts/IAlert";

export interface IAlertCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  newAlertIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  createdOnLabel?: any /* IStylesFunction<
    ITextProps,
    ITextTokens,
    IComponentStyles<ITextSlots>
  >; */;
  seenOnLabel?: any /* IStylesFunction<
    ITextProps,
    ITextTokens,
    IComponentStyles<ITextSlots>
  >; */;
}

export interface IAlertCardStyles
  extends IAmigoBaseStyles<IAlertCardSubComponentStyles> {}

export interface IAlertCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  item?: IAlert;
}

export const getAlertCardClassNames = (
  styles?: IStyleFunctionOrObject<IAlertCardStyleProps, IAlertCardStyles>,
  props?: IAlertCardStyleProps
): [
  IProcessedStyleSet<IAlertCardStyles>,
  IAlertCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<IAlertCardStyleProps, IAlertCardStyles> =
  (props: IAlertCardStyleProps): IAlertCardStyles => {
    return {
      subComponentStyles: {
        gridViewCard: {
          root: {
            height: "140px",
          },
        },
        topIcon: props.tokens?.defaultIconStyles,
        newAlertIcon: props.tokens?.defaultIconStyles,
        createdOnLabel:
          (props.item?.seenOn.length ?? -1) === 0
            ? () => {
                return {
                  root: {
                    color: "#333333",
                    fontSize: "14px",
                    fontWeight: 600,
                    lineHeight: "20px",
                  },
                };
              }
            : props.tokens?.defaultCardTextStyle,
        seenOnLabel: () => {
          return {
            root: {
              color: "#333333",
              fontSize: "14px",
              fontWeight: FontWeights.regular,
              lineHeight: "20px",
            },
          };
        },
      },
    };
  };
