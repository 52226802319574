import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IContactModificationPanelSubComponentStyles {}

export interface IContactModificationPanelStyles
  extends IAmigoBaseStyles<IContactModificationPanelSubComponentStyles> {}

export interface IContactModificationPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getContactModificationPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IContactModificationPanelStyleProps,
    IContactModificationPanelStyles
  >,
  props?: IContactModificationPanelStyleProps
): [
  IProcessedStyleSet<IContactModificationPanelStyles>,
  IContactModificationPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IContactModificationPanelStyleProps,
  IContactModificationPanelStyles
> = (
  _props: IContactModificationPanelStyleProps
): IContactModificationPanelStyles => {
  return {};
};
