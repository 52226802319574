import {
  CommandBarButton,
  IconButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import * as React from "react";
import { MessageEditor } from "../Controls/MessageEditor";
import { getAssistedMessageEditionContainerClassNames } from "./AssistedMessageEditionContainer.styles";
import {
  IAssistedMessageEditionContainerProps,
  IAssistedMessageEditionContainerState,
} from "./AssistedMessageEditionContainer.types";

export class AssistedMessageEditionContainerComponent extends React.Component<
  IAssistedMessageEditionContainerProps,
  IAssistedMessageEditionContainerState
> {
  constructor(props: IAssistedMessageEditionContainerProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, onmessage, onTabChanged, children } = this.props;
    const [classNames] = getAssistedMessageEditionContainerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>

        <Stack
          horizontal
          grow
          tokens={{ childrenGap: 10 }}
          styles={{
            root: {
              borderRadius: "4px",
              width: "100%",
              padding: "5px",
              border: "1px solid lightgray",
            },
          }}
        >
          <StackItem align={"start"} grow>
            <CommandBarButton
              disabled={this.props.errorMessage !== undefined}
              iconProps={{ iconName: "CannedChat" }}
              styles={{ root: { height: "34px" } }}
              text="Message"
              onClick={() => {
                onTabChanged(!onmessage);
              }}
            />
          </StackItem>
          <StackItem shrink align={"end"}>
            <IconButton
              disabled={this.props.errorMessage !== undefined}
              iconProps={{
                iconName: "Drilldown",
                styles:
                  onmessage || this.props.errorMessage
                    ? { root: { transform: "rotate(180deg)" } }
                    : undefined,
              }}
              onClick={() => {
                onTabChanged(!onmessage);
              }}
            />
          </StackItem>
        </Stack>
        {(onmessage || this.props.errorMessage) && (
          <MessageEditor
            {...this.props}
            required={true}
            styles={undefined}
            label={undefined}
          />
        )}
        {children}
      </div>
    );
  }
}
