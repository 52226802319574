import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportOfferPageProps } from "./TransportOfferPage.types";
import {
  getStyles,
  ITransportOfferPageStyleProps,
  ITransportOfferPageStyles,
} from "./TransportOfferPage.styles";
import { TransportOfferPageComponent } from "./TransportOfferPage.base";

/**
 * TransportOfferPage
 */
export const TransportOfferPage = styled<
  ITransportOfferPageProps,
  ITransportOfferPageStyleProps,
  ITransportOfferPageStyles
>(TransportOfferPageComponent, getStyles);
