import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantsSectionProps } from "./RequestParticipantsSection.types";
import {
  getStyles,
  IRequestParticipantsSectionStyleProps,
  IRequestParticipantsSectionStyles,
} from "./RequestParticipantsSection.styles";
import { RequestParticipantsSectionComponent } from "./RequestParticipantsSection.base";

/**
 * RequestParticipantsSection description
 */
export const RequestParticipantsSection = styled<
  IRequestParticipantsSectionProps,
  IRequestParticipantsSectionStyleProps,
  IRequestParticipantsSectionStyles
>(RequestParticipantsSectionComponent, getStyles);
