import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationControlProps } from "./DossierImmigrationControl.types";
import {
  getStyles,
  IDossierImmigrationControlStyleProps,
  IDossierImmigrationControlStyles,
} from "./DossierImmigrationControl.styles";
import { DossierImmigrationControlComponent } from "./DossierImmigrationControl.base";

/**
 * ImmigrationControl description
 */
export const DossierImmigrationControl = styled<
  IDossierImmigrationControlProps,
  IDossierImmigrationControlStyleProps,
  IDossierImmigrationControlStyles
>(DossierImmigrationControlComponent, getStyles);
