import { Stack } from "@fluentui/react";
import * as React from "react";
import { Redirect } from "react-router-dom";
import { objectId } from "src/models/IObjectReference";
import { IFlightCriteria } from "src/models/Transports/IFlightCriteria";
import { TransportFlightBookingService } from "src/services/Transports/TransportFlightBookingService";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { CustomSpinner } from "../../../components/CustomSpinner";
import i18n from "../../../services/i18n";
import { getTransportOfferPageClassNames } from "./TransportOfferPage.styles";
import {
  ITransportOfferPageProps,
  ITransportOfferPageState,
} from "./TransportOfferPage.types";

export class TransportOfferPageComponent extends React.Component<
  ITransportOfferPageProps,
  ITransportOfferPageState
> {
  constructor(props: ITransportOfferPageProps) {
    super(props);
    this.state = {
      searching: true,
    };

    const idStr = this.props.route.match.params.id;
    const id = idStr === undefined ? 0 : parseInt(idStr);
    const urlSearchParams = new URLSearchParams(window.location.search);
    const dateStr = urlSearchParams.get("date");
    const arrivalStr = urlSearchParams.get("arrival");
    const passengersStr = urlSearchParams.get("passagers");
    const passengers = passengersStr == null ? 1 : parseInt(passengersStr);
    const date = dateStr == null ? new Date() : i18n.parseISOLocal(dateStr);

    TransportFlightSearchService.getAirports(
      objectId(this.props.enterprise)
    ).then((y) => {
      const criteria: IFlightCriteria = {
        from: "",
        to: "",
        charter: true,
        market: false,
        passengers: passengers,
        dates: {
          start: date,
          end: date,
        },
        searchDates: {
          start: date,
          end: date,
        },
        flexible: false,
        arrival:
          arrivalStr != null ? arrivalStr.toLowerCase() === "true" : false,
      };
      TransportFlightSearchService.loadOffer(
        objectId(this.props.enterprise),
        id,
        passengers,
        arrivalStr != null ? arrivalStr.toLowerCase() === "true" : false
      ).then((x) => {
        let offerIndex = 0;
        const offers = TransportFlightSearchService.getOffers(x.data);
        const offer = offers.filter((x) => x.id === id)[0];
        offers.forEach((x, i) => {
          if (x.id === id) {
            offerIndex = i;
          }
        });

        TransportFlightBookingService.getDataModel(
          objectId(props.enterprise),
          criteria?.arrival ?? false
            ? criteria.from ?? "MEX"
            : criteria.to ?? "MEX",
          criteria?.arrival ?? false
        ).then((m) => {
          this.setState({
            model: {
              marketEnabled: y.marketEnabled,
              charterEnabled: y.charterEnabled,
              defaultAirports: x.defaultAirports,
              model: m,
              data: x.data,
              interiorAirports: y.interiors.filter(
                (r) => (x.defaultAirports.local?.[0] ?? "YUL") === r.key
              ),
              exteriorAirports: y.exteriors.filter(
                (r) => (x.defaultAirports.remote?.[0] ?? "YUL") === r.key
              ),
              criteria: {
                ...criteria,
                from: offer.from,
                to: offer.to,
              },
              offers: offers,
              offer: offer,
              offerIndex: offerIndex,
              flexible: false,
              serviceIndex: 0,
            },
          });
        });
      });
    });
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getTransportOfferPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { model } = this.state;
    return (
      <Stack
        verticalFill
        grow
        verticalAlign="center"
        horizontalAlign="center"
        className={classNames.root}
      >
        {model !== undefined ? (
          <Redirect
            to={{ pathname: "/transports/billetterie/sommaire", state: model }}
          />
        ) : (
          <CustomSpinner
            {...this.props}
            styles={{
              root: {
                marginTop: "100px",
              },
            }}
            text={i18n.t("transports:offer:title")}
          />
        )}
      </Stack>
    );
  }
}
