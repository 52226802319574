import { Icon, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { UserCallout } from "src/controls/UserCallout";
import { getRequestHistoryCardClassNames } from "./RequestHistoryCard.styles";
import {
  IRequestHistoryCardProps,
  IRequestHistoryCardState,
} from "./RequestHistoryCard.types";

export class RequestHistoryCardComponent extends React.Component<
  IRequestHistoryCardProps,
  IRequestHistoryCardState
> {
  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getRequestHistoryCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;
    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={item.name}
        columnWidth={columnWidth}
        footerText={item.createdOn}
        footerIconName={footerIconName ?? "Calendar"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <TooltipHost className={classNames.root} content={item.name}>
            <Icon iconName="DocLibrary" styles={subComponentStyles?.topIcon} />
          </TooltipHost>
        }
      >
        <UserCallout {...this.props} user={item.createdBy} />
      </GridViewCard>
    );
  }
}
