import { ProgressIndicator } from "@fluentui/react";
import * as React from "react";
import { PasswordCheckStrength } from "src/models/PasswordCheckStrength";
import i18n from "src/services/i18n";
import { PasswordCheckService } from "src/services/PasswordCheckService";
import { getPasswordStrengthIndicatorClassNames } from "./PasswordStrengthIndicator.styles";
import {
  IPasswordStrengthIndicatorProps,
  IPasswordStrengthIndicatorState,
} from "./PasswordStrengthIndicator.types";

export class PasswordStrengthIndicatorComponent extends React.Component<
  IPasswordStrengthIndicatorProps,
  IPasswordStrengthIndicatorState
> {
  private strengthToPercentage = (strength: PasswordCheckStrength): number => {
    let percentage: number = 0;
    switch (strength) {
      case PasswordCheckStrength.Empty:
        percentage = 0;
        break;
      case PasswordCheckStrength.Short:
        percentage = 20;
        break;
      case PasswordCheckStrength.Common:
        percentage = 33;
        break;
      case PasswordCheckStrength.Weak:
        percentage = 50;
        break;
      case PasswordCheckStrength.Ok:
        percentage = 75;
        break;
      case PasswordCheckStrength.Strong:
        percentage = 90;
        break;
      default:
        percentage = 20;
        break;
    }
    return percentage;
  };

  render(): JSX.Element {
    const { styles, password } = this.props;
    const strength: PasswordCheckStrength =
      PasswordCheckService.checkPasswordStrength(password ?? "");
    const percentage: number = this.strengthToPercentage(strength);
    const [classNames, subComponentStyles] =
      getPasswordStrengthIndicatorClassNames(styles!, {
        ...this.props,
        ...this.state,
        strength: strength,
      });
    return (
      <ProgressIndicator
        className={classNames.root}
        styles={subComponentStyles?.indicator}
        percentComplete={percentage / 100}
        description={i18n.t(`passwordstrength:${strength}`)}
      />
    );
  }
}
