import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestHistoryControlProps } from "./RequestHistoryControl.types";
import {
  getStyles,
  IRequestHistoryControlStyleProps,
  IRequestHistoryControlStyles,
} from "./RequestHistoryControl.styles";
import { RequestHistoryControlComponent } from "./RequestHistoryControl.base";

/**
 * RequestHistoryControl description
 */
export const RequestHistoryControl = styled<
  IRequestHistoryControlProps,
  IRequestHistoryControlStyleProps,
  IRequestHistoryControlStyles
>(RequestHistoryControlComponent, getStyles);
