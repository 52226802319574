import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAuthorizationConsentPanelSubComponentStyles {}

export interface IAuthorizationConsentPanelStyles
  extends IAmigoBaseStyles<IAuthorizationConsentPanelSubComponentStyles> {}

export interface IAuthorizationConsentPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAuthorizationConsentPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IAuthorizationConsentPanelStyleProps,
    IAuthorizationConsentPanelStyles
  >,
  props?: IAuthorizationConsentPanelStyleProps
): [
  IProcessedStyleSet<IAuthorizationConsentPanelStyles>,
  IAuthorizationConsentPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAuthorizationConsentPanelStyleProps,
  IAuthorizationConsentPanelStyles
> = (
  _props: IAuthorizationConsentPanelStyleProps
): IAuthorizationConsentPanelStyles => {
  return {
    root: ["amigo-AuthorizationConsentPanel", {}],
  };
};
