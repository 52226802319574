import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingParticipantsPageProps } from "./FlightBookingParticipantsPage.types";
import {
  getStyles,
  IFlightBookingParticipantsPageStyleProps,
  IFlightBookingParticipantsPageStyles,
} from "./FlightBookingParticipantsPage.styles";
import { FlightBookingParticipantsPageComponent } from "./FlightBookingParticipantsPage.base";

/**
 * FlightBookingParticipantsPage
 */
export const FlightBookingParticipantsPage = styled<
  IFlightBookingParticipantsPageProps,
  IFlightBookingParticipantsPageStyleProps,
  IFlightBookingParticipantsPageStyles
>(FlightBookingParticipantsPageComponent, getStyles);
