import * as React from "react";
import { getPaymentMethodDeleteMessageClassNames } from "./PaymentMethodDeleteMessage.styles";
import {
  IPaymentMethodDeleteMessageState,
  IPaymentMethodDeleteMessageProps,
} from "./PaymentMethodDeleteMessage.types";
import {
  Text,
  Stack,
  DetailsList,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { IPaymentMethod } from "src/models/Enterprises/IPaymentMethod";

export class PaymentMethodDeleteMessageComponent extends React.Component<
  IPaymentMethodDeleteMessageProps,
  IPaymentMethodDeleteMessageState
> {
  constructor(props: IPaymentMethodDeleteMessageProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, lastcard, multiple } = this.props;
    const [classNames] = getPaymentMethodDeleteMessageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack
        className={classNames.root}
        tokens={this.props.tokens?.defaultStackTokens}
      >
        <Text>
          {i18n.t(
            `enterprises:paymentmethods:deletemessage:${
              multiple ? "plural" : "simple"
            }`
          )}
        </Text>
        <DetailsList
          items={this.props.items}
          columns={[
            {
              key: "card",
              fieldName: "card",
              name: i18n.t("enterprises:paymentmethods:card"),
              minWidth: 120,
              onRender: (item: IPaymentMethod) => <Text>{item.card}</Text>,
            },
            {
              key: "expirationMonth",
              name: i18n.t("enterprises:paymentmethods:expiration"),
              minWidth: 80,
              onRender: (item: IPaymentMethod) => (
                <Text>{`${
                  item.expirationMonth < 10
                    ? "0" + item.expirationMonth.toString()
                    : item.expirationMonth.toString()
                }/${item.expirationYear}`}</Text>
              ),
            },
          ]}
        />
        <MessageBar messageBarType={MessageBarType.warning}>
          {i18n.t("enterprises:paymentmethods:delete:irreversible")}
        </MessageBar>
        {lastcard && (
          <MessageBar messageBarType={MessageBarType.severeWarning}>
            {i18n.t("enterprises:paymentmethods:delete:lastcard:1")}
            <br />
            <br />
            {i18n.t("enterprises:paymentmethods:delete:lastcard:2")}
          </MessageBar>
        )}
      </Stack>
    );
  }
}
