import moment from "moment";
import {
  Calendar,
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import Moment from "moment";
import { CustomSpinner } from "src/components/CustomSpinner";
import { MediaQueries } from "src/components/MediaQueries";
import { IPaymentOperationModel } from "src/models/Billing/IPaymentOperationModel";
import { IPaymentOperationResult } from "src/models/Billing/IPaymentOperationResult";
import { IPendingBill } from "src/models/Billing/IPendingBill";
import { IPaymentMethod } from "src/models/Enterprises/IPaymentMethod";
import { objectId } from "src/models/IObjectReference";
import { NewPaymentMethodControl } from "src/pages/Enterprises/NewPaymentMethodControl";
import { PaymentMethodDeleteMessage } from "src/pages/Enterprises/PaymentMethodDeleteMessage";
import PaymentsService from "src/services/Billing/PaymentsService";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import PaymentMethodsService from "src/services/Enterprises/PaymentMethodsService";
import i18n from "src/services/i18n";
import { NewPaymentCommandBar } from "../Controls";
import { NewPaymentBillingSummary } from "../NewPaymentBillingSummary";
import { NewPaymentClientInfo } from "../NewPaymentClientInfo";
import { NewPaymentMethodSelector } from "../NewPaymentMethodSelector";
import { NewPaymentMethodSummary } from "../NewPaymentMethodSummary";
import { NewPaymentBills } from "../NewPaymentsBills";
import { NewPaymentSecureDialog } from "../NewPaymentSecureDialog";
import { PaymentConfirmationPage } from "../PaymentConfirmationPage";
import { PaymentErrorPage } from "../PaymentErrorPage";
import { getNewPaymentPageClassNames } from "./NewPaymentPage.styles";
import {
  INewPaymentPageProps,
  INewPaymentPageState,
} from "./NewPaymentPage.types";

export class NewPaymentPageComponent extends React.Component<
  INewPaymentPageProps,
  INewPaymentPageState
> {
  constructor(props: INewPaymentPageProps) {
    super(props);
    this.state = {
      errors: [],
      securedialog: false,
      paymenterror: false,
      thankyou: false,
      paying: false,
      confirmation: false,
      cardEditing: false,
      showMonerisError: false,
    };
    EnterpriseService.getEnterprise(props.enterprise).then((z) => {
      PaymentMethodsService.getPaymentMethods(
        objectId(this.props.enterprise),
        "createdOn",
        true,
        [],
        undefined
      ).then((y) => {
        const methods = y.concat([
          {
            key: "custom",
            id: -1,
            card: "",
            name: "",
            expirationMonth: 0,
            expirationYear: new Date().getFullYear() + 1,
            cvc: "",
            enabled: true,
            createdBy: "",
            createdOn: "",
            modifiedOn: "",
            cardType: "Visa",
            transportAuthorized: true,
            billingAuthorized: true,
            saveOnVaultDB: false,
          },
        ]);

        PaymentsService.getPendingBills(objectId(props.enterprise)).then(
          (x) => {
            this.setState({ bills: x, paymentMethods: methods, enterprise: z });
          }
        );
      }).catch((error) => {
        if (error.message === "FatalErrorFetchPaymentMethods")
          this.setState({
            showMonerisError: true
          });
      });
    });
  }

  private onDismiss = (): void => {
    const { route } = this.props;
    const { confirmation } = this.state;
    if (confirmation) {
      this.setState({ confirmation: false });
    } else {
      route.history.push("/facturation/paiements");
    }
  };

  private hideCardNumber = (str: string): string => {
    if (str.length < 4) {
      return str;
    }
    const cardnumber = str.replace(" ", "");
    const lastFourChars = cardnumber.slice(-4); // Get the last 4 characters
    const asterisks = "*".repeat(12); // Create a string of asterisks
    return asterisks + lastFourChars;
  }

  private onSubmit = (): void => {
    const { bills, selectedPaymentMethod } = this.state;
    if (!bills) return;
    const payment: number = bills.reduce((sum, item) => sum + item.payment, 0);
    const errors: string[] = [];
    if (payment <= 0) {
      (errors as any).payment = "error";
    }
    if (!selectedPaymentMethod) {
      (errors as any).paymentmethod = "error";
    }

    if (
      this.state.confirmation &&
      Object.keys(errors).length === 0 &&
      this.state.selectedPaymentMethod
    ) {
      const currentPayment: number = (bills ?? []).reduce(
        (sum, current) => sum + current.payment,
        0
      );
      const model: IPaymentOperationModel = {
        enterpriseId: objectId(this.props.enterprise),
        totalPayment: currentPayment,
        inlinePaymentMethod:
          this.state.selectedPaymentMethod.id === -1
            ? this.state.selectedPaymentMethod
            : undefined,
        paymentMethodId:
          this.state.selectedPaymentMethod.id === -1
            ? undefined
            : this.state.selectedPaymentMethod.id,
        cvc: this.state.selectedPaymentMethod.cvc,
        details: bills
          .filter((x) => x.payment > 0)
          .map((x) => {
            return {
              billId: x.id,
              amount: x.payment,
            };
          }),
      };
      PaymentsService.pay(model)
        .then((x) => {
          
          if(model?.inlinePaymentMethod)
          {
            // Removing sencesive data
            model.inlinePaymentMethod.card = this.hideCardNumber(model.inlinePaymentMethod.card);
            model.inlinePaymentMethod.name = "";
            model.inlinePaymentMethod.expirationMonth = 0;
            model.inlinePaymentMethod.expirationYear = 0;
            model.inlinePaymentMethod.cvc = "";
          }

          const received: boolean = x.date !== undefined;
          const results: IPaymentOperationResult = received
            ? x
            : {
                success: false,
                reservationId: 0,
                date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
                code: "000",
                transaction: "0000-0-000",
                authorization: "000000Z",
                reference: "000000000000000000",
                key: "000000",
                payment: "000000",
              };

          this.setState({
            thankyou: results.success,
            paymenterror: !results.success,
            results: results,
            paying: false,
          });
        })
        .catch((_x) => {
          this.setState({
            thankyou: false,
            paying: false,
            paymenterror: true,
            results: {
              success: false,
              reservationId: 0,
              date: moment(new Date()).format("YYYY-MM-DD hh:mm:ss"),
              code: "000",
              transaction: "0000-0-000",
              authorization: "000000Z",
              reference: "000000000000000000",
              key: "000000",
              payment: "000000",
            },
          });
        });
      this.setState({
        errors: errors,
        paying: Object.keys(errors).length === 0,
      });
    } else {
      let securedialog: boolean = false;
      if (
        this.state.selectedPaymentMethod &&
        !this.state.selectedPaymentMethod.secured
      ) {
        securedialog =
          !this.state.selectedPaymentMethod.cvc ||
          this.state.selectedPaymentMethod.cvc.length < 3;
      }

      this.setState({
        errors: errors,
        confirmation: !securedialog && Object.keys(errors).length === 0,
        securedialog: securedialog,
      });
    }
  };

  render(): JSX.Element {
    const { styles, mobile, tablet, enterprise } = this.props;
    const [classNames, subComponentStyles] = getNewPaymentPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    let subtotal: number = (this.state.bills ?? []).reduce(
      (sum, current) => sum + current.subtotal,
      0
    );

    let tps: number = (this.state.bills ?? []).reduce(
      (sum, current) => sum + current.tps,
      0
    );

    let tvq: number = (this.state.bills ?? []).reduce(
      (sum, current) => sum + current.tvq,
      0
    );

    const total: number = (this.state.bills ?? []).reduce(
      (sum, current) => sum + current.total,
      0
    );

    const currentPayment: number = (this.state.bills ?? []).reduce(
      (sum, current) => sum + current.payment,
      0
    );

    const ratio = total > 0 ? currentPayment / total : 1;

    subtotal *= ratio;
    tps *= ratio;
    tvq *= ratio;

    if (this.state.paying) {
      return (
        <div
          style={{
            marginLeft: mobile ? "5px" : "50px",
            marginTop: mobile ? "30px" : "40px",
          }}
        >
          <div style={{ height: mobile ? "40px" : "70px" }}></div>
          <CustomSpinner
            {...this.props}
            styles={undefined}
            text={"Veuillez patientez... Nous procédons avec votre paiement."}
          />
        </div>
      );
    } else if (this.state.paymenterror) {
      return (
        <PaymentErrorPage
          {...this.props}
          styles={undefined}
          subtotal={subtotal}
          tps={tps}
          tvq={tvq}
          total={currentPayment}
          card={this.state.selectedPaymentMethod?.card ?? ""}
          cardType={this.state.selectedPaymentMethod?.cardType ?? "Visa"}
          results={this.state.results}
        />
      );
    } else if (this.state.thankyou) {
      return (
        <PaymentConfirmationPage
          {...this.props}
          styles={undefined}
          subtotal={subtotal}
          tps={tps}
          tvq={tvq}
          total={currentPayment}
          card={this.state.selectedPaymentMethod?.card ?? ""}
          cardType={this.state.selectedPaymentMethod?.cardType ?? "Visa"}
          results={this.state.results}
        />
      );
    }

    return (
      <div className={classNames.root}>
         <Dialog
          hidden={!this.state.showMonerisError}
          dialogContentProps={{
            title: i18n.t("dialog:error:title"),
            subText: i18n.t("dialog:error:moneris"),
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}/>

        <Stack
          horizontal={!mobile && !tablet}
          style={{ width: "100%", padding: mobile ? "10px" : "0px 5px" }}
          tokens={{ childrenGap: 10 }}
        >
          {mobile && (
            <NewPaymentCommandBar
              {...this.props}
              styles={subComponentStyles?.commandBar}
              confirmation={this.state.confirmation}
              onDismiss={this.onDismiss}
              onSubmit={this.onSubmit}
            />
          )}
          {mobile &&
            this.state.confirmation &&
            this.state.selectedPaymentMethod && (
              <NewPaymentMethodSummary
                {...this.props}
                styles={undefined}
                paymentMethod={this.state.selectedPaymentMethod}
                payment={total}
              />
            )}
          <div style={{ minWidth: "240px" }}>
            {!mobile && (
              <NewPaymentCommandBar
                {...this.props}
                confirmation={this.state.confirmation}
                styles={subComponentStyles?.commandBar}
                onDismiss={this.onDismiss}
                onSubmit={this.onSubmit}
              />
            )}
            {!tablet && this.state.enterprise && (
              <NewPaymentClientInfo
                {...this.props}
                styles={undefined}
                enterprise={this.state.enterprise}
              />
            )}

            {tablet && (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                {this.state.enterprise && (
                  <NewPaymentClientInfo
                    {...this.props}
                    styles={undefined}
                    enterprise={this.state.enterprise}
                  />
                )}
                <div>
                  {!mobile && <div style={{ height: "48px" }}></div>}
                  <Text style={{ fontWeight: 600 }}>
                    {i18n.t("billing:payments:confirmation:date")}
                  </Text>
                  <Calendar
                    strings={i18n.CalendarStrings()}
                    isMonthPickerVisible={false}
                    minDate={Moment(new Date()).subtract(1, "day").toDate()}
                    maxDate={new Date(2199, 1, 1)}
                    isDayPickerVisible={true}
                    value={new Date()}
                    today={new Date()}
                    showGoToToday={false}
                    showCloseButton={false}
                  />
                </div>
              </Stack>
            )}
          </div>
          {!tablet && (
            <div>
              {!mobile && <div style={{ height: "48px" }}></div>}
              <Text style={{ fontWeight: 600 }}>
                {i18n.t("billing:payments:confirmation:date")}
              </Text>
              <Calendar
                strings={i18n.CalendarStrings()}
                isMonthPickerVisible={false}
                minDate={Moment(new Date()).subtract(1, "day").toDate()}
                maxDate={new Date(2199, 1, 1)}
                isDayPickerVisible={true}
                value={new Date()}
                today={new Date()}
                showGoToToday={false}
                showCloseButton={false}
              />
            </div>
          )}
          {!this.state.confirmation && (
            <div>
              <NewPaymentBills
                onUpdate={(_item: IPendingBill) => {
                  const { bills } = this.state;
                  this.setState({ bills: bills });
                }}
                {...this.props}
                {...this.state}
                styles={subComponentStyles?.newPaymentBills}
              />
            </div>
          )}
          {!mobile &&
            this.state.confirmation &&
            this.state.selectedPaymentMethod && (
              <NewPaymentMethodSummary
                {...this.props}
                styles={undefined}
                paymentMethod={this.state.selectedPaymentMethod}
                payment={currentPayment}
              />
            )}
        </Stack>
        {!this.state.confirmation && (
          <NewPaymentMethodSelector
            {...this.props}
            {...this.state}
            styles={undefined}
            onDelete={(item: IPaymentMethod) => {
              if (item.id < 0) return;
              this.setState({ deletingCard: item });
            }}
            onSelected={(item: IPaymentMethod) => {
              const { paymentMethods } = this.state;
              if (
                paymentMethods &&
                this.state.selectedPaymentMethod &&
                this.state.selectedPaymentMethod.id !== -1
              ) {
                paymentMethods.forEach((x) => {
                  if (!(x.secured ?? false)) {
                    x.cvc = undefined;
                  }
                });
              }

              if (item.id === -1) {
                this.setState({
                  selectedPaymentMethod: item,
                  paymentMethods: paymentMethods,
                  cardEditing: true,
                  securedialog: false,
                });
              } else {
                let securedialog: boolean = false;
                if (!item.secured) {
                  securedialog = !item.cvc || item.cvc.length < 3;
                }
                this.setState({
                  selectedPaymentMethod: item,
                  paymentMethods: paymentMethods,
                  securedialog: securedialog,
                });
              }
            }}
          />
        )}
        {this.state.confirmation && (
          <NewPaymentBillingSummary
            {...this.props}
            styles={undefined}
            subtotal={subtotal}
            tps={tps}
            tvq={tvq}
            total={currentPayment}
          />
        )}
        <Dialog
          hidden={this.state.deletingCard === undefined}
          dialogContentProps={{
            type: DialogType.normal,
            title: "Deleting Payment Method",
            closeButtonAriaLabel: i18n.t("global:close"),
            subText: undefined,
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}
        >
          <PaymentMethodDeleteMessage
            {...this.props}
            styles={undefined}
            items={this.state.deletingCard ? [this.state.deletingCard] : []}
            multiple={false}
            lastcard={(this.state.paymentMethods ?? []).length - 1 <= 0}
          />
          <DialogFooter>
            <PrimaryButton
              data-automation-id="nvx:grid:confirm:yes"
              onClick={() =>
                this.state.deletingCard &&
                PaymentMethodsService.deletePaymentMethods(
                  objectId(enterprise),
                  [this.state.deletingCard]
                ).then((_x) => {
                  PaymentMethodsService.getPaymentMethods(
                    objectId(this.props.enterprise),
                    "createdOn",
                    true,
                    [],
                    undefined
                  ).then((y) => {
                    const methods = y.concat([
                      {
                        key: "custom",
                        id: -1,
                        card: "",
                        name: "",
                        expirationMonth: 0,
                        expirationYear: new Date().getFullYear() + 1,
                        cvc: "",
                        enabled: true,
                        createdBy: "",
                        createdOn: "",
                        modifiedOn: "",
                        cardType: "Visa",
                        transportAuthorized: true,
                        billingAuthorized: true,
                        saveOnVaultDB: false,
                      },
                    ]);
                    this.setState({
                      deletingCard: undefined,
                      paymentMethods: methods,
                    });
                  }).catch((error) => {
                    if (error.message === "FatalErrorFetchPaymentMethods")
                      this.setState({
                        showMonerisError: true
                      });
                  });
                })
              }
              text={i18n.t("global:yes")}
            />
            <DefaultButton
              data-automation-id="nvx:grid:confirm:no"
              onClick={() => this.setState({ deletingCard: undefined })}
              text={i18n.t("global:no")}
            />
          </DialogFooter>
        </Dialog>
        <Dialog
          hidden={!this.state.cardEditing}
          modalProps={{
            isBlocking: true,
            isDarkOverlay: true,
          }}
          onDismiss={() => {
            this.setState({
              cardEditing: false,
              selectedPaymentMethod: undefined, // TODO: validate and keep if good
            });
          }}
          title={i18n.t("enterprises:paymentmethods:new:header")}
          styles={{
            main: {
              padding: "0",
              width: "80vw",
              maxWidth: "80vw",
              selectors: {
                "@media (min-width: 480px)": {
                  width: "80vw",
                  maxWidth: "80vw",
                },
                [MediaQueries.mobile]: {
                  width: "100vw",
                  maxWidth: "100vw",
                },
              },
            },
          }}
        >
          <DialogContent titleProps={{ style: { display: "none" } }}>
            <NewPaymentMethodControl
              {...this.props}
              styles={undefined}
              dialog={true}
              onSave={(item: IPaymentMethod) => {
                if (!this.state.paymentMethods) return;
                const methods = this.state.paymentMethods;
                const custom = methods.filter((x) => x.id === -1)[0];
                custom.key = "custom";
                custom.id = -1;
                custom.card = PaymentsService.formatCreditCard(item.card);
                custom.name = item.name;
                custom.expirationMonth = item.expirationMonth;
                custom.expirationYear = item.expirationYear;
                custom.cvc = item.cvc;
                custom.enabled = true;
                custom.createdBy = "";
                custom.createdOn = "";
                custom.modifiedOn = "";
                custom.cardType = item.cardType;
                custom.transportAuthorized = item.transportAuthorized;
                custom.billingAuthorized = item.billingAuthorized;

                this.setState({
                  paymentMethods: methods,
                  selectedPaymentMethod: custom,
                  cardEditing: false,
                });
              }}
              onDismiss={() => {
                this.setState({
                  cardEditing: false,
                  selectedPaymentMethod: undefined, // TODO: validate and keep if good
                });
              }}
            />
          </DialogContent>
        </Dialog>
        <NewPaymentSecureDialog
          {...this.props}
          styles={undefined}
          card={this.state.selectedPaymentMethod?.card ?? ""}
          isOpen={this.state.securedialog}
          onSubmit={(cvc: string) => {
            const { selectedPaymentMethod } = this.state;
            if (selectedPaymentMethod) {
              selectedPaymentMethod.cvc = cvc;
              this.setState({
                securedialog: false,
                selectedPaymentMethod: selectedPaymentMethod,
              });
            }
          }}
          onDismiss={() => {
            const { selectedPaymentMethod } = this.state;
            if (
              selectedPaymentMethod &&
              !(selectedPaymentMethod.secured ?? false)
            ) {
              selectedPaymentMethod.cvc = undefined;
            }
            this.setState({
              securedialog: false,
              selectedPaymentMethod: selectedPaymentMethod,
            });
          }}
        />
      </div>
    );
  }
}
