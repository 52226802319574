import {
  DefaultPalette,
  Link,
  ShimmerLine,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { IRequestPeriodPosition } from "src/models/Requests/IRequestPeriodPosition";
import i18n from "src/services/i18n";
import { getPeriodPositionCountTextClassNames } from "./PeriodPositionCountText.styles";
import {
  IPeriodPositionCountTextProps,
  IPeriodPositionCountTextState,
} from "./PeriodPositionCountText.types";

export class PeriodPositionCountTextComponent extends React.Component<
  IPeriodPositionCountTextProps,
  IPeriodPositionCountTextState
> {
  private resolvePosition = (x: IRequestPeriodPosition): string => {
    if (x?.name === null || x.name === "") return "";
    return ` ${i18n.t("global:of")} ${x.name}`;
  };

  private getRate = (x: IRequestPeriodPosition, force?: boolean): string => {
    if ((force ?? false) || (this.props.showRates ?? false)) {
      return ` (${x.hourlyRate})`;
    }
    return "";
  };

  private tooltip = (x: IRequestPeriodPosition): JSX.Element => {
    return (
      <Stack style={{ marginRight: "3px" }} tokens={{ childrenGap: 5 }}>
        <Text variant="small">{`${x.count} ${i18n.t(
          x.count > 1 ? "requests:postes" : "requests:poste"
        )}${this.resolvePosition(x)}${this.getRate(x, true)}`}</Text>
        <Text variant="small">{x.functionDescription}</Text>
      </Stack>
    );
  };

  private getPositions = (): [IRequestPeriodPosition[], string] => {
    const { route } = this.props;
    const s = route.location.state;
    const r: IRequestPeriodPosition[] = this.props.positions;
    if (s) {
      const p = (s as any).position;
      if (p) {
        return [r, p ?? ""];
      }
    }
    return [r, ""];
  };

  render(): JSX.Element {
    const { styles, route } = this.props;
    const [classNames] = getPeriodPositionCountTextClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const [positions, filter] = this.getPositions();
    const max: number = this.props.maxPositions ?? positions.length;
    return (
      <div className={classNames.root}>
        {positions &&
          positions.length > 1 &&
          (filter === "" || filter === "all") && (
            <Text
              key={"all"}
              {...this.props}
              styles={{
                root: {
                  color: this.props.theme.palette.themePrimary,
                  fontWeight: "bold",
                },
              }}
            >
              {i18n.t("requests:positions:all")}
            </Text>
          )}
        {positions &&
          positions.length > 1 &&
          !(filter === "" || filter === "all") && (
            <Link
              key={"positions"}
              onClick={(
                ev: React.MouseEvent<
                  HTMLElement | HTMLAnchorElement | HTMLButtonElement,
                  MouseEvent
                >
              ) => {
                ev.preventDefault();
                ev.stopPropagation();
                route.location.state = undefined;
                if (this.props.onClick) {
                  this.props.onClick();
                } else {
                  this.forceUpdate();
                }
              }}
              to={`/demandes/${this.props.requestId}/periodes/${this.props.periodId}`}
            >
              <Text
                style={
                  filter === "" || filter === "all"
                    ? { fontWeight: "bold" }
                    : undefined
                }
                key={"all"}
                {...this.props}
                styles={undefined}
              >
                {i18n.t("requests:positions:all")}
              </Text>
            </Link>
          )}
        {positions &&
          positions.map((x) => (
            <div key={x.name}>
              <TooltipHost content={this.tooltip(x)}>
                {filter === x.name || positions.length === 1 ? (
                  <Text
                    style={
                      filter === x.name || positions.length === 1
                        ? {
                            color: this.props.theme.palette.themePrimary,
                            fontWeight: "bold",
                          }
                        : undefined
                    }
                    key={x.name}
                    {...this.props}
                    styles={undefined}
                  >{`${x.count} ${i18n.t(
                    x.count > 1 ? "requests:postes" : "requests:poste"
                  )}${this.resolvePosition(x)}${this.getRate(x)}`}</Text>
                ) : (
                  <Link
                    onClick={(
                      ev: React.MouseEvent<
                        HTMLElement | HTMLAnchorElement | HTMLButtonElement,
                        MouseEvent
                      >
                    ) => {
                      ev.preventDefault();
                      ev.stopPropagation();
                      if (this.props.showRates) {
                        route.location.state = { position: x.name };
                        if (this.props.onClick) {
                          this.props.onClick();
                        } else {
                          this.forceUpdate();
                        }
                      } else {
                        route.history.push(
                          `/demandes/${this.props.requestId}/periodes/${this.props.periodId}`,
                          { position: x.name }
                        );
                      }
                    }}
                    to={`/demandes/${this.props.requestId}/periodes/${this.props.periodId}`}
                  >
                    <Text
                      style={
                        filter === x.name || positions.length === 1
                          ? { fontWeight: "bold" }
                          : undefined
                      }
                      key={x.name}
                      {...this.props}
                      styles={undefined}
                    >{`${x.count} ${i18n.t(
                      x.count > 1 ? "requests:postes" : "requests:poste"
                    )}${this.resolvePosition(x)}${this.getRate(x)}`}</Text>
                  </Link>
                )}
              </TooltipHost>
            </div>
          ))}
        {[...Array(max - positions.length)].map((_x, i) => (
          <div key={`shimmer_d_${i}`}>
            <ShimmerLine
              key={`shimmer_${i}`}
              height={18}
              color={DefaultPalette.white}
              styles={{
                root: {
                  marginTop: "5px",
                  backgroundColor: DefaultPalette.white,
                },
              }}
            />
          </div>
        ))}
      </div>
    );
  }
}
