import { styled } from "@fluentui/react/lib/Utilities";
import { IUsersPickerProps } from "./UsersPicker.types";
import {
  getStyles,
  IUsersPickerStyleProps,
  IUsersPickerStyles,
} from "./UsersPicker.styles";
import { UsersPickerComponent } from "./UsersPicker.base";

/**
 * UsersPicker description
 */
export const UsersPicker = styled<
  IUsersPickerProps,
  IUsersPickerStyleProps,
  IUsersPickerStyles
>(UsersPickerComponent, getStyles);
