import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestsControlProps } from "./RequestsControl.types";
import {
  getStyles,
  IRequestsControlStyleProps,
  IRequestsControlStyles,
} from "./RequestsControl.styles";
import { RequestsControlComponent } from "./RequestsControl.base";

/**
 * RequestsControl description
 */
export const RequestsControl = styled<
  IRequestsControlProps,
  IRequestsControlStyleProps,
  IRequestsControlStyles
>(RequestsControlComponent, getStyles);
