import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IDepartureAssistedParticipantCardStyleProps,
  IDepartureAssistedParticipantCardStyles,
} from "../DepartureAssistedParticipantCard";

export interface IDepartureAssistedEditorSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IDepartureAssistedParticipantCardStyleProps,
    IDepartureAssistedParticipantCardStyles
  >;
}

export interface IDepartureAssistedEditorStyles
  extends IAmigoBaseStyles<IDepartureAssistedEditorSubComponentStyles> {}

export interface IDepartureAssistedEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDepartureAssistedEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    IDepartureAssistedEditorStyleProps,
    IDepartureAssistedEditorStyles
  >,
  props?: IDepartureAssistedEditorStyleProps
): [
  IProcessedStyleSet<IDepartureAssistedEditorStyles>,
  IDepartureAssistedEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDepartureAssistedEditorStyleProps,
  IDepartureAssistedEditorStyles
> = (
  _props: IDepartureAssistedEditorStyleProps
): IDepartureAssistedEditorStyles => {
  return {
    root: ["amigo-DepartureAssistedEditor", {}],
  };
};
