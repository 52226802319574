import LocalizedStrings from "react-localization";
import locales from "../locales";
import { IDatePickerStrings } from "@fluentui/react";

class LocaleManager {
  private strings = new LocalizedStrings(locales);

  constructor() {
    let lang = navigator.language.substr(0, 2);
    const obj = localStorage.getItem("lang");
    if (obj !== undefined && obj != null) {
      lang = obj;
    }
    this.strings.setLanguage(lang);
  }

  public parseISOLocal(s: string): Date {
    const b = s.split(/\D/);
    return new Date(parseInt(b[0]), parseInt(b[1]) - 1, parseInt(b[2]));
  }

  public replaceAll(
    chaine: string,
    replace: string,
    replacePar: string
  ): string {
    return chaine.replace(new RegExp(replace, "g"), replacePar);
  }

  public parseISOLocalString(s: string): string {
    const b = s.split(/\D/);
    const m = parseInt(b[1]);
    const d = parseInt(b[2]);
    const y = b[0];
    return `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
  }

  public parseISOLocalDate(s: Date): string {
    const m = s.getMonth();
    const d = s.getDate();
    const y = s.getFullYear();
    return `${y}-${m < 10 ? "0" + m : m}-${d < 10 ? "0" + d : d}`;
  }

  public getLanguage(): string {
    return this.strings.getLanguage();
  }

  public formatString(str: string, ...val: string[]): string {
    for (let index = 0; index < val.length; index++) {
      str = str.replace(`{${index}}`, val[index]);
    }
    return str;
  }

  public setLanguage(lang: string): void {
    if (lang === undefined) lang = "en";
    localStorage.setItem("lang", lang);
    this.strings.setLanguage(lang);
  }

  public t(key: string): string {
    return this.strings[key] ?? key;
  }

  public PhoneLink(value: string): string | undefined {
    return value === undefined
      ? undefined
      : "tel:+1" + value.match(this.NumberPattern())?.join("") ?? "";
  }

  public NumberPattern(): RegExp {
    return /\d+/g;
  }

  public currency(value?: number): string {
    if (value === undefined || value == null) return " - $";

    const formatter = new Intl.NumberFormat("fr-CA", {
      style: "currency",
      currency: "CAD",
      minimumFractionDigits: 2,
    });
    return formatter.format(value);
  }

  public stripDiacritics(str: string): string {
    const diacritics = [
      { char: "A", base: /[\300-\306]/g },
      { char: "a", base: /[\340-\346]/g },
      { char: "E", base: /[\310-\313]/g },
      { char: "e", base: /[\350-\353]/g },
      { char: "I", base: /[\314-\317]/g },
      { char: "i", base: /[\354-\357]/g },
      { char: "O", base: /[\322-\330]/g },
      { char: "o", base: /[\362-\370]/g },
      { char: "U", base: /[\331-\334]/g },
      { char: "u", base: /[\371-\374]/g },
      { char: "N", base: /[\321]/g },
      { char: "n", base: /[\361]/g },
      { char: "C", base: /[\307]/g },
      { char: "c", base: /[\347]/g },
    ];

    diacritics.forEach(function (letter) {
      str = str.replace(letter.base, letter.char);
    });

    return str;
  }

  public CreditCardStrings = () => {
    return {
      placeholders: { name: this.t("creditcard:nameplaceholder") },
      locale: { valid: this.t("creditcard:validplaceholder") },
    };
  };

  public CalendarStrings = (): IDatePickerStrings => {
    return {
      months: [
        this.t("calendars:months:january"),
        this.t("calendars:months:february"),
        this.t("calendars:months:march"),
        this.t("calendars:months:april"),
        this.t("calendars:months:may"),
        this.t("calendars:months:june"),
        this.t("calendars:months:july"),
        this.t("calendars:months:august"),
        this.t("calendars:months:september"),
        this.t("calendars:months:october"),
        this.t("calendars:months:november"),
        this.t("calendars:months:december"),
      ],
      shortMonths: [
        this.t("calendars:months:january:short"),
        this.t("calendars:months:february:short"),
        this.t("calendars:months:march:short"),
        this.t("calendars:months:april:short"),
        this.t("calendars:months:may:short"),
        this.t("calendars:months:june:short"),
        this.t("calendars:months:july:short"),
        this.t("calendars:months:august:short"),
        this.t("calendars:months:september:short"),
        this.t("calendars:months:october:short"),
        this.t("calendars:months:november:short"),
        this.t("calendars:months:december:short"),
      ],
      days: [
        this.t("calendars:days:sunday"),
        this.t("calendars:days:monday"),
        this.t("calendars:days:tuesday"),
        this.t("calendars:days:wednesday"),
        this.t("calendars:days:thursday"),
        this.t("calendars:days:friday"),
        this.t("calendars:days:saturday"),
      ],
      shortDays: [
        this.t("calendars:days:sunday:short"),
        this.t("calendars:days:monday:short"),
        this.t("calendars:days:tuesday:short"),
        this.t("calendars:days:wednesday:short"),
        this.t("calendars:days:thursday:short"),
        this.t("calendars:days:friday:short"),
        this.t("calendars:days:saturday:short"),
      ],
      goToToday: this.t("calendars:today"),
      weekNumberFormatString: this.t("calendars:weeks"),
      prevMonthAriaLabel: this.t("calendars:previousmonth"),
      nextMonthAriaLabel: this.t("calendars:nextmonth"),
      prevYearAriaLabel: this.t("calendars:previousyear"),
      nextYearAriaLabel: this.t("calendars:nextyear"),
      prevYearRangeAriaLabel: this.t("calendars:previousyearrange"),
      nextYearRangeAriaLabel: this.t("calendars:nextyearrange"),
      closeButtonAriaLabel: this.t("global:close"),
    };
  };
}

export default new LocaleManager();
