import {
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getRequestParticipantFormClassNames } from "./RequestParticipantForm.styles";
import {
  IRequestParticipantFormProps,
  IRequestParticipantFormState,
} from "./RequestParticipantForm.types";

export class RequestParticipantFormComponent extends React.Component<
  IRequestParticipantFormProps,
  IRequestParticipantFormState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getRequestParticipantFormClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { participant } = this.props;
    return (
      <>
        <Stack
          grow
          horizontal={!this.props.mobile}
          styles={{ root: { maxWidth: "600px" } }}
          tokens={{ childrenGap: 10 }}
        >
          <StackItem grow styles={{ root: { maxWidth: "250px" } }}>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:name")}
              content={participant.name}
            />
          </StackItem>
          <StackItem grow>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:number")}
              content={participant.participantNumber}
            />
          </StackItem>
        </Stack>
        {participant.arrivalStatus === 2 && participant.readyToTravel && (
          <MessageBar
            styles={{
              root: {
                maxWidth: this.props.mobile ? "100%" : "460px",
                margin: "10px 0px 15px 0px",
              },
            }}
            messageBarType={MessageBarType.success}
          >
            <div style={{ marginBottom: "5px" }}>
              <Text variant="mediumPlus" styles={{ root: { fontWeight: 600 } }}>
                {i18n.t("requests:participants:item:readytotravel:title")}
              </Text>
            </div>
            <div>
              <Text>
                {i18n.t("requests:participants:item:readytotravel:description")}
              </Text>
            </div>
          </MessageBar>
        )}
        <Stack
          horizontal={!this.props.mobile}
          grow
          styles={{ root: { maxWidth: "600px" } }}
          tokens={{ childrenGap: 10 }}
        >
          <StackItem grow styles={{ root: { maxWidth: "250px" } }}>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.visa}
              label={i18n.t("requests:participants:item:visa")}
              content={participant.visa}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:visa:reception")}
              content={participant.visaReception}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:visa:expiration")}
              content={participant.visaExpiration}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:position")}
              content={participant.position}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:type")}
              content={participant.type}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:arrival:estimated")}
              content={participant.arrivalEstimated}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:arrival:real")}
              content={participant.arrivalReal}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:arrival:type")}
              content={participant.arrivalType}
            />
          </StackItem>
          <StackItem grow>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:status")}
              content={
                participant.status
                  ? i18n.t("global:active")
                  : i18n.t("global:inactive")
              }
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:gender")}
              content={
                participant.gender && participant.gender !== ""
                  ? i18n.t(
                      `requests:participants:item:gender:${participant.gender.toLowerCase()}`
                    )
                  : ""
              }
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:birthdate")}
              content={participant.birthDate}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:country")}
              content={
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <Text className={classNames.countryText}>
                    {participant.country}
                  </Text>
                  <CountryIcon
                    {...this.props}
                    styles={undefined}
                    country={participant.country}
                    style={{ margin: "-2px 5px" }}
                  />
                </Stack>
              }
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:caq")}
              content={participant.caq}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:departure:estimated")}
              content={participant.departureEstimated}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:departure:real")}
              content={participant.departureReal}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("requests:participants:item:departure:type")}
              content={participant.departureType}
            />
          </StackItem>
        </Stack>
      </>
    );
  }
}
