import {
  CommandBarButton,
  MessageBar,
  MessageBarType,
  Panel,
  PanelType,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import GridViewDialog from "src/components/GridView/GridViewDialog";
import { PanelEditorButtons } from "src/components/PanelEditorButtons";
import { IEnterpriseContact } from "src/models/Enterprises/IEnterpriseContact";
import { objectId } from "src/models/IObjectReference";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import i18n from "src/services/i18n";
import { ContactModificationForm } from "../ContactModificationForm";
import { ContactModificationFormComponent } from "../ContactModificationForm/ContactModificationForm.base";
import { ContactsModificationList } from "../ContactsModificationList";
import { getContactModificationPanelClassNames } from "./ContactModificationPanel.styles";
import {
  IContactModificationPanelProps,
  IContactModificationPanelState,
} from "./ContactModificationPanel.types";

export class ContactModificationPanelComponent extends React.Component<
  IContactModificationPanelProps,
  IContactModificationPanelState
> {
  private _form: React.RefObject<ContactModificationFormComponent>;

  constructor(props: IContactModificationPanelProps) {
    super(props);
    this._form = React.createRef();
    this.onAccept = this.onAccept.bind(this);
    this.state = {
      edition: [],
      error: undefined,
      confirming: false,
    };
  }

  private onDismiss = (force?: boolean): void => {
    if (this.state.contact) {
      this.setState({
        contact: undefined,
        error: undefined,
        confirming: false,
      });
    } else if ((force ?? false) || !this.state.confirming) {
      this.setState({
        edition: [],
        confirming: false,
      });
      this.props.onDismiss();
    }
  };

  private AddNew = (): void => {
    let min: number = 0;
    this.state.edition.forEach((x) => {
      if (x.id <= min) {
        min = x.id;
      }
    });
    min -= 1;

    this.setState({
      contact: {
        id: min,
        key: min.toString(),
        contactId: 0,
        pending: true,
        firstName: "",
        lastName: "",
        name: "",
        title: "",
        email: "",
        phone: "",
        cellphone: "",
        isBillingContact: false,
        isFermeTravelContact: false,
        isNewsletterMember: false,
        isRequestContact: false,
      },
    });
  };

  private onAccept = () => {
    if (this.state.contact) {
      if (this._form.current?.validateContact() ?? false) {
        const obj = this._form.current?.state.edition;
        if (obj === undefined || obj === null) return;
        const contact: IEnterpriseContact = {
          id: obj.id,
          key: obj.key,
          contactId: obj.contactId,
          pending: obj.pending,
          firstName: obj.firstName,
          lastName: obj.lastName,
          name: `${obj.firstName} ${obj.lastName}`,
          title: obj.title,
          email: obj.email,
          phone: obj.phone,
          cellphone: obj.cellphone,
          isRequestContact: obj.isRequestContact,
          isBillingContact: obj.isBillingContact,
          isFermeTravelContact: obj.isFermeTravelContact,
          isNewsletterMember: obj.isNewsletterMember,
        };

        const { edition } = this.state;
        const index = edition.findIndex((x) => x.id === contact.id);

        if (index === -1) {
          edition.push(contact);
        } else {
          edition[index] = contact;
        }

        this.setState({
          edition: edition,
          contact: undefined,
          confirming: false,
        });
      }
    } else {
      const id: number = objectId(this.props.enterprise);
      EnterpriseService.saveContacts(id, this.state.edition)
        .then((_x) => {
          this.onDismiss(true);
        })
        .catch((_x) => {
          this.setState({
            confirming: false,
            error: "enterprises:contacts:error",
          });
        });
    }
  };

  private onDialogDismiss = (result: boolean) => {
    if (result) {
      this.onAccept();
    } else {
      this.setState({ confirming: false });
    }
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getContactModificationPanelClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    return (
      <>
        <GridViewDialog
          onDismiss={(result: boolean) => {
            _this.onDialogDismiss(result);
          }}
          isConfirming={this.state.confirming}
          confirmMessage={i18n.t("enterprises:contacts:confirm")}
        />
        <Panel
          isBlocking={true}
          isFooterAtBottom={true}
          isHiddenOnDismiss={true}
          isOpen={this.props.enabled}
          headerText={i18n.t("enterprises:contacts:panel")}
          className={classNames.root}
          onDismiss={() => _this.onDismiss()}
          type={PanelType.medium}
          onRenderFooter={() => {
            return (
              <PanelEditorButtons
                disabled={false}
                acceptLabel={i18n.t(
                  this.state.contact
                    ? "enterprises:contacts:apply:2"
                    : "enterprises:contacts:apply:1"
                )}
                canAccept={!this.state.error}
                onAccept={() => {
                  if (_this.state.contact) {
                    _this.onAccept();
                  } else {
                    _this.setState({ confirming: true });
                  }
                }}
                onDismiss={() => _this.onDismiss()}
              />
            );
          }}
        >
          {this.state.error && (
            <div>
              <MessageBar
                dismissIconProps={{ iconName: undefined }}
                messageBarType={MessageBarType.error}
              >
                {i18n.t(this.state.error)}
              </MessageBar>
              <div style={{ height: "40px" }}></div>
              <PrimaryButton
                text={"Ok"}
                onClick={() => this.setState({ error: undefined })}
              />
            </div>
          )}
          {!this.state.error && !this.state.contact && (
            <div style={{ float: "right", marginRight: "10px" }}>
              <CommandBarButton
                style={{ padding: "3px" }}
                text={i18n.t("enterprises:contacts:new")}
                iconProps={{ iconName: "AddFriend" }}
                onClick={this.AddNew}
              />
            </div>
          )}
          {!this.state.error && !this.state.contact && (
            <div style={{ clear: "both" }}></div>
          )}
          {!this.state.error && !this.state.contact && (
            <ContactsModificationList
              onUndo={(contact: IEnterpriseContact) => {
                if (contact.contactId < 0) {
                  const contactsEdited = this.state.edition.filter(
                    (x) => Math.abs(x.contactId) !== contact.contactId
                  );
                  contact.contactId = contact.contactId * -1;
                  contactsEdited.push(contact);

                  this.setState({
                    edition: contactsEdited,
                  });
                }

                this.setState({
                  edition: this.state.edition.filter(
                    (x) => x.id !== contact.id
                  ),
                });
              }}
              onContactRemoved={(contact: IEnterpriseContact) => {
                const contactsEdited = this.state.edition.filter(
                  (x) => Math.abs(x.contactId) !== contact.contactId
                );
                contact.contactId = contact.contactId * -1;
                contactsEdited.push(contact);

                this.setState({
                  edition: contactsEdited,
                });
              }}
              onContactSelected={(contact: IEnterpriseContact) =>
                this.setState({ contact: contact })
              }
              {...this.props}
              {...this.state}
            />
          )}
          {!this.state.error && this.state.contact && (
            <ContactModificationForm
              {...this.props}
              {...this.state}
              componentRef={this._form}
            />
          )}
        </Panel>
      </>
    );
  }
}
