import {
  IColumn,
  Icon,
  MessageBar,
  MessageBarType,
  Selection,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { IBillingElement } from "src/models/Billing/IBillingElement";
import { objectId } from "src/models/IObjectReference";
import BillingService from "src/services/Billing/BillingService";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import { BillingDetailsPanelContent } from "../BillingDetailsPanelContent";
import { BillingElementCard } from "../BillingElementCard";
import { getBillingElementsControlClassNames } from "./BillingElementsControl.styles";
import {
  IBillingElementsControlProps,
  IBillingElementsControlState,
} from "./BillingElementsControl.types";

export class BillingElementsControlComponent extends React.Component<
  IBillingElementsControlProps,
  IBillingElementsControlState
> {
  constructor(props: IBillingElementsControlProps) {
    super(props);
    const { route } = props;
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "billing",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "billingDate",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "billing",
        false,
        () => []
      ),
      items: [],
    };
    BillingService.init();
    BillingService.getBillingElements(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      let item: IBillingElement | undefined;
      if (route.location.state) {
        const id = route.location.state
          ? (route.location.state as any).id
          : undefined;
        if (id) {
          const fil = x.filter((x) => x.id === parseInt(id));
          item = fil.length === 0 ? undefined : fil[0];

          if (!item) {
            // get it from direct call
            BillingService.getBillingElement(
              objectId(this.props.enterprise),
              parseInt(id)
            ).then((y) => {
              this.setState({
                items: x,
                billingElement: y,
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
            return;
          }
        }
      }
      this.setState({
        items: x,
        billingElement: item,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private onItemInvoked = (item: IBillingElement) => {
    BillingService.getBillingElement(
      objectId(this.props.enterprise),
      item.id
    ).then((x) =>
      this.setState({
        billingElement: x,
      })
    );
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getBillingElementsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{
            root: {
              paddingLeft: mobile ? "4px" : "10px",
            },
          }}
        >
          <Stack horizontal tokens={{ childrenGap: mobile ? 8 : 20 }}>
            <Text
              variant={"small"}
              style={{
                marginRight: "35px",
                minWidth: "46px",
                wordSpacing: "nowrap",
              }}
            >
              {i18n.t("billing:legend")}
            </Text>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Icon iconName="Script" />
              <Text variant="small">{i18n.t("billing:billtype:standard")}</Text>
            </Stack>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Icon
                iconName="AirplaneSolid"
                styles={{ root: { color: "#2f96b4" } }}
              />
              <Text
                variant="small"
                styles={{
                  root: {
                    color: "#2f96b4",
                  },
                }}
              >
                {i18n.t("billing:billtype:travel")}
              </Text>
            </Stack>
          </Stack>
        </MessageBar>
        <div style={{ height: "10px" }}></div>
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{
            root: {
              paddingLeft: mobile ? "4px" : "10px",
            },
          }}
        >
          {i18n.t("billing:warning:1")}
        </MessageBar>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : mobile || tablet
                    ? "10px"
                    : "20px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={undefined}
                embedded={true}
                summaryColumCount={2}
                onItemInvoked={this.onItemInvoked}
                selectionMode={SelectionMode.single}
                onRenderTile={(
                  item: IBillingElement,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <BillingElementCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onSelected={(item: IBillingElement) => {
                        const isSelected: boolean =
                          selection
                            .getSelection()
                            .filter((x: any) => x.key === item.key).length > 0;
                        ensureSelection(selection, item, true);
                        selection.setKeySelected(item.key, !isSelected, false);
                        callback(selection);
                      }}
                      onClick={(item: IBillingElement) => {
                        BillingService.getBillingElement(
                          objectId(this.props.enterprise),
                          item.id
                        ).then((x) =>
                          this.setState({
                            billingElement: x,
                          })
                        );
                      }}
                    />
                  );
                }}
                onSort={(fieldName: string, desc: boolean) => {
                  BillingService.init();
                  BillingService.getBillingElements(
                    objectId(this.props.enterprise),
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: PreferencesService.asCommittedOptions(
                        GridViewOptionsBuilder.getOptions(
                          this.state.options.mode,
                          fieldName,
                          desc,
                          true
                        ),
                        "billing"
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                options={this.state.options}
                onOptionsChanged={(options: IGridViewOptions) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      options,
                      "billing"
                    ),
                  });
                }}
                columns={[
                  {
                    key: "travel",
                    name: "billing:billtype",
                    minWidth: 38,
                    maxWidth: 66,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost
                          content={i18n.t(
                            item.travel
                              ? "billing:billtype:travel"
                              : "billing:billtype:standard"
                          )}
                        >
                          <Icon
                            iconName={item.travel ? "AirplaneSolid" : "Script"}
                            styles={{
                              root: {
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          />
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "billingNumber",
                    name: "billing:billingnumber",
                    minWidth: 150,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.billingNumber}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.billingNumber}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "billingDate",
                    name: "billing:billingdate",
                    minWidth: 150,
                    maxWidth: 180,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.billingDate}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.billingDate}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "status",
                    name: "billing:status",
                    minWidth: 90,
                    maxWidth: 100,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.status}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.status}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "subtotal",
                    name: "billing:subtotal",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.subtotal}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.subtotal}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "tps",
                    name: "billing:tps",
                    minWidth: 75,
                    maxWidth: 90,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.tps}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.tps}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "tvq",
                    name: "billing:tvq",
                    minWidth: 75,
                    maxWidth: 90,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.tvq}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.tvq}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "total",
                    name: "billing:total",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.total}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.total}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                  {
                    key: "balance",
                    name: "billing:balance",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item: IBillingElement) => {
                      return (
                        <TooltipHost content={item.balance}>
                          <Text
                            onClick={() => this.onItemInvoked(item)}
                            styles={{
                              root: {
                                cursor: "pointer",
                                color: item.travel ? "#2f96b4" : undefined,
                              },
                            }}
                          >
                            {item.balance}
                          </Text>
                        </TooltipHost>
                      );
                    },
                  },
                ]}
                onDataPaging={() => {
                  BillingService.getBillingElements(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  BillingService.init();
                  BillingService.getBillingElements(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: PreferencesService.asCommittedFilters(
                        filters,
                        "billing"
                      ),
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                onQueryFilters={(reset: boolean) => {
                  return new Promise<IQueryFilter[]>((resolve) => {
                    const statuses = [
                      { id: "À payer", name: "billing:status:pending" },
                      {
                        id: "Payée partiellement",
                        name: "billing:status:partial",
                      },
                      { id: "Payée", name: "billing:status:paid" },
                      { id: "Retard", name: "billing:status:late" },
                    ];
                    resolve(
                      PreferencesService.getFiltersOrDefault(
                        "billing",
                        reset,
                        () => [
                          {
                            name: "billing:billingnumber",
                            fieldName: "billingNumber",
                            type: "text",
                            value: "",
                          },
                          {
                            name: "billing:status",
                            fieldName: "status",
                            type: "choice",
                            choices: statuses,
                            value: statuses,
                          },
                          {
                            name: "billing:billingdate",
                            fieldName: "billingDate",
                            type: "date",
                            value: null,
                          },
                        ]
                      )
                    );
                  });
                }}
                onRenderCell={(
                  item?: any,
                  index?: number,
                  column?: IColumn
                ) => {
                  return column === undefined ||
                    column.fieldName === undefined ? (
                    <React.Fragment />
                  ) : (
                    <Text
                      data-automation-id={`nvx:billing:items:${index}:${column.fieldName}`}
                      onClick={() => {
                        BillingService.getBillingElement(
                          objectId(this.props.enterprise),
                          item.id
                        ).then((x) =>
                          this.setState({
                            billingElement: x,
                          })
                        );
                      }}
                      style={{
                        cursor: "pointer",
                        fontWeight: item.late ? "bold" : "normal",
                      }}
                    >
                      {item[column.fieldName]}
                    </Text>
                  );
                }}
                items={this.state.items}
                commands={[
                  {
                    key: "accountstatement",
                    name: i18n.t("billing:accountstatement"),
                    icon: "EntitlementPolicy",
                    confirmTitle: undefined,
                    confirmMessage: (_items: IBillingElement[]) => {
                      return "";
                    },
                    onClick: () => {
                      BillingService.printAccountStatement(
                        objectId(this.props.enterprise)
                      );
                    },
                  },
                  {
                    key: "printbill",
                    name: i18n.t("billing:printbill"),
                    icon: "PDF",
                    selectionRequired: true,
                    confirmTitle: undefined,
                    confirmMessage: (_items: IBillingElement[]) => {
                      return "";
                    },
                    onClick: (
                      selectedItems: IBillingElement[],
                      onCompleted: () => void
                    ) => {
                      selectedItems.length > 0 &&
                        BillingService.printBill(
                          objectId(this.props.enterprise),
                          selectedItems[0].id
                        ).then((_x) => {
                          onCompleted();
                        });
                    },
                  },
                ]}
                isDefaultOptions={(this.state.filters ?? []).length === 0}
                onExport={(filtered: boolean) =>
                  BillingService.exportBills(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filtered ? this.state.filters : []
                  )
                }
                openedItem={this.state.billingElement}
                entityPanelProps={{
                  ...this.props,
                  styles: undefined,
                  isOpen: this.state.billingElement !== undefined,
                  "data-automation-id": "nvx:bills:itempanel",
                  elementName: i18n.t("billing:element"),
                  headerIconName: "Script",
                  title: this.state.billingElement?.billingNumber,
                  commands: [
                    {
                      key: "printbill",
                      name: i18n.t("billing:printbill"),
                      icon: "PDF",
                      confirmTitle: undefined,
                      confirmMessage: (_items: IBillingElement[]) => {
                        return "";
                      },
                      onClick: (
                        ev?:
                          | React.MouseEvent<HTMLElement, MouseEvent>
                          | React.KeyboardEvent<HTMLElement>
                      ) => {
                        ev && ev.preventDefault() && ev.stopPropagation();
                        this.state.billingElement &&
                          BillingService.printBill(
                            objectId(this.props.enterprise),
                            this.state.billingElement.id
                          )
                            .then((_x) => {
                              /* nothing to do */
                            })
                            .catch((_x) => {
                              /* TODO: Show error */
                            });
                      },
                    },
                  ],
                  onDismiss: () => {
                    this.setState({
                      billingElement: undefined,
                    });
                  },
                  children: (
                    <BillingDetailsPanelContent
                      {...this.props}
                      billingElement={this.state.billingElement}
                    />
                  ),
                }}
              />
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }
}
