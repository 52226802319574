import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentCategoriesProps } from "./DocumentCategories.types";
import {
  getStyles,
  IDocumentCategoriesStyleProps,
  IDocumentCategoriesStyles,
} from "./DocumentCategories.styles";
import { DocumentCategoriesComponent } from "./DocumentCategories.base";

/**
 * DocumentCategories
 */
export const DocumentCategories = styled<
  IDocumentCategoriesProps,
  IDocumentCategoriesStyleProps,
  IDocumentCategoriesStyles
>(DocumentCategoriesComponent, getStyles);
