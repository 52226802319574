import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantHistoryCardProps } from "./ParticipantHistoryCard.types";
import {
  getStyles,
  IParticipantHistoryCardStyleProps,
  IParticipantHistoryCardStyles,
} from "./ParticipantHistoryCard.styles";
import { ParticipantHistoryCardComponent } from "./ParticipantHistoryCard.base";

/**
 * ParticipantHistoryCard
 */
export const ParticipantHistoryCard = styled<
  IParticipantHistoryCardProps,
  IParticipantHistoryCardStyleProps,
  IParticipantHistoryCardStyles
>(ParticipantHistoryCardComponent, getStyles);
