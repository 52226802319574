import MobileDetect from "mobile-detect";
import MediaQuery from "react-responsive";

class BrowserService {
  private _service: MobileDetect = new MobileDetect(window.navigator.userAgent);

  public isDesktopOrLaptop(): boolean {
    return !this._service.isPhoneSized(1224);
  }

  public isBigScreen(): boolean {
    return !this._service.isPhoneSized(1824);
  }

  public isMobile(): boolean {
    return this._service.isPhoneSized(767);
  }

  public isTabletOrMobile(): boolean {
    return this._service.isPhoneSized(1224);
  }

  public isPortrait(): boolean {
    return true;
  }

  public isRetina(): boolean {
    return false;
  }

  public phoneOr(
    phoneElement: JSX.Element,
    nonPhoneElement: JSX.Element
  ): JSX.Element {
    return (
      <MediaQuery maxDeviceWidth={767}>
        {(matches) => (matches ? phoneElement : nonPhoneElement)}
      </MediaQuery>
    );
  }

  public init(): void {
    this._service = new MobileDetect(window.navigator.userAgent);
  }

  public phoneAndTabletOr(
    phoneElement: JSX.Element,
    nonPhoneElement: JSX.Element
  ): JSX.Element {
    return (
      <MediaQuery maxDeviceWidth={1224}>
        {(matches) => (matches ? phoneElement : nonPhoneElement)}
      </MediaQuery>
    );
  }
}

export default new BrowserService();
