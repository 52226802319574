import { styled } from "@fluentui/react/lib/Utilities";
import { IContactModificationPanelProps } from "./ContactModificationPanel.types";
import {
  getStyles,
  IContactModificationPanelStyleProps,
  IContactModificationPanelStyles,
} from "./ContactModificationPanel.styles";
import { ContactModificationPanelComponent } from "./ContactModificationPanel.base";

/**
 * ContactModificationPanel description
 */
export const ContactModificationPanel = styled<
  IContactModificationPanelProps,
  IContactModificationPanelStyleProps,
  IContactModificationPanelStyles
>(ContactModificationPanelComponent, getStyles);
