import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantTransfertRequestParticipantCardProps } from "./ParticipantTransfertRequestParticipantCard.types";
import {
  getStyles,
  IParticipantTransfertRequestParticipantCardStyleProps,
  IParticipantTransfertRequestParticipantCardStyles,
} from "./ParticipantTransfertRequestParticipantCard.styles";
import { ParticipantTransfertRequestParticipantCardComponent } from "./ParticipantTransfertRequestParticipantCard.base";

/**
 * ParticipantTransfertRequestParticipantCard
 */
export const ParticipantTransfertRequestParticipantCard = styled<
  IParticipantTransfertRequestParticipantCardProps,
  IParticipantTransfertRequestParticipantCardStyleProps,
  IParticipantTransfertRequestParticipantCardStyles
>(ParticipantTransfertRequestParticipantCardComponent, getStyles);
