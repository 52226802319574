import { ActionButton, DefaultPalette, TooltipHost } from "@fluentui/react";
import { INavButtonProps, INavLink, INavLinkGroup, Nav } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getNavigationMenuClassNames } from "./NavigationMenu.styles";
import {
  INavigationMenuProps,
  INavigationMenuState,
} from "./NavigationMenu.types";

export class NavigationMenuComponent extends React.Component<
  INavigationMenuProps,
  INavigationMenuState
> {
  constructor(props: INavigationMenuProps) {
    super(props);
    this.onLinkClick = this.onLinkClick.bind(this);
  }

  onLinkClick(ev?: React.MouseEvent<HTMLElement>, item?: INavLink): void {
    if (ev) ev.preventDefault();
    if (item && item.url) {
      this.props.route.history.push(item.url);
      if (this.props.onDismiss) {
        this.props.onDismiss();
      }
    }
  }

  private collapsed = (items: INavLink[]): INavLinkGroup[] => {
    return [
      {
        links: items.map((x) => {
          x.name = "";
          return x;
        }),
      },
    ];
  };

  private linkAs = (props: INavButtonProps) => {
    return (props.link?.iconProps?.["aria-label"] ?? "") === "" ? (
      this.linkAsTooltip(
        props,
        this.props.collapsed ? i18n.t("menu:expand") : i18n.t("menu:collapse"),
        true
      )
    ) : this.props.collapsed ?? false ? (
      this.linkAsTooltip(props, props.link?.iconProps?.["aria-label"] ?? "")
    ) : (
      <ActionButton
        key={(props as any).key}
        text={props.link?.name}
        iconProps={props.iconProps}
        onClick={(ev) => props.onClick?.(ev as any)}
        title=""
        label={props.name}
        styles={{
          root: {
            borderLeft:
              props.link !== undefined && this.isSelected(props.link)
                ? `3px solid ${DefaultPalette.themePrimary}`
                : undefined,
          },
          textContainer: {
            fontWeight:
              props.link !== undefined && this.isSelected(props.link)
                ? "bold"
                : "normal",
          },
        }}
      />
    );
  };

  private isSelected = (link: INavLink): boolean => {
    if (this.props.route.location.pathname === "/") {
      return link.url === "/";
    } else {
      return (
        link.url !== "/" &&
        this.props.route.location.pathname.startsWith(link.url)
      );
    }
  };

  private linkAsTooltip = (
    props: INavButtonProps,
    title?: string,
    skipStyling?: boolean
  ) => {
    return (
      <TooltipHost content={title ?? props.title}>
        <ActionButton
          key={(props as any).key}
          text={props.link?.name}
          iconProps={props.iconProps}
          styles={
            skipStyling
              ? undefined
              : {
                  root: {
                    borderLeft:
                      props.link !== undefined && this.isSelected(props.link)
                        ? `3px solid ${DefaultPalette.themePrimary}`
                        : undefined,
                  },
                }
          }
          onClick={(ev) => props.onClick?.(ev as any)}
        />
      </TooltipHost>
    );
  };

  private getSelectedKey = (): string => {
    return document.location.pathname === "/"
      ? "/"
      : document.location.pathname.substring(1).indexOf("/") === -1
      ? document.location.pathname
      : document.location.pathname.substring(
          0,
          document.location.pathname.substring(1).indexOf("/") + 1
        );
  };

  render(): JSX.Element {
    const selectedKey: string = this.getSelectedKey();
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getNavigationMenuClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        <Nav
          styles={subComponentStyles?.nav}
          linkAs={this.linkAs}
          groups={
            this.props.collapsed ?? false
              ? this.collapsed(this.props.groups[0].links)
              : this.props.groups
          }
          onLinkClick={this.onLinkClick}
          selectedKey={selectedKey}
        />
      </div>
    );
  }
}
