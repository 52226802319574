import {
  ChoiceGroup,
  DefaultButton,
  DetailsList,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  Facepile,
  IColumn,
  ICommandBarItemProps,
  IFacepilePersona,
  MessageBar,
  MessageBarType,
  PersonaSize,
  PrimaryButton,
  ResponsiveMode,
  SelectionMode,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import moment from "moment";
import * as React from "react";
import { Redirect } from "react-router";
import { CustomSpinner } from "src/components/CustomSpinner";
import { LabelField } from "src/components/LabelField";
import { MediaQueries } from "src/components/MediaQueries";
import { IPaymentMethod } from "src/models/Enterprises/IPaymentMethod";
import { objectId } from "src/models/IObjectReference";
// import { FlightResultsResponse } from "src/models/Transports/FlightResultsResponse";
import { IFlightCriteria } from "src/models/Transports/IFlightCriteria";
import { IFlightOffer } from "src/models/Transports/IFlightOffer";
import {
  IFlightSegementModel,
  IParticipantNameRecordModel,
  IPassengerNameRecordModel,
} from "src/models/Transports/IPassengerNameRecordModel";
import { NewPaymentBillingSummary } from "src/pages/Billing/NewPaymentBillingSummary";
import { NewPaymentClientInfo } from "src/pages/Billing/NewPaymentClientInfo";
import { NewPaymentMethodSelector } from "src/pages/Billing/NewPaymentMethodSelector";
import { NewPaymentMethodSummary } from "src/pages/Billing/NewPaymentMethodSummary";
import { NewPaymentSecureDialog } from "src/pages/Billing/NewPaymentSecureDialog";
import { NewPaymentMethodControl } from "src/pages/Enterprises/NewPaymentMethodControl";
import { PaymentMethodDeleteMessage } from "src/pages/Enterprises/PaymentMethodDeleteMessage";
import PaymentsService from "src/services/Billing/PaymentsService";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import PaymentMethodsService from "src/services/Enterprises/PaymentMethodsService";
import i18n from "src/services/i18n";
import { TransportFlightBookingService } from "src/services/Transports/TransportFlightBookingService";
import { FlightConfirmationPage } from "../../FlightConfirmation/FlightConfirmationPage";
import { FlightCheckoutErrorPage } from "../FlightCheckoutErrorPage";
import { NewPaymentCommandBar } from "./Controls/NewPaymentCommandBar/NewPaymentCommandBar";
import { getFlightCheckoutPageClassNames } from "./FlightCheckoutPage.styles";
import {
  IFlightCheckoutPageProps,
  IFlightCheckoutPageState,
} from "./FlightCheckoutPage.types";

const flightBillingEnabled = false;
const maxSeatBillable = 12;

export class FlightCheckoutPageComponent extends React.Component<
  IFlightCheckoutPageProps,
  IFlightCheckoutPageState
> {
  constructor(props: IFlightCheckoutPageProps) {
    super(props);

    const routeState = this.props.route.location.state as any;
    const offer: IFlightOffer = routeState?.offer as any;
    this.state = {
      processing: false,
      errors: [],
      reference: "",
      securedialog: false,
      paymenterror: false,
      thankyou: false,
      paying: false,
      billing:
        flightBillingEnabled &&
        (offer.serviceIndex === 1 ||
          /* maxSeatBillable !== 0 &&  */ offer.passengersCount <=
          maxSeatBillable) &&
        (offer.subTotalPrice ?? 0) > 0,
      confirmation: false,
      cardEditing: false,
      authorization: false,
      showMonerisError: false,
    };
    EnterpriseService.getEnterprise(props.enterprise).then((z) => {
      PaymentMethodsService.getPaymentMethods(
        objectId(this.props.enterprise),
        "createdOn",
        true,
        [
          {
            name: "VisibleFermeVoyage",
            fieldName: "VisibleFermeVoyage",
            type: "text",
            value: "true",
          },
        ],
        undefined
      ).then((y) => {
        const methods = y.concat([
          {
            key: "custom",
            id: -1,
            card: "",
            name: "",
            expirationMonth: 1,
            expirationYear: new Date().getFullYear() + 1,
            cvc: "",
            enabled: true,
            createdBy: "",
            createdOn: "",
            modifiedOn: "",
            cardType: "Visa",
            transportAuthorized: true,
            billingAuthorized: true,
            saveOnVaultDB: false,
          },
        ]);
        this.setState({ paymentMethods: methods, enterprise: z });
      }).catch((error) => {
        if (error.message === "FatalErrorFetchPaymentMethods")
          this.setState({
            showMonerisError: true
          });
      });
    });
  }

  private onDismiss = (): void => {
    const { route } = this.props;
    const { confirmation } = this.state;
    if (confirmation) {
      this.setState({ confirmation: false });
    } else {
      route.history.push("/transports/billetterie");
    }
  };

  private getPrices = (offer: IFlightOffer): any[] => {
    return [
      {
        desc: `${offer.passengersCount} x ${i18n.t(
          `transports:flightbooking:adult${offer.passengersCount > 1 ? "s" : ""
          }`
        )}`,
        value: i18n.currency(offer.subTotalPrice),
      },
    ]

      .concat(
        offer.charges?.map((x) => ({
          desc: x.name,
          value: i18n.currency(x.amount),
        })) ?? []
      )
      .concat(
        offer.bookingFeeAmount
          ? [
            {
              desc: "Frais de billetterie",
              value: i18n.currency(
                offer.bookingFeeAmount * offer.passengersCount
              ),
            },
          ]
          : []
      )
      .concat([
        {
          desc: i18n.t("transports:flightbooking:taxes"),
          value: offer.totalTaxAmount
            ? offer.totalTaxAmount <= 0
              ? i18n.t("transports:flightbooking:taxes:included")
              : i18n.currency(offer.totalTaxAmount)
            : i18n.t("transports:flightbooking:taxes:included"),
        },
        {
          desc: "Total",
          value: i18n.currency(offer.totalPrice),
        },
      ]);
  };

  private onAuthorize = () =>
    this.setState({
      authorization: true,
    });

  private onSubmit = (): void => {
    const { billing, selectedPaymentMethod } = this.state;
    const { enterprise } = this.props;
    if (this.state.processing) {
      return;
    }
    const routeState = this.props.route.location.state as any;
    const offer: IFlightOffer = routeState.offer as any;
    const offerIndex: number = routeState.offerIndex as any;
    const serviceIndex: number = routeState.serviceIndex as any;
    const errors: string[] = [];
    if (!offer) {
      (errors as any).offer = "error";
    }

    if (this.state.billing && !selectedPaymentMethod) {
      (errors as any).paymentmethod = "error";
    }

    if (errors.length > 0 || Object.keys(errors).length > 0) {
      this.setState({
        errors: errors,
        authorization: false,
        confirmation: false,
        processing: false,
      });
      return;
    }

    const reason: string = routeState.reason as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    const requestId: number | undefined = routeState.requestId as any;
    const participants: IParticipantNameRecordModel[] =
      routeState.participants as any;
    const model: IPassengerNameRecordModel = {
      index: offerIndex,
      id: offer.id,
      requestDate: criteria.searchDates?.start ?? new Date(),
      requestId: requestId ?? -1,
      serviceIndex: serviceIndex,
      arrival: criteria.arrival,
      origin: criteria.from ?? "",
      destination: criteria.to ?? "",
      enterpriseId: objectId(enterprise),
      immediate: billing,
      totalPayment: offer.totalPrice,
      inlinePaymentMethod:
        selectedPaymentMethod === undefined || selectedPaymentMethod.id === -1
          ? this.state.selectedPaymentMethod
          : undefined,
      paymentMethodId:
        !selectedPaymentMethod || selectedPaymentMethod.id === -1
          ? undefined
          : selectedPaymentMethod.id,
      cvc: selectedPaymentMethod?.cvc ?? "",
      participants: participants.filter((x) => x.selected),
      reason: reason,
      segments: offer.schedules.map((x) => {
        const segment: IFlightSegementModel = {
          id: x.id,
          marketingCarrier: x.carrier,
          operatingCarrier: x.operating,
          marketingFlightNumber: x.marketingFlightNumber,
          flightNumber: x.flightNumber,
          arrival: moment(x.arrivalDate)
            .add("day", x.arrivalAdjustement ?? 0)
            .add(moment.duration(x.arrival))
            .toDate(),
          departure: moment(x.departureDate)
            .add("day", x.departureAjustment ?? 0)
            .add(moment.duration(x.departure))
            .toDate(),
          destination: x.to,
          origin: x.from,
        };
        return segment;
      }),
    };

    this.setState({
      processing: true,
      authorization: false,
    });

    TransportFlightBookingService.bookAsync(model)
      .then((x) => {
        if (x !== undefined && x.success) {
          this.setState({
            thankyou: true,
            authorization: false,
            processing: false,
            paymenterror: false,
            results: x as any,
            reference: x.referenceNumber,
            errors: [],
          });
        } else {
          this.setState({
            processing: false,
            authorization: false,
            paymenterror: true,
            reference: "",
            results: {
              code: "FLIGHT",
              payment: "0.00",
              reservationId: 0,
              date: moment(new Date()).format("yyyy-MM-DD"),
              transaction: i18n.t("messages:assisted:newdeparture:unknown"),
              success: false,
              error: x.error,
              participants: x.participants,
              key: "error",
              reference: "",
              authorization: "",
            },
          });
        }
      })
      .catch((_x) => {
        this.setState({
          processing: false,
          authorization: false,
          paymenterror: true,
          results: {
            code: "FLIGHT",
            payment: "0.00",
            error: "error",
            reservationId: 0,
            date: moment(new Date()).format("yyyy-MM-DD"),
            transaction: i18n.t("messages:assisted:newdeparture:unknown"),
            success: false,
            key: "error",
            reference: "",
            authorization: "",
          },
        });
      });
  };

  render(): JSX.Element {
    const { styles, mobile, tablet, enterprise } = this.props;
    const [classNames, subComponentStyles] = getFlightCheckoutPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    const subtotal: number = 0;
    const tps: number = 0;
    const tvq: number = 0;
    const total: number = 0;
    const routeState = this.props.route.location.state as any;
    if (!routeState) return <Redirect to={"/transports/billetterie"} />;
    const offer: IFlightOffer = routeState.offer as any;
    // const data: FlightResultsResponse = routeState.data as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    // const offerIndex: number = routeState.offerIndex as any;
    // const reason: string = routeState.reason as any;
    const serviceIndex: number = routeState.serviceIndex as any;
    const participants: IParticipantNameRecordModel[] =
      routeState.participants as any;
    if (!offer) return <Redirect to={"/transports/billetterie"} />;
    const authorization = this.state.authorization;
    if (this.state.paying) {
      return (
        <div
          style={{
            marginLeft: mobile ? "5px" : "50px",
            marginTop: mobile ? "30px" : "40px",
          }}
        >
          <div style={{ height: mobile ? "40px" : "70px" }}></div>
          <CustomSpinner
            {...this.props}
            styles={undefined}
            text={i18n.t("transports:flights:billingmethod:paying")}
          />
        </div>
      );
    } else if (this.state.paymenterror) {
      return (
        <FlightCheckoutErrorPage
          {...this.props}
          styles={undefined}
          label={i18n.t("billing:payments:reservationerror:title")}
          billingEnabled={this.state.billing}
          subtotal={subtotal}
          tps={tps}
          tvq={tvq}
          total={total}
          card={this.state.selectedPaymentMethod?.card ?? ""}
          cardType={this.state.selectedPaymentMethod?.cardType ?? "Visa"}
          results={this.state.results}
          onRetry={() => {
            this.setState({
              thankyou: false,
              paymenterror: false,
              results: undefined,
              paying: false,
              reference: "",
            });
          }}
        />
      );
    } else if (this.state.thankyou) {
      return (
        <FlightConfirmationPage
          {...this.props}
          styles={undefined}
          reference={this.state.reference}
          subtotal={subtotal}
          tps={tps}
          tvq={tvq}
          total={total}
          card={this.state.selectedPaymentMethod?.card ?? ""}
          cardType={this.state.selectedPaymentMethod?.cardType ?? "Visa"}
          results={this.state.results}
        />
      );
    }

    const back: ICommandBarItemProps = {
      key: "back",
      text: i18n.t("transports:flights:back"),
      iconProps: {
        iconName: "NavigateForwardMirrored",
      },
      onClick: () =>
        this.props.route.history.push(
          "/transports/billetterie/passagers",
          this.props.route.location.state
        ),
      disabled: this.state.paying,
      buttonStyles: {
        root: {
          backgroundColor: "transparent",
        },
      },
    };

    return (
      <>
        <Dialog
          hidden={!authorization}
          dialogContentProps={{
            title: "Confirmation",
          }}
          onDismiss={() => this.setState({ authorization: false })}
          modalProps={{
            isBlocking: true,
            isDarkOverlay: true,
            responsiveMode: ResponsiveMode.large,
          }}
        >

          <Dialog
            hidden={!this.state.showMonerisError}
            dialogContentProps={{
              title: i18n.t("dialog:error:title"),
              subText: i18n.t("dialog:error:moneris"),
            }}
            modalProps={{
              isBlocking: true,
              styles: { main: { maxWidth: 450 } },
            }} />
          <DialogContent>
            <div style={{ padding: "8px" }}>
              <Text
                styles={{
                  root: {
                    fontWeight: 600,
                  },
                }}
              >
                {i18n.t("transports:flights:confirmation:message")}
              </Text>
            </div>
          </DialogContent>
          <DialogFooter>
            <Stack tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                text={i18n.t("transports:flights:confirmation:buttons:submit")}
                onClick={this.onSubmit}
              />
              <DefaultButton
                text={i18n.t("transports:flights:confirmation:buttons:cancel")}
                onClick={() => this.setState({ authorization: false })}
              />
            </Stack>
          </DialogFooter>
        </Dialog>
        <div className={classNames.root}>
          <Stack
            horizontal={!mobile && !tablet}
            style={{ width: "100%", padding: mobile ? "10px" : "0px 5px" }}
            tokens={{ childrenGap: 10 }}
          >
            <div
              style={{
                backgroundColor: "#fff",
                margin: "10px",
                padding: "10px",
                boxShadow:
                  "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
                cursor: "pointer",
                borderRadius: "2px",
                transition: "box-shadow 0.5s ease 0s",
              }}
            >
              {mobile && (
                <NewPaymentCommandBar
                  {...this.props}
                  styles={subComponentStyles?.commandBar}
                  confirmation={this.state.confirmation}
                  onDismiss={this.onDismiss}
                  onSubmit={this.onAuthorize}
                  submitLabel={i18n.t(
                    this.state.billing
                      ? "transports:flightbooking:paybutton"
                      : "transports:flightbooking:reservebutton"
                  )}
                  submitDisabled={
                    this.state.processing ||
                    (this.state.billing &&
                      this.state.selectedPaymentMethod === undefined)
                  }
                  dismissDisabled={this.state.processing}
                  commands={[back]}
                />
              )}
              {mobile &&
                this.state.confirmation &&
                this.state.selectedPaymentMethod && (
                  <NewPaymentMethodSummary
                    {...this.props}
                    styles={undefined}
                    paymentMethod={this.state.selectedPaymentMethod}
                    payment={total}
                  />
                )}
              <div style={{ minWidth: "265px" }}>
                {!mobile && (
                  <NewPaymentCommandBar
                    {...this.props}
                    confirmation={this.state.confirmation}
                    styles={subComponentStyles?.commandBar}
                    confirmationLabel={i18n.t("global:close")}
                    submitLabel={i18n.t(
                      this.state.billing
                        ? "transports:flightbooking:paybutton"
                        : "transports:flightbooking:reservebutton"
                    )}
                    onDismiss={this.onDismiss}
                    onSubmit={this.onAuthorize}
                    submitDisabled={
                      this.state.processing ||
                      (this.state.billing &&
                        this.state.selectedPaymentMethod === undefined)
                    }
                    dismissDisabled={this.state.processing}
                    commands={[back]}
                  />
                )}
                {!tablet && this.state.enterprise && (
                  <NewPaymentClientInfo
                    {...this.props}
                    styles={undefined}
                    enterprise={this.state.enterprise}
                    phone={false}
                    delayed={!this.state.billing}
                  />
                )}

                {tablet && (
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    {this.state.enterprise && (
                      <NewPaymentClientInfo
                        {...this.props}
                        styles={undefined}
                        enterprise={this.state.enterprise}
                        phone={false}
                        delayed={!this.state.billing}
                      />
                    )}
                    <div>
                      {!mobile && <div style={{ height: "48px" }}></div>}
                      <LabelField
                        {...this.props}
                        styles={undefined}
                        label={i18n.t("billing:payments:confirmation:date")}
                        content={moment(new Date())
                          .locale(i18n.getLanguage())
                          .format("LL")}
                      />
                    </div>
                  </Stack>
                )}
              </div>
              {!tablet && (
                <div>
                  {!mobile && <div style={{ height: "48px" }}></div>}
                  <LabelField
                    {...this.props}
                    styles={{ root: { width: "170px" } }}
                    label={i18n.t(
                      this.state.billing
                        ? "transports:flights:billingdate"
                        : "transports:flights:paymentdate"
                    )}
                    content={moment(new Date())
                      .locale(i18n.getLanguage())
                      .format("LL")}
                  />
                </div>
              )}
              {flightBillingEnabled && serviceIndex === 0 && (
                <div>
                  {(offer.totalPrice ?? 0) <= 0 ? (
                    <MessageBar messageBarType={MessageBarType.info}>
                      Le paiement en ligne n'est pas disponible pour ce vol.
                    </MessageBar>
                  ) : criteria.passengers > maxSeatBillable ? (
                    <MessageBar messageBarType={MessageBarType.info}>
                      Le paiement en ligne n'est pas disponible pour les
                      réservation de plus de 12 passagers.
                    </MessageBar>
                  ) : (
                    <ChoiceGroup
                      onChange={(_ev, option) =>
                        option !== undefined &&
                        this.setState({
                          billing: option.key === "billing",
                          selectedPaymentMethod: undefined,
                        })
                      }
                      selectedKey={
                        this.state.billing ? "billing" : "no-billing"
                      }
                      options={[
                        {
                          key: "billing",
                          text: "Payez maintenant",
                        },
                        {
                          key: "no-billing",
                          text: "Facturation ultérieure",
                        },
                      ]}
                    />
                  )}
                </div>
              )}
            </div>
            {!this.state.confirmation && (
              <div style={{ width: "100%" }}>
                <Stack
                  horizontal={!mobile}
                  horizontalAlign={"stretch"}
                  tokens={{ childrenGap: 10 }}
                  styles={{ root: { width: "100%" } }}
                  grow
                >
                  <StackItem grow styles={{ root: { width: "50%" } }}>
                    <h4>{i18n.t("transports:flights:summary")}</h4>
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("transports:flights:origindest:label")}
                      content={`${criteria.from} - ${criteria.to}`}
                    />
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("transports:flights:traveldate")}
                      content={moment(offer.departureDate)
                        .locale(i18n.getLanguage())
                        .format("LL")}
                    />
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("transports:requests:passengers")}
                      content={
                        criteria.passengers > 1
                          ? `${criteria.passengers} ${i18n.t(
                            "transports:flights:passengerscount:label"
                          )}s`
                          : `${criteria.passengers} ${i18n.t(
                            "transports:flights:passengerscount:label"
                          )}`
                      }
                    />
                    <Facepile
                      personaSize={PersonaSize.size32}
                      personas={participants
                        .filter((x) => x.selected)
                        .map((x) => {
                          const item: IFacepilePersona = {
                            personaName: `${x.lastName.toUpperCase()}, ${x.firstName
                              }`,
                            data: "50%",
                          };
                          return item;
                        })}
                      overflowPersonas={[]}
                      ariaDescription={i18n.t("messages:commandbar:aria")}
                      ariaLabel={i18n.t(
                        "transports:flights:summary:participants"
                      )}
                    />
                  </StackItem>
                  {this.state.processing ? (
                    <CustomSpinner
                      {...this.props}
                      styles={subComponentStyles?.spinner}
                      text={i18n.t("billing:payments:processing")}
                    />
                  ) : (
                    <StackItem
                      grow
                      align={mobile ? undefined : "end"}
                      styles={{ root: { width: mobile ? "100%" : "50%" } }}
                    >
                      <DetailsList
                        selectionMode={SelectionMode.none}
                        items={this.getPrices(offer)}
                        columns={[
                          {
                            key: "desc",
                            fieldName: "desc",
                            name: "",
                            minWidth: 170,
                            onRender: (
                              item?: any,
                              _index?: number,
                              _column?: IColumn
                            ) => {
                              return (
                                <Text
                                  styles={{
                                    root: {
                                      fontWeight:
                                        item.desc === "Total" ? 600 : "normal",
                                    },
                                  }}
                                >
                                  {item.desc}
                                </Text>
                              );
                            },
                          },
                          {
                            key: "value",
                            fieldName: "value",
                            name: i18n.t("billing:payments:total"),
                            minWidth: 100,
                            styles: {
                              root: { textAlign: "right" },
                            },
                            onRender: (
                              item?: any,
                              _index?: number,
                              _column?: IColumn
                            ) => {
                              return (
                                <Text
                                  styles={{
                                    root: {
                                      textAlign: "right",
                                      fontWeight:
                                        item.desc === "Total" ? 600 : "normal",
                                    },
                                  }}
                                >
                                  {item.value}
                                </Text>
                              );
                            },
                          },
                        ]}
                      />
                      <div style={{ height: "20px", clear: "both" }}></div>
                      <MessageBar messageBarType={MessageBarType.info}>
                        {i18n.t("transports:flights:currencywarning")}
                      </MessageBar>
                      <div style={{ height: "20px", clear: "both" }}></div>
                    </StackItem>
                  )}
                </Stack>
              </div>
            )}
            {!mobile &&
              this.state.billing &&
              this.state.confirmation &&
              this.state.selectedPaymentMethod && (
                <NewPaymentMethodSummary
                  {...this.props}
                  styles={undefined}
                  paymentMethod={this.state.selectedPaymentMethod}
                  payment={total}
                />
              )}
          </Stack>
          {this.state.billing && !this.state.confirmation && (
            <NewPaymentMethodSelector
              {...this.props}
              {...this.state}
              styles={undefined}
              onDelete={(item: IPaymentMethod) => {
                if (item.id < 0) return;
                this.setState({ deletingCard: item });
              }}
              onSelected={(item: IPaymentMethod) => {
                const { paymentMethods } = this.state;
                if (
                  paymentMethods &&
                  this.state.selectedPaymentMethod &&
                  this.state.selectedPaymentMethod.id !== -1
                ) {
                  paymentMethods.forEach((x) => {
                    if (!(x.secured ?? false)) {
                      x.cvc = undefined;
                    }
                  });
                }

                if (item.id === -1) {
                  this.setState({
                    selectedPaymentMethod: item,
                    paymentMethods: paymentMethods,
                    cardEditing: true,
                    securedialog: false,
                  });
                } else {
                  let securedialog: boolean = false;
                  if (!item.secured) {
                    securedialog = !item.cvc || item.cvc.length < 3;
                  }
                  this.setState({
                    selectedPaymentMethod: item,
                    paymentMethods: paymentMethods,
                    securedialog: securedialog,
                  });
                }
              }}
            />
          )}
          {this.state.confirmation && (
            <NewPaymentBillingSummary
              {...this.props}
              styles={undefined}
              subtotal={subtotal}
              tps={tps}
              tvq={tvq}
              total={total}
            />
          )}
          {this.state.billing && (
            <Dialog
              hidden={this.state.deletingCard === undefined}
              dialogContentProps={{
                type: DialogType.normal,
                title: i18n.t("enterprises:paymentmethods:deletetitle"),
                closeButtonAriaLabel: i18n.t("global:close"),
                subText: undefined,
              }}
              modalProps={{
                isBlocking: true,
                styles: { main: { maxWidth: 450 } },
              }}
            >
              <PaymentMethodDeleteMessage
                {...this.props}
                styles={undefined}
                items={this.state.deletingCard ? [this.state.deletingCard] : []}
                multiple={false}
                lastcard={(this.state.paymentMethods ?? []).length - 1 <= 0}
              />
              <DialogFooter>
                <PrimaryButton
                  data-automation-id="nvx:grid:confirm:yes"
                  onClick={() =>
                    this.state.deletingCard &&
                    PaymentMethodsService.deletePaymentMethods(
                      objectId(enterprise),
                      [this.state.deletingCard]
                    ).then((_x) => {
                      PaymentMethodsService.getPaymentMethods(
                        objectId(this.props.enterprise),
                        "createdOn",
                        true,
                        [
                          {
                            name: "VisibleFermeVoyage",
                            fieldName: "VisibleFermeVoyage",
                            type: "text",
                            value: "true",
                          },
                        ],
                        undefined
                      ).then((y) => {
                        const methods = y.concat([
                          {
                            key: "custom",
                            id: -1,
                            card: "",
                            name: "",
                            expirationMonth: 0,
                            expirationYear: new Date().getFullYear() + 1,
                            cvc: "",
                            enabled: true,
                            createdBy: "",
                            createdOn: "",
                            modifiedOn: "",
                            cardType: "Visa",
                            transportAuthorized: true,
                            billingAuthorized: true,
                            saveOnVaultDB: false,
                          },
                        ]);
                        this.setState({
                          deletingCard: undefined,
                          paymentMethods: methods,
                        });
                      }).catch((error) => {
                        if (error.message === "FatalErrorFetchPaymentMethods")
                          this.setState({
                            showMonerisError: true
                          });
                      });
                    })
                  }
                  text={i18n.t("global:yes")}
                />
                <DefaultButton
                  data-automation-id="nvx:grid:confirm:no"
                  onClick={() => this.setState({ deletingCard: undefined })}
                  text={i18n.t("global:no")}
                />
              </DialogFooter>
            </Dialog>
          )}
          {this.state.billing && (
            <Dialog
              hidden={!this.state.cardEditing}
              modalProps={{
                isBlocking: true,
                isDarkOverlay: true,
              }}
              onDismiss={() => {
                this.setState({
                  cardEditing: false,
                  selectedPaymentMethod: undefined, // TODO: validate and keep if good
                });
              }}
              title={i18n.t("enterprises:paymentmethods:new:header")}
              styles={{
                main: {
                  padding: "0",
                  width: "80vw",
                  maxWidth: "80vw",
                  selectors: {
                    "@media (min-width: 480px)": {
                      width: "80vw",
                      maxWidth: "80vw",
                    },
                    [MediaQueries.mobile]: {
                      width: "100vw",
                      maxWidth: "100vw",
                    },
                  },
                },
              }}
            >
              <DialogContent titleProps={{ style: { display: "none" } }}>
                <NewPaymentMethodControl
                  {...this.props}
                  styles={undefined}
                  dialog={true}
                  onSave={(item: IPaymentMethod) => {
                    if (!this.state.paymentMethods) return;
                    const methods = this.state.paymentMethods;
                    const custom = methods.filter((x) => x.id === -1)[0];
                    custom.key = "custom";
                    custom.id = -1;
                    custom.card = PaymentsService.formatCreditCard(item.card);
                    custom.name = item.name;
                    custom.expirationMonth = item.expirationMonth;
                    custom.expirationYear = item.expirationYear;
                    custom.cvc = item.cvc;
                    custom.enabled = true;
                    custom.createdBy = "";
                    custom.createdOn = "";
                    custom.modifiedOn = "";
                    custom.cardType = item.cardType;
                    custom.transportAuthorized = item.transportAuthorized;
                    custom.billingAuthorized = item.billingAuthorized;

                    this.setState({
                      paymentMethods: methods,
                      selectedPaymentMethod: custom,
                      cardEditing: false,
                    });
                  }}
                  onDismiss={() => {
                    this.setState({
                      cardEditing: false,
                      selectedPaymentMethod: undefined, // TODO: validate and keep if good
                    });
                  }}
                />
              </DialogContent>
            </Dialog>
          )}
          {this.state.billing && (
            <NewPaymentSecureDialog
              {...this.props}
              styles={undefined}
              card={this.state.selectedPaymentMethod?.card ?? ""}
              isOpen={this.state.securedialog}
              onSubmit={(cvc: string) => {
                const { selectedPaymentMethod } = this.state;
                if (selectedPaymentMethod) {
                  selectedPaymentMethod.cvc = cvc;
                  this.setState({
                    securedialog: false,
                    selectedPaymentMethod: selectedPaymentMethod,
                  });
                }
              }}
              onDismiss={() => {
                const { selectedPaymentMethod } = this.state;
                if (
                  selectedPaymentMethod &&
                  !(selectedPaymentMethod.secured ?? false)
                ) {
                  selectedPaymentMethod.cvc = undefined;
                }
                this.setState({
                  securedialog: false,
                  selectedPaymentMethod: selectedPaymentMethod,
                });
              }}
            />
          )}
        </div>
      </>
    );
  }
}
