import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IBillingControlStyleProps,
  IBillingControlStyles,
} from "../BillingControl";

export interface IBillingPageSubComponentStyles {
  control?: IStyleFunctionOrObject<
    IBillingControlStyleProps,
    IBillingControlStyles
  >;
}

export interface IBillingPageStyles
  extends IAmigoBaseStyles<IBillingPageSubComponentStyles> {}

export interface IBillingPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getBillingPageClassNames = (
  styles?: IStyleFunctionOrObject<IBillingPageStyleProps, IBillingPageStyles>,
  props?: IBillingPageStyleProps
): [
  IProcessedStyleSet<IBillingPageStyles>,
  IBillingPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IBillingPageStyleProps,
  IBillingPageStyles
> = (props: IBillingPageStyleProps): IBillingPageStyles => {
  const pageStyle = props.tokens.defaultPageRootStyle as any;
  return {
    root: {
      ...pageStyle,
      overflowX: "hidden",
    },
  };
};
