import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseAddressProps } from "./EnterpriseAddress.types";
import {
  getStyles,
  IEnterpriseAddressStyleProps,
  IEnterpriseAddressStyles,
} from "./EnterpriseAddress.styles";
import { EnterpriseAddressComponent } from "./EnterpriseAddress.base";

/**
 * EnterpriseAddress description
 */
export const EnterpriseAddress = styled<
  IEnterpriseAddressProps,
  IEnterpriseAddressStyleProps,
  IEnterpriseAddressStyles
>(EnterpriseAddressComponent, getStyles);
