import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";
import { GridViewMode, IGridViewOptions } from "./GridView.types";
import { MediaQueries } from "../MediaQueries";

export interface IGridViewSubComponentStyles {}

export interface IGridViewStyles
  extends IComponentBaseStyles<IGridViewSubComponentStyles> {
  gridContainer?: IStyle;
  infiniteScroll?: IStyle;
  gridScrolling?: IStyle;
  emptyMessageContainer?: IStyle;
  emptyMessage?: IStyle;
}

export interface IGridViewStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  viewPortOffset?: number;
  options?: IGridViewOptions;
  embedded?: boolean;
  scrollableTargetId?: string;
}

export const getGridViewClassNames = (
  styles?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>,
  props?: IGridViewStyleProps
): [
  IProcessedStyleSet<IGridViewStyles>,
  IGridViewSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<IGridViewStyleProps, IGridViewStyles> = (
  props: IGridViewStyleProps
): IGridViewStyles => {
  const height =
    (props.embedded ?? false) || props.scrollableTargetId !== undefined
      ? "100%"
      : props.mobile
      ? `calc(-${0 + 126}px + 100vh)`
      : `calc(-${(props.viewPortOffset ?? 0) + 126}px + 100vh)`;
  return {
    root: [
      props.embedded ?? false
        ? "ms-GridView"
        : "ms-GridView ms-GridView-Complete",
      (props.options?.mode ?? "") === GridViewMode.tiles
        ? props.tokens.gridViewTilesStyle
        : props.tokens.gridViewListsStyle,
    ],
    gridContainer: [
      "ms-GridViewContainer",
      {
        height: height,
        overflowY: "auto",
        overflowX: "hidden",
      },
      (props.options?.mode ?? "") === GridViewMode.tiles && {
        boxShadow:
          "inset rgba(0, 0, 0, 0.133) 0 15px 5px -16px, rgba(0, 0, 0, 0.11) 0 7px 3px -8px",
      },
    ],
    gridScrolling: [
      "ms-GridViewScroll",
      {
        height: "auto",
        overflowY: "auto",
        overflowX: "hidden",
      },
    ],
    emptyMessageContainer: {
      width: "100%",
      textAlign: "center",
      marginTop: "60px",
      selectors: {
        [MediaQueries.mobile]: {
          marginTop: "40px",
        },
      },
    },
    emptyMessage: {
      fontStyle: "italic",
    },
  };
};
