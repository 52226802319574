import axios from "axios";
import { IDepartureRequestDataModel } from "src/models/Messages/IDepartureRequestDataModel";
import AuthService from "../AuthService";
import i18n from "../i18n";
import { IDepartureRequestParticipant } from "src/models/Messages/IDepartureRequestParticipant";
import {
  INewMessageModel,
  IMessageSummary,
} from "src/models/Messages/IMessageSummary";
import { INewDepartureRequest } from "src/models/Messages/INewDepartureRequest";
import { IDepartureRequestFlight } from "src/models/Messages/IDepartureRequestFlight";
import { IDepartureRequest } from "src/models/Messages/IDepartureRequest";
export class DepartureRequestService {
  public static async getDataModel(
    enterpriseId: number
  ): Promise<IDepartureRequestDataModel> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/model`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public static async getDepartureRequest(
    enterpriseId: number,
    messageItemId: number
  ): Promise<IDepartureRequest> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/${messageItemId}/assisted/departurerequest`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static getDepartureRequestEditModel(
    enterpriseId: number,
    messageItemId: number
  ): Promise<[IDepartureRequest, IDepartureRequestDataModel]> {
    return axios
      .all([
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/${messageItemId}/assisted/departurerequest`,
          {},
          AuthService.getAuthHeaderWithLanguage()
        ),
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/model`,
          {},
          AuthService.getAuthHeader()
        ),
      ])
      .then((x: any) => {
        return [x[0].data, x[1].data];
      });
  }

  public static async getParticipants(
    enterpriseId: number,
    requestId: number,
    changing: boolean
  ): Promise<IDepartureRequestParticipant[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/${requestId}/participants`,
      {},
      {
        headers: {
          Authorization: "Bearer " + AuthService.getUserInfo()?.token,
          "X-Message-Language": i18n.getLanguage(),
          "X-Entity-Changing": changing ? "true" : "false",
        },
        withCredentials: false,
      }
    );
    return x.data as any;
  }

  public static getRequestedDataModel(
    enterpriseId: number,
    requestId: number,
    requestNumber: string,
    city: string,
    requestDate: Date,
    changing: boolean
  ): Promise<[IDepartureRequestParticipant[], IDepartureRequestFlight[]]> {
    return axios
      .all([
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/${requestId}/participants`,
          {},
          {
            headers: {
              Authorization: "Bearer " + AuthService.getUserInfo()?.token,
              "X-Message-Language": i18n.getLanguage(),
              "X-Entity-Changing": changing ? "true" : "false",
            },
            withCredentials: false,
          }
        ),
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/${requestId}/flights`,
          {
            enterpriseId: enterpriseId,
            requestId: requestId,
            requestNumber: requestNumber,
            requestDate: requestDate,
            city: city,
          },
          AuthService.getAuthHeaderWithLanguage()
        ),
      ])
      .then((x) => {
        return [x[0].data as any, x[1].data as any];
      });
  }

  public static async getFlights(
    enterpriseId: number,
    requestId: number,
    requestNumber: string,
    city: string,
    requestDate: Date
  ): Promise<IDepartureRequestFlight[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests/${requestId}/flights`,
      {
        enterpriseId: enterpriseId,
        requestId: requestId,
        requestNumber: requestNumber,
        requestDate: requestDate,
        city: city,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static async send(
    enterpriseId: number,
    message: INewMessageModel,
    request: INewDepartureRequest,
    draft: boolean
  ): Promise<IMessageSummary> {
    const x = await axios.put(
      `/api/enterprises/${enterpriseId}/messages/assisted/departurerequests`,
      {
        message: {
          ...message,
          draft: draft,
        },
        request: {
          ...request,
          requestDate:
            !request.requestDate || request.requestDate === null
              ? null
              : request.requestDate,
          returnDate:
            !request.returnDate || request.returnDate === null
              ? null
              : request.returnDate,
          draft: draft,
        },
        draft: draft,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }
}
