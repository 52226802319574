import axios from "axios";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import { ITransportSummaryItem } from "../../models/Transports/ITransportSummaryItem";
import AuthService from "../AuthService";
import DocumentService from "../Documents/DocumentService";
import PreferencesService from "../PreferencesService";

class TransportsService {
  private _transports?: ITransportSummaryItem[] = undefined;
  private _transportsPages: number = 1;

  public init(): void {
    this._transports = undefined;
    this._transportsPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("name", "LibelleTravailleur")
      .replace("request", "NumeroDemande")
      .replace("code", "CodeTravailleur")
      .replace("arrivalDate", "DateArrivee")
      .replace("departureDate", "DateDepart")
      .replace("nextEvent", "DateProchainEvenement")
      .replace("country", "Pays")
      .replace("readyToTravel", "PretAVoyager");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async countTransportsSummary(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ): Promise<number | undefined> {
    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/transports-requests/summary/$count`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 0,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      return x.status === 200 ? (x.data as any)?.count : undefined;
    } catch (ex) {
      console.log("error");
      return undefined;
    }
  }

  public async getTransportsSummary(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<ITransportSummaryItem[]> {
    const _this = this;
    let currentPage = this._transportsPages;
    let combine: boolean;
    if (next && _this._transports !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/transports-requests/summary`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._transports !== undefined) {
        _this._transportsPages = currentPage;
        _this._transports = _this._transports.concat(items);
      } else {
        _this._transportsPages = 1;
        _this._transports = this._transports = items;
      }
      return _this._transports ?? [];
    } catch (ex) {
      console.log("error");
      return _this._transports ?? [];
    }
  }

  public getFilters(reset: boolean): IQueryFilter[] {
    return PreferencesService.getFiltersOrDefault("transports", reset, () => [
      {
        name: "participants:positions:requestnumber",
        fieldName: "request",
        type: "text",
        value: "",
      },
      {
        name: "participants:name",
        fieldName: "name",
        type: "text",
        value: "",
      },
      {
        name: "participants:code",
        fieldName: "code",
        type: "text",
        value: "",
      },
      {
        name: "requests:periods:grouping:arrivaldate",
        fieldName: "arrivalDate",
        type: "date",
        value: null,
      },
      {
        name: "requests:periods:grouping:departuredate",
        fieldName: "departureDate",
        type: "date",
        value: null,
      },
    ]);
  }

  public async downloadTransportSummary(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters?: IQueryFilter[]
  ): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports-requests/summary/export`,
      {
        sortBy: this.translateSortBy(sortBy),
        desc: desc,
        filters: this.transformFilters(filters),
      },
      AuthService.getAuthHeaderWithBlob()
    );
    if (x.status === 200) {
      DocumentService.openBlob(x);
      return true;
    }
    return false;
  }
}

export default new TransportsService();
