export default {
  "language:en": "English",
  "language:fr": "French",
  "language:es": "Spanish",
  "env:dev": "Dev",
  "env:test": "Test Version",
  "global:loading": "Loading...",
  "global:pleasewait": "Please wait...",
  "global:upload": "Upload Files",
  "menu:logout": "Signout",
  "global:items": "items",
  "global:item": "item",
  "logout:message": "Logging out of Ferme AMIGO...",
  "maintenance:message": "A system maintenance will occurs between {0} and {1}",
  "global:yearsold": "years old",
  "global:moredetails": "More details",
  "menu:newmessages": "new messages",
  "menu:newmessages:bubble":
    "You have {0} new messages in your AMIGO Inbox. Please review them to stay informed about the latest changes to your account.",
  "menu:newmessages:bubble:title": "New Messages",
  "creditcard:validplaceholder": "valid thru",
  "creditcard:nameplaceholder": "YOUR NAME HERE",
  "navbarfull:message:title": "Messages",
  "navbarfull:enterprise:selector:title": "Enterprise selector",
  "navbarfull:language:selector:title": "Language selector",
  "navbarfull:profile:title": "Profile",
  "profile:preferences:instuctions":
    "Here you can select the commucation method which suit you the most when when send you notifications.",

  "address:street": "Street",
  "address:street:error": "The street is required",
  "address:city": "City",
  "address:city:error": "The city is required",
  "address:postalcode": "Postal Code",
  "address:postalcode:error": "The postal code is required",
  "address:state": "State",
  "address:state:error": "The state is required",
  "owner:ask":"Owner",
  "address:askowner:error": "Owner information is required",
  "requests:positions": "Postes",
  "menu:alerts": "Alerts",
  "menu:enterprises": "Enterprise",
  "menu:requests": "Requests",
  "menu:immigration": "Immigration",
  "menu:participants": "Participants",
  "menu:billing": "Billing",
  "menu:factures": "Billing",
  "menu:transports": "Transports",
  "menu:documents": "Info",
  "menu:messages": "Messages",
  "menu:profile": "Profile",
  "menu:expand": "Expand Navigation Menu",
  "menu:collapse": "Collapse Navigation Menu",
  "login:title": "AMIGO - by FERME Québec",
  "login:instructions": "Enter your credentials to login",
  "login:username": "Username",
  "login:password": "Password",
  "login:logincommand": "Login",
  "login:forgotinscructions": "Forgot your password?",
  "login:linkprefix": "No Problem! Just click ",
  "login:linkhere": "here",
  "login:linksuffix": "to reinitialize it.",
  "login:error":
    "Invalid username or password. Please verify your credentials.",
  "login:error:locked":
    "Your account has been locked. Please contact your administrator.",
  "login:username:error": "The username is required.",
  "login:password:error": "The password is required.",

  "forgot:title": "AMIGO - by FERME Québec<br />I forgot my password",
  "forgot:instructions": "Please input your username or your email.",
  "forgot:username": "Username or email",
  "forgot:forgotcommand": "Submit",
  "forgot:cancelcommand": "Cancel",
  "forgot:error":
    "We could not sent you and email. Please contact your administrator.",
  "forgot:success":
    "Great! We just sent you an email to start passwword reset procedure.",
  "forgot:username:error": "The username or email is required.",

  "reset:title": "AMIGO - by FERME Québec   Reset Password",
  "reset:instructions": "Please choose a new password.",
  "reset:newpassword": "New Password",
  "reset:confirmpassword": "Confirm Password",
  "reset:resetcommand": "Reset",
  "reset:cancelcommand": "Cancel",
  "reset:back": "Back to login",
  "reset:error":
    "We could not reset your password. Please contact your administrator.",
  "reset:success":
    "Your password has been reinitialized correctly. You may now proceed to the login page.",
  "reset:newpassword:error": "The new password is required.",
  "reset:newpassword:strength": "This password is too weak.",
  "reset:confirmpassword:error": "You must confirm your password.",
  "reset:confirmpassword:mismatch": "Password does not match.",

  "passwordstrength:0": "_",
  "passwordstrength:1": "Too short",
  "passwordstrength:2": "Too common",
  "passwordstrength:3": "Weak",
  "passwordstrength:4": "Ok",
  "passwordstrength:5": "Strong",

  "changepwd:title": "Change Password",
  "changepwd:instructions":
    "Enter your current password and a new password, confirm it and press the submit button.",
  "changepwd:currentpassword": "Current Password",
  "changepwd:newpassword": "New Password",
  "changepwd:confirmpassword": "Confirm Password",
  "changepwd:submitcommand": "Submit",
  "changepwd:cancelcommand": "Cancel",
  "changepwd:back": "Ok",
  "changepwd:error":
    "An error occurred while changing your password. Please verify you entered your current password correctly.",
  "changepwd:success": "Your password has been changed successfully.",
  "changepwd:lastreset": "Last Password Change:",
  "changepwd:currentpassword:error": "The current password is required.",
  "changepwd:newpassword:error": "The new password is required.",
  "changepwd:confirmpassword:error": "You must confirm your password.",
  "changepwd:newpassword:strength": "This password is too weak.",
  "changepwd:confirmpassword:mismatch": "Password does not match.",

  "alert:element": "Alert",
  "alerts:affected": "The following elements are impacted by this alert:",
  "alerts:deletetitle": "Deleting Alerts",
  "alerts:delete": "Delete",
  "alerts:deletemessage:plural":
    "Are you sure you want to delete these alerts?",
  "alerts:deletemessage:simple": "Are you sure you want to delete this alert?",
  "alerts:title": "Title",
  "alerts:type": "Alert Type",
  "alerts:createdon": "Created On",
  "alerts:createdon:filter": "Created",
  "alerts:seenOn": "Seen On",
  "alerts:description": "Description",
  "alerts:comments": "Comments",
  "alerts:defaultgroup": "Elements",
  "alerts:defaultgroup:_t": "Elements",
  "alerts:defaultgroup:_d": "Requests",
  "alerts:defaultgroup:_m": "Messages",
  "alerts:defaultgroup:_f": "Billing",
  "alerts:status": "Status",
  "alerts:all": "All Alerts",
  "alerts:unread": "Unread Alerts",
  "alerts:newalert": "Unread Alert",
  "alerts:spotlight:intro":
    "We want to bring to your attention this important alert",

  "profile:info": "Info",
  "profile:name": "Name",
  "profile:username": "Username",
  "profile:email": "Email",
  "profile:language": "Language",
  "profile:communications:preferences:title": "Communication Preferences",
  "profile:communications:methods": "Méthodes de communication",
  "profile:communications:methods:placeholder": "Méthode",
  "profile:preferences:success":
    "Your communication preferences were updated succesfully.",
  "profile:communications:alerts": "Unread alerts notifications",

  "enterprise:tab:enterprise": "Enterprise",
  "enterprise:tab:documents": "Documents",
  "enterprise:tab:payments": "Payment Settings",
  "enterprise:address:enterprise": "Enterprise",
  "enterprise:address:name": "Name",
  "enterprise:contacts": "Contacts",
  "enterprise:contacts:submit": "Submit Contact Request",
  "enterprise:info:phone": "Phone",
  "enterprise:info:cellphone": "Cellphone",
  "enterprise:info:fax": "Fax",
  "enterprise:info:email": "Email",
  "enterprise:info:neq": "N.E.Q",
  "enterprise:info:tps": "T.P.S",
  "enterprise:info:sector": "Sector",
  "enterprise:info:subsector": "Sub sector",
  "enterprise:info:memberid": "Member Number",
  "enterprise:addresses:title": "Addresses",
  "enterprise:addresses:branches": "Branches",
  "enterprise:addresses:address": "Address",
  "enterprise:addresses:mainaddress": "Main Address",
  "enterprise:addresses:name": "Enterprise Name",
  "enterprises:contacts:newsletter": "Newsletter subscription",
  "enterprises:contacts:billing": "Billing Contact",
  "enterprises:contacts:request": "Requests Contact",
  "enterprises:contacts:ferme": "FERME/Travel Contact",
  "enterprises:contacts:new": "Request New Contact",
  "enterprises:contacts:firstname:error": "FirstName is required.",
  "enterprises:contacts:lastname:error": "LastName is required.",
  "enterprises:contacts:email:error": "A valid email is required.",
  "enterprises:contacts:firstname": "FirstName",
  "enterprises:contacts:lastname": "LastName",
  "enterprises:contacts:email": "Email",
  "enterprises:contacts:phone": "Phone",
  "enterprises:contacts:cellphone": "Cellphone",
  "enterprises:contacts:title": "Title",
  "enterprises:contacts:panel": "Contacts Modification Request",
  "enterprises:documents:more": "More documents",
  "enterprises:documents:empty": "No documents avaialble",
  "enterprises:documents:processedon": "Processed On",
  "enterprises:documents:noprocess": "Unprocessed",
  "enterprises:contacts:delete": "Request Contact Deletion",
  "enterprises:contacts:delete:confirm":
    "Are you sure you want to delete this contact?",
  "enterprises:contacts:undo": "Undo this modification",

  "enterprises:paymentmethods:authorize:transport":
    "I authorize FERME/Voyages to use this credit card to complete airplane tickets purshases on your behalf. It is recommanded to check this authorization as it reduced and avoid delays for your workers' arrivals and departures.",
  "enterprises:paymentmethods:authorize:accept":
    "I have read and accept the conditions above",
  "enterprises:paymentmethods:authorize:billing":
    "I authorize FERME to use this credit card without any other notice to proceed with the payments of the following services: Annual Subscription, management fees, administration fees, late fees, airplane tickets and other transport fees.",
  "enterprises:paymentmethods:authorize:error":
    "This authorization is requried.",
  "enterprises:paymentmethods:createdon": "Created On",
  "enterprises:paymentmethods:createdby": "Created By",
  "enterprises:paymentmethods:card": "Card Number",
  "enterprises:paymentmethods:name": "Name on card",
  "enterprises:paymentmethods:expiration": "Expiry",
  "enterprises:paymentmethods:cvc": "CVC",
  "enterprises:paymentmethods:type": "Type",
  "enterprises:paymentmethods:element": "Payment Method",
  "enterprises:paymentmethods:header": "Payment Methods",
  "enterprises:paymentmethods:new": "New",
  "enterprises:paymentmethods:delete": "Delete",
  "enterprises:paymentmethods:save": "Save",
  "enterprises:paymentmethods:cancel": "Cancel",
  "enterprises:paymentmethods:deletetitle": "Delete Payment Method",
  "enterprises:paymentmethods:deletemessage:plural":
    "Are you sure you want to delete these payment methods?",
  "enterprises:paymentmethods:deletemessage:simple":
    "Are you sure you want to delete this payment method?",
  "enterprises:paymentmethods:new:header": "Nouvelle méthode de paiement",
  "enterprises:paymentmethods:card:error": "Card number is not valid.",
  "enterprises:paymentmethods:name:error": "Card holder name not correctly defined or contains unsupported characters (only letters, numbers, spaces and the following characters: _-:.@$=/.,).",
  "enterprises:paymentmethods:cvc:error": "CVC is required",
  "enterprises:paymentmethods:cvc:label": "Card Verification Code",
  "enterprises:paymentmethods:month": "Month",
  "enterprises:paymentmethods:year": "Year",
  "enterprises:paymentmethods:expiry:error": "Expiry Date is not valid",
  "enterprises:paymentmethods:saving":
    "Please wait... We are adding your new payment method.",
  "enterprises:paymentmethods:save:error":
    "An error prevented us to save your payment method. Please try again later.",
  "enterprises:paymentmethods:delete:irreversible":
    "This operation is irreversible.",
  "enterprises:paymentmethods:delete:lastcard:1":
    "WARNING ! You are about to remove the last credit card registred to your account. In the case of a missing credit card, some processes like airplane ticket purchase might be delayed.",
  "enterprises:paymentmethods:delete:lastcard:2":
    "Are you still sure you want to remove this credit card?",
  "enterprises:neq:editBouton:title": "Edit",
  "enterprises:neq:saveButton:title": "Save",
  "enterprises:neq:cancelButton:title": "Cancel",
  "enterprises:alerts:title": "Alerts Level",
  "enterprises:alerts:label": "Reminders in days before departures or arrivals",
  "enterprises:alerts:instructions":
    "I want to receive alerts before an arrival or departure.",
  "enterprises:alerts:tooltip":
    "You will receive an alert {0} days before an arrival or deprature.",
  "enterprises:contacts:added": "Contact Inclusion Requests",
  "enterprises:contacts:modified": "Contact Modification Requests",
  "enterprises:contacts:deleted": "Contact Delete Requests",
  "enterprises:contacts:apply:1": "Submit",
  "enterprises:contacts:apply:2": "Submit",
  "enterprises:contacts:confirm":
    "Are you sure you want to submit this contact modification request?",
  "enterprises:contacts:error":
    "Sorry. An error occured while saving your contact modification requests.",

  "documents:name": "Name",
  "documents:size": "Size",
  "documents:createdBy": "Created By",
  "documents:createdOn": "Created On",
  "documents:lastmodified": "Last modified on",
  "documents:processedOn": "Processed On",
  "documents:download": "Download",
  "documents:download:progress": "Download in progress...",
  "documents:formulaire": "Forms",
  "documents:capsuleaide": "Help Capsule",
  "documents:aidememoire": "Checklists",
  "documents:backcategories": "Return to categories",
  document: "Document",
  "documents:download:error":
    "Sorry we cannot download your document right now.",

  "participants:info": "Personal Information",
  "participants:workinfo": "Work Related",
  "participants:name": "Fullname",
  "participants:name:lastname": "Lastname",
  "participants:name:firstname": "Firstname",
  "participants:country": "Country",
  "participants:birthdate": "Birth Date",
  "participants:birthdate:filter": "Born",
  "participants:age": "Age",
  "participants:code": "Participant Code",
  "participants:createdon": "Created On",
  "participants:modifiedon": "Modified On",
  "participants:recall": "Last Recall Status",
  "participants:reason": "Reason",
  "participants:genre": "Gender",
  "participants:status": "Status",
  "participants:back": "Back to list",
  "participants:insurance": "Insurance Identification Number",
  "participants:marks": "Physical characteristics",
  "participants:edit": "Edit",
  "participants:address": "Participant Address",
  "participants:participant": "Participant",
  "participants:save": "Save",
  "participants:cancel": "Cancel",
  "participants:street": "Street",
  "participants:city": "City",
  "participants:postal": "Postal Code",
  "participants:state": "State/Province",
  "participants:email": "Email",
  "participants:upload": "Upload Files",
  "participants:phone": "Phone",
  "participants:cellphone": "Cellphone",
  "participants:error:0":
    "An error occured while editing participant address. Please contact your administator",
  "participants:documents": "Documents",
  "participants:history": "History",
  "participants:positions": "Positions",
  "participants:positions:title": "Position Title",
  "participants:positions:enterpriseName": "Enterprise Name",
  "participants:positions:arrivaldate": "Arrival Date",
  "participants:positions:departuredate": "Departure Date",
  "participants:positions:nextevent": "Next Event",
  "participants:history:title": "Position Title",
  "participants:history:enterpriseName": "Enterprise Name",
  "participants:history:arrivaldate": "Arrival Date",
  "participants:history:departuredate": "Departure Date",
  "flights:flightdate": "Search a flight date between the following date range",
  "global:never": "Jamais",
  "billing:status:paid": "Paid",
  "billing:status:late": "Late",
  "billing:status:pending": "To be paid",
  "billing:status:partial": "Paid partially",
  "billing:billingnumber": "Billing Number",
  "billing:billingdate": "Billing Date",
  "billing:subtotal": "Subtotal",
  "billing:tps": "T.P.S",
  "billing:tvq": "T.V.Q",
  "billing:total": "Total",
  "billing:balance": "Balance",
  "billing:amount": "Payment",
  "billing:status": "Status",
  "billing:element": "Billing",
  "billing:payment:element": "Payment",
  "billing:payment:details": "Payment Details",
  "billing:payments:paymentnumber": "Payment Number",
  "billing:payments:paymentdate": "Payment Date",
  "billing:payments:bills": "Associated Bills",
  "billing:payments:paymenttype": "Payment Type",
  "billing:payments:confirmation": "Confirmation Number",
  "billing:payments:confirmation:short": "Confirmation #",
  "billing:payments:total": "Amount",
  "billing:billing:header": "Bills",
  "billing:payments:retrypaymentagain": "Retry again",
  "billing:payments:header": "Payments",
  "billing:payments:pendingbill:date": "Date",
  "billing:payments:pendingbill:total": "Total",
  "billing:payments:pendingbill:received": "Payment made",
  "billing:payments:pendingbill:payment": "Payment",
  "billing:payments:pendingbill:balance": "Balance",
  "billing:payments:pendingbill:newbalance": "New Balance",
  "billing:payments:pendingbill:bill": "Bill",
  "billing:billtype": "Type",
  "billing:billtype:standard": "Standard Bill",
  "billing:billtype:travel": "FERME/Voyages Bill",
  "billing:warning:1":
    "FERME/Voyages are unfortuatly to payable through AMIGO portal yet. In fact, you need to communicate with our accounting service at (514 387-8816 # 221) to comply with payment.",
  "billing:legend": "Legend",
  "billing:accountstatement": "Account Statement",
  "billing:printbill": "Print",
  "billing:backtoalert": "Navigate to related alert",
  "billing:payments:newpayment": "Add Payment",
  "billing:payments:newpayment:existing": "Use an existing payment method",
  "billing:payments:newpayment:existing:required":
    "Select the payment method among options below.",
  "billing:payments:newpayment:newcard": "Use another payment method",
  "billing:payments:newpayment:confirm": "Please confirm your payment",
  "billing:payments:newpayment:confirm:instructions":
    "A payment of {0} $ CAD will be charge on the credit card below.",
  "billing:payments:newpaymentagain": "Add New Payment",
  "billing:payments:seebills": "See Bills",
  "billing:payments:seepayments": "See Payments",
  "billing:payments:thankyou": "Thank you for your payment!",
  "billing:payments:newpayment:amountrequired:singular":
    "Please enter amount as payment for this bill to pay.",
  "billing:payments:newpayment:amountrequired:plural":
    "Please enter amount as payment for at least one bill to pay.",

  "billing:payments:confirmation:date": "Payment Date",
  "billing:payments:confirmation:auth": "Authorization Code",
  "billing:payments:confirmation:receipt": "Receipt Number",
  "billing:payments:confirmation:transaction": "Transaction Number",
  "billing:payments:confirmation:conf": "Confirmation Number",
  "billing:payments:confirmation:card": "Credit Card Number",
  "billing:payments:paymenterror:title": "Payment Transaction Error",
  "billing:payments:paymenterror:refused": "Payment refused",
  "billing:payments:paymenterror:seats_exeeded":
    "Sorry, the number of seats requested are not available anymore on this flight.",
  "billing:payments:paymenterror:participants":
    "The following participants are no longer eligible for this reservation",
  "billing:payments:reservationerror:title": "Reservation Transaction Error",
  "billing:payments:newbillnumber:label": "Payment Number",
  "billing:payments:employer": "Employeur",
  "billing:payments:newbillnumber":
    "Payment Number will <br /> be generated upon saving",
  "billing:payments:newbillnumber:delayed":
    "A FERME/Voyages bill will be<br />issued according to usual<br />procedures.",
  "transports:flights:backhome": "Finish",

  "billing:payments:processing":
    "We are processing your reservation. Please wait...",
  "billing:payments:paymenterror:FLIGHT":
    "Sorry, An error occured while processing your reservation. Please contact your administrator.",
  "billing:payments:paymenterror:000":
    "An error occured while processing your payment. Please contact your administrator.",
  "billing:payments:paymenterror:050":
    "An error occured while processing your payment. Code: 050",
  "billing:payments:paymenterror:051": "Your credit card is expired. Code: 051",
  "billing:payments:paymenterror:052":
    "Too many PIN tentatives. Transaction cannot be completed. Code: 052",
  "billing:payments:paymenterror:053":
    "An error occured while processing your payment. Code: 053",
  "billing:payments:paymenterror:054":
    "This transaction cannot be completed with this credit card.. Code: 054",
  "billing:payments:paymenterror:055":
    "Your credit card has been rejected for this transaction. Code: 055",
  "billing:payments:paymenterror:056":
    "Sorry, our payment processing provider does not accept this credit card. Code: 056",
  "billing:payments:paymenterror:057":
    "This credit card has been reported as lost or stolen and cannot be used to complete this transaction. Please contact your card issuer. Code: 057",
  "billing:payments:paymenterror:058":
    "The usage of this credit card has been restricted  and cannot be used to complete this transaction. Please contact your card issuer. Code: 058",
  "billing:payments:paymenterror:059":
    "The usage of this credit card has been restricted  and cannot be used to complete this transaction. Please contact your card issuer. Code: 059",
  "billing:payments:paymenterror:060":
    "The usage of this credit card has been restricted  and cannot be used to complete this transaction. Please contact your card issuer. Code: 060",
  "billing:payments:paymenterror:061":
    "The usage of this credit card has been restricted  and cannot be used to complete this transaction. Please contact your card issuer. Code: 061",
  "billing:payments:paymenterror:062":
    "An error occured while processing your payment. Code: 062",
  "billing:payments:paymenterror:063":
    "An error occured while processing your payment. Code: 063",
  "billing:payments:paymenterror:064":
    "An error occured while processing your payment. Code: 064",
  "billing:payments:paymenterror:065":
    "An error occured while processing your payment. Code: 065",
  "billing:payments:paymenterror:066":
    "An error occured while processing your payment. Code: 066",
  "billing:payments:paymenterror:067":
    "An error occured while processing your payment. Code: 067",
  "billing:payments:paymenterror:068":
    "An error occured while processing your payment. Code: 068",
  "billing:payments:paymenterror:069":
    "An error occured while processing your payment. Code: 069",
  "billing:payments:paymenterror:070":
    "An error occured while processing your payment. Code: 070",
  "billing:payments:paymenterror:071":
    "An error occured while processing your payment. Code: 071",
  "billing:payments:paymenterror:072":
    "An error occured while processing your payment. Code: 072",
  "billing:payments:paymenterror:073":
    "An error occured while processing your payment. Code: 073",
  "billing:payments:paymenterror:074":
    "An error occured while processing your payment. Code: 074",
  "billing:payments:paymenterror:075":
    "An error occured while processing your payment. Code: 075",
  "billing:payments:paymenterror:076":
    "Insuffisant funds on this credit card. Your transaction have been refused. Code: 076",
  "billing:payments:paymenterror:086":
    "The amount must be superior to payment floor limit. Code: 086",
  "billing:payments:paymenterror:095":
    "Insuffisant funds on this credit card. Your transaction have been refused. Code: 095",
  "billing:payments:paymenterror:476":
    "An error occured while processing your payment. Please contact your administrator.",

  "billing:payments:submit": "Pay",
  "billing:payments:cancel": "Cancel",

  "billing:pendingbills:billsummary:title": "Billing Summary",
  "billing:payments:confirmpay": "Confirm payment",
  "billing:pendingbills:billsummary:subtotal": "Subtotal",
  "billing:pendingbills:billsummary:tps": "G.S.T",
  "billing:pendingbills:billsummary:tvq": "Q.S.T",
  "billing:pendingbills:billsummary:total": "Total",

  "billing:payments:securedialog:instructions":
    "To complete the transaction, you must enter the card verification code which is located at the back of your card.",
  "billing:payments:securedialog:cvc:label": "Card Verification Code",
  "billing:payments:securedialog:error":
    "Veuillez entrer le code de vérification de la carte.",
  "billing:payments:back": "Back to list",

  "dossierimmigration:info": "Info",
  "dossierimmigration:file": "Immigration File",
  "dossierimmigration:year": "Year",
  "dossierimmigration:number": "Number",
  "dossierimmigration:agent": "Agent",
  "dossierimmigration:country": "Country",
  "dossierimmigration:title": "Title",
  "dossierimmigration:version": "Version",
  "dossierimmigration:filecreation": "Opened On",
  "dossierimmigration:createdon": "Created On",
  "dossierimmigration:version:title": "Title",
  "dossierimmigration:namesreception": "Names Reception",
  "dossierimmigration:namesrequest": "Names Request",
  "dossierimmigration:transmission": "Transmission",
  "dossierimmigration:request": "Request",
  "dossierimmigration:participantCount": "Number of workers",
  "dossierimmigration:participants": "Participants",
  "dossierimmigration:history": "History",
  "dossierimmigration:back": "Back to list",
  "dossierimmigration:warning":
    "Information regarding the progress of your immigration file can be provided to you only to the extent that FERME acts as your third party for the preparation of your LMIA.",
  "dossierimmigration:details": "Followup Details",
  "dossierimmigration:details:createdon": "Created On",
  "dossierimmigration:details:title": "Title",
  "dossierimmigration:documents:processedon": "Processed On",
  "dossierimmigration:documents:noprocess": "Unprocesssed",
  "dossierimmigration:tab:documents": "Documents",
  "messages:searchcontact:title": "Select Recipients",
  "messages:users:role": "Role",
  "messages:users:title": "Name",
  "messages:users:email": "Email",
  "messages:element": "Message",
  "messages:title": "Messages",
  "messages:inbox": "Inbox",
  "messages:drafts": "Drafts",
  "messages:sent": "Sent",
  "messages:sent:tooltip": "Sent on {0}",
  "messages:newmessage": "New Message",
  "messages:commandbar:aria":
    "Use left and right arrow keys to navigate between commands",
  "messages:sort:recent": "Recent",
  "messages:sort:older": "Older",
  "messages:sort:timestamp": "Sent Date",
  "messages:sort:subject": "Subject",
  "messages:sort:recipient": "Recipient",
  "messages:sort:from": "Recipient",
  "messages:sort:type": "Communication Type",
  "messages:delete:title": "Delete Message",
  "messages:delete:subtext": "Do you really want to delete this message?",
  "messages:send": "Send",
  "messages:save": "Save",
  "messages:discard": "Discard",
  "messages:senderror": "An error occured while sending your message.",
  "messages:savedrafterror": "An error occured while saving your message.",
  "messages:savedraft:pleasewait":
    "A moment please... We're saving your draft.",
  "messages:send:pleasewait": "A moment please... We're sending your message.",
  "messages:subject": "Subject",
  "messages:designations": "Assigned Recipients",
  "messages:designations:dispatcher": "Dispatcher",
  "messages:recipients": "Recipients",
  "messages:recipients:extra": "Additionnal recipients",
  "messages:recipients:error": "Specify at least one recipient",
  "messages:subject:error": "Subject is required",
  "messages:message:error": "You must enter a message.",
  "messages:communicationtype": "Communication Type",
  "messages:attach": "Attach",
  "global:searching": "Searching...",
  "global:next": "Next",
  "messages:assisted:participants": "Participants concerned by this request",
  "messages:assisted:participants:emptymessage":
    "Select a request to related participants to include.",
  "messages:assisted:participants:transfert:noparticipants":
    "The selected request does not contains any available participants for a transfert according to our files.",
  "messages:assisted:participants:depart:noparticipants":
    "The selected request does not contains any available participants to a departure according to our files.",
  "messages:assisted:participants:arrivee:noparticipants":
    "The selected request does not contains any available participants to an arrival according to our files.",
  "messages:assisted:authorization:label": "Your full name",
  "messages:assisted:authorization:instructions":
    "To confirm text above and that you grant it authorization, please enter your fullname.",
  "messages:assisted:authorization:title": "Required Autorization",
  "messages:assisted:authorization:error:required":
    "Please enter your fullname, we were expecting {0}.",
  "messages:assisted:authorization:error:invalid":
    "This value does not match your full name. We were expecting {0}.",
  "messages:assisted:authorization":
    "AUTORIZATION : I acknowledge of this message and authorize FERME/Voyage to operate on plane tickets purchase to the best price possible without any other consent conserning this request. I also understand plane tickets bought on our platform is at market price and does not include any possiblity of refunds, exchange or changes at no charge.",

  "messages:assisted:newdeparture:title": "Departure Request",
  "messages:assisted:newdeparture:code": "Code",
  "messages:assisted:changedeparture:title": "Departure Request",
  "messages:assisted:enterprisechange:title": "Enterprise Change",
  "messages:assisted:inspection:title": "Inspection Request",
  "messages:assisted:inspection:unavailable":
    "Then inspection details is available through to your original message",
  "messages:assisted:inspection:confirmation":
    "Avant la visite de l'inspecteur, ASSUREZ-VOUS d'avoir à jour les éléments suivants",

  "messages:assisted:inspection:confirmation:reminder":
    "**Le bâtiment doit être propre, impeccable et sécuritaire**",
  "messages:assisted:inspection:confirmation:footer":
    "Travaillons ensemble pour le mieux-être de tous !!!",
  "messages:assisted:newarrival:title": "Arrival Request",
  "messages:assisted:newdeparture:requestdate:error":
    "Request Date is required",
  "messages:assisted:newdeparture:recall:error": "Recall Status is required",
  "messages:assisted:newdeparture:reason:error":
    "Non recall reason is required",
  "messages:assisted:newdeparture:requestnumber:error":
    "Request Number is required",
  "messages:assisted:newdeparture:participants:required":
    "Select at least one participant to complete your departure request",
  "messages:assisted:newdeparture:warning:title": "A small reminder",
  "messages:assisted:newdeparture:warning:content":
    "If you have many participants of different countries to travel on a same date, ensure to complete all your departure request at the same time.",
  "messages:assisted:participanttransfert:requestnumber": "Request Number",
  "messages:assisted:participanttransfert:requestnumber:error":
    "Select a request.",

  "messages:assisted:participanttransfert:warning:norequest:title":
    "Attention!",
  "messages:assisted:participanttransfert:norequest":
    "There is no active request with transfert pending.",
  "messages:assisted:participanttransfert:participantcount":
    "Number of participants",
  "messages:assisted:participanttransfert:warning:title": "A small reminder",
  "messages:assisted:participanttransfert:warning:content":
    "Indicate only one enterprise name to whom selected participants will be transfered. You must complete a confirmation request for each different enterprises included in the tranfert.",

  "messages:assisted:participanttransfert:requestdate":
    "Enterprise Tranfert Date",
  "messages:assisted:participanttransfert:requestdate:error":
    "A date is required for the request",
  "messages:assisted:participanttransfert:transfertenterprise":
    "Transfered to enterprise",
  "messages:assisted:participanttransfert:transfertenterprise:error":
    "The name of the enterprise is required.",
  "messages:assisted:participanttransfert:summary:title":
    "Enterprise Transfert Request Summary",
  "messages:recipients:suggestedcontacts:1": "Suggested People",
  "messages:recipients:suggestedcontacts:2": "Suggested Contacts",
  "messages:recipients:suggestedcontacts:noresults": "No contacts found",
  "messages:recipients:suggestedcontacts:loading": "Loading",

  "messages:commands:reply": "Reply",
  "messages:commands:replyall": "Reply to all",
  "messages:commands:forward": "Forward",
  "messages:gotoinbox": "Go to Inbox",
  "messages:type:standard": "Standard Communication",
  "messages:type:general": "Global Communication",
  "messages:type:standard:desc": "Send a standard communication",
  "messages:type:newdeparture": "New Departure Request",
  "messages:type:newdeparture:desc":
    "Send a communication for a new transport departure request",
  "messages:type:changedeparture": "Departure Change Request",
  "messages:type:changedeparture:desc":
    "Send a communication about an existing departure request",
  "messages:type:transfert": "Enterprise Change Confirmation",
  "messages:type:transfert:desc": "Send a enterprise change confirmation",
  "messages:type:newarrival": "Arrival Request",
  "messages:type:newarrival:desc":
    "Send a communication for a new arrival request",
  "messages:type:newarrival:transport": "New Transport Request",
  "messages:type:newarrival:transport:desc":
    "Send a communication for a new transport request for an arrival",
  "messages:type:inspection": "New Inspection Request",
  "messages:type:inspection:desc":
    "Send a new inspection request for your buildings.",

  "messages:assisted:newdeparture:charter:places": "Only {0} seat(s) left",
  "messages:assisted:newdeparture:charter:label": "Charter Flights",
  "messages:assisted:newdeparture:charter:warning:title":
    "Charter Flights Policy",
  "messages:assisted:newdeparture:charter:select": "Select",
  "messages:assisted:newdeparture:charter:selectother": "Select another flight",
  "messages:assisted:newdeparture:charter:warning:content":
    "The preselection of charter flight does not constiture an absolute garrantee to access this charter flight or any other. It may be the case, because of major constraints happens and force FERME/Voyages to redirect your workers to other regular flights. Departure or arrival configuration will indicate the final flights your workers will fly on.",
  "messages:assisted:newdeparture:charter:selection":
    "Charter Flight Selection",
  "messages:assisted:newdeparture:charter:noflight":
    "No charter flights are scheduled according the your actual request criterias.",
  "messages:assisted:preauth:1":
    "In granting autorization to buy market price plane tickets, you allow FERME/Voyages to make ticket search and booking operations at the lowest price possible for the requested departure date.",
  "messages:assisted:preauth:2":
    "This means FERME/Voyages operators will not seek phone confirmation before processing with flight ticket reservation at market place, and so, in the extent that the price is lower than 500,00 $ CAN per ticket. (The agreement price is around 600,00 $ CAN).",

  "messages:assisted:preauth:3":
    "Select this option if you want to grant this authorization.",

  "messages:assisted:newarrival:warning:title": "A quick reminder",
  "messages:assisted:newarrival:warning:1":
    "Fees related tho this service will be billed through FERME/Voyages.",
  "messages:assisted:newarrival:warning:2":
    "An additional cost is to be expected if your workers arrives directly at your installations.",

  "messages:assisted:newdeparture:requestnumber": "Request Number",
  "messages:assisted:newdeparture:preauth:label": "Purchase Preauthorization",
  "messages:assisted:newdeparture:origincity": "Origin City",
  "messages:assisted:newdeparture:origin": "Departure",
  "messages:assisted:newdeparture:flight": "Flight Number",
  "messages:assisted:newdeparture:origincity:error":
    "Select your city of origin",
  "messages:assisted:newdeparture:destinationcity": "Destination City",
  "messages:assisted:newdeparture:destination": "Destination",
  "messages:assisted:newdeparture:reason": "Travel Reason",
  "messages:assisted:newdeparture:reasonrecall": "Recall Reason",
  "messages:assisted:newdeparture:recall": "Recall Status",
  "messages:assisted:newdeparture:participant": "Participant Name",
  "messages:assisted:newdeparture:requestdate": "Request Date",
  "messages:assisted:newdeparture:select": "Select...",
  "messages:assisted:newdeparture:leavereason": "Departure Reason",
  "messages:assisted:newdeparture:returndate":
    "Return Date (if known and applicable)",
  "messages:assisted:newdeparture:returndate:short": "Return Date",
  "messages:assisted:newdeparture:summary:title": "Departure Request Summary",
  "messages:assisted:newdeparture:other": "Other",
  "messages:assisted:newdeparture:none": "None",
  "messages:assisted:newdeparture:unknown": "Unknown",
  "messages:assisted:newdeparture:estimatedarrivaldate":
    "Estimated Departure Date",
  "messages:assisted:newdeparture:estimateddeparturedate":
    "Estimated Arrival Date",
  "messages:assisted:newdeparture:estimateddate": "Estimated Departure Date",
  "messages:assisted:newdeparture:completeaddress": "Complete Address",
  "messages:assisted:newdeparture:landrequested":
    "The current departure request must include land transportation.",
  "messages:assisted:newdeparture:landrequested:toggle":
    "Land Transportation Required",

  "messages:assisted:newdeparture:preauthrequested":
    "You accept the purshase pre-authorization policy for the current departure request.",
  "messages:assisted:newarrival:preauthrequested":
    "You accept the purshase pre-authorization policy for the current arrival request.",
  "messages:assisted:newarrival:option:custom":
    "Specfic Address Transportation",
  "messages:assisted:newarrival:option:city": "Standard Arrival City",
  "messages:assisted:newdeparture:unspecified": "Unspecified",
  "messages:assisted:newarrival:requestnumber": "Request Number",
  "messages:assisted:newarrival:select": "Select...",
  "messages:assisted:newarrival:requestdate": "Request Date",
  "messages:assisted:newarrival:enterprise": "Enterprise",
  "messages:assisted:newarrival:city": "Arrival City",
  "messages:assisted:newarrival:participant": "Participant Name",
  "messages:assisted:newdeparture:passport": "Passport",
  "messages:assisted:newdeparture:passport:invalid": "Invalid Passport",
  "messages:assisted:newdeparture:passport:invalid:instructions":
    "This worker does not have any valid passport. Please communicate with your agent.",
  "messages:assisted:newdeparture:passport:invalid:instructions:arrival":
    "You can continue with your reservation during for arrival",
  "messages:assisted:newdeparture:passport:invalid:instructions:departure":
    "We must first resolve this invalid passport issue to proceed with this reservation",
  "transports:offer:title": "We're loading your travel offer... One moment...",
  "messages:assisted:newarrival:maxseat":
    "The selected flight does not contains enough available seats to accomodate your current selection.",

  "messages:assisted:inspection:confirmation:item1":
    "Un test d'eau valide de moins de 1 an (non requis si l'eau potable est fourni par un réseau d'aqueduc)",
  "messages:assisted:inspection:confirmation:item2":
    "Changez les piles des détecteurs de feu et vous assurez qu'ils soient tous fonctionnels et datant de moins de 10 ans",
  "messages:assisted:inspection:confirmation:item3":
    "Des extincteurs conformes de type ABC, de moins de 6 ans et en bonne condition, vissés près des armoires de cuisine",
  "messages:assisted:inspection:confirmation:item4":
    "Des équipements en bon nombres, propre et fonctionnels",
  "messages:assisted:inspection:confirmation:item5":
    "Des couvres-matelas (draps-contour acceptés) sur TOUS les matelas, ainsi que des oreillers convenables",

  "messages:assisted:newarrival:requestdate:error":
    "A date for the request is required",
  "messages:assisted:newarrival:requestnumber:error":
    "You must select a request",
  "messages:assisted:newarrival:city:error": "Select a city for your arrival",
  "messages:assisted:newarrival:custom:error":
    "Please enter a address for your arrival",
  "messages:assisted:inspection:address": "Inspection Address",
  "messages:assisted:inspection:enterprise": "Enterprise",
  "messages:assisted:inspection:branch": "Branch",
  "messages:assisted:inspection:street": "Street",
  "messages:assisted:inspection:city": "City",
  "messages:assisted:inspection:postalcode": "Postal Code",
  "messages:assisted:inspection:state": "Province",
  "messages:assisted:inspection:contact": "Contact",
  "messages:assisted:inspection:comments": "Comments",
  "messages:assisted:inspection:contacts": "Enterprise Contacts",
  "messages:assisted:inspection:contactname": "Contact Name",
  "messages:assisted:inspection:phone": "Phone Number",
  "messages:assisted:inspection:email": "Email",
  "messages:assisted:inspection:details": "Inspection Details",
  "messages:assisted:inspection:building": "Building Count",
  "messages:assisted:inspection:workers": " F.E.R.M.E Number of Workers",
  "messages:assisted:inspection:workers:extern": " External Number of Workers",
  "messages:assisted:inspection:building:error":
    "Building Count must be greater than zero.",
  "messages:assisted:inspection:workers:error":
    "Number of workers must be greater than zero.",
  "messages:newmessage:discard:title": "Discard new message?",
  "messages:newmessage:discard:message":
    "Are you sure you want to cancel this new message?",
  "messages:assisted:inspection:dates": "Related Dates",
  "messages:assisted:inspection:estimateddeposit":
    "EIMT Estimated Deposit Date",
  "messages:assisted:inspection:lastinspection": "Last Inspection Report Date",
  "messages:assisted:inspection:select": "Select...",
  "messages:assisted:inspection:contactname:error": "Contact Name is required",
  "messages:assisted:inspection:phone:error": "Phone Number is required",
  "messages:assisted:inspection:email:error": "Email is required",
  "messages:assisted:inspection:estimateddeposit:error":
    "EIMT Estimated Deposit Date is required",
  "messages:assisted:inspection:lastinspection:error":
    "Last Inspection Report Date is required",
  "messages:assisted:inspection:confirmation:title": "Confirmation",
  "messages:assisted:inspection:confirmation:header":
    "Inspection Address Confirmation",
  "messages:assisted:inspection:confirmation:description":
    "You indicated the following address for buildings to be inspected.",
  "messages:assisted:inspection:confirmation:description:plural":
    "You indicated the following addresses for buildings to be inspected.",
  "messages:assisted:inspection:confirmation:instructions":
    "Please certify this adress is correct et exact, otherwise click cancel to return to the request and correct any erroneous informations.",
  "messages:assisted:inspection:checkbox":
    "I certify this address is correct and exact.",
  "messages:assisted:inspection:askifishousingowner":"Are you the owner of the housing to inspect ?",
  "messages:assisted:inspection:askownerfullname":"Please write the owner fullname",
  "messages:assisted:inspection:askbedcount":"How many beds are in this address ?",
  "messages:assisted:inspection:askifrenttootheremployer":"Do you rent this housing to other empoloyers ?",
  "messages:assisted:inspection:askiftakeworkerextraferme":"Do you take workers other than at F.E.R.M.E ?",
  "messages:assisted:inspection:message:placeholder":"Please write your message here",
  "messages:assisted:transfert:code": "Participant Code",
  "messages:assisted:transfert:date": "Estimated Departure Date",
  "messages:reply:format:subjet": "Subject",
  "messages:reply:format:from": "From",
  "messages:reply:format:to": "To",
  "messages:reply:format:created": "Created On",

  "requests:noevent": "No departure or arrival",
  "requests:nextdeparture": "Next Departure",
  "requests:nextarrival": "Next Arrival",
  "requests:nextevent": "Prochain événement",
  "requests:requestnumber": "Request Number",
  "requests:createdon": "Creation Date",
  "requests:creationdate": "Version Creation Date",
  "requests:country": "Country",
  "requests:agent": "Agent",
  "requests:status": "Status",
  "requests:sector": "Sector",
  "requests:offptas": "Off PTAS",
  "requests:thirdParty": "Third Party",
  "requests:participantcount": "Participant Count",
  "requests:version": "Version #",
  "requests:status:closed": "Closed",
  "requests:status:confirmed": "Confirmed",
  "requests:status:draft": "Draft",
  "requests:participants": "participants",
  "requests:participantslabel": "Participants",
  "requests:request": "Request",
  "requests:info": "Info",
  "requests:year": "Year",
  "requests:version_": "Version",
  "requests:title": "Version Title",
  "requests:periods": "Periods",
  "requests:postes": "positions",
  "requests:poste": "position",
  "requests:postes:title": "Positions",
  "requests:poste:title": "Position",
  "requests:backtolist": "Back to list",
  "requests:fromto": "Period from {0} to {1}",
  "requests:fromto:eimt": "Period from {0} to {1} - EIMT: {2}",
  "request:EIMT:number": "EIMT Number",
  "requests:branch": "Branch",
  "requests:period": "Period",
  "requests:period:startdate": "Start Date",
  "requests:period:enddate": "End Date",
  "requests:period:eimt": "EIMT",
  "requests:period:eimt:delivery": "EIMT Delivery Date",
  "requests:period:eimt:expiration": "EIMT Expiration Date",
  "requests:period:count": "Nombre de postes",
  "requests:backrequest": "Back to request",
  "requests:backtoriginal": "Back to original",
  "requests:contacts": "Contacts",
  "requests:participant:lastname": "LastName",
  "requests:participant:firstname": "FirstName",
  "requests:participant:participantnumber": "Participant Number",
  "requests:participant:participanttype": "Participant Type",
  "requests:participant:visanumber": "Visa Number",
  "requests:participant:caqnumber": "CAQ Number",
  "requests:participants:eimt": "EIMT Number",
  "requests:participants:eimtexpiration": "EIMT Expiration",
  "requests:participants:hourlyrate": "Hourly Rate",
  "requests:participants:participantnumber": "Participants Count",
  "requests:periods:arrivalfrom": "Arrival from ",
  "requests:periods:departurefrom": "Depature from ",
  "requests:periods:arrival": "Arrival",
  "requests:periods:departure": "Departure",
  "requests:periods:completed": "Completed",
  "requests:history:header": "History",
  "requests:history:name": "Version Title",
  "requests:history:createdby": "Created By",
  "requests:history:createdon": "Created On",
  "requests:history:goto": "Consultation",
  "requests:history:goto:label": "Browse this version",

  "requests:periods:grouping:firstname": "Participant",
  "requests:periods:grouping:participanttype": "Participant Type",
  "requests:periods:grouping:title": "Title",
  "requests:periods:grouping:arrivalstatus": "Arrival",
  "requests:periods:grouping:departurestatus": "Departure",
  "requests:periods:grouping:arrivaldate": "Arrival Date",
  "requests:periods:grouping:departuredate": "Departure Date",
  "requests:periods:grouping:participantnumber": "Participant Number",
  "requests:periods:grouping:readytotravel": "Ready To Travel",

  "requests:periods:grouping:period": "Per period",
  "requests:periods:grouping:next": "Per next event",
  "requests:periods:grouping:withhistory": "With History",
  "requests:periods:grouping:withouthistory": "Without History",

  "requests:periods:participants:title": "Title",
  "requests:periods:participants:name": "Participant",
  "requests:periods:participants:number": "Participant Number",
  "requests:periods:participants:type": "Participant Type",

  "requests:participants:item:readytotravel:title": "Ready To Travel",
  "requests:participants:item:readytotravel:description":
    "This participant is now ready to travel",
  "requests:participants:item:name": "Participant Name",
  "requests:participants:item:number": "Participant Number",
  "requests:participants:item:visa": "Visa Number",
  "requests:participants:item:visa:reception": "Visa Reception Date",
  "requests:participants:item:visa:expiration": "Visa Expiration Date",
  "requests:participants:item:type": "Participant Type",
  "requests:participants:item:birthdate": "Birthdate",
  "requests:participants:item:gender": "Gender",
  "requests:participants:item:gender:m": "Male",
  "requests:participants:item:gender:f": "Female",
  "requests:participants:item:arrival:estimated": "Estimated Arrival Date",
  "requests:participants:item:arrival:real": "Real Arrival Date",
  "requests:participants:item:arrival:type": "Arrival Transport Type",
  "requests:participants:item:caq": "Québec Selection Certificate",
  "requests:participants:item:status": "Participant Status",
  "requests:participants:item:departure:estimated": "Estimated Departure Date",
  "requests:participants:item:departure:real": "Real Departure Date",
  "requests:participants:item:departure:type": "Departure Transport Type",
  "requests:participants:item:country": "Country",
  "requests:participants:item:position": "Position",
  "requests:participants:item:end:type": "End Of Participation Type",
  "requests:participants:item:end:recall:status": "Participant Recall Status",
  "requests:participants:item:end:recall:reason": "Non Recall Reason",
  "requests:participants:item:end:notes": "End Of Participation Notes",
  "requests:participants:item:notes:empty": "No notes for this participant.",
  "requests:participants:item:back": "Back to list",

  "requests:participants:item:pathway:medical": "Medical Exam Request Date",
  "requests:participants:item:pathway:eimt": "EIMT Reception Date",
  "requests:participants:item:pathway:signature": "Documents signing date",
  "requests:participants:item:pathway:annexe": "Annex B Reception Date",
  "requests:participants:item:pathway:deposit": "Visa Request Deposit Date",
  "requests:participants:item:pathway:delivery": "Visa Delivery Date",
  "requests:participants:item:pathway:status": "Status",

  "requests:print": "Imprimer la fiche demande",
  "requests:reports:type:report": "Request Detailled Report",
  "requests:reports:type:arrival": "Arrival Confirmation Report",
  "requests:reports:type:departure": "Departure Confirmation Report",
  "requests:reports:types": "Request Report Types",
  "requests:reports:type:date:all": "All Dates",
  "requests:reports:type:departure:date": "Departure Date",
  "requests:reports:type:arrival:date": "Arrival Date",
  "requests:printrequest": "Print",
  "requests:positions:all": "All positions",
  "requests:AERIEN": "Aeral",
  "requests:TRANSFERT": "Enterprise Change",
  "requests:AUTRE": "Other",
  "requests:NONE": "None",
  "requests:documents": "Documents",
  "requests:pathway": "Progresss",
  "requests:endparticipation": "End of participation",
  "requests:notes": "Notes",
  "transports:element:type": "{0} Transportation",
  "transports:requests:arrivals:header": "Arrivals",
  "transports:requests:departures:header": "Departures",
  "transports:requests:billetterie:header": "Plane Tickets",
  "transports:arrivals:element": "Arrivals",
  "transports:departures:element": "Departures",
  "transports:requests:requestnumber": "Request Number",
  "transports:requests:type": "Transport Type",
  "transports:requests:typeicon": "Transport Status",
  "transports:requests:legend": "Transport Type Legend",
  "transports:requests:date": "Date",

  "transports:offers:waranty": "No warranty of available upon this offer",
  "transports:offers:itenarytext:singular": "itinéraire disponible",
  "transports:offers:title":
    "Des vols juste à temps, voici nos suggestions en ce moment",
  "transports:offers:itenarytext:plural": "itinéraires differents disponibles",
  "transports:offers:arrivals:card:instructions":
    "Des arrivées sont prévues pour le ",
  "transports:offers:departures:card:instructions":
    "Des départs sont prévues pour le ",
  "transports:requests:passengers": "Passengers Count",
  "transports:requests:passengers:singular": "passenger",
  "transports:requests:passengers:plural": "passengers",
  "transports:requests:legend:regular": "Regular Flight",
  "transports:requests:legend:charter": "Charter Flight",
  "transports:requests:legend:confirmed": "Regular Flight",
  "transports:requests:legend:requested": "Regular Flight",
  "transports:requests:legend:currenttransfert": "Transfert",
  "transports:requests:legend:transfert": "Transfert",
  "transports:requests:legend:othercurrent": "Other",
  "transports:requests:legend:workerescaped": "Worker eskaped",
  "transports:requests:legend:contractrenewd": "Contract renewed",
  "transports:requests:legend:other": "Other",
  "transports:requests:legend:leaving": "Regular Flight",
  "transports:requests:legend:leaving:desc": "In Progress",
  "transports:requests:legend:regular:desc": "",
  "transports:requests:legend:charter:desc": "",
  "transports:requests:legend:confirmed:desc": "Confirmed Departure",
  "transports:requests:legend:requested:desc": "Itinerary Request",
  "transports:requests:legend:currenttransfert:desc": "Departure in progress",
  "transports:requests:legend:transfert:desc": "Itinerary Request",
  "transports:requests:legend:othercurrent:desc": "Departure in progress",
  "transports:requests:legend:other:desc": "Itinerary Request",
  "transports:flights:bestprice:before:days": "days earlier",
  "transports:flights:bestprice:after:days": "days after",
  "transports:flights:bestprice:before": "Best price for 3 previous days",
  "transports:flights:bestprice:after": "Best price for next 3 days",
  "transports:flightcriteria:instructions:arrivals": "arrivals",
  "transports:flightcriteria:instructions:departures": "departures",
  "transports:flightcriteria:instructions:1": "Select your airports for your ",
  "transports:flightcriteria:instructions:2":
    " as well as the date to search flights corresponding to your needs.",
  "transports:flightcriteria:passengers": "Passengers",
  "transports:flightcriteria:passengersnumber": "Number of passengers",
  "transports:flightcriteria:flexible": "Flexible date",
  "transports:flightcriteria:market": "Market Flights",
  "transports:flightcriteria:charter": "Charter Flights",
  "transports:flightcriteria:from:error": "Please select your departure",
  "transports:flightcriteria:to:error": "Please selection your destination",
  "transports:flightcriteria:passengers:error":
    "You must specify the number of passengers",
  "transports:flights:departurereason": "Departure Reason",
  "transports:flights:departurereason:select": "Select reason",

  "transports:flightcriteria:travelwarning:title": "Active Travel Advisory",
  "transports:flightcriteria:travelwarning:desc":
    "There's a government travel advisory related to coronavirus (COVID-19).",
  "transports:flightcriteria:travelwarning:url":
    "https://travel.gc.ca/travelling/advisories",
  "transports:flightcriteria:generalwarning:name": "",
  "transports:flightcriteria:generalwarning:description":
    "This charter flight and bundled flight reservation platform and platform for the profit of FERME/Voyages.",
  "transports:flightcriteria:generalwarning:subtext":
    "Travel Agency license delivered by OPC # 702576",

  "transports:flights:conditions:arrival:standard:title":
    "Ticket conditions and baggage allowance",

  "transports:flights:conditions:arrival:standard:1":
    "Ticket is refundable in case of NO SHOW due to positive covid test",
  "transports:flights:conditions:arrival:standard:2":
    "Luggage allowed: 1 suitcase of 15 kg MAXIMUM + 1 hand luggage of 5 kg MAXIMUM included in the price ",
  "transports:flights:conditions:arrival:standard:3":
    "No additional baggage accepted by the airline. Please respect the dimensions and weight of the suitcases, otherwise it will cause real problems at the airport and the flight will be delayed",
  "transports:flights:conditions:arrival:standard:4":
    "Attention: NO oversized luggage is accepted by the airline, i.e. bicycles, televisions and musical instruments will not be allowed on board. Please communicate the information to your workers, it is your responsibility as an employer",

  "transports:flights:conditions:arrival:am:1":
    "The ticket is at the contract price is non-refundable / possibility of making a free change (flight date or passenger name)",

  "transports:flights:conditions:arrival:am:2":
    "The 2nd change is priced at $150 CAD",
  "transports:flights:conditions:arrival:am:3":
    "Luggage allowed: 1 suitcase of MAXIMUM 23 kg + 1 hand luggage of MAXIMUM 10 kg included in the price",
  "transports:flights:conditions:arrival:am:4":
    "Attention: 2nd piece of luggage of 23 kg MAXIMUM at the price of CAD $50, to be paid directly at the Aeromexico check-in counter in cash or by credit card",

  "transports:flights:conditions:departure:standard:title":
    "Ticket conditions and baggage allowance",

  "transports:flights:conditions:departure:standard:1":
    "Ticket is refundable in case of NO SHOW due to positive covid test",
  "transports:flights:conditions:departure:standard:2":
    "Luggage allowed: 2 suitcase of 23 kg MAXIMUM + 1 hand luggage of 5 kg MAXIMUM included in the price ",
  "transports:flights:conditions:departure:standard:3":
    "No additional baggage accepted by the airline. Please respect the dimensions and weight of the suitcases, otherwise it will cause real problems at the airport and the flight will be delayed",
  "transports:flights:conditions:departure:standard:4":
    "Attention: NO oversized luggage is accepted by the airline, i.e. bicycles, televisions and musical instruments will not be allowed on board. Please communicate the information to your workers, it is your responsibility as an employer",

  "transports:flights:conditions:departure:am:1":
    "The ticket is at the contract price is non-refundable / possibility of making a free change (flight date or passenger name)",

  "transports:flights:conditions:departure:am:2":
    "The 2nd change is priced at $200 CAD",
  "transports:flights:conditions:departure:am:3":
    "Luggage allowed: 1 suitcase of MAXIMUM 23 kg + 1 hand luggage of MAXIMUM 10 kg included in the price",
  "transports:flights:conditions:departure:am:4":
    "Attention: 2nd piece of luggage of 23 kg MAXIMUM at the price of CAD $50, to be paid directly at the Aeromexico check-in counter in cash or by credit card",

  "transports:flights:confirmation:message":
    "By confirming this reservation, I acknowledge accepting all conditions related to the emission of plane tickets as mentionned during the registration process.",
  "transports:flights:confirmation:buttons:submit": "I confirm",
  "transports:flights:confirmation:buttons:cancel": "Cancel",

  "transports:flightcriteria:date:error": "Select the departure date",
  "transports:flights:billingdate": "Billing Date",
  "transports:flights:paymentdate": "Payment Date",
  "transports:flights:origindest:label": "Origin-Destination",
  "transports:flights:passengerscount:label": "passenger",
  "transports:flights:traveldate": "Travel Date",
  "transports:flightbooking:taxes": "Taxes",
  "transports:flightbooking:taxes:included": "Included",
  "transports:flightbooking:adult": "Adult",
  "transports:flightbooking:adults": "Adults",
  "transports:flightbooking:paybutton": "Pay",
  "transports:flightbooking:reservebutton": "Confirm your reservation",
  "transports:flights:participants:selection:error":
    "You need to select {0} participants to match passengers criteria.",
  "transports:flights:totalpricewarning:singular":
    "Total price includes taxes + fees for {0} adult. ",
  "transports:flights:totalpricewarning:plural":
    "Total price includes taxes + fees for {0} adults. ",
  "transports:flights:totalpricewarning:baggagelink": "Additional bag fees",
  "transports:flights:totalpricewarning:baggage:suffix":
    "and other fees may apply.",
  "transports:flights:currencywarning":
    "We remind you that all prices are displayed in canadian currency (CAD).",
  "transports:flights:requests": "Requests",
  "transports:flights:participants:passport": "Passport Number",
  "transports:flights:criteria:arrivals": "Arrivals",
  "transports:flights:criteria:departures": "Departures",
  "transports:flights:criteria:title": "Flight Search",
  "transports:flights:noresult": "No results found",
  "transports:flights:loading": "Loading",
  "transports:flights:searching": "Searching for flights",

  "transports:flights:billingmethod": "Biling Method",
  "transports:flights:billingmethod:local": "Bill issued by: Ferme Voyage",
  "transports:flights:booking:search": "Search",
  "transports:flights:booking:summary": "Summary",
  "transports:flights:booking:checkout": "Checkout",
  "transports:flights:booking:confirmation": "Confirmation",
  "transports:flights:billingmethod:paying":
    "Please wait... We are proceeding with your payment.",
  "transports:flights:airport:aria": "Airport Picker",
  "transports:flights:airport:origin": "Origin",
  "transports:flights:airport:destination": "Destination",
  "transports:flights:airport:origin:placeholder": "Leaving from",
  "transports:flights:airport:destination:placeholder": "Arriving to",
  "transports:flights:airportavailable": "Airports available",
  "transports:flights:buynow": "Acheter maintenant",
  "transports:flights:booknow": "Book now",
  "transports:flights:airport:layover": "layover",
  "transports:flights:airport:stops": "stops",
  "transports:flights:airport:nonstops": "Non-stop",
  "transports:flights:operatedby": "Operated By",
  "transports:flights:summary": "Flight Summary",
  "transports:flights:summary:participants": "Participants on the flight",
  "transports:flights:participants:code": "Participant Code",
  "transports:flights:participants:estimatedarrival": "Estimated Arrival Date",
  "transports:flights:notfound":
    "Oups... No flights available with those criteria",
  "transports:flights:participants:estimateddeparture":
    "Estimated Departure Date",
  "transports:flights:pnr": "Reservation Number",
  "transports:flights:pnr:internal": "Internal",
  "transports:flights:confirmation:header": "Thanks you for booking with FERME",
  "transports:flights:confirmation:text": "Flight Reservation Confirmation",
  "transports:flights:request:instruction:departure":
    "Please select the request conserning the departures.",
  "transports:flights:request:instruction:arrival":
    "Please select the request conserning the arrivals",

  "transports:flights:back:selection": "Back to selection",
  "transports:flights:back:search": "Back to search results",
  "transports:flights:back": "Back",
  "transports:flights:tripsummary": "Trip Summary",
  "transports:flights:tripsummary:back": "Back to search results",
  "transports:flights:participants:selection": "Selected passengers",
  "transports:flights:participants:count": "Number of participants",

  "transports:summary:passenger": "Passenger",
  "transports:summary:code": "Code",
  "transports:summary:request": "Request Number",
  "transports:summary:undefined": "Undefined Aerial Transportation",

  "transports:summary:pricing": "Pricing",

  "chat:panel:title": "Chat with your agent",
  "chat:title": "Chat",
  "chat:notification:message":
    "You received a new instant message from your agent.",
  "chat:notification:ack": "Chat with your agent",
  "contacts:email": "Email",
  "contacts:phone": "Phone",
  "contacts:cellphone": "Cellphone",
  "contacts:roles": "Roles & options",
  "contacts:communication": "Communication",

  "users:title": "Name",
  "users:email": "Email",
  "users:role": "Role",

  "error:500:title": "Something went wrong",
  "error:500:id": "Event Id",
  "error:500:message": "Message",
  "error:500:back": "Back to homepage",
  "error:500:date": "Date",
  "error:500:message:default":
    "A fatal error occured. We are looking into the issue.",

  "validator:mandatory:error": "Mandatory field, cannot be left empty",
  "validator:number:error": "Only allow numeric values",
  "validator:email:error": "Should be a valid email address",
  "validator:decimal:error": "Only allow decimal values",
  "validator:invalid:error": "Invalid value",
  "validator:only:allow": "Only allow",
  "validator:validvalue": "Select a valid value",
  "validator:validdate": "Should be a valid date",
  "validator:length:between": "Should have length between",
  "validator:length:maxlength": "The maximum length for this field is ",
  "validator:characters": "characters",
  "validator:compare:error": "The confirmation is wrong",

  "commandbar:arialabel":
    "Use left and right arrow keys to navigate between commands",
  "global:peoplepicker": "People Picker",
  "global:ascending": "ascending",
  "global:descending": "descending",
  "global:close": "Close",
  "global:open": "Open",
  "global:yes": "Yes",
  "global:no": "No",
  "global:apply": "Apply",
  "global:active": "Active",
  "global:inactive": "Inactive",
  "global:ok": "Ok",
  "global:cancel": "Cancel",
  "grid:filterby": "Filter by",
  "grid:filterby:placeholder": "Enter text to filter...",
  "grid:sort": "Sort",
  "grid:filter": "Filter",
  "grid:selectall": "Select all",
  "grid:selectnone": "Unselect all",
  "grid:clearfilters": "Clear Filters",
  "grid:gridview": "Grid View",
  "grid:list": "Detailed List",
  "grid:summary": "Summary",
  "grid:export": "Export",
  "grid:export:full": "Export all",
  "grid:export:filtered": "Export filtered",
  "global:copyrights":
    "2014-2021 © All rights reserved &centerdot; FERME Québec",
  "global:justnow": "just now",
  "global:days": "days",
  "global:daysbefore": "days before",
  "global:day": "day",
  "global:daybefore": "day before",
  "global:search": "Search",
  "global:noagent": "No agent assigned",
  "global:print": "Print",
  "global:between": "between",
  "global:and": "and",
  "global:of": "of",
  "global:emptyitemsmessage": "No elements to display.",

  // Calendar Strings
  "calendars:months:january": "January",
  "calendars:months:february": "February",
  "calendars:months:march": "March",
  "calendars:months:april": "April",
  "calendars:months:may": "May",
  "calendars:months:june": "June",
  "calendars:months:july": "July",
  "calendars:months:august": "August",
  "calendars:months:september": "September",
  "calendars:months:october": "October",
  "calendars:months:november": "November",
  "calendars:months:december": "December",

  "calendars:months:january:short": "Jan",
  "calendars:months:february:short": "Feb",
  "calendars:months:march:short": "Mar",
  "calendars:months:april:short": "Apr",
  "calendars:months:may:short": "May",
  "calendars:months:june:short": "Jun",
  "calendars:months:july:short": "Jul",
  "calendars:months:august:short": "Aug",
  "calendars:months:september:short": "Sep",
  "calendars:months:october:short": "Oct",
  "calendars:months:november:short": "Nov",
  "calendars:months:december:short": "Dec",

  "calendars:days:sunday": "Sunday",
  "calendars:days:monday": "Monday",
  "calendars:days:tuesday": "Tuesday",
  "calendars:days:wednesday": "Wednesday",
  "calendars:days:thursday": "Thursday",
  "calendars:days:friday": "Friday",
  "calendars:days:saturday": "Saturday",

  "calendars:days:sunday:short": "S",
  "calendars:days:monday:short": "M",
  "calendars:days:tuesday:short": "T",
  "calendars:days:wednesday:short": "W",
  "calendars:days:thursday:short": "T",
  "calendars:days:friday:short": "F",
  "calendars:days:saturday:short": "S",
  "calendars:today": "Go to today",
  "calendars:weeks": "Week number {0}",
  "calendars:previousmonth": "Previous month",
  "calendars:previousyear": "Previous year",
  "calendars:nextmonth": "Next month",
  "calendars:nextyear": "Next year",
  "calendars:previousyearrange": "Next year range",
  "calendars:previousmonthrange": "Previous year range",

  "country:Canada": "Canada",
  "country:Mexique": "Mexico",
  "country:Guatemala": "Guatemala",
  "country:Jamaïque": "Jamaica",
  "country:Honduras": "Honduras",
  "country:Grenade": "Granada",
  "country:Sainte Lucie": "Santa Lucia",
  "country:Salvador": "El Salvador",
  "country:Trinidad et Tobago": "Trinidad and Tobago",
  "country:Barbades": "Barbados",
  "country:Panama": "Panama",
  "country:Costa Rica": "Costa Rica",
  "country:Antilles": "Antigua and Barbuda",
  "country:République Dominicaine": "Dominican Republic",
  "country:Nicaragua": "Nicaragua",
  "country:Saint-Vincent": "Saint-Vincent",
  "country:États-Unis": "United States",
  "country:Tunisie": "Tunesia",
  "country:France": "France",
  "country:Italie": "Italy",
  "country:Algérie": "Algeria",
  "country:Madagascar": "Madagascar",
  "country:Colombie": "Colombia",
  "country:Maroc": "Morocco",

  "dialog:error:title": "Error",
  "dialog:error:moneris": "An error has occurred with Moneris.",
};
