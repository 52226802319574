import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IRequestParticipantItemStyleProps,
  IRequestParticipantItemStyles,
} from "../RequestParticipantItem";
import {
  IRequestParticipantsControlStyleProps,
  IRequestParticipantsControlStyles,
} from "../RequestParticipantsControl";

export interface IRequestParticipantsSectionSubComponentStyles {
  participantItem?: IStyleFunctionOrObject<
    IRequestParticipantItemStyleProps,
    IRequestParticipantItemStyles
  >;
  participants?: IStyleFunctionOrObject<
    IRequestParticipantsControlStyleProps,
    IRequestParticipantsControlStyles
  >;
}

export interface IRequestParticipantsSectionStyles
  extends IAmigoBaseStyles<IRequestParticipantsSectionSubComponentStyles> {}

export interface IRequestParticipantsSectionStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantsSectionClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantsSectionStyleProps,
    IRequestParticipantsSectionStyles
  >,
  props?: IRequestParticipantsSectionStyleProps
): [
  IProcessedStyleSet<IRequestParticipantsSectionStyles>,
  IRequestParticipantsSectionSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantsSectionStyleProps,
  IRequestParticipantsSectionStyles
> = (
  _props: IRequestParticipantsSectionStyleProps
): IRequestParticipantsSectionStyles => {
  return {};
};
