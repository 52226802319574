import { Icon, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getDossierImmigrationDocumentCardClassNames } from "./DossierImmigrationDocumentCard.styles";
import {
  IDossierImmigrationDocumentCardProps,
  IDossierImmigrationDocumentCardState,
} from "./DossierImmigrationDocumentCard.types";
import Moment from "moment";
import DateText from "src/components/DateText";

export class DossierImmigrationDocumentCardComponent extends React.Component<
  IDossierImmigrationDocumentCardProps,
  IDossierImmigrationDocumentCardState
> {
  constructor(props: IDossierImmigrationDocumentCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] =
      getDossierImmigrationDocumentCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={item.name}
        columnWidth={columnWidth}
        footerText={Moment(item.createdOn).format("YYYY-MM-DD HH:mm")}
        footerIconName={footerIconName ?? "Calendar"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <>
            <TooltipHost content={item.name}>
              <Icon iconName="DocLibrary" />
            </TooltipHost>
          </>
        }
      >
        <div className={classNames.root} style={{ height: "40px" }}>
          {item.processedOn && (
            <div>
              <Text
                variant="small"
                styles={this.props.tokens.defaultCardTextStyle}
              >
                {i18n.t("dossierimmigration:documents:processedon")}
              </Text>
              <DateText date={item.processedOn} />
            </div>
          )}
        </div>
        {/*
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.createdBy}
        </Text>
        */}
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.size}
        </Text>
      </GridViewCard>
    );
  }
}
