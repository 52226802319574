import {
  Icon,
  PersonaCoin,
  PersonaSize,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { GridViewCard } from "src/components/GridViewCard";
import { UserCallout } from "src/controls/UserCallout";
import i18n from "src/services/i18n";
import { getRequestCardClassNames } from "./RequestCard.styles";
import { IRequestCardProps, IRequestCardState } from "./RequestCard.types";

export class RequestCardComponent extends React.Component<
  IRequestCardProps,
  IRequestCardState
> {
  /*
  private renderContextualInfo = (item: IRequestReference): JSX.Element => {
    return (
      <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
        {item.nextArrival
          ? i18n.t("requests:nextarrival") + ": " + item.nextArrival
          : item.nextDeparture
          ? i18n.t("requests:nextdeparture") + ": " + item.nextDeparture
          : item.status === "Fermée" || item.status === "Closed"
          ? item.year.toString() + " - " + item.country
          : i18n.t("requests:noevent")}
      </Text>
    );
  };
  */

  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getRequestCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={item.name}
        columnWidth={columnWidth}
        footerText={`${item.participantCount} ${i18n.t(
          item.participantCount > 1 ? "requests:postes" : "requests:poste"
        )}`}
        footerIconName={footerIconName ?? "WorkforceManagement"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <>
            <CountryIcon
              {...this.props}
              styles={undefined}
              country={item.country}
            />
            <TooltipHost
              className={classNames.root}
              content={`${item.participantCount.toString()} ${i18n.t(
                item.participantCount > 1 ? "requests:postes" : "requests:poste"
              )}`}
            >
              <PersonaCoin
                initialsColor={"rgb(122, 117, 116)"}
                imageAlt={`${item.participantCount.toString()} ${i18n.t(
                  item.participantCount > 1
                    ? "requests:postes"
                    : "requests:poste"
                )}`}
                text={item.participantCount.toString()}
                coinSize={18}
                onRenderInitials={() => {
                  return <span>{item.participantCount.toString()}</span>;
                }}
                aria-label={`${item.participantCount.toString()} ${i18n.t(
                  item.participantCount > 1
                    ? "requests:postes"
                    : "requests:poste"
                )}`}
                size={PersonaSize.size16}
                style={{
                  fontSize: "14px",
                }}
              />
            </TooltipHost>
            {this.props.onPrint && (
              <TooltipHost content={i18n.t("requests:print")}>
                <Icon
                  ariaLabel={i18n.t("requests:print")}
                  iconName={"Print"}
                  onClick={(ev: React.MouseEvent<HTMLDivElement>) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.props.onPrint && this.props.onPrint(item);
                  }}
                  styles={this.props.tokens.defaultIconStyles}
                />
              </TooltipHost>
            )}
            {item.status === "Confirmée" && (
              <TooltipHost content={item.status}>
                <Icon
                  ariaLabel={item.status}
                  iconName="SingleBookmark"
                  styles={this.props.tokens.defaultIconStyles}
                />
              </TooltipHost>
            )}
            {item.status === "Fermée" && (
              <TooltipHost content={item.status}>
                <Icon
                  ariaLabel={item.status}
                  iconName="Archive"
                  styles={this.props.tokens.defaultIconStyles}
                />
              </TooltipHost>
            )}
          </>
        }
      >
        <Text styles={this.props.tokens.defaultCardTextStyle}>
          {item.status}
        </Text>
        <UserCallout
          {...this.props}
          styles={subComponentStyles?.userCallout}
          user={item.agent}
        />
      </GridViewCard>
    );
  }
}
