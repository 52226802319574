import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IEnterpriseHeaderSubComponentStyles {}

export interface IEnterpriseHeaderStyles
  extends IAmigoBaseStyles<IEnterpriseHeaderSubComponentStyles> {}

export interface IEnterpriseHeaderStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseHeaderClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseHeaderStyleProps,
    IEnterpriseHeaderStyles
  >,
  props?: IEnterpriseHeaderStyleProps
): [
  IProcessedStyleSet<IEnterpriseHeaderStyles>,
  IEnterpriseHeaderSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseHeaderStyleProps,
  IEnterpriseHeaderStyles
> = (_props: IEnterpriseHeaderStyleProps): IEnterpriseHeaderStyles => {
  return {
    root: [
      "amigo-EnterpriseHeader",
      {
        minHeight: "160px!important",
        display: "block",
        position: "relative",
        width: "100%",
      },
    ],
  };
};
