import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseAlertLevelProps } from "./EnterpriseAlertLevel.types";
import {
  getStyles,
  IEnterpriseAlertLevelStyleProps,
  IEnterpriseAlertLevelStyles,
} from "./EnterpriseAlertLevel.styles";
import { EnterpriseAlertLevelComponent } from "./EnterpriseAlertLevel.base";

/**
 * EnterpriseAlertLevel description
 */
export const EnterpriseAlertLevel = styled<
  IEnterpriseAlertLevelProps,
  IEnterpriseAlertLevelStyleProps,
  IEnterpriseAlertLevelStyles
>(EnterpriseAlertLevelComponent, getStyles);
