import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IParticipantAddressSummarySubComponentStyles {}

export interface IParticipantAddressSummaryStyles
  extends IAmigoBaseStyles<IParticipantAddressSummarySubComponentStyles> {}

export interface IParticipantAddressSummaryStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantAddressSummaryClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantAddressSummaryStyleProps,
    IParticipantAddressSummaryStyles
  >,
  props?: IParticipantAddressSummaryStyleProps
): [
  IProcessedStyleSet<IParticipantAddressSummaryStyles>,
  IParticipantAddressSummarySubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantAddressSummaryStyleProps,
  IParticipantAddressSummaryStyles
> = (
  _props: IParticipantAddressSummaryStyleProps
): IParticipantAddressSummaryStyles => {
  return {};
};
