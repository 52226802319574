import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface ILoginControlSubComponentStyles {}

export interface ILoginControlStyles
  extends IAmigoBaseStyles<ILoginControlSubComponentStyles> {
  title?: IStyle;
  spinner?: IStyle;
  leftActions?: IStyle;
  bottomActions?: IStyle;
}

export interface ILoginControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getLoginControlClassNames = (
  styles?: IStyleFunctionOrObject<ILoginControlStyleProps, ILoginControlStyles>,
  props?: ILoginControlStyleProps
): [
  IProcessedStyleSet<ILoginControlStyles>,
  ILoginControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ILoginControlStyleProps,
  ILoginControlStyles
> = (_props: ILoginControlStyleProps): ILoginControlStyles => {
  return {
    root: [
      "amigo-Login",
      {
        maxWidth: "100%",
        width: "420px",
        maxHeight: "100%",
        marginLeft: "50%",
        [MediaQueries.tablet]: {
          width: "45vw",
          position: "absolute",
          right: 0,
        },
        "@media (min-width: 480px)": {
          maxWidth: "100%",
          width: "420px",
          maxHeight: "100%",
        },
        [MediaQueries.mobile]: {
          width: "100%",
          position: "absolute",
          right: 0,
          bottom: 0,
          marginLeft: 0,
        },
        [MediaQueries.small]: {
          position: "absolute",
          left: 0,
          bottom: 0,
          marginLeft: 0,
        },
      },
    ],
    title: [
      "amigo-Login-Title",
      {
        maxWidth: "280px",
        marginRight: "15px",
      },
    ],
    spinner: [
      "amigo-Login-Spinner",
      {
        width: "152px",
      },
    ],
    leftActions: [
      "amigo-Login-LeftActions",
      {
        display: "none",
        selectors: {
          [MediaQueries.landscape]: {
            display: "block",
          },
        },
      },
    ],
    bottomActions: [
      "amigo-Login-BottomActions",
      {
        display: "block",
        selectors: {
          [MediaQueries.landscape]: {
            display: "none",
          },
        },
      },
    ],
  };
};
