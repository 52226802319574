import * as React from "react";
import { IChoiceGroupOption, ChoiceGroup } from "@fluentui/react";

export interface IPaymentMethodTypeProps {
  mobile: boolean;
  cardType: string;
  onChange: (cardType: string) => void;
}

export interface IPaymentMethodTypeState {}

export class PaymentMethodType extends React.Component<
  IPaymentMethodTypeProps,
  IPaymentMethodTypeState
> {
  constructor(props: IPaymentMethodTypeProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const width: string = this.props.mobile ? "100px" : "120px";
    const innerWidth: string = this.props.mobile ? "80px" : "100px";
    const options: IChoiceGroupOption[] = [
      {
        key: "Visa",
        text: "Visa",
        styles: {
          root: { width: width },
          choiceFieldWrapper: { width: width, maxWidth: width },
          labelWrapper: {
            fontSize: "13px",
            width: innerWidth,
            maxWidth: `${innerWidth}!important`,
          },
        },
        width: width,
        iconProps: {
          imageProps: {
            src: "/img/cards/visa.png",
            width: "51px",
            height: "32px",
          },
        },
      },
      {
        key: "MasterCard",
        text: "MasterCard",
        styles: {
          root: { width: width },
          choiceFieldWrapper: { width: width, maxWidth: width },
          labelWrapper: {
            fontSize: "13px",
            width: innerWidth,
            maxWidth: `${innerWidth}!important`,
          },
        },
        width: width,
        iconProps: {
          imageProps: {
            src: "/img/cards/mastercard.png",
            width: "51px",
            height: "32px",
          },
        },
      },
      {
        key: "American Express",
        text: "American Express",
        styles: {
          root: { width: width },
          choiceFieldWrapper: { width: width, maxWidth: width },
          labelWrapper: {
            fontSize: "13px",
            width: innerWidth,
            maxWidth: `${innerWidth}!important`,
          },
        },
        width: width,
        iconProps: {
          imageProps: {
            src: "/img/cards/amex.png",
            width: "51px",
            height: "32px",
          },
        },
      },
    ];
    return (
      <ChoiceGroup
        label=""
        selectedKey={this.props.cardType}
        options={options}
        onChange={(
          _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          option?: IChoiceGroupOption
        ) => {
          this.props.onChange(option === undefined ? "Visa" : option.key);
        }}
      />
    );
  }
}
