import * as React from "react";
import { getArrivalAssistedControlClassNames } from "./ArrivalAssistedControl.styles";
import {
  IArrivalAssistedControlState,
  IArrivalAssistedControlProps,
} from "./ArrivalAssistedControl.types";
import {
  Stack,
  MessageBar,
  MessageBarType,
  ActivityItem,
  Icon,
  Text,
  Link,
  Separator,
} from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { ArrivalRequestService } from "src/services/Messages/ArrivalRequestService";
import { objectId } from "src/models/IObjectReference";

export class ArrivalAssistedControlComponent extends React.Component<
  IArrivalAssistedControlProps,
  IArrivalAssistedControlState
> {
  constructor(props: IArrivalAssistedControlProps) {
    super(props);
    this.state = {};
    this.getArrivalRequest();
  }

  componentDidUpdate() {
    if (
      !this.state.request ||
      this.props.message.itemId !== this.state.request.id
    ) {
      this.getArrivalRequest();
    }
  }

  private getArrivalRequest = () => {
    ArrivalRequestService.getArrivalRequest(
      objectId(this.props.enterprise),
      this.props.message.itemId
    ).then((x) => {
      this.setState({
        request: x,
      });
    });
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getArrivalAssistedControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { request } = this.state;
    const selectedFlightDetails = request?.selectedFlightDetails;
    return (
      <div className={classNames.root}>
        <h3>{i18n.t("messages:assisted:newarrival:summary:title")}</h3>
        <Stack
          horizontal={!mobile}
          tokens={{ childrenGap: mobile ? 10 : 20 }}
          style={{ marginLeft: "5px" }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("messages:assisted:newdeparture:requestnumber")}
              content={request?.requestNumber ?? ""}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("messages:assisted:newdeparture:requestdate")}
              content={request?.requestDate ?? ""}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:participantcount")}
              content={request?.participants.length ?? 0}
            />
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          {(request?.landTransportIncluded ?? false) && (
            <MessageBar
              messageBarType={MessageBarType.info}
              messageBarIconProps={{
                iconName: "Bus",
              }}
            >
              {i18n.t("messages:assisted:newdeparture:landrequested")}
            </MessageBar>
          )}

          {(request?.preauth ?? false) && (
            <MessageBar messageBarType={MessageBarType.info}>
              {i18n.t("messages:assisted:newarrival:preauthrequested")}
            </MessageBar>
          )}

          <div>
            <div style={{ clear: "both" }}></div>
          </div>

          {selectedFlightDetails && (
            <div
              key={`selected_flight_${request?.id ?? -1}`}
              style={{ float: mobile ? undefined : "left", padding: "5px" }}
            >
              <ActivityItem
                key={`selected_flight_activity_${request?.id ?? -1}`}
                activityIcon={<Icon iconName={"Airplane"} />}
                comments={
                  <Stack
                    key={"comments"}
                    horizontal={!mobile}
                    tokens={{ childrenGap: 5 }}
                  >
                    <LabelField
                      key={"1"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:newdeparture:flight")}
                      content={selectedFlightDetails.flightNumber}
                    />
                    <Separator vertical key={1} />
                    <LabelField
                      key={"origincity"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:newdeparture:origin")}
                      content={selectedFlightDetails.originCode}
                    />
                    <Separator vertical key={2} />
                    <LabelField
                      key={"destinationcity"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t(
                        "messages:assisted:newdeparture:destination"
                      )}
                      content={selectedFlightDetails.destinationCode}
                    />
                  </Stack>
                }
                timeStamp={selectedFlightDetails.departureDate}
                activityDescription={[
                  <Text key={1} className={classNames.nameText}>
                    {i18n.t("transports:requests:legend:charter")}
                  </Text>,
                ]}
              />
            </div>
          )}

          {request?.participants.map((x) => (
            <div
              key={`card_participant_${x.id}`}
              style={{ float: mobile ? undefined : "left", padding: "5px" }}
            >
              <ActivityItem
                activityIcon={<Icon iconName={"Contact"} />}
                comments={
                  <Stack
                    key={"comments"}
                    horizontal={!mobile}
                    tokens={{ childrenGap: 5 }}
                  >
                    <LabelField
                      key={"destinationcity"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t(
                        "messages:assisted:newdeparture:destinationcity"
                      )}
                      content={
                        x.city && x.city != null && x.cities.length > 0
                          ? x.cities[0].name
                          : i18n.t("messages:assisted:newdeparture:other")
                      }
                    />
                  </Stack>
                }
                timeStamp={x.date}
                activityDescription={[
                  x.workerId ? (
                    <Link
                      key={1}
                      className={classNames.nameText}
                      onClick={() => {
                        this.props.route.history.push(
                          `/participants/${x.workerId}`
                        );
                      }}
                    >
                      {x.name}
                    </Link>
                  ) : (
                    <Text>{x.name}</Text>
                  ),
                  <span
                    style={{ marginLeft: "5px" }}
                    key={2}
                  >{` ${x.code}`}</span>,
                ]}
              />
            </div>
          ))}
        </Stack>
      </div>
    );
  }
}
