import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IUnauthenticatedLayoutStyleProps,
  IUnauthenticatedLayoutStyles,
} from "src/controls/UnauthenticatedLayout";
import {
  IForgotPasswordControlStyleProps,
  IForgotPasswordControlStyles,
} from "../ForgotPasswordControl";

export interface IForgotPasswordPageSubComponentStyles {
  layout?: IStyleFunctionOrObject<
    IUnauthenticatedLayoutStyleProps,
    IUnauthenticatedLayoutStyles
  >;
  control?: IStyleFunctionOrObject<
    IForgotPasswordControlStyleProps,
    IForgotPasswordControlStyles
  >;
}

export interface IForgotPasswordPageStyles
  extends IAmigoBaseStyles<IForgotPasswordPageSubComponentStyles> {}

export interface IForgotPasswordPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getForgotPasswordPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IForgotPasswordPageStyleProps,
    IForgotPasswordPageStyles
  >,
  props?: IForgotPasswordPageStyleProps
): [
  IProcessedStyleSet<IForgotPasswordPageStyles>,
  IForgotPasswordPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IForgotPasswordPageStyleProps,
  IForgotPasswordPageStyles
> = (_props: IForgotPasswordPageStyleProps): IForgotPasswordPageStyles => {
  return {};
};
