import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestsPageProps } from "./RequestsPage.types";
import {
  getStyles,
  IRequestsPageStyleProps,
  IRequestsPageStyles,
} from "./RequestsPage.styles";
import { RequestsPageComponent } from "./RequestsPage.base";

/**
 * RequestsPage description
 */
export const RequestsPage = styled<
  IRequestsPageProps,
  IRequestsPageStyleProps,
  IRequestsPageStyles
>(RequestsPageComponent, getStyles);
