import * as React from "react";
import { MessageBarType, MessageBar, Checkbox } from "@fluentui/react";
import i18n from "src/services/i18n";

export interface IPaymentAuthorizationMessagesProps {
  billingAuthorized: boolean;
  transportAuthorized: boolean;
  errorMessage: string | undefined;
  saveOnVaultDB: boolean;
  onBillingAuthorized: (newValue: boolean) => void;
  onTransportAuthorized: (newValue: boolean) => void;
  onSaveOnVaultDB: (newValue: boolean) => void;
  dialog: boolean;
}

export interface IPaymentAuthorizationMessagesState {}

export class PaymentAuthorizationMessages extends React.Component<
  IPaymentAuthorizationMessagesProps,
  IPaymentAuthorizationMessagesState
> {
  constructor(props: IPaymentAuthorizationMessagesProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { dialog } = this.props;
    return (
      <div>
        <div style={{ height: "20px" }}></div>
        <MessageBar messageBarType={MessageBarType.info}>
          {i18n.t("enterprises:paymentmethods:authorize:transport")}
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Checkbox
              label={i18n.t("enterprises:paymentmethods:authorize:accept")}
              checked={this.props.transportAuthorized}
              onChange={(
                _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean
              ) => {
                this.props.onTransportAuthorized(checked ?? false);
              }}
            />
          </div>
        </MessageBar>
        <div style={{ height: "20px" }}></div>
        <MessageBar
          messageBarType={dialog ? MessageBarType.info : MessageBarType.warning}
        >
          {i18n.t("enterprises:paymentmethods:authorize:billing")}
          <div style={{ paddingTop: "10px", paddingBottom: "10px" }}>
            <Checkbox
              className={dialog ? undefined : "ms-Checkbox-required"}
              label={i18n.t("enterprises:paymentmethods:authorize:accept")}
              checked={this.props.billingAuthorized}
              onChange={(
                _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                checked?: boolean
              ) => {
                this.props.onBillingAuthorized(checked ?? false);
              }}
            />
            {this.props.errorMessage && (
              <div style={{ marginTop: "3px" }}>
                <span style={{ color: "rgb(164, 38, 44)" }}>
                  {this.props.errorMessage}
                </span>
              </div>
            )}
          </div>
        </MessageBar>
        <div style={{ height: "20px" }}></div>
      </div>
    );
  }
}
