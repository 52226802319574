import * as React from "react";
import { getAssistedMessageControlClassNames } from "./AssistedMessageControl.styles";
import {
  IAssistedMessageControlState,
  IAssistedMessageControlProps,
} from "./AssistedMessageControl.types";
import AssistedMessageService from "src/services/Messages/AssistedMessageService";
import { MessageBar, MessageBarType } from "@fluentui/react";
import i18n from "src/services/i18n";

export class AssistedMessageControlComponent extends React.Component<
  IAssistedMessageControlProps,
  IAssistedMessageControlState
> {
  constructor(props: IAssistedMessageControlProps) {
    super(props);
    this.state = {
      enabled: false,
    };
  }

  private commTypeToLabel = (value: string): string => {
    switch (value) {
      case "STANDARD":
        return i18n.t("messages:type:standard");
      case "GENERAL":
        return i18n.t("messages:type:general");
      case "DEMANDE_DEPART":
        return i18n.t("messages:type:newdeparture");
      case "DEMANDE_ARRIVEE":
        return i18n.t("messages:type:newarrival");
      case "TRANSPORT_ARRIVEE":
        return i18n.t("messages:type:newarrival:transport");
      case "DEMANDE_DEPART_MAJ":
        return i18n.t("messages:type:changedeparture");
      case "TRANSPORT_ARRIVEE_MAJ":
        return i18n.t("messages:type:newarrival:transport");
      case "INSPECTION":
        return i18n.t("messages:type:inspection");
    }
    return i18n.t("messages:type:standard");
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getAssistedMessageControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        {this.props.message.type.code === "STANDARD" ||
          (this.props.message.type.code === "GENERAL" && (
            <MessageBar messageBarType={MessageBarType.info}>
              {this.props.message.type.name}
            </MessageBar>
          ))}
        {this.props.message.type.code !== "STANDARD" &&
          this.props.message.type.code !== "GENERAL" && (
            <div
              style={{
                border: "1px solid #000000",
              }}
            >
              <MessageBar
                messageBarType={MessageBarType.info}
                onDismiss={() => {
                  this.setState({
                    enabled: !this.state.enabled,
                  });
                  return false;
                }}
                messageBarIconProps={{
                  iconName: AssistedMessageService.getAssistedTypeIcon(
                    this.props.message.type
                  ),
                }}
                dismissIconProps={
                  this.props.message.response
                    ? {
                        style: {
                          display: "none",
                          cursor: "default",
                        },
                      }
                    : {
                        iconName: this.state.enabled
                          ? "CollapseContent"
                          : "DrillDown",
                      }
                }
              >
                {this.commTypeToLabel(this.props.message?.type?.code)}
              </MessageBar>
              {!this.props.message.response && (
                <div
                  style={
                    this.state.enabled
                      ? { padding: mobile ? 5 : 10 }
                      : undefined
                  }
                >
                  {this.state.enabled &&
                    AssistedMessageService.getAssistedComponent(
                      this.props,
                      this.props.enterprise,
                      this.props.message
                    )}
                </div>
              )}
            </div>
          )}
      </div>
    );
  }
}
