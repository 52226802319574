import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentBillingSummaryProps } from "./NewPaymentBillingSummary.types";
import {
  getStyles,
  INewPaymentBillingSummaryStyleProps,
  INewPaymentBillingSummaryStyles,
} from "./NewPaymentBillingSummary.styles";
import { NewPaymentBillingSummaryComponent } from "./NewPaymentBillingSummary.base";

/**
 * NewPaymentBillingSummary description
 */
export const NewPaymentBillingSummary = styled<
  INewPaymentBillingSummaryProps,
  INewPaymentBillingSummaryStyleProps,
  INewPaymentBillingSummaryStyles
>(NewPaymentBillingSummaryComponent, getStyles);
