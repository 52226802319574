import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightPersonalizedOfferCardProps } from "./FlightPersonalizedOfferCard.types";
import {
  getStyles,
  IFlightPersonalizedOfferCardStyleProps,
  IFlightPersonalizedOfferCardStyles,
} from "./FlightPersonalizedOfferCard.styles";
import { FlightPersonalizedOfferCardComponent } from "./FlightPersonalizedOfferCard.base";

/**
 * FlightPersonalizedOfferCard
 */
export const FlightPersonalizedOfferCard = styled<
  IFlightPersonalizedOfferCardProps,
  IFlightPersonalizedOfferCardStyleProps,
  IFlightPersonalizedOfferCardStyles
>(FlightPersonalizedOfferCardComponent, getStyles);
