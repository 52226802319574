import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IBillingDetailsPanelContentSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IBillingDetailsPanelContentStyles
  extends IAmigoBaseStyles<IBillingDetailsPanelContentSubComponentStyles> {}

export interface IBillingDetailsPanelContentStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getBillingDetailsPanelContentClassNames = (
  styles?: IStyleFunctionOrObject<
    IBillingDetailsPanelContentStyleProps,
    IBillingDetailsPanelContentStyles
  >,
  props?: IBillingDetailsPanelContentStyleProps
): [
  IProcessedStyleSet<IBillingDetailsPanelContentStyles>,
  IBillingDetailsPanelContentSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IBillingDetailsPanelContentStyleProps,
  IBillingDetailsPanelContentStyles
> = (
  _props: IBillingDetailsPanelContentStyleProps
): IBillingDetailsPanelContentStyles => {
  return {
    root: ["amigo-BillingDetailsPanelContent", {}],
  };
};
