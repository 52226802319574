import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IParticipantHistoryCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IIconStyles;
}

export interface IParticipantHistoryCardStyles
  extends IAmigoBaseStyles<IParticipantHistoryCardSubComponentStyles> {}

export interface IParticipantHistoryCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantHistoryCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantHistoryCardStyleProps,
    IParticipantHistoryCardStyles
  >,
  props?: IParticipantHistoryCardStyleProps
): [
  IProcessedStyleSet<IParticipantHistoryCardStyles>,
  IParticipantHistoryCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantHistoryCardStyleProps,
  IParticipantHistoryCardStyles
> = (
  _props: IParticipantHistoryCardStyleProps
): IParticipantHistoryCardStyles => {
  return {
    root: ["amigo-ParticipantHistoryCard", {}],
  };
};
