import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IEnterpriseContactInfoSubComponentStyles {
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IEnterpriseContactInfoStyles
  extends IAmigoBaseStyles<IEnterpriseContactInfoSubComponentStyles> {}

export interface IEnterpriseContactInfoStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseContactInfoClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseContactInfoStyleProps,
    IEnterpriseContactInfoStyles
  >,
  props?: IEnterpriseContactInfoStyleProps
): [
  IProcessedStyleSet<IEnterpriseContactInfoStyles>,
  IEnterpriseContactInfoSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseContactInfoStyleProps,
  IEnterpriseContactInfoStyles
> = (
  _props: IEnterpriseContactInfoStyleProps
): IEnterpriseContactInfoStyles => {
  return {
    root: {
      width: "100%",
      padding: "10px",
    },
  };
};
