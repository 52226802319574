import * as React from "react";
import { getForgotPasswordControlClassNames } from "./ForgotPasswordControl.styles";
import {
  IForgotPasswordControlState,
  IForgotPasswordControlProps,
} from "./ForgotPasswordControl.types";
import {
  Dialog,
  DialogType,
  ResponsiveMode,
  ContextualMenuItemType,
  Stack,
  MessageBar,
  MessageBarType,
  DialogFooter,
  DefaultButton,
  Spinner,
  TextField,
  PrimaryButton,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { Redirect } from "react-router";
import { validate } from "src/utils/validator";
import { ForgotPasswordSchema } from "src/models/IForgotPasswordModel";
import AuthService from "src/services/AuthService";

export class ForgotPasswordControlComponent extends React.Component<
  IForgotPasswordControlProps,
  IForgotPasswordControlState
> {
  constructor(props: IForgotPasswordControlProps) {
    super(props);
    const { route } = this.props;
    const lstate = route.location.state as any;
    this.state = {
      success: false,
      model: {
        username: (
          lstate?.username ??
          localStorage.getItem("lastUsername") ??
          ""
        )
          .toLowerCase()
          .trim(),
      },
      errors: [],
      message: "",
      progress: false,
      redirect: false,
    };
    this.onUsernameChange = this.onUsernameChange.bind(this);
  }

  onUsernameChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    if (newValue === undefined) this.setState({ model: { username: "" } });
    else this.setState({ model: { username: newValue } });
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getForgotPasswordControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return !this.state.redirect ? (
      <div className={classNames.root}>
        <Dialog
          hidden={false}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: (
              <span
                dangerouslySetInnerHTML={{ __html: i18n.t("forgot:title") }}
              ></span>
            ),
            subText: this.state.success ? " " : i18n.t("forgot:instructions"),
            showCloseButton: false,
            responsiveMode: ResponsiveMode.large,
            titleProps: {
              className: classNames.title,
            },
            topButtonsProps: [
              {
                iconProps: {
                  iconName: "LocaleLanguage",
                  className: "NavBarButton",
                },
                menuIconProps: {
                  style: { display: "none" },
                  iconName: undefined,
                },
                menuProps: {
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "english",
                      text: i18n.t("language:en"),
                      style: {
                        fontWeight:
                          this.props.language === "en" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("en"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: "french",
                      text: i18n.t("language:fr"),
                      style: {
                        fontWeight:
                          this.props.language === "fr" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("fr"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    } /*,
                                          {
                                              key: 'spanish',
                                              text: i18n.t('language:es'),
                                              onClick: () => this.props.onLanguageChanged('es')
                                          } */,
                  ],
                },
              },
            ],
          }}
          modalProps={{
            isBlocking: true,
            containerClassName: classNames.root,
            styles: { main: { minWidth: 270 } },
            dragOptions: undefined,
            layerProps: {},
          }}
        >
          {this.state.success ? (
            <div>
              <Stack>
                <MessageBar messageBarType={MessageBarType.success}>
                  {i18n.t("forgot:success")}
                </MessageBar>
              </Stack>
              <DialogFooter>
                <DefaultButton
                  data-automation-id="nvx:forgotpassword:cancel"
                  onClick={() => {
                    this.setState({
                      redirect: true,
                      success: false,
                    });
                  }}
                  text={i18n.t("reset:back")}
                />
              </DialogFooter>
            </div>
          ) : (
            <div>
              {this.state.progress ? (
                <Stack
                  horizontalAlign={"center"}
                  verticalAlign={"center"}
                  verticalFill={true}
                  className={classNames.spinner}
                >
                  <Spinner
                    label={i18n.t("global:pleasewait")}
                    ariaLive="assertive"
                    labelPosition="right"
                  />
                </Stack>
              ) : (
                <Stack>
                  {!this.state.message || this.state.message.length === 0 ? (
                    <div style={{ height: "32px" }}></div>
                  ) : (
                    <MessageBar messageBarType={MessageBarType.error}>
                      {this.state.message}
                    </MessageBar>
                  )}
                  <TextField
                    autoFocus
                    type="text"
                    label={i18n.t("forgot:username")}
                    data-automation-id="nvx:forgotpassword:username"
                    name="username"
                    iconProps={{ iconName: "UserOptional" }}
                    errorMessage={this.state.errors.username}
                    value={this.state.model.username}
                    onChange={this.onUsernameChange}
                    onKeyPress={(e: any) =>
                      e.key === "Enter" && this.submitForgot__(e)
                    }
                  />
                </Stack>
              )}
              <DialogFooter>
                <PrimaryButton
                  data-automation-id="nvx:forgotpassword:submit"
                  disabled={this.state.progress}
                  onClick={this.submitForgot}
                  text={i18n.t("forgot:forgotcommand")}
                />
                <DefaultButton
                  data-automation-id="nvx:forgotpassword:cancel"
                  disabled={this.state.progress}
                  onClick={() => {
                    this.setState({
                      redirect: true,
                    });
                  }}
                  text={i18n.t("forgot:cancelcommand")}
                />
              </DialogFooter>
            </div>
          )}
        </Dialog>
      </div>
    ) : (
      <Redirect to="/" />
    );
  }

  private submitForgot__ = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    e.preventDefault();
    this.executeForgotPassword();
  };

  private submitForgot = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ): void => {
    e.preventDefault();
    this.executeForgotPassword();
  };

  private executeForgotPassword = (): void => {
    const errors: any = validate(ForgotPasswordSchema, this.state.model);
    if (Object.keys(errors).length === 0) {
      AuthService.forgotPassword(this.state.model)
        .then((x) => {
          if (x.status === 200) {
            switch (x.data.result) {
              case 0:
              case 2:
                this.setState({
                  errors: [],
                  message: i18n.t("forgot:notfound"),
                  progress: false,
                });
                break;
              case 1:
                this.setState({
                  errors: [],
                  message: i18n.t("forgot:lockederror"),
                  progress: false,
                });
                break;
              case 3:
                this.setState({
                  errors: [],
                  message: i18n.t("forgot:accounterror"),
                  progress: false,
                });
                break;
              case 4:
                this.setState({
                  errors: [],
                  message: i18n.t("forgot:error"),
                  progress: false,
                });
                break;
              case 99:
                this.setState({
                  errors: [],
                  message: "",
                  success: true,
                  progress: false,
                });
                break;
              default:
                this.setState({
                  errors: [],
                  message: i18n.t("forgot:notfound"),
                  progress: false,
                  success: false,
                });
                break;
            }
          }
        })
        .catch(() => {
          this.setState({
            errors: [],
            message: i18n.t("forgot:notfound"),
            progress: false,
            success: false,
          });
        });

      this.setState({
        errors: [],
        message: "",
        progress: true,
        success: false,
      });
    } else {
      this.setState({
        errors: errors,
        message: "",
        progress: false,
        success: false,
      });
    }
  };
}
