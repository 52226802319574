import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IImmigrationControlStyleProps,
  IImmigrationControlStyles,
} from "../DossierImmigrationControl";

export interface IDossierImmigrationPageSubComponentStyles {
  control?: IStyleFunctionOrObject<
    IImmigrationControlStyleProps,
    IImmigrationControlStyles
  >;
}

export interface IDossierImmigrationPageStyles
  extends IAmigoBaseStyles<IDossierImmigrationPageSubComponentStyles> {}

export interface IDossierImmigrationPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getImmigrationPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationPageStyleProps,
    IDossierImmigrationPageStyles
  >,
  props?: IDossierImmigrationPageStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationPageStyles>,
  IDossierImmigrationPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationPageStyleProps,
  IDossierImmigrationPageStyles
> = (
  _props: IDossierImmigrationPageStyleProps
): IDossierImmigrationPageStyles => {
  return {};
};
