import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportSummaryCardProps } from "./TransportSummaryCard.types";
import {
  getStyles,
  ITransportSummaryCardStyleProps,
  ITransportSummaryCardStyles,
} from "./TransportSummaryCard.styles";
import { TransportSummaryCardComponent } from "./TransportSummaryCard.base";

/**
 * TransportSummaryCard
 */
export const TransportSummaryCard = styled<
  ITransportSummaryCardProps,
  ITransportSummaryCardStyleProps,
  ITransportSummaryCardStyles
>(TransportSummaryCardComponent, getStyles);
