import { styled } from "@fluentui/react/lib/Utilities";
import { CountryIconComponent } from "./CountryIcon.base";
import {
  getStyles,
  ICountryIconStyleProps,
  ICountryIconStyles,
} from "./CountryIcon.styles";
import { ICountryIconProps } from "./CountryIcon.types";

/**
 * CountryIcon description
 */
export const CountryIcon = styled<
  ICountryIconProps,
  ICountryIconStyleProps,
  ICountryIconStyles
>(CountryIconComponent, getStyles);
