import * as React from "react";
import { getCustomSpinnerClassNames } from "./CustomSpinner.styles";
import {
  ICustomSpinnerState,
  ICustomSpinnerProps,
} from "./CustomSpinner.types";

export class CustomSpinnerComponent extends React.Component<
  ICustomSpinnerProps,
  ICustomSpinnerState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getCustomSpinnerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <div className={classNames.loaderContainer}>
          <div className="nvx-loader">
            <div className="nvx-loader"></div>
          </div>
        </div>
        <div className={classNames.labelWrapper}>
          <h2 className={classNames.label}>{this.props.text}</h2>
        </div>
        <div className={classNames.footer}></div>
      </div>
    );
  }
}
