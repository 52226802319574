import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightOfferDetailsProps } from "./FlightOfferDetails.types";
import {
  getStyles,
  IFlightOfferDetailsStyleProps,
  IFlightOfferDetailsStyles,
} from "./FlightOfferDetails.styles";
import { FlightOfferDetailsComponent } from "./FlightOfferDetails.base";

/**
 * FlightOfferDetails
 */
export const FlightOfferDetails = styled<
  IFlightOfferDetailsProps,
  IFlightOfferDetailsStyleProps,
  IFlightOfferDetailsStyles
>(FlightOfferDetailsComponent, getStyles);
