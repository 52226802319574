import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyles,
  ILabelFieldStyleProps,
} from "src/components/LabelField";

export interface IPendingBillPanelSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IPendingBillPanelStyles
  extends IAmigoBaseStyles<IPendingBillPanelSubComponentStyles> {}

export interface IPendingBillPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPendingBillPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IPendingBillPanelStyleProps,
    IPendingBillPanelStyles
  >,
  props?: IPendingBillPanelStyleProps
): [
  IProcessedStyleSet<IPendingBillPanelStyles>,
  IPendingBillPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPendingBillPanelStyleProps,
  IPendingBillPanelStyles
> = (_props: IPendingBillPanelStyleProps): IPendingBillPanelStyles => {
  return {};
};
