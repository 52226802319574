import * as React from "react";
import posed, { PoseGroup } from "react-pose";
import { RouteComponentProps, Switch, withRouter } from "react-router-dom";

export interface IAnimatedSwitchProperties extends RouteComponentProps<any> {}

export class AnimatedSwitchImpl extends React.Component<IAnimatedSwitchProperties> {
  render() {
    const reverse: boolean = true; // this.props.location.pathname === "/";
    return (
      <PoseGroup
        flipMove={false}
        preEnterPose={reverse ? "leftSide" : "rightSide"}
        exitPose={reverse ? "rightSide" : "leftSide"}
      >
        <ContextRouteAnimation
          key={this.props.location.pathname}
          reverse={reverse}
        >
          <Switch {...this.props}>{this.props.children}</Switch>
        </ContextRouteAnimation>
      </PoseGroup>
    );
  }
}

export const AnimatedSwitch = withRouter(AnimatedSwitchImpl);

/**
 * Try to change up the different commented values for varying animatmions
 */
export const ContextRouteAnimation = posed.div({
  enter: {
    x: 0,
    // opacity: 1,
    // scale: 1,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 400,
    },
  },
  leftSide: {
    x: "-100%",
    // opacity: 0,
    // scale: 1.5,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 400,
    },
  },
  rightSide: {
    x: "100%",
    // opacity: 0,
    // scale: 1.5,
    transition: {
      type: "tween",
      ease: "easeInOut",
      duration: 600,
    },
  },
});
