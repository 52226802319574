import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseContactsControlProps } from "./EnterpriseContactsControl.types";
import {
  getStyles,
  IEnterpriseContactsControlStyleProps,
  IEnterpriseContactsControlStyles,
} from "./EnterpriseContactsControl.styles";
import { EnterpriseContactsControlComponent } from "./EnterpriseContactsControl.base";

/**
 * EnterpriseContactsControl description
 */
export const EnterpriseContactsControl = styled<
  IEnterpriseContactsControlProps,
  IEnterpriseContactsControlStyleProps,
  IEnterpriseContactsControlStyles
>(EnterpriseContactsControlComponent, getStyles);
