import * as React from "react";
import { getTravelWarningsSectionClassNames } from "./TravelWarningsSection.styles";
import {
  ITravelWarningsSectionState,
  ITravelWarningsSectionProps,
} from "./TravelWarningsSection.types";
import { TravelWarningService } from "src/services/Requests/TravelWarningService";
import { TravelWarning } from "../TravelWarning/TravelWarning";
import { Stack, StackItem } from "@fluentui/react";

export class TravelWarningsSectionComponent extends React.Component<
  ITravelWarningsSectionProps,
  ITravelWarningsSectionState
> {
  constructor(props: ITravelWarningsSectionProps) {
    super(props);
    this.state = {
      warnings: [],
    };
    TravelWarningService.getTravelWarnings().then((x) =>
      this.setState({ warnings: x })
    );
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getTravelWarningsSectionClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { warnings } = this.state;
    return (
      <div className={classNames.root}>
        <Stack grow>
          {warnings.map((x, index) => {
            return (
              <StackItem key={`warning_${index}`} grow>
                <TravelWarning {...this.props} styles={undefined} warning={x} />
              </StackItem>
            );
          })}
        </Stack>
      </div>
    );
  }
}
