import * as React from "react";
import { getContactModificationFormClassNames } from "./ContactModificationForm.styles";
import {
  IContactModificationFormState,
  IContactModificationFormProps,
} from "./ContactModificationForm.types";
import { validate, getFieldMaxLength } from "src/utils/validator";
import { EnterpriseContactSchema } from "src/models/Enterprises/IEnterpriseContact";
import {
  Text,
  Stack,
  Icon,
  TextField,
  MaskedTextField,
  Checkbox,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { initializeComponentRef } from "@fluentui/react/lib/Utilities";

export class ContactModificationFormComponent extends React.Component<
  IContactModificationFormProps,
  IContactModificationFormState
> {
  constructor(props: IContactModificationFormProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {
      errors: {},
      edition: {
        id: parseInt(this.props.contact?.key ?? "0"),
        key: this.props.contact?.key ?? "0",
        contactId: this.props.contact?.contactId ?? 0,
        pending: this.props.contact?.pending ?? false,
        name: `${this.props.contact?.firstName ?? ""} ${
          this.props.contact?.lastName ?? ""
        }`,
        firstName: this.props.contact?.firstName ?? "",
        lastName: this.props.contact?.lastName ?? "",
        title: this.props.contact?.title ?? "",
        email: this.props.contact?.email ?? "",
        phone: this.props.contact?.phone ?? "",
        cellphone: this.props.contact?.cellphone ?? "",
        isNewsletterMember: this.props.contact?.isNewsletterMember ?? false,
        isRequestContact: this.props.contact?.isRequestContact ?? false,
        isBillingContact: this.props.contact?.isBillingContact ?? false,
        isFermeTravelContact: this.props.contact?.isFermeTravelContact ?? false,
      },
    };
  }

  public validateContact = (): boolean => {
    const errors = validate(EnterpriseContactSchema, this.state.edition);
    this.setState({
      errors: errors,
    });
    return Object.keys(errors).length === 0;
  };

  private renderCheckboxLabel(label: string, iconName: string) {
    return (
      <Stack horizontal tokens={{ childrenGap: 8 }}>
        <Icon iconName={iconName} style={{ fontSize: "24px" }} />
        <Text>{label}</Text>
      </Stack>
    );
  }

  private returnPhoneFormatted(phone: string) {
    if (!phone) return phone;
    phone = i18n.replaceAll(phone, "_", "").trim();
    if (phone.endsWith("#")) {
      phone = i18n.replaceAll(phone, "#", "").trim();
    }
    return phone;
  }

  private _phoneChange = (
    _event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { edition } = this.state;
    edition.phone = this.returnPhoneFormatted(newValue ?? "");
    this.setState({ edition: edition });
  };

  private _cellphoneChange = (
    _event?: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const { edition } = this.state;
    edition.cellphone = this.returnPhoneFormatted(newValue ?? "");
    this.setState({ edition: edition });
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getContactModificationFormClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    if (this.props.contact?.key !== this.state.edition.key) {
      setTimeout(() => {
        this.setState({
          errors: {},
          edition: {
            id: parseInt(this.props.contact?.key ?? "0"),
            key: this.props.contact?.key ?? "0",
            contactId: this.props.contact?.contactId ?? 0,
            pending: this.props.contact?.pending ?? false,
            name: `${this.props.contact?.firstName ?? ""} ${
              this.props.contact?.lastName ?? ""
            }`,
            firstName: this.props.contact?.firstName ?? "",
            lastName: this.props.contact?.lastName ?? "",
            title: this.props.contact?.title ?? "",
            email: this.props.contact?.email ?? "",
            phone: this.props.contact?.phone ?? "",
            cellphone: this.props.contact?.cellphone ?? "",
            isNewsletterMember: this.props.contact?.isNewsletterMember ?? false,
            isRequestContact: this.props.contact?.isRequestContact ?? false,
            isBillingContact: this.props.contact?.isBillingContact ?? false,
            isFermeTravelContact:
              this.props.contact?.isFermeTravelContact ?? false,
          },
        });
      }, 400);
    }
    return (
      <Stack className={classNames.root}>
        <TextField
          maxLength={getFieldMaxLength(
            EnterpriseContactSchema,
            "firstName",
            100
          )}
          styles={subComponentStyles?.textbox}
          label={i18n.t("enterprises:contacts:firstname")}
          value={this.state.edition.firstName}
          required
          errorMessage={this.state.errors.firstName}
          onChange={(_ev: any, newValue?: string) => {
            const { edition } = this.state;
            edition.firstName = newValue ?? "";
            this.setState({ edition: edition });
          }}
        />
        <TextField
          styles={subComponentStyles?.textbox}
          maxLength={getFieldMaxLength(
            EnterpriseContactSchema,
            "lastName",
            100
          )}
          label={i18n.t("enterprises:contacts:lastname")}
          value={this.state.edition.lastName}
          required
          errorMessage={this.state.errors.lastName}
          onChange={(_ev: any, newValue?: string) => {
            const { edition } = this.state;
            edition.lastName = newValue ?? "";
            this.setState({ edition: edition });
          }}
        />
        <TextField
          maxLength={getFieldMaxLength(
            EnterpriseContactSchema,
            "lastName",
            250
          )}
          styles={subComponentStyles?.textbox}
          label={i18n.t("enterprises:contacts:email")}
          value={this.state.edition.email}
          required
          errorMessage={this.state.errors.email}
          onChange={(_ev: any, newValue?: string) => {
            const { edition } = this.state;
            edition.email = newValue ?? "";
            this.setState({ edition: edition });
          }}
        />
        <MaskedTextField
          styles={subComponentStyles?.textbox}
          maxLength={getFieldMaxLength(EnterpriseContactSchema, "phone", 100)}
          label={i18n.t("enterprises:contacts:phone")}
          mask="(999) 999-9999 #99999"
          errorMessage={this.state.errors.phone}
          value={this.state.edition.phone}
          onChange={this._phoneChange}
        />
        <MaskedTextField
          styles={subComponentStyles?.textbox}
          maxLength={getFieldMaxLength(
            EnterpriseContactSchema,
            "cellphone",
            100
          )}
          label={i18n.t("enterprises:contacts:cellphone")}
          mask="(999) 999-9999"
          errorMessage={this.state.errors.cellphone}
          value={this.state.edition.cellphone}
          onChange={this._cellphoneChange}
        />
        <Checkbox
          styles={subComponentStyles?.checkbox}
          onRenderLabel={() => {
            return this.renderCheckboxLabel(
              i18n.t("enterprises:contacts:newsletter"),
              "News"
            );
          }}
          label={i18n.t("enterprises:contacts:newsletter")}
          checked={this.state.edition.isNewsletterMember}
          onChange={(_ev: any, checked?: boolean) => {
            const { edition } = this.state;
            edition.isNewsletterMember = checked ?? false;
            this.setState({ edition: edition });
          }}
        />
        <Checkbox
          styles={subComponentStyles?.checkbox}
          onRenderLabel={() => {
            return this.renderCheckboxLabel(
              i18n.t("enterprises:contacts:request"),
              "DocumentSet"
            );
          }}
          label={i18n.t("enterprises:contacts:request")}
          checked={this.state.edition.isRequestContact}
          onChange={(_ev: any, checked?: boolean) => {
            const { edition } = this.state;
            edition.isRequestContact = checked ?? false;
            this.setState({ edition: edition });
          }}
        />
        <Checkbox
          styles={subComponentStyles?.checkbox}
          onRenderLabel={() => {
            return this.renderCheckboxLabel(
              i18n.t("enterprises:contacts:billing"),
              "PaymentCard"
            );
          }}
          label={i18n.t("enterprises:contacts:billing")}
          checked={this.state.edition.isBillingContact}
          onChange={(_ev: any, checked?: boolean) => {
            const { edition } = this.state;
            edition.isBillingContact = checked ?? false;
            this.setState({ edition: edition });
          }}
        />
        <Checkbox
          styles={subComponentStyles?.checkbox}
          onRenderLabel={() => {
            return this.renderCheckboxLabel(
              i18n.t("enterprises:contacts:ferme"),
              "AirplaneSolid"
            );
          }}
          label={i18n.t("enterprises:contacts:ferme")}
          checked={this.state.edition.isFermeTravelContact}
          onChange={(_ev: any, checked?: boolean) => {
            const { edition } = this.state;
            edition.isFermeTravelContact = checked ?? false;
            this.setState({ edition: edition });
          }}
        />
      </Stack>
    );
  }
}
