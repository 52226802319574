import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchResultsProps } from "./FlightSearchResults.types";
import {
  getStyles,
  IFlightSearchResultsStyleProps,
  IFlightSearchResultsStyles,
} from "./FlightSearchResults.styles";
import { FlightSearchResultsComponent } from "./FlightSearchResults.base";

/**
 * FlightSearchResults
 */
export const FlightSearchResults = styled<
  IFlightSearchResultsProps,
  IFlightSearchResultsStyleProps,
  IFlightSearchResultsStyles
>(FlightSearchResultsComponent, getStyles);
