import { styled } from "@fluentui/react/lib/Utilities";
import { IScrollToTopProps } from "./ScrollToTop.types";
import {
  getStyles,
  IScrollToTopStyleProps,
  IScrollToTopStyles,
} from "./ScrollToTop.styles";
import { ScrollToTopComponent } from "./ScrollToTop.base";

export const ScrollToTop = styled<
  IScrollToTopProps,
  IScrollToTopStyleProps,
  IScrollToTopStyles
>(ScrollToTopComponent, getStyles);
