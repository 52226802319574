import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";
import {
  IEnterpriseContactItemStyleProps,
  IEnterpriseContactItemStyles,
} from "src/pages/Enterprises/EnterpriseContactItem";
import {
  IRequestHistoryControlStyleProps,
  IRequestHistoryControlStyles,
} from "../RequestHistoryControl";
import {
  IRequestParticipantsSectionStyleProps,
  IRequestParticipantsSectionStyles,
} from "../RequestParticipantsSection";
import {
  IRequestPeriodsControlStyleProps,
  IRequestPeriodsControlStyles,
} from "../RequestPeriodsControl";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import { IPivotStyles } from "@fluentui/react";

export interface IRequestDetailPageSubComponentStyles {
  pivot?: IPivotStyles;
  contact?: IStyleFunctionOrObject<
    IEnterpriseContactItemStyleProps,
    IEnterpriseContactItemStyles
  >;
  periodControl?: IStyleFunctionOrObject<
    IRequestPeriodsControlStyleProps,
    IRequestPeriodsControlStyles
  >;
  periodSection?: IStyleFunctionOrObject<
    IRequestParticipantsSectionStyleProps,
    IRequestParticipantsSectionStyles
  >;
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
  history?: IStyleFunctionOrObject<
    IRequestHistoryControlStyleProps,
    IRequestHistoryControlStyles
  >;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IRequestDetailPageStyles
  extends IAmigoBaseStyles<IRequestDetailPageSubComponentStyles> {
  msGridRow?: IStyle;
}

export interface IRequestDetailPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestDetailPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestDetailPageStyleProps,
    IRequestDetailPageStyles
  >,
  props?: IRequestDetailPageStyleProps
): [
  IProcessedStyleSet<IRequestDetailPageStyles>,
  IRequestDetailPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestDetailPageStyleProps,
  IRequestDetailPageStyles
> = (props: IRequestDetailPageStyleProps): IRequestDetailPageStyles => {
  return {
    root: ["amigo-RequestDetailPage", props.tokens.defaultPageRootStyle],
    msGridRow: props.tokens.msGridRow,
    subComponentStyles: {
      pivot: props.tokens.defaultPivotStyles,
    },
  };
};
