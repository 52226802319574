import { styled } from "@fluentui/react/lib/Utilities";
import { ILoginControlProps } from "./LoginControl.types";
import {
  getStyles,
  ILoginControlStyleProps,
  ILoginControlStyles,
} from "./LoginControl.styles";
import { LoginControlComponent } from "./LoginControl.base";

/**
 * LoginControl description
 */
export const LoginControl = styled<
  ILoginControlProps,
  ILoginControlStyleProps,
  ILoginControlStyles
>(LoginControlComponent, getStyles);
