import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IDepartureAssistedParticipantCardStyleProps,
  IDepartureAssistedParticipantCardStyles,
} from "src/pages/Messages/DepartureAssistedParticipantCard";

export interface IFlightBookingParticipantsGridViewSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IDepartureAssistedParticipantCardStyleProps,
    IDepartureAssistedParticipantCardStyles
  >;
}

export interface IFlightBookingParticipantsGridViewStyles
  extends IAmigoBaseStyles<IFlightBookingParticipantsGridViewSubComponentStyles> {}

export interface IFlightBookingParticipantsGridViewStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingParticipantsGridViewClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingParticipantsGridViewStyleProps,
    IFlightBookingParticipantsGridViewStyles
  >,
  props?: IFlightBookingParticipantsGridViewStyleProps
): [
  IProcessedStyleSet<IFlightBookingParticipantsGridViewStyles>,
  IFlightBookingParticipantsGridViewSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingParticipantsGridViewStyleProps,
  IFlightBookingParticipantsGridViewStyles
> = (
  _props: IFlightBookingParticipantsGridViewStyleProps
): IFlightBookingParticipantsGridViewStyles => {
  return {
    root: ["amigo-FlightBookingParticipantsGridView", {}],
  };
};
