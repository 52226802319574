import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantsControlProps } from "./ParticipantsControl.types";
import {
  getStyles,
  IParticipantsControlStyleProps,
  IParticipantsControlStyles,
} from "./ParticipantsControl.styles";
import { ParticipantsControlComponent } from "./ParticipantsControl.base";

/**
 * ParticipantsControl description
 */
export const ParticipantsControl = styled<
  IParticipantsControlProps,
  IParticipantsControlStyleProps,
  IParticipantsControlStyles
>(ParticipantsControlComponent, getStyles);
