import * as React from "react";
import { GenericPageHeader } from "src/components/GenericPageHeader/GenericPageHeader";
import { uniqueId } from "src/utils/uniqueId";
import { TransportRequestsPage } from "../TransportRequestsPage";
import { getTransportsPageClassNames } from "./TransportsPage.styles";
import {
  ITransportsPageProps,
  ITransportsPageState,
} from "./TransportsPage.types";

export class TransportsPageComponent extends React.Component<
  ITransportsPageProps,
  ITransportsPageState
> {
  constructor(props: ITransportsPageProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getTransportsPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        {this.props.routePath === "billetterie" ? (
          <></>
        ) : (
          <GenericPageHeader
            title="Transports"
            {...this.props}
            styles={undefined}
            themeIndex={7}
          />
        )}
        <TransportRequestsPage
          {...this.props}
          scrollableTargetId={id}
          styles={subComponentStyles?.land}
        />
      </div>
    );
  }
}
