import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightPersonalizedOfferDetailsProps } from "./FlightPersonalizedOfferDetails.types";
import {
  getStyles,
  IFlightPersonalizedOfferDetailsStyleProps,
  IFlightPersonalizedOfferDetailsStyles,
} from "./FlightPersonalizedOfferDetails.styles";
import { FlightPersonalizedOfferDetailsComponent } from "./FlightPersonalizedOfferDetails.base";

/**
 * FlightPersonalizedOfferDetails
 */
export const FlightPersonalizedOfferDetails = styled<
  IFlightPersonalizedOfferDetailsProps,
  IFlightPersonalizedOfferDetailsStyleProps,
  IFlightPersonalizedOfferDetailsStyles
>(FlightPersonalizedOfferDetailsComponent, getStyles);
