import {
  CommandBar,
  DefaultPalette,
  DetailsRow,
  FocusZone,
  GroupedList,
  GroupHeader,
  IColumn,
  Icon,
  IconButton,
  IGroup,
  IGroupHeaderProps,
  MessageBar,
  MessageBarType,
  Selection,
  SelectionMode,
  SelectionZone,
  Stack,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { IDocumentReference } from "src/models/Documents/IDocumentReference";
import { IDocumentSection } from "src/models/Documents/IDocumentSection";
import DocumentCategoryService from "src/services/Documents/DocumentCategoryService";
import DocumentService from "src/services/Documents/DocumentService";
import i18n from "src/services/i18n";
import LINQ from "src/services/LINQ";
import { getDocumentsCategoryItemsClassNames } from "./DocumentsCategoryItems.styles";
import {
  IDocumentsCategoryItemsProps,
  IDocumentsCategoryItemsState,
} from "./DocumentsCategoryItems.types";

export class DocumentsCategoryItemsComponent extends React.Component<
  IDocumentsCategoryItemsProps,
  IDocumentsCategoryItemsState
> {
  private _columns: IColumn[];
  constructor(props: IDocumentsCategoryItemsProps) {
    super(props);
    const selection = new Selection();
    selection.setItems([]);
    this.state = { sections: [], documents: [], selection: selection };
    this._columns = [
      {
        key: "iconName",
        fieldName: "iconName",
        data: "iconName",
        name: "Icon",
        minWidth: 55,
        maxWidth: 55,
        onRender: (item: IDocumentReference) => {
          return <Icon iconName={item.iconName} style={{ fontSize: "18px" }} />;
        },
      },
      {
        key: "name",
        fieldName: "name",
        data: "name",
        name: "Title",
        minWidth: 200,
        calculatedWidth: this.props.mobile ? 250 : 500,
        className: "DocumentName",
        onRender: (item: IDocumentReference) => (
          <Text>
            {item.name.endsWith(".pdf")
              ? item.name.substr(0, item.name.length - 4)
              : item.name}
          </Text>
        ),
      },
      {
        key: "size",
        fieldName: "size",
        data: "size",
        name: "Size",
        minWidth: 100,
        calculatedWidth: 100,
        className: "DocumentSize",
        onRender: (item: IDocumentReference) => (
          <Text>{item.type === "URL" ? "" : item.size}</Text>
        ),
      },
      {
        key: "key",
        data: "key",
        fieldName: "key",
        name: "Download",
        onRender: this.onLinkRender,
        minWidth: 70,
        calculatedWidth: 70,
        className: "DocumentDownload",
      },
    ];
    DocumentCategoryService.getDocumentSections(
      props.category.type,
      props.category.id
    ).then((x) => {
      this.setState({
        sections: x,
        documents: LINQ.selectMany<IDocumentSection, IDocumentReference>(
          x,
          (x) => x.documents
        ),
      });
    });
  }

  onLinkRender(
    item?: IDocumentReference,
    _index?: number,
    _column?: IColumn
  ): JSX.Element {
    return item === undefined ? (
      <></>
    ) : (
      <div style={{ marginTop: "-5px" }}>
        {item === null ? (
          <span></span>
        ) : (
          <IconButton
            iconProps={{
              iconName:
                item.type === "URL" ? "NavigateExternalInline" : "Download",
            }}
            onClick={() => {
              item.sectionId &&
                item.type === "URL" &&
                window.open(item.size, "_blank");

              item.sectionId &&
                item.type !== "URL" &&
                DocumentService.downloadFormsDocument(item.sectionId, item.key);
            }}
          />
        )}
      </div>
    );
  }

  private _onRenderCell = (
    nestingDepth?: number,
    item?: IDocumentReference,
    itemIndex?: number
  ): JSX.Element => {
    const x = (
      <DetailsRow
        styles={{
          root: {
            width: "100%",
            backgroundColor:
              (itemIndex ?? 0) % 2 === 0
                ? undefined
                : DefaultPalette.neutralLighter,
          },
          cell: {
            fontSize: "14px",
          },
        }}
        key={item?.key ?? "0"}
        columns={this._columns}
        groupNestingDepth={nestingDepth}
        item={item}
        itemIndex={itemIndex ?? 0}
        selection={this.state.selection}
        selectionMode={SelectionMode.none}
      />
    );

    const section = this.state.sections.filter(
      (x) => x.documents.filter((y) => y.key === item?.key).length > 0
    );

    let len = 0;
    for (let i = 0; i < this.state.sections.length; i++) {
      const y = this.state.sections[i];
      if (item?.sectionId !== undefined && y.id === item?.sectionId) {
        break;
      } else {
        len += y.documents.length;
      }
    }

    const startIndex = len;
    return itemIndex === startIndex &&
      section &&
      section.length > 0 &&
      section[0].description &&
      section[0].description.length > 0 ? (
      <>
        <div
          style={{
            backgroundColor: this.props.theme.palette.neutralLighter,
            paddingLeft: "33px",
            clear: "both",
          }}
        >
          <MessageBar messageBarType={MessageBarType.info}>
            <span
              dangerouslySetInnerHTML={{ __html: section[0].description }}
            ></span>
          </MessageBar>
        </div>
        {x}
      </>
    ) : (
      x
    );
  };

  private toGroups = (sections: IDocumentSection[]): IGroup[] => {
    const results: IGroup[] = [];
    let len = 0;
    sections.forEach((x) => {
      results.push({
        key: x.key?.toString() ?? "",
        name: this.props.mobile ? LINQ.truncate(x.name, 36) : x.name,
        isCollapsed: sections.length > 1,
        isSelected: false,
        startIndex: len,
        count: x.documents.length,
      });
      len += x.documents.length;
    });
    return results;
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getDocumentsCategoryItemsClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              padding: mobile ? "4px" : "10px",
              width: "100%",
              maxWidth: "100%",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor: "#fff",
                padding: "0 10px 10px 10px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
            tokens={{ childrenGap: 10 }}
          >
            <Card.Item>
              <Text variant={mobile ? "xxLarge" : "xxLargePlus"}>
                {i18n.t(
                  `documents:${this.props.category.name.toLowerCase()}`
                ) === `documents:${this.props.category.name.toLowerCase()}`
                  ? this.props.category.name
                  : i18n.t(
                      `documents:${this.props.category.name.toLowerCase()}`
                    )}
              </Text>
              <div style={{ height: "25px" }}></div>
              <CommandBar
                items={[
                  {
                    key: "1",
                    name: i18n.t("documents:backcategories"),
                    iconProps: { iconName: "NavigateBack" },
                    onClick: () => this.props.onDismiss(),
                    className: "NavigateBack",
                    style: {
                      fontWeight: "bold",
                      backgroundColor: this.props.theme.palette.neutralLighter,
                    },
                  },
                ]}
                styles={{
                  root: {
                    backgroundColor: this.props.theme.palette.neutralLighter,
                  },
                }}
              />
              <FocusZone>
                <SelectionZone
                  selection={this.state.selection}
                  selectionMode={SelectionMode.none}
                >
                  <GroupedList
                    items={this.state.documents}
                    groupProps={{
                      onRenderHeader: this.onRenderHeader,
                      headerProps: {
                        styles: {
                          headerCount: {
                            display: "inline-block",
                          },
                        },
                      },
                    }}
                    onRenderCell={this._onRenderCell}
                    selection={this.state.selection}
                    selectionMode={SelectionMode.none}
                    groups={this.toGroups(this.state.sections)}
                  />
                </SelectionZone>
              </FocusZone>
              <div style={{ height: "25px" }}></div>
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }

  private onRenderHeader(props?: IGroupHeaderProps) {
    const headerCountStyle = { display: "none" };
    const checkButtonStyle = { display: "none" };

    const onToggleSelectGroup = () => {
      props?.group !== undefined && props?.onToggleCollapse?.(props?.group);
    };

    return (
      <GroupHeader
        styles={{ check: checkButtonStyle, headerCount: headerCountStyle }}
        {...props}
        onToggleSelectGroup={onToggleSelectGroup}
      />
    );
  }
}
