import {
  Dropdown,
  IDropdownOption,
  MaskedTextField,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";

export interface ICreditCardExpirationFieldProps {
  mobile: boolean;
  month: number;
  year: number;
  cvc: string;
  cvcErrorMessage: string | JSX.Element | undefined;
  expiryErrorMessage: string | undefined;
  onExpiryFocus: () => void;
  onCvcFocus: () => void;
  onCvcChange: (newValue: string) => void;
  onMonthChange: (newValue: number) => void;
  onYearChange: (newValue: number) => void;
}

export interface ICreditCardExpirationFieldState {}

const maskFormat: { [key: string]: RegExp } = {
  "*": /[0-9]/,
};

export class CreditCardExpirationField extends React.Component<
  ICreditCardExpirationFieldProps,
  ICreditCardExpirationFieldState
> {
  constructor(props: ICreditCardExpirationFieldProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const year = new Date().getFullYear();
    const years = [];
    for (let i = year; i < year + 12; i++) {
      years.push(i);
    }
    return (
      <div style={{ marginTop: "10px", marginBottom: "10px" }}>
        <Stack tokens={{ childrenGap: 10 }} horizontal={!this.props.mobile}>
          <div>
            <Text variant="medium" style={{ fontWeight: "bold" }}>
              {i18n.t("enterprises:paymentmethods:expiration")}
            </Text>
            <Stack tokens={{ childrenGap: 10 }} horizontal>
              <Dropdown
                required
                onFocus={this.props.onExpiryFocus}
                dropdownWidth={130}
                errorMessage={this.props.expiryErrorMessage}
                styles={{ root: { width: "134px" } }}
                selectedKey={this.props.month.toString()}
                label={i18n.t("enterprises:paymentmethods:month")}
                options={i18n.CalendarStrings().months.map((x, y) => {
                  return {
                    key: (y + 1).toString(),
                    text: x,
                  };
                })}
                onChange={(
                  _event: React.FormEvent<HTMLDivElement>,
                  _option?: IDropdownOption,
                  index?: number
                ) => {
                  this.props.onMonthChange((index ?? 0) + 1);
                }}
              />
              <Dropdown
                required
                dropdownWidth={130}
                onFocus={this.props.onExpiryFocus}
                styles={{ root: { width: "134px" } }}
                selectedKey={this.props.year.toString()}
                label={i18n.t("enterprises:paymentmethods:year")}
                options={years.map((x) => {
                  return { key: x.toString(), text: x.toString() };
                })}
                onChange={(
                  _event: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption,
                  _index?: number
                ) => {
                  this.props.onYearChange(
                    option
                      ? parseInt(option.key.toString())
                      : new Date().getFullYear()
                  );
                }}
              />
            </Stack>
          </div>
          <div style={{ display: "none" }}>
            <Text variant="medium" style={{ fontWeight: "bold" }}>
              {i18n.t("enterprises:paymentmethods:cvc")}
            </Text>
            <MaskedTextField
              onFocus={this.props.onCvcFocus}
              
              errorMessage={this.props.cvcErrorMessage}
              value={this.props.cvc}
              mask={"****"}
              maskFormat={maskFormat}
              label={i18n.t("enterprises:paymentmethods:cvc:label")}
              onChange={(
                event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                _newValue?: string
              ) => {
                this.props.onCvcChange((event.target as any).value);
              }}
            />
          </div>
        </Stack>
        <div style={{ height: "10px" }}></div>
      </div>
    );
  }
}
