import { styled } from "@fluentui/react/lib/Utilities";
import { IDateRangePickerProps } from "./DateRangePicker.types";
import {
  getStyles,
  IDateRangePickerStyleProps,
  IDateRangePickerStyles,
} from "./DateRangePicker.styles";
import { DateRangePickerComponent } from "./DateRangePicker.base";

/**
 * DateRangePicker
 */
export const DateRangePicker = styled<
  IDateRangePickerProps,
  IDateRangePickerStyleProps,
  IDateRangePickerStyles
>(DateRangePickerComponent, getStyles);
