import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITravelWarningSubComponentStyles {}

export interface ITravelWarningStyles
  extends IAmigoBaseStyles<ITravelWarningSubComponentStyles> {}

export interface ITravelWarningStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTravelWarningClassNames = (
  styles?: IStyleFunctionOrObject<
    ITravelWarningStyleProps,
    ITravelWarningStyles
  >,
  props?: ITravelWarningStyleProps
): [
  IProcessedStyleSet<ITravelWarningStyles>,
  ITravelWarningSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITravelWarningStyleProps,
  ITravelWarningStyles
> = (_props: ITravelWarningStyleProps): ITravelWarningStyles => {
  return {
    root: [
      "amigo-TravelWarning",
      {
        width: "100%",
        paddingTop: "20px",
      },
    ],
  };
};
