import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAuthorizationWarningPanelSubComponentStyles {}

export interface IAuthorizationWarningPanelStyles
  extends IAmigoBaseStyles<IAuthorizationWarningPanelSubComponentStyles> {}

export interface IAuthorizationWarningPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAuthorizationWarningPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IAuthorizationWarningPanelStyleProps,
    IAuthorizationWarningPanelStyles
  >,
  props?: IAuthorizationWarningPanelStyleProps
): [
  IProcessedStyleSet<IAuthorizationWarningPanelStyles>,
  IAuthorizationWarningPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAuthorizationWarningPanelStyleProps,
  IAuthorizationWarningPanelStyles
> = (
  _props: IAuthorizationWarningPanelStyleProps
): IAuthorizationWarningPanelStyles => {
  return {
    root: ["amigo-AuthorizationWarningPanel", {}],
  };
};
