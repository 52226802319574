import { IObjectReference } from "src/models/IObjectReference";
import { TransportArrivalAssistedControl } from "src/pages/Messages/TransportArrivalAssistedControl";
import { TransportArrivalUpdateAssistedControl } from "src/pages/Messages/TransportArrivalUpdateAssistedControl";
import { DepartureAssistedControl } from "src/pages/Messages/DepartureAssistedControl";
import { InspectionAssistedControl } from "src/pages/Messages/InspectionAssistedControl";
import { ArrivalAssistedControl } from "src/pages/Messages/ArrivalAssistedControl/ArrivalAssistedControl";
import {
  IMessageSummary,
  IMessageType,
} from "../../models/Messages/IMessageSummary";
import { ParticipantTransfertAssistedControl } from "src/pages/Messages/ParticipantTransfertAssistedControl";

class AssistedMessageService {
  getAssistedTypeIcon(type: IMessageType): string {
    switch (type.code) {
      case "STANDARD":
        return "Info";
      case "DEMANDE_DEPART":
        return "Leave";
      case "CONFIRMATION_DEPART":
        return "ChangeEntitlements";
      case "GENERAL":
        return "Info";
      case "TRANSPORT_ARRIVEE":
        return "Arrivals";
      case "DEMANDE_DEPART_MAJ":
        return "Certificate";
      case "TRANSPORT_ARRIVEE_MAJ":
        return "Arrivals";
      case "INSPECTION":
        return "FabricFolderSearch";
    }
    return "Info";
  }

  getAssistedComponent(
    props: any,
    enterprise: IObjectReference,
    message: IMessageSummary
  ): JSX.Element {
    const payload = {
      enterprise: enterprise,
      message: message,
    };

    switch (message.type.code) {
      case "STANDARD":
        throw new Error("Invalid Communciation Type");
      case "DEMANDE_DEPART":
        return <DepartureAssistedControl {...props} {...payload} />;
      case "CONFIRMATION_DEPART":
        return <ParticipantTransfertAssistedControl {...props} {...payload} />;
      case "GENERAL":
        throw new Error("Invalid Communciation Type");
      case "TRANSPORT_ARRIVEE":
        return <TransportArrivalAssistedControl {...props} {...payload} />;
      case "DEMANDE_ARRIVEE":
        return <ArrivalAssistedControl {...props} {...payload} />;
      case "DEMANDE_DEPART_MAJ":
        return <DepartureAssistedControl {...props} {...payload} />;
      case "TRANSPORT_ARRIVEE_MAJ":
        return (
          <TransportArrivalUpdateAssistedControl {...props} {...payload} />
        );
      case "INSPECTION":
        return <InspectionAssistedControl {...props} {...payload} />;
    }

    throw new Error("Invalid Communciation Type");
  }
}

export default new AssistedMessageService();
