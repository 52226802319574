import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertSpotlightProps } from "./AlertSpotlight.types";
import {
  getStyles,
  IAlertSpotlightStyleProps,
  IAlertSpotlightStyles,
} from "./AlertSpotlight.styles";
import { AlertSpotlightComponent } from "./AlertSpotlight.base";

/**
 * AlertSpotlight
 */
export const AlertSpotlight = styled<
  IAlertSpotlightProps,
  IAlertSpotlightStyleProps,
  IAlertSpotlightStyles
>(AlertSpotlightComponent, getStyles);
