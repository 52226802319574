import { Panel, PanelType, Stack } from "@fluentui/react";
import * as React from "react";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { getFlightDirectOffersClassNames } from "./FlightDirectOffers.styles";
import { FlightPersonalizedOffers } from "../FlightPersonalizedOffers/FlightPersonalizedOffers";
import { FlightPersonalizedOfferDetails } from "../FlightPersonalizedOfferDetails/FlightPersonalizedOfferDetails";
import {
  IFlightDirectOffersState,
  IFlightDirectOffersProps,
} from "./FlightDirectOffers.types";
import i18n from "src/services/i18n";

function groupBy(objectArray: any[], property: string) {
  return objectArray.reduce(function (acc, obj) {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

function resolveBy(objectArray: any[]) {
  const r = groupBy(objectArray, "key");
  return Object.keys(r).map((x) => {
    return {
      key: x,
      items: r[x],
    };
  });
}

export class FlightDirectOffersComponent extends React.Component<
  IFlightDirectOffersProps,
  IFlightDirectOffersState
> {
  constructor(props: IFlightDirectOffersProps) {
    super(props);
    this.state = {};

    const { arrivals, departures } = this.state;
    const { arrival, enterpriseId } = this.props;

    const onUpdateArrivals = (x: any[]) =>
      this.setState({ arrivals: resolveBy(x) });
    const onUpdateDepartures = (x: any[]) =>
      this.setState({ departures: resolveBy(x) });

    if (arrival && arrivals === undefined) {
      TransportFlightSearchService.getArrivalOffersAsync(enterpriseId).then(
        onUpdateArrivals
      );
    } else if (departures === undefined) {
      TransportFlightSearchService.getDeparturesOffersAsync(enterpriseId).then(
        onUpdateDepartures
      );
    }
  }

  componentDidUpdate() {
    const { arrivals, departures } = this.state;
    const { arrival, enterpriseId } = this.props;

    const onUpdateArrivals = (x: any[]) =>
      this.setState({ arrivals: resolveBy(x) });
    const onUpdateDepartures = (x: any[]) =>
      this.setState({ departures: resolveBy(x) });

    if (arrival && arrivals === undefined) {
      TransportFlightSearchService.getArrivalOffersAsync(enterpriseId).then(
        onUpdateArrivals
      );
    } else if (departures === undefined) {
      TransportFlightSearchService.getDeparturesOffersAsync(enterpriseId).then(
        onUpdateDepartures
      );
    }
  }

  render(): JSX.Element {
    const { styles, arrival } = this.props;
    const [classNames /* , subComponentStyles */] =
      getFlightDirectOffersClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { arrivals, departures, selected } = this.state;
    const onSelected = (item: any) => {
      this.setState({
        selected: item,
      });
    };

    return (arrival && arrivals !== undefined && arrivals.length > 0) ||
      (!arrival && departures !== undefined && departures.length > 0) ? (
      <>
        <Panel
          onDismiss={() => this.setState({ selected: undefined })}
          type={PanelType.medium}
          isLightDismiss={true}
          styles={{
            contentInner: {
              backgroundColor: this.props.theme.palette.neutralLighter,
            },
          }}
          isOpen={selected !== undefined}
          headerText={selected?.key}
        >
          {selected !== undefined && (
            <FlightPersonalizedOfferDetails
              {...this.props}
              styles={undefined}
              onSelected={(item: any) => {
                this.props.route.history.push({
                  pathname: `/transports/offre/${item.id}`,
                  search: `passagers=${item.passengers}&arrival=${
                    arrival ? "true" : "false"
                  }`,
                });
              }}
              itemKey={selected.key}
              items={selected.items}
            />
          )}
        </Panel>
        <Stack grow className={classNames.root}>
          <h3>{i18n.t("transports:offers:title")}</h3>
          <FlightPersonalizedOffers
            {...this.props}
            styles={undefined}
            items={arrival ? arrivals ?? [] : departures ?? []}
            arrival={arrival}
            onSelected={onSelected}
          />
        </Stack>
      </>
    ) : (
      <div className={classNames.root}></div>
    );
  }
}
