import { IStackStyles, Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getReloadPageClassNames } from "./ReloadPage.styles";
import { IReloadPageProps, IReloadPageState } from "./ReloadPage.types";

const stackStyles: IStackStyles = {
  root: {
    marginTop: 100,
    marginBottom: 100,
    height: 250,
  },
};

export class ReloadPageComponent extends React.Component<
  IReloadPageProps,
  IReloadPageState
> {
  constructor(props: IReloadPageProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, route } = this.props;
    const [classNames] = getReloadPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const state = route.location.state as any;
    const location: string = state.location;
    setTimeout(() => {
      route.history.push(location ?? "/", state);
    });

    return (
      <Stack
        className={classNames.root}
        styles={stackStyles}
        horizontalAlign="center"
        verticalAlign="center"
      >
        <div style={{ padding: "5px" }}>
          <div style={{ width: "180px", marginLeft: "10px", float: "left" }}>
            <div className="nvx-loader">
              <div className="nvx-loader"></div>
            </div>
          </div>
          {this.props.mobile ? (
            <div className="clear-both">
              <h2>{i18n.t("global:pleasewait")}</h2>
            </div>
          ) : (
            <div style={{ width: "180px", float: "left" }}>
              <h2>{i18n.t("global:pleasewait")}</h2>
            </div>
          )}

          <div className="clear-both"></div>
        </div>
      </Stack>
    );
  }
}
