import { ActivityItem, Icon, Link, Stack, Text } from "@fluentui/react";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import { ParticipantTransfertService } from "src/services/Messages/ParticipantTransfertService";
import { getParticipantTransfertAssistedControlClassNames } from "./ParticipantTransfertAssistedControl.styles";
import {
  IParticipantTransfertAssistedControlProps,
  IParticipantTransfertAssistedControlState,
} from "./ParticipantTransfertAssistedControl.types";

export class ParticipantTransfertAssistedControlComponent extends React.Component<
  IParticipantTransfertAssistedControlProps,
  IParticipantTransfertAssistedControlState
> {
  constructor(props: IParticipantTransfertAssistedControlProps) {
    super(props);
    this.state = {};
    this.getDepartureRequest();
  }

  componentDidUpdate() {
    if (
      !this.state.request ||
      this.props.message.itemId !== this.state.request.id
    ) {
      this.getDepartureRequest();
    }
  }

  private getDepartureRequest = () => {
    ParticipantTransfertService.getParticipantTransfertRequest(
      objectId(this.props.enterprise),
      this.props.message.itemId
    ).then((x) => {
      this.setState({
        request: x,
      });
    });
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getParticipantTransfertAssistedControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { request } = this.state;
    return (
      <div className={classNames.root}>
        <h3>
          {i18n.t("messages:assisted:participanttransfert:summary:title")}
        </h3>
        <Stack
          horizontal={!mobile}
          tokens={{ childrenGap: mobile ? 10 : 20 }}
          style={{ marginLeft: "5px" }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t(
                "messages:assisted:participanttransfert:requestnumber"
              )}
              content={request?.requestNumber ?? ""}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t(
                "messages:assisted:participanttransfert:requestdate"
              )}
              content={request?.requestDate ?? ""}
            />
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t(
                "messages:assisted:participanttransfert:transfertenterprise"
              )}
              content={request?.transfertEnterprise ?? ""}
            />
          </Stack>
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t(
                i18n.t(
                  "messages:assisted:participanttransfert:participantcount"
                )
              )}
              content={request?.participants.length ?? 0}
            />
          </Stack>
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <div>
            <div style={{ clear: "both" }}></div>
          </div>

          {request?.participants.map((x) => (
            <div
              key={`card_participant_${x.id}`}
              style={{ float: mobile ? undefined : "left", padding: "5px" }}
            >
              <ActivityItem
                activityIcon={<Icon iconName={"Contact"} />}
                comments={
                  <Stack
                    key={"comments"}
                    horizontal={!mobile}
                    tokens={{ childrenGap: 5 }}
                  >
                    <LabelField
                      key={"recall"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:newdeparture:recall")}
                      content={
                        x.recallName && x.recallName != null
                          ? x.recallName
                          : i18n.t("messages:assisted:newdeparture:unknown")
                      }
                    />
                    <LabelField
                      key={"reasonrecall"}
                      {...this.props}
                      styles={undefined}
                      label={i18n.t(
                        "messages:assisted:newdeparture:reasonrecall"
                      )}
                      content={x.reason && x.reason != null ? x.reason : ""}
                    />
                  </Stack>
                }
                timeStamp={x.date}
                activityDescription={[
                  x.workerId ? (
                    <Link
                      key={1}
                      className={classNames.nameText}
                      onClick={() => {
                        this.props.route.history.push(
                          `/participants/${x.workerId}`
                        );
                      }}
                    >
                      {x.name}
                    </Link>
                  ) : (
                    <Text>{x.name}</Text>
                  ),
                  <span
                    style={{ marginLeft: "5px" }}
                    key={2}
                  >{` ${x.code}`}</span>,
                ]}
              />
            </div>
          ))}
        </Stack>
      </div>
    );
  }
}
