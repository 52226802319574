import { styled } from "@fluentui/react/lib/Utilities";
import { IInspectionRequestEditorProps } from "./InspectionRequestEditor.types";
import {
  getStyles,
  IInspectionRequestEditorStyleProps,
  IInspectionRequestEditorStyles,
} from "./InspectionRequestEditor.styles";
import { InspectionRequestEditorComponent } from "./InspectionRequestEditor.base";

/**
 * InspectionRequestEditor
 */
export const InspectionRequestEditor = styled<
  IInspectionRequestEditorProps,
  IInspectionRequestEditorStyleProps,
  IInspectionRequestEditorStyles
>(InspectionRequestEditorComponent, getStyles);
