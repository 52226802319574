import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IPaymentMethodCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IPaymentMethodCardStyles
  extends IAmigoBaseStyles<IPaymentMethodCardSubComponentStyles> {}

export interface IPaymentMethodCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentMethodCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentMethodCardStyleProps,
    IPaymentMethodCardStyles
  >,
  props?: IPaymentMethodCardStyleProps
): [
  IProcessedStyleSet<IPaymentMethodCardStyles>,
  IPaymentMethodCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentMethodCardStyleProps,
  IPaymentMethodCardStyles
> = (props: IPaymentMethodCardStyleProps): IPaymentMethodCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
    },
  };
};
