import { FontWeights, Stack, StackItem } from "@fluentui/react";
import { Icon, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import BillingService from "src/services/Billing/BillingService";
import i18n from "src/services/i18n";
import { getBillingElementCardClassNames } from "./BillingElementCard.styles";
import {
  IBillingElementCardProps,
  IBillingElementCardState,
} from "./BillingElementCard.types";

const defaultCardTitleStyles = {
  root: {
    color: "#201f1e",
    fontSize: "15px",
    fontWeight: FontWeights.semibold,
  },
};

const travelCardTitleStyles = {
  root: {
    color: "#201f1e",
    fontSize: "15px",
    fontWeight: FontWeights.semibold,
  },
};

export class BillingElementCardComponent extends React.Component<
  IBillingElementCardProps,
  IBillingElementCardState
> {
  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getBillingElementCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
        highlight: item.late,
        travel: item.travel,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x: any) => x.key === item.key
      ).length > 0;

    let iconCount = 0;

    if (item.travel) {
      iconCount++;
    }

    if (item.late) {
      iconCount++;
    } else if (item.status === "Payée") {
      iconCount++;
    }

    iconCount = 2 - iconCount;

    const balance: number = parseInt(
      item.balance.replace("$", "").replace(/ /g, "")
    );

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <Text
              variant="small"
              styles={
                item.travel ? travelCardTitleStyles : defaultCardTitleStyles
              }
            >
              {item.billingNumber}
            </Text>
            <Text
              variant="small"
              styles={
                item.travel ? travelCardTitleStyles : defaultCardTitleStyles
              }
            >
              {item.billingDate}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={
          balance > 0
            ? `${item.total} ${i18n.t("billing:balance")}: ${item.balance}`
            : item.total
        }
        footerIconName={footerIconName ?? "Money"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <>
            <TooltipHost content={"Imprimer la facture"}>
              <Icon
                iconName="Print"
                className="ms-Card-Image"
                onClick={(ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  ev && ev.preventDefault() && ev.stopPropagation();
                  BillingService.printBill(
                    objectId(this.props.enterprise),
                    item.id
                  );
                }}
              />
            </TooltipHost>

            {item.travel ? (
              <div>
                <TooltipHost content={"FERME/Voyages"}>
                  <Icon iconName="AirplaneSolid" className="ms-Card-Image" />
                </TooltipHost>
              </div>
            ) : (
              <TooltipHost content={item.billingNumber}>
                <Icon iconName="Script" className="ms-Card-Image" />
              </TooltipHost>
            )}

            <div>
              <TooltipHost content={item.status}>
                <Icon
                  iconName={item.status === "Payée" ? "Completed" : "HourGlass"}
                  className="ms-Card-Image"
                />
              </TooltipHost>
            </div>

            {item.late && (
              <div>
                <TooltipHost content={item.status}>
                  <Icon
                    iconName="EventDateMissed12"
                    className="ms-Card-Image"
                  />
                </TooltipHost>
              </div>
            )}

            {/* Icon PlaceHolder */}
            {iconCount > 1 && (
              <div style={{ visibility: "hidden" }}>
                <TooltipHost content={""}>
                  <Icon iconName="Info" className="ms-Card-Image" />
                </TooltipHost>
              </div>
            )}
            {iconCount > 0 && (
              <div style={{ visibility: "hidden" }}>
                <TooltipHost content={""}>
                  <Icon iconName="Info" className="ms-Card-Image" />
                </TooltipHost>
              </div>
            )}
          </>
        }
      >
        <Stack
          className={classNames.root}
          horizontal
          maxWidth="100%"
          tokens={{ childrenGap: 10 }}
        >
          <StackItem align={"start"} grow>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("billing:subtotal")}
              content={item.subtotal}
            />
          </StackItem>
          <StackItem align={"end"} grow>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("billing:status")}
              content={
                <Text
                  style={{
                    color: item.travel ? "#2f96b4" : "",
                    fontWeight: item.late ? "bold" : "normal",
                  }}
                >
                  {item.status}
                </Text>
              }
            />
          </StackItem>
        </Stack>
      </GridViewCard>
    );
  }
}
