import { styled } from "@fluentui/react/lib/Utilities";
import { IMaintenanceBarProps } from "./MaintenanceBar.types";
import {
  getStyles,
  IMaintenanceBarStyleProps,
  IMaintenanceBarStyles,
} from "./MaintenanceBar.styles";
import { MaintenanceBarComponent } from "./MaintenanceBar.base";

/**
 * MaintenanceBar description
 */
export const MaintenanceBar = styled<
  IMaintenanceBarProps,
  IMaintenanceBarStyleProps,
  IMaintenanceBarStyles
>(MaintenanceBarComponent, getStyles);
