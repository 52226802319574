import { CommandBarButton, Selection, Spinner } from "@fluentui/react";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { IEnterpriseDocument } from "src/models/Enterprises/IEnterpriseDocument";
import { objectId } from "src/models/IObjectReference";
import DocumentEnterpriseService from "src/services/Enterprises/DocumentEnterpriseService";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import { EnterpriseDocumentCard } from "../EnterpriseDocumentCard/EnterpriseDocumentCard";
import { getEnterpriseDocumentsControlClassNames } from "./EnterpriseDocumentsControl.styles";
import {
  IEnterpriseDocumentsControlProps,
  IEnterpriseDocumentsControlState,
} from "./EnterpriseDocumentsControl.types";
import { SelectionMode, Icon } from "@fluentui/react";
import DocumentService from "src/services/Documents/DocumentService";
import { UploadFilesDialog } from "src/controls/UploadFilesDialog";
import { IAttachmentModel } from "src/models/Messages/IMessageSummary";
import { UploadFilesDialogComponent } from "src/controls/UploadFilesDialog/UploadFilesDialog.base";

export class EnterpriseDocumentsControlComponent extends React.Component<
  IEnterpriseDocumentsControlProps,
  IEnterpriseDocumentsControlState
> {
  private uploadRef: React.RefObject<UploadFilesDialogComponent>;

  constructor(props: IEnterpriseDocumentsControlProps) {
    super(props);
    this.uploadRef = React.createRef();
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "enterprisesdocuments",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "enterprisesdocuments",
        false,
        () => []
      ),
      items: [],
      downloadProgress: "0",
    };
    DocumentEnterpriseService.getDocuments(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private download = (item: IEnterpriseDocument): Promise<void> => {
    this.setState({
      downloadProgress: item.key,
    });
    return DocumentEnterpriseService.downloadEnterpriseDocument(
      this.props.enterprise.key?.toString() ?? "-1",
      item
    )
      .then(() => {
        this.setState({
          downloadProgress: "0",
        });
      })
      .catch((_x) => {
        this.setState({
          downloadProgress: "0",
        });
      });
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getEnterpriseDocumentsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <UploadFilesDialog
          {...this.props}
          styles={undefined}
          componentRef={this.uploadRef}
          onUpload={(attachements: IAttachmentModel[]) => {
            return DocumentEnterpriseService.uploadDocuments(
              objectId(this.props.enterprise),
              attachements
            ).then((x) => {
              DocumentEnterpriseService.init();
              DocumentEnterpriseService.getDocuments(
                objectId(this.props.enterprise),
                this.state.options.sortBy,
                this.state.options.desc,
                this.state.filters,
                undefined
              ).then((x) => {
                this.setState({
                  items: x,
                  options: GridViewOptionsBuilder.getFetched(this),
                });
              });
              return x;
            });
          }}
        />
        <GridView
          {...this.props}
          styles={undefined}
          totalCount={
            this.state.items === undefined || this.state.items.length === 0
              ? undefined
              : this.state.items.length
          }
          viewPortOffset={30}
          selectionMode={SelectionMode.none}
          fullTextEnabled={false}
          onItemInvoked={(item: IEnterpriseDocument) => {
            this.setState({
              document: item,
            });
          }}
          onRenderTile={(
            item: IEnterpriseDocument,
            columnWidth: number,
            selection: Selection,
            _callback: (sel: Selection) => void
          ): JSX.Element => {
            return (
              <EnterpriseDocumentCard
                {...this.props}
                onDownload={() => this.download(item)}
                styles={subComponentStyles?.gridCard}
                item={item}
                columnWidth={columnWidth}
                selection={selection}
                onClick={(item: IEnterpriseDocument) => {
                  this.setState({ document: item });
                }}
              />
            );
          }}
          onSort={(fieldName: string, desc: boolean) => {
            DocumentEnterpriseService.init();
            DocumentEnterpriseService.getDocuments(
              objectId(this.props.enterprise),
              fieldName,
              desc,
              this.state.filters,
              false
            ).then((x) => {
              this.setState({
                options: PreferencesService.asCommittedOptions(
                  GridViewOptionsBuilder.getOptions(
                    this.state.options.mode,
                    fieldName,
                    desc,
                    true
                  ),
                  "enterprisesdocuments"
                ),
                items: x,
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          options={this.state.options}
          onOptionsChanged={(options: IGridViewOptions) => {
            this.setState({
              options: PreferencesService.asCommittedOptions(
                options,
                "enterprisesdocuments"
              ),
            });
          }}
          columns={[
            {
              key: "key",
              name: "",
              minWidth: 60,
              maxWidth: 60,
              onRenderCell: (item: IEnterpriseDocument) => {
                const iconName = DocumentService.getIconFromExtension(
                  item.name
                );
                return (
                  <Icon
                    onClick={() => {
                      this.setState({
                        document: item,
                      });
                    }}
                    styles={{
                      root: {
                        cursor: "pointer",
                        fontSize: "17px",
                      },
                    }}
                    iconName={iconName}
                  />
                );
              },
            },
            {
              key: "name",
              name: "documents:name",
              minWidth: undefined,
            },
            {
              key: "size",
              name: "documents:size",
              minWidth: 90,
            },
            {
              key: "createdOn",
              name: "documents:createdOn",
              minWidth: 120,
            } /*
            {
              key: "createdBy",
              name: "documents:createdBy",
              minWidth: 150,
            }, */,
            {
              key: "id",
              name: "documents:download",
              minWidth: 190,
              maxWidth: 190,
              onRenderCell: (item: IEnterpriseDocument) => {
                return this.state.downloadProgress === item.key ? (
                  <Spinner
                    style={{ width: "190px" }}
                    label={i18n.t("documents:download:progress")}
                    labelPosition={"right"}
                  />
                ) : (
                  <CommandBarButton
                    style={{ backgroundColor: "transparent" }}
                    iconProps={{ iconName: "CloudDownload" }}
                    onClick={() => this.download(item)}
                    text={i18n.t("documents:download")}
                  />
                );
              },
            },
          ]}
          onDataPaging={() => {
            DocumentEnterpriseService.getDocuments(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              this.state.filters,
              true
            ).then((x) => {
              this.setState({
                items: x,
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
          }}
          onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
            DocumentEnterpriseService.init();
            DocumentEnterpriseService.getDocuments(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              filters,
              undefined
            ).then((x) => {
              callback();
              this.setState({
                items: x,
                filters: PreferencesService.asCommittedFilters(
                  filters,
                  "enterprisesdocuments"
                ),
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          onQueryFilters={(reset: boolean) => {
            return new Promise<IQueryFilter[]>((resolve) => {
              resolve(
                PreferencesService.getFiltersOrDefault(
                  "enterprisesdocuments",
                  reset,
                  () => [
                    {
                      name: "documents:name",
                      fieldName: "name",
                      type: "text",
                      value: "",
                    },
                    {
                      name: "documents:processedOn",
                      fieldName: "processedOn",
                      type: "date",
                      value: null,
                    },
                    {
                      name: "documents:createdOn",
                      fieldName: "createdOn",
                      type: "date",
                      value: null,
                    },
                  ]
                )
              );
            });
          }}
          items={this.state.items}
          commands={[
            {
              key: "upload",
              icon: "BulkUpload",
              name: i18n.t("participants:upload"),
              selectionRequired: false,
              confirmTitle: undefined,
              confirmMessage: (_items: any) => {
                return "";
              },
              onClick: () => {
                this.uploadRef.current?.open();
              },
            },
          ]}
          openedItem={this.state.document}
          entityPanelProps={{
            ...this.props,
            styles: undefined,
            "data-automation-id": "nvx:enterprises:documents:itempanel",
            isOpen: this.state.document !== undefined,
            onDismiss: () => {
              this.setState({
                document: undefined,
              });
            },
            commands: [
              {
                key: "download",
                disabled: this.state.downloadProgress !== "0",
                name: i18n.t("documents:download"),
                iconProps: {
                  iconName: "CloudDownload",
                },
                onClick: () => {
                  this.state.document !== undefined &&
                    this.download(this.state.document);
                },
              },
            ],
            elementName: i18n.t("document"),
            title: this.state.document?.name,
            children:
              this.state.document === undefined ? (
                <span></span>
              ) : (
                <div>
                  <div style={{ height: "40px" }}></div>
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:name")}
                    content={this.state.document?.name}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:size")}
                    content={this.state.document?.size}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:processedOn")}
                    content={this.state.document?.processedOn ?? ""}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:createdOn")}
                    content={this.state.document?.createdOn}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:createdBy")}
                    content={this.state.document?.createdBy}
                  />
                  {/*
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("documents:createdBy")}
                    content={this.state.document?.createdBy}
                  />
                  */}
                </div>
              ),
          }}
        />
        <div style={{ height: "40px" }}></div>
      </div>
    );
  }
}
