import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";
import { INavBarStyleProps, INavBarStyles } from "../NavBar";
import {
  INavigationMenuStyleProps,
  INavigationMenuStyles,
} from "../NavigationMenu";

export interface INavigationMenuPanelSubComponentStyles {
  navBar?: IStyleFunctionOrObject<INavBarStyleProps, INavBarStyles>;
  navigationMenu?: IStyleFunctionOrObject<
    INavigationMenuStyleProps,
    INavigationMenuStyles
  >;
}

export interface INavigationMenuPanelStyles
  extends IComponentBaseStyles<INavigationMenuPanelSubComponentStyles> {
  icon?: IStyle;
  panel?: IStyle;
  container?: IStyle;
}

export interface INavigationMenuPanelStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getNavigationMenuPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    INavigationMenuPanelStyleProps,
    INavigationMenuPanelStyles
  >,
  props?: INavigationMenuPanelStyleProps
): [
  IProcessedStyleSet<INavigationMenuPanelStyles>,
  INavigationMenuPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};
export const getStyles: IStyleFunction<
  INavigationMenuPanelStyleProps,
  INavigationMenuPanelStyles
> = (props: INavigationMenuPanelStyleProps): INavigationMenuPanelStyles => {
  return {
    root: [
      "ms-NavigationMenuPanel",
      {
        margin: 0,
      },
    ],
    icon: [
      "ms-NavigationNenuCollapseIcon",
      {
        color: props.theme.palette.black,
        fontSize: "21px",
      },
    ],
    container: [
      "ms-NavigationMenuPanelContainer",
      {
        marginTop: "15px",
      },
    ],
  };
};
