import {
  ChoiceGroup,
  DatePicker,
  Dropdown,
  IChoiceGroupOption,
  IDropdownOption,
  initializeComponentRef,
  MessageBar,
  MessageBarType,
  Selection,
  Separator,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import Moment from "moment";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
} from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import {
  ArrivalRequestParticipantSchema,
  ITransportArrivalRequestParticipant,
} from "src/models/Messages/ITransportArrivalRequestParticipant";
import { NewTransportArrivalRequestSchema } from "src/models/Messages/INewTransportArrivalRequest";
import i18n from "src/services/i18n";
import { TransportArrivalRequestService } from "src/services/Messages/TransportArrivalRequestService";
import { validate } from "src/utils/validator";
import { ArrivalAssistedParticipantCard } from "../ArrivalAssistedParticipantCard";
import { getTransportArrivalAssistedRequestEditorClassNames } from "./TransportArrivalAssistedRequestEditor.styles";
import {
  ITransportArrivalAssistedRequestEditorProps,
  ITransportArrivalAssistedRequestEditorState,
} from "./TransportArrivalAssistedRequestEditor.types";

export class TransportArrivalAssistedRequestEditorComponent extends React.Component<
  ITransportArrivalAssistedRequestEditorProps,
  ITransportArrivalAssistedRequestEditorState
> {
  constructor(props: ITransportArrivalAssistedRequestEditorProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {
      specific: false,
      participants: [],
      participantsFetched: false,
    };

    const { inputMessage, model } = props;
    if (inputMessage && !model) {
      TransportArrivalRequestService.getArrivalRequest(
        objectId(props.enterprise),
        inputMessage.itemId
      ).then((x) => {
        TransportArrivalRequestService.getDataModel(objectId(props.enterprise))
          .then((y) => {
            const request = x;
            if (request.requestId) {
              TransportArrivalRequestService.getParticipants(
                objectId(props.enterprise),
                request.requestId
              ).then((w) => {
                props.onDataModelUpdate(y, {
                  ...request,
                  participants: w.map((z) => {
                    const pos = request.participants.filter(
                      (zz) => zz.id === z.id
                    );
                    return pos.length === 0 ? z : pos[0];
                  }),
                  errors: [],
                });
              });
            } else {
              props.onDataModelUpdate(y, {
                ...request,
                errors: [],
              });
            }
          })
          .catch((_y) => {
            console.log("error");
          });
      });
    } else if (!model) {
      TransportArrivalRequestService.getDataModel(objectId(props.enterprise))
        .then((x) => {
          this.props.onDataModelUpdate(x);
        })
        .catch((_x) => {
          console.log("error");
        });
    }
  }

  public validate = (): string[] => {
    const { request, onUpdate } = this.props;
    const { participants } = request;
    const errors = validate(
      NewTransportArrivalRequestSchema(request.isCustom),
      request
    );
    participants.forEach((x) => {
      x.errors = (
        x.selected ? validate(ArrivalRequestParticipantSchema, x) : []
      ) as any;
    });
    if (
      request.requestId &&
      participants.filter((x) => x.selected).length === 0
    ) {
      errors.participants =
        "messages:assisted:newdeparture:participants:required";
    }

    if (
      participants.filter((x) => x.selected && x.errors.length > 0).length > 0
    ) {
      errors["participants:item"] = "error";
    } else if (
      Object.keys(errors).filter((x) => x === "participants:item").length > 0
    ) {
      delete errors["participants:item"];
    }

    onUpdate({ ...request, participants: participants }, true, errors as any);
    return errors as any;
  };

  render(): JSX.Element {
    const { styles, mobile, onUpdate, request, model, errors } = this.props;
    const [classNames, subComponentStyles] =
      getTransportArrivalAssistedRequestEditorClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const participantsFetched: boolean = request?.participants !== undefined;
    const { requestDate, requestId, participants } = request;
    const requests = model
      ? model.requests.map((x) => {
          const i: IDropdownOption = {
            key: parseInt(x.key ?? ""),
            text: x.name,
          };
          return i;
        })
      : [];
    const cities = model
      ? model.cities.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "").toString(),
            text: x.name,
          };
          return i;
        })
      : [];
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text style={{ fontWeight: 600 }}>
                {i18n.t("messages:assisted:newarrival:warning:title")}
              </Text>
              <Text style={mobile ? undefined : { maxWidth: "500px" }}>
                {i18n.t("messages:assisted:newarrival:warning:1")}
              </Text>
              <Text style={mobile ? undefined : { maxWidth: "500px" }}>
                {i18n.t("messages:assisted:newarrival:warning:2")}
              </Text>
            </Stack>
          </MessageBar>
          <Stack horizontal tokens={{ childrenGap: mobile ? 10 : 50 }}>
            <Dropdown
              styles={{ root: { minWidth: "192px" } }}
              required
              label={i18n.t("messages:assisted:newarrival:requestnumber")}
              options={requests}
              errorMessage={errors.requestId}
              selectedKey={requestId}
              onChange={(
                _ev: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => {
                option &&
                  onUpdate({
                    ...request,
                    requestId: parseInt(option.key.toString()),
                    requestNumber: option.text,
                  });
                option &&
                  TransportArrivalRequestService.getParticipants(
                    objectId(this.props.enterprise),
                    parseInt(option.key.toString())
                  ).then((x) => {
                    onUpdate(
                      {
                        ...request,
                        requestId: parseInt(option.key.toString()),
                        requestNumber: option.text,
                        participants: x,
                      },
                      true
                    );
                  });
              }}
              placeholder={i18n.t("messages:assisted:newarrival:select")}
            />
            <Stack tokens={{ childrenGap: 10 }}>
              <LabelField
                {...this.props}
                styles={{
                  root: {
                    marginTop: "5px",
                  },
                }}
                label={i18n.t("messages:assisted:newarrival:enterprise")}
                content={this.props.enterprise.name}
              />
            </Stack>
          </Stack>
          <Separator />
          <Stack
            horizontal={!mobile}
            tokens={{ childrenGap: mobile ? 10 : 50 }}
          >
            <Stack tokens={{ childrenGap: 5 }}>
              <DatePicker
                isRequired={true}
                label={i18n.t("messages:assisted:newarrival:requestdate")}
                title={""}
                minDate={Moment(new Date()).subtract(1, "day").toDate()}
                maxDate={new Date(2199, 1, 1)}
                formatDate={(date?: Date) => {
                  if (!date) return "";
                  return Moment(date).locale(i18n.getLanguage()).format("LL");
                }}
                showGoToToday={false}
                strings={i18n.CalendarStrings()}
                value={
                  requestDate
                    ? Moment(requestDate, "YYYY-MM-DD").toDate() <
                      Moment(new Date()).subtract(1, "day").toDate()
                      ? undefined
                      : Moment(requestDate, "YYYY-MM-DD").toDate()
                    : undefined
                }
                onSelectDate={(date: Date | null | undefined) => {
                  onUpdate({
                    ...request,
                    requestDate:
                      date === null
                        ? undefined
                        : Moment(date).format("YYYY-MM-DD"),
                  });
                }}
              />
              {errors.requestDate && (
                <Text
                  style={{ color: this.props.theme.palette.redDark }}
                  variant={"small"}
                >
                  {errors.requestDate}
                </Text>
              )}
            </Stack>
            <StackItem>
              <Stack
                horizontal={!mobile}
                tokens={{ childrenGap: mobile ? 10 : 20 }}
              >
                <ChoiceGroup
                  selectedKey={request?.isCustom ? "custom" : "city"}
                  onChange={(
                    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    option?: IChoiceGroupOption
                  ) => {
                    option &&
                      onUpdate({
                        ...request,
                        isCustom: option.key === "custom",
                      });
                  }}
                  options={[
                    {
                      key: "city",
                      text: i18n.t("messages:assisted:newarrival:option:city"),
                    },
                    {
                      key: "custom",
                      text: i18n.t(
                        "messages:assisted:newarrival:option:custom"
                      ),
                    },
                  ]}
                />
                <StackItem grow>
                  {!request?.isCustom && (
                    <Dropdown
                      required
                      errorMessage={errors.city}
                      selectedKey={
                        request?.city ? request.city.toString() : undefined
                      }
                      styles={{
                        callout: {
                          minWidth: "300px",
                        },
                      }}
                      options={cities}
                      label={i18n.t("messages:assisted:newarrival:city")}
                      onChange={(
                        _ev: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption
                      ) => {
                        option &&
                          onUpdate({
                            ...request,
                            city: parseInt(option.key.toString()),
                            cityName: option.text,
                          });
                      }}
                    />
                  )}
                  {request.isCustom && (
                    <TextField
                      label={i18n.t(
                        "messages:assisted:newdeparture:completeaddress"
                      )}
                      multiline={true}
                      rows={4}
                      value={request.custom}
                      errorMessage={errors.custom}
                      maxLength={250}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        newValue &&
                          onUpdate({
                            ...request,
                            isCustom: true,
                            custom: newValue,
                          });
                      }}
                    />
                  )}
                </StackItem>
              </Stack>
            </StackItem>
          </Stack>
          <Separator />
          <Stack tokens={{ childrenGap: 10 }}>
            <Text style={{ fontWeight: "bold" }}>
              {i18n.t("messages:assisted:participants")}
            </Text>
            {errors.participants && (
              <MessageBar messageBarType={MessageBarType.error}>
                {i18n.t(errors.participants)}
              </MessageBar>
            )}
            <GridView
              {...this.props}
              styles={undefined}
              embedded={true}
              isItemSelected={(item: ITransportArrivalRequestParticipant) => {
                return item.selected;
              }}
              onSelectionChanged={(
                items: ITransportArrivalRequestParticipant[],
                selection?: Selection
              ) => {
                items.forEach((x) => (x.selected = true));
                const { request, onUpdate } = this.props;
                const { participants } = request;
                const paths = (window.event as any).path;
                if (
                  paths.filter((y: any) => {
                    const res =
                      (y.className ?? "").indexOf("ms-DetailsRow-fields") !==
                        -1 ||
                      (y.className ?? "").indexOf("ms-Dropdown-") !== -1 ||
                      (y.className ?? "").indexOf("ms-TextField-") !== -1;
                    /*
                    //NOTE:  Useful when introducing new row controls because it messes selection
                    console.log(
                      `${y.className}   ::::    ${res ? "yes" : "no"}`
                    ) */

                    return res;
                  }).length > 0
                ) {
                  participants.forEach((x) => {
                    if (x.selected) {
                      selection?.setKeySelected(x.id.toString(), true, false);
                    }
                  });
                } else {
                  participants.forEach((x) => {
                    x.selected = items.filter((y) => y.id === x.id).length > 0;
                  });
                }
                onUpdate({ ...request, participants: participants });
              }}
              columns={[
                {
                  key: "name",
                  name: "messages:assisted:newdeparture:participant",
                  minWidth: undefined,
                  onRenderCell: (item: ITransportArrivalRequestParticipant) => {
                    return (
                      <Stack tokens={{ childrenGap: 2 }}>
                        <Text>{item.name}</Text>
                        <Text variant={"small"}>{item.code}</Text>
                        <Text variant={"small"}>{item.date}</Text>
                      </Stack>
                    );
                  },
                },
              ]}
              onRenderTile={(
                item: ITransportArrivalRequestParticipant,
                columnWidth: number,
                selection: Selection,
                callback: (sel: Selection) => void
              ): JSX.Element => {
                return (
                  <ArrivalAssistedParticipantCard
                    {...this.props}
                    styles={subComponentStyles?.gridCard}
                    item={item}
                    columnWidth={columnWidth}
                    selection={selection}
                    onSelected={(item: ITransportArrivalRequestParticipant) => {
                      ensureSelection(selection, item);
                      item.selected = !item.selected;
                      selection.setKeySelected(
                        item.key,
                        selection
                          .getSelection()
                          .filter((x) => x.key === item.key).length === 0,
                        false
                      );
                      callback(selection);
                    }}
                  />
                );
              }}
              onDataPaging={() => {}}
              options={GridViewOptionsBuilder.getOptions(
                GridViewMode.list,
                "name",
                false,
                !requestId || participantsFetched
              )}
              emptyMessage={i18n.t(
                requestId && participantsFetched && participants.length === 0
                  ? "messages:assisted:participants:arrivee:noparticipants"
                  : "messages:assisted:participants:emptymessage"
              )}
              items={participants}
            />
            <div style={{ height: "20px" }}></div>
          </Stack>
        </Stack>
      </div>
    );
  }
}
