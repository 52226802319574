import axios from "axios";
import {
  IMessageSummary,
  INewMessageModel,
} from "src/models/Messages/IMessageSummary";
import { INewTransportArrivalRequest } from "src/models/Messages/INewTransportArrivalRequest";
import { ITransportArrivalRequest } from "src/models/Messages/ITransportArrivalRequest";
import { ITransportArrivalRequestDataModel } from "src/models/Messages/ITransportArrivalRequestDataModel";
import { ITransportArrivalRequestParticipant } from "src/models/Messages/ITransportArrivalRequestParticipant";
import AuthService from "../AuthService";
import i18n from "../i18n";

export class TransportArrivalRequestService {
  public static async getDataModel(
    enterpriseId: number
  ): Promise<ITransportArrivalRequestDataModel> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/transportrequests/model`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public static async getArrivalRequest(
    enterpriseId: number,
    messageId: number
  ): Promise<ITransportArrivalRequest> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/${messageId}/assisted/transportrequest`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public static async getParticipants(
    enterpriseId: number,
    requestId: number
  ): Promise<ITransportArrivalRequestParticipant[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/transportrequests/${requestId}/participants`,
      {},
      {
        headers: {
          Authorization: "Bearer " + AuthService.getUserInfo()?.token,
          "X-Message-Language": i18n.getLanguage(),
        },
        withCredentials: false,
      }
    );
    return x.data as any;
  }

  public static async send(
    enterpriseId: number,
    message: INewMessageModel,
    request: INewTransportArrivalRequest,
    draft: boolean
  ): Promise<IMessageSummary> {
    const x = await axios.put(
      `/api/enterprises/${enterpriseId}/messages/assisted/transportrequests`,
      {
        message: {
          ...message,
          draft: draft,
        },
        request: {
          ...request,
          custom: request.isCustom ? request.custom : undefined,
          requestDate:
            request.requestDate !== undefined && request.requestDate != null
              ? i18n.parseISOLocal(request.requestDate)
              : null,
          draft: draft,
        },
        draft: draft,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }
}
