import * as React from "react";
import {
  Dialog,
  DialogType,
  DialogFooter,
  PrimaryButton,
  DefaultButton,
} from "@fluentui/react";
import i18n from "src/services/i18n";

export interface IGridViewDialogProperties {
  isConfirming: boolean;
  confirmMessage?: string | JSX.Element;
  confirmTitle?: string;
  onDismiss: (result: boolean) => void;
  yes?: string;
  no?: string;
  yesDisabled?: boolean;
}

export default class GridViewDialog extends React.Component<IGridViewDialogProperties> {
  constructor(props: IGridViewDialogProperties) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <Dialog
        hidden={!this.props.isConfirming}
        onDismiss={() => this.props.onDismiss(false)}
        dialogContentProps={{
          type: DialogType.normal,
          title: this.props.confirmTitle,
          closeButtonAriaLabel: i18n.t("global:close"),
          subText:
            typeof this.props.confirmMessage === "string"
              ? this.props.confirmMessage
              : undefined,
        }}
        modalProps={{
          isBlocking: true,
          styles: { main: { maxWidth: 450 } },
        }}
      >
        {typeof this.props.confirmMessage !== "string"
          ? this.props.confirmMessage
          : undefined}
        <DialogFooter>
          <PrimaryButton
            disabled={this.props.yesDisabled}
            data-automation-id="nvx:grid:confirm:yes"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              this.props.onDismiss(true);
            }}
            text={this.props.yes ?? i18n.t("global:yes")}
          />
          <DefaultButton
            data-automation-id="nvx:grid:confirm:no"
            onClick={(ev) => {
              ev.preventDefault();
              ev.stopPropagation();
              this.props.onDismiss(false);
            }}
            text={this.props.no ?? i18n.t("global:no")}
          />
        </DialogFooter>
      </Dialog>
    );
  }
}
