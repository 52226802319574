import axios from "axios";
import { IInspectionRequest } from "src/models/Messages/IInspectionRequest";
import AuthService from "../AuthService";
import { IInspectionRequestDataModel } from "src/models/Messages/IInspectionRequestDataModel";
import { INewMessageModel } from "src/models/Messages/IMessageSummary";
import { INewInspectionRequest } from "src/models/Messages/INewInspectionRequest";
import i18n from "../i18n";

export class InspectionRequestService {
  public static async getInspectionRequest(
    enterpriseId: number,
    messageId: number
  ): Promise<IInspectionRequest> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/${messageId}/assisted/inspectionrequest`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.status > 299 ? undefined : (x.data as any);
  }

  public static async getInspectionRequestAndModel(
    enterpriseId: number,
    messageId: number
  ): Promise<[IInspectionRequest, IInspectionRequestDataModel]> {
    const x = await axios.all([
      axios.post(
        `/api/enterprises/${enterpriseId}/messages/${messageId}/assisted/inspectionrequest`,
        {},
        AuthService.getAuthHeaderWithLanguage()
      ),
      axios.post(
        `/api/enterprises/${enterpriseId}/messages/assisted/inspectionrequests/model`,
        {},
        AuthService.getAuthHeaderWithLanguage()
      ),
    ]);
    return [x[0].data as any, x[1].data as any];
  }

  public static async getInspectionRequestEditModel(
    enterpriseId: number
  ): Promise<IInspectionRequestDataModel> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/inspectionrequests/model`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static send(
    enterpriseId: number,
    message: INewMessageModel,
    request: INewInspectionRequest,
    draft: boolean
  ): Promise<void> {
    return axios.put(
      `/api/enterprises/${enterpriseId}/messages/assisted/inspectionrequests`,
      {
        message: {
          ...message,
          draft: draft,
        },
        request: {
          ...request,
          contactId: request.contactId === -1 ? undefined : request.contactId,
          estimatedDeposit: request.estimatedDeposit
            ? i18n.parseISOLocal(request.estimatedDeposit)
            : null,
          lastInspectionDate: request.lastInspectionDate
            ? i18n.parseISOLocal(request.lastInspectionDate)
            : null,
          draft: draft,
        },
        draft: draft,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
  }
}
