import * as React from "react";
import { getTransportArrivalAssistedControlClassNames } from "./TransportArrivalAssistedControl.styles";
import {
  ITransportArrivalAssistedControlState,
  ITransportArrivalAssistedControlProps,
} from "./TransportArrivalAssistedControl.types";
import {
  Stack,
  StackItem,
  ActivityItem,
  Text,
  Icon,
  Link,
} from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { ITransportArrivalRequestParticipant } from "src/models/Messages/ITransportArrivalRequestParticipant";
import { TransportArrivalRequestService } from "src/services/Messages/TransportArrivalRequestService";
import { objectId } from "src/models/IObjectReference";

export class TransportArrivalAssistedControlComponent extends React.Component<
  ITransportArrivalAssistedControlProps,
  ITransportArrivalAssistedControlState
> {
  constructor(props: ITransportArrivalAssistedControlProps) {
    super(props);
    this.state = {};
    this.getArrivalRequest();
  }

  componentDidUpdate() {
    if (
      !this.state.request ||
      this.props.message.itemId !== this.state.request.id
    ) {
      this.getArrivalRequest();
    }
  }

  private getArrivalRequest = () => {
    TransportArrivalRequestService.getArrivalRequest(
      objectId(this.props.enterprise),
      this.props.message.itemId
    ).then((x) => {
      this.setState({
        request: x,
      });
    });
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getTransportArrivalAssistedControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { request } = this.state;
    const participants: ITransportArrivalRequestParticipant[] =
      request?.participants ?? [];

    return (
      <div className={classNames.root}>
        <Stack grow horizontal={true}>
          <StackItem grow>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("messages:assisted:newarrival:requestnumber")}
              content={request?.requestNumber ?? ""}
            />
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("messages:assisted:newarrival:enterprise")}
              content={this.props.enterprise.name}
            />
          </StackItem>
          <StackItem grow>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("messages:assisted:newarrival:requestdate")}
              content={request?.requestDate ?? ""}
            />
            {request?.isCustom ? (
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("messages:assisted:newdeparture:completeaddress")}
                content={request?.custom ?? ""}
              />
            ) : (
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("messages:assisted:newarrival:city")}
                content={request?.cityName ?? ""}
              />
            )}
          </StackItem>
        </Stack>

        <div>
          <div style={{ clear: "both" }}></div>
        </div>

        <div>
          {participants.map((x) => (
            <div
              key={`card_participant_${x.id}`}
              style={{ float: mobile ? undefined : "left", padding: "5px" }}
            >
              <ActivityItem
                activityIcon={<Icon iconName={"Contact"} />}
                comments={
                  <Stack
                    key={"comments"}
                    horizontal={!mobile}
                    tokens={{ childrenGap: 5 }}
                  ></Stack>
                }
                timeStamp={x.date}
                activityDescription={[
                  x.workerId ? (
                    <Link
                      key={1}
                      className={classNames.nameText}
                      onClick={() => {
                        this.props.route.history.push(
                          `/participants/${x.workerId}`
                        );
                      }}
                    >
                      {x.name}
                    </Link>
                  ) : (
                    <Text>{x.name}</Text>
                  ),
                  <span
                    style={{ marginLeft: "5px" }}
                    key={2}
                  >{` ${x.code}`}</span>,
                ]}
              ></ActivityItem>
            </div>
          ))}
          <div style={{ clear: "both" }}></div>
        </div>
      </div>
    );
  }
}
