import * as React from "react";
import { getAuthorizationWarningPanelClassNames } from "./AuthorizationWarningPanel.styles";
import {
  IAuthorizationWarningPanelState,
  IAuthorizationWarningPanelProps,
} from "./AuthorizationWarningPanel.types";
import { Panel, Stack, Text, Icon, PrimaryButton } from "@fluentui/react";
import i18n from "src/services/i18n";

export class AuthorizationWarningPanelComponent extends React.Component<
  IAuthorizationWarningPanelProps,
  IAuthorizationWarningPanelState
> {
  render(): JSX.Element {
    const { styles, mobile, warning, onDismiss } = this.props;
    const [classNames] = getAuthorizationWarningPanelClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <Panel
          isOpen={warning}
          headerText={"Attention"}
          isLightDismiss={true}
          isBlocking={true}
          hasCloseButton={true}
          onDismiss={onDismiss}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Icon iconName={"Info"} />
            <Text style={{ fontWeight: 600 }}>{"Préautorisation d'achat"}</Text>
          </Stack>
          <div style={{ height: mobile ? "20px" : "40px" }}></div>
          <Stack tokens={{ childrenGap: 10 }}>
            <Text
              style={{
                textAlign: "justify",
                marginRight: mobile ? undefined : "-8px",
              }}
            >
              {i18n.t("messages:assisted:preauth:1")}
            </Text>
            <Text
              style={{
                textAlign: "justify",
                marginRight: mobile ? undefined : "-8px",
              }}
            >
              {i18n.t("messages:assisted:preauth:2")}
            </Text>
            <Text style={{ fontWeight: 600 }}>
              {i18n.t("messages:assisted:preauth:3")}
            </Text>
            <PrimaryButton text={"Ok"} width="100%" onClick={onDismiss} />
          </Stack>
        </Panel>
      </div>
    );
  }
}
