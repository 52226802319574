import { SearchBox, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { GenericPageHeader } from "src/components/GenericPageHeader";
import { IDocumentCategory } from "src/models/Documents/IDocumentCategory";
import DocumentService from "src/services/Documents/DocumentService";
import i18n from "src/services/i18n";
import { DocumentCategories } from "../DocumentCategories";
import { DocumentsCategoryItems } from "../DocumentsCategoryItems";
import { DocumentsSearchResults } from "../DocumentsSearchResults";
import { getDocumentsPageClassNames } from "./DocumentsPage.styles";
import {
  IDocumentsPageProps,
  IDocumentsPageState,
} from "./DocumentsPage.types";

export class DocumentsPageComponent extends React.Component<
  IDocumentsPageProps,
  IDocumentsPageState
> {
  constructor(props: IDocumentsPageProps) {
    super(props);
    this.state = {
      selected: null,
    };
  }

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getDocumentsPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <GenericPageHeader
          title={i18n.t("menu:documents")}
          {...this.props}
          styles={undefined}
        />
        <Stack
          horizontal={!mobile}
          styles={{
            root: {
              marginLeft: "10px",
              marginTop: this.props.mobile ? "5px" : "20px",
            },
          }}
          verticalAlign={"center"}
          tokens={{ childrenGap: mobile ? 10 : 20 }}
        >
          <StackItem>
            <SearchBox
              placeholder={i18n.t("global:search")}
              onClear={() => {
                this.setState({
                  search: undefined,
                  results: undefined,
                });
              }}
              onSearch={(newValue: any) => {
                if (newValue) {
                  DocumentService.searchContent(newValue).then((x) => {
                    this.setState({
                      results: x,
                      search: newValue.toString(),
                    });
                  });
                  this.setState({
                    search: newValue.toString(),
                  });
                } else {
                  this.setState({
                    search: undefined,
                    results: undefined,
                  });
                }
              }}
            />
          </StackItem>
        </Stack>
        {this.state.search && (
          <DocumentsSearchResults
            {...this.props}
            styles={undefined}
            search={this.state.search}
            results={this.state.results ? this.state.results : []}
            onDismiss={() => {
              this.setState({
                search: undefined,
                results: [],
                selected: null,
              });
            }}
          />
        )}
        {!this.props.mobile && <div style={{ height: "5px" }}></div>}
        {!this.state.search && this.state.selected === null && (
          <DocumentCategories
            {...this.props}
            styles={undefined}
            onSelected={(item: IDocumentCategory) =>
              this.setState({
                search: undefined,
                results: undefined,
                selected: item,
              })
            }
          />
        )}
        {!this.state.search && this.state.selected != null && (
          <DocumentsCategoryItems
            {...this.props}
            styles={undefined}
            category={this.state.selected}
            onDismiss={() =>
              this.setState({ search: undefined, selected: null })
            }
          />
        )}
      </div>
    );
  }
}
