import {
  DefaultPalette,
  Icon,
  TooltipHost,
  IProcessedStyleSet,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import i18n from "src/services/i18n";
import { uniqueId } from "src/utils/uniqueId";
import { IEnterprisesPageStyles } from ".";
import { DocumentSummaryControl } from "../DocumentSummaryControl";
import { EnterpriseAddress } from "../EnterpriseAddress";
import { EnterpriseAlertLevel } from "../EnterpriseAlertLevel";
import { EnterpriseContactInfo } from "../EnterpriseContactInfo";
import { EnterpriseContactsControl } from "../EnterpriseContactsControl";
import { EnterpriseDocumentsControl } from "../EnterpriseDocumentsControl";
import { EnterpriseHeader } from "../EnterpriseHeader";
import { PaymentMethodsControl } from "../PaymentMethodsControl";
import {
  getEnterprisesPageClassNames,
  IEnterprisesPageSubComponentStyles,
} from "./EnterprisesPage.styles";
import {
  IEnterprisesPageProps,
  IEnterprisesPageState,
} from "./EnterprisesPage.types";

const themeIndex: number = 3; // Math.floor(Math.random() * 4);

export class EnterprisesPageComponent extends React.Component<
  IEnterprisesPageProps,
  IEnterprisesPageState
> {
  constructor(props: IEnterprisesPageProps) {
    super(props);
    this.state = {
      enterprise: null,
      tab: "0",
    };

    this.refreshIfNeeded(true);
  }

  private renderTabDocument = (
    mobile: any,
    classNames: IProcessedStyleSet<IEnterprisesPageStyles>,
    subComponentStyles?: IEnterprisesPageSubComponentStyles,
    scrollableTargetId?: string
  ) => {
    return (
      <PivotItem
        headerText={i18n.t("enterprise:tab:documents")}
        itemKey={"1"}
        key={"1"}
      >
        <div className="ms-Grid" style={{ marginTop: "10px" }} dir="ltr">
          <div className={classNames.msGridRow}>
            <div className="ms-Grid-col ms-sm12">
              <div
                style={{
                  backgroundColor: DefaultPalette.white,
                  margin: mobile ? "0 0 10px 0" : "0 10px 10px 10px",
                }}
                className="ms-depth-16"
              >
                <div>
                  <div
                    style={{
                      float: "left",
                      paddingLeft: "5px",
                      paddingRight: "10px",
                    }}
                  >
                    <h2>
                      <Icon style={{ fontSize: "22px" }} iconName="Attach" />
                    </h2>
                  </div>
                  <div style={{ float: "left" }}>
                    <h2>{i18n.t("enterprise:tab:documents")}</h2>
                  </div>
                  <div className="clear-both"></div>
                </div>
                <EnterpriseDocumentsControl
                  {...this.props}
                  scrollableTargetId={scrollableTargetId}
                  styles={subComponentStyles?.documentsSummary}
                />
              </div>
            </div>
          </div>
        </div>
      </PivotItem>
    );
  };

  refreshIfNeeded = (force?: boolean): void => {
    if (force || this.state.enterprise != null) {
      if (
        force ||
        (this.props.enterprise.key !== this.state.enterprise?.id.toString() &&
          this.props.enterprise.key !== "")
      ) {
        EnterpriseService.getEnterprise(this.props.enterprise).then((x) => {
          this.setState({
            enterprise: x,
          });
        });
      }
    }
  };

  render(): JSX.Element {
    const { styles, tablet } = this.props;
    const [classNames, subComponentStyles] = getEnterprisesPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    if (
      this.state.enterprise != null &&
      this.state.enterprise.id.toString() !==
        this.props.enterprise.key?.toString()
    ) {
      setTimeout(() => {
        this.refreshIfNeeded(true);
      }, 300);
    }

    const id: string = uniqueId("nvx_gridview_viewport_");

    return (
      <div id={id} className={classNames.root}>
        <EnterpriseHeader
          {...this.props}
          {...this.state}
          styles={subComponentStyles?.header}
          themeIndex={themeIndex}
        />
        {this.state.enterprise === null ? (
          <div></div>
        ) : (
          <>
            {this.state.enterprise === null ? (
              <div></div>
            ) : (
              <div
                style={{
                  width: "100%",
                  minHeight: "500px",
                  backgroundColor: DefaultPalette.neutralLighter,
                }}
              >
                <Pivot
                  styles={subComponentStyles?.pivot}
                  onLinkClick={(item?: PivotItem) => {
                    this.setState({
                      tab:
                        item?.props.itemKey === undefined
                          ? "0"
                          : item.props.itemKey,
                    });
                  }}
                  style={{ backgroundColor: DefaultPalette.neutralLighter }}
                  selectedKey={this.state.tab.toString()}
                >
                  <PivotItem
                    headerText={i18n.t("enterprise:tab:enterprise")}
                    itemKey={"0"}
                    key={"0"}
                  >
                    <div
                      className="ms-Grid"
                      style={{ marginTop: "10px" }}
                      dir="ltr"
                    >
                      <div className={classNames.msGridRow}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4">
                          <div
                            style={{
                              backgroundColor: DefaultPalette.white,
                              margin: tablet
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <EnterpriseContactInfo
                              {...this.props}
                              styles={subComponentStyles?.info}
                              enterprise={this.state.enterprise}
                            />
                          </div>
                          <div
                            style={{
                              backgroundColor: DefaultPalette.white,
                              margin: tablet
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <EnterpriseAddress
                              {...this.props}
                              styles={subComponentStyles?.address}
                              enterprise={this.state.enterprise}
                            />
                          </div>
                          {this.state.enterprise.experienced && (
                            <div
                              style={{
                                backgroundColor: DefaultPalette.white,
                                margin: tablet
                                  ? "0 0 10px 0"
                                  : "0 10px 10px 10px",
                              }}
                              className="ms-depth-16"
                            >
                              <EnterpriseAlertLevel
                                {...this.props}
                                styles={subComponentStyles?.alertLevels}
                                enterprise={this.state.enterprise}
                              />
                            </div>
                          )}
                        </div>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl8">
                          <div
                            style={{
                              position: "relative",
                              backgroundColor: DefaultPalette.white,
                              margin: tablet
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <EnterpriseContactsControl
                              {...this.props}
                              styles={subComponentStyles?.contacts}
                              enterprise={this.props.enterprise}
                            />
                          </div>
                          <div
                            style={{
                              backgroundColor: DefaultPalette.white,
                              margin: tablet
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <div>
                              <div
                                style={{
                                  float: "left",
                                  paddingLeft: "5px",
                                  paddingRight: "10px",
                                }}
                              >
                                <TooltipHost content={i18n.t("menu:documents")}>
                                  <h2>
                                    <Icon
                                      style={{ fontSize: "22px" }}
                                      iconName="Attach"
                                    />
                                  </h2>
                                </TooltipHost>
                              </div>
                              <div style={{ float: "left" }}>
                                <h2>{i18n.t("enterprise:tab:documents")}</h2>
                              </div>
                              <div className="clear-both"></div>
                            </div>
                            <div style={{ padding: "0 0 0 10px" }}>
                              <DocumentSummaryControl
                                {...this.props}
                                styles={subComponentStyles?.documentsSummary}
                                onNavigateDocuments={() => {
                                  this.setState({
                                    tab: "1",
                                  });
                                }}
                              />
                              <div
                                className="clear-both"
                                style={{ height: "40px" }}
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </PivotItem>
                  {this.renderTabDocument(
                    tablet,
                    classNames,
                    subComponentStyles,
                    id
                  )}
                  <PivotItem
                    headerText={i18n.t("enterprise:tab:payments")}
                    itemKey={"2"}
                    key={"2"}
                  >
                    <div
                      className="ms-Grid"
                      style={{ marginTop: "10px" }}
                      dir="ltr"
                    >
                      <div className={classNames.msGridRow}>
                        <div className="ms-Grid-col ms-sm12">
                          <div
                            style={{
                              backgroundColor: DefaultPalette.white,
                              margin: tablet
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <div>
                              <div
                                style={{
                                  float: "left",
                                  paddingLeft: "5px",
                                  paddingRight: "10px",
                                }}
                              >
                                <h2>
                                  <Icon
                                    style={{ fontSize: "22px" }}
                                    iconName="PaymentCard"
                                  />
                                </h2>
                              </div>
                              <div style={{ float: "left" }}>
                                <h2>
                                  {i18n.t("enterprises:paymentmethods:header")}
                                </h2>
                              </div>
                              <div className="clear-both"></div>
                            </div>
                            <PaymentMethodsControl
                              {...this.props}
                              scrollableTargetId={id}
                              styles={subComponentStyles?.paymentMethods}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </PivotItem>
                </Pivot>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}
