import * as React from "react";
import {
  IGridViewOptions,
  GridViewMode,
} from "../components/GridView/GridView.types";
import {
  IVisualPreferences,
  IGridViewPreferencesDictionary,
} from "../models/IVisualPreferences";
import { IGridViewPreferences } from "../models/IGridViewPreferences";
import { IQueryFilter } from "../models/IQueryFilter";
import { GridViewOptionsBuilder } from "../components/GridView/GridView.imports";

class PreferencesService {
  public get(): IVisualPreferences | undefined {
    const x = localStorage.getItem("visual");
    if (!x || x === null || x === "") return undefined;
    return JSON.parse(x);
  }

  public set(prefs: IVisualPreferences): void {
    localStorage.setItem("visual", JSON.stringify(prefs));
  }

  public setOptions<P, S>(
    options: IGridViewOptions,
    name: string,
    component: React.Component<P, S>
  ): void {
    (component as any).setState({
      options: this.asCommittedOptions(options, name),
    });
  }

  public asCommittedOptions(
    options: IGridViewOptions,
    name: string
  ): IGridViewOptions {
    const x = this.getGridViewPreferences(name);
    if (x) {
      x.options = {
        desc: options.desc,
        fetched: false,
        sortBy: options.sortBy,
        mode: options.mode,
        iconName: options.iconName,
        title: options.title,
      };
      this.setGridViewPreferences(name, x);
    } else {
      const prefs: IGridViewPreferences = {
        options: {
          desc: options.desc,
          fetched: false,
          sortBy: options.sortBy,
          mode: options.mode,
          iconName: options.iconName,
          title: options.title,
        },
        filters: undefined,
      };
      this.setGridViewPreferences(name, prefs);
    }
    return options;
  }

  public seFilters<P, S>(
    filters: IQueryFilter[],
    name: string,
    component: React.Component<P, S>
  ): void {
    (component as any).setState({
      filters: this.asCommittedFilters(filters, name),
    });
  }

  public asCommittedFilters(
    filters: IQueryFilter[],
    name: string
  ): IQueryFilter[] {
    const x = this.getGridViewPreferences(name);
    if (x) {
      x.filters =
        filters.length === 0 ||
        (filters.length === 1 &&
          filters.filter((y) => y.name === "fulltext").length === 1)
          ? undefined
          : filters;
      this.setGridViewPreferences(name, x);
    } else {
      const prefs: IGridViewPreferences = {
        options: undefined,
        filters:
          filters.length === 0 ||
          (filters.length === 1 &&
            filters.filter((y) => y.name === "fulltext").length === 1)
            ? undefined
            : filters,
      };
      this.setGridViewPreferences(name, prefs);
    }
    return filters.filter((y) => y.name !== "fulltext");
  }

  public getOptionsOrDefault(
    name: string,
    mode: GridViewMode,
    sortBy: string,
    desc: boolean
  ): IGridViewOptions {
    const x = this.getGridViewPreferences(name);
    if (x?.options) {
      x.options.fetched = false;
    }
    return x?.options ?? GridViewOptionsBuilder.getOptions(mode, sortBy, desc);
  }

  public getFiltersOrDefault(
    name: string,
    reset: boolean,
    filterPredicate: () => IQueryFilter[]
  ): IQueryFilter[] {
    if (reset) return filterPredicate();
    const x = this.getGridViewPreferences(name);
    return x?.filters ?? filterPredicate();
  }

  public setGridViewPreferences(
    name: string,
    prefs: IGridViewPreferences
  ): void {
    const x = this.get();
    if (x && x.grids) {
      x.grids[name.toString()] = prefs;
      this.set(x);
    } else {
      const npr: IGridViewPreferencesDictionary = {};
      npr[name] = prefs;
      this.set({
        menucollapsed: false,
        grids: npr,
      });
    }
    return undefined;
  }

  public getGridViewPreferences(
    name: string
  ): IGridViewPreferences | undefined {
    const x = this.get();
    if (x && x.grids) {
      return x.grids[name];
    }
    return undefined;
  }

  public setMenuCollapsed(collapsed: boolean): void {
    const x = this.get();
    if (x) {
      x.menucollapsed = collapsed;
      this.set(x);
    } else {
      this.set({
        menucollapsed: collapsed,
        grids: {},
      });
    }
  }

  public menucollapsed(): boolean {
    const x = this.get();
    if (x) {
      return x.menucollapsed;
    }
    return false;
  }
}

export default new PreferencesService();
