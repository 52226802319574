import { IObjectReference } from "../IObjectReference";
import { ISchemaObject } from "src/utils/validator";

export interface ITransportArrivalRequestParticipant {
  id: number;
  key: string;
  workerId: number | undefined;
  name: string;
  code: string;
  date: string;
  recall?: number;
  recallName?: string;
  reason?: string;
  city?: number;
  cities: IObjectReference[];
  errors: [];
  selected: boolean;
}

export const ArrivalRequestParticipantSchema: ISchemaObject = {
  name: "arrivalrequestparticipant",
  plural: "arrivalrequestparticipants",
  label: "ArrivalRequestParticipant",
  fields: {},
};
