import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentConfirmationPageProps } from "./PaymentConfirmationPage.types";
import {
  getStyles,
  IPaymentConfirmationPageStyleProps,
  IPaymentConfirmationPageStyles,
} from "./PaymentConfirmationPage.styles";
import { PaymentConfirmationPageComponent } from "./PaymentConfirmationPage.base";

/**
 * PaymentConfirmationPage description
 */
export const PaymentConfirmationPage = styled<
  IPaymentConfirmationPageProps,
  IPaymentConfirmationPageStyleProps,
  IPaymentConfirmationPageStyles
>(PaymentConfirmationPageComponent, getStyles);
