import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDocumentsSearchResultsSubComponentStyles {}

export interface IDocumentsSearchResultsStyles
  extends IAmigoBaseStyles<IDocumentsSearchResultsSubComponentStyles> {}

export interface IDocumentsSearchResultsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDocumentsSearchResultsClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentsSearchResultsStyleProps,
    IDocumentsSearchResultsStyles
  >,
  props?: IDocumentsSearchResultsStyleProps
): [
  IProcessedStyleSet<IDocumentsSearchResultsStyles>,
  IDocumentsSearchResultsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentsSearchResultsStyleProps,
  IDocumentsSearchResultsStyles
> = (
  _props: IDocumentsSearchResultsStyleProps
): IDocumentsSearchResultsStyles => {
  return {
    root: ["amigo-DocumentsSearchResults", {}],
  };
};
