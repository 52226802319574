import {
  classNamesFunction,
  divProperties,
  getInitials,
  getNativeProps,
  getRTL,
  Icon,
  IPersonaCoinProps,
  IPersonaCoinStyleProps,
  IPersonaCoinStyles,
  mergeStyles,
  PersonaPresence,
  PersonaSize,
  warnDeprecations,
} from "@fluentui/react";
import * as React from "react";
import { MediaQueries } from "../MediaQueries";

const getClassNames = classNamesFunction<
  IPersonaCoinStyleProps,
  IPersonaCoinStyles
>();

export interface IPersonaState {
  isImageLoaded?: boolean;
  isImageError?: boolean;
}

export interface INumberedPersonaCoinProps extends IPersonaCoinProps {
  mobile: boolean;
  onClick: () => void;
}

/**
 * PersonaCoin with no default styles.
 * [Use the `getStyles` API to add your own styles.](https://github.com/OfficeDev/office-ui-fabric-react/wiki/Styling)
 */
export class NumberedPersonaCoin extends React.Component<
  INumberedPersonaCoinProps,
  IPersonaState
> {
  public static defaultProps: INumberedPersonaCoinProps = {
    size: PersonaSize.size48,
    presence: PersonaPresence.none,
    imageAlt: "",
    onClick: () => {},
    mobile: false,
  };

  constructor(props: INumberedPersonaCoinProps) {
    super(props);

    if (process.env.NODE_ENV !== "production") {
      warnDeprecations("PersonaCoin", props, { primaryText: "text" });
    }

    this.state = {
      isImageLoaded: false,
      isImageError: false,
    };
  }

  // tslint:disable-next-line function-name
  public UNSAFE_componentWillReceiveProps(nextProps: IPersonaCoinProps): void {
    if (nextProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        isImageLoaded: false,
        isImageError: false,
      });
    }
  }

  public render(): JSX.Element | null {
    const {
      className,
      coinProps,
      showUnknownPersonaCoin,
      coinSize,
      styles,
      imageUrl,
      // tslint:disable:deprecation
      // onRenderCoin = this._onRenderCoin,
      // onRenderPersonaCoin = onRenderCoin,
      // tslint:enable:deprecation
      // onRenderInitials = this._onRenderInitials,
      // presence,
      // presenceTitle,
      showInitialsUntilImageLoads,
      theme,
    } = this.props;

    const size = this.props.size as PersonaSize;
    const divProps = getNativeProps<React.HTMLAttributes<HTMLDivElement>>(
      this.props,
      divProperties
    );
    const divCoinProps = getNativeProps<React.HTMLAttributes<HTMLDivElement>>(
      coinProps || {},
      divProperties
    );
    const coinSizeStyle = coinSize
      ? { width: coinSize, height: coinSize }
      : undefined;
    const hideImage = showUnknownPersonaCoin;

    /*
    const personaPresenceProps: INumberedPersonaPresenceProps = {
      mobile,
      coinSize,
      presence,
      presenceTitle,
      size,
      theme: theme ?? getTheme(),
    };
    */

    // Use getStyles from props, or fall back to getStyles from styles file.
    const classNames = getClassNames(styles, {
      theme: theme!,
      className: className,
      size,
      coinSize,
      showUnknownPersonaCoin,
    });

    const shouldRenderInitials = Boolean(
      !this.state.isImageLoaded &&
        ((showInitialsUntilImageLoads && imageUrl) ||
          !imageUrl ||
          this.state.isImageError ||
          hideImage)
    );

    return (
      <div
        role="presentation"
        {...divProps}
        className={className + " " + classNames.coin}
      >
        {
          // Render PersonaCoin if size is not size8. size10 and tiny need to removed after a deprecation cleanup.
          // tslint:disable-next-line:deprecation
          size !== PersonaSize.size8 &&
          size !== PersonaSize.size10 &&
          size !== PersonaSize.tiny ? (
            <div
              role="presentation"
              onClick={this.props.onClick}
              {...divCoinProps}
              className={classNames.imageArea}
              style={coinSizeStyle}
            >
              {shouldRenderInitials && (
                <div
                  className={mergeStyles(
                    classNames.initials,
                    !showUnknownPersonaCoin && { backgroundColor: "white" }
                  )}
                  style={coinSizeStyle}
                  aria-hidden="true"
                >
                  {this._onRenderInitials(this.props)}
                </div>
              )}
              {/*
            {!hideImage && onRenderPersonaCoin(this.props, this._onRenderCoin)}
            {this.props.presenceTitle !== "0" && (
              <NumberedPersonaPresence {...personaPresenceProps} />
            )}
            */}
            </div>
          ) : // Otherwise, render just PersonaPresence.
          this.props.presence ? (
            {
              /* <NumberedPersonaPresence {...personaPresenceProps} /> */
            }
          ) : (
            <Icon
              iconName="Mail"
              className={"NavBarButton " + classNames.size10WithoutPresenceIcon}
            />
          )
        }
        {this.props.children}
      </div>
    );
  }

  /*
  private _onRenderCoin = (props: IPersonaCoinProps): JSX.Element | null => {
    const {
      coinSize,
      styles,
      imageUrl,
      imageAlt,
      imageShouldFadeIn,
      imageShouldStartVisible,
      theme,
      showUnknownPersonaCoin,
    } = this.props;

    // Render the Image component only if an image URL is provided
    if (!imageUrl) {
      return null;
    }

    const size = this.props.size as PersonaSize;

    const classNames = getClassNames(styles, {
      theme: theme!,
      size,
      showUnknownPersonaCoin,
    });

    const dimension = coinSize || sizeToPixels[size];

    return (
      <Image
        className={classNames.image}
        imageFit={ImageFit.cover}
        src={imageUrl}
        width={dimension}
        height={dimension}
        alt={imageAlt}
        shouldFadeIn={imageShouldFadeIn}
        shouldStartVisible={imageShouldStartVisible}
        onLoadingStateChange={this._onPhotoLoadingStateChange}
      />
    );
  };

  */

  /**
   * Deprecation helper for getting text.
   */
  private _getText(): string {
    // tslint:disable-next-line:deprecation
    return this.props.text || this.props.primaryText || "";
  }

  private _onRenderInitials = (props: IPersonaCoinProps): JSX.Element => {
    let { imageInitials } = props;
    const { allowPhoneInitials, showUnknownPersonaCoin } = props;

    if (showUnknownPersonaCoin) {
      return <Icon iconName="Help" />;
    }

    const isRTL = getRTL(this.props.theme);

    imageInitials =
      imageInitials || getInitials(this._getText(), isRTL, allowPhoneInitials);

    const className = mergeStyles(
      {
        color: props.theme?.palette.themePrimary ?? "rgb(184, 72, 24)",
        marginTop: "1px",
      },
      {
        fontSize: "21px",
        selectors: {
          [MediaQueries.mobile]: {
            fontSize: "21px",
          },
          "&.ms-Icon": {
            fontSize: "21px",
            [MediaQueries.mobile]: {
              fontSize: "21px",
            },
          },
        },
      }
    );
    return imageInitials !== "" ? (
      <span>{imageInitials}</span>
    ) : (
      <Icon className={className} iconName="Mail" />
    );
  };

  /*
  private _onPhotoLoadingStateChange = (loadState: ImageLoadState) => {
    this.setState({
      isImageLoaded: loadState === ImageLoadState.loaded,
      isImageError: loadState === ImageLoadState.error,
    });

    this.props.onPhotoLoadingStateChange &&
      this.props.onPhotoLoadingStateChange(loadState);
  };

  */
}
