import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestCardProps } from "./RequestCard.types";
import {
  getStyles,
  IRequestCardStyleProps,
  IRequestCardStyles,
} from "./RequestCard.styles";
import { RequestCardComponent } from "./RequestCard.base";

/**
 * RequestCard description
 */
export const RequestCard = styled<
  IRequestCardProps,
  IRequestCardStyleProps,
  IRequestCardStyles
>(RequestCardComponent, getStyles);
