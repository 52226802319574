import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface ITransportSummaryCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface ITransportSummaryCardStyles
  extends IAmigoBaseStyles<ITransportSummaryCardSubComponentStyles> {}

export interface ITransportSummaryCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportSummaryCardClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportSummaryCardStyleProps,
    ITransportSummaryCardStyles
  >,
  props?: ITransportSummaryCardStyleProps
): [
  IProcessedStyleSet<ITransportSummaryCardStyles>,
  ITransportSummaryCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportSummaryCardStyleProps,
  ITransportSummaryCardStyles
> = (_props: ITransportSummaryCardStyleProps): ITransportSummaryCardStyles => {
  return {
    root: ["amigo-TransportSummaryCard", {}],
  };
};
