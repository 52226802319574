import {
  IColumn,
  IContextualMenuProps,
  IStyle,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import { IQueryFilter } from "src/models/IQueryFilter";
import { IComponentBaseProps, IComponentBaseTokens } from "../Base";
import { IEntityPanelProps } from "../EntityPanel/EntityPanel.types";
import {
  IGridViewStyleProps,
  IGridViewStyles,
  IGridViewSubComponentStyles,
} from "./GridView.styles";

export interface IGridViewImplementation {
  onResize: () => void;
  onSelectionChanged: () => void;
}

export interface IGridColumn<T> {
  key: string;
  name: string;
  minWidth: number | undefined;
  maxWidth?: number;
  onRenderCell?: (item: T) => JSX.Element;
  iconName?: string;
  visible?: boolean;
  styles?: IStyle;
}

export enum GridViewMode {
  // eslint-disable-next-line no-unused-vars
  summary = 1,
  // eslint-disable-next-line no-unused-vars
  list = 2,
  // eslint-disable-next-line no-unused-vars
  tiles = 3,
}

export interface IGridViewOptions {
  mode: GridViewMode;
  title: string;
  iconName: string;
  sortBy: string;
  desc: boolean;
  fetched: boolean;
}

export interface IGridCommand<T> {
  key: string;
  name: string;
  icon: string;
  menuProps?: IContextualMenuProps;
  selectionRequired?: boolean;
  confirmTitle?: string;
  confirmMessage?: (items: T[]) => string | JSX.Element;
  onClick?: (selectedItems: T[], onCompleted: () => void) => void;
  onRenderContent?: () => JSX.Element;
}

export interface IGridViewProps<T>
  extends IComponentBaseProps<
    IGridViewStyleProps,
    IGridViewStyles,
    IGridViewSubComponentStyles,
    IComponentBaseTokens
  > {
  options: IGridViewOptions;
  isDefaultOptions?: boolean;
  onOptionsChanged?: (options: IGridViewOptions) => void;
  openedItem?: T;
  items: T[];
  totalCount?: number;
  gridTitle?: string;
  scrollableTargetId?: string;
  fullTextEnabled?: boolean;
  beforeList?: React.ReactNode;
  columns: IGridColumn<T>[];
  onExport?: (filtered: boolean) => Promise<boolean | void>;
  onSort?: (fieldName: string, desc: boolean) => void;
  onQueryFilters?: (reset: boolean) => Promise<IQueryFilter[] | undefined>;
  onApplyFilters?: (filters: IQueryFilter[], callback: () => void) => void;
  onDataPaging: () => void;
  onRenderTile?: (
    item: T,
    columnWidth: number,
    selection: Selection,
    callback: (sel: Selection) => void
  ) => JSX.Element;
  commands?: IGridCommand<T>[];
  onItemInvoked?: (item?: T, index?: number) => void;
  onActiveItemChanged?: (
    item?: any,
    index?: number,
    ev?: React.FocusEvent<HTMLElement>
  ) => void;
  onSelectionChanged?: (items: T[], selection?: Selection) => void;
  onRenderCell?: (item?: any, index?: number, column?: IColumn) => JSX.Element;
  selectionPreservedOnEmptyClick?: boolean;
  isSelectedOnFocus?: boolean;
  emptyMessage?: string;
  summaryColumStartIndex?: number;
  summaryColumCount?: number;
  viewPortOffset?: number;
  entityPanelProps?: IEntityPanelProps;
  embedded?: boolean;
  inboxStyled?: boolean;
  selectionMode?: SelectionMode;
  onRenderHeader?: () => JSX.Element;
  isItemSelected?: (item: T) => boolean;
  isItemSelectable?: (item: T) => boolean;
}

export interface IGridViewState<T> {
  filtering: boolean;
  selection: Selection;
  canSelect?: string;
  isConfirming: boolean;
  confirmTitle: string;
  confirmMessage: string | JSX.Element;
  confirmingCommand?: (selectedItems: T[], onCompleted: () => void) => void;
  filters: IQueryFilter[] | undefined;
  language: string;
}
