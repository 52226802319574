import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentMethodSummaryProps } from "./NewPaymentMethodSummary.types";
import {
  getStyles,
  INewPaymentMethodSummaryStyleProps,
  INewPaymentMethodSummaryStyles,
} from "./NewPaymentMethodSummary.styles";
import { NewPaymentMethodSummaryComponent } from "./NewPaymentMethodSummary.base";

/**
 * NewPaymentMethodSummary description
 */
export const NewPaymentMethodSummary = styled<
  INewPaymentMethodSummaryProps,
  INewPaymentMethodSummaryStyleProps,
  INewPaymentMethodSummaryStyles
>(NewPaymentMethodSummaryComponent, getStyles);
