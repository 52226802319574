import { Icon, Stack, StackItem, Text } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import Moment from "moment";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { getFlightSearchOffsetOfferCardClassNames } from "./FlightSearchOffsetOfferCard.styles";
import {
  IFlightSearchOffsetOfferCardProps,
  IFlightSearchOffsetOfferCardState,
} from "./FlightSearchOffsetOfferCard.types";

export class FlightSearchOffsetOfferCardComponent extends React.Component<
  IFlightSearchOffsetOfferCardProps,
  IFlightSearchOffsetOfferCardState
> {
  render(): JSX.Element {
    const { styles, mobile, offer, offerDate, searchDate, onSelected } =
      this.props;
    const [classNames] = getFlightSearchOffsetOfferCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const carrier = offer?.carrier ?? "";
    const operating = offer?.operating ?? "";

    console.log(offer);

    const diff = Moment(offerDate).diff(Moment(searchDate), "d");
    const diffText =
      diff < 0
        ? `${Math.abs(diff)} ${i18n.t(
            "transports:flights:bestprice:before:days"
          )}`
        : `${diff} ${i18n.t("transports:flights:bestprice:after:days")}`;
    const dateStr = Moment(offerDate).format("yyyy-MM-DD hh:mm");
    return (
      <Card.Item
        className={classNames.root}
        styles={{
          root: {
            borderRight: "1px solid " + this.props.theme.palette.neutralLighter,
          },
        }}
      >
        {offer && (
          <Stack
            onClick={() => {
              onSelected(offer);
            }}
            styles={{ root: { cursor: "pointer" } }}
            horizontal={!mobile}
            tokens={{ childrenGap: mobile ? 2 : 20 }}
            horizontalAlign={mobile ? undefined : "space-evenly"}
          >
            <StackItem
              align={"center"}
              styles={mobile ? { root: { width: "100%" } } : undefined}
            >
              {(carrier && carrier === "") ||
                (carrier === "multiple" && (
                  <Icon
                    iconName={"AirplaneSolid"}
                    style={{ fontSize: "32px" }}
                  />
                ))}
              {carrier && carrier !== "" && carrier !== "multiple" && (
                <img
                  src={TransportFlightSearchService.getCarrierImage(carrier)}
                  style={{ width: "32px", height: "32px" }}
                />
              )}
            </StackItem>
            <StackItem
              grow
              styles={{ root: { width: mobile ? "100%" : "40%" } }}
            >
              <LabelField
                {...this.props}
                styles={{ root: { marginTop: "3px" } }}
                label={diffText}
                content={
                  carrier !== operating
                    ? `${dateStr} ${offer.departure.substr(0, 5)} ${
                        carrier === "multiple"
                          ? offer.carriers
                              .map((y) =>
                                TransportFlightSearchService.getCarrierName(y)
                              )
                              .join(",")
                          : TransportFlightSearchService.getCarrierName(carrier)
                      } ${i18n.t(
                        "transports:flights:operatedby"
                      )} ${TransportFlightSearchService.getCarrierName(
                        operating
                      )}`
                    : `${dateStr} ${offer.departure.substr(
                        0,
                        5
                      )} ${TransportFlightSearchService.getCarrierName(
                        carrier
                      )}`
                }
              />
            </StackItem>
            <StackItem
              align={"end"}
              styles={{
                root: {
                  marginTop: mobile ? 0 : undefined,
                },
              }}
            >
              <LabelField
                {...this.props}
                styles={{ root: { marginTop: "3px", marginRight: "5px" } }}
                label={`${
                  offer.totalPrice ? offer.totalPrice.toFixed(2) : "-"
                } $`}
                content={
                  <Text variant={"small"}>
                    {diff < 0
                      ? i18n.t("transports:flights:bestprice:before")
                      : i18n.t("transports:flights:bestprice:after")}
                  </Text>
                }
              />
            </StackItem>
          </Stack>
        )}
      </Card.Item>
    );
  }
}
