import {
  Icon,
  Selection,
  SelectionMode,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import GridViewOptionsBuilder from "src/components/GridView/GridViewOptionsBuilder";
import { objectId } from "src/models/IObjectReference";
import { IQueryFilter } from "src/models/IQueryFilter";
import { ITransportSummaryItem } from "src/models/Transports/ITransportSummaryItem";
import { TravelStatusIcon } from "src/pages/Requests/TravelStatusIcon";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import TransportsService from "src/services/Transports/TransportsService";
import { TransportSummaryCard } from "../TransportSummaryCard";
import { getTransportSummaryControlClassNames } from "./TransportSummaryControl.styles";
import {
  ITransportSummaryControlProps,
  ITransportSummaryControlState,
} from "./TransportSummaryControl.types";

export class TransportSummaryControlComponent extends React.Component<
  ITransportSummaryControlProps,
  ITransportSummaryControlState
> {
  constructor(props: ITransportSummaryControlProps) {
    super(props);
    TransportsService.init();
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "transports",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "",
        false
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "transports",
        false,
        () => []
      ),
      items: [],
    };
    TransportsService.countTransportsSummary(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters
    ).then((x) => this.setState({ totalCount: x }));
    TransportsService.getTransportsSummary(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    )
      .then((x) => {
        this.setState({
          items: x,
          options: GridViewOptionsBuilder.getFetched(this),
        });
      })
      .catch(() => {
        document.location.reload();
      });
  }

  render(): JSX.Element {
    const { styles, route, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getTransportSummaryControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={`${classNames.root}`}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "0 10px 10px 10px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={undefined}
                totalCount={this.state.totalCount}
                selectionMode={SelectionMode.none}
                onItemInvoked={(item: ITransportSummaryItem) => {
                  route.history.push(`/participants/${item.travailleurId}`);
                }}
                onRenderTile={(
                  item: ITransportSummaryItem,
                  columnWidth: number,
                  selection: Selection,
                  _callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <TransportSummaryCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onClick={(_item: ITransportSummaryItem) => {
                        route.history.push(
                          `/participants/${item.travailleurId}`
                        );
                      }}
                    />
                  );
                }}
                onExport={(filtered) =>
                  TransportsService.downloadTransportSummary(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filtered ? this.state.filters : []
                  )
                }
                onSort={(fieldName: string, desc: boolean) => {
                  TransportsService.init();
                  TransportsService.getTransportsSummary(
                    objectId(this.props.enterprise),
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: PreferencesService.asCommittedOptions(
                        GridViewOptionsBuilder.getOptions(
                          this.state.options.mode,
                          fieldName,
                          desc,
                          true
                        ),
                        "transports"
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                options={this.state.options}
                onOptionsChanged={(options: IGridViewOptions) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      options,
                      "transports"
                    ),
                  });
                }}
                columns={[
                  {
                    key: "request",
                    name: "participants:positions:requestnumber",
                    minWidth: 140,
                    maxWidth: 140,
                  },
                  {
                    key: "code",
                    name: "participants:code",
                    minWidth: 80,
                    maxWidth: 100,
                  },
                  {
                    key: "name",
                    name: "participants:name",
                    minWidth: 300,
                  },
                  {
                    key: "arrivalStatus",
                    name: "requests:periods:grouping:arrivalstatus",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item) => (
                      <TravelStatusIcon
                        {...this.props}
                        requestId={item.requestId}
                        status={item.arrivalStatus}
                        arrival={true}
                        participant={item as any}
                      />
                    ),
                  },
                  {
                    key: "arrivalDate",
                    name: "requests:periods:grouping:arrivaldate",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item) => (
                      <Text
                        styles={{
                          root: {
                            fontWeight:
                              item.nextEvent === "A" ? "600" : "normal",
                          },
                        }}
                      >
                        {item.arrivalDate}
                      </Text>
                    ),
                  },
                  {
                    key: "departureStatus",
                    name: "requests:periods:grouping:departurestatus",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item) => (
                      <TravelStatusIcon
                        {...this.props}
                        requestId={item.requestId}
                        status={item.departureStatus}
                        arrival={false}
                        participant={item as any}
                      />
                    ),
                  },
                  {
                    key: "departureDate",
                    name: "requests:periods:grouping:departuredate",
                    minWidth: 120,
                    maxWidth: 120,
                    onRenderCell: (item) => (
                      <Text
                        styles={{
                          root: {
                            fontWeight:
                              item.nextEvent === "D" ? "600" : "normal",
                          },
                        }}
                      >
                        {item.departureDate}
                      </Text>
                    ),
                  },
                  {
                    key: "nextEvent",
                    name: "participants:positions:nextevent",
                    minWidth: 0,
                    visible: false,
                    maxWidth: 0,
                  },
                  {
                    key: "readyToTravel",
                    name: "requests:periods:grouping:readytotravel",
                    minWidth: 110,
                    visible: false,
                    onRenderCell: (item) => {
                      return item.readyToTravel ?? false ? (
                        <TooltipHost
                          content={
                            <div>
                              <div>
                                <Text>
                                  {i18n.t(
                                    "requests:participants:item:readytotravel:title"
                                  )}
                                </Text>
                              </div>
                              <div>
                                <Text>
                                  {i18n.t(
                                    "requests:participants:item:readytotravel:description"
                                  )}
                                </Text>
                              </div>
                            </div>
                          }
                        >
                          <Icon
                            iconName="AirTickets"
                            styles={{
                              root: {
                                fontSize: "22px",
                                color: "rgb(0, 75, 28)",
                              },
                            }}
                          />
                        </TooltipHost>
                      ) : (
                        <div></div>
                      );
                    },
                  },
                ]}
                summaryColumStartIndex={0}
                summaryColumCount={3}
                onDataPaging={() => {
                  TransportsService.getTransportsSummary(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  TransportsService.init();
                  TransportsService.getTransportsSummary(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: PreferencesService.asCommittedFilters(
                        filters,
                        "transports"
                      ),
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                onQueryFilters={(reset: boolean) => {
                  return new Promise<IQueryFilter[]>((resolve) => {
                    resolve(TransportsService.getFilters(reset));
                  });
                }}
                items={this.state.items}
                commands={[]}
              />
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }
}
