import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightOfferContainerProps } from "./FlightOfferContainer.types";
import {
  getStyles,
  IFlightOfferContainerStyleProps,
  IFlightOfferContainerStyles,
} from "./FlightOfferContainer.styles";
import { FlightOfferContainerComponent } from "./FlightOfferContainer.base";

/**
 * FlightOfferContainer
 */
export const FlightOfferContainer = styled<
  IFlightOfferContainerProps,
  IFlightOfferContainerStyleProps,
  IFlightOfferContainerStyles
>(FlightOfferContainerComponent, getStyles);
