import {
  DefaultPalette,
  GroupHeader,
  Icon,
  IGroup,
  IGroupHeaderProps,
  Selection,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  Toggle,
} from "@fluentui/react";
import * as React from "react";
import { DisplayList } from "src/components/DisplayList";
import { EntityPanel } from "src/components/EntityPanel";
import { GridView } from "src/components/GridView/GridView";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { GridViewCommandBar } from "src/components/GridView/GridViewCommandBar";
import GridViewOptionsBuilder from "src/components/GridView/GridViewOptionsBuilder";
import { objectId } from "src/models/IObjectReference";
import { IPeriodParticipant } from "src/models/Requests/IPeriodParticipant";
import i18n from "src/services/i18n";
import RequestsService from "src/services/Requests/RequestsService";
import { RequestsParticipantsCard } from "../RequestsParticipantsCard/RequestsParticipantsCard";
import { TravelStatusIcon } from "../TravelStatusIcon";
import { getRequestParticipantsControlClassNames } from "./RequestParticipantsControl.styles";
import {
  IRequestParticipantsControlProps,
  IRequestParticipantsControlState,
} from "./RequestParticipantsControl.types";

export class RequestParticipantsControlComponent extends React.Component<
  IRequestParticipantsControlProps,
  IRequestParticipantsControlState
> {
  private _selection: Selection;
  constructor(props: IRequestParticipantsControlProps) {
    super(props);
    this._selection = new Selection();
    this.state = {
      view: 0,
      withHistory: false,
      options: GridViewOptionsBuilder.getOptions(
        this.props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "",
        false,
        undefined
      ),
    };
    RequestsService.getRequestParticipants(
      this.state.options.sortBy,
      this.state.options.desc,
      objectId(this.props.enterprise),
      this.props.requestId,
      this.props.route.match?.params.id
        ? parseInt(this.props.route.match.params.version)
        : undefined
    ).then((x) => {
      this.setState({
        participants: x,
      });
      this._selection.setItems(x);
    });
  }

  private getPeriod = (items: IPeriodParticipant[]) => {
    const { periodId } = this.state;
    return items.filter((x) => x.period?.key === periodId)[0]?.period;
  };

  /*
  private getReplacement = (
    items: IPeriodParticipant[],
    item: IPeriodParticipant
  ) => {
    return `${
      items.filter(
        (r) =>
          r.poste !== undefined && r.poste === item.poste && item.key !== r.key
      )[0]?.lastName
    }, ${
      items.filter(
        (r) =>
          r.poste !== undefined && r.poste === item.poste && item.key !== r.key
      )[0]?.firstName
    }`;
  };
  */

  /*
  private _onRenderRow: IDetailsListProps["onRenderRow"] = (props) => {
    const { theme } = this.props;
    const customStyles: Partial<IDetailsRowStyles> = {};
    if (props) {
      if (props.itemIndex % 2 === 0) {
        // Every other row renders with a different background color
        customStyles.root = {};
      } else {
        customStyles.root = {
          backgroundColor: theme.palette.neutralLighter,
        };
      }

      return (
        <div>
          <DetailsRow {...props} styles={customStyles} />
        </div>
      );
    }
    return null;
  };
  */

  private onRenderHeader(props?: IGroupHeaderProps, items?: any[]) {
    const checkButtonStyle = { display: "none" };

    return (
      <GroupHeader
        styles={{
          root: {
            whiteSpace: "break-spaces!important",
            span: {
              whiteSpace: "break-spaces!important",
            },
            ".ms-GroupSpacer": {
              display: "none!important",
            },
            ".ms-GroupHeader-expand": {
              display: "none!important",
            },
          },
          check: checkButtonStyle,
        }}
        {...props}
        group={
          props?.group === undefined
            ? undefined
            : {
                ...props.group,
                count: items?.filter((x) => x.title !== "").length ?? 0,
              }
        }
        isCollapsedGroupSelectVisible={false}
      />
    );
  }

  /*
  private getPeriodIdFromGroup = (
    items: IPeriodParticipant[],
    group: string
  ) => {
    const item = items.filter((x) => {
      const fromto: string =
        x.period === undefined
          ? ""
          : i18n.formatString(
              i18n.t("requests:fromto:eimt"),
              i18n.parseISOLocalString(x.period.startDate),
              i18n.parseISOLocalString(x.period.endDate),
              x.period.EIMT
            );
      return group === fromto;
    })[0];
    return item?.period?.key ?? undefined;
  };
  */

  private toGroups = (items: IPeriodParticipant[], view: number): IGroup[] => {
    if (items.length === 0) return [];
    const periods = {};
    const groups =
      view === 1
        ? items.reduce((catsSoFar, { arrivalDate, departureDate, key }) => {
            const now = new Date();
            const groupDate =
              i18n.parseISOLocal(departureDate) > now
                ? i18n.t("requests:periods:departurefrom") + departureDate
                : i18n.parseISOLocal(arrivalDate) > now
                ? i18n.t("requests:periods:arrivalfrom") + " " + arrivalDate
                : i18n.t("requests:periods:completed");
            if (!catsSoFar[groupDate]) catsSoFar[groupDate] = [];
            catsSoFar[groupDate].push(key);
            return catsSoFar;
          }, {})
        : items.reduce((catsSoFar, { period, key }) => {
            const fromto: string =
              period === undefined
                ? ""
                : i18n.formatString(
                    i18n.t("requests:fromto:eimt"),
                    i18n.parseISOLocalString(period.startDate),
                    i18n.parseISOLocalString(period.endDate),
                    period.EIMT
                  );
            if (!catsSoFar[fromto]) catsSoFar[fromto] = [];
            periods[fromto] = period?.key ?? "";
            catsSoFar[fromto].push(key);
            return catsSoFar;
          }, {});

    const results: IGroup[] = [];
    let i = 0;
    let len = 0;
    for (const e in groups) {
      const count = groups[e].length;
      results.push({
        key: "group" + i++,
        name: e,
        startIndex: len,
        level: 0,
        count: count,
        data: periods[e],
      });
      len += count;
    }
    return results;
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getRequestParticipantsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { options } = this.state;
    // const withTiles: boolean = options.mode === GridViewMode.tiles;
    const _this = this;
    const items = this.state.participants?.filter(
      (x) => !x.cancelled && (x.enabled || this.state.withHistory)
    );
    const groupedList = false;
    let currentP: number | null = null;
    items?.forEach((x) => {
      if (currentP === null || x.poste !== currentP) {
        currentP = x.poste ?? null;
      } else {
        x.title = "";
      }
    });

    const onSort = (sortBy: string) => {
      RequestsService.getRequestParticipants(
        sortBy,
        options.sortBy === sortBy ? !options.desc : false,
        objectId(this.props.enterprise),
        this.props.requestId,
        this.props.route.match?.params.id
          ? parseInt(this.props.route.match.params.version)
          : undefined
      ).then((x) => {
        this.setState({
          participants: x,
        });
        this._selection.setItems(x);
      });

      this.setState({
        options: {
          ...options,
          sortBy: sortBy,
          desc: options.sortBy === sortBy ? !options.desc : false,
        },
      });
    };

    const groups =
      items === undefined ? undefined : this.toGroups(items, this.state.view);
    const cols = [
      {
        key: "title",
        name: i18n.t("requests:periods:participants:title"),
        fieldName: "title",
        minWidth: 180,
        maxWidth: 220,
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("title")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.title}
            </Text>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onPositionSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.title}
            </Text>
          ),
      },
      {
        key: "participant",
        name: i18n.t("requests:periods:participants:name"),
        fieldName: "firstName",
        minWidth: 200,
        maxWidth: 280,
        styles: {
          root: {
            width: "280px",
          },
          cellName: {
            width: "380px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.firstName}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >{`${item.lastName.toUpperCase()}, ${item.firstName}`}</Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >{`${x.lastName.toUpperCase()}, ${x.firstName}`}</Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >{`${item.lastName.toUpperCase()}, ${item.firstName}`}</Text>
          ),
      },
      {
        key: "participantNumber",
        name: i18n.t("requests:periods:participants:number"),
        fieldName: "participantNumber",
        minWidth: 60,
        maxWidth: 60,
        styles: {
          root: {
            width: "60px",
          },
          cellName: {
            width: "60px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("participantNumber")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.participantNumber}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.participantNumber}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {x.participantNumber}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.participantNumber}
            </Text>
          ),
      },
      {
        key: "type",
        name: i18n.t("requests:periods:participants:type"),
        fieldName: "participantType",
        minWidth: 140,
        maxWidth: 140,
        styles: {
          root: {
            width: "140px",
          },
          cellName: {
            width: "140px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("participantType")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.participantType}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.participantType}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {x.participantType}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.participantType}
            </Text>
          ),
      },
      {
        key: "arrivalStatus",
        name: i18n.t("requests:arrival:status"),
        fieldName: "arrivalStatus",
        minWidth: 95,
        maxWidth: 95,
        styles: {
          root: {
            width: "95px",
          },
          cellName: {
            width: "95px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("arrivalStatus")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.arrivalStatus}
            </Text>
          ) : (
            <Stack horizontal tokens={{ childrenGap: 5 }}>
              <TravelStatusIcon
                {...this.props}
                styles={undefined}
                arrival={true}
                participant={item}
                status={item?.arrivalStatus ?? 0}
              />
            </Stack>
          ),
      },
      {
        key: "arrivalDate",
        name: i18n.t("requests:arrival:date"),
        fieldName: "arrivalDate",
        minWidth: 110,
        maxWidth: 110,
        styles: {
          root: {
            width: "110px",
          },
          cellName: {
            width: "110px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("arrivalDate")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#333333",
                },
              }}
            >
              {item.arrivalDate}
            </Text>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.arrivalDate}
            </Text>
          ),
      },
      {
        key: "departureStatus",
        name: i18n.t("requests:departure:status"),
        fieldName: "departureStatus",
        minWidth: 110,
        maxWidth: 110,
        styles: {
          root: {
            width: "90px",
          },
          cellName: {
            width: "90px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("departureStatus")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#333333",
                },
              }}
            >
              {item.departureStatus}
            </Text>
          ) : (
            <div style={{ width: "100px" }}>
              <TravelStatusIcon
                {...this.props}
                styles={undefined}
                participant={item}
                arrival={false}
                status={item?.departureStatus ?? 0}
              />
            </div>
          ),
      },
      {
        key: "departureDate",
        name: i18n.t("requests:departure:date"),
        fieldName: "departureDate",
        minWidth: 110,
        maxWidth: 110,
        styles: {
          root: {
            width: "110px",
          },
          cellName: {
            width: "110px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("departureDate")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                },
              }}
            >
              {item.departureDate}
            </Text>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  cursor: "pointer",
                  color: "#333333",
                },
              }}
            >
              {item.departureDate}
            </Text>
          ),
      },
    ];
    const periodItem =
      items === undefined || this.state.periodId === undefined
        ? undefined
        : this.getPeriod(items);
    return (
      <>
        <EntityPanel
          {...this.props}
          styles={undefined}
          isOpen={this.state.periodId !== undefined}
          elementName={undefined}
          title={
            periodItem === undefined
              ? ""
              : i18n.formatString(
                  i18n.t("requests:fromto"),
                  i18n.parseISOLocalString(periodItem.startDate),
                  i18n.parseISOLocalString(periodItem.endDate)
                )
          }
          data-automation-id="period-info"
          onDismiss={() => this.setState({ periodId: undefined })}
        >
          {!mobile && <div style={{ height: "60px" }}></div>}
          <DisplayList
            items={[
              {
                key: i18n.t("requests:period:startdate"),
                value: periodItem?.startDate ?? "",
              },
              {
                key: i18n.t("requests:period:enddate"),
                value: periodItem?.startDate ?? "",
              },
              {
                key: i18n.t("requests:period:eimt"),
                value: periodItem?.EIMT ?? "",
              },
              {
                key: i18n.t("requests:period:eimt:delivery"),
                value: periodItem?.deliveryDateEIMT ?? "",
              },
              {
                key: i18n.t("requests:period:eimt:expiration"),
                value: periodItem?.expirationEIMT ?? "",
              },
              {
                key: i18n.t("requests:period:count"),
                value: (items ?? [])
                  .filter((x) => x.period?.key === this.state.periodId)
                  .length.toString(),
              },
            ]}
          />
        </EntityPanel>
        <div className={classNames.root}>
          <Stack
            grow
            verticalFill
            styles={{
              root: {
                width: "100%",
                padding: mobile ? "4px" : tablet ? "10px" : "20px",
              },
            }}
          >
            {/*
            <Card
              styles={{
                root: {
                  backgroundColor:
                    this.state.options.mode === GridViewMode.tiles
                      ? undefined
                      : "#fff",
                  padding:
                    this.state.options.mode === GridViewMode.tiles
                      ? undefined
                      : mobile || tablet
                      ? "10px"
                      : "20px",
                  height: "auto",
                  maxHeight: "unset",
                  width: "100%",
                  maxWidth: "unset",
                },
              }}
            >
              <Card.Item>
                */}
            <GridViewCommandBar
              {...this.props}
              menucollapsed={true}
              hasSelection={false}
              fullTextEnabled={false}
              columns={cols}
              commands={[
                {
                  key: "export",
                  name: "Exporter",
                  icon: "ExcelDocument",
                  selectionRequired: false,
                  onClick: () => {
                    RequestsService.exportRequestParticipants(
                      this.state.options.sortBy,
                      this.state.options.desc,
                      objectId(this.props.enterprise),
                      this.props.requestId
                    );
                  },
                },
                {
                  key: "view",
                  name:
                    this.state.view === 1
                      ? i18n.t("requests:periods:grouping:next")
                      : i18n.t("requests:periods:grouping:period"),
                  menuProps: {
                    items: [
                      {
                        key: "period",
                        name: i18n.t("requests:periods:grouping:period"),
                        iconProps: {
                          iconName: "CalendarYear",
                        },
                        onClick: () => this.setState({ view: 0 }),
                      },
                      {
                        key: "next",
                        name: i18n.t("requests:periods:grouping:next"),
                        iconProps: {
                          iconName: "Arrivals",
                        },
                        onClick: () => this.setState({ view: 1 }),
                      },
                    ],
                  },
                  icon: "GroupList",
                },
                {
                  key: "history",
                  name: i18n.t(
                    this.state.withHistory
                      ? "requests:periods:grouping:withhistory"
                      : "requests:periods:grouping:withouthistory"
                  ),
                  icon: "FullHistory",
                  selectionRequired: false,
                  onRenderContent: () => (
                    <Stack
                      horizontal
                      verticalAlign="center"
                      tokens={{ childrenGap: this.props.mobile ? 4 : 10 }}
                    >
                      {!this.props.mobile && (
                        <Icon
                          iconName="FullHistory"
                          styles={{
                            root: {
                              fontSize: "18px",
                              color: this.props.theme.palette.themePrimary,
                            },
                          }}
                        />
                      )}
                      <Toggle
                        aria-readonly
                        onChange={() => {
                          this.setState({
                            withHistory: !this.state.withHistory,
                          });
                        }}
                        checked={this.state.withHistory}
                        styles={{
                          root: {
                            height: "16px!important",
                            width: "42px!important",
                            marginTop: "4px",
                          },
                        }}
                      />
                      <Text>
                        {i18n.t(
                          this.state.withHistory
                            ? "requests:periods:grouping:withhistory"
                            : "requests:periods:grouping:withouthistory"
                        )}
                      </Text>
                    </Stack>
                  ),
                },
                {
                  key: "periodfilter",
                  name:
                    this.state.period === undefined
                      ? i18n.t("requests:periods:all")
                      : groups?.filter((y) => y.key === this.state.period)[0]
                          ?.name ?? "",
                  icon: "Code",
                  menuProps: {
                    items: [
                      {
                        key: "all",
                        name: i18n.t("requests:periods:all"),
                        onClick: () => {
                          this.setState({
                            period: undefined,
                          });
                        },
                      },
                    ].concat(
                      groups?.map((x) => {
                        return {
                          key: x.key,
                          name: x.name,
                          onClick: () => {
                            this.setState({
                              period: x.key,
                            });
                          },
                        };
                      }) ?? []
                    ),
                  },
                },
              ]}
              options={options}
              onShowDialog={() => {}}
            />

            {/*
            !withTiles && (
              <DetailsRow
                styles={{
                  root: {
                    width: "100%",
                    ".ms-GroupSpacer": {
                      display: "none!important",
                    },
                    borderRadius: "6px",
                    boxShadow:
                      "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px",
                    transition: "box-shadow 0.5s ease 0s",
                    ":hover": {
                      backgroundColor: "white",
                    },
                    "div.ms-DetailsRow-cell:first-child": {
                      width: "200px!important",
                    },
                    "div.ms-DetailsRow-cell:nth-child(2)": {
                      width: "240px!important",
                    },
                    "div.ms-DetailsRow-cell:nth-child(3)": {
                      width: "90px!important",
                    },
                    "div.ms-DetailsRow-cell:nth-child(4)": {
                      width: "140px!important",
                    },
                    "div.ms-DetailsRow-cell:nth-child(5)": {
                      width: "120px!important",
                    },
                    "div.ms-DetailsRow-cell:nth-child(6)": {
                      width: "120px!important",
                    },
                    "div.ms-DetailsRow-cell": {
                      fontWeight: 600,
                      color: "rgb(96, 94, 92)",
                    },
                  },
                }}
                columns={cols}
                groupNestingDepth={1}
                item={{
                  firstName: i18n.t("requests:periods:grouping:firstname"),
                  participantType: i18n.t(
                    "requests:periods:grouping:participanttype"
                  ),
                  title: i18n.t("requests:periods:grouping:title"),
                  arrivalStatus: i18n.t(
                    "requests:periods:grouping:arrivalstatus"
                  ),
                  departureStatus: i18n.t(
                    "requests:periods:grouping:departurestatus"
                  ),
                  arrivalDate: i18n.t("requests:periods:grouping:arrivaldate"),
                  departureDate: i18n.t(
                    "requests:periods:grouping:departuredate"
                  ),
                  participantNumber: i18n.t(
                    "requests:periods:grouping:participantnumber"
                  ),
                  isHeader: true,
                }}
                itemIndex={-1}
                selectionMode={SelectionMode.none}
                compact={false}
                group={undefined}
              />
            )
              */}
            {items === undefined && (
              <Stack
                styles={{
                  root: {
                    margin: "40px",
                    ".ms-Spinner-label": {
                      fontWeight: 600,
                    },
                  },
                }}
              >
                <Spinner label={i18n.t("global:loading")} />
              </Stack>
            )}
            {!groupedList &&
              items !== undefined &&
              groups !== undefined &&
              groups
                .filter(
                  (x) =>
                    this.state.period === undefined ||
                    x.key === this.state.period
                )
                .map((x) => {
                  const elements = items.slice(
                    x.startIndex,
                    x.startIndex + x.count
                  );
                  return (
                    <div key={x.key} style={{ width: "100%" }}>
                      {this.onRenderHeader(
                        {
                          group: x,
                        },
                        elements
                      )}
                      <div>
                        <GridView
                          {...this.props}
                          embedded
                          styles={undefined}
                          items={elements}
                          columns={cols}
                          selectionMode={SelectionMode.none}
                          onRenderTile={(item) => (
                            <>
                              {item !== undefined ? (
                                <RequestsParticipantsCard
                                  key={item.id}
                                  title={item.title}
                                  {...this.props}
                                  onClick={() => {
                                    if (item) {
                                      _this.props.onParticipantSelected(item);
                                    }
                                  }}
                                  styles={subComponentStyles?.gridCard}
                                  columnWidth={200}
                                  item={item}
                                />
                              ) : (
                                <div></div>
                              )}
                            </>
                          )}
                          options={GridViewOptionsBuilder.getOptions(
                            GridViewMode.list,
                            "name",
                            false,
                            true
                          )}
                          onDataPaging={() => {}}
                        />
                      </div>
                    </div>
                  );
                })}
          </Stack>
          <div className="clear-both" style={{ height: "50px" }}></div>
        </div>
      </>
    );
  }
}
