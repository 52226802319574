import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles,
} from "../EnterpriseDocumentCard/EnterpriseDocumentCard.styles";

export interface IEnterpriseDocumentsControlSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  gridCard?: IStyleFunctionOrObject<
    IEnterpriseDocumentCardStyleProps,
    IEnterpriseDocumentCardStyles
  >;
}

export interface IEnterpriseDocumentsControlStyles
  extends IAmigoBaseStyles<IEnterpriseDocumentsControlSubComponentStyles> {}

export interface IEnterpriseDocumentsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseDocumentsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseDocumentsControlStyleProps,
    IEnterpriseDocumentsControlStyles
  >,
  props?: IEnterpriseDocumentsControlStyleProps
): [
  IProcessedStyleSet<IEnterpriseDocumentsControlStyles>,
  IEnterpriseDocumentsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseDocumentsControlStyleProps,
  IEnterpriseDocumentsControlStyles
> = (
  _props: IEnterpriseDocumentsControlStyleProps
): IEnterpriseDocumentsControlStyles => {
  return {
    root: {
      width: "100%",
      marginBottom: "10px",
    },
  };
};
