import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightSearchResultsContainerSubComponentStyles {}

export interface IFlightSearchResultsContainerStyles
  extends IAmigoBaseStyles<IFlightSearchResultsContainerSubComponentStyles> {}

export interface IFlightSearchResultsContainerStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchResultsContainerClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchResultsContainerStyleProps,
    IFlightSearchResultsContainerStyles
  >,
  props?: IFlightSearchResultsContainerStyleProps
): [
  IProcessedStyleSet<IFlightSearchResultsContainerStyles>,
  IFlightSearchResultsContainerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchResultsContainerStyleProps,
  IFlightSearchResultsContainerStyles
> = (
  _props: IFlightSearchResultsContainerStyleProps
): IFlightSearchResultsContainerStyles => {
  return {
    root: [
      "amigo-FlightSearchResultsContainer",
      {
        width: "100%",
        padding: "8px",
        marginLeft: "0px",
      },
    ],
  };
};
