import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface IResetPasswordControlSubComponentStyles {}

export interface IResetPasswordControlStyles
  extends IAmigoBaseStyles<IResetPasswordControlSubComponentStyles> {}

export interface IResetPasswordControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getResetPasswordControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IResetPasswordControlStyleProps,
    IResetPasswordControlStyles
  >,
  props?: IResetPasswordControlStyleProps
): [
  IProcessedStyleSet<IResetPasswordControlStyles>,
  IResetPasswordControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IResetPasswordControlStyleProps,
  IResetPasswordControlStyles
> = (_props: IResetPasswordControlStyleProps): IResetPasswordControlStyles => {
  return {
    root: [
      "amigo-ResetPassword",
      {
        maxWidth: "100%",
        width: "420px",
        maxHeight: "100%",
        marginLeft: "50%",
        [MediaQueries.tablet]: {
          width: "45vw",
          position: "absolute",
          right: 0,
        },
        [MediaQueries.mobile]: {
          width: "100%",
          position: "absolute",
          right: 0,
          bottom: 0,
          marginLeft: 0,
        },
        "@media (min-width: 480px)": {
          maxWidth: "100%",
          width: "420px",
          maxHeight: "100%",
        },
        [MediaQueries.small]: {
          position: "absolute",
          left: 0,
          bottom: 0,
          marginLeft: 0,
        },
      },
    ],
  };
};
