import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IPaymentMethodsControlSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IPaymentMethodsControlStyles
  extends IAmigoBaseStyles<IPaymentMethodsControlSubComponentStyles> {}

export interface IPaymentMethodsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentMethodsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentMethodsControlStyleProps,
    IPaymentMethodsControlStyles
  >,
  props?: IPaymentMethodsControlStyleProps
): [
  IProcessedStyleSet<IPaymentMethodsControlStyles>,
  IPaymentMethodsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentMethodsControlStyleProps,
  IPaymentMethodsControlStyles
> = (
  _props: IPaymentMethodsControlStyleProps
): IPaymentMethodsControlStyles => {
  return {
    root: {
      width: "100%",
    },
  };
};
