import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IRequestHistoryCardStyleProps,
  IRequestHistoryCardStyles,
} from "../RequestHistoryCard/RequestHistoryCard.styles";

export interface IRequestHistoryControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<
    IRequestHistoryCardStyleProps,
    IRequestHistoryCardStyles
  >;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IRequestHistoryControlStyles
  extends IAmigoBaseStyles<IRequestHistoryControlSubComponentStyles> {}

export interface IRequestHistoryControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestHistoryControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestHistoryControlStyleProps,
    IRequestHistoryControlStyles
  >,
  props?: IRequestHistoryControlStyleProps
): [
  IProcessedStyleSet<IRequestHistoryControlStyles>,
  IRequestHistoryControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestHistoryControlStyleProps,
  IRequestHistoryControlStyles
> = (
  _props: IRequestHistoryControlStyleProps
): IRequestHistoryControlStyles => {
  return {};
};
