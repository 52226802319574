import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";

export interface IUsersPickerSubComponentStyles {}

export interface IUsersPickerStyles
  extends IComponentBaseStyles<IUsersPickerSubComponentStyles> {}

export interface IUsersPickerStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getUsersPickerClassNames = (
  styles?: IStyleFunctionOrObject<IUsersPickerStyleProps, IUsersPickerStyles>,
  props?: IUsersPickerStyleProps
): [
  IProcessedStyleSet<IUsersPickerStyles>,
  IUsersPickerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUsersPickerStyleProps,
  IUsersPickerStyles
> = (_props: IUsersPickerStyleProps): IUsersPickerStyles => {
  return {};
};
