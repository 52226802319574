import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  DefaultPalette,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IRequestParticipantFormSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  visa?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IRequestParticipantFormStyles
  extends IAmigoBaseStyles<IRequestParticipantFormSubComponentStyles> {
  countryText?: IStyle;
}

export interface IRequestParticipantFormStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantFormClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantFormStyleProps,
    IRequestParticipantFormStyles
  >,
  props?: IRequestParticipantFormStyleProps
): [
  IProcessedStyleSet<IRequestParticipantFormStyles>,
  IRequestParticipantFormSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantFormStyleProps,
  IRequestParticipantFormStyles
> = (
  _props: IRequestParticipantFormStyleProps
): IRequestParticipantFormStyles => {
  return {
    countryText: [
      "amigo-LabelField-CountryIcon-Text",
      {
        color: "rgb(72, 70, 68)",
        height: undefined,
        WebkitFontSmoothing: "antialiased",
        fontSize: "14px",
        fontWeight: 400,
      },
    ],
    subComponentStyles: {
      visa: {
        value: {
          color: DefaultPalette.themePrimary,
        },
      },
    },
  };
};
