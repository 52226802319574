import axios, { AxiosResponse } from "axios";
import * as contentDisposition from "content-disposition";
import { IDocumentReference } from "../../models/Documents/IDocumentReference";
import AuthService from "../AuthService";
import i18n from "../i18n";

class DocumentService {
  private getContentType(x: AxiosResponse<any>): string {
    let res: string = "application/octet-stream";
    if (!x) return res;
    if (!x.headers) return res;

    for (const e in x.headers) {
      if (e === "content-type") {
        res = x.headers[e];
        break;
      }
    }
    return res;
  }

  private getContentDisposition(x: AxiosResponse<any>): string {
    let res: string = "application/octet-stream";
    if (!x) return res;
    if (!x.headers) return res;
    if (x.status > 399) return res;
    for (const e in x.headers) {
      if (e === "content-disposition") {
        res = x.headers[e];
        break;
      }
    }
    return res;
  }

  public openBlob(x: AxiosResponse<any>): void {
    if (x.status > 399) throw new Error("File is missing");
    const type: string = this.getContentType(x);
    const headDispo = this.getContentDisposition(x);
    if (headDispo) {
      const disposition = contentDisposition.parse(headDispo);
      const fileName = disposition.parameters.filename;
      if (fileName) {
        const file = new File([x.data], fileName, { type: type });
        if ((navigator as any).msSaveBlob) {
          // For ie and Edge
          (navigator as any).msSaveBlob(file, fileName);
          return;
        } else {
          setTimeout(() => {
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(file);
            link.download = fileName;
            document.body.appendChild(link);
            link.dispatchEvent(
              new MouseEvent("click", {
                bubbles: true,
                cancelable: true,
                view: window,
              })
            );
            const url = link.href;
            link.remove();
            setTimeout(() => {
              window.URL.revokeObjectURL(url);
            }, 0);
          }, 500);
        }
        return;
      } else return;
    }
    const blob = new Blob([x.data], { type: type });
    const url = window.URL.createObjectURL(blob);
    window.open(url, "_blank");
    setTimeout(() => {
      window.URL.revokeObjectURL(url);
    }, 0);
  }

  public downloadFormsDocument(sectionId: number, key: string): void {
    axios
      .post(
        `/api/documents/sections/${sectionId}/${key}`,
        {},
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        this.openBlob(x);
      })
      .catch((_x) => {
        console.log(i18n.t("documents:download:error"));
      });
  }

  public getIconFromExtension(name: string): string {
    name = name.toLowerCase();
    return name.endsWith(".pdf")
      ? "PDF"
      : name.endsWith(".msg")
      ? "Mail"
      : name.endsWith(".xlsx")
      ? "ExcelDocument"
      : name.endsWith(".pptx")
      ? "PowerPointDocument"
      : name.endsWith(".docx")
      ? "WordDocument"
      : "TextDocument";
  }

  public searchContent(value: string): Promise<IDocumentReference[]> {
    return axios
      .post(
        `/api/documents/search`,
        {
          content: value,
          date: new Date(),
        },
        AuthService.getAuthHeaderWithLanguage()
      )
      .then((x) => x.data as any);
  }

  public init(): void {}
}

export default new DocumentService();
