import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertsPageProps } from "./AlertsPage.types";
import {
  getStyles,
  IAlertsPageStyleProps,
  IAlertsPageStyles,
} from "./AlertsPage.styles";
import { AlertsPageComponent } from "./AlertsPage.base";

/**
 * AlertsPage description
 */
export const AlertsPage = styled<
  IAlertsPageProps,
  IAlertsPageStyleProps,
  IAlertsPageStyles
>(AlertsPageComponent, getStyles);
