import {
  ISpinnerStyleProps,
  ISpinnerStyles,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IAlertDetailsSubComponentStyles {
  progressSpinner?: IStyleFunctionOrObject<ISpinnerStyleProps, ISpinnerStyles>;
}

export interface IAlertDetailsStyles
  extends IAmigoBaseStyles<IAlertDetailsSubComponentStyles> {
  progress?: IStyle;
}

export interface IAlertDetailsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAlertDetailsClassNames = (
  styles?: IStyleFunctionOrObject<IAlertDetailsStyleProps, IAlertDetailsStyles>,
  props?: IAlertDetailsStyleProps
): [
  IProcessedStyleSet<IAlertDetailsStyles>,
  IAlertDetailsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAlertDetailsStyleProps,
  IAlertDetailsStyles
> = (_props: IAlertDetailsStyleProps): IAlertDetailsStyles => {
  return {
    progress: {
      marginLeft: "20px",
    },
  };
};
