import { styled } from "@fluentui/react/lib/Utilities";
import { IContactsModificationListProps } from "./ContactsModificationList.types";
import {
  getStyles,
  IContactsModificationListStyleProps,
  IContactsModificationListStyles,
} from "./ContactsModificationList.styles";
import { ContactsModificationListComponent } from "./ContactsModificationList.base";

/**
 * ContactsModificationList description
 */
export const ContactsModificationList = styled<
  IContactsModificationListProps,
  IContactsModificationListStyleProps,
  IContactsModificationListStyles
>(ContactsModificationListComponent, getStyles);
