import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IDossierImmigrationDocumentCardStyleProps,
  IDossierImmigrationDocumentCardStyles,
} from "../DossierImmigrationDocumentCard/DossierImmigrationDocumentCard.styles";

export interface IDossierImmigrationDocumentsControlSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  gridCard?: IStyleFunctionOrObject<
    IDossierImmigrationDocumentCardStyleProps,
    IDossierImmigrationDocumentCardStyles
  >;
}

export interface IDossierImmigrationDocumentsControlStyles
  extends IAmigoBaseStyles<IDossierImmigrationDocumentsControlSubComponentStyles> {}

export interface IDossierImmigrationDocumentsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDossierImmigrationDocumentsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationDocumentsControlStyleProps,
    IDossierImmigrationDocumentsControlStyles
  >,
  props?: IDossierImmigrationDocumentsControlStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationDocumentsControlStyles>,
  IDossierImmigrationDocumentsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationDocumentsControlStyleProps,
  IDossierImmigrationDocumentsControlStyles
> = (
  _props: IDossierImmigrationDocumentsControlStyleProps
): IDossierImmigrationDocumentsControlStyles => {
  return {};
};
