import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationDocumentCardProps } from "./DossierImmigrationDocumentCard.types";
import {
  getStyles,
  IDossierImmigrationDocumentCardStyleProps,
  IDossierImmigrationDocumentCardStyles,
} from "./DossierImmigrationDocumentCard.styles";
import { DossierImmigrationDocumentCardComponent } from "./DossierImmigrationDocumentCard.base";

/**
 * DossierImmigrationDocumentCard description
 */
export const DossierImmigrationDocumentCard = styled<
  IDossierImmigrationDocumentCardProps,
  IDossierImmigrationDocumentCardStyleProps,
  IDossierImmigrationDocumentCardStyles
>(DossierImmigrationDocumentCardComponent, getStyles);
