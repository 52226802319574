import * as React from "react";
import { getRequestParticipantItemClassNames } from "./RequestParticipantItem.styles";
import {
  IRequestParticipantItemState,
  IRequestParticipantItemProps,
} from "./RequestParticipantItem.types";
import {
  CommandBarButton,
  Pivot,
  PivotItem,
  DefaultPalette,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { RequestParticipantForm } from "../RequestParticipantForm";
import { RequestParticipantEndParticipation } from "../RequestParticipantEndParticipation";
import { RequestParticipantNotes } from "../RequestParticipantNotes";
import { RequestParticipantPathway } from "../RequestParticipantPathway";

export class RequestParticipantItemComponent extends React.Component<
  IRequestParticipantItemProps,
  IRequestParticipantItemState
> {
  constructor(props: IRequestParticipantItemProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getRequestParticipantItemClassNames(styles!, {
        ...this.props,
        ...this.state,
      });

    return (
      <div className={classNames.root}>
        <div style={{ height: "20px" }}></div>
        <div className="ms-Grid" dir="ltr">
          <div className={classNames.msGridRow}>
            <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12">
              {!this.props.inline && (
                <CommandBarButton
                  style={{ background: "transparent" }}
                  iconProps={{ iconName: "NavigateBack" }}
                  ariaDescription={i18n.t("requests:participants:item:back")}
                  text={i18n.t("requests:participants:item:back")}
                  onClick={this.props.onDismiss}
                />
              )}
              <Pivot>
                <PivotItem headerText={i18n.t("participants:participant")}>
                  <div style={{ height: "20px" }}></div>
                  {this.props.inline ? (
                    <RequestParticipantForm
                      {...this.props}
                      styles={subComponentStyles?.participantForm}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: DefaultPalette.white,
                        margin: "0 0 10px 0",
                        padding: "20px",
                      }}
                      className="ms-depth-16"
                    >
                      <RequestParticipantForm
                        {...this.props}
                        styles={subComponentStyles?.participantForm}
                      />
                    </div>
                  )}
                </PivotItem>

                {this.props.participant.pathway !== undefined &&
                  this.props.participant.pathway != null && (
                    <PivotItem headerText={i18n.t("requests:pathway")}>
                      <div style={{ height: "20px" }}></div>
                      <RequestParticipantPathway
                        {...this.props}
                        participant={this.props.participant}
                      />
                    </PivotItem>
                  )}
                <PivotItem headerText={i18n.t("requests:endparticipation")}>
                  <div style={{ height: "20px" }}></div>
                  {this.props.inline ? (
                    <RequestParticipantEndParticipation
                      {...this.props}
                      styles={subComponentStyles?.endParticipantion}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: DefaultPalette.white,
                        margin: "0 0 10px 0",
                        padding: "20px",
                      }}
                      className="ms-depth-16"
                    >
                      <RequestParticipantEndParticipation
                        {...this.props}
                        styles={subComponentStyles?.endParticipantion}
                      />
                    </div>
                  )}
                </PivotItem>
                <PivotItem headerText={i18n.t("requests:notes")}>
                  <div style={{ height: "20px" }}></div>
                  {this.props.inline ? (
                    <RequestParticipantNotes
                      {...this.props}
                      notes={this.props.participant.notes}
                    />
                  ) : (
                    <div
                      style={{
                        backgroundColor: DefaultPalette.white,
                        margin: "0 0 10px 0",
                        padding: "20px",
                      }}
                      className="ms-depth-16"
                    >
                      <RequestParticipantNotes
                        {...this.props}
                        notes={this.props.participant.notes}
                      />
                    </div>
                  )}
                </PivotItem>
              </Pivot>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
