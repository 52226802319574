import * as React from "react";
import { withAITracking } from "@microsoft/applicationinsights-react-js";
import ai from "./TelemetryIntitializer";
import { RouteComponentProps, withRouter } from "react-router-dom";

export interface ITelemetryProviderProps extends RouteComponentProps<any> {
  enabled: boolean;
  instrumentationKey: string;
  after: () => void;
}

export interface ITelemetryProviderState {
  initialized: boolean;
}

/**
 * This Component provides telemetry with Azure App Insights
 *
 * NOTE: the package '@microsoft/applicationinsights-react-js' has a HOC withAITracking that requires this to be a Class Component rather than a Functional Component
 */
export class TelemetryProvider extends React.Component<
  ITelemetryProviderProps,
  ITelemetryProviderState
> {
  constructor(props: ITelemetryProviderProps) {
    super(props);
    this.state = {
      initialized: false,
    };
  }

  componentDidMount() {
    const { instrumentationKey, history } = this.props;
    const { initialized } = this.state;
    if (this.props.enabled) {
      if (!initialized && Boolean(instrumentationKey) && Boolean(history)) {
        const item = ai as any;
        if (item) {
          item.initialize(instrumentationKey, history);
        }
        this.setState({ initialized: true });
      }

      this.props.after();
    }
  }

  render() {
    const { children } = this.props;
    return <>{children}</>;
  }
}

export default withRouter(
  withAITracking((ai as any).reactPlugin, TelemetryProvider)
);
