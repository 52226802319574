import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface ILogoutPageSubComponentStyles {}

export interface ILogoutPageStyles
  extends IAmigoBaseStyles<ILogoutPageSubComponentStyles> {}

export interface ILogoutPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getLogoutPageClassNames = (
  styles?: IStyleFunctionOrObject<ILogoutPageStyleProps, ILogoutPageStyles>,
  props?: ILogoutPageStyleProps
): [
  IProcessedStyleSet<ILogoutPageStyles>,
  ILogoutPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ILogoutPageStyleProps,
  ILogoutPageStyles
> = (_props: ILogoutPageStyleProps): ILogoutPageStyles => {
  return {
    root: {},
  };
};
