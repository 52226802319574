import {
  DefaultPalette,
  ShimmerCircle,
  ShimmerLine,
  Stack,
  TooltipHost,
} from "@fluentui/react";
import {
  Card,
  ICardSectionStyles,
  ICardSectionTokens,
  ICardTokens,
} from "@fluentui/react-cards";
import * as React from "react";

export interface IShimmeredCardProps {
  columnWidth: number;
}

export interface IShimmeredCardState {}

export class ShimmeredCard extends React.Component<
  IShimmeredCardProps,
  IShimmeredCardState
> {
  constructor(props: IShimmeredCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const footerCardSectionStyles: ICardSectionStyles = {
      root: {
        alignSelf: "stretch",
        borderLeft: "1px solid #F3F2F1",
      },
    };
    const calloutProps = { gapSpace: 0 };

    const cardTokens: ICardTokens = {
      childrenGap: 10,
      childrenMargin: 4,
      minWidth: this.props.columnWidth,
    };

    const footerCardSectionTokens: ICardSectionTokens = {
      padding: "0px 0px 0px 10px",
    };
    return (
      <Card
        style={{
          backgroundColor: DefaultPalette.neutralLighter,
          padding: "10px",
        }}
        className="ListViewCard"
        horizontal
        tokens={cardTokens}
      >
        <Card.Section grow>
          <ShimmerLine
            width={"100%"}
            height={14}
            color={"#ffffff"}
            styles={{ root: { backgroundColor: "#ffffff" } }}
          />
          <div style={{ clear: "both" }}></div>
          {this.props.children}
          <div>
            <ShimmerLine
              width={"50%"}
              height={14}
              color={"#ffffff"}
              styles={{ root: { backgroundColor: "#ffffff" } }}
            />
          </div>
          <div>
            <ShimmerLine
              width={"75%"}
              height={14}
              color={"#ffffff"}
              styles={{ root: { backgroundColor: "#ffffff" } }}
            />
          </div>
          <div>
            <div style={{ float: "left" }}>
              <ShimmerCircle styles={{ root: { width: "18px" } }} />
            </div>
            <TooltipHost
              hostClassName={"ms-Card-bottom-container"}
              content={""}
              calloutProps={calloutProps}
            >
              <ShimmerLine
                width={"85%"}
                height={14}
                color={"#ffffff"}
                styles={{ root: { backgroundColor: "#ffffff" } }}
              />
            </TooltipHost>
          </div>
        </Card.Section>
        <Card.Section
          styles={footerCardSectionStyles}
          tokens={footerCardSectionTokens}
        >
          <ShimmerCircle styles={{ root: { width: "18px" } }} />
          <Stack.Item grow={1}>
            <span />
          </Stack.Item>
        </Card.Section>
      </Card>
    );
  }
}
