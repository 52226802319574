import {
  DefaultPalette,
  DetailsList,
  Icon,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import { UserCallout } from "src/controls/UserCallout";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import DossierImmigrationService from "src/services/DossierImmigration/DossierImmigrationService";
import { DossierImmigrationDocumentsControl } from "../DossierImmigrationDocumentsControl";
import { getImmigrationDetailPageClassNames } from "./DossierImmigrationDetailPage.styles";
import {
  IDossierImmigrationDetailPageProps,
  IDossierImmigrationDetailPageState,
} from "./DossierImmigrationDetailPage.types";
import { SelectionMode } from "@fluentui/react";

const themeIndex: number = 0;

export class DossierImmigrationDetailPageComponent extends React.Component<
  IDossierImmigrationDetailPageProps,
  IDossierImmigrationDetailPageState
> {
  constructor(props: IDossierImmigrationDetailPageProps) {
    super(props);
    this.state = {
      tab: "0",
      immigrationFileId: parseInt(this.props.route.match?.params.id),
    };

    DossierImmigrationService.getDossierImmigrationFile(
      objectId(this.props.enterprise),
      this.state.immigrationFileId
    ).then((x) => {
      this.setState({
        immigrationFile: x,
      });
    });
  }

  render(): JSX.Element {
    const { styles, mobile, tablet, route } = this.props;
    const [classNames, subComponentStyles] = getImmigrationDetailPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const enterpriseSize: PersonaSize = mobile
      ? PersonaSize.size56
      : PersonaSize.size100;

    const themeColor = [DefaultPalette.black];
    const themeImageSize = ["100% 110%"];
    const themeOpacity = ["0.6"];

    return (
      <Stack grow verticalFill className={classNames.root}>
        <div
          style={{ minHeight: "180px", position: "relative", width: "100%" }}
        >
          <div
            className="EnterpriseBackground"
            style={{
              opacity: themeOpacity[themeIndex],
              backgroundImage: "url('/img/visa.jpg')",
              backgroundSize: themeImageSize[themeIndex],
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className="WhitePersona"
            style={{
              position: "absolute",
              padding: "10px 10px",
              width: "95%",
              left: 0,
              bottom: 0,
            }}
          >
            <Persona
              initialsColor={"rgb(122, 117, 116)"}
              text={`${i18n.t("dossierimmigration:file")} ${
                this.state.immigrationFile?.name
              }`}
              styles={{
                primaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
                secondaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
              }}
              imageInitials={this.state.immigrationFile?.year
                .toString()
                .substr(2)}
              color={themeColor[themeIndex]}
              secondaryText={this.state.immigrationFile?.year.toString()}
              size={enterpriseSize}
            />
          </div>
          <div
            data-note="top-right-section"
            style={{ position: "absolute", top: 0, right: 0, padding: "4px" }}
          ></div>
          <div></div>
        </div>
        <Stack
          grow
          verticalFill
          style={{
            width: "100%",
            minHeight: "500px",
            backgroundColor: DefaultPalette.neutralLighter,
          }}
        >
          <Pivot
            styles={subComponentStyles?.pivot}
            selectedKey={this.state.tab.toString()}
            onLinkClick={(item?: PivotItem) => {
              const newTab =
                item?.props.itemKey === undefined ? "0" : item.props.itemKey;
              if (newTab === "99") route.history.push("/immigration");
              else {
                this.setState({ tab: newTab });
              }
            }}
          >
            <PivotItem
              headerText={i18n.t("dossierimmigration:file")}
              itemKey={"0"}
              key={"0"}
            >
              <div className="ms-Grid" style={{ paddingTop: "10px" }} dir="ltr">
                <div
                  className="ms-Grid-row"
                  style={{
                    margin: tablet ? "15px 0px 5px 0px" : "15px 5px 5px 5px",
                  }}
                >
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
                    <div
                      style={{
                        position: "relative",
                        backgroundColor: DefaultPalette.white,
                        margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                      }}
                      className="ms-depth-16"
                    >
                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem>
                          <TooltipHost
                            content={i18n.t("dossierimmigration:info")}
                          >
                            <Icon
                              style={{ fontSize: "22px", padding: "20px 10px" }}
                              iconName="Certificate"
                            />
                          </TooltipHost>
                        </StackItem>
                        <StackItem grow>
                          {" "}
                          <h2>{i18n.t("dossierimmigration:info")}</h2>{" "}
                        </StackItem>
                      </Stack>
                      <div>
                        <div style={{ padding: tablet ? "10px" : "30px" }}>
                          <Stack grow tokens={{ childrenGap: 10 }}>
                            <LabelField
                              {...this.props}
                              styles={undefined}
                              iconProps={{ iconName: "EntitlementPolicy" }}
                              label={i18n.t("dossierimmigration:version:title")}
                              content={this.state.immigrationFile?.title ?? ""}
                            />
                            <Stack horizontal tokens={{ childrenGap: 10 }}>
                              <StackItem grow>
                                <LabelField
                                  {...this.props}
                                  styles={undefined}
                                  iconProps={{ iconName: "DateTime" }}
                                  label={i18n.t("dossierimmigration:year")}
                                  content={(
                                    this.state.immigrationFile?.year ??
                                    new Date().getFullYear()
                                  ).toString()}
                                />

                                <LabelField
                                  {...this.props}
                                  styles={undefined}
                                  iconProps={{ iconName: "World" }}
                                  label={i18n.t("dossierimmigration:country")}
                                  content={
                                    <Stack
                                      horizontal
                                      tokens={{ childrenGap: 10 }}
                                    >
                                      <Text>
                                        {this.state.immigrationFile?.country}
                                      </Text>
                                      <CountryIcon
                                        {...this.props}
                                        styles={undefined}
                                        country={
                                          this.state.immigrationFile?.country ??
                                          ""
                                        }
                                      />
                                    </Stack>
                                  }
                                />

                                <LabelField
                                  {...this.props}
                                  styles={undefined}
                                  iconProps={{ iconName: "Telemarketer" }}
                                  label={i18n.t("dossierimmigration:agent")}
                                  content={
                                    this.state.immigrationFile ? (
                                      <UserCallout
                                        {...this.props}
                                        styles={undefined}
                                        user={this.state.immigrationFile.agent}
                                      />
                                    ) : (
                                      ""
                                    )
                                  }
                                />
                              </StackItem>
                              <StackItem grow>
                                <LabelField
                                  {...this.props}
                                  styles={undefined}
                                  iconProps={{ iconName: "EventDate" }}
                                  label={i18n.t(
                                    "dossierimmigration:transmission"
                                  )}
                                  content={
                                    this.state.immigrationFile
                                      ?.transmissionDate ?? ""
                                  }
                                />
                                <LabelField
                                  {...this.props}
                                  styles={undefined}
                                  iconProps={{
                                    iconName: "WorkforceManagement",
                                  }}
                                  label={i18n.t(
                                    "dossierimmigration:participantCount"
                                  )}
                                  content={(
                                    this.state.immigrationFile
                                      ?.participantCount ?? 0
                                  ).toString()}
                                />
                              </StackItem>
                            </Stack>
                          </Stack>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </PivotItem>
            {this.state.immigrationFile &&
              this.state.immigrationFile.details &&
              this.state.immigrationFile.details.length > 0 && (
                <PivotItem
                  headerText={i18n.t("dossierimmigration:details")}
                  itemKey={"2"}
                  key={"2"}
                >
                  <div className="ms-Grid" dir="ltr">
                    <div
                      className="ms-Grid-row"
                      style={{
                        margin: tablet
                          ? "15px 0px 5px 0px"
                          : "15px 5px 5px 5px",
                      }}
                    >
                      <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl8 ms-xxl8">
                        <div
                          style={{
                            position: "relative",
                            backgroundColor: DefaultPalette.white,
                            margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                          }}
                          className="ms-depth-16"
                        >
                          <IconHeader
                            {...this.props}
                            styles={subComponentStyles?.iconHeader}
                            iconName={"Certificate"}
                            title={i18n.t("dossierimmigration:details")}
                          />
                          <DetailsList
                            selectionMode={SelectionMode.none}
                            items={this.state.immigrationFile.details}
                            columns={[
                              {
                                key: "name",
                                fieldName: "name",
                                name: i18n.t(
                                  "dossierimmigration:details:title"
                                ),
                                minWidth: 200,
                              },
                              {
                                key: "createdOn",
                                fieldName: "createdOn",
                                name: i18n.t(
                                  "dossierimmigration:details:createdon"
                                ),
                                minWidth: 120,
                              },
                            ]}
                          />
                          <div style={{ height: "20px" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </PivotItem>
              )}
            <PivotItem
              headerText={i18n.t("dossierimmigration:tab:documents")}
              itemKey={"1"}
              key={"1"}
            >
              <div className="ms-Grid" dir="ltr">
                <div
                  className="ms-Grid-row"
                  style={{
                    margin: mobile ? "15px 0px 5px 0px" : "15px 5px 5px 5px",
                  }}
                >
                  <div className="ms-Grid-col ms-sm12">
                    <div
                      style={{
                        backgroundColor: DefaultPalette.white,
                        margin: mobile ? "0 0 10px 0" : "0 10px 10px 10px",
                      }}
                      className="ms-depth-16"
                    >
                      <div>
                        {" "}
                        <div
                          style={{
                            float: "left",
                            paddingLeft: "5px",
                            paddingRight: "10px",
                          }}
                        >
                          {" "}
                          <h2>
                            {" "}
                            <Icon
                              style={{ fontSize: "22px" }}
                              iconName="Attach"
                            />{" "}
                          </h2>{" "}
                        </div>
                        <div style={{ float: "left" }}>
                          {" "}
                          <h2>Documents</h2>{" "}
                        </div>
                        <div className="clear-both"></div>
                      </div>
                      <DossierImmigrationDocumentsControl
                        {...this.props}
                        styles={undefined}
                        dossierimmigrationId={this.state.immigrationFileId}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </PivotItem>
            <PivotItem
              itemIcon={"NavigateBack"}
              headerText={i18n.t("dossierimmigration:back")}
              itemKey={"99"}
              key={"99"}
            />
          </Pivot>
        </Stack>
      </Stack>
    );
  }
}
