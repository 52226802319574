import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDepartureAssistedControlSubComponentStyles {}

export interface IDepartureAssistedControlStyles
  extends IAmigoBaseStyles<IDepartureAssistedControlSubComponentStyles> {
  nameText?: IStyle;
}

export interface IDepartureAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDepartureAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IDepartureAssistedControlStyleProps,
    IDepartureAssistedControlStyles
  >,
  props?: IDepartureAssistedControlStyleProps
): [
  IProcessedStyleSet<IDepartureAssistedControlStyles>,
  IDepartureAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDepartureAssistedControlStyleProps,
  IDepartureAssistedControlStyles
> = (
  _props: IDepartureAssistedControlStyleProps
): IDepartureAssistedControlStyles => {
  return {};
};
