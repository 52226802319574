import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IFlightBookingParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  newAlertIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IFlightBookingParticipantCardStyles
  extends IAmigoBaseStyles<IFlightBookingParticipantCardSubComponentStyles> {}

export interface IFlightBookingParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingParticipantCardStyleProps,
    IFlightBookingParticipantCardStyles
  >,
  props?: IFlightBookingParticipantCardStyleProps
): [
  IProcessedStyleSet<IFlightBookingParticipantCardStyles>,
  IFlightBookingParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingParticipantCardStyleProps,
  IFlightBookingParticipantCardStyles
> = (
  _props: IFlightBookingParticipantCardStyleProps
): IFlightBookingParticipantCardStyles => {
  return {
    root: ["amigo-FlightBookingParticipantCard", {}],
    subComponentStyles: {
      gridViewCard: {
        root: {
          width: "43%",
          maxWidth: "640px",
        },
      },
    },
  };
};
