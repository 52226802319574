import { Text, ActionButton, PrimaryButton, Stack } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import { MediaQueries } from "src/components/MediaQueries";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import { TransportFlightBookingService } from "src/services/Transports/TransportFlightBookingService";
import { getFlightConfirmationPageClassNames } from "./FlightConfirmationPage.styles";
import {
  IFlightConfirmationPageProps,
  IFlightConfirmationPageState,
} from "./FlightConfirmationPage.types";

export class FlightConfirmationPageComponent extends React.Component<
  IFlightConfirmationPageProps,
  IFlightConfirmationPageState
> {
  constructor(props: IFlightConfirmationPageProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, route, enterprise } = this.props;
    const [classNames] = getFlightConfirmationPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack className={classNames.root} tokens={{ childrenGap: 10 }}>
        <h1>{i18n.t("transports:flights:confirmation:header")}</h1>
        <h3>{i18n.t("transports:flights:confirmation:text")}</h3>
        <LabelField
          {...this.props}
          styles={undefined}
          label={i18n.t("transports:flights:pnr")}
          content={
            this.props.results?.reservationId ??
            i18n.t("transports:flights:pnr:internal")
          }
        />
        {/*
          <LabelField
          {...this.props}
          styles={undefined}
          label={"Numéro d'autorisation"}
          content={`32584745`}
        /> */}
        <div></div>
        <Card
          styles={{
            root: {
              backgroundColor: "#fff",
            },
          }}
        >
          <Card.Section>
            <Stack
              styles={{
                root: {
                  margin: "20px",
                  [MediaQueries.mobile]: {
                    margin: "5px",
                  },
                },
              }}
            >
              <Text
                styles={{
                  root: {
                    marginLeft: "10px",
                  },
                }}
              >
                {i18n.t("transports:flights:download:pdf")}
              </Text>
              <ActionButton
                text={i18n.t("transports:flights:download:pdf:button")}
                iconProps={{
                  iconName: "PDF",
                }}
                onClick={() =>
                  TransportFlightBookingService.getReservationAsync(
                    objectId(enterprise),
                    this.props.results?.reservationId ?? 0,
                    "application/pdf"
                  )
                }
              />
            </Stack>
          </Card.Section>
        </Card>
        <Card
          styles={{
            root: {
              backgroundColor: "#fff",
            },
          }}
        >
          <Card.Section>
            <Stack
              styles={{
                root: {
                  margin: "20px",
                  [MediaQueries.mobile]: {
                    margin: "5px",
                  },
                },
              }}
            >
              <Text
                styles={{
                  root: {
                    marginLeft: "10px",
                  },
                }}
              >
                {i18n.t("transports:flights:download:excel")}
              </Text>
              <ActionButton
                text={i18n.t("transports:flights:download:excel:button")}
                iconProps={{
                  iconName: "ExcelDocument",
                }}
                onClick={() =>
                  TransportFlightBookingService.getReservationAsync(
                    objectId(enterprise),
                    this.props.results?.reservationId ?? 0,
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  )
                }
              />
            </Stack>
          </Card.Section>
        </Card>

        <div></div>
        <div>
          <PrimaryButton
            styles={{
              root: {
                margin: "8px",
              },
            }}
            text={i18n.t("transports:flights:backhome")}
            onClick={() => {
              route.history.push("/transports/billetterie");
            }}
          />
        </div>
      </Stack>
    );
  }
}
