import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentDetailListProps } from "./PaymentDetailList.types";
import {
  getStyles,
  IPaymentDetailListStyleProps,
  IPaymentDetailListStyles,
} from "./PaymentDetailList.styles";
import { PaymentDetailListComponent } from "./PaymentDetailList.base";

/**
 * PaymentDetailList
 */
export const PaymentDetailList = styled<
  IPaymentDetailListProps,
  IPaymentDetailListStyleProps,
  IPaymentDetailListStyles
>(PaymentDetailListComponent, getStyles);
