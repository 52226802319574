import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
  ICommandBarStyleProps,
  ICommandBarStyles,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface INewPaymentCommandBarSubComponentStyles {
  commandBar?: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles>;
}

export interface INewPaymentCommandBarStyles
  extends IAmigoBaseStyles<INewPaymentCommandBarSubComponentStyles> {}

export interface INewPaymentCommandBarStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentCommandBarClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentCommandBarStyleProps,
    INewPaymentCommandBarStyles
  >,
  props?: INewPaymentCommandBarStyleProps
): [
  IProcessedStyleSet<INewPaymentCommandBarStyles>,
  INewPaymentCommandBarSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentCommandBarStyleProps,
  INewPaymentCommandBarStyles
> = (props: INewPaymentCommandBarStyleProps): INewPaymentCommandBarStyles => {
  return {
    root: [
      "amigo-NewPaymentCommandBar",
      {
        padding: "0",
        backgroundColor: props.theme.palette.neutralLighter,
      },
    ],
    subComponentStyles: {
      commandBar: {
        root: {
          backgroundColor: props.theme.palette.neutralLighter,
          padding: "0",
        },
      },
    },
  };
};
