import { styled } from "@fluentui/react/lib/Utilities";
import { IGridViewCardProps } from "./GridViewCard.types";
import {
  getStyles,
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "./GridViewCard.styles";
import { GridViewCardComponent } from "./GridViewCard.base";

/**
 * GridViewCard description
 */
export const GridViewCard = styled<
  IGridViewCardProps,
  IGridViewCardStyleProps,
  IGridViewCardStyles
>(GridViewCardComponent, getStyles);
