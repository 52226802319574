import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
export interface IRequestParticipantPathwaySubComponentStyles {}

export interface IRequestParticipantPathwayStyles
  extends IAmigoBaseStyles<IRequestParticipantPathwaySubComponentStyles> {}

export interface IRequestParticipantPathwayStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantPathwayClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantPathwayStyleProps,
    IRequestParticipantPathwayStyles
  >,
  props?: IRequestParticipantPathwayStyleProps
): [
  IProcessedStyleSet<IRequestParticipantPathwayStyles>,
  IRequestParticipantPathwaySubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantPathwayStyleProps,
  IRequestParticipantPathwayStyles
> = (
  _props: IRequestParticipantPathwayStyleProps
): IRequestParticipantPathwayStyles => {
  return {
    root: ["amigo-RequestParticipantPathway", {}],
  };
};
