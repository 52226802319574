import { Stack } from "@fluentui/react";
import {
  IconButton,
  Link,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import AuthService from "src/services/AuthService";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import i18n from "src/services/i18n";
import { getEnterpriseContactInfoClassNames } from "./EnterpriseContactInfo.styles";
import {
  IEnterpriseContactInfoProps,
  IEnterpriseContactInfoState,
} from "./EnterpriseContactInfo.types";

export class EnterpriseContactInfoComponent extends React.Component<
  IEnterpriseContactInfoProps,
  IEnterpriseContactInfoState
> {
  constructor(props: IEnterpriseContactInfoProps) {
    super(props);
    this.state = {
      editneq: false,
      oldneq: props.enterprise.neq,
    };
  }

  renderNeqSaveBouton() {
    return (
      <IconButton
        style={{
          marginLeft: "5px",
          padding: "0",
          height: "16px",
          width: "16px",
        }}
        iconProps={{ iconName: "Save" }}
        styles={{
          root: {
            margin: "2px 5px",
            padding: "0",
            height: "16px",
            width: "16px",
          },
          rootHovered: {
            backgroundColor: "transparent",
          },
          flexContainer: {
            margin: "0",
            padding: "0",
            height: "16px",
            width: "16px",
          },
        }}
        onClick={() => {
          if (this.state.newneq && this.state.newneq.length > 8) {
            EnterpriseService.updateEnterpriseNumber(
              this.props.enterprise.id,
              this.state.newneq
            ).then((_x) => {
              this.setState({ editneq: false });
            });
          } else {
            this.setState({ editneq: false });
          }
        }}
      />
    );
  }

  renderNeqButtons() {
    return (
      <>
        {this.state.editneq && (
          <TooltipHost content={i18n.t("enterprises:neq:saveButton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Save" }}
              styles={{
                root: {
                  margin: "2px 5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                if (this.state.newneq && this.state.newneq.length > 8) {
                  EnterpriseService.updateEnterpriseNumber(
                    this.props.enterprise.id,
                    this.state.newneq
                  )
                    .then((_x) => {
                      this.setState({ editneq: false });
                    })
                    .catch((_x) => {
                      this.setState({ editneq: false });
                    });
                } else {
                  this.setState({ editneq: false });
                }
              }}
            />
          </TooltipHost>
        )}
        {this.state.editneq && (
          <TooltipHost content={i18n.t("enterprises:neq:cancelButton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Cancel" }}
              styles={{
                root: {
                  margin: "2px 5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                this.setState({ newneq: this.state.oldneq });
                this.setState({ editneq: false });
              }}
            />
          </TooltipHost>
        )}
        {!this.state.editneq && (
          <TooltipHost content={i18n.t("enterprises:neq:editBouton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Edit" }}
              styles={{
                root: {
                  marginLeft: "5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                this.setState({ editneq: true });
              }}
            />
          </TooltipHost>
        )}
      </>
    );
  }

  private _neqChange = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const maxLenghtNEQ = 25;
    if (!newValue || newValue.length <= maxLenghtNEQ) {
      this.setState({ newneq: newValue });
    } else {
      this.setState({ editneq: this.state.editneq });
    }
  };

  render(): JSX.Element {
    const { styles, mobile, tablet, enterprise } = this.props;
    const [classNames, subComponentStyles] = getEnterpriseContactInfoClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const email: string | undefined =
      this.props.enterprise.email === null
        ? AuthService.getUserInfo()?.email
        : enterprise.email;
    return (
      <div className={classNames.root}>
        <IconHeader
          {...this.props}
          styles={subComponentStyles?.iconHeader}
          iconName="ChatInviteFriend"
          title={i18n.t("enterprise:address:enterprise")}
        />
        <div>
          <div>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("enterprise:address:name")}
              content={enterprise.name}
            />
            <div style={{ height: "20px" }}></div>
          </div>
          <Stack
            horizontal
            tokens={{ childrenGap: mobile ? 10 : tablet ? 40 : 70 }}
          >
            <Stack>
              <LabelField
                {...this.props}
                iconProps={{ iconName: "Phone" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:phone")}
                content={
                  <Link
                    href={i18n.PhoneLink(this.props.enterprise.phone)}
                    className="ms-Link"
                  >
                    {enterprise.phone}
                  </Link>
                }
              />
              <LabelField
                {...this.props}
                iconProps={{ iconName: "Fax" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:fax")}
                content={enterprise.fax}
              />
              {email && (
                <LabelField
                  {...this.props}
                  iconProps={{ iconName: "Mail" }}
                  styles={subComponentStyles?.label}
                  label={i18n.t("enterprise:info:email")}
                  content={
                    <Link href={`mailto:${email}`} className="ms-Link">
                      {email}
                    </Link>
                  }
                />
              )}
              <LabelField
                {...this.props}
                iconProps={{ iconName: "WebAppBuilderFragment" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:sector")}
                content={enterprise.sector}
              />
            </Stack>
            <Stack>
              <LabelField
                {...this.props}
                iconProps={{ iconName: "WebAppBuilderFragment" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:memberid")}
                content={enterprise.memberid}
              />
              <LabelField
                {...this.props}
                iconProps={{ iconName: "Certificate" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:neq")}
                content={
                  <>
                    {this.state.editneq && (
                      <Stack horizontal>
                        <TextField
                          styles={{ fieldGroup: { height: "24px" } }}
                          style={{ fontSize: "13px" }}
                          onChange={this._neqChange}
                          value={this.state.newneq}
                          defaultValue={
                            this.state.newneq ?? this.props.enterprise.neq
                          }
                        />
                        {this.renderNeqButtons()}
                      </Stack>
                    )}
                    {!this.state.editneq && (
                      <Stack horizontal>
                        <Text>
                          {this.state.newneq ?? this.props.enterprise.neq}
                        </Text>
                        {this.renderNeqButtons()}
                      </Stack>
                    )}
                  </>
                }
              />
              <LabelField
                {...this.props}
                iconProps={{ iconName: "Certificate" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:tps")}
                content={enterprise.tps}
              />
              <LabelField
                {...this.props}
                iconProps={{ iconName: "WebAppBuilderSlot" }}
                styles={subComponentStyles?.label}
                label={i18n.t("enterprise:info:subsector")}
                content={enterprise.subSector}
              />
            </Stack>
          </Stack>
        </div>
      </div>
    );
  }
}
