import {
  Icon,
  IconButton,
  Persona,
  PersonaSize,
  Stack,
  StackItem,
  Text,
  TooltipHost,
  MessageBar,
  MessageBarType,
  Dialog,
  DialogFooter,
  PrimaryButton,
} from "@fluentui/react";
import * as React from "react";
import DateText from "src/components/DateText";
import {
  IAttachmentSummary,
  IMessageSummary,
} from "src/models/Messages/IMessageSummary";
import MessageService from "src/services/Messages/MessagesService";
import { AssistedMessageControl } from "../AssistedMessageControl";
import { MessageReplyCommandBar } from "../Controls/MessageReplyCommandBar";
import {
  getMessageDisplayControlClassNames,
  IMessageDisplayControlSubComponentStyles,
} from "./MessageDisplayControl.styles";
import {
  IMessageDisplayControlProps,
  IMessageDisplayControlState,
} from "./MessageDisplayControl.types";
import i18n from "src/services/i18n";

export class MessageDisplayControlComponent extends React.Component<
  IMessageDisplayControlProps,
  IMessageDisplayControlState
> {
  constructor(props: IMessageDisplayControlProps) {
    super(props);
    this.state = {
      downloadError: false,
    };
  }

  private onReply = (): void => {
    this.props.onReply(this.props.message);
  };

  private onReplyAll = (): void => {
    this.props.onReplyAll(this.props.message);
  };

  private onForward = (): void => {
    this.props.onForward(this.props.message);
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames, subComponentStyles] = getMessageDisplayControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const tos = this.props.message.to.concat(this.props.message.designations);

    const defaultName =
      this.props.message.type?.code === undefined ||
      this.props.message.type.code.toUpperCase() === "INSPECTION" ||
      this.props.message.type.code.toUpperCase() === "STANDARD" ||
      this.props.message.type.code.toUpperCase() === "GENERAL"
        ? undefined
        : i18n.t("messages:designations:dispatcher");

    const toLen = tos.length;
    return (
      <div className={classNames.root}>
        <Dialog
          title={i18n.t("error:500:title")}
          hidden={!this.state.downloadError}
          onDismiss={() => this.setState({ downloadError: false })}
          modalProps={{
            isDarkOverlay: true,
            isBlocking: true,
          }}
        >
          <MessageBar messageBarType={MessageBarType.error}>
            {i18n.t("error:download:document")}
          </MessageBar>
          <DialogFooter>
            <PrimaryButton
              data-automation-id="nvx:error:ok"
              onClick={() => this.setState({ downloadError: false })}
              text={"Ok"}
            />
          </DialogFooter>
        </Dialog>
        <div style={{ width: "100%", marginBottom: "2.2rem" }}>
          <div style={{ float: "left" }}>
            {this.props.folder === "Inbox" ? (
              <Persona
                initialsColor={"rgb(122, 117, 116)"}
                secondaryText={
                  defaultName === undefined
                    ? this.props.message.from.email
                    : undefined
                }
                styles={{
                  primaryText:
                    defaultName === undefined
                      ? undefined
                      : {
                          fontSize: "16px",
                          fontWeight: 600,
                        },
                  secondaryText: {
                    maxWidth: "365px",
                  },
                }}
                text={defaultName ?? this.props.message.from.name}
                size={PersonaSize.size48}
              />
            ) : (
              <Persona
                styles={{
                  primaryText:
                    defaultName === undefined
                      ? undefined
                      : {
                          fontSize: "16px",
                          fontWeight: 600,
                        },
                  secondaryText: {
                    maxWidth: "365px",
                  },
                }}
                secondaryText={
                  defaultName === undefined
                    ? tos
                        .filter((_x, index) => index > 0)
                        .map((x) => {
                          return toLen < 3 ? `${x.name} (${x.email})` : x.name;
                        })
                        .join(", ")
                    : undefined
                }
                text={
                  defaultName ??
                  (tos.length === 0 ? "" : `${tos[0].name} (${tos[0].email})`)
                }
                initialsColor={"rgb(122, 117, 116)"}
                size={PersonaSize.size48}
              />
            )}
          </div>
          <div style={{ float: "right" }}>
            <Stack horizontal tokens={{ childrenGap: 8 }}>
              {!mobile && (
                <MessageReplyCommandBar
                  {...this.props}
                  styles={undefined}
                  onReply={this.onReply}
                  onReplyAll={this.onReplyAll}
                  onForward={this.onForward}
                />
              )}
              <DateText
                variant="small"
                tooltipFormat={"messages:sent:tooltip"}
                tooltipStyle={{
                  marginTop: "7px",
                  marginLeft: "0px!important",
                }}
                className="message-datetime"
                date={this.props.message.timestamp}
              />
              <IconButton
                style={mobile ? undefined : { marginTop: "7px" }}
                iconProps={{ iconName: "Print" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.props.onPrintMessage(
                    this.props.message,
                    (_item: IMessageSummary | undefined) => {}
                  );
                }}
              />
              <IconButton
                style={mobile ? undefined : { marginTop: "7px" }}
                iconProps={{ iconName: "Delete" }}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  this.props.onDeleteMessage(
                    this.props.message,
                    (_item: IMessageSummary | undefined) => {}
                  );
                }}
              />
            </Stack>
          </div>
          <div className="clear-both"></div>
          {mobile && (
            <MessageReplyCommandBar
              {...this.props}
              styles={undefined}
              onReply={this.onReply}
              onReplyAll={this.onReplyAll}
              onForward={this.onForward}
            />
          )}
          <div style={{ height: "5px" }}></div>
          {this.props.message.attachments.length > 0 && (
            <div>
              {this.props.message.attachments.map((x) => {
                return this.onRenderAttachment(
                  this.props.message,
                  x,
                  subComponentStyles
                );
              })}
              <div className="clear-both"></div>
              <div style={{ height: "5px" }}></div>
            </div>
          )}
        </div>
        <AssistedMessageControl {...this.props} styles={undefined} />
        <div
          dangerouslySetInnerHTML={{ __html: this.props.message.message }}
        ></div>
      </div>
    );
  }

  private onRenderAttachment = (
    message: IMessageSummary,
    item: IAttachmentSummary,
    subComponentStyles?: IMessageDisplayControlSubComponentStyles
  ): JSX.Element => {
    return (
      <div
        key={item.id}
        style={{
          float: "left",
          padding: "4px",
          border: "1px solid " + this.props.theme.palette.neutralDark,
          maxWidth: "255px",
        }}
      >
        <Stack grow={false} horizontal tokens={{ childrenGap: 10 }}>
          <TooltipHost content={item.name}>
            <Icon iconName="PDF" style={{ fontSize: "36px" }} />
          </TooltipHost>
          <StackItem>
            <Stack tokens={{ childrenGap: 4 }}>
              <TooltipHost content={item.name}>
                <Text block nowrap styles={{ root: { maxWidth: "160px" } }}>
                  {item.name}
                </Text>
              </TooltipHost>
              <TooltipHost content={item.name}>
                <Text variant={"small"}>{item.size}</Text>
              </TooltipHost>
            </Stack>
          </StackItem>
          <StackItem align={"baseline"}>
            <Stack>
              <div style={{ height: "5px" }}></div>
              <TooltipHost content={item.name}>
                <IconButton
                  onClick={() => {
                    MessageService.downloadAttachment(
                      message.id,
                      item.id
                    ).catch((_x) => {
                      this.setState({ downloadError: true });
                    });
                  }}
                  iconProps={{ iconName: "DownloadDocument" }}
                  styles={subComponentStyles?.iconStyles}
                />
              </TooltipHost>
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  };
}
