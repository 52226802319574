import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IAppRouterStyleProps,
  IAppRouterStyles,
} from "src/AppRouter/AppRouter.styles";
import { IAmigoBaseStyleProps, IAmigoBaseStyles, IAmigoTokens } from "src/Base";
import { getComponentClassNames } from "src/components/Base";

export interface IAppSubComponentStyles {
  stack?: any;
  router?: IStyleFunctionOrObject<IAppRouterStyleProps, IAppRouterStyles>;
}

export interface IAppStyles extends IAmigoBaseStyles<IAppSubComponentStyles> {
  root?: IStyle;
}

export interface IAppStyleProps extends IAmigoBaseStyleProps<IAmigoTokens> {
  defaultColor?: string;
}

export const getAppClassNames = (
  styles?: IStyleFunctionOrObject<IAppStyleProps, IAppStyles>,
  props?: IAppStyleProps
): [IProcessedStyleSet<IAppStyles>, IAppSubComponentStyles | undefined] => {
  return getComponentClassNames(styles, props);
};

export const getStyles = (props: IAppStyleProps): IAppStyles => {
  return {
    root: [
      "ms-App",
      {
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        width: "100%",
        backgroundColor: `${props.theme.palette.neutralLighter}!important`,
      },
    ],
  };
};
