import { styled } from "@fluentui/react/lib/Utilities";
import { IAuthorizationWarningPanelProps } from "./AuthorizationWarningPanel.types";
import {
  getStyles,
  IAuthorizationWarningPanelStyleProps,
  IAuthorizationWarningPanelStyles,
} from "./AuthorizationWarningPanel.styles";
import { AuthorizationWarningPanelComponent } from "./AuthorizationWarningPanel.base";

/**
 * AuthorizationWarningPanel
 */
export const AuthorizationWarningPanel = styled<
  IAuthorizationWarningPanelProps,
  IAuthorizationWarningPanelStyleProps,
  IAuthorizationWarningPanelStyles
>(AuthorizationWarningPanelComponent, getStyles);
