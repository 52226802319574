import { styled } from "@fluentui/react/lib/Utilities";
import { ILogoutPageProps } from "./LogoutPage.types";
import {
  getStyles,
  ILogoutPageStyleProps,
  ILogoutPageStyles,
} from "./LogoutPage.styles";
import { LogoutPageComponent } from "./LogoutPage.base";

/**
 * LogoutPage description
 */
export const LogoutPage = styled<
  ILogoutPageProps,
  ILogoutPageStyleProps,
  ILogoutPageStyles
>(LogoutPageComponent, getStyles);
