import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationCardProps } from "./DossierImmigrationCard.types";
import {
  getStyles,
  IDossierImmigrationCardStyleProps,
  IDossierImmigrationCardStyles,
} from "./DossierImmigrationCard.styles";
import { DossierImmigrationCardComponent } from "./DossierImmigrationCard.base";

/**
 * DossierImmigrationCard description
 */
export const DossierImmigrationCard = styled<
  IDossierImmigrationCardProps,
  IDossierImmigrationCardStyleProps,
  IDossierImmigrationCardStyles
>(DossierImmigrationCardComponent, getStyles);
