import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IParticipantCardStyleProps,
  IParticipantCardStyles,
} from "../ParticipantCard";

export interface IParticipantsControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<
    IParticipantCardStyleProps,
    IParticipantCardStyles
  >;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IParticipantsControlStyles
  extends IAmigoBaseStyles<IParticipantsControlSubComponentStyles> {}

export interface IParticipantsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantsControlStyleProps,
    IParticipantsControlStyles
  >,
  props?: IParticipantsControlStyleProps
): [
  IProcessedStyleSet<IParticipantsControlStyles>,
  IParticipantsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantsControlStyleProps,
  IParticipantsControlStyles
> = (_props: IParticipantsControlStyleProps): IParticipantsControlStyles => {
  return {};
};
