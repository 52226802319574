import { Icon, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getPaymentElementCardClassNames } from "./PaymentElementCard.styles";
import {
  IPaymentElementCardProps,
  IPaymentElementCardState,
} from "./PaymentElementCard.types";
import { Stack, StackItem } from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import { CreditCardIcon } from "src/components/CreditCardIcon";

export class PaymentElementCardComponent extends React.Component<
  IPaymentElementCardProps,
  IPaymentElementCardState
> {
  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getPaymentElementCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {`${i18n.t("billing:payment:element")} ${item.name}`}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {item.paymentDate}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={item.total}
        footerIconName={footerIconName ?? "Money"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <TooltipHost content={item.name}>
            <Icon iconName="PaymentCard" className="ms-Card-Image" />
          </TooltipHost>
        }
      >
        <Stack
          className={classNames.root}
          styles={{
            root: {
              marginTop: "-10px!important",
            },
          }}
        >
          <LabelField
            {...this.props}
            styles={undefined}
            label={i18n.t("billing:payments:bills")}
            content={item.bills}
          />
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("billing:payments:paymenttype")}
                content={
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <CreditCardIcon marginTop={3} card={item.paymentType} />
                    <Text>{item.paymentType}</Text>
                  </Stack>
                }
              />
            </StackItem>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("billing:payments:confirmation:short")}
                content={item.confirmation}
              />
            </StackItem>
          </Stack>
        </Stack>
      </GridViewCard>
    );
  }
}
