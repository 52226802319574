import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyles,
  ILabelFieldStyleProps,
} from "src/components/LabelField";

export interface INewPaymentClientInfoSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface INewPaymentClientInfoStyles
  extends IAmigoBaseStyles<INewPaymentClientInfoSubComponentStyles> {}

export interface INewPaymentClientInfoStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentClientInfoClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentClientInfoStyleProps,
    INewPaymentClientInfoStyles
  >,
  props?: INewPaymentClientInfoStyleProps
): [
  IProcessedStyleSet<INewPaymentClientInfoStyles>,
  INewPaymentClientInfoSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentClientInfoStyleProps,
  INewPaymentClientInfoStyles
> = (_props: INewPaymentClientInfoStyleProps): INewPaymentClientInfoStyles => {
  return {};
};
