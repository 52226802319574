import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentMethodDeleteMessageProps } from "./PaymentMethodDeleteMessage.types";
import {
  getStyles,
  IPaymentMethodDeleteMessageStyleProps,
  IPaymentMethodDeleteMessageStyles,
} from "./PaymentMethodDeleteMessage.styles";
import { PaymentMethodDeleteMessageComponent } from "./PaymentMethodDeleteMessage.base";

/**
 * PaymentMethodDeleteMessage description
 */
export const PaymentMethodDeleteMessage = styled<
  IPaymentMethodDeleteMessageProps,
  IPaymentMethodDeleteMessageStyleProps,
  IPaymentMethodDeleteMessageStyles
>(PaymentMethodDeleteMessageComponent, getStyles);
