import { styled } from "@fluentui/react/lib/Utilities";
import { IProfileInfoProps } from "./ProfileInfo.types";
import {
  getStyles,
  IProfileInfoStyleProps,
  IProfileInfoStyles,
} from "./ProfileInfo.styles";
import { ProfileInfoComponent } from "./ProfileInfo.base";

/**
 * ProfileInfo
 */
export const ProfileInfo = styled<
  IProfileInfoProps,
  IProfileInfoStyleProps,
  IProfileInfoStyles
>(ProfileInfoComponent, getStyles);
