import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseDocumentsControlProps } from "./EnterpriseDocumentsControl.types";
import {
  getStyles,
  IEnterpriseDocumentsControlStyleProps,
  IEnterpriseDocumentsControlStyles,
} from "./EnterpriseDocumentsControl.styles";
import { EnterpriseDocumentsControlComponent } from "./EnterpriseDocumentsControl.base";

/**
 * EnterpriseDocumentsControl description
 */
export const EnterpriseDocumentsControl = styled<
  IEnterpriseDocumentsControlProps,
  IEnterpriseDocumentsControlStyleProps,
  IEnterpriseDocumentsControlStyles
>(EnterpriseDocumentsControlComponent, getStyles);
