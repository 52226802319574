import {
  DefaultButton,
  DefaultPalette,
  Dialog,
  DialogFooter,
  DialogType,
  MaskedTextField,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import i18n from "src/services/i18n";
import { getNewPaymentSecureDialogClassNames } from "./NewPaymentSecureDialog.styles";
import {
  INewPaymentSecureDialogProps,
  INewPaymentSecureDialogState,
} from "./NewPaymentSecureDialog.types";

const maskFormat: { [key: string]: RegExp } = {
  "*": /[0-9]/,
};

export class NewPaymentSecureDialogComponent extends React.Component<
  INewPaymentSecureDialogProps,
  INewPaymentSecureDialogState
> {
  constructor(props: INewPaymentSecureDialogProps) {
    super(props);
    this.state = {
      cvc: "",
      card: props.card,
    };
  }

  render(): JSX.Element {
    const { styles, mobile, onDismiss, onSubmit, isOpen } = this.props;
    const [classNames] = getNewPaymentSecureDialogClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { card } = this.state;

    if (this.props.card !== card) {
      setTimeout(() => {
        this.setState({
          card: this.props.card,
          cvc: "",
        });
      }, 200);
    }

    return (
      <Dialog
        hidden={!isOpen}
        type={DialogType.largeHeader}
        minWidth={mobile ? undefined : 500}
        modalProps={{
          className: classNames.root,
          isBlocking: true,
        }}
        title={"Information sécurisés"}
      >
        <div>
          <MessageBar messageBarType={MessageBarType.info}>
            {i18n.t("billing:payments:securedialog:instructions")}
          </MessageBar>
          <div style={{ height: "20px" }}></div>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <div style={{ width: "220px", padding: "10px" }}>
              <Stack>
                <Text style={{ fontWeight: "bold" }}>CVC</Text>
                <Text
                  variant={"small"}
                  style={{ color: DefaultPalette.neutralDark }}
                >
                  {i18n.t("billing:payments:securedialog:cvc:label")}
                </Text>
                <MaskedTextField
                  errorMessage={this.state.errorMessage}
                  required
                  value={this.state.cvc}
                  mask={"****"}
                  maskFormat={maskFormat}
                  onChange={(
                    _event: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string
                  ) => {
                    this.setState({
                      cvc: (newValue ?? "").replace(/_/, "").replace(/ /, ""),
                    });
                  }}
                />
              </Stack>
            </div>
            <Cards
              {...i18n.CreditCardStrings()}
              cvc={this.state.cvc}
              focused={"cvc"}
              name=""
              number=""
              expiry="01/01"
            />
          </Stack>
        </div>
        <DialogFooter>
          <PrimaryButton
            data-automation-id="nvx:securepayment:confirm:ok"
            disabled={
              this.state.cvc.length !== 3 && this.state.cvc.length !== 4
            }
            onClick={() => {
              if (this.state.cvc.length !== 3 && this.state.cvc.length !== 4) {
                this.setState({
                  errorMessage: i18n.t("billing:payments:securedialog:error"),
                });
              } else {
                onSubmit(this.state.cvc);
              }
            }}
            text={i18n.t("global:apply")}
          />
          <DefaultButton
            data-automation-id="nvx:securepayment:confirm:cancel"
            onClick={onDismiss}
            text={i18n.t("global:cancel")}
          />
        </DialogFooter>
      </Dialog>
    );
  }
}
