import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IDossierImmigrationDocumentCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IDossierImmigrationDocumentCardStyles
  extends IAmigoBaseStyles<IDossierImmigrationDocumentCardSubComponentStyles> {}

export interface IDossierImmigrationDocumentCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDossierImmigrationDocumentCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationDocumentCardStyleProps,
    IDossierImmigrationDocumentCardStyles
  >,
  props?: IDossierImmigrationDocumentCardStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationDocumentCardStyles>,
  IDossierImmigrationDocumentCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationDocumentCardStyleProps,
  IDossierImmigrationDocumentCardStyles
> = (
  props: IDossierImmigrationDocumentCardStyleProps
): IDossierImmigrationDocumentCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
    },
  };
};
