import { MessageBar, MessageBarType, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import { CustomSpinner } from "src/components/CustomSpinner";
import {
  CompletePaymentMethodSchema,
  IPaymentMethod,
  PaymentMethodSchema,
} from "src/models/Enterprises/IPaymentMethod";
import PaymentMethodsService from "src/services/Enterprises/PaymentMethodsService";
import i18n from "src/services/i18n";
import { validate } from "src/utils/validator";
import { CreditCardExpirationField } from "../_newpaymentmethod/CreditCardExpirationField";
import { CreditCardNameHolderField } from "../_newpaymentmethod/CreditCardNameHolderField";
import { CreditCardNumberField } from "../_newpaymentmethod/CreditCardNumberField";
import { PaymentAuthorizationMessages } from "../_newpaymentmethod/PaymentAuthorizationMessages";
import { PaymentMethodCommandBar } from "../_newpaymentmethod/PaymentMethodCommandBar";
import { PaymentMethodType } from "../_newpaymentmethod/PaymentMethodType";
import { getNewPaymentMethodControlClassNames } from "./NewPaymentMethodControl.styles";
import {
  INewPaymentMethodControlProps,
  INewPaymentMethodControlState,
} from "./NewPaymentMethodControl.types";

export class NewPaymentMethodControlComponent extends React.Component<
  INewPaymentMethodControlProps,
  INewPaymentMethodControlState
> {
  constructor(props: INewPaymentMethodControlProps) {
    super(props);

    this.state = {
      errors: [],
      focus: undefined,
      saving: false,
      errorMessage: undefined,
      entity: {
        id: 0,
        key: "0",
        card: "",
        name: "",
        expirationMonth: 0,
        expirationYear: new Date().getFullYear(),
        enabled: true,
        createdOn: "",
        modifiedOn: "",
        createdBy: "",
        cardType: "Visa",
        cvc: "",
        billingAuthorized: false,
        transportAuthorized: false,
        saveOnVaultDB: false,
      },
    };
  }

  private getValidationError = (entity: IPaymentMethod): any => {
    const errors: any = validate(
      this.props.dialog ?? false
        ? CompletePaymentMethodSchema(entity.name)
        : PaymentMethodSchema(entity.name),
      entity
    );
    return errors;
  }

  private isValid = (entity: IPaymentMethod): boolean => {
    return Object.keys(this.getValidationError(entity)).length === 0;
  };

  private save = (entity: IPaymentMethod): Promise<any> => {
    return PaymentMethodsService.addPaymentMethod(
      (this.props.enterprise.key ?? "-1").toString(),
      entity
    )
      .then(() => {
        this.setState({ saving: false, errorMessage: undefined });
        this.props.onDismiss(true);
      })
      .catch((_x) => {
        this.setState({
          saving: false,
          errorMessage: i18n.t("enterprises:paymentmethods:save:error"),
        });
      });
  };

  render(): JSX.Element {
    const { styles, dialog, onSave, onDismiss } = this.props;
    const [classNames] = getNewPaymentMethodControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    const expiry: string = `${
      this.state.entity.expirationMonth < 10
        ? "0" + this.state.entity.expirationMonth.toString()
        : this.state.entity.expirationMonth.toString()
    }/${this.state.entity.expirationYear}`;
    return (
      <div className={classNames.root}>
        <PaymentMethodCommandBar
          disabled={this.state.saving || !this.isValid(this.state.entity)}
          onSave={
            onSave === undefined
              ? () => {
                  const { entity } = _this.state;
                  const errors: any = validate(
                    CompletePaymentMethodSchema(entity.cardType),
                    entity
                  );
                  if (Object.keys(errors).length === 0) {
                    this.setState({ saving: true, errorMessage: undefined });
                    _this.save(_this.state.entity);
                  } else {
                    this.setState({ errors: errors, errorMessage: undefined });
                  }
                }
              : () => {
                  onSave(this.state.entity);
                }
          }
          onDismiss={() => onDismiss(false)}
        />
        <div style={{ padding: "10px" }}>
          {!(dialog ?? false) && (
            <h2>{i18n.t("enterprises:paymentmethods:new:header")}</h2>
          )}
          {this.state.saving && (
            <div>
              <div style={{ height: "70px" }}></div>
              <CustomSpinner
                {...this.props}
                styles={undefined}
                text={i18n.t("enterprises:paymentmethods:saving")}
              />
              <div style={{ height: "20px" }}></div>
            </div>
          )}
          {!this.state.saving && (
            <Stack horizontal tokens={{ childrenGap: 20 }}>
              <StackItem
                styles={{
                  root: { maxWidth: this.props.mobile ? undefined : "900px" },
                }}
              >
                {this.state.errorMessage && (
                  <div style={{ marginBottom: "20px" }}>
                    <MessageBar messageBarType={MessageBarType.error}>
                      {this.state.errorMessage}
                    </MessageBar>
                  </div>
                )}
                <div style={{ display: "none" }}>
                  <PaymentMethodType
                    mobile={this.props.mobile}
                    cardType={this.state.entity.cardType}
                    onChange={(cardType: string) => {
                      const { entity } = _this.state;
                      entity.cardType = cardType;
                      entity.card = "";
                      const errors: any =
                        this.state.errors.length > 0
                          ? validate(
                              dialog ?? false
                                ? CompletePaymentMethodSchema(entity.cardType)
                                : PaymentMethodSchema(entity.cardType),
                              entity
                            )
                          : [];
                      _this.setState({
                        entity: entity,
                        focus: undefined,
                        errors: errors,
                      });
                    }}
                  />
                  <hr />
                </div>
                <div>
                  <div
                    style={{
                      float: "left",
                      width: this.props.mobile ? undefined : "500px",
                    }}
                  >
                    <CreditCardNumberField
                      errorMessage={this.state.errors.card}
                      value={this.state.entity.card}
                      onFocus={() => this.setState({ focus: "number" })}
                      onChange={(newValue: string) => {
                        const { entity } = _this.state;
                        entity.card = newValue
                          .replace(/ /g, "")
                          .replace(/_/g, "")
                          .substr(0, 16);

                        if (
                          entity.card.startsWith("4") &&
                          entity.cardType !== "Visa"
                        ) {
                          entity.cardType = "Visa";
                        } else if (
                          entity.card.startsWith("5") &&
                          !entity.card.startsWith("50") &&
                          entity.cardType !== "MasterCard"
                        ) {
                          entity.cardType = "MasterCard";
                        } else if (
                          (entity.card.startsWith("34") ||
                            entity.card.startsWith("37")) &&
                          entity.cardType !== "American Express"
                        ) {
                          entity.cardType = "American Express";
                        }

                        _this.setState({ entity: entity });
                      }}
                    />
                    <CreditCardExpirationField
                      mobile={this.props.mobile}
                      month={this.state.entity.expirationMonth}
                      year={this.state.entity.expirationYear}
                      cvc={this.state.entity.cvc ?? ""}
                      onExpiryFocus={() => this.setState({ focus: "expiry" })}
                      onCvcFocus={() => this.setState({ focus: "cvc" })}
                      onMonthChange={(newValue: number) => {
                        const { entity } = _this.state;
                        entity.expirationMonth = newValue;
                        const errors: any =
                          this.state.errors.length > 0
                            ? validate(
                                dialog ?? false
                                  ? CompletePaymentMethodSchema(entity.cardType)
                                  : PaymentMethodSchema(entity.cardType),
                                entity
                              )
                            : [];
                        _this.setState({
                          entity: entity,
                          errors: errors,
                          errorMessage: undefined,
                        });
                      }}
                      onYearChange={(newValue: number) => {
                        const { entity } = _this.state;
                        entity.expirationYear = newValue;
                        const errors: any =
                          this.state.errors.length > 0
                            ? validate(
                                dialog ?? false
                                  ? CompletePaymentMethodSchema(entity.cardType)
                                  : PaymentMethodSchema(entity.cardType),
                                entity
                              )
                            : [];
                        _this.setState({
                          entity: entity,
                          errors: errors,
                          errorMessage: undefined,
                        });
                      }}
                      onCvcChange={(newValue: string) => {
                        const { entity } = _this.state;
                        entity.cvc = newValue
                          .replace(/ /g, "")
                          .replace(/_/g, "");
                        const errors: any =
                          this.state.errors.length > 0
                            ? validate(
                                dialog ?? false
                                  ? CompletePaymentMethodSchema(entity.cardType)
                                  : PaymentMethodSchema(entity.cardType),
                                entity
                              )
                            : [];
                        _this.setState({
                          entity: entity,
                          errors: errors,
                          errorMessage: undefined,
                        });
                      }}
                      expiryErrorMessage={this.state.errors.cvc}
                      cvcErrorMessage={this.state.errors.cvc}
                    />
                    <hr />
                    <CreditCardNameHolderField
                      errorMessage={this.state.errors.name}
                      value={this.state.entity.name}
                      onFocus={() => this.setState({ focus: "name" })}
                      onChange={(newValue: string) => {
                        
                        const { entity } = _this.state;
                        entity.name = newValue;
                        const errors: any =
                          this.state.errors.length > 0
                            ? validate(
                                dialog ?? false
                                  ? CompletePaymentMethodSchema(entity.cardType)
                                  : PaymentMethodSchema(entity.cardType),
                                entity
                              )
                            : [];
                        _this.setState({
                          entity: entity,
                          errors: errors,
                          errorMessage: this.getValidationError(entity)?.name,
                        });
                      }}
                    />
                  </div>
                  <div
                    style={{
                      float: "left",
                      padding: this.props.mobile ? "20px 10px" : "40px 50px",
                    }}
                  >
                    <Cards
                      {...i18n.CreditCardStrings()}
                      cvc={this.state.entity.cvc ?? ""}
                      expiry={expiry}
                      focused={this.state.focus}
                      name={this.state.entity.name}
                      number={this.state.entity.card}
                    />
                  </div>
                  <div style={{ clear: "both" }}></div>
                </div>
                <PaymentAuthorizationMessages
                  dialog={this.props.dialog ?? false}
                  errorMessage={this.state.errors.billingAuthorized}
                  billingAuthorized={this.state.entity.billingAuthorized}
                  transportAuthorized={this.state.entity.transportAuthorized}
                  saveOnVaultDB={this.state.entity.saveOnVaultDB}
                  onBillingAuthorized={(newValue: boolean) => {
                    const { entity } = _this.state;
                    entity.billingAuthorized = newValue;
                    const errors: any =
                      this.state.errors.length > 0
                        ? validate(
                            dialog ?? false
                              ? CompletePaymentMethodSchema(entity.cardType)
                              : PaymentMethodSchema(entity.cardType),
                            dialog
                              ? { ...entity, billingAuthorized: true }
                              : entity
                          )
                        : [];
                    _this.setState({
                      entity: entity,
                      errors: errors,
                      errorMessage: undefined,
                    });
                  }}
                  onTransportAuthorized={(newValue: boolean) => {
                    const { entity } = _this.state;
                    entity.transportAuthorized = newValue;
                    const errors: any =
                      this.state.errors.length > 0
                        ? validate(
                            dialog ?? false
                              ? CompletePaymentMethodSchema(entity.cardType)
                              : PaymentMethodSchema(entity.cardType),
                            entity
                          )
                        : [];
                    _this.setState({
                      entity: entity,
                      errors: errors,
                      errorMessage: undefined,
                    });
                  }}
                  onSaveOnVaultDB={(newValue: boolean)=>{
                    const { entity } = _this.state;
                    this.setState({
                      ..._this.state,
                      entity: {
                        ...entity,
                        saveOnVaultDB: newValue,
                      }
                    })
                  }}
                />
              </StackItem>
            </Stack>
          )}
        </div>
      </div>
    );
  }
}
