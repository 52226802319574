import { ISchemaObject } from "../utils/validator";
import i18n from "src/services/i18n";

export interface ICredentials {
  username: string;
  password: string;
}

export const CredentialsSchema: ISchemaObject = {
  name: "user",
  plural: "users",
  label: "User",
  fields: {
    username: {
      name: "username",
      label: i18n.t("login:username"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("login:username:error"),
      type: "string",
    },
    password: {
      name: "password",
      label: i18n.t("login:password"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("login:password:error"),
      type: "string",
    },
  },
};
