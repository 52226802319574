import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportRequestElementSubComponentStyles {}

export interface ITransportRequestElementStyles
  extends IAmigoBaseStyles<ITransportRequestElementSubComponentStyles> {}

export interface ITransportRequestElementStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportRequestElementClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportRequestElementStyleProps,
    ITransportRequestElementStyles
  >,
  props?: ITransportRequestElementStyleProps
): [
  IProcessedStyleSet<ITransportRequestElementStyles>,
  ITransportRequestElementSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportRequestElementStyleProps,
  ITransportRequestElementStyles
> = (
  _props: ITransportRequestElementStyleProps
): ITransportRequestElementStyles => {
  return {
    root: ["amigo-TransportRequestElement", {}],
  };
};
