import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IRequestsControlStyleProps,
  IRequestsControlStyles,
} from "../RequestsControl";

export interface IRequestsPageSubComponentStyles {
  control?: IStyleFunctionOrObject<
    IRequestsControlStyleProps,
    IRequestsControlStyles
  >;
}

export interface IRequestsPageStyles
  extends IAmigoBaseStyles<IRequestsPageSubComponentStyles> {}

export interface IRequestsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestsPageClassNames = (
  styles?: IStyleFunctionOrObject<IRequestsPageStyleProps, IRequestsPageStyles>,
  props?: IRequestsPageStyleProps
): [
  IProcessedStyleSet<IRequestsPageStyles>,
  IRequestsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestsPageStyleProps,
  IRequestsPageStyles
> = (props: IRequestsPageStyleProps): IRequestsPageStyles => {
  const pageStyle = props.tokens.defaultPageRootStyle as any;
  return {
    root: {
      ...pageStyle,
      overflowX: "hidden",
    },
  };
};
