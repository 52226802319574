import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportArrivalAssistedRequestEditorProps } from "./TransportArrivalAssistedRequestEditor.types";
import {
  getStyles,
  ITransportArrivalAssistedRequestEditorStyleProps,
  ITransportArrivalAssistedRequestEditorStyles,
} from "./TransportArrivalAssistedRequestEditor.styles";
import { TransportArrivalAssistedRequestEditorComponent } from "./TransportArrivalAssistedRequestEditor.base";

/**
 * TransportArrivalAssistedRequestEditor
 */
export const TransportArrivalAssistedRequestEditor = styled<
  ITransportArrivalAssistedRequestEditorProps,
  ITransportArrivalAssistedRequestEditorStyleProps,
  ITransportArrivalAssistedRequestEditorStyles
>(TransportArrivalAssistedRequestEditorComponent, getStyles);
