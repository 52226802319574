import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface ILabelFieldSubComponentStyles {}

export interface ILabelFieldStyles
  extends IComponentBaseStyles<ILabelFieldSubComponentStyles> {
  description?: IStyle;
  value?: IStyle;
  footer?: IStyle;
}

export interface ILabelFieldStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  isText: boolean;
  gap: number | undefined;
  maxWidth: number | undefined;
  isHidden: boolean | undefined;
}

export const getLabelFieldClassNames = (
  styles?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>,
  props?: ILabelFieldStyleProps
): [
  IProcessedStyleSet<ILabelFieldStyles>,
  ILabelFieldSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ILabelFieldStyleProps,
  ILabelFieldStyles
> = (props: ILabelFieldStyleProps): ILabelFieldStyles => {
  return {
    root: [
      "ms-LabelField",
      {
        maxWidth: props.maxWidth,
      },
    ],
    description: [
      "ms-LabelField-Desc",
      {
        WebkitFontSmoothing: "antialiased",
        fontSize: "14px",
        fontWeight: 600,
      },
    ],
    value: [
      "ms-LabelField-Value",
      {
        color: "rgb(72, 70, 68)",
        height: props.isText ? "20px" : undefined,
        WebkitFontSmoothing: "antialiased",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: props.isText ? "20px" : undefined,
      },
    ],
    footer: [
      "ms-LabelField-End",
      {
        height: props.gap ? `${props.gap}px` : "10px",
      },
    ],
  };
};
