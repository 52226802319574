import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantsPageProps } from "./ParticipantsPage.types";
import {
  getStyles,
  IParticipantsPageStyleProps,
  IParticipantsPageStyles,
} from "./ParticipantsPage.styles";
import { ParticipantsPageComponent } from "./ParticipantsPage.base";

/**
 * ParticipantsPage description
 */
export const ParticipantsPage = styled<
  IParticipantsPageProps,
  IParticipantsPageStyleProps,
  IParticipantsPageStyles
>(ParticipantsPageComponent, getStyles);
