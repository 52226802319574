import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentBillsProps } from "./NewPaymentBills.types";
import {
  getStyles,
  INewPaymentBillsStyleProps,
  INewPaymentBillsStyles,
} from "./NewPaymentBills.styles";
import { NewPaymentBillsComponent } from "./NewPaymentBills.base";

/**
 * NewPaymentBills description
 */
export const NewPaymentBills = styled<
  INewPaymentBillsProps,
  INewPaymentBillsStyleProps,
  INewPaymentBillsStyles
>(NewPaymentBillsComponent, getStyles);
