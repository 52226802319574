import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseContactInfoProps } from "./EnterpriseContactInfo.types";
import {
  getStyles,
  IEnterpriseContactInfoStyleProps,
  IEnterpriseContactInfoStyles,
} from "./EnterpriseContactInfo.styles";
import { EnterpriseContactInfoComponent } from "./EnterpriseContactInfo.base";

/**
 * EnterpriseContactInfo description
 */
export const EnterpriseContactInfo = styled<
  IEnterpriseContactInfoProps,
  IEnterpriseContactInfoStyleProps,
  IEnterpriseContactInfoStyles
>(EnterpriseContactInfoComponent, getStyles);
