import { Icon, Stack, StackItem, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getArrivalAssistedParticipantCardClassNames } from "./ArrivalAssistedParticipantCard.styles";
import {
  IArrivalAssistedParticipantCardProps,
  IArrivalAssistedParticipantCardState,
} from "./ArrivalAssistedParticipantCard.types";

export class ArrivalAssistedParticipantCardComponent extends React.Component<
  IArrivalAssistedParticipantCardProps,
  IArrivalAssistedParticipantCardState
> {
  constructor(props: IArrivalAssistedParticipantCardProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, item, columnWidth } = this.props;
    const [classNames, subComponentStyles] =
      getArrivalAssistedParticipantCardClassNames(styles!, {
        ...this.props,
        ...this.state,
        hasErrors: false,
      });

    return (
      <div className={classNames.root}>
        <GridViewCard
          {...this.props}
          styles={subComponentStyles?.gridViewCard}
          title={item.name}
          columnWidth={columnWidth}
          footerText={undefined}
          footerIconName={undefined}
          selected={item.selected}
          onClick={
            item.selected
              ? this.props.onClick
                ? () => {
                    this.props.onClick && this.props.onClick(item);
                  }
                : undefined
              : this.props.onSelected
              ? () => {
                  this.props.onSelected && this.props.onSelected(item);
                }
              : undefined
          }
          onSelected={
            this.props.onSelected
              ? () => {
                  this.props.onSelected && this.props.onSelected(item);
                }
              : undefined
          }
          icons={
            <>
              <TooltipHost content={item.name}>
                <Icon
                  iconName="UserFollowed"
                  styles={subComponentStyles?.topIcon}
                />
              </TooltipHost>
            </>
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("transports:flights:participants:code")}
                content={item.code}
              />
            </StackItem>
          </Stack>
        </GridViewCard>
      </div>
    );
  }
}
