import { styled } from "@fluentui/react/lib/Utilities";
import { IUploadFilesDialogProps } from "./UploadFilesDialog.types";
import {
  getStyles,
  IUploadFilesDialogStyleProps,
  IUploadFilesDialogStyles,
} from "./UploadFilesDialog.styles";
import { UploadFilesDialogComponent } from "./UploadFilesDialog.base";

/**
 * UploadFilesDialog
 */
export const UploadFilesDialog = styled<
  IUploadFilesDialogProps,
  IUploadFilesDialogStyleProps,
  IUploadFilesDialogStyles
>(UploadFilesDialogComponent, getStyles);
