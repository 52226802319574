import { styled } from "@fluentui/react/lib/Utilities";
import { IArrivalAssistedEditorProps } from "./ArrivalAssistedEditor.types";
import {
  getStyles,
  IArrivalAssistedEditorStyleProps,
  IArrivalAssistedEditorStyles,
} from "./ArrivalAssistedEditor.styles";
import { ArrivalAssistedEditorComponent } from "./ArrivalAssistedEditor.base";

/**
 * ArrivalAssistedEditor
 */
export const ArrivalAssistedEditor = styled<
  IArrivalAssistedEditorProps,
  IArrivalAssistedEditorStyleProps,
  IArrivalAssistedEditorStyles
>(ArrivalAssistedEditorComponent, getStyles);
