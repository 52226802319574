import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IRequestsParticipantsCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IRequestsParticipantsCardStyles
  extends IAmigoBaseStyles<IRequestsParticipantsCardSubComponentStyles> {}

export interface IRequestsParticipantsCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestsParticipantsCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestsParticipantsCardStyleProps,
    IRequestsParticipantsCardStyles
  >,
  props?: IRequestsParticipantsCardStyleProps
): [
  IProcessedStyleSet<IRequestsParticipantsCardStyles>,
  IRequestsParticipantsCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestsParticipantsCardStyleProps,
  IRequestsParticipantsCardStyles
> = (
  props: IRequestsParticipantsCardStyleProps
): IRequestsParticipantsCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
      gridViewCard: {
        root: {
          cursor: "pointer",
          padding: "8px",
          backgroundColor: props.theme.palette.white,
          margin: "10px",
          float: "left",
          width: props.mobile ? "100%" : "390px",
          maxWidth: props.mobile ? undefined : "390px",
          selectors: {
            [MediaQueries.tablet]: {
              width: "99%",
            },
            [MediaQueries.mobile]: {
              width: "99%",
            },
          },
        },
      },
    },
  };
};
