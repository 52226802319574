import * as React from "react";
import { DetailsList, SelectionMode } from "@fluentui/react";

export interface IDisplayItem {
  key: string;
  value: string;
}

export interface IDisplayListProps {
  items: IDisplayItem[];
}

export const DisplayList: React.FunctionComponent<IDisplayListProps> = ({
  items,
}) => {
  return (
    <DetailsList
      selectionMode={SelectionMode.none}
      isHeaderVisible={false}
      items={items}
      columns={[
        {
          key: "key",
          name: "",
          fieldName: "key",
          minWidth: 60,
        },
        {
          key: "value",
          name: "",
          fieldName: "value",
          minWidth: 200,
        },
      ]}
    />
  );
};
