import { Icon, Text } from "@fluentui/react";
import * as React from "react";
import DateText from "../DateText";
import { getLabelFieldClassNames } from "./LabelField.styles";
import { ILabelFieldProps, ILabelFieldState } from "./LabelField.types";

export class LabelFieldComponent extends React.Component<
  ILabelFieldProps,
  ILabelFieldState
> {
  constructor(props: ILabelFieldProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles } = this.props;
    const [classNames] = getLabelFieldClassNames(styles!, {
      ...this.props,
      ...this.state,
      isText:
        this.props.content === undefined ||
        this.props.content === null ||
        typeof this.props.content === "string",
      gap: this.props.gap,
      maxWidth: this.props.maxWidth,
      isHidden : true,
    });

    return (
      <div
        className={classNames.root}
        style={
          {
            maxWidth:  this.props.maxWidth?  `${this.props.maxWidth}px`:"",
            display: this.props.isHidden? "none": ""
          }
        }
      >
        <div>
          {this.props.iconProps && (
            <Icon {...this.props.iconProps} style={{ marginRight: "5px" }} />
          )}
          <Text className={classNames.description}>{this.props.label}</Text>
        </div>
        {this.props.content === undefined ||
        this.props.content === null ||
        (typeof this.props.content === "string" &&
          this.props.content.length === 0) ? (
          <div style={{ height: "20px" }}>{""}</div>
        ) : (
          <Text className={classNames.value}>
            {this.props.content && this.props.content instanceof Date ? (
              <DateText date={this.props.content} />
            ) : (
              <>{this.props.content}</>
            )}
            {this.props.content &&
              (this.props.content instanceof Date ||
                typeof this.props.content === "string") && <span>&nbsp;</span>}
          </Text>
        )}
        <div className={classNames.footer}></div>
      </div>
    );
  }
}
