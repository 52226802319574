import { SelectionMode } from "@fluentui/react/lib/Utilities";
import { DetailsList, Text } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getNewPaymentBillingSummaryClassNames } from "./NewPaymentBillingSummary.styles";
import {
  INewPaymentBillingSummaryProps,
  INewPaymentBillingSummaryState,
} from "./NewPaymentBillingSummary.types";

export class NewPaymentBillingSummaryComponent extends React.Component<
  INewPaymentBillingSummaryProps,
  INewPaymentBillingSummaryState
> {
  render(): JSX.Element {
    const { styles, mobile, subtotal, tps, tvq, total } = this.props;
    const [classNames] = getNewPaymentBillingSummaryClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <div>
          <Text variant={mobile ? "large" : "xLarge"}>
            {i18n.t("billing:pendingbills:billsummary:title")}
          </Text>
        </div>
        <DetailsList
          onRenderDetailsHeader={() => <React.Fragment />}
          selectionMode={SelectionMode.none}
          styles={{
            root: {
              width: "420px",
              overflow: "hidden",
            },
          }}
          items={[
            {
              key: "subtotal",
              name: i18n.t("billing:pendingbills:billsummary:subtotal"),
              amount: subtotal,
            },
            {
              key: "tps",
              name: i18n.t("billing:pendingbills:billsummary:tps"),
              amount: tps,
            },
            {
              key: "tvq",
              name: i18n.t("billing:pendingbills:billsummary:tvq"),
              amount: tvq,
            },
            {
              key: "total",
              name: i18n.t("billing:pendingbills:billsummary:total"),
              amount: total,
            },
          ]}
          columns={[
            {
              key: "title",
              name: "",
              fieldName: "name",
              minWidth: mobile ? 120 : 240,
              maxWidth: mobile ? 120 : 240,
              onRender: (item: any) => {
                return (
                  <Text
                    style={
                      item.key === "total" ? { fontWeight: "bold" } : undefined
                    }
                  >
                    {item.name}
                  </Text>
                );
              },
            },
            {
              key: "amount",
              name: "",
              fieldName: "amount",
              minWidth: 120,
              maxWidth: 120,
              onRender: (item: any) => {
                return (
                  <Text
                    style={
                      item.key === "total" ? { fontWeight: "bold" } : undefined
                    }
                  >{`${item.amount.toFixed(2)} $`}</Text>
                );
              },
            },
          ]}
        />
      </div>
    );
  }
}
