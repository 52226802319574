import { DefaultPalette, FontWeights, Stack, Text } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { TravelStatusIcon } from "../TravelStatusIcon";
import { getRequestsParticipantsCardClassNames } from "./RequestsParticipantsCard.styles";
import {
  IRequestsParticipantsCardProps,
  IRequestsParticipantsCardState,
} from "./RequestsParticipantsCard.types";

export class RequestsParticipantsCardComponent extends React.Component<
  IRequestsParticipantsCardProps,
  IRequestsParticipantsCardState
> {
  render(): JSX.Element {
    const { styles, item, title, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] =
      getRequestsParticipantsCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;
    const icons: JSX.Element = (
      <>
        <Stack
          horizontal
          horizontalAlign={"center"}
          className={classNames.root}
        >
          <Text
            style={{
              width: "32px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            A
          </Text>
          <Text
            style={{
              width: "32px",
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            D
          </Text>
        </Stack>
        <Stack horizontal horizontalAlign={"center"}>
          <TravelStatusIcon
            {...this.props}
            styles={undefined}
            participant={item}
            arrival={true}
            status={item?.arrivalStatus ?? 0}
          />
          <TravelStatusIcon
            {...this.props}
            styles={undefined}
            participant={item}
            arrival={false}
            status={item?.departureStatus ?? 0}
          />
        </Stack>
      </>
    );

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <Text
              variant="small"
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  fontSize: "14px",
                  fontWeight: FontWeights.bold,
                },
              }}
            >
              {`${item?.lastName.toUpperCase()},`}
            </Text>
            <Text
              variant="small"
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  fontSize: "14px",
                  fontWeight: FontWeights.bold,
                },
              }}
            >
              {`${item?.firstName}`}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={`${item?.participantType}`}
        footerIconName={
          (item?.participantType ?? []).length === 0
            ? undefined
            : footerIconName ?? "Pin"
        }
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={icons}
      >
        <Text
          styles={{
            root: {
              color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
              fontSize: "14px",
              fontWeight: FontWeights.regular,
            },
          }}
        >
          {item?.participantNumber}
        </Text>
        <Text
          variant="small"
          styles={{
            root: {
              color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
              height: "38px" /* NOTE: Consider always for double line here */,
              fontSize: "14px",
              fontWeight: FontWeights.regular,
            },
          }}
        >
          {i18n.t("requests:periods:arrival")}:{" "}
          {item?.arrivalType && item?.arrivalType != null
            ? i18n
                .t(`requests:${item?.arrivalType ?? "NONE"}`)
                .replace("requests:", "")
            : i18n.t("requests:NONE")}{" "}
          - {item?.arrivalDate}
        </Text>
        <Text
          variant="small"
          styles={{
            root: {
              color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
              fontSize: "14px",
              fontWeight: FontWeights.regular,
            },
          }}
        >
          {i18n.t("requests:periods:departure")}
          {":"}{" "}
          {item?.departureType && item?.departureType != null
            ? i18n
                .t(`requests:${item?.departureType ?? "NONE"}`)
                .replace("requests:", "")
            : i18n.t("requests:NONE")}{" "}
          - {item?.departureDate}
        </Text>
        <Text
          variant="small"
          styles={{
            root: {
              color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
              fontSize: "14px",
              fontWeight: FontWeights.regular,
            },
          }}
        >
          {title}
        </Text>
      </GridViewCard>
    );
  }
}
