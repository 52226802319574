import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IParticipantTransfertRequestParticipantCardStyleProps,
  IParticipantTransfertRequestParticipantCardStyles,
} from "../ParticipantTransfertRequestParticipantCard";

export interface IParticipantTransfertAssistedEditorSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IParticipantTransfertRequestParticipantCardStyleProps,
    IParticipantTransfertRequestParticipantCardStyles
  >;
}

export interface IParticipantTransfertAssistedEditorStyles
  extends IAmigoBaseStyles<IParticipantTransfertAssistedEditorSubComponentStyles> {}

export interface IParticipantTransfertAssistedEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantTransfertAssistedEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantTransfertAssistedEditorStyleProps,
    IParticipantTransfertAssistedEditorStyles
  >,
  props?: IParticipantTransfertAssistedEditorStyleProps
): [
  IProcessedStyleSet<IParticipantTransfertAssistedEditorStyles>,
  IParticipantTransfertAssistedEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantTransfertAssistedEditorStyleProps,
  IParticipantTransfertAssistedEditorStyles
> = (
  _props: IParticipantTransfertAssistedEditorStyleProps
): IParticipantTransfertAssistedEditorStyles => {
  return {
    root: ["amigo-ParticipantTransfertAssistedEditor", {}],
  };
};
