import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightConfirmationPageProps } from "./FlightConfirmationPage.types";
import {
  getStyles,
  IFlightConfirmationPageStyleProps,
  IFlightConfirmationPageStyles,
} from "./FlightConfirmationPage.styles";
import { FlightConfirmationPageComponent } from "./FlightConfirmationPage.base";

/**
 * FlightConfirmationPage
 */
export const FlightConfirmationPage = styled<
  IFlightConfirmationPageProps,
  IFlightConfirmationPageStyleProps,
  IFlightConfirmationPageStyles
>(FlightConfirmationPageComponent, getStyles);
