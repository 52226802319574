import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IError500PageSubComponentStyles {}

export interface IError500PageStyles
  extends IAmigoBaseStyles<IError500PageSubComponentStyles> {
  icon?: IStyle;
}

export interface IError500PageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getError500PageClassNames = (
  styles?: IStyleFunctionOrObject<IError500PageStyleProps, IError500PageStyles>,
  props?: IError500PageStyleProps
): [
  IProcessedStyleSet<IError500PageStyles>,
  IError500PageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IError500PageStyleProps,
  IError500PageStyles
> = (_props: IError500PageStyleProps): IError500PageStyles => {
  return {
    icon: {
      fontSize: "56px",
      margin: "20px",
    },
  };
};
