import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentsCategoryItemsProps } from "./DocumentsCategoryItems.types";
import {
  getStyles,
  IDocumentsCategoryItemsStyleProps,
  IDocumentsCategoryItemsStyles,
} from "./DocumentsCategoryItems.styles";
import { DocumentsCategoryItemsComponent } from "./DocumentsCategoryItems.base";

/**
 * DocumentsCategoryItems
 */
export const DocumentsCategoryItems = styled<
  IDocumentsCategoryItemsProps,
  IDocumentsCategoryItemsStyleProps,
  IDocumentsCategoryItemsStyles
>(DocumentsCategoryItemsComponent, getStyles);
