import * as React from "react";
import { getDepartureRequestFlightSelectorClassNames } from "./DepartureRequestFlightSelector.styles";
import {
  IDepartureRequestFlightSelectorState,
  IDepartureRequestFlightSelectorProps,
} from "./DepartureRequestFlightSelector.types";
import {
  Stack,
  IconButton,
  Toggle,
  Text,
  Panel,
  PanelType,
  Separator,
  DefaultButton,
  MessageBarType,
  MessageBar,
  Icon,
  TooltipHost,
  PrimaryButton,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { Card } from "@fluentui/react-cards";
import moment from "moment";

export class DepartureRequestFlightSelectorComponent extends React.Component<
  IDepartureRequestFlightSelectorProps,
  IDepartureRequestFlightSelectorState
> {
  constructor(props: IDepartureRequestFlightSelectorProps) {
    super(props);
    this.state = {
      prompt: false,
      warning: false,
    };
  }

  render(): JSX.Element {
    const {
      styles,
      mobile,
      disabled,
      flights,
      selectedFlight,
      onDismiss,
      onFlightSelected,
    } = this.props;
    const [classNames] = getDepartureRequestFlightSelectorClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack className={classNames.root} tokens={{ childrenGap: 10 }}>
        <Stack horizontal tokens={{ childrenGap: mobile ? 2 : 7 }}>
          <Text nowrap style={{ fontWeight: 600, marginTop: "5px" }}>
            {i18n.t("messages:assisted:newdeparture:charter:label")}
          </Text>
          <IconButton
            iconProps={{ iconName: "Info" }}
            onClick={() => this.setState({ warning: true })}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <Toggle
            styles={{ root: { marginTop: "3px!important" } }}
            checked={!disabled && flights && selectedFlight !== undefined}
            disabled={disabled || !flights}
            onChange={(
              _event: React.MouseEvent<HTMLElement>,
              checked?: boolean
            ) => {
              if (checked ?? false) {
                this.setState({ prompt: true });
              } else onFlightSelected(undefined);
            }}
          />
          {!disabled && flights && selectedFlight !== undefined && (
            <Card
              aria-label={selectedFlight.flightNumber}
              horizontal
              style={{ marginTop: "-10px" }}
              styles={{
                root: {
                  margin: "3px 8px 3px 3px!important",
                  maxWidth: "100%",
                },
              }}
              tokens={{ childrenMargin: 3 }}
            >
              <Card.Item
                styles={{
                  root: {
                    margin: "3px 8px 3px 3px!important",
                  },
                }}
              >
                <Stack
                  horizontal
                  horizontalAlign={"center"}
                  tokens={{ childrenGap: 10 }}
                >
                  <TooltipHost
                    content={`${selectedFlight.originCode} ${selectedFlight.originCity} - ${selectedFlight.destinationCode} ${selectedFlight.destinationCity}`}
                  >
                    <Text style={{ marginLeft: "5px" }} variant={"medium"}>
                      {selectedFlight.flightNumber}
                    </Text>
                  </TooltipHost>
                  <TooltipHost
                    content={`${selectedFlight.originCode} ${selectedFlight.originCity} - ${selectedFlight.destinationCode} ${selectedFlight.destinationCity}`}
                  >
                    <Text variant={"medium"}>
                      {moment(selectedFlight.departureDate).format(
                        "YYYY-MM-DD HH:mm"
                      )}
                    </Text>
                  </TooltipHost>
                  <TooltipHost
                    content={`${selectedFlight.originCode} ${selectedFlight.originCity} - ${selectedFlight.destinationCode} ${selectedFlight.destinationCity}`}
                  >
                    <Text variant={"medium"}>
                      {`${selectedFlight.originCode} - ${selectedFlight.destinationCode}`}
                    </Text>
                  </TooltipHost>
                </Stack>
              </Card.Item>
              <Card.Section>
                <Stack horizontal tokens={{ childrenGap: 3 }}>
                  <TooltipHost
                    content={i18n.t(
                      "messages:assisted:newdeparture:charter:selectother"
                    )}
                  >
                    <IconButton
                      iconProps={{ iconName: "Edit" }}
                      onClick={() => {
                        this.setState({
                          prompt: true,
                        });
                      }}
                    />
                  </TooltipHost>
                  <TooltipHost content={i18n.t("global:cancel")}>
                    <IconButton
                      iconProps={{ iconName: "Cancel" }}
                      onClick={() => {
                        onFlightSelected(undefined);
                      }}
                    />
                  </TooltipHost>
                </Stack>
              </Card.Section>
            </Card>
          )}
          {disabled && flights && flights.length === 0 && (
            <MessageBar
              style={mobile ? undefined : { maxWidth: "185px" }}
              messageBarType={MessageBarType.info}
            >
              {i18n.t("messages:assisted:newdeparture:charter:noflight")}
            </MessageBar>
          )}
        </Stack>
        <Panel
          isOpen={this.state.warning}
          isLightDismiss={true}
          isBlocking={false}
          headerText={i18n.t(
            "messages:assisted:newdeparture:charter:warning:title"
          )}
          onDismiss={() => {
            this.setState({ warning: false });
            onDismiss();
          }}
        >
          <div style={{ height: "60px" }}></div>
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 10 }}>
              <Text style={{ fontWeight: "bold" }}>
                {i18n.t("messages:assisted:newdeparture:charter:warning:title")}
              </Text>
              <Text>
                {i18n.t(
                  "messages:assisted:newdeparture:charter:warning:content"
                )}
              </Text>
            </Stack>
          </MessageBar>
          <div style={{ height: "40px" }}></div>
          <PrimaryButton
            text="Ok"
            onClick={() => {
              this.setState({ warning: false });
              onDismiss();
            }}
          />
        </Panel>
        <Panel
          isOpen={this.state.prompt}
          isLightDismiss={true}
          isBlocking={false}
          headerText={i18n.t(
            "messages:assisted:newdeparture:charter:selection"
          )}
          onDismiss={() => {
            this.setState({ prompt: false });
            onDismiss();
          }}
          type={PanelType.medium}
        >
          {flights && (
            <Stack
              grow
              style={{ marginTop: "10px", width: "100%" }}
              tokens={{ childrenGap: mobile ? 10 : 20 }}
            >
              {flights.map((x) => {
                return (
                  <Card
                    key={x.id}
                    aria-label={x.flightNumber}
                    horizontal={!mobile}
                    width={"100%"}
                    styles={{
                      root: {
                        maxWidth: "100%",
                      },
                    }}
                    tokens={{ childrenMargin: 12 }}
                  >
                    <Card.Item
                      grow
                      key={`card_item_${x.id}`}
                      styles={{
                        root: {
                          width: "100%",
                        },
                      }}
                    >
                      <Stack grow tokens={{ childrenGap: 10 }}>
                        <Stack
                          horizontal
                          grow
                          tokens={{ childrenGap: mobile ? 10 : 36 }}
                        >
                          {!mobile && (
                            <Icon
                              iconName="AirplaneSolid"
                              style={{
                                marginRight: "20px",
                                color:
                                  selectedFlight && selectedFlight.id === x.id
                                    ? this.props.theme.palette.green
                                    : this.props.theme.palette.blueLight,
                              }}
                            />
                          )}
                          <Stack
                            horizontalAlign={"center"}
                            grow
                            tokens={{ childrenGap: 10 }}
                          >
                            <Text nowrap variant={"large"}>
                              {moment(x.departureDate).format("YYYY-MM-DD")}
                            </Text>
                            <Text nowrap variant={"large"}>
                              {moment(x.departureDate).format("HH:mm")}
                            </Text>
                            <Text nowrap variant={"medium"}>
                              {mobile
                                ? x.originCode
                                : `${x.originCode} ${x.originCity}`}
                            </Text>
                          </Stack>
                          <Stack
                            grow
                            horizontalAlign={"center"}
                            tokens={{ childrenGap: 10 }}
                          >
                            <Text nowrap variant={"medium"}>
                              {x.flightNumber}
                            </Text>
                            <Separator
                              color={this.props.theme.palette.neutralDark}
                              styles={{
                                root: {
                                  minWidth: "60px",
                                  "::before": {
                                    minWidth: "60px",
                                    backgroundColor:
                                      this.props.theme.palette.neutralDark,
                                  },
                                },
                              }}
                            />
                            <Text nowrap variant={"medium"}>
                              {x.code}
                            </Text>
                          </Stack>
                          <Stack
                            grow
                            horizontalAlign={"center"}
                            tokens={{ childrenGap: 10 }}
                          >
                            <Text
                              variant={"large"}
                              nowrap
                              style={{
                                visibility:
                                  moment(x.arrivalDate).format("YYYY-MM-DD") ===
                                  moment(x.departureDate).format("YYYY-MM-DD")
                                    ? "hidden"
                                    : undefined,
                              }}
                            >
                              {moment(x.arrivalDate).format("YYYY-MM-DD")}
                            </Text>
                            <Text nowrap variant={"large"}>
                              {moment(x.arrivalDate).format("HH:ss")}
                            </Text>
                            <Text nowrap variant={"medium"}>
                              {mobile
                                ? x.destinationCode
                                : `${x.destinationCode} ${x.destinationCity}`}
                            </Text>
                          </Stack>
                        </Stack>
                        {x.seatAvailable <= 5 && (
                          <Text
                            style={{ color: this.props.theme.palette.redDark }}
                          >
                            {i18n.formatString(
                              i18n.t(
                                "messages:assisted:newdeparture:charter:places"
                              ),
                              x.seatAvailable.toString()
                            )}
                          </Text>
                        )}
                      </Stack>
                    </Card.Item>
                    <Card.Section
                      grow={false}
                      wrap={false}
                      styles={{
                        root: {
                          margin: "3px 8px!important",
                        },
                      }}
                    >
                      <DefaultButton
                        text={i18n.t(
                          "messages:assisted:newdeparture:charter:select"
                        )}
                        onClick={() => {
                          this.setState({
                            warning: false,
                            prompt: false,
                          });
                          onFlightSelected(x);
                        }}
                      />
                    </Card.Section>
                  </Card>
                );
              })}
              <div style={{ height: "20px" }}></div>
              <MessageBar messageBarType={MessageBarType.warning}>
                <Stack tokens={{ childrenGap: 10 }}>
                  <Text style={{ fontWeight: "bold" }}>
                    {i18n.t(
                      "messages:assisted:newdeparture:charter:warning:title"
                    )}
                  </Text>
                  <Text>
                    {i18n.t(
                      "messages:assisted:newdeparture:charter:warning:content"
                    )}
                  </Text>
                </Stack>
              </MessageBar>
            </Stack>
          )}
        </Panel>
      </Stack>
    );
  }
}
