import * as React from "react";
import { getTransportRequestsPageClassNames } from "./TransportRequestsPage.styles";
import {
  ITransportRequestsPageState,
  ITransportRequestsPageProps,
} from "./TransportRequestsPage.types";
import { Pivot, PivotItem } from "@fluentui/react";
import i18n from "src/services/i18n";
import { TransportSummaryControl } from "../TransportSummaryControl";
import { TransportRequestsArrivalSection } from "../TransportRequestsArrivalSection";
import { TransportRequestsDepartureSection } from "../TransportRequestsDepartureSection";
import { Route } from "react-router";
import { FlightBookingPage } from "../FlightBooking/FlightBookingPage";
import { FlightBookingParticipantsPage } from "../FlightBooking/FlightBookingParticipantsPage";
import { FlightCheckoutPage } from "../FlightCheckout/FlightCheckoutPage";
import { FlightSearchPage } from "../FlightSearch/FlightSearchPage";
import { FlightBookingPassengersPage } from "../FlightBooking/FlightBookingPassengersPage";

export class TransportRequestsPageComponent extends React.Component<
  ITransportRequestsPageProps,
  ITransportRequestsPageState
> {
  render(): JSX.Element {
    const { styles, route } = this.props;
    const [classNames] = getTransportRequestsPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <Pivot
          selectedKey={this.props.routePath}
          onLinkClick={(
            item?: PivotItem,
            _ev?: React.MouseEvent<HTMLElement>
          ) => {
            const key = (item as any).key ?? ".$summary";
            switch (key) {
              case ".$summary":
                route.history.push("/transports/sommaire");
                break;
              case ".$arrivees":
                route.history.push("/transports/demandes/arrivees");
                break;
              case ".$departs":
                route.history.push("/transports/demandes/departs");
                break;
              default:
                route.history.push("/transports/billetterie");
                break;
            }
          }}
        >
          <PivotItem
            itemKey={"summary"}
            key={"summary"}
            className={"amigo-Transports-Summary-PivotItem"}
            headerButtonProps={{
              "data-automation-id": "nav:transports:summary:tab",
            }}
            headerText={i18n.t("transports:requests:summary:header")}
          >
            <TransportSummaryControl {...this.props} styles={undefined} />
          </PivotItem>
          <PivotItem
            itemKey={"arrivees"}
            key={"arrivees"}
            className={"amigo-Transports-Arrivals-PivotItem"}
            headerButtonProps={{
              "data-automation-id": "nav:transports:arrivals:tab",
            }}
            headerText={i18n.t("transports:requests:arrivals:header")}
          >
            <TransportRequestsArrivalSection
              {...this.props}
              styles={undefined}
            />
          </PivotItem>
          <PivotItem
            itemKey={"departs"}
            key={"departs"}
            className={"amigo-Transports-Departures-PivotItem"}
            headerButtonProps={{
              "data-automation-id": "nav:transports:departures:tab",
            }}
            headerText={i18n.t("transports:requests:departures:header")}
          >
            <TransportRequestsDepartureSection
              {...this.props}
              styles={undefined}
            />
          </PivotItem>
          <PivotItem
            itemKey={"billetterie"}
            key={"billetterie"}
            headerButtonProps={{
              "data-automation-id": "nav:transports:billetterie:tab",
            }}
            className={"amigo-Transports-Billetterie-PivotItem"}
            headerText={i18n.t("transports:requests:billetterie:header")}
          >
            <Route
              path="/transports/billetterie/sommaire"
              exact
              render={(props) => {
                return (
                  <FlightBookingPage
                    {...this.props}
                    route={props}
                    styles={undefined}
                  />
                );
              }}
            />
            <Route
              path="/transports/billetterie/passagers"
              exact
              render={(props) => {
                return (
                  <FlightBookingPassengersPage
                    {...this.props}
                    route={props}
                    styles={undefined}
                  />
                );
              }}
            />
            <Route
              path="/transports/billetterie/participants"
              exact
              render={(props) => {
                return (
                  <FlightBookingParticipantsPage
                    {...this.props}
                    route={props}
                    styles={undefined}
                  />
                );
              }}
            />
            <Route
              path="/transports/billetterie/paiement"
              exact
              render={(props) => {
                return (
                  <FlightCheckoutPage
                    {...this.props}
                    route={props}
                    styles={undefined}
                  />
                );
              }}
            />
            <Route
              path="/transports/billetterie/confirmation"
              exact
              render={(_props) => {
                return <div></div>;
                /* 
                {
                   (<FlightConfirmationPage
                            {...this.props}
                            route={props}
                            styles={undefined}
                          />
                          );
                }
                */
              }}
            />
            <Route
              path="/transports/billetterie"
              exact
              render={(props) => {
                return (
                  <FlightSearchPage
                    {...this.props}
                    route={props}
                    styles={undefined}
                  />
                );
              }}
            />
          </PivotItem>
        </Pivot>
      </div>
    );
  }
}
