import * as React from "react";
import { getForgotPasswordPageClassNames } from "./ForgotPasswordPage.styles";
import {
  IForgotPasswordPageState,
  IForgotPasswordPageProps,
} from "./ForgotPasswordPage.types";
import { UnauthenticatedLayout } from "src/controls/UnauthenticatedLayout";
import { ForgotPasswordControl } from "../ForgotPasswordControl";

export class ForgotPasswordPageComponent extends React.Component<
  IForgotPasswordPageProps,
  IForgotPasswordPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getForgotPasswordPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <UnauthenticatedLayout
        {...this.props}
        className={classNames.root}
        styles={subComponentStyles?.layout}
      >
        <ForgotPasswordControl
          {...this.props}
          styles={subComponentStyles?.control}
          onLanguageChanged={
            this.props.onLanguageChanged === undefined
              ? (window as any).onLanguageChanged
              : this.props.onLanguageChanged
          }
        />
      </UnauthenticatedLayout>
    );
  }
}
