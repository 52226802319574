import {
  DefaultButton,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogType,
  Label,
  Panel,
  PanelType,
  PrimaryButton,
  Stack,
  StackItem,
} from "@fluentui/react";
import * as React from "react";
import MediaQuery from "react-responsive";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import GridViewDialog from "src/components/GridView/GridViewDialog";
import { IQueryFilter } from "src/models/IQueryFilter";
import { IMessageSummary } from "src/models/Messages/IMessageSummary";
import i18n from "src/services/i18n";
import MaintenanceService from "src/services/MaintenanceService";
import { MessageDisplayControl } from "../MessageDisplayControl";
import { MessagesListControl } from "../MessagesListControl";
import { NewMessageControl } from "../NewMessageControl";
import { getMessagesFolderClassNames } from "./MessagesFolder.styles";
import {
  IMessagesFolderProps,
  IMessagesFolderState,
} from "./MessagesFolder.types";
import MessagesService from "src/services/Messages/MessagesService";

export class MessagesFolderComponent extends React.Component<
  IMessagesFolderProps,
  IMessagesFolderState
> {
  constructor(props: IMessagesFolderProps) {
    super(props);
    const routeState = this.props.route.location.state;
    this.state = {
      hasMore: true,
      messages: [],
      fetched: false,
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.tiles,
        "timestamp",
        true
      ),
      filters: [],
      delete: false,
      message: null,
      new: routeState !== undefined,
      newtransit: false,
      messageToDelete: undefined,
      messageToEdit: undefined,
      confirmdiscard: false,
    };
    this.refresh.bind(this);
    MessagesService.getMessages(
      props.folder,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      false
    ).then((x) => {
      if (
        routeState === undefined &&
        !this.props.mobile &&
        !this.props.tablet &&
        this.state.message === null &&
        x.length > 0
      ) {
        /* Initial Value (if no incomming actions) */
        if (this.props.folder === "Drafts") {
          /* Drafts are editable */
          this.setState({
            messageToEdit: x[0],
            replyType: undefined,
            new: true,
            message: null,
            messages: x,
            hasMore: true,
            fetched: true,
            options: GridViewOptionsBuilder.getFetched(this),
          });
        } else {
          this.setState({
            message: x[0],
            messageToEdit: undefined,
            replyType: undefined,
            messages: x,
            new: false,
            hasMore: true,
            fetched: true,
            options: GridViewOptionsBuilder.getFetched(this),
          });
        }
      } else {
        this.setState({
          messageToEdit: undefined,
          replyType: undefined,
          message: null,
          messages: x,
          fetched: true,
          hasMore: x.length > 0,
          options: GridViewOptionsBuilder.getFetched(this),
        });
      }
    });
  }

  componentDidUpdate() {
    if (this.state.newtransit) {
      this.setState({
        newtransit: false,
        new: true,
      });
    }
  }

  private refresh() {
    MessagesService.init(this.props.folder);
    MessagesService.getMessages(
      this.props.folder,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      false
    ).then((x) =>
      this.setState({
        messages: x,
        hasMore: x.length > 0,
        fetched: true,
        options: GridViewOptionsBuilder.getFetched(this),
      })
    );
  }

  private onReply = (item: IMessageSummary): void => {
    this.setState({
      message: null,
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.tiles,
        "timestamp",
        true,
        true
      ),
      filters: [],
      new: true,
      messageToEdit: item,
      replyType: "reply",
    });
  };

  private onReplyAll = (item: IMessageSummary): void => {
    this.setState({
      message: null,
      new: true,
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.tiles,
        "timestamp",
        true,
        true
      ),
      filters: [],
      messageToEdit: item,
      replyType: "reply",
    });
  };

  private onForward = (item: IMessageSummary): void => {
    this.setState({
      message: null,
      new: true,
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.tiles,
        "timestamp",
        true,
        true
      ),
      filters: [],
      messageToEdit: item,
      replyType: "forward",
    });
  };

  private onMessageSelected = (item: IMessageSummary) => {
    if (!this.state.new && !this.state.newtransit) {
      if (this.props.folder === "Drafts") {
        this.setState({
          confirmdiscard: false,
          messageUponConfirmation: undefined,
          message: null,
          messageToEdit: item,
          new: true,
          newtransit: false,
          replyType: undefined,
        });
      } else {
        if (this.props.folder === "Inbox" && !item.acknowledged) {
          MessagesService.ackownledge(item.id).then((_x) => {
            item.acknowledged = true;
            this.refresh();
            this.setState({
              confirmdiscard: false,
              messageUponConfirmation: undefined,
              message: item,
              messageToEdit: undefined,
              new: false,
              newtransit: false,
              replyType: undefined,
            });
            this.props.onUpdate && this.props.onUpdate();
          });
        } else {
          this.setState({
            confirmdiscard: false,
            messageUponConfirmation: undefined,
            message: item,
            messageToEdit: undefined,
            new: false,
            newtransit: false,
            replyType: undefined,
          });
        }
      }
    } else {
      if (this.props.folder === "Inbox" && !item.acknowledged) {
        MessagesService.ackownledge(item.id).then((_x) => {
          item.acknowledged = true;
          this.refresh();
          this.props.onUpdate && this.props.onUpdate();
        });
      }

      if (this.props.folder === "Drafts") {
        if (this.state.messageToEdit) {
          const id = this.state.messageToEdit.id;
          if (item.id === id) {
            // User is editing this message
          } else {
            this.setState({
              confirmdiscard: true,
              messageUponConfirmation: item,
              new: true,
              replyType: undefined,
            });
          }
        } else if (this.state.new) {
          this.setState({
            confirmdiscard: true,
            messageUponConfirmation: item,
            replyType: undefined,
          });
        } else {
          this.setState({
            confirmdiscard: true,
            messageUponConfirmation: item,
            new: true,
            replyType: undefined,
          });
        }
      } else if (this.state.new) {
        this.setState({
          confirmdiscard: true,
          messageUponConfirmation: item,
          replyType: undefined,
          new: this.props.folder === "Drafts",
        });
      } else {
        this.setState({
          confirmdiscard: false,
          messageUponConfirmation: undefined,
          message: item,
          messageToEdit: undefined,
          new: false,
          newtransit: false,
          replyType: undefined,
        });
      }
    }
  };

  render(): JSX.Element {
    const { styles, tablet } = this.props;
    const [classNames] = getMessagesFolderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames?.root}>
        <div>
          <Stack styles={{ root: { width: "100%" } }} horizontal={true}>
            <GridViewDialog
              confirmTitle={i18n.t("messages:newmessage:discard:title")}
              confirmMessage={i18n.t("messages:newmessage:discard:message")}
              isConfirming={this.state.confirmdiscard}
              onDismiss={(result: boolean) => {
                const item = this.state.messageUponConfirmation;
                if (
                  result &&
                  (this.state.replyType || item || this.state.new)
                ) {
                  this.setState({
                    message: this.state.new ? null : item ?? null,
                    messageToEdit: this.state.new ? item : undefined,
                    newtransit: item ? this.state.new : false,
                    new: false,
                    confirmdiscard: false,
                    messageUponConfirmation: undefined,
                  });
                } else {
                  this.setState({
                    confirmdiscard: false,
                    messageUponConfirmation: undefined,
                  });
                }
              }}
            />
            <MediaQuery maxDeviceWidth="1224px">
              <StackItem styles={{ root: { width: "100%" } }} verticalFill>
                <MessagesListControl
                  {...this.props}
                  styles={undefined}
                  options={this.state.options}
                  messages={this.state.messages}
                  hasMore={this.state.hasMore}
                  fetched={this.state.fetched}
                  commands={[
                    {
                      key: "new",
                      name: i18n.t("messages:newmessage"),
                      icon: "NewMail",
                      confirmTitle: undefined,
                      confirmMessage: () => {
                        return "";
                      },
                      onClick: () => {
                        this.setState({
                          newtransit: true,
                          new: false,
                          message: null,
                          messageToEdit: undefined,
                          replyType: undefined,
                          options: GridViewOptionsBuilder.getOptions(
                            GridViewMode.tiles,
                            "timestamp",
                            true,
                            true
                          ),
                          filters: [],
                        });
                      },
                    },
                  ]}
                  onSort={(fieldName: string, desc: boolean) => {
                    MessagesService.init(this.props.folder);
                    MessagesService.getMessages(
                      this.props.folder,
                      fieldName,
                      desc,
                      this.state.filters,
                      undefined
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        options: {
                          ...this.state.options,
                          sortBy: fieldName,
                          desc: desc,
                          fetched: true,
                        },
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  onApplyFilters={(filters: IQueryFilter[]) => {
                    MessagesService.init(this.props.folder);
                    MessagesService.getMessages(
                      this.props.folder,
                      this.state.options.sortBy,
                      this.state.options.desc,
                      filters,
                      undefined
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        filters: filters,
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  onDataPaging={() => {
                    MessagesService.getMessages(
                      this.props.folder,
                      this.state.options.sortBy,
                      this.state.options.desc,
                      this.state.filters,
                      true
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  onDeleteMessage={(item: IMessageSummary) => {
                    this.setState({
                      delete: true,
                      messageToDelete: item,
                    });
                  }}
                  folder={this.props.folder}
                  messageSelected={this.onMessageSelected}
                />
              </StackItem>
            </MediaQuery>
            <MediaQuery minDeviceWidth="1224px">
              <StackItem styles={{ root: { width: "420px" } }} verticalFill>
                <Label
                  className="ms-hiddenLgUp"
                  styles={{ root: { marginTop: 10 } }}
                >
                  {this.props.folder}
                </Label>
                <MessagesListControl
                  {...this.props}
                  styles={undefined}
                  options={this.state.options}
                  messages={this.state.messages}
                  hasMore={this.state.hasMore}
                  fetched={this.state.fetched}
                  onDeleteMessage={(item: IMessageSummary) => {
                    this.setState({
                      delete: true,
                      messageToDelete: item,
                    });
                  }}
                  onDataPaging={() => {
                    MessagesService.getMessages(
                      this.props.folder,
                      this.state.options.sortBy,
                      this.state.options.desc,
                      this.state.filters,
                      true
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  commands={[
                    {
                      key: "new",
                      name: i18n.t("messages:newmessage"),
                      icon: "NewMail",
                      confirmTitle: undefined,
                      confirmMessage: () => {
                        return "";
                      },
                      onClick: () => {
                        this.setState({
                          newtransit: true,
                          new: false,
                          message: null,
                          messageToEdit: undefined,
                          replyType: undefined,
                          options: GridViewOptionsBuilder.getOptions(
                            GridViewMode.tiles,
                            "timestamp",
                            true,
                            true
                          ),
                          filters: [],
                        });
                      },
                    },
                  ]}
                  onSort={(fieldName: string, desc: boolean) => {
                    MessagesService.init(this.props.folder);
                    MessagesService.getMessages(
                      this.props.folder,
                      fieldName,
                      desc,
                      this.state.filters,
                      undefined
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        options: {
                          ...this.state.options,
                          sortBy: fieldName,
                          desc: desc,
                          fetched: true,
                        },
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  onApplyFilters={(filters: IQueryFilter[]) => {
                    MessagesService.init(this.props.folder);
                    MessagesService.getMessages(
                      this.props.folder,
                      this.state.options.sortBy,
                      this.state.options.desc,
                      filters,
                      undefined
                    ).then((x) => {
                      this.setState({
                        messages: x,
                        filters: filters,
                        hasMore:
                          x.length > this.state.messages.length && x.length > 0,
                        fetched: true,
                      });
                    });
                  }}
                  folder={this.props.folder}
                  messageSelected={this.onMessageSelected}
                />
              </StackItem>
              <StackItem
                styles={{
                  root: {
                    overflowX: "hidden",
                    overflowY: "auto",
                    marginTop: "3px",
                    height:
                      (
                        window.innerHeight -
                        (MaintenanceService.isOnMaintenance() ? 190 : 164)
                      ).toString() + "px",
                    backgroundColor: this.props.theme.palette.white,
                  },
                }}
                grow={2}
                verticalFill
              >
                {this.state.new ? (
                  <div style={{ padding: "0 0.5rem", width: "98%" }}>
                    <NewMessageControl
                      {...this.props}
                      inputMessage={this.state.messageToEdit}
                      replyType={this.state.replyType}
                      onDismiss={(
                        forceUpdate: boolean,
                        keep: boolean,
                        id?: number
                      ) => {
                        if (forceUpdate) {
                          MessagesService.init(this.props.folder);
                          MessagesService.getMessages(
                            this.props.folder,
                            this.state.options.sortBy,
                            this.state.options.desc,
                            this.state.filters,
                            true
                          ).then((x) => {
                            if (keep) {
                              this.setState({
                                hasMore:
                                  x.length > this.state.messages.length &&
                                  x.length > 0,
                                messages: x,
                                fetched: true,
                                newtransit:
                                  this.props.folder === "Drafts" &&
                                  x.length > 0,
                                new: false,
                                messageToEdit:
                                  this.props.folder !== "Drafts" ||
                                  x.length === 0
                                    ? undefined
                                    : id === undefined
                                    ? x[0]
                                    : x.filter((y) => y.id === id)[0],
                                messageToDelete: undefined,
                              });
                            } else {
                              this.setState({
                                hasMore:
                                  x.length > this.state.messages.length &&
                                  x.length > 0,
                                messages: x,
                                fetched: true,
                                new: false,
                                newtransit: false,
                                messageToDelete: undefined,
                                messageToEdit: undefined,
                                message: null,
                              });
                            }
                            this.props.onUpdate && this.props.onUpdate();
                          });
                        } else {
                          this.setState({
                            confirmdiscard: true,
                          });
                        }
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
                {this.state.message === null ? (
                  <div></div>
                ) : (
                  <div style={{ padding: "0.7rem" }}>
                    <h2 className="MailSubject">
                      {this.state.message.subject}
                    </h2>
                    <MessageDisplayControl
                      {...this.props}
                      styles={undefined}
                      onPrintMessage={(item: IMessageSummary) => {
                        MessagesService.printMessage(item);
                      }}
                      onReply={this.onReply}
                      onReplyAll={this.onReplyAll}
                      onForward={this.onForward}
                      onDeleteMessage={(
                        item: IMessageSummary,
                        _callback?: (item: IMessageSummary) => void
                      ) => {
                        this.setState({
                          delete: true,
                          messageToDelete: item,
                        });
                      }}
                      message={this.state.message}
                      folder={this.props.folder}
                    />
                  </div>
                )}
              </StackItem>
            </MediaQuery>
          </Stack>
          <MediaQuery maxDeviceWidth="1224px">
            <Panel
              type={tablet ? PanelType.custom : PanelType.medium}
              customWidth={tablet ? "100vw" : undefined}
              focusTrapZoneProps={{
                isClickableOutsideFocusTrap: true,
              }}
              isBlocking={this.state.new}
              isLightDismiss={!this.state.new}
              headerText={this.state.message?.subject}
              isOpen={this.state.message != null || this.state.new}
              onDismiss={() => {
                if (this.state.new) {
                  this.setState({ confirmdiscard: true });
                } else {
                  this.setState({ message: null });
                }
              }}
            >
              <div>
                {this.state.new ? (
                  <NewMessageControl
                    {...this.props}
                    onDismiss={(
                      forceUpdate: boolean,
                      keep: boolean,
                      id?: number
                    ) => {
                      if (forceUpdate) {
                        MessagesService.init(this.props.folder);
                        MessagesService.getMessages(
                          this.props.folder,
                          this.state.options.sortBy,
                          this.state.options.desc,
                          this.state.filters,
                          true
                        ).then((x) => {
                          if (keep) {
                            this.setState({
                              hasMore:
                                x.length > this.state.messages.length &&
                                x.length > 0,
                              messages: x,
                              fetched: true,
                              newtransit:
                                this.props.folder === "Drafts" && x.length > 0,
                              new: false,
                              messageToEdit:
                                this.props.folder !== "Drafts" || x.length === 0
                                  ? undefined
                                  : id === undefined
                                  ? x[0]
                                  : x.filter((y) => y.id === id)[0],
                              messageToDelete: undefined,
                            });
                          } else {
                            this.setState({
                              hasMore:
                                x.length > this.state.messages.length &&
                                x.length > 0,
                              messages: x,
                              fetched: true,
                              new: false,
                              newtransit: false,
                              messageToDelete: undefined,
                              messageToEdit: undefined,
                              message: null,
                            });
                          }
                          this.props.onUpdate && this.props.onUpdate();
                        });
                      } else {
                        this.setState({
                          confirmdiscard: true,
                        });
                      }
                    }}
                  />
                ) : (
                  <div></div>
                )}
                {this.state.message === null ? (
                  <div></div>
                ) : (
                  <MessageDisplayControl
                    {...this.props}
                    onPrintMessage={(item: IMessageSummary) => {
                      MessagesService.printMessage(item);
                    }}
                    onReply={this.onReply}
                    onReplyAll={this.onReplyAll}
                    onForward={this.onForward}
                    onDeleteMessage={(item: IMessageSummary) => {
                      this.setState({
                        delete: true,
                        messageToDelete: item,
                      });
                    }}
                    message={this.state.message}
                    folder={this.props.folder}
                  />
                )}
              </div>
            </Panel>
          </MediaQuery>
        </div>
        <Dialog
          hidden={!this.state.delete}
          onDismiss={() => {
            this.setState({
              delete: false,
              messageToDelete: undefined,
            });
          }}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: i18n.t("messages:delete:title"),
            closeButtonAriaLabel: i18n.t("global:close"),
            subText: i18n.t("messages:delete:subtext"),
          }}
          modalProps={{
            className: "DeleteDialog",
            titleAriaId: "1",
            subtitleAriaId: "1",
            isBlocking: true,
            dragOptions: undefined,
          }}
        >
          <DialogContent>
            <div></div>
          </DialogContent>
          <DialogFooter>
            <PrimaryButton
              onClick={() => {
                if (this.state.messageToDelete !== undefined) {
                  MessagesService.deleteMessage(this.state.messageToDelete)
                    .then((x) => {
                      this.props.onDelete && this.props.onDelete();
                      this.setState((prevState) => {
                        const newData = prevState.messages.slice();
                        const index = prevState.messages.indexOf(x);
                        newData.splice(index, 1);
                        return {
                          messages: newData,
                          fetched: true,
                          delete: false,
                          message: null,
                          new: false,
                          newtransit: false,
                          messageToEdit: undefined,
                          messageToDelete: undefined,
                        };
                      });

                      this.setState({
                        delete: false,
                        message: null,
                        new: false,
                        newtransit: false,
                        messageToEdit: undefined,
                        messageToDelete: undefined,
                      });
                      this.refresh();
                    })
                    .catch((_x) => {
                      this.setState({
                        delete: false,
                        new: false,
                        newtransit: false,
                        messageToEdit: undefined,
                        messageToDelete: undefined,
                      });
                    });
                } else {
                  this.setState({
                    delete: false,
                    messageToDelete: undefined,
                    new: false,
                    newtransit: false,
                    messageToEdit: undefined,
                  });
                }
              }}
              text={i18n.t("global:yes")}
            />
            <DefaultButton
              onClick={(_ev) => {
                this.setState({
                  delete: false,
                  messageToDelete: undefined,
                });
              }}
              text={i18n.t("global:no")}
            />
          </DialogFooter>
        </Dialog>
      </div>
    );
  }
}
