import { styled } from "@fluentui/react/lib/Utilities";
import { IArrivalAssistedControlProps } from "./ArrivalAssistedControl.types";
import {
  getStyles,
  IArrivalAssistedControlStyleProps,
  IArrivalAssistedControlStyles,
} from "./ArrivalAssistedControl.styles";
import { ArrivalAssistedControlComponent } from "./ArrivalAssistedControl.base";

/**
 * ArrivalAssistedControl
 */
export const ArrivalAssistedControl = styled<
  IArrivalAssistedControlProps,
  IArrivalAssistedControlStyleProps,
  IArrivalAssistedControlStyles
>(ArrivalAssistedControlComponent, getStyles);
