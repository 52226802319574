import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewStyleProps,
  IGridViewStyles,
} from "src/components/GridView/GridView.styles";
import { IGridViewOptions } from "src/components/GridView/GridView.types";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IAlertCardStyleProps,
  IAlertCardStyles,
} from "../AlertCard/AlertCard.styles";
import { IAlertDetailsStyleProps, IAlertDetailsStyles } from "../AlertDetails";

export interface IAlertsControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<IAlertCardStyleProps, IAlertCardStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  alertDetails?: IStyleFunctionOrObject<
    IAlertDetailsStyleProps,
    IAlertDetailsStyles
  >;
}

export interface IAlertsControlStyles
  extends IAmigoBaseStyles<IAlertsControlSubComponentStyles> {
  typeColumIcon?: IStyle;
}

export interface IAlertsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  options: IGridViewOptions;
}

export const getAlertsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IAlertsControlStyleProps,
    IAlertsControlStyles
  >,
  props?: IAlertsControlStyleProps
): [
  IProcessedStyleSet<IAlertsControlStyles>,
  IAlertsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAlertsControlStyleProps,
  IAlertsControlStyles
> = (_props: IAlertsControlStyleProps): IAlertsControlStyles => {
  return {
    typeColumIcon: {
      margin: "4px 5px",
    },
    /*
    subComponentStyles: {
      gridView: {
        gridContainer:
          props.options.mode === GridViewMode.tiles
            ? {
                maxHeight: "calc(-509px + 100vh)",
                [MediaQueries.mobile]: {
                  maxHeight: "calc(-294px + 100vh)",
                },
              }
            : {
                maxHeight: "calc(-509px + 100vh)",
                height: "calc(-509px + 100vh)",
                [MediaQueries.mobile]: {
                  maxHeight: "calc(-294px + 100vh)",
                  height: "calc(-294px + 100vh)",
                },
              },
      },
    },
    */
  };
};
