import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IContactsModificationListSubComponentStyles {}

export interface IContactsModificationListStyles
  extends IAmigoBaseStyles<IContactsModificationListSubComponentStyles> {}

export interface IContactsModificationListStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getContactsModificationListClassNames = (
  styles?: IStyleFunctionOrObject<
    IContactsModificationListStyleProps,
    IContactsModificationListStyles
  >,
  props?: IContactsModificationListStyleProps
): [
  IProcessedStyleSet<IContactsModificationListStyles>,
  IContactsModificationListSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IContactsModificationListStyleProps,
  IContactsModificationListStyles
> = (
  _props: IContactsModificationListStyleProps
): IContactsModificationListStyles => {
  return {};
};
