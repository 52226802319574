import { styled } from "@fluentui/react/lib/Utilities";
import { IInspectionAssistedControlProps } from "./InspectionAssistedControl.types";
import {
  getStyles,
  IInspectionAssistedControlStyleProps,
  IInspectionAssistedControlStyles,
} from "./InspectionAssistedControl.styles";
import { InspectionAssistedControlComponent } from "./InspectionAssistedControl.base";

/**
 * InspectionAssistedControl description
 */
export const InspectionAssistedControl = styled<
  IInspectionAssistedControlProps,
  IInspectionAssistedControlStyleProps,
  IInspectionAssistedControlStyles
>(InspectionAssistedControlComponent, getStyles);
