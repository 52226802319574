/**
 * @summary Application class
 * @version 2.0.1
 */
/** imports */
import { IIconProps, INavLink, Stack, ThemeProvider } from "@fluentui/react";
import "@fluentui/react/dist/css/fabric.min.css";
import "react-credit-cards/es/styles-compiled.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { RouteComponentProps } from "react-router";
import { theme } from "src/AmigoTokens";
import MaintenanceService from "src/services/MaintenanceService";
import { AppRouter } from "../AppRouter/AppRouter";
import { AppEnterpriseMenu } from "../controls/AppEnterpriseMenu";
import BrowserService from "../services/BrowserService";
import EnvironmentService from "../services/EnvironmentService";
import i18n from "../services/i18n";
import NavService from "../services/NavService";
import PreferencesService from "../services/PreferencesService";
/* css imports */
import "../_styles/App.css";
import "../_styles/Images.css";
import { AppComponentImpl } from "./App.impl";
import { getAppClassNames } from "./App.styles";

/**
 * App class
 */
export class AppComponent extends AppComponentImpl {
  /**
   * Renders the application
   * @returns the Fabric Application
   */
  render() {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getAppClassNames(styles!, {
      ...this.props,
      ...this.state,
      defaultColor: this.props.theme.palette.black,
    });
    const home: INavLink = NavService.getHomeLink(() => {
      this.forceUpdate();
    });

    const [version, env] = EnvironmentService.getEnvironmentName();

    let envIconName: string | undefined;
    if (env) {
      switch (env) {
        case "dev":
          envIconName = "Bug";
          break;
        case "test":
          envIconName = "TestParameter";
          break;
      }
    }

    const icon: IIconProps = {
      itemID: version,
      title: env ? i18n.t(`env:${env}`) : undefined,
      iconName: envIconName,
    };
    BrowserService.init();
    return (
      <ThemeProvider theme={theme}>
        <Stack className={classNames.root} styles={subComponentStyles?.stack}>
          <AppRouter
            {...this.props}
            {...this.state}
            styles={subComponentStyles?.router}
            environment={icon}
            menu={[
              {
                links: [home].concat(NavService.getMenu()[0].links),
              },
            ]}
            isOnMaintenance={MaintenanceService.isOnMaintenance()}
            ackownledgeMaintenance={MaintenanceService.acknowledge}
            maintenanceMessage={this.maintenanceMessage()}
            nav={(props: RouteComponentProps<any>) => (
              <AppEnterpriseMenu
                {...this.props}
                {...this.state}
                route={props}
                onEnterpriseChanged={this.onEnterpriseChanged}
              />
            )}
            accountDisplayName={this.state.enterprise?.name}
            menucollapsed={PreferencesService.menucollapsed()}
            onGetNotification={this.onGetNotification}
            onLanguageChanged={this.onLanguageChanged}
            onEnterpriseChanged={this.onEnterpriseChanged}
          />
        </Stack>
      </ThemeProvider>
    );
  }
}
