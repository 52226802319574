import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchCriteriaProps } from "./FlightSearchCriteria.types";
import {
  getStyles,
  IFlightSearchCriteriaStyleProps,
  IFlightSearchCriteriaStyles,
} from "./FlightSearchCriteria.styles";
import { FlightSearchCriteriaComponent } from "./FlightSearchCriteria.base";

/**
 * FlightSearchCriteria
 */
export const FlightSearchCriteria = styled<
  IFlightSearchCriteriaProps,
  IFlightSearchCriteriaStyleProps,
  IFlightSearchCriteriaStyles
>(FlightSearchCriteriaComponent, getStyles);
