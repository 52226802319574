import * as React from "react";
import { CommandBar } from "@fluentui/react";
import i18n from "src/services/i18n";

export interface IPaymentMethodCommandBarProps {
  disabled: boolean;
  onSave: () => void;
  onDismiss: () => void;
}

export interface IPaymentMethodCommandBarState {}

export class PaymentMethodCommandBar extends React.Component<
  IPaymentMethodCommandBarProps,
  IPaymentMethodCommandBarState
> {
  constructor(props: IPaymentMethodCommandBarProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <CommandBar
        items={[
          {
            key: "save",
            name: i18n.t("enterprises:paymentmethods:save"),
            disabled: this.props.disabled,
            iconProps: {
              iconName: "Save",
            },
            onClick: this.props.onSave,
          },
          {
            key: "cancel",
            name: i18n.t("enterprises:paymentmethods:cancel"),
            iconProps: {
              iconName: "Cancel",
            },
            onClick: this.props.onDismiss,
          },
        ]}
      />
    );
  }
}
