import { IParticipantTransfertRequestParticipant } from "./IParticipantTransfertRequestParticipant";
import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface INewParticipantTransfertRequest {
  id: number;
  key: string;
  itemId: number;
  enterpriseId: number;
  requestId?: number;
  requestNumber?: string;
  requestDate?: string;
  transfertEnterprise?: string;
  participants: IParticipantTransfertRequestParticipant[];
}

export const NewParticipantTransfertRequestSchema: ISchemaObject = {
  name: "participanttransfert",
  plural: "participanttransferts",
  label: "ParticipantTransfert",
  fields: {
    requestId: {
      name: "requestId",
      label: i18n.t("messages:assisted:participanttransfert:requestnumber"),
      required: true,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:participanttransfert:requestnumber:error"
      ),
      type: "number",
    },
    requestDate: {
      name: "requestDate",
      label: i18n.t("messages:assisted:participanttransfert:requestdate"),
      required: true,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:participanttransfert:requestdate:error"
      ),
      type: "string",
    },
    transfertEnterprise: {
      name: "transfertEnterprise",
      label: i18n.t(
        "messages:assisted:participanttransfert:transfertenterprise"
      ),
      required: true,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:participanttransfert:transfertenterprise:error"
      ),
      type: "string",
    },
  },
};
