import { Checkbox } from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import AlertsService from "src/services/Alerts/AlertsService";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import i18n from "src/services/i18n";
import { getEnterpriseAlertLevelClassNames } from "./EnterpriseAlertLevel.styles";
import {
  IEnterpriseAlertLevelProps,
  IEnterpriseAlertLevelState,
} from "./EnterpriseAlertLevel.types";

export class EnterpriseAlertLevelComponent extends React.Component<
  IEnterpriseAlertLevelProps,
  IEnterpriseAlertLevelState
> {
  constructor(props: IEnterpriseAlertLevelProps) {
    super(props);
    this.state = {
      deltas: [],
      selected: this.props.enterprise.deltas,
    };

    AlertsService.getAlertDeltas().then((x) => {
      this.setState({
        deltas: x,
        selected: this.props.enterprise.deltas,
      });
    });
  }

  private onChange = (checked: boolean, id: number | string) => {
    let { selected } = this.state;

    if (checked) {
      selected = selected
        .filter((y) => y !== id)
        .concat([parseInt(id.toString())]);
    } else {
      selected = selected.filter((y) => y !== id);
    }
    EnterpriseService.updateAlertDeltas(
      this.props.enterprise.id,
      selected
    ).then((_y) => {
      this.setState({
        selected: selected,
      });
    });
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getEnterpriseAlertLevelClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        <IconHeader
          {...this.props}
          styles={subComponentStyles?.iconHeader}
          iconName="MailAlert"
          title={i18n.t("enterprises:alerts:title")}
        />
        <div>
          <div>
            <div className={classNames.inner}>
              {i18n.t("enterprises:alerts:instructions")}
              {this.state.deltas.map((x) => {
                const label: string = x.name
                  .replace("daybefore", i18n.t("global:daybefore"))
                  .replace("daysbefore", i18n.t("global:daysbefore"));
                return (
                  <div
                    key={`ws-nvx-${x.id}`}
                    style={{ paddingTop: "10px", paddingBottom: "10px" }}
                  >
                    <Checkbox
                      key={x.id.toString()}
                      data-itemID={x.id.toString()}
                      label={label}
                      checked={
                        this.state.selected.filter(
                          (y) => y === parseInt(x.id.toString())
                        ).length !== 0
                      }
                      onChange={(
                        _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        checked?: boolean
                      ) => {
                        this.onChange(checked ?? false, x.id);
                      }}
                    />
                  </div>
                );
              })}
            </div>
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
