import {
  CommandBarButton,
  CompactPeoplePicker,
  IBasePicker,
  Icon,
  IconButton,
  IPersonaProps,
  Modal,
  Persona,
  PersonaPresence,
  PersonaSize,
  Selection,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { IUser } from "src/models/User";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import UsersService from "src/services/Users/UsersService";
import { GridView } from "../GridView/GridView";
import {
  GridViewOptionsBuilder,
  IQueryFilter,
} from "../GridView/GridView.imports";
import { GridViewMode, IGridViewOptions } from "../GridView/GridView.types";
import { MediaQueries } from "../MediaQueries";
import { getUsersPickerDialogClassNames } from "./UsersPickerDialog.styles";
import {
  IUsersPickerDialogProps,
  IUsersPickerDialogState,
} from "./UsersPickerDialog.types";

export class UsersPickerDialogComponent extends React.Component<
  IUsersPickerDialogProps,
  IUsersPickerDialogState
> {
  // private _titleId: string = getId("title");
  // private _subtitleId: string = getId("subText");
  private picker: React.RefObject<IBasePicker<IPersonaProps>>;
  private selection: Selection;

  constructor(props: IUsersPickerDialogProps) {
    super(props);
    this.selection = new Selection();
    this.selection.setItems([]);
    this.picker = React.createRef();
    this.state = {
      options: GridViewOptionsBuilder.getOptions(
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "name",
        false
      ),
      items: [],
    };
  }

  private userToPersona = (user: IUser): IPersonaProps => {
    return {
      imageUrl: undefined,
      id: user.id,
      text: user.name,
      secondaryText: user.role,
      tertiaryText: undefined,
      optionalText: user.email,
      presence: PersonaPresence.none,
    };
  };

  private personaToUser = (props: IPersonaProps): IUser | undefined => {
    const selectedItems = this.state.items.filter(
      (x) => props.id && x.id === props.id
    );
    return selectedItems.length === 0 ? undefined : selectedItems[0];
  };

  private getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  private _onRenderCell = (item: IUser, index?: number): JSX.Element => {
    return (
      <div
        style={{ padding: "20px", float: "left", cursor: "pointer" }}
        onClick={() => {
          index !== undefined &&
            this.selection.setKeySelected(item.key, true, false);
          this.forceUpdate();
        }}
      >
        <Persona
          size={PersonaSize.size72}
          text={item.name}
          initialsColor={"rgb(122, 117, 116)"}
          secondaryText={item.email}
          tertiaryText={item.role}
        />
      </div>
    );
  };

  componentDidUpdate() {
    if (!this.state.options.fetched) {
      UsersService.getUsers(
        this.state.options.sortBy,
        this.state.options.desc,
        this.state.filters,
        undefined
      ).then((x) => {
        this.selection.setItems(x);
        this.setState({
          items: x,
          options: GridViewOptionsBuilder.getFetched(this),
        });
      });
    }
  }

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames, subComponentStyles] = getUsersPickerDialogClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selectedItems: IPersonaProps[] = _this.selection
      .getSelection()
      .map((x) => this.userToPersona(x as IUser));
    return (
      <Modal
        isOpen={this.props.enabled}
        onDismiss={() => {
          this.selection.setAllSelected(false);
          this.props.onDismiss();
        }}
        isBlocking={true}
        styles={{
          main: {
            minWidth: "60vw",
            [MediaQueries.mobile]: {
              minWidth: "100%",
            },
            [MediaQueries.tablet]: {
              minWidth: "100%",
            },
          },
        }}
        closeButtonAriaLabel={i18n.t("global:close")}
        containerClassName={classNames.container}
      >
        <div className={classNames.header}>
          <Stack horizontal grow tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <Stack
                horizontal
                tokens={{ childrenGap: 10 }}
                style={{ marginTop: "5px" }}
              >
                <Icon iconName={"Contact"} />
                <Text variant={"large"} style={{ fontWeight: 600 }}>
                  {i18n.t("messages:searchcontact:title")}
                </Text>
              </Stack>
            </StackItem>
            <StackItem>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <CommandBarButton
                  styles={subComponentStyles?.commandButton}
                  iconProps={{ iconName: "CheckMark" }}
                  text={i18n.t("global:apply")}
                  onClick={() => {
                    this.props.onApplySelection(
                      this.selection.getSelection() as IUser[]
                    );
                    this.selection.setAllSelected(false);
                  }}
                />
                <IconButton
                  styles={subComponentStyles?.iconButton}
                  style={{ width: "42px", float: "right" }}
                  iconProps={{ iconName: "Cancel" }}
                  ariaLabel={i18n.t("global:close")}
                  onClick={this.props.onDismiss}
                />
              </Stack>
            </StackItem>
          </Stack>
        </div>
        <div style={{ padding: "10px" }}>
          <CompactPeoplePicker
            onResolveSuggestions={(
              _filterText: string,
              _currentPersonas?: IPersonaProps[],
              _limitResults?: number
            ): IPersonaProps[] | Promise<IPersonaProps[]> => {
              return [];
            }}
            onEmptyInputFocus={undefined}
            getTextFromItem={this.getTextFromItem}
            className={"ms-PeoplePicker"}
            onGetMoreResults={undefined}
            pickerSuggestionsProps={undefined}
            onRemoveSuggestion={undefined}
            styles={subComponentStyles?.picker}
            selectedItems={selectedItems}
            onChange={(items?: IPersonaProps[]) => {
              const selectedItems: IUser[] = [];
              items?.forEach((x) => {
                const user: IUser | undefined = this.personaToUser(x);
                if (user) {
                  selectedItems.push(user);
                }
              });
              _this.selection.setItems(selectedItems);
              _this.selection.setAllSelected(true);
              _this.forceUpdate();
            }}
            inputProps={{
              onBlur: (_ev: React.FocusEvent<HTMLInputElement>) => {},
              onFocus: (ev: React.FocusEvent<HTMLInputElement>) => {
                ev.preventDefault();
                ev.stopPropagation();
              },
              disabled: true,
              "aria-label": i18n.t("messages:searchcontact:title"),
            }}
            componentRef={this.picker}
            resolveDelay={300}
          />
        </div>
        <div>
          <GridView
            {...this.props}
            styles={subComponentStyles?.gridView}
            embedded={true}
            fullTextEnabled={false}
            selectionPreservedOnEmptyClick={true}
            onSelectionChanged={(items: IUser[]) => {
              _this.selection.setItems(items);
              _this.selection.setAllSelected(true);
              _this.forceUpdate();
            }}
            onItemInvoked={() => {
              _this.forceUpdate();
            }}
            onSort={(fieldName: string, desc: boolean) => {
              UsersService.init();
              UsersService.getUsers(
                fieldName,
                desc,
                this.state.filters,
                false
              ).then((x) => {
                this.setState({
                  items: x,
                  options: GridViewOptionsBuilder.getOptions(
                    this.state.options.mode,
                    fieldName,
                    desc,
                    true
                  ),
                });
              });
            }}
            options={this.state.options}
            onOptionsChanged={(options: IGridViewOptions) => {
              this.setState({
                options: options,
              });
            }}
            columns={
              mobile
                ? [
                    {
                      key: "name",
                      name: "messages:users:title",
                      minWidth: 200,
                    },
                    {
                      key: "email",
                      name: "messages:users:email",
                      minWidth: undefined,
                    },
                  ]
                : [
                    {
                      key: "name",
                      name: "messages:users:title",
                      minWidth: 200,
                    },
                    {
                      key: "email",
                      name: "messages:users:email",
                      minWidth: undefined,
                    },
                    /*
                    ,
                    {
                      key: "role",
                      name: "messages:users:role",
                      minWidth: 340,
                    },
                    */
                  ]
            }
            onRenderTile={this._onRenderCell}
            onDataPaging={() => {
              UsersService.getUsers(
                this.state.options.sortBy,
                this.state.options.desc,
                this.state.filters,
                true
              ).then((x) => {
                this.setState({
                  items: x,
                  options: GridViewOptionsBuilder.getFetched(this),
                });
              });
            }}
            onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
              UsersService.init();
              const { options } = this.state;
              options.fetched = true;
              UsersService.getUsers(
                this.state.options.sortBy,
                this.state.options.desc,
                filters,
                undefined
              ).then((x) => {
                callback();
                this.setState({
                  items: x,
                  filters: filters,
                  options: GridViewOptionsBuilder.getFetched(this),
                });
              });
              GridViewOptionsBuilder.unfetched(this);
            }}
            onQueryFilters={(reset: boolean) => {
              return new Promise((resolve) => {
                resolve(
                  PreferencesService.getFiltersOrDefault("users", reset, () => [
                    {
                      name: "messages:users:title",
                      fieldName: "name",
                      type: "text",
                      value: "",
                    },
                    /*
                    {
                      name: "messages:users:role",
                      fieldName: "role",
                      type: "choice",
                      value: x,
                      choices: x,
                    },
                    */
                    {
                      name: "messages:users:email",
                      fieldName: "email",
                      type: "text",
                      value: null,
                    },
                  ])
                );
              });
            }}
            items={this.state.items}
            commands={[]}
          />
        </div>
        <div></div>
      </Modal>
    );
  }
}
