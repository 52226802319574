import { Pivot, PivotItem } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { BillingElementsControl } from "../BillingElementsControl";
import { PaymentsElementsControl } from "../PaymentsElementsControl";
import { getBillingControlClassNames } from "./BillingControl.styles";
import {
  IBillingControlProps,
  IBillingControlState,
} from "./BillingControl.types";

export class BillingControlComponent extends React.Component<
  IBillingControlProps,
  IBillingControlState
> {
  render(): JSX.Element {
    const { styles, route, className } = this.props;
    const [classNames] = getBillingControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Pivot
        className={`${className} ${classNames.root}`}
        selectedKey={this.props.routePath}
        onLinkClick={(
          item?: PivotItem,
          _ev?: React.MouseEvent<HTMLElement>
        ) => {
          route.history.push(
            ((item as any).key ?? ".$factures") === ".$factures"
              ? "/facturation/factures"
              : "/facturation/paiements"
          );
        }}
      >
        <PivotItem
          itemKey={"factures"}
          key={"factures"}
          headerText={i18n.t("billing:billing:header")}
        >
          <BillingElementsControl {...this.props} />
        </PivotItem>
        <PivotItem
          itemKey={"paiements"}
          key={"paiements"}
          headerText={i18n.t("billing:payments:header")}
        >
          <PaymentsElementsControl {...this.props} />
        </PivotItem>
      </Pivot>
    );
  }
}
