import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingPassengersGridViewProps } from "./FlightBookingPassengersGridView.types";
import {
  getStyles,
  IFlightBookingPassengersGridViewStyleProps,
  IFlightBookingPassengersGridViewStyles,
} from "./FlightBookingPassengersGridView.styles";
import { FlightBookingPassengersGridViewComponent } from "./FlightBookingPassengersGridView.base";

/**
 * FlightBookingPassengersGridView
 */
export const FlightBookingPassengersGridView = styled<
  IFlightBookingPassengersGridViewProps,
  IFlightBookingPassengersGridViewStyleProps,
  IFlightBookingPassengersGridViewStyles
>(FlightBookingPassengersGridViewComponent, getStyles);
