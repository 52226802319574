import { styled } from "@fluentui/react/lib/Utilities";
import { IPeriodPositionCountTextProps } from "./PeriodPositionCountText.types";
import {
  getStyles,
  IPeriodPositionCountTextStyleProps,
  IPeriodPositionCountTextStyles,
} from "./PeriodPositionCountText.styles";
import { PeriodPositionCountTextComponent } from "./PeriodPositionCountText.base";

/**
 * PeriodPositionCountText description
 */
export const PeriodPositionCountText = styled<
  IPeriodPositionCountTextProps,
  IPeriodPositionCountTextStyleProps,
  IPeriodPositionCountTextStyles
>(PeriodPositionCountTextComponent, getStyles);
