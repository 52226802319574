import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportSummaryControlProps } from "./TransportSummaryControl.types";
import {
  getStyles,
  ITransportSummaryControlStyleProps,
  ITransportSummaryControlStyles,
} from "./TransportSummaryControl.styles";
import { TransportSummaryControlComponent } from "./TransportSummaryControl.base";

/**
 * TransportSummaryControl
 */
export const TransportSummaryControl = styled<
  ITransportSummaryControlProps,
  ITransportSummaryControlStyleProps,
  ITransportSummaryControlStyles
>(TransportSummaryControlComponent, getStyles);
