import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightCheckoutErrorPageProps } from "./FlightCheckoutErrorPage.types";
import {
  getStyles,
  IFlightCheckoutErrorPageStyleProps,
  IFlightCheckoutErrorPageStyles,
} from "./FlightCheckoutErrorPage.styles";
import { FlightCheckoutErrorPageComponent } from "./FlightCheckoutErrorPage.base";

/**
 * FlightCheckoutErrorPage
 */
export const FlightCheckoutErrorPage = styled<
  IFlightCheckoutErrorPageProps,
  IFlightCheckoutErrorPageStyleProps,
  IFlightCheckoutErrorPageStyles
>(FlightCheckoutErrorPageComponent, getStyles);
