import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingBreadcrumbProps } from "./FlightBookingBreadcrumb.types";
import {
  getStyles,
  IFlightBookingBreadcrumbStyleProps,
  IFlightBookingBreadcrumbStyles,
} from "./FlightBookingBreadcrumb.styles";
import { FlightBookingBreadcrumbComponent } from "./FlightBookingBreadcrumb.base";

/**
 * FlightBookingBreadcrumb
 */
export const FlightBookingBreadcrumb = styled<
  IFlightBookingBreadcrumbProps,
  IFlightBookingBreadcrumbStyleProps,
  IFlightBookingBreadcrumbStyles
>(FlightBookingBreadcrumbComponent, getStyles);
