import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDocumentCategoriesSubComponentStyles {}

export interface IDocumentCategoriesStyles
  extends IAmigoBaseStyles<IDocumentCategoriesSubComponentStyles> {}

export interface IDocumentCategoriesStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDocumentCategoriesClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentCategoriesStyleProps,
    IDocumentCategoriesStyles
  >,
  props?: IDocumentCategoriesStyleProps
): [
  IProcessedStyleSet<IDocumentCategoriesStyles>,
  IDocumentCategoriesSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentCategoriesStyleProps,
  IDocumentCategoriesStyles
> = (props: IDocumentCategoriesStyleProps): IDocumentCategoriesStyles => {
  return {
    root: [
      "amigo-DocumentCategories",
      {
        backgroundColor: props.theme.palette.neutralLighter,
        width: "100%",
      },
    ],
  };
};
