import {
  IconButton,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  StackItem,
  TooltipHost,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { IEnterpriseContact } from "src/models/Enterprises/IEnterpriseContact";
import i18n from "src/services/i18n";
import { getContactsModificationListClassNames } from "./ContactsModificationList.styles";
import {
  IContactsModificationListProps,
  IContactsModificationListState,
} from "./ContactsModificationList.types";
import GridViewDialog from "src/components/GridView/GridViewDialog";

export class ContactsModificationListComponent extends React.Component<
  IContactsModificationListProps,
  IContactsModificationListState
> {
  constructor(props: IContactsModificationListProps) {
    super(props);
    this.state = {};
  }

  private onRenderContact = (
    contact: IEnterpriseContact,
    readOnly?: boolean
  ): JSX.Element => {
    const contactSize: PersonaSize = this.props.mobile
      ? PersonaSize.size24
      : PersonaSize.size32;
    return (
      <Stack
        key={`amigo-enterprises-contacts-${contact.key}`}
        style={{ width: "100%" }}
        horizontal
      >
        <StackItem grow>
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            key={contact.key}
            onClick={
              readOnly ?? false
                ? undefined
                : () => {
                    this.props.onContactSelected(contact);
                  }
            }
            styles={{
              root: {
                margin: "20px",
                cursor: "pointer",
              },
            }}
            text={contact.name}
            secondaryText={contact.phone}
            size={contactSize}
            presence={PersonaPresence.none}
            imageAlt={contact.name}
          />
        </StackItem>
        {!(readOnly ?? false) && (
          <StackItem>
            <div style={{ padding: "20px 0" }}>
              <TooltipHost content={i18n.t("enterprises:contacts:delete")}>
                <IconButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={() => {
                    this.setState({
                      contactToDelete: contact,
                    });
                  }}
                />
              </TooltipHost>
            </div>
          </StackItem>
        )}
      </Stack>
    );
  };

  private onRenderNewContact = (contact: IEnterpriseContact): JSX.Element => {
    const contactSize: PersonaSize = this.props.mobile
      ? PersonaSize.size24
      : PersonaSize.size32;
    return (
      <Stack
        key={`amigo-enterprises-contacts-${contact.key}`}
        style={{ width: "100%" }}
        horizontal
      >
        <StackItem grow>
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            key={contact.key}
            onClick={() => {
              this.props.onContactSelected(contact);
            }}
            styles={{
              root: {
                margin: "20px",
                cursor: "pointer",
              },
            }}
            text={contact.name}
            secondaryText={contact.phone}
            size={contactSize}
            presence={PersonaPresence.none}
            imageAlt={contact.name}
          />
        </StackItem>
        <StackItem>
          <div style={{ padding: "20px 0" }}>
            <TooltipHost content={i18n.t("enterprises:contacts:undo")}>
              <IconButton
                iconProps={{ iconName: "Undo" }}
                onClick={() => {
                  this.props.onUndo(contact);
                }}
              />
            </TooltipHost>
          </div>
        </StackItem>
      </Stack>
    );
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getContactsModificationListClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <GridViewDialog
          isConfirming={this.state.contactToDelete !== undefined}
          confirmMessage={i18n.t("enterprises:contacts:delete:confirm")}
          confirmTitle={i18n.t("enterprises:contacts:delete")}
          onDismiss={(result: boolean) => {
            if (result && this.state.contactToDelete)
              this.props.onContactRemoved(this.state.contactToDelete);
            this.setState({ contactToDelete: undefined });
          }}
        />
        {!this.props.contact && (
          <>
            <Stack>
              {this.props.contacts
                .filter((x) => {
                  if (!x.pending && x.contactId >= 0) {
                    return (
                      this.props.edition.filter(
                        (y) => y.id > 0 && x.key === y.key
                      ).length === 0
                    );
                  }
                  return false;
                })
                .map((contact) => {
                  const edit: IEnterpriseContact[] = this.props.contacts.filter(
                    (x) =>
                      x.pending &&
                      Math.abs(x.contactId) === Math.abs(contact.id)
                  );
                  contact = edit.length > 0 ? edit[0] : contact;
                  const el = this.props.edition.filter(
                    (x) => x.id > 0 && contact.key === x.key
                  );

                  const removeDeletedContact =
                    edit[0] != null && edit[0].contactId < 0;
                  if (removeDeletedContact) {
                    return <></>;
                  }

                  return this.onRenderContact(el.length > 0 ? el[0] : contact);
                })}
            </Stack>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text styles={{ root: { fontWeight: 600 } }}>
                Demandes en cours
              </Text>
              <Stack>
                {this.props.contacts
                  .filter((x) => {
                    if (x.pending && x.contactId >= 0) {
                      return (
                        this.props.edition.filter(
                          (y) => y.id > 0 && x.key === y.key
                        ).length === 0
                      );
                    }
                    return false;
                  })
                  .map((contact) => {
                    const edit: IEnterpriseContact[] =
                      this.props.contacts.filter(
                        (x) =>
                          x.pending &&
                          Math.abs(x.contactId) === Math.abs(contact.id)
                      );
                    contact = edit.length > 0 ? edit[0] : contact;
                    const el = this.props.edition.filter(
                      (x) => x.id > 0 && contact.key === x.key
                    );

                    const removeDeletedContact =
                      edit[0] != null && edit[0].contactId < 0;
                    if (removeDeletedContact) {
                      return <></>;
                    }

                    return this.onRenderContact(
                      el.length > 0 ? el[0] : contact,
                      true
                    );
                  })}
              </Stack>
            </Stack>
          </>
        )}
        {!this.props.contact &&
          this.props.edition.filter((x) => x.id < 0).length > 0 && (
            <Stack>
              <h3>{i18n.t("enterprises:contacts:added")}</h3>
              {this.props.edition
                .filter((x) => x.id < 0)
                .map((contact) => {
                  return this.onRenderNewContact(contact);
                })}
            </Stack>
          )}
        {!this.props.contact &&
          this.props.edition.filter((x) => x.id > 0 && x.contactId > 0).length >
            0 && (
            <Stack>
              <h3>{i18n.t("enterprises:contacts:modified")}</h3>
              {this.props.edition
                .filter((x) => x.id > 0 && x.contactId > 0)
                .map((contact) => {
                  return this.onRenderNewContact(contact);
                })}
            </Stack>
          )}
        {!this.props.contact &&
          this.props.edition.filter((x) => x.id > 0 && x.contactId < 0).length >
            0 && (
            <Stack>
              <h3>{i18n.t("enterprises:contacts:deleted")}</h3>
              {this.props.edition
                .filter((x) => x.id > 0 && x.contactId < 0)
                .map((contact) => {
                  return this.onRenderNewContact(contact);
                })}
            </Stack>
          )}
      </div>
    );
  }
}
