import { styled } from "@fluentui/react/lib/Utilities";
import { IDepartureAssistedEditorProps } from "./DepartureAssistedEditor.types";
import {
  getStyles,
  IDepartureAssistedEditorStyleProps,
  IDepartureAssistedEditorStyles,
} from "./DepartureAssistedEditor.styles";
import { DepartureAssistedEditorComponent } from "./DepartureAssistedEditor.base";

/**
 * DepartureAssistedEditor
 */
export const DepartureAssistedEditor = styled<
  IDepartureAssistedEditorProps,
  IDepartureAssistedEditorStyleProps,
  IDepartureAssistedEditorStyles
>(DepartureAssistedEditorComponent, getStyles);
