import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAppEnterpriseMenuSubComponentStyles {}

export interface IAppEnterpriseMenuStyles
  extends IAmigoBaseStyles<IAppEnterpriseMenuSubComponentStyles> {
  iconButton?: IStyle;
}

export interface IAppEnterpriseMenuStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAppEnterpriseMenuClassNames = (
  styles?: IStyleFunctionOrObject<
    IAppEnterpriseMenuStyleProps,
    IAppEnterpriseMenuStyles
  >,
  props?: IAppEnterpriseMenuStyleProps
): [
  IProcessedStyleSet<IAppEnterpriseMenuStyles>,
  IAppEnterpriseMenuSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAppEnterpriseMenuStyleProps,
  IAppEnterpriseMenuStyles
> = (props: IAppEnterpriseMenuStyleProps): IAppEnterpriseMenuStyles => {
  return {
    iconButton: props.tokens.defaultNavBarButton,
  };
};
