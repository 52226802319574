import { Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getFlightPersonalizedOfferDetailsClassNames } from "./FlightPersonalizedOfferDetails.styles";
import {
  IFlightPersonalizedOfferDetailsState,
  IFlightPersonalizedOfferDetailsProps,
} from "./FlightPersonalizedOfferDetails.types";

export class FlightPersonalizedOfferDetailsComponent extends React.Component<
  IFlightPersonalizedOfferDetailsProps,
  IFlightPersonalizedOfferDetailsState
> {
  constructor(props: IFlightPersonalizedOfferDetailsProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, items } = this.props;
    // , itemKey,
    const [classNames /* , subComponentStyles */] =
      getFlightPersonalizedOfferDetailsClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <Stack grow className={classNames.root}>
        {items.map((x, i) => (
          <GridViewCard
            key={i.toString()}
            {...this.props}
            styles={{
              root: {
                width: "100%!important",
                maxWidth: "100%!important",
              },
            }}
            title={x.details
              ?.split(";")
              .filter((_x: string, i: number) => i === 0)
              .map((x: string) => x)}
            icons={<div></div>}
            columnWidth={this.props.mobile ? 311 : 411}
            selectorStyle={{
              display: "none",
            }}
            onClick={() => {
              this.props.onSelected && this.props.onSelected(x);
            }}
            selectable={false}
            onSelected={
              this.props.onSelected !== undefined
                ? () => {
                    this.props.onSelected && this.props.onSelected(x);
                  }
                : undefined
            }
          >
            <Stack horizontal>
              <StackItem grow>
                <Stack
                  tokens={{ childrenGap: 10 }}
                  styles={{
                    root: { margin: this.props.mobile ? "5px" : "10px" },
                  }}
                >
                  <LabelField
                    {...this.props}
                    styles={{
                      subComponentStyles: {},
                    }}
                    label={"Prix total"}
                    content={<h4>{i18n.currency(x.totalprice)}</h4>}
                  />
                  <LabelField
                    {...this.props}
                    styles={undefined}
                    label={"Passagers"}
                    content={x.passengers}
                  />
                  <LabelField
                    {...this.props}
                    styles={undefined}
                    label={"Prix unitaire"}
                    content={i18n.currency(x.unitprice)}
                  />
                </Stack>
              </StackItem>
              <StackItem grow>
                <Stack tokens={{ childrenGap: 10 }}>
                  {x.details
                    ?.split(";")
                    .filter((_x: string, i: number) => i > 0)
                    .map((x: string, i: number) => (
                      <div
                        key={i.toString()}
                        style={{
                          fontSize: "14px",
                          fontWeight: i % 2 === 0 ? 600 : 400,
                        }}
                      >
                        {x}
                      </div>
                    ))}
                </Stack>
              </StackItem>
            </Stack>
          </GridViewCard>
        ))}
      </Stack>
    );
  }
}
