import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IDepartureAssistedParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IIconStyles;
}

export interface IDepartureAssistedParticipantCardStyles
  extends IAmigoBaseStyles<IDepartureAssistedParticipantCardSubComponentStyles> {}

export interface IDepartureAssistedParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  hasErrors: boolean;
}

export const getDepartureAssistedParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IDepartureAssistedParticipantCardStyleProps,
    IDepartureAssistedParticipantCardStyles
  >,
  props?: IDepartureAssistedParticipantCardStyleProps
): [
  IProcessedStyleSet<IDepartureAssistedParticipantCardStyles>,
  IDepartureAssistedParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDepartureAssistedParticipantCardStyleProps,
  IDepartureAssistedParticipantCardStyles
> = (
  props: IDepartureAssistedParticipantCardStyleProps
): IDepartureAssistedParticipantCardStyles => {
  return {
    root: ["amigo-DepartureAssistedParticipantCard", {}],
    subComponentStyles: {
      topIcon: props.hasErrors
        ? {
            root: {
              color: props.theme.palette.redDark,
            },
          }
        : undefined,
      gridViewCard: {
        root: {
          width: "95%",
          selectors: {
            [MediaQueries.mobile]: {
              width: "95%",
            },
            [MediaQueries.small]: {
              width: "97%",
            },
          },
        },
      },
    },
  };
};
