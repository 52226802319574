import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportRequestsArrivalSectionProps } from "./TransportRequestsArrivalSection.types";
import {
  getStyles,
  ITransportRequestsArrivalSectionStyleProps,
  ITransportRequestsArrivalSectionStyles,
} from "./TransportRequestsArrivalSection.styles";
import { TransportRequestsArrivalSectionComponent } from "./TransportRequestsArrivalSection.base";

/**
 * TransportRequestsArrivalSection
 */
export const TransportRequestsArrivalSection = styled<
  ITransportRequestsArrivalSectionProps,
  ITransportRequestsArrivalSectionStyleProps,
  ITransportRequestsArrivalSectionStyles
>(TransportRequestsArrivalSectionComponent, getStyles);
