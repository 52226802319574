import axios from "axios";
import { IDossierImmigrationFileDetails } from "../../models/DossierImmigration/IDossierImmigrationFileDetails";
import AuthService from "../AuthService";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import { IDossierImmigrationFile } from "src/models/DossierImmigration/IDossierImmigrationFile";
import DocumentService from "../Documents/DocumentService";

class DossierImmigrationService {
  private _dossierImmigration?: IDossierImmigrationFile[] = undefined;
  private _dossierImmigrationPages: number = 1;

  public init(): void {
    this._dossierImmigration = undefined;
    this._dossierImmigrationPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("name", "Id")
      .replace("title", "Titre")
      .replace("agentLabel", "AgentAttitreCode")
      .replace("agent", "AgentAttitreCode")
      .replace("openedOn", "DateCreationDossier")
      .replace("createdOn", "CreerLe")
      .replace("country", "Pays.Description")
      .replace("namesRequestDate", "DateDemandeNom")
      .replace("namesReceptionDate", "DateReceptionNom")
      .replace("transmissionDate", "DateDemandeServiceCanada")
      .replace("year", "Annee")
      .replace("participantCount", "NombreTravailleur");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    if (filters === undefined) return [];
    return filters
      .filter((x) => {
        return (
          (x.type === "text" &&
            x.value !== undefined &&
            x.value != null &&
            x.value.toString().length > 0) ||
          (x.type === "choice" &&
            x.value !== undefined &&
            x.choices !== undefined &&
            x.choices.length > (x.value as IChoiceValue[]).length) ||
          (x.type === "date" && x.value !== undefined) ||
          (x.type === "null" &&
            x.value !== undefined &&
            (x.value as IChoiceValue).id === 0)
        );
      })
      .map((x) => {
        return {
          fieldName: this.translateSortBy(x.fieldName).replace(
            ".Description",
            ".Id"
          ),
          name: x.name,
          type: x.type,
          textValue: x.type === "text" ? x.value : null,
          choiceValue:
            x.type === "null"
              ? [(x.value as IChoiceValue).id.toString()]
              : x.type === "choice"
              ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
              : null,
          dateValue: x.type === "date" ? x.value : null,
        };
      });
  };

  public getDossierImmigrationFiles(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IDossierImmigrationFile[]> {
    const _this = this;
    let currentPage = this._dossierImmigrationPages;
    let combine: boolean;
    if (next && _this._dossierImmigration !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `api/enterprises/${enterpriseId}/dossierimmigration`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._dossierImmigration !== undefined) {
          _this._dossierImmigrationPages = currentPage;
          _this._dossierImmigration = _this._dossierImmigration.concat(items);
        } else {
          _this._dossierImmigrationPages = 1;
          _this._dossierImmigration = this._dossierImmigration = items;
        }
        return _this._dossierImmigration ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this._dossierImmigration ?? [];
      });
  }

  public export(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ) {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/dossierimmigration/reports/export-excel`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 1,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }

  public getDossierImmigrationFile(
    enterpriseId: number,
    fileId: number
  ): Promise<IDossierImmigrationFileDetails> {
    return axios
      .post(
        `api/enterprises/${enterpriseId}/dossierimmigration/${fileId}`,
        {},
        AuthService.getAuthHeader()
      )
      .then((x) => x.data as any);
  }
}

export default new DossierImmigrationService();
