import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentDetailInfoProps } from "./PaymentDetailInfo.types";
import {
  getStyles,
  IPaymentDetailInfoStyleProps,
  IPaymentDetailInfoStyles,
} from "./PaymentDetailInfo.styles";
import { PaymentDetailInfoComponent } from "./PaymentDetailInfo.base";

/**
 * PaymentDetailInfo
 */
export const PaymentDetailInfo = styled<
  IPaymentDetailInfoProps,
  IPaymentDetailInfoStyleProps,
  IPaymentDetailInfoStyles
>(PaymentDetailInfoComponent, getStyles);
