import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  INewPaymentCommandBarStyleProps,
  INewPaymentCommandBarStyles,
} from "../Controls";
import {
  INewPaymentBillsStyleProps,
  INewPaymentBillsStyles,
} from "../NewPaymentsBills";

export interface INewPaymentPageSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  commandBar?: IStyleFunctionOrObject<
    INewPaymentCommandBarStyleProps,
    INewPaymentCommandBarStyles
  >;
  newPaymentBills?: IStyleFunctionOrObject<
    INewPaymentBillsStyleProps,
    INewPaymentBillsStyles
  >;
}

export interface INewPaymentPageStyles
  extends IAmigoBaseStyles<INewPaymentPageSubComponentStyles> {}

export interface INewPaymentPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentPageClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentPageStyleProps,
    INewPaymentPageStyles
  >,
  props?: INewPaymentPageStyleProps
): [
  IProcessedStyleSet<INewPaymentPageStyles>,
  INewPaymentPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentPageStyleProps,
  INewPaymentPageStyles
> = (props: INewPaymentPageStyleProps): INewPaymentPageStyles => {
  return {
    root: ["amigo-NewPaymentPage", props.tokens.defaultPageRootStyle],
  };
};
