import { styled } from "@fluentui/react/lib/Utilities";
import { IUserCalloutProps } from "./UserCallout.types";
import {
  getStyles,
  IUserCalloutStyleProps,
  IUserCalloutStyles,
} from "./UserCallout.styles";
import { UserCalloutComponent } from "./UserCallout.base";

/**
 * UserCallout description
 */
export const UserCallout = styled<
  IUserCalloutProps,
  IUserCalloutStyleProps,
  IUserCalloutStyles
>(UserCalloutComponent, getStyles);
