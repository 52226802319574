import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantWorkInfoProps } from "./ParticipantWorkInfo.types";
import {
  getStyles,
  IParticipantWorkInfoStyleProps,
  IParticipantWorkInfoStyles,
} from "./ParticipantWorkInfo.styles";
import { ParticipantWorkInfoComponent } from "./ParticipantWorkInfo.base";

/**
 * ParticipantWorkInfo description
 */
export const ParticipantWorkInfo = styled<
  IParticipantWorkInfoProps,
  IParticipantWorkInfoStyleProps,
  IParticipantWorkInfoStyles
>(ParticipantWorkInfoComponent, getStyles);
