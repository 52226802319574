import i18n from "src/services/i18n";
import { ISchemaObject } from "src/utils/validator";
import { ITransportArrivalRequestParticipant } from "./ITransportArrivalRequestParticipant";

export interface INewTransportArrivalRequest {
  id: number;
  key: string;
  itemId: number;
  enterpriseId: number;
  requestId?: number;
  requestNumber?: string;
  requestDate?: string;
  city?: number;
  cityName?: string;
  custom?: string;
  isCustom: boolean;
  participants: ITransportArrivalRequestParticipant[];
  errors: string[];
}

export const NewTransportArrivalRequestSchema = (
  custom: boolean
): ISchemaObject => {
  return {
    name: "transportarrival",
    plural: "transportarrivals",
    label: "TransportArrival",
    fields: custom
      ? {
          requestId: {
            name: "requestId",
            label: i18n.t("messages:assisted:newarrival:requestnumber"),
            required: true,
            pattern: "",
            errorMessage: i18n.t(
              "messages:assisted:newarrival:requestnumber:error"
            ),
            type: "number",
          },
          custom: {
            name: "custom",
            label: i18n.t("messages:assisted:newarrival:city"),
            required: true,
            pattern: "",
            errorMessage: i18n.t("messages:assisted:newarrival:custom:error"),
            type: "string",
          },
          requestDate: {
            name: "requestDate",
            label: i18n.t("messages:assisted:newarrival:requestdate"),
            required: true,
            pattern: "",
            errorMessage: i18n.t(
              "messages:assisted:newarrival:requestdate:error"
            ),
            type: "string",
          },
        }
      : {
          requestId: {
            name: "requestId",
            label: i18n.t("messages:assisted:newarrival:requestnumber"),
            required: true,
            pattern: "",
            errorMessage: i18n.t(
              "messages:assisted:newarrival:requestnumber:error"
            ),
            type: "number",
          },
          city: {
            name: "city",
            label: i18n.t("messages:assisted:newarrival:city"),
            required: true,
            pattern: "",
            errorMessage: i18n.t("messages:assisted:newarrival:city:error"),
            type: "number",
          },
          requestDate: {
            name: "requestDate",
            label: i18n.t("messages:assisted:newarrival:requestdate"),
            required: true,
            pattern: "",
            errorMessage: i18n.t(
              "messages:assisted:newarrival:requestdate:error"
            ),
            type: "string",
          },
        },
  };
};
