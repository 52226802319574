import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface IUnauthenticatedLayoutSubComponentStyles {}

export interface IUnauthenticatedLayoutStyles
  extends IAmigoBaseStyles<IUnauthenticatedLayoutSubComponentStyles> {
  logo?: IStyle;
  pageContainer?: IStyle;
  pageControl?: IStyle;
  backgroundLayer?: IStyle;
  background?: IStyle;
  backgroundImage?: IStyle;
}

export interface IUnauthenticatedLayoutStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getUnauthenticatedLayoutClassNames = (
  styles?: IStyleFunctionOrObject<
    IUnauthenticatedLayoutStyleProps,
    IUnauthenticatedLayoutStyles
  >,
  props?: IUnauthenticatedLayoutStyleProps
): [
  IProcessedStyleSet<IUnauthenticatedLayoutStyles>,
  IUnauthenticatedLayoutSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUnauthenticatedLayoutStyleProps,
  IUnauthenticatedLayoutStyles
> = (props: IUnauthenticatedLayoutStyleProps): IUnauthenticatedLayoutStyles => {
  return {
    root: [
      "amigo-UnauthenticatedLayout",
      {
        backgroundColor: props.theme.palette.neutralLighter,
      },
    ],
    logo: [
      "amigo-UnauthenticatedLayout-Logo",
      {
        zIndex: 1000,
        position: "absolute",
        top: 0,
        left: 0,
        selectors: {
          [MediaQueries.mobile]: {
            position: "absolute",
            right: 0,
            left: "unset",
          },
        },
      },
    ],
    background: [
      "amigo-UnauthenticatedLayout-Background",
      {
        position: "fixed",
        top: 0,
        width: "100%",
        height: "100%",
      },
    ],
    backgroundImage: [
      "amigo-UnauthenticatedLayout-BackgroundImage",
      {
        width: "100%",
        height: "100%",
        backgroundRepeat: "no-repeat, no-repeat",
        backgroundPosition: "center center, center center",
        backgroundSize: "cover, cover",
        backgroundImage: "url('/img/Slide-Employeur.jpg')",
        animation: "fadeIn 1s",
      },
    ],
  };
};
