import { styled } from "@fluentui/react/lib/Utilities";
import { IBillingPageProps } from "./BillingPage.types";
import {
  getStyles,
  IBillingPageStyleProps,
  IBillingPageStyles,
} from "./BillingPage.styles";
import { BillingPageComponent } from "./BillingPage.base";

/**
 * BillingPage description
 */
export const BillingPage = styled<
  IBillingPageProps,
  IBillingPageStyleProps,
  IBillingPageStyles
>(BillingPageComponent, getStyles);
