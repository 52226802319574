import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IFlightPersonalizedOfferDetailsSubComponentStyles {}

export interface IFlightPersonalizedOfferDetailsStyles
  extends IAmigoBaseStyles<IFlightPersonalizedOfferDetailsSubComponentStyles> {}

export interface IFlightPersonalizedOfferDetailsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightPersonalizedOfferDetailsClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightPersonalizedOfferDetailsStyleProps,
    IFlightPersonalizedOfferDetailsStyles
  >,
  props?: IFlightPersonalizedOfferDetailsStyleProps
): [
  IProcessedStyleSet<IFlightPersonalizedOfferDetailsStyles>,
  IFlightPersonalizedOfferDetailsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightPersonalizedOfferDetailsStyleProps,
  IFlightPersonalizedOfferDetailsStyles
> = (
  _props: IFlightPersonalizedOfferDetailsStyleProps
): IFlightPersonalizedOfferDetailsStyles => {
  return {
    root: [
      "amigo-FlightPersonalizedOfferDetails",
      {
        width: "100%",
        overflow: "hidden",
      },
    ],
  };
};
