import { styled } from "@fluentui/react/lib/Utilities";
import { ILabelFieldProps } from "./LabelField.types";
import {
  getStyles,
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "./LabelField.styles";
import { LabelFieldComponent } from "./LabelField.base";

/**
 * LabelField description
 */
export const LabelField = styled<
  ILabelFieldProps,
  ILabelFieldStyleProps,
  ILabelFieldStyles
>(LabelFieldComponent, getStyles);
