import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentClientInfoProps } from "./NewPaymentClientInfo.types";
import {
  getStyles,
  INewPaymentClientInfoStyleProps,
  INewPaymentClientInfoStyles,
} from "./NewPaymentClientInfo.styles";
import { NewPaymentClientInfoComponent } from "./NewPaymentClientInfo.base";

/**
 * NewPaymentClientInfo description
 */
export const NewPaymentClientInfo = styled<
  INewPaymentClientInfoProps,
  INewPaymentClientInfoStyleProps,
  INewPaymentClientInfoStyles
>(NewPaymentClientInfoComponent, getStyles);
