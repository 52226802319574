import {
  MessageBar,
  MessageBarType,
  Separator,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import { InspectionRequestService } from "src/services/Messages/InspectionRequestService";
import { getInspectionAssistedControlClassNames } from "./InspectionAssistedControl.styles";
import {
  IInspectionAssistedControlProps,
  IInspectionAssistedControlState,
} from "./InspectionAssistedControl.types";

export class InspectionAssistedControlComponent extends React.Component<
  IInspectionAssistedControlProps,
  IInspectionAssistedControlState
> {
  constructor(props: IInspectionAssistedControlProps) {
    super(props);
    this.state = {};
    this.getInspectionRequest();
  }

  componentDidUpdate() {
    if (
      this.state.error === undefined &&
      (!this.state.request ||
        !this.state.request.itemId ||
        this.props.message.itemId !== this.state.request.itemId)
    ) {
      this.getInspectionRequest();
    }
  }

  private getInspectionRequest = () => {
    this.state.error === undefined &&
      InspectionRequestService.getInspectionRequest(
        objectId(this.props.enterprise),
        this.props.message.itemId
      )
        .then((x) => {
          if (x === undefined) {
            this.setState({
              error: "error",
            });
          } else {
            this.setState({
              request: x,
            });
          }
        })
        .catch(() => {
          this.setState({
            error: "error",
          });
        });
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getInspectionAssistedControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { error, request } = this.state;
    return (
      <>
        {error && (
          <MessageBar messageBarType={MessageBarType.info}>
            {i18n.t("messages:assisted:inspection:unavailable")}
          </MessageBar>
        )}
        {request && (
          <div className={classNames.root}>
            {request.addresses.map((address, index) => (
              <Stack key={index.toString()} tokens={{ childrenGap: 10 }}>
                <Stack horizontal={!mobile} tokens={{ childrenGap: 10 }}>
                  <StackItem grow={mobile ? true : 2}>
                    <IconHeader
                      {...this.props}
                      as={"h3"}
                      styles={{
                        root: {
                          marginTop: 0,
                        },
                        text: {
                          marginTop: "10px!important",
                          marginBottom: "0!important",
                        },
                      }}
                      iconName="MapPin"
                      title={
                        request.addresses.length > 1
                          ? `${i18n.t(
                              "messages:assisted:inspection:address"
                            )} #${index + 1}`
                          : i18n.t("messages:assisted:inspection:address")
                      }
                    />
                    <Stack
                      grow
                      horizontal={!mobile}
                      tokens={{ childrenGap: 10 }}
                    >
                      {index === 0 && (
                        <StackItem grow={1}>
                          <LabelField
                            {...this.props}
                            styles={{
                              root: {
                                marginTop: "5px",
                              },
                            }}
                            label={i18n.t(
                              "messages:assisted:inspection:enterprise"
                            )}
                            content={
                              <div style={{ marginTop: "4px" }}>
                                <Text>{`${
                                  request?.memberId
                                    ? "(" + request.memberId + ")"
                                    : ""
                                }${request?.enterpriseName}`}</Text>
                              </div>
                            }
                          />
                        </StackItem>
                      )}
                      {request?.branchName && request.branchName.length > 0 && (
                        <StackItem grow={2}>
                          <LabelField
                            {...this.props}
                            styles={undefined}
                            label={i18n.t(
                              "messages:assisted:inspection:branch"
                            )}
                            content={request?.branchName ?? ""}
                          />
                        </StackItem>
                      )}
                    </Stack>
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:inspection:street")}
                      content={address.address.street ?? ""}
                    />
                    {mobile && (
                      <LabelField
                        {...this.props}
                        styles={undefined}
                        label={i18n.t("messages:assisted:inspection:city")}
                        content={address.address.city ?? ""}
                      />
                    )}
                    <Stack grow horizontal={true} tokens={{ childrenGap: 10 }}>
                      <StackItem grow>
                        <LabelField
                          {...this.props}
                          styles={undefined}
                          label={i18n.t(
                            "messages:assisted:inspection:postalcode"
                          )}
                          content={address.address.postalcode ?? ""}
                        />
                      </StackItem>
                      {!mobile && (
                        <StackItem grow>
                          <LabelField
                            {...this.props}
                            styles={undefined}
                            label={i18n.t("messages:assisted:inspection:city")}
                            content={address.address.city ?? ""}
                          />
                        </StackItem>
                      )}
                      <StackItem grow>
                        <LabelField
                          {...this.props}
                          styles={undefined}
                          label={i18n.t("messages:assisted:inspection:state")}
                          content={address.address.state ?? ""}
                        />
                      </StackItem>
                    </Stack>

                    <Stack horizontal>
                      <LabelField
                              {...this.props}
                              styles={undefined}
                              label={i18n.t("messages:assisted:inspection:askifishousingowner")}
                              content={address.isHousingOwner? i18n.t("global:yes") : i18n.t("global:no")}
                            />
                      &nbsp;
                      <LabelField
                              {...this.props}
                              styles={undefined}
                              isHidden={!address.isHousingOwner}
                              label={i18n.t("messages:assisted:inspection:askbedcount")}
                              content={address.bedCount ?? 0}
                            />
                      &nbsp;
                      <LabelField
                              {...this.props}
                              styles={undefined}
                              isHidden={!address.isHousingOwner}
                              label={i18n.t("messages:assisted:inspection:askifrenttootheremployer")}
                              content={address.isRentedToOtherEmployers? i18n.t("global:yes") : i18n.t("global:no")}
                            />
                      <LabelField
                              {...this.props}
                              styles={undefined}
                              isHidden={address.isHousingOwner}
                              label={i18n.t("messages:assisted:inspection:askownerfullname")}
                              content={address.ownerFullName}
                            />
                    </Stack>
                  </StackItem>
                  {mobile && <Separator />}
                  <StackItem grow={mobile ? true : 1}>
                    <Stack tokens={{ childrenGap: 10 }}>
                      <IconHeader
                        {...this.props}
                        as={"h4"}
                        styles={{
                          root: {
                            marginTop: 0,
                          },
                          text: {
                            marginTop: "10px!important",
                            marginBottom: "0!important",
                          },
                        }}
                        iconName="ProductList"
                        title={i18n.t("messages:assisted:inspection:details")}
                      />
                      <LabelField
                        {...this.props}
                        styles={undefined}
                        label={i18n.t("messages:assisted:inspection:building")}
                        content={(address.buildingCount ?? 0).toString()}
                      />
                      <LabelField
                        {...this.props}
                        styles={undefined}
                        label={i18n.t("messages:assisted:inspection:workers")}
                        content={(address.participantsCount ?? 0).toString()}
                      />
                      {!mobile && <div style={{ height: "59px" }}></div>}
                    </Stack>
                  </StackItem>
                </Stack>
              </Stack>
            ))}

            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal={!mobile} tokens={{ childrenGap: 10 }}>
                <StackItem grow={mobile ? true : 2}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <IconHeader
                      {...this.props}
                      as={"h4"}
                      styles={{
                        root: {
                          marginTop: mobile ? 0 : "-10px",
                        },
                        text: {
                          marginTop: "10px!important",
                          marginBottom: "0!important",
                        },
                      }}
                      iconName="ConnectContacts"
                      title={i18n.t("messages:assisted:inspection:contact")}
                    />
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:inspection:contactname")}
                      content={request?.contactName ?? ""}
                    />
                    <Stack
                      horizontal={!mobile}
                      grow
                      tokens={{ childrenGap: 10 }}
                    >
                      <StackItem grow>
                        <LabelField
                          {...this.props}
                          styles={undefined}
                          label={i18n.t("messages:assisted:inspection:phone")}
                          content={request?.phone ?? ""}
                        />
                      </StackItem>
                      <StackItem grow>
                        <LabelField
                          {...this.props}
                          styles={undefined}
                          label={i18n.t("messages:assisted:inspection:email")}
                          content={request?.email ?? ""}
                        />
                      </StackItem>
                    </Stack>
                  </Stack>
                </StackItem>
                {mobile && <Separator />}
                <StackItem grow={mobile ? true : 1}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <IconHeader
                      {...this.props}
                      as={"h4"}
                      styles={{
                        root: {
                          marginTop: 0,
                        },
                        text: {
                          marginTop: "10px!important",
                          marginBottom: "-1px!important",
                        },
                      }}
                      iconName="Calendar"
                      title={i18n.t("messages:assisted:inspection:dates")}
                    />
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t(
                        "messages:assisted:inspection:estimateddeposit"
                      )}
                      content={this.state.request?.estimatedDeposit ?? ""}
                    />
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t(
                        "messages:assisted:inspection:lastinspection"
                      )}
                      content={this.state.request?.lastInspectionDate ?? ""}
                    />
                  </Stack>
                </StackItem>
              </Stack>

              <LabelField
                              {...this.props}
                              styles={undefined}
                              label={i18n.t("messages:assisted:inspection:askiftakeworkerextraferme")}
                              content={request.takeForeignerWorkersOutOfFerme? i18n.t("global:yes") : i18n.t("global:no")}
                            />
              <div style={{ height: "20px" }}></div>
            </Stack>
          </div>
        )}
      </>
    );
  }
}

/* 
//TODO: Just a copy of message???
<Separator />
          <LabelField
            {...this.props}
            styles={undefined}
            label={i18n.t("messages:assisted:inspection:comments")}
            content={
              <span
                dangerouslySetInnerHTML={{
                  __html: this.state.request?.comments ?? "",
                }}
              ></span>
            }
          />
          */
