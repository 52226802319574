import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportRequestsArrivalSectionSubComponentStyles {}

export interface ITransportRequestsArrivalSectionStyles
  extends IAmigoBaseStyles<ITransportRequestsArrivalSectionSubComponentStyles> {}

export interface ITransportRequestsArrivalSectionStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportRequestsArrivalSectionClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportRequestsArrivalSectionStyleProps,
    ITransportRequestsArrivalSectionStyles
  >,
  props?: ITransportRequestsArrivalSectionStyleProps
): [
  IProcessedStyleSet<ITransportRequestsArrivalSectionStyles>,
  ITransportRequestsArrivalSectionSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportRequestsArrivalSectionStyleProps,
  ITransportRequestsArrivalSectionStyles
> = (
  _props: ITransportRequestsArrivalSectionStyleProps
): ITransportRequestsArrivalSectionStyles => {
  return {
    root: ["amigo-TransportRequestsArrivalSection", {}],
  };
};
