import { styled } from "@fluentui/react/lib/Utilities";
import { IPositionPanelProps } from "./PositionPanel.types";
import {
  getStyles,
  IPositionPanelStyleProps,
  IPositionPanelStyles,
} from "./PositionPanel.styles";
import { PositionPanelComponent } from "./PositionPanel.base";

/**
 * PositionPanel
 */
export const PositionPanel = styled<
  IPositionPanelProps,
  IPositionPanelStyleProps,
  IPositionPanelStyles
>(PositionPanelComponent, getStyles);
