import { Icon, Stack } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import moment from "moment";
import * as React from "react";
import { IFlightOffer } from "src/models/Transports/IFlightOffer";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { FlightOfferDetails } from "../FlightOfferDetails";
import { FlightSearchOffsetOfferCard } from "../FlightSearchOffsetOfferCard";
import { getFlightSearchOffsetOffersClassNames } from "./FlightSearchOffsetOffers.styles";
import {
  IFlightSearchOffsetOffersProps,
  IFlightSearchOffsetOffersState,
} from "./FlightSearchOffsetOffers.types";

export class FlightSearchOffsetOffersComponent extends React.Component<
  IFlightSearchOffsetOffersProps,
  IFlightSearchOffsetOffersState
> {
  constructor(props: IFlightSearchOffsetOffersProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, mobile, flexibles, criteria, onOfferSelected } = this.props;
    const [classNames] = getFlightSearchOffsetOffersClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    if (!criteria.flexible || flexibles.length === 0) return <div></div>;
    const before =
      (flexibles[0]?.itineraryGroups[0]?.itineraries?.length ?? 0) > 0
        ? flexibles[0]
        : flexibles[2];

    const beforeServiceIndex =
      (flexibles[0]?.itineraryGroups[0]?.itineraries?.length ?? 0) > 0 ? 0 : 2;

    const after =
      (flexibles[1]?.itineraryGroups[0]?.itineraries?.length ?? 0) > 0
        ? flexibles[1]
        : flexibles[3];

    const afterServiceIndex =
      (flexibles[1]?.itineraryGroups[0]?.itineraries?.length ?? 0) > 0 ? 1 : 3;

    const beforeOffer =
      criteria.searchDates &&
      moment(criteria.searchDates.start).diff(
        moment(new Date()).add("d", 1),
        "d"
      ) > 1 &&
      before &&
      before.itineraryGroups[0].itineraries.length > 0
        ? TransportFlightSearchService.getOffer(before, 0, 0)
        : undefined;

    const afterOffer =
      after && after.itineraryGroups[0].itineraries.length > 0
        ? TransportFlightSearchService.getOffer(after, 0, 0)
        : undefined;

    const beforeDate = beforeOffer
      ? moment(
          before.itineraryGroups[0].groupDescription.legDescriptions[0]
            .departureDate + " 00:00:00"
        ).toDate()
      : new Date();

    const afterDate = afterOffer
      ? moment(
          after.itineraryGroups[0].groupDescription.legDescriptions[0]
            .departureDate + " 00:00:00"
        ).toDate()
      : new Date();

    return beforeOffer || afterOffer ? (
      <div className={classNames.root}>
        <Card
          horizontal
          tokens={{ childrenGap: mobile ? 10 : 20 }}
          style={{
            width: "100%",
            textAlign: "left",
            maxWidth: "100%",
            padding: "8px",
            backgroundColor: this.props.theme.palette.white,
          }}
        >
          <Card.Section>
            <Icon iconName={"BufferTimeBefore"} />
          </Card.Section>
          <Card.Section>
            <Stack horizontal tokens={{ childrenGap: 10 }} grow>
              <FlightSearchOffsetOfferCard
                {...this.props}
                styles={undefined}
                offer={beforeOffer}
                offerDate={beforeDate}
                searchDate={criteria.searchDates?.start ?? new Date()}
                onSelected={(item: IFlightOffer) => {
                  this.setState({
                    offer: item,
                    serviceIndex: beforeServiceIndex,
                    data: flexibles,
                  });
                }}
              />
              <FlightSearchOffsetOfferCard
                {...this.props}
                styles={undefined}
                offer={afterOffer}
                offerDate={afterDate}
                searchDate={criteria.searchDates?.start ?? new Date()}
                onSelected={(item: IFlightOffer) => {
                  this.setState({
                    offer: item,
                    serviceIndex: afterServiceIndex,
                    data: flexibles,
                  });
                }}
              />
            </Stack>
            <div>
              {this.state.serviceIndex !== undefined &&
                this.state.offer !== undefined &&
                this.state.data !== undefined && (
                  <FlightOfferDetails
                    {...this.props}
                    styles={undefined}
                    booking={false}
                    offer={this.state.offer}
                    offerIndex={this.state.serviceIndex ?? 0}
                    serviceIndex={0}
                    data={this.state.data}
                    flexible={true}
                    onOfferSelected={onOfferSelected}
                  />
                )}
            </div>
          </Card.Section>
          <Card.Section>
            <div style={{ width: "25px" }}></div>
          </Card.Section>
        </Card>
      </div>
    ) : (
      <></>
    );
  }
}
