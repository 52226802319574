import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface ITransportArrivalAssistedControlSubComponentStyles {}

export interface ITransportArrivalAssistedControlStyles
  extends IAmigoBaseStyles<ITransportArrivalAssistedControlSubComponentStyles> {
  nameText?: IStyle;
}

export interface ITransportArrivalAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportArrivalAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportArrivalAssistedControlStyleProps,
    ITransportArrivalAssistedControlStyles
  >,
  props?: ITransportArrivalAssistedControlStyleProps
): [
  IProcessedStyleSet<ITransportArrivalAssistedControlStyles>,
  ITransportArrivalAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportArrivalAssistedControlStyleProps,
  ITransportArrivalAssistedControlStyles
> = (
  _props: ITransportArrivalAssistedControlStyleProps
): ITransportArrivalAssistedControlStyles => {
  return {
    root: {
      maxWidth: "500px",
    },
  };
};
