import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ICommunicationTypeSelectorSubComponentStyles {}

export interface ICommunicationTypeSelectorStyles
  extends IAmigoBaseStyles<ICommunicationTypeSelectorSubComponentStyles> {}

export interface ICommunicationTypeSelectorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getCommunicationTypeSelectorClassNames = (
  styles?: IStyleFunctionOrObject<
    ICommunicationTypeSelectorStyleProps,
    ICommunicationTypeSelectorStyles
  >,
  props?: ICommunicationTypeSelectorStyleProps
): [
  IProcessedStyleSet<ICommunicationTypeSelectorStyles>,
  ICommunicationTypeSelectorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ICommunicationTypeSelectorStyleProps,
  ICommunicationTypeSelectorStyles
> = (
  _props: ICommunicationTypeSelectorStyleProps
): ICommunicationTypeSelectorStyles => {
  return {
    root: ["amigo-CommunicationTypeSelector", {}],
  };
};
