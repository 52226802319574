import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAssistedMessageControlSubComponentStyles {}

export interface IAssistedMessageControlStyles
  extends IAmigoBaseStyles<IAssistedMessageControlSubComponentStyles> {}

export interface IAssistedMessageControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAssistedMessageControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IAssistedMessageControlStyleProps,
    IAssistedMessageControlStyles
  >,
  props?: IAssistedMessageControlStyleProps
): [
  IProcessedStyleSet<IAssistedMessageControlStyles>,
  IAssistedMessageControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAssistedMessageControlStyleProps,
  IAssistedMessageControlStyles
> = (
  _props: IAssistedMessageControlStyleProps
): IAssistedMessageControlStyles => {
  return {};
};
