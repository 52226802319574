import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  ITextFieldStyleProps,
  ITextFieldStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IContactModificationFormSubComponentStyles {
  textbox?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
  checkbox?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
}

export interface IContactModificationFormStyles
  extends IAmigoBaseStyles<IContactModificationFormSubComponentStyles> {}

export interface IContactModificationFormStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getContactModificationFormClassNames = (
  styles?: IStyleFunctionOrObject<
    IContactModificationFormStyleProps,
    IContactModificationFormStyles
  >,
  props?: IContactModificationFormStyleProps
): [
  IProcessedStyleSet<IContactModificationFormStyles>,
  IContactModificationFormSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IContactModificationFormStyleProps,
  IContactModificationFormStyles
> = (
  _props: IContactModificationFormStyleProps
): IContactModificationFormStyles => {
  return {
    subComponentStyles: {
      textbox: {
        root: {
          paddingBottom: "8px",
        },
      },
      checkbox: {
        root: {
          paddingTop: "10px",
          paddingBottom: "10px",
        },
      },
    },
  };
};
