import axios from "axios";
import Moment from "moment";
import { ITransportArrivalRequest } from "src/models/Transports/ITransportArrivalRequest";
import { ITransportDepartureRequest } from "src/models/Transports/ITransportDepartureRequest";
import { ITransportRequestDetails } from "src/models/Transports/ITransportRequestDetails";
import AuthService from "../AuthService";
import DocumentService from "../Documents/DocumentService";

export class TransportRequestsService {
  public static async getArrivalRequests(
    enterpriseId: number
  ): Promise<ITransportArrivalRequest[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports-requests/arrivals`,
      {},
      AuthService.getAuthHeader()
    );
    return (x.data as any)
      .sort(function (
        a: ITransportArrivalRequest,
        b: ITransportArrivalRequest
      ) {
        return a.effectiveDate < b.effectiveDate
          ? -1
          : a.effectiveDate > b.effectiveDate
          ? 1
          : 0;
      })
      .filter((y: ITransportArrivalRequest) => y.nextEventType === "A");
  }

  public static async getDepartureRequests(
    enterpriseId: number
  ): Promise<ITransportDepartureRequest[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports-requests/departures`,
      {},
      AuthService.getAuthHeader()
    );
    return (x.data as any)
      .sort(function (
        a: ITransportDepartureRequest,
        b: ITransportDepartureRequest
      ) {
        return a.effectiveDate < b.effectiveDate
          ? -1
          : a.effectiveDate > b.effectiveDate
          ? 1
          : 0;
      })
      .filter((y: ITransportDepartureRequest) => y.nextEventType === "D");
  }

  public static async getDepartureRequestDetails(
    enterpriseId: number,
    request: ITransportDepartureRequest
  ): Promise<ITransportRequestDetails> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports/requests/departures/${
        request.key.split("_")[0]
      }/${request.key.split("_")[1]}`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    const result: ITransportRequestDetails = {
      requestId: request.requestId,
      requestNumber: request.requestNumber,
      date: request.eventDate ? Moment(request.eventDate).toDate() : undefined,
      arrival: false,
      type: request.type,
      typeDescription: request.typeDescription,
      transportType: request.transportType,
      source: request.source,
      participants: x.data as any,
    };
    return result;
  }

  public static async getArrivalRequestDetails(
    enterpriseId: number,
    request: ITransportArrivalRequest
  ): Promise<ITransportRequestDetails> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports/requests/arrivals/${
        request.key.split("_")[0]
      }/${request.key.split("_")[1]}`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    const result: ITransportRequestDetails = {
      requestId: request.requestId,
      requestNumber: request.requestNumber,
      date: request.eventDate ? Moment(request.eventDate).toDate() : undefined,
      arrival: false,
      type: request.type,
      typeDescription: request.typeDescription,
      transportType: request.transportType,
      source: request.source,
      participants: x.data as any,
    };
    return result;
  }

  public static async exportArrivals(enterpriseId: number): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports/requests/arrivals/export`,
      {},
      AuthService.getAuthHeaderWithBlob()
    );
    if (x.status === 200) {
      DocumentService.openBlob(x);
      return true;
    }
    return false;
  }

  public static async exportDepartures(enterpriseId: number): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/transports/requests/departures/export`,
      {},
      AuthService.getAuthHeaderWithBlob()
    );

    if (x.status === 200) {
      DocumentService.openBlob(x);
      return true;
    }
    return false;
  }
}
