import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightBookingBreadcrumbSubComponentStyles {}

export interface IFlightBookingBreadcrumbStyles
  extends IAmigoBaseStyles<IFlightBookingBreadcrumbSubComponentStyles> {}

export interface IFlightBookingBreadcrumbStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingBreadcrumbClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingBreadcrumbStyleProps,
    IFlightBookingBreadcrumbStyles
  >,
  props?: IFlightBookingBreadcrumbStyleProps
): [
  IProcessedStyleSet<IFlightBookingBreadcrumbStyles>,
  IFlightBookingBreadcrumbSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingBreadcrumbStyleProps,
  IFlightBookingBreadcrumbStyles
> = (
  _props: IFlightBookingBreadcrumbStyleProps
): IFlightBookingBreadcrumbStyles => {
  return {
    root: ["amigo-FlightBookingBreadcrumb", {}],
  };
};
