import * as React from "react";
import { getTravelWarningClassNames } from "./TravelWarning.styles";
import {
  ITravelWarningState,
  ITravelWarningProps,
} from "./TravelWarning.types";
import { MessageBar, MessageBarType, Stack, Link, Text } from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import { ICardTokens, Card } from "@fluentui/react-cards";
import i18n from "src/services/i18n";

export class TravelWarningComponent extends React.Component<
  ITravelWarningProps,
  ITravelWarningState
> {
  render(): JSX.Element {
    const { styles, warning, mobile } = this.props;
    const [classNames] = getTravelWarningClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const cardTokens: ICardTokens = { childrenMargin: 12 };
    return (
      <div className={classNames.root}>
        <Card
          aria-label=""
          horizontal
          styles={{
            root: {
              background: "#fff",
              maxWidth: "100%",
              width: "100%",
            },
          }}
          tokens={cardTokens}
        >
          <Card.Section grow>
            <MessageBar
              style={{ width: "100%" }}
              messageBarType={MessageBarType.info}
              messageBarIconProps={{
                iconName: "Info",
              }}
            >
              <LabelField
                {...this.props}
                styles={undefined}
                label={warning.name}
                content={
                  <Stack horizontal tokens={{ childrenGap: 5 }}>
                    <Stack tokens={{ childrenGap: 5 }}>
                      <Text
                        styles={{ root: { lineHeight: "20px" } }}
                        nowrap={!mobile}
                      >
                        {warning.description}
                      </Text>
                      {warning.subtext !== undefined && (
                        <Text
                          styles={{ root: { lineHeight: "20px" } }}
                          nowrap={!mobile}
                        >
                          {warning.subtext}
                        </Text>
                      )}
                    </Stack>
                    {warning.url !== undefined && (
                      <Link
                        styles={{
                          root: { wordWrap: "nowrap", minWidth: "80px" },
                        }}
                        href={warning.url}
                        target={"_blank"}
                      >
                        {i18n.t("global:moredetails")}
                      </Link>
                    )}
                    <div style={{ width: "40px", height: "25px" }}></div>
                  </Stack>
                }
              />
            </MessageBar>
          </Card.Section>
        </Card>
      </div>
    );
  }
}
