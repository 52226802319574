import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentMethodControlProps } from "./NewPaymentMethodControl.types";
import {
  getStyles,
  INewPaymentMethodControlStyleProps,
  INewPaymentMethodControlStyles,
} from "./NewPaymentMethodControl.styles";
import { NewPaymentMethodControlComponent } from "./NewPaymentMethodControl.base";

/**
 * NewPaymentMethodControl description
 */
export const NewPaymentMethodControl = styled<
  INewPaymentMethodControlProps,
  INewPaymentMethodControlStyleProps,
  INewPaymentMethodControlStyles
>(NewPaymentMethodControlComponent, getStyles);
