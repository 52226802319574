import { IconButton, Panel, PanelType } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import NavService from "../../services/NavService";
import { NavBar } from "../NavBar";
import { NavigationMenu } from "../NavigationMenu";
import { getNavigationMenuPanelClassNames } from "./NavigationMenuPanel.styles";
import {
  INavigationMenuPanelProps,
  INavigationMenuPanelState,
} from "./NavigationMenuPanel.types";

export class NavigationMenuPanelComponent extends React.Component<
  INavigationMenuPanelProps,
  INavigationMenuPanelState
> {
  constructor(props: INavigationMenuPanelProps) {
    super(props);
    this.state = {
      menuVisible: false,
    };
    this._showPanel = this._showPanel.bind(this);
    this._hidePanel = this._hidePanel.bind(this);
  }

  private _showPanel = () => {
    this.setState({ menuVisible: true });
  };

  private _hidePanel = () => {
    this.setState({ menuVisible: false });
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getNavigationMenuPanelClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        <IconButton
          className={classNames.icon}
          iconProps={{ iconName: "CollapseMenu" }}
          onClick={this._showPanel}
        />
        <Panel
          className={classNames.panel}
          isOpen={this.state.menuVisible}
          type={PanelType.smallFluid}
          onDismiss={this._hidePanel}
          closeButtonAriaLabel={i18n.t("global:close")}
          headerText={this.props.tokens?.sidebarMenuText ?? ""}
        >
          <div className={classNames.container}>
            <NavBar
              {...this.props}
              styles={subComponentStyles?.navBar}
              onDismiss={this._hidePanel}
            />
          </div>
          <NavigationMenu
            {...this.props}
            styles={subComponentStyles?.navigationMenu}
            onDismiss={this._hidePanel}
            groups={NavService.getMenu()}
          />
        </Panel>
      </div>
    );
  }
}
