import * as React from "react";
import { getBillingDetailsPanelContentClassNames } from "./BillingDetailsPanelContent.styles";
import {
  IBillingDetailsPanelContentState,
  IBillingDetailsPanelContentProps,
} from "./BillingDetailsPanelContent.types";
import { LabelField } from "src/components/LabelField";
import {
  DetailsList,
  Text,
  Icon,
  MessageBar,
  MessageBarType,
  SelectionMode,
  Stack,
} from "@fluentui/react";
import i18n from "src/services/i18n";

export class BillingDetailsPanelContentComponent extends React.Component<
  IBillingDetailsPanelContentProps,
  IBillingDetailsPanelContentState
> {
  constructor(props: IBillingDetailsPanelContentProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getBillingDetailsPanelContentClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <>
        {this.props.billingElement !== undefined && (
          <div className={classNames.root} style={{ marginTop: "30px" }}>
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <Stack grow>
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:billingnumber")}
                  content={this.props.billingElement.billingNumber}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:billingdate")}
                  content={this.props.billingElement.billingDate}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:status")}
                  content={this.props.billingElement.status}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:balance")}
                  content={this.props.billingElement.balance}
                />
              </Stack>
              <Stack grow>
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:subtotal")}
                  content={this.props.billingElement.subtotal}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:tps")}
                  content={this.props.billingElement.tps}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:tvq")}
                  content={this.props.billingElement.tvq}
                />
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  label={i18n.t("billing:total")}
                  content={this.props.billingElement.total}
                />
              </Stack>
            </Stack>
            {this.props.billingElement.travel ? (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Icon iconName="AirplaneSolid" />
                <Text variant="small">{i18n.t("billing:billtype:travel")}</Text>
              </Stack>
            ) : (
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Icon iconName="Script" />
                <Text variant="small">
                  {i18n.t("billing:billtype:standard")}
                </Text>
              </Stack>
            )}
            {this.props.billingElement.travel && (
              <>
                <div style={{ height: "10px" }}></div>
                <MessageBar messageBarType={MessageBarType.info}>
                  {i18n.t("billing:warning:1")}
                </MessageBar>
              </>
            )}
            {this.props.billingElement.details !== undefined &&
              this.props.billingElement.details.length > 0 && (
                <DetailsList
                  styles={{
                    root: {
                      marginLeft: "-10px",
                    },
                  }}
                  selectionMode={SelectionMode.none}
                  columns={
                    this.props.mobile
                      ? [
                          {
                            key: "description",
                            fieldName: "title",
                            name: "Description",
                            minWidth: 200,
                          },
                          {
                            key: "total",
                            name: "Montant",
                            fieldName: "total",
                            minWidth: 70,
                          },
                        ]
                      : [
                          {
                            key: "description",
                            fieldName: "title",
                            name: "Description",
                            minWidth: 200,
                          },
                          {
                            key: "qty",
                            name: "Qty",
                            fieldName: "qty",
                            minWidth: 50,
                          },
                          {
                            key: "unitary",
                            name: "Coût unitaire",
                            fieldName: "unitary",
                            minWidth: 90,
                          },
                          {
                            key: "total",
                            name: "Montant",
                            fieldName: "total",
                            minWidth: 70,
                          },
                        ]
                  }
                  items={this.props.billingElement.details}
                />
              )}
          </div>
        )}
      </>
    );
  }
}
