import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentsSearchResultsProps } from "./DocumentsSearchResults.types";
import {
  getStyles,
  IDocumentsSearchResultsStyleProps,
  IDocumentsSearchResultsStyles,
} from "./DocumentsSearchResults.styles";
import { DocumentsSearchResultsComponent } from "./DocumentsSearchResults.base";

/**
 * DocumentsSearchResults
 */
export const DocumentsSearchResults = styled<
  IDocumentsSearchResultsProps,
  IDocumentsSearchResultsStyleProps,
  IDocumentsSearchResultsStyles
>(DocumentsSearchResultsComponent, getStyles);
