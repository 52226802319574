/**
 * @summary Index Application EntryPoint
 * @version 2.0.1
 */
/** imports */
import {
  registerIcons,
  registerDefaultFontFaces,
  initializeIcons,
} from "@fluentui/react";
import { initializeFileTypeIcons } from "@fluentui/react-file-type-icons";
import * as ReactDOM from "react-dom";
import MediaQuery from "react-responsive";
import { getAmigoCustomizations } from "./AmigoTokens";
import { App, IAppProps } from "./App";
import { IIconSubset } from "@fluentui/react";
import axios, { AxiosResponse } from "axios";
import { WorkerEscapedRedSVG, 
         WorkerEscapedGreenSVG, 
         WorkerEscapedWhiteSVG,
         ContractRenewdRedSVG, 
         ContractRenewdGreenSVG,
         ContractRenewdWhiteSVG } from "./components/TravelIcons/ArrivalDeparture"

(window as any).FabricConfig = {
  fontBaseUrl: "",
  mergeStyles: {
    cspSettings: { nonce: "mynonce" },
  },
};

registerDefaultFontFaces("/fonts/");
/**
 * Root Element Declaration
 * @public rootElement
 */
const rootElement = document.getElementById("root");

/**
 * Icons Initialization
 * @method initializeIcons
 */
initializeIcons("/fonts/", { disableWarnings: true });
initializeFileTypeIcons("/fonts/", { disableWarnings: true });

const subset: IIconSubset = {
  style: {
    MozOsxFontSmoothing: "grayscale",
    WebkitFontSmoothing: "antialiased",
    fontStyle: "normal",
    fontWeight: "normal",
    speak: "none",
  },
  fontFace: {
    fontFamily: `"FabricMDL2Icons-18"`,
    src: `url('/fonts/fabric-icons-de9692ea.woff') format('woff')`,
  },
  icons: {
    SortLinesAscending: "\uE43A",
    AlertSettings: "\uF8B6",
  },
};
registerIcons(subset, { disableWarnings: false });
registerIcons({
  icons: {
     WorkerEscapedRedSVG: <WorkerEscapedRedSVG/>,
     WorkerEscapedGreenSVG: <WorkerEscapedGreenSVG/>,
     WorkerEscapedWhiteSVG: <WorkerEscapedWhiteSVG/>,
     ContractRenewdRedSVG:  <ContractRenewdRedSVG/>,
     ContractRenewdGreenSVG: <ContractRenewdGreenSVG/>, 
     ContractRenewdWhiteSVG: <ContractRenewdWhiteSVG/>,
  }
});

const [theme, tokens] = getAmigoCustomizations();

const props: IAppProps = {
  name: "AMIGO",
  theme: theme,
  tokens: tokens,
  mobile: false,
  tablet: false,
} as any;

axios.interceptors.response.use(
  (response: AxiosResponse<any>) => {
    return response;
  },
  (error) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      document.location.href.indexOf("/deconnexion") === -1 &&
      document.location.href.indexOf("/connexion") === -1 &&
      document.location.href.indexOf("/oublie-mot-de-passe") === -1 &&
      document.location.href.indexOf("/oublie-mot-de-passe") === -1 &&
      document.location.href.indexOf("/reinitialisation-mot-de-passe") === -1
    ) {
      document.location.href = "/deconnexion";
    }
    return error;
  }
);

ReactDOM.render(
  <MediaQuery query="(max-width: 767px)">
    {(mobile) => (
      <MediaQuery query="(max-width: 1224px)">
        {(tablet) => (
          <App {...props} mobile={mobile} tablet={tablet && !mobile} />
        )}
      </MediaQuery>
    )}
  </MediaQuery>,
  rootElement
);
