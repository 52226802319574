import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchResultsContainerProps } from "./FlightSearchResultsContainer.types";
import {
  getStyles,
  IFlightSearchResultsContainerStyleProps,
  IFlightSearchResultsContainerStyles,
} from "./FlightSearchResultsContainer.styles";
import { FlightSearchResultsContainerComponent } from "./FlightSearchResultsContainer.base";

/**
 * FlightSearchResultsContainer
 */
export const FlightSearchResultsContainer = styled<
  IFlightSearchResultsContainerProps,
  IFlightSearchResultsContainerStyleProps,
  IFlightSearchResultsContainerStyles
>(FlightSearchResultsContainerComponent, getStyles);
