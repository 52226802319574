import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IContactModificationPanelStyleProps,
  IContactModificationPanelStyles,
} from "../ContactModificationPanel";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";
import {
  IEnterpriseContactItemStyles,
  IEnterpriseContactItemStyleProps,
} from "../EnterpriseContactItem";

export interface IEnterpriseContactsControlSubComponentStyles {
  panel?: IStyleFunctionOrObject<
    IContactModificationPanelStyleProps,
    IContactModificationPanelStyles
  >;
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
  contact?: IStyleFunctionOrObject<
    IEnterpriseContactItemStyleProps,
    IEnterpriseContactItemStyles
  >;
}

export interface IEnterpriseContactsControlStyles
  extends IAmigoBaseStyles<IEnterpriseContactsControlSubComponentStyles> {}

export interface IEnterpriseContactsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseContactsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseContactsControlStyleProps,
    IEnterpriseContactsControlStyles
  >,
  props?: IEnterpriseContactsControlStyleProps
): [
  IProcessedStyleSet<IEnterpriseContactsControlStyles>,
  IEnterpriseContactsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseContactsControlStyleProps,
  IEnterpriseContactsControlStyles
> = (
  _props: IEnterpriseContactsControlStyleProps
): IEnterpriseContactsControlStyles => {
  return {};
};
