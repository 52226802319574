import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IAlertsPageSubComponentStyles {}

export interface IAlertsPageStyles
  extends IAmigoBaseStyles<IAlertsPageSubComponentStyles> {}

export interface IAlertsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAlertsPageClassNames = (
  styles?: IStyleFunctionOrObject<IAlertsPageStyleProps, IAlertsPageStyles>,
  props?: IAlertsPageStyleProps
): [
  IProcessedStyleSet<IAlertsPageStyles>,
  IAlertsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAlertsPageStyleProps,
  IAlertsPageStyles
> = (props: IAlertsPageStyleProps): IAlertsPageStyles => {
  const pageStyle = props.tokens.defaultPageRootStyle as any;
  return {
    root: {
      ...pageStyle,
      overflowX: "hidden",
    },
  };
};
