import { styled } from "@fluentui/react/lib/Utilities";
import { IPasswordStrengthIndicatorProps } from "./PasswordStrengthIndicator.types";
import {
  getStyles,
  IPasswordStrengthIndicatorStyleProps,
  IPasswordStrengthIndicatorStyles,
} from "./PasswordStrengthIndicator.styles";
import { PasswordStrengthIndicatorComponent } from "./PasswordStrengthIndicator.base";

/**
 * PasswordStrengthIndicator description
 */
export const PasswordStrengthIndicator = styled<
  IPasswordStrengthIndicatorProps,
  IPasswordStrengthIndicatorStyleProps,
  IPasswordStrengthIndicatorStyles
>(PasswordStrengthIndicatorComponent, getStyles);
