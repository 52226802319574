import { styled } from "@fluentui/react/lib/Utilities";
import { IEntityPanelProps } from "./EntityPanel.types";
import {
  getStyles,
  IEntityPanelStyleProps,
  IEntityPanelStyles,
} from "./EntityPanel.styles";
import { EntityPanelComponent } from "./EntityPanel.base";

/**
 * EntityPanel description
 */
export const EntityPanel = styled<
  IEntityPanelProps,
  IEntityPanelStyleProps,
  IEntityPanelStyles
>(EntityPanelComponent, getStyles);
