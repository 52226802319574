import AlertsService from "./Alerts/AlertsService";
import BillingService from "./Billing/BillingService";
import DocumentService from "./Documents/DocumentService";
import DocumentCategoryService from "./Documents/DocumentCategoryService";
import EnterpriseService from "./Enterprises/EnterpriseService";
import DossierImmigrationService from "./DossierImmigration/DossierImmigrationService";
import MessageService from "./Messages/MessagesService";
import ParticipantsService from "./Participants/ParticipantsService";
import RequestsService from "./Requests/RequestsService";
import UsersService from "./Users/UsersService";

class Housekeeper {
  public init(): void {
    AlertsService.init();
    BillingService.init();
    DocumentService.init();
    DocumentCategoryService.init();
    EnterpriseService.init();
    DossierImmigrationService.init();
    MessageService.init(undefined);
    ParticipantsService.init();
    RequestsService.init();
    UsersService.init();
  }
}

export default new Housekeeper();
