/**
 * Application Theme Loading
 * @method loadTheme
 */

import { ITheme, loadTheme } from "@fluentui/react/lib/Styling";

export const getAmigoTheme = (): ITheme => {
  const theme: ITheme = loadTheme({
    palette: {
      themePrimary: "#cb511b",
      themeLighterAlt: "#fdf7f4",
      themeLighter: "#f7dfd4",
      themeLight: "#f0c4b1",
      themeTertiary: "#e08e6b",
      themeSecondary: "#d26231",
      themeDarkAlt: "#b84818",
      themeDark: "#9b3d14",
      themeDarker: "#722d0f",
      neutralLighterAlt: "#faf9f8",
      neutralLighter: "#f3f2f1",
      neutralLight: "#edebe9",
      neutralQuaternaryAlt: "#e1dfdd",
      neutralQuaternary: "#d0d0d0",
      neutralTertiaryAlt: "#c8c6c4",
      neutralTertiary: "#a19f9d",
      neutralSecondary: "#605e5c",
      neutralPrimaryAlt: "#3b3a39",
      neutralPrimary: "#323130",
      neutralDark: "#201f1e",
      black: "#000000",
      white: "#ffffff",
    },
  });

  return theme;
};
