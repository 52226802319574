import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseDocumentCardProps } from "./EnterpriseDocumentCard.types";
import {
  getStyles,
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles,
} from "./EnterpriseDocumentCard.styles";
import { EnterpriseDocumentCardComponent } from "./EnterpriseDocumentCard.base";

/**
 * EnterpriseDocumentCard description
 */
export const EnterpriseDocumentCard = styled<
  IEnterpriseDocumentCardProps,
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles
>(EnterpriseDocumentCardComponent, getStyles);
