import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentCommandBarProps } from "./NewPaymentCommandBar.types";
import {
  getStyles,
  INewPaymentCommandBarStyleProps,
  INewPaymentCommandBarStyles,
} from "./NewPaymentCommandBar.styles";
import { NewPaymentCommandBarComponent } from "./NewPaymentCommandBar.base";

/**
 * NewPaymentCommandBar description
 */
export const NewPaymentCommandBar = styled<
  INewPaymentCommandBarProps,
  INewPaymentCommandBarStyleProps,
  INewPaymentCommandBarStyles
>(NewPaymentCommandBarComponent, getStyles);
