import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingParticipantCardProps } from "./FlightBookingParticipantCard.types";
import {
  getStyles,
  IFlightBookingParticipantCardStyleProps,
  IFlightBookingParticipantCardStyles,
} from "./FlightBookingParticipantCard.styles";
import { FlightBookingParticipantCardComponent } from "./FlightBookingParticipantCard.base";

/**
 * FlightBookingParticipantCard
 */
export const FlightBookingParticipantCard = styled<
  IFlightBookingParticipantCardProps,
  IFlightBookingParticipantCardStyleProps,
  IFlightBookingParticipantCardStyles
>(FlightBookingParticipantCardComponent, getStyles);
