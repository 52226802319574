import { DetailsList, Icon, SelectionMode, Text } from "@fluentui/react";
import * as React from "react";
import { IRequestParticipantPathwayDetail } from "src/models/Requests/IRequestParticipantPathwayDetail";
import i18n from "src/services/i18n";
import { RequestParticipantPathwayTimeline } from "../RequestParticipantPathwayTimeline";
import { getRequestParticipantPathwayClassNames } from "./RequestParticipantPathway.styles";
import {
  IRequestParticipantPathwayProps,
  IRequestParticipantPathwayState,
} from "./RequestParticipantPathway.types";

const getDetailItems = (item?: IRequestParticipantPathwayDetail) => {
  return item === undefined || item == null
    ? []
    : [
        {
          completed: item.medicalExamDate != null,
          eventDate: item.medicalExamDate,
          description: i18n.t("requests:participants:item:pathway:medical"),
        },
        {
          completed: item.eimtDate != null,
          eventDate: item.eimtDate,
          description: i18n.t("requests:participants:item:pathway:eimt"),
        },
        {
          completed: item.signatureDate != null,
          eventDate: item.signatureDate,
          description: i18n.t("requests:participants:item:pathway:signature"),
        },
        {
          completed: item.annexReceptionDate != null,
          eventDate: item.annexReceptionDate,
          description: i18n.t("requests:participants:item:pathway:annexe"),
        },
        {
          completed: item.depositDate != null,
          eventDate: item.depositDate,
          description: i18n.t("requests:participants:item:pathway:deposit"),
        },
        {
          completed: item.deliveryDate != null,
          eventDate: item.deliveryDate,
          description: i18n.t("requests:participants:item:pathway:delivery"),
        },
      ];
};

export class RequestParticipantPathwayComponent extends React.Component<
  IRequestParticipantPathwayProps,
  IRequestParticipantPathwayState
> {
  constructor(props: IRequestParticipantPathwayProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getRequestParticipantPathwayClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <RequestParticipantPathwayTimeline
          {...this.props}
          timeline={this.props.participant?.pathway?.timeline}
        />
        <div style={{ height: "20px" }}></div>
        <DetailsList
          items={getDetailItems(this.props.participant?.pathway?.details)}
          selectionMode={SelectionMode.none}
          columns={
            this.props.mobile
              ? [
                  {
                    key: "status",
                    name: i18n.t("requests:participants:item:pathway:status"),
                    fieldName: "completed",
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item) => (
                      <Icon
                        iconName={
                          item.completed ? "VerifiedBrandSolid" : "Error"
                        }
                        styles={{
                          root: {
                            fontSize: "22px",
                            color: item.completed
                              ? this.props.theme.palette.green
                              : this.props.theme.palette.redDark,
                          },
                        }}
                      />
                    ),
                  },
                  {
                    key: "description",
                    name: "Description",
                    fieldName: "description",
                    minWidth: 125,
                    onRender: (item) => (
                      <Text
                        variant={this.props.mobile ? "mediumPlus" : "large"}
                        styles={{
                          root: {
                            color: item.completed
                              ? this.props.theme.palette.green
                              : this.props.theme.palette.redDark,
                          },
                        }}
                      >
                        {item.description}
                        <br />
                        {item.eventDate != null && <>{item.eventDate}</>}
                      </Text>
                    ),
                  },
                ]
              : [
                  {
                    key: "status",
                    name: i18n.t("requests:participants:item:pathway:status"),
                    fieldName: "completed",
                    minWidth: 50,
                    maxWidth: 50,
                    onRender: (item) => (
                      <Icon
                        iconName={
                          item.completed ? "VerifiedBrandSolid" : "Error"
                        }
                        styles={{
                          root: {
                            fontSize: "22px",
                            color: item.completed
                              ? this.props.theme.palette.green
                              : this.props.theme.palette.redDark,
                          },
                        }}
                      />
                    ),
                  },
                  {
                    key: "eventDate",
                    name: "Date",
                    fieldName: "date",
                    minWidth: 125,
                    maxWidth: 125,
                    onRender: (item) => (
                      <Text
                        variant={this.props.mobile ? "mediumPlus" : "large"}
                        styles={{
                          root: {
                            color: item.completed
                              ? this.props.theme.palette.green
                              : this.props.theme.palette.redDark,
                          },
                        }}
                      >
                        {item.eventDate}
                      </Text>
                    ),
                  },
                  {
                    key: "description",
                    name: "Description",
                    fieldName: "description",
                    minWidth: 125,
                    onRender: (item) => (
                      <Text
                        variant={this.props.mobile ? "mediumPlus" : "large"}
                        styles={{
                          root: {
                            color: item.completed
                              ? this.props.theme.palette.green
                              : this.props.theme.palette.redDark,
                          },
                        }}
                      >
                        {item.description}
                      </Text>
                    ),
                  },
                ]
          }
        />
      </div>
    );
  }
}
