import {
  ActionButton,
  DefaultPalette,
  HoverCard,
  Icon,
  IExpandingCardProps,
  MessageBar,
  MessageBarType,
  PanelType,
  Persona,
  PersonaCoin,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { DisplayList } from "src/components/DisplayList";
import { EntityPanel } from "src/components/EntityPanel";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import RequestsService from "src/services/Requests/RequestsService";
import { getTravelStatusIconClassNames } from "./TravelStatusIcon.styles";
import {
  ITravelStatusIconProps,
  ITravelStatusIconState,
} from "./TravelStatusIcon.types";

const colors = [
  "#000000",
  "#5cb85c",
  "#d9534f",
  "#f0ad4e",
  "#9370DB",
  "#2F96B4",
  "#5cb85c",
  "#d9534f",
  "#f0ad4e",
  "#5cb85c",
  "#d9534f",
  "#2F96B4",
  "#5cb85c",
  "#d9534f",
  "#f0ad4e",
  "#FF0000",
  "#FF0000",
  "#5cb85c",
  "#5cb85c",
];

export class TravelStatusIconComponent extends React.Component<
  ITravelStatusIconProps,
  ITravelStatusIconState
> {
  constructor(props: ITravelStatusIconProps) {
    super(props);
    this.state = {
      fetched: false,
      isOpen: false,
    };
  }

  private resolveIconName = (status: number, isColorReserved?: boolean): string => {
    if (status < 6) return "AirplaneSolid";
    else if (status < 9) return "NavigateForward";
    else if (status < 12) return "Suitcase";
    else if(status === 15) return isColorReserved? "ContractRenewdWhiteSVG" : "ContractRenewdRedSVG";
    else if(status === 16) return  isColorReserved? "WorkerEscapedWhiteSVG": "WorkerEscapedRedSVG";
    else if(status === 17) return isColorReserved? "ContractRenewdWhiteSVG" : "ContractRenewdGreenSVG";
    else if(status === 18) return  isColorReserved? "WorkerEscapedWhiteSVG": "WorkerEscapedGreenSVG";
    else return "Help";
  };

  private resolveColor = (status: number): string => {
    return colors[status] ?? "#000000";
  };

  private hasTrajetId = () => {
    const ite: any = this.props.participant;
    const id = this.props.arrival
      ? ite.arrivalTrajetId ?? ite.arrivalId ?? 0
      : ite.departureTrajetId ?? ite.departureId ?? 0;

    return id !== 0;
  };

  /*
  private buildColumn = (): IColumn[] => {
    const { status } = this.props;
    if (status < 6)
      return [
        {
          key: "title",
          name: "title",
          fieldName: "title",
          minWidth: 200,
        },
        {
          key: "origin",
          name: "origin",
          fieldName: "origin",
          minWidth: 200,
        },
        {
          key: "destination",
          name: "destination",
          fieldName: "origin",
          minWidth: 200,
        },
        {
          key: "arrivalDate",
          name: "arrivalDate",
          fieldName: "arrivalDate",
          minWidth: 200,
        },
      ];
    else if (status < 9)
      return [
        {
          key: "title",
          name: "title",
          fieldName: "title",
          minWidth: 200,
        },
        {
          key: "departureDate",
          name: "departureDate",
          fieldName: "departureDate",
          minWidth: 200,
        },
      ];
    else if (status < 12)
      return [
        {
          key: "title",
          name: "title",
          fieldName: "title",
          minWidth: 200,
        },
        {
          key: "departureDate",
          name: "departureDate",
          fieldName: "departureDate",
          minWidth: 200,
        },
      ];
    else return [];
  };

  */

  render(): JSX.Element {
    const { styles, requestId, participant } = this.props;
    const [classNames] = getTravelStatusIconClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const onRenderCompactCard = (item: any, isPanel?: boolean): JSX.Element => {
      return (
        <Stack
          tokens={{ childrenGap: 5 }}
          grow
          styles={{
            root: {
              margin: "10px 5px",
            },
          }}
        >
          <div style={{ height: "2px" }}></div>
          <StackItem align="center">
            <Persona
              initialsColor={this.resolveColor(item)}
              coinProps={{
                onRenderInitials: () => (
                  <Icon
                    iconName={this.resolveIconName(this.props.status, true)}
                    style={{
                      fontSize: "22px",
                      marginTop: "2px",
                      marginLeft: "2px",
                      textAlign: "center",
                      width: "32px",
                      color: this.props.theme.palette.white,
                    }}
                  />
                ),
              }}
              styles={{
                secondaryText: {
                  marginLeft: "-10px",
                },
              }}
              text={`${participant?.lastName.toUpperCase()}, ${
                participant?.firstName
              }`}
              secondaryText=""
              onRenderSecondaryText={
                isPanel
                  ? undefined
                  : () => (
                      <ActionButton
                        text={i18n.t("global:moredetails")}
                        styles={{
                          root: {
                            margin: 0,
                          },
                        }}
                        onClick={() =>
                          this.setState({
                            isOpen: true,
                          })
                        }
                      />
                    )
              }
            />
          </StackItem>
          <LabelField
            {...this.props}
            styles={undefined}
            label={i18n.t("requests:positions:details:type")}
            content={i18n.t(
              this.props.status < 6
                ? "requests:positions:details:type:flight"
                : this.props.status < 9
                ? "requests:positions:details:type:employer"
                : this.props.status === 15
                ? "requests:positions:details:type:contractrenewd"
                : this.props.status === 16
                ? "requests:positions:details:type:workerescaped"
                : "requests:positions:details:type:other"
            )}
          />
          {this.props.status < 6 && (
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:details:ticket")}
              content={
                this.props.arrival
                  ? this.props.participant.arrivalTicket === undefined ||
                    this.props.participant.arrivalTicket === ""
                    ? "-"
                    : this.props.participant.arrivalTicket
                  : this.props.participant.departureTicket === undefined ||
                    this.props.participant.departureTicket === ""
                  ? "-"
                  : this.props.participant.departureTicket
              }
            />
          )}
          <div style={{ height: "2px" }}></div>
        </Stack>
      );
    };

    // const columns: IColumn[] = this.buildColumn();
    const { transportSummary, transfertSummary, fetched, isOpen } = this.state;
    const onRenderExpandedCard = (item: any): JSX.Element => {
      return (
        <div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <div style={{ height: "20px" }}></div>
          {((this.props.status < 6 &&
            transportSummary === undefined &&
            transfertSummary === undefined &&
            fetched) ||
            (transfertSummary === undefined && !this.hasTrajetId())) && (
            <MessageBar messageBarType={MessageBarType.info}>
              {i18n.t("transports:summary:undefined")}
            </MessageBar>
          )}
          {transfertSummary && (
            <DisplayList
              items={[
                {
                  key: "Employeur",
                  value: transfertSummary.enterpriseName,
                },
                {
                  key: "Poste",
                  value: transfertSummary.title,
                },
              ]}
            />
          )}
          {transportSummary !== undefined && (
            <>
              <Stack tokens={{ childrenGap: 10 }}>
                {transportSummary.trajets
                  .filter((_x, index) => index === 0)
                  .map((x, index) => (
                    <Stack
                      key={index.toString()}
                      horizontal
                      tokens={{ childrenGap: 10 }}
                      verticalAlign="center"
                      horizontalAlign="center"
                      grow
                      styles={{
                        root: {
                          border: `1px solid ${this.resolveColor(item)}`,
                          paddingLeft: "5px",
                        },
                      }}
                    >
                      <StackItem align="center">
                        <h3 style={{ textAlign: "center" }}>{x.origine}</h3>
                        <div>{x.dateDepart}</div>
                      </StackItem>
                      <StackItem align="center">
                        <Icon iconName="AirplaneSolid" />
                      </StackItem>
                      <StackItem align="center">
                        <h3 style={{ textAlign: "center" }}>{x.destination}</h3>
                        <div>{x.dateArrivee}</div>
                      </StackItem>
                    </Stack>
                  ))}
              </Stack>
              <ActionButton
                text={i18n.t("global:moredetails")}
                onClick={() =>
                  this.setState({
                    isOpen: true,
                  })
                }
              />
            </>
          )}
        </div>
      );
    };
    const expandingCardProps: IExpandingCardProps = {
      onRenderCompactCard: (item) => onRenderCompactCard(item),
      onRenderExpandedCard: (item) => onRenderExpandedCard(item),
      renderData: this.props.status,
      calloutProps: {
        styles: {
          root: {
            marginTop: "-5px",
          },
        },
      },
    };

    return (
      <>
        <HoverCard
          expandingCardProps={expandingCardProps}
          instantOpenOnClick={true}
          onCardVisible={() => {
            if (
              this.state.transportSummary === undefined &&
              this.state.transfertSummary === undefined
            ) {
              const ite: any = this.props.participant;
              const id = this.props.arrival
                ? ite.arrivalTrajetId ?? 0
                : ite.departureTrajetId ?? 0;
              if (id !== 0) {
                RequestsService.getTransportDetail(
                  objectId(this.props.enterprise),
                  id
                ).then((x) =>
                  this.setState({
                    fetched: true,
                    transportSummary: x,
                  })
                );
              } else {
                const sid = this.props.arrival
                  ? ite.arrivalId ?? 0
                  : ite.departureId ?? 0;
                if (sid !== 0) {
                  RequestsService.getTransportDetailParticipation(
                    objectId(this.props.enterprise),
                    sid,
                    this.props.arrival
                  ).then((x) =>
                    this.setState({
                      fetched: true,
                      transportSummary: x,
                    })
                  );
                } else if (this.props.status >= 6 && this.props.status < 9) {
                  RequestsService.getTransfertDetail(
                    objectId(this.props.enterprise),
                    requestId,
                    this.props.participant.travailleurId,
                    this.props.arrival
                      ? this.props.participant.arrivalDate
                      : this.props.participant.departureDate,
                    this.props.arrival
                      ? this.props.participant.arrivalTransfertKey
                      : this.props.participant.departureTransfertKey,
                    this.props.arrival
                  ).then((x) =>
                    this.setState({
                      fetched: true,
                      transfertSummary: x,
                    })
                  );
                }
              }
            }
          }}
        >
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <Icon
              className={classNames.root}
              iconName={this.resolveIconName(this.props.status)}
              onClick={() => this.setState({ isOpen: true })}
              style={{
                fontSize: "22px",
                textAlign: "center",
                cursor: "pointer",
                width: "32px",
                color: this.resolveColor(this.props.status),
              }}
            />
            {this.props.arrival &&
              this.props.status === 2 &&
              (this.props.participant.readyToTravel ?? false) && (
                <TooltipHost
                  content={i18n.t(
                    "requests:participants:item:readytotravel:title"
                  )}
                >
                  <Icon
                    iconName="AirTickets"
                    styles={{
                      root: {
                        fontSize: "22px",
                        textAlign: "center",
                        cursor: "pointer",
                        color: DefaultPalette.greenDark,
                      },
                    }}
                  />
                </TooltipHost>
              )}
          </Stack>
        </HoverCard>
        <EntityPanel
          {...this.props}
          panelType={PanelType.medium}
          styles={undefined}
          data-automation-id="nvx:requests:periods:transportpanel"
          isOpen={isOpen}
          onDismiss={() => {
            this.setState({
              isOpen: false,
            });
          }}
          elementName={
            this.props.arrival
              ? i18n.t("requests:periods:arrival")
              : i18n.t("requests:periods:departure")
          }
          title={`${participant?.lastName.toUpperCase()}, ${
            participant?.firstName
          }`}
        >
          <div>
            <Stack
              horizontal={!this.props.mobile}
              tokens={{ childrenGap: this.props.mobile ? 10 : 20 }}
              grow
              styles={{
                root: {
                  margin: "10px 5px",
                },
              }}
            >
              <StackItem>
                <PersonaCoin
                  initialsColor={this.resolveColor(this.props.status)}
                  onRenderInitials={() => (
                    <Icon
                      iconName={this.resolveIconName(this.props.status, true)}
                      style={{
                        fontSize: "22px",
                        marginTop: "2px",
                        marginLeft: "2px",
                        textAlign: "center",
                        width: "32px",
                        color: this.props.theme.palette.white,
                      }}
                    />
                  )}
                />
              </StackItem>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("requests:positions:details:type")}
                content={i18n.t(
                  this.props.status < 6
                    ? "requests:positions:details:type:flight"
                    : this.props.status < 9
                    ? "requests:positions:details:type:employer"
                    : this.props.status === 15
                    ? "requests:positions:details:type:contractrenewd"
                    : this.props.status === 16
                    ? "requests:positions:details:type:workerescaped"
                    : "requests:positions:details:type:other"
                )}
              />
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("requests:positions:details:ticket")}
                content={
                  this.props.arrival
                    ? this.props.participant.arrivalTicket === undefined ||
                      this.props.participant.arrivalTicket === ""
                      ? "-"
                      : this.props.participant.arrivalTicket
                    : this.props.participant.departureTicket === undefined ||
                      this.props.participant.departureTicket === ""
                    ? "-"
                    : this.props.participant.departureTicket
                }
              />
            </Stack>
            {((this.props.status < 6 &&
              transportSummary === undefined &&
              transfertSummary === undefined &&
              fetched) ||
              (transfertSummary === undefined && !this.hasTrajetId())) && (
              <MessageBar messageBarType={MessageBarType.info}>
                {i18n.t("transports:summary:undefined")}
              </MessageBar>
            )}
            {transfertSummary && (
              <DisplayList
                items={[
                  {
                    key: "Employeur",
                    value: transfertSummary.enterpriseName,
                  },
                  {
                    key: "Poste",
                    value: transfertSummary.title,
                  },
                  {
                    key: "Date",
                    value: transfertSummary.date,
                  },
                  {
                    key: "Numero de demande",
                    value: transfertSummary.requestNumber,
                  },
                ]}
              />
            )}
            {transportSummary !== undefined && (
              <div style={{ marginLeft: "10px", marginRight: "10px" }}>
                <div style={{ height: "20px" }}></div>
                {transportSummary.trajets.map((trajet, trajetIndex) => (
                  <Stack
                    key={trajetIndex.toString()}
                    tokens={{ childrenGap: 10 }}
                  >
                    <div>
                      {`${trajet.origine} (${trajet.dateDepart}) - ${trajet.destination} (${trajet.dateArrivee})`}
                    </div>
                    {trajet.flights.map((y, index) => (
                      <Stack
                        key={index.toString()}
                        horizontal
                        tokens={{ childrenGap: 20 }}
                        verticalAlign="center"
                        horizontalAlign="center"
                        grow
                        styles={{
                          root: {
                            border: `1px solid ${DefaultPalette.themeDark}`,
                            paddingLeft: "5px",
                          },
                        }}
                      >
                        <StackItem
                          align="center"
                          styles={{
                            root: { width: "225px", textAlign: "center" },
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>{y.origine}</h3>
                          <div>{y.dateDepart}</div>
                          <div style={{ height: "10px" }}></div>
                        </StackItem>
                        <StackItem align="center">
                          <div style={{ textAlign: "center" }}>
                            <Text>{y.numeroVol}</Text>
                          </div>
                          <div style={{ textAlign: "center" }}>
                            <Icon iconName="AirplaneSolid" />
                          </div>
                          <div style={{ height: "10px" }}></div>
                        </StackItem>
                        <StackItem
                          align="center"
                          styles={{
                            root: { width: "225px", textAlign: "center" },
                          }}
                        >
                          <h3 style={{ textAlign: "center" }}>
                            {y.destination}
                          </h3>
                          <div>{y.dateArrivee}</div>
                          <div style={{ height: "10px" }}></div>
                        </StackItem>
                      </Stack>
                    ))}
                  </Stack>
                ))}
              </div>
            )}
          </div>
        </EntityPanel>
      </>
    );
  }
}
