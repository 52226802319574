import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentsElementsControlProps } from "./PaymentsElementsControl.types";
import {
  getStyles,
  IPaymentsElementsControlStyleProps,
  IPaymentsElementsControlStyles,
} from "./PaymentsElementsControl.styles";
import { PaymentsElementsControlComponent } from "./PaymentsElementsControl.base";

/**
 * PaymentsElementsControl description
 */
export const PaymentsElementsControl = styled<
  IPaymentsElementsControlProps,
  IPaymentsElementsControlStyleProps,
  IPaymentsElementsControlStyles
>(PaymentsElementsControlComponent, getStyles);
