import axios, { AxiosRequestConfig } from "axios";
import { IChangePasswordModel } from "../models/IChangePasswordModel";
import { IForgotPasswordModel } from "../models/IForgotPasswordModel";
import { ISessionProfile } from "../models/ISessionProfile";
import { IResetPasswordModel } from "../models/IResetPasswordModel";
import { ICredentials } from "../models/ICredentials";
import i18n from "./i18n";

class AuthService {
  public login(credentials: ICredentials) {
    return axios.post("/auth/login", credentials);
  }

  public getUserInfo(): ISessionProfile | null {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo === null) return null;
    return JSON.parse(userInfo);
  }

  public updateLanguage(lang: string): Promise<any> {
    return axios.post(
      "/api/auth/language",
      { language: lang },
      this.getAuthHeader()
    );
  }

  public hasPermissionOf(code: string): boolean {
    const session: ISessionProfile | null = this.getUserInfo();
    return session === null
      ? false
      : session.permissions
      ? session.permissions.filter((x) => x === code).length > 0
      : false;
  }

  public hasEditContactPermission(): boolean {
    return this.hasPermissionOf("Portail_Empl_EditContact");
  }

  public hasEditPartcipantPermission(): boolean {
    return this.hasPermissionOf("Particip_Empl_Editer");
  }

  public getAuthHeader() {
    return {
      headers: { Authorization: "Bearer " + this.getUserInfo()?.token },
      withCredentials: false,
    };
  }

  public getAuthHeaderWithLanguage() {
    return {
      headers: {
        Authorization: "Bearer " + this.getUserInfo()?.token,
        "X-Message-Language": i18n.getLanguage(),
      },
      withCredentials: false,
    };
  }

  public getAuthHeaderWithBlob(): AxiosRequestConfig {
    return {
      headers: {
        Authorization: "Bearer " + this.getUserInfo()?.token,
        "X-Message-Language": i18n.getLanguage(),
      },
      withCredentials: false,
      method: "POST",
      responseType: "blob",
    };
  }

  public changePassword(data: IChangePasswordModel): Promise<any> {
    return axios.post("/auth/changepwd", data, this.getAuthHeader());
  }

  public forgotPassword(data: IForgotPasswordModel): Promise<any> {
    return axios.post("/auth/forgotpwd", data);
  }

  public resetPassword(data: IResetPasswordModel): Promise<any> {
    return axios.post("/auth/resetpwd", data);
  }

  public logOut(): Promise<any> {
    const header = this.getAuthHeader();
    localStorage.removeItem("userInfo");
    return axios.post("/auth/logout", {}, header).catch((_x) => {
      return undefined;
    });
  }

  public updateCellphone(cell: string) {
    const user = this.getUserInfo();
    if (user) {
      user.cellphone = cell;
      localStorage.setItem("userInfo", JSON.stringify(user));
    }
  }
}

export default new AuthService();
