import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IPaymentErrorPageSubComponentStyles {}

export interface IPaymentErrorPageStyles
  extends IAmigoBaseStyles<IPaymentErrorPageSubComponentStyles> {}

export interface IPaymentErrorPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentErrorPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentErrorPageStyleProps,
    IPaymentErrorPageStyles
  >,
  props?: IPaymentErrorPageStyleProps
): [
  IProcessedStyleSet<IPaymentErrorPageStyles>,
  IPaymentErrorPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentErrorPageStyleProps,
  IPaymentErrorPageStyles
> = (_props: IPaymentErrorPageStyleProps): IPaymentErrorPageStyles => {
  return {
    root: [
      "amigo-PaymentErrorPage",
      {
        padding: "10px",
      },
    ],
  };
};
