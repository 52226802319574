import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IFlightPersonalizedOffersSubComponentStyles {}

export interface IFlightPersonalizedOffersStyles
  extends IAmigoBaseStyles<IFlightPersonalizedOffersSubComponentStyles> {}

export interface IFlightPersonalizedOffersStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightPersonalizedOffersClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightPersonalizedOffersStyleProps,
    IFlightPersonalizedOffersStyles
  >,
  props?: IFlightPersonalizedOffersStyleProps
): [
  IProcessedStyleSet<IFlightPersonalizedOffersStyles>,
  IFlightPersonalizedOffersSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightPersonalizedOffersStyleProps,
  IFlightPersonalizedOffersStyles
> = (
  _props: IFlightPersonalizedOffersStyleProps
): IFlightPersonalizedOffersStyles => {
  return {
    root: ["amigo-FlightPersonalizedOffers", {}],
  };
};
