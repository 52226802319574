import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles,
} from "src/pages/Enterprises/EnterpriseDocumentCard";

export interface IParticipantDocumentsControlSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  gridCard?: IStyleFunctionOrObject<
    IEnterpriseDocumentCardStyleProps,
    IEnterpriseDocumentCardStyles
  >;
}

export interface IParticipantDocumentsControlStyles
  extends IAmigoBaseStyles<IParticipantDocumentsControlSubComponentStyles> {}

export interface IParticipantDocumentsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantDocumentsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantDocumentsControlStyleProps,
    IParticipantDocumentsControlStyles
  >,
  props?: IParticipantDocumentsControlStyleProps
): [
  IProcessedStyleSet<IParticipantDocumentsControlStyles>,
  IParticipantDocumentsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantDocumentsControlStyleProps,
  IParticipantDocumentsControlStyles
> = (
  _props: IParticipantDocumentsControlStyleProps
): IParticipantDocumentsControlStyles => {
  return {
    root: ["amigo-ParticipantDocumentsControl", {}],
  };
};
