import { styled } from "@fluentui/react/lib/Utilities";
import { IMessageDisplayControlProps } from "./MessageDisplayControl.types";
import {
  getStyles,
  IMessageDisplayControlStyleProps,
  IMessageDisplayControlStyles,
} from "./MessageDisplayControl.styles";
import { MessageDisplayControlComponent } from "./MessageDisplayControl.base";

/**
 * MessageDisplayControl description
 */
export const MessageDisplayControl = styled<
  IMessageDisplayControlProps,
  IMessageDisplayControlStyleProps,
  IMessageDisplayControlStyles
>(MessageDisplayControlComponent, getStyles);
