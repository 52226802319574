import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IRequestHistoryCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IRequestHistoryCardStyles
  extends IAmigoBaseStyles<IRequestHistoryCardSubComponentStyles> {}

export interface IRequestHistoryCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestHistoryCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestHistoryCardStyleProps,
    IRequestHistoryCardStyles
  >,
  props?: IRequestHistoryCardStyleProps
): [
  IProcessedStyleSet<IRequestHistoryCardStyles>,
  IRequestHistoryCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestHistoryCardStyleProps,
  IRequestHistoryCardStyles
> = (props: IRequestHistoryCardStyleProps): IRequestHistoryCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
    },
  };
};
