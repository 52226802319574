import axios from "axios";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";
import i18n from "../i18n";
import { IEnterpriseDocument } from "src/models/Enterprises/IEnterpriseDocument";
import { IAttachmentModel } from "src/models/Messages/IMessageSummary";

class RequestDocumentsService {
  public downloadRequestDocument(
    enterpriseId: number,
    requestId: number,
    item: IEnterpriseDocument
  ): Promise<void> {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/requests/${requestId}/documents/${item.key}`,
        {},
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        const type = x.headers["Content-Type"] ?? "application/octet-stream";
        const blob = new Blob([x.data], { type: type });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.href = url;
        a.download = x.headers["content-filename"];
        a.click();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }, 0);
      })
      .catch((_x) => {
        alert(i18n.t("documents:download:error"));
      });
  }

  private _documents?: IEnterpriseDocument[] = undefined;
  private _documentsPages: number = 1;

  public init(): void {
    this._documents = undefined;
    this._documentsPages = 1;
  }

  private pageSize: number = 500;

  private translateSortBy = (field: string): string => {
    return field
      .replace("size", "Taille")
      .replace("createdOn", "CreerLe")
      .replace("createdBy", "CreerPar.Nom")
      .replace("createdByEmail", "CreerPar.Courriel")
      .replace("name", "Nom")
      .replace("processedOn", "TraiteeLe");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName)
                .replace(".Nom", ".Id")
                .replace(".Description", ".Id"),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async uploadDocuments(
    enterpriseId: number,
    requestId: number,
    attachments: IAttachmentModel[]
  ): Promise<boolean> {
    try {
      const x = await axios.put(
        `/api/enterprises/${enterpriseId}/requests/${requestId}/documents`,
        attachments,
        {
          headers: {
            Authorization: "Bearer " + AuthService.getUserInfo()?.token,
            "X-Message-Language": i18n.getLanguage(),
            "X-EntepriseTarget": enterpriseId.toString(),
          },
          withCredentials: false,
        }
      );
      return x.status < 299;
    } catch (e) {
      return false;
    }
  }

  public getDocuments(
    enterpriseId: number,
    requestId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IEnterpriseDocument[]> {
    const _this = this;
    let currentPage = this._documentsPages;
    let combine: boolean;
    if (next && _this._documents !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `/api/enterprises/${enterpriseId}/requests/${requestId}/documents`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._documents !== undefined) {
          _this._documentsPages = currentPage;
          _this._documents = _this._documents.concat(items);
        } else {
          _this._documentsPages = 1;
          _this._documents = this._documents = items;
        }
        return _this._documents ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this._documents ?? [];
      });
  }
}

export default new RequestDocumentsService();
