import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IGenericPageHeaderSubComponentStyles {}

export interface IGenericPageHeaderStyles
  extends IAmigoBaseStyles<IGenericPageHeaderSubComponentStyles> {}

export interface IGenericPageHeaderStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getGenericPageHeaderClassNames = (
  styles?: IStyleFunctionOrObject<
    IGenericPageHeaderStyleProps,
    IGenericPageHeaderStyles
  >,
  props?: IGenericPageHeaderStyleProps
): [
  IProcessedStyleSet<IGenericPageHeaderStyles>,
  IGenericPageHeaderSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IGenericPageHeaderStyleProps,
  IGenericPageHeaderStyles
> = (_props: IGenericPageHeaderStyleProps): IGenericPageHeaderStyles => {
  return {
    root: ["amigo-GenericPageHeader", {}],
  };
};
