import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantPersonalInfoProps } from "./ParticipantPersonalInfo.types";
import {
  getStyles,
  IParticipantPersonalInfoStyleProps,
  IParticipantPersonalInfoStyles,
} from "./ParticipantPersonalInfo.styles";
import { ParticipantPersonalInfoComponent } from "./ParticipantPersonalInfo.base";

/**
 * ParticipantPersonalInfo description
 */
export const ParticipantPersonalInfo = styled<
  IParticipantPersonalInfoProps,
  IParticipantPersonalInfoStyleProps,
  IParticipantPersonalInfoStyles
>(ParticipantPersonalInfoComponent, getStyles);
