import {
  IPivotStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { IStyle } from "@fluentui/react/lib/Styling";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IProfilePageSubComponentStyles {
  pivot?: IPivotStyles;
}

export interface IProfilePageStyles
  extends IAmigoBaseStyles<IProfilePageSubComponentStyles> {
  content?: IStyle;
  msGridRow?: IStyle;
}

export interface IProfilePageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getProfilePageClassNames = (
  styles?: IStyleFunctionOrObject<IProfilePageStyleProps, IProfilePageStyles>,
  props?: IProfilePageStyleProps
): [
  IProcessedStyleSet<IProfilePageStyles>,
  IProfilePageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IProfilePageStyleProps,
  IProfilePageStyles
> = (props: IProfilePageStyleProps): IProfilePageStyles => {
  return {
    root: ["amigo-ProfilePage", props.tokens.defaultPageRootStyle],
    content: [
      "amigo-ProfilePage-Content",
      {
        width: "100%",
      },
    ],
    msGridRow: props.tokens.msGridRow,
    subComponentStyles: {},
  };
};
