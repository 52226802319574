import axios from "axios";
import { IAlert } from "../../models/Alerts/IAlert";
import { IAlertRelation } from "../../models/Alerts/IAlertRelation";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";

class AlertsService {
  private _alerts?: IAlert[] = undefined;
  private _alertsPages: number = 1;

  public init(): void {
    this._alerts = undefined;
    this._alertsPages = 1;
  }

  private pageSize: number = 20;

  public async getAlertRelations(alertId: number): Promise<IAlertRelation[]> {
    try {
      const x = await axios.post(
        `/api/alerts/${alertId}/related`,
        {},
        AuthService.getAuthHeader()
      );
      return x.data as any;
    } catch (ex) {
      return [];
    }
  }

  private translateSortBy = (field: string): string => {
    return field
      .replace("createdOn", "DateCreation")
      .replace("seenOn", "DateLecture")
      .replace("name", "Titre")
      .replace("type", "TypeAlerte.Description");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async countAlerts(
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ): Promise<number | undefined> {
    try {
      const x = await axios.post(
        "/api/alerts/$count",
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 0,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      return (x.data as any)?.count;
    } catch {
      return undefined;
    }
  }

  public async getAlerts(
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IAlert[]> {
    const _this = this;
    let currentPage = this._alertsPages;
    let combine: boolean;
    if (next && _this._alerts !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        "/api/alerts",
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._alerts !== undefined) {
        _this._alertsPages = currentPage;
        _this._alerts = _this._alerts.concat(items);
      } else {
        _this._alertsPages = 1;
        _this._alerts = this._alerts = items;
      }
      return _this._alerts ?? [];
    } catch (ex) {
      console.log("error");
      return _this._alerts ?? [];
    }
  }

  public deleteAlerts(items: IAlert[]): Promise<void> {
    return axios.delete("/api/alerts", {
      headers: AuthService.getAuthHeader().headers,
      data: {
        elements: items.map((x) => x.id),
      },
    });
  }

  public acknownledge(id: number): Promise<any> {
    return axios.post(
      `api/alerts/acknowledge/${id}`,
      {},
      AuthService.getAuthHeader()
    );
  }

  public async getAlertTypes(): Promise<IChoiceValue[]> {
    const x = await axios.post(
      "/api/alerts/types",
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getAlertDeltas(): Promise<IChoiceValue[]> {
    const x = await axios.post(
      `/api/alerts/deltas`,
      {},
      AuthService.getAuthHeader()
    );
    const items: number[] = x.data as any;
    return items.map((x) => {
      return {
        id: x,
        name: `${x} ${x > 1 ? "daysbefore" : "daybefore"}`,
      };
    });
  }
}

export default new AlertsService();
