import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportRequestsPageSubComponentStyles {}

export interface ITransportRequestsPageStyles
  extends IAmigoBaseStyles<ITransportRequestsPageSubComponentStyles> {}

export interface ITransportRequestsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportRequestsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportRequestsPageStyleProps,
    ITransportRequestsPageStyles
  >,
  props?: ITransportRequestsPageStyleProps
): [
  IProcessedStyleSet<ITransportRequestsPageStyles>,
  ITransportRequestsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportRequestsPageStyleProps,
  ITransportRequestsPageStyles
> = (
  _props: ITransportRequestsPageStyleProps
): ITransportRequestsPageStyles => {
  return {
    root: ["amigo-TransportRequestsPage", { overflowX: "hidden" }],
  };
};
