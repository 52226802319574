import {
  Callout,
  DefaultPalette,
  DirectionalHint,
  Panel,
  PanelType,
  Persona,
  PersonaPresence,
  PersonaSize,
} from "@fluentui/react";
import * as React from "react";
import { UserContactCard } from "../UserContactCard";
import { getUserCalloutClassNames } from "./UserCallout.styles";
import { IUserCalloutProps, IUserCalloutState } from "./UserCallout.types";

export class UserCalloutComponent extends React.Component<
  IUserCalloutProps,
  IUserCalloutState
> {
  private _element: React.RefObject<HTMLDivElement>;
  constructor(props: IUserCalloutProps) {
    super(props);
    this.state = {
      selected: false,
    };
    this._element = React.createRef();
  }

  render(): JSX.Element {
    const { styles, headerText, user } = this.props;
    const [classNames] = getUserCalloutClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const contactSize: PersonaSize = this.props.mobile
      ? PersonaSize.size16
      : PersonaSize.size24;
    return (
      <div className={classNames.root}>
        <div ref={this._element}>
          {user && (
            <Persona
              initialsColor={"rgb(122, 117, 116)"}
              onClick={(ev?: React.MouseEvent<HTMLDivElement>) => {
                ev?.preventDefault();
                ev?.stopPropagation();
                this.setState({
                  selected: true,
                });
              }}
              styles={{
                root: {
                  cursor: "pointer",
                },
              }}
              text={user.name}
              secondaryText={user.role}
              size={contactSize}
              presence={PersonaPresence.none}
              imageAlt={user.name}
            />
          )}
          {!this.props.mobile && user && this.state.selected && (
            <Callout
              role="alertdialog"
              gapSpace={4}
              styles={{
                root: {
                  backgroundColor: DefaultPalette.neutralLighter,
                },
              }}
              directionalHint={DirectionalHint.topAutoEdge}
              target={this._element?.current}
              onDismiss={() => {
                this.setState({
                  selected: false,
                });
              }}
              setInitialFocus={true}
            >
              <UserContactCard {...this.props} user={user} />
            </Callout>
          )}
          {this.props.mobile && user && this.state.selected && (
            <Panel
              isOpen={this.state.selected}
              hasCloseButton={true}
              isLightDismiss={true}
              isHiddenOnDismiss={true}
              headerText={headerText ?? user.name}
              onDismiss={() => {
                this.setState({
                  selected: false,
                });
              }}
              type={PanelType.smallFluid}
            >
              <UserContactCard {...this.props} user={user} />
            </Panel>
          )}
        </div>
      </div>
    );
  }
}
