import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportRequestElementProps } from "./TransportRequestElement.types";
import {
  getStyles,
  ITransportRequestElementStyleProps,
  ITransportRequestElementStyles,
} from "./TransportRequestElement.styles";
import { TransportRequestElementComponent } from "./TransportRequestElement.base";

/**
 * TransportRequestElement
 */
export const TransportRequestElement = styled<
  ITransportRequestElementProps,
  ITransportRequestElementStyleProps,
  ITransportRequestElementStyles
>(TransportRequestElementComponent, getStyles);
