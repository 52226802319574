import { ProgressIndicator, Stack, StackItem } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { MediaQueries } from "src/components/MediaQueries";
import i18n from "src/services/i18n";
import { FlightOfferContainer } from "../FlightOfferContainer";
import { getFlightSearchResultsClassNames } from "./FlightSearchResults.styles";
import {
  IFlightSearchResultsProps,
  IFlightSearchResultsState,
} from "./FlightSearchResults.types";

export class FlightSearchResultsComponent extends React.Component<
  IFlightSearchResultsProps,
  IFlightSearchResultsState
> {
  render(): JSX.Element {
    const { styles, mobile, data, offers, searching } = this.props;
    const [classNames] = getFlightSearchResultsClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <Stack
          tokens={{ childrenGap: mobile ? 5 : 10 }}
          id={"flight_search_results"}
        >
          {searching && (
            <Card
              styles={{
                root: {
                  width: "100%",
                  backgroundColor: "#fff",
                  maxWidth: "100%",
                  padding: "10px",
                },
              }}
            >
              <Card.Item>
                <ProgressIndicator
                  styles={{
                    root: {
                      marginTop: "30px",
                      minWidth: "310px",
                      marginLeft: "15px",
                      width: "97%",
                      maxWidth: "97%",
                    },
                  }}
                  label={i18n.t("transports:flights:searching")}
                  description={i18n.t("global:pleasewait")}
                />
              </Card.Item>
            </Card>
          )}
          {!searching &&
            offers.map((x, index) => {
              return (
                <StackItem
                  key={x.id}
                  grow
                  styles={{
                    root: {
                      [MediaQueries.mobile]: {
                        marginLeft: 0,
                      },
                    },
                  }}
                >
                  <FlightOfferContainer
                    {...this.props}
                    styles={undefined}
                    offer={x}
                    data={data}
                    serviceIndex={x.serviceIndex}
                    offerIndex={index}
                  />
                </StackItem>
              );
            })}
        </Stack>
      </div>
    );
  }
}
