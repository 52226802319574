import { styled } from "@fluentui/react/lib/Utilities";
import { ICustomSpinnerProps } from "./CustomSpinner.types";
import {
  getStyles,
  ICustomSpinnerStyleProps,
  ICustomSpinnerStyles,
} from "./CustomSpinner.styles";
import { CustomSpinnerComponent } from "./CustomSpinner.base";

/**
 * CustomSpinner description
 */
export const CustomSpinner = styled<
  ICustomSpinnerProps,
  ICustomSpinnerStyleProps,
  ICustomSpinnerStyles
>(CustomSpinnerComponent, getStyles);
