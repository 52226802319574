import {
  Checkbox,
  Dropdown,
  IconButton,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Selection,
  SelectionMode,
  Stack,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import moment from "moment";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { GridView } from "src/components/GridView";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridColumn,
} from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { IParticipantNameRecordModel } from "src/models/Transports/IPassengerNameRecordModel";
import i18n from "src/services/i18n";
import { FlightBookingParticipantCard } from "../FlightBookingParticipantCard";
import { getFlightBookingParticipantsGridViewClassNames } from "./FlightBookingParticipantsGridView.styles";
import {
  IFlightBookingParticipantsGridViewProps,
  IFlightBookingParticipantsGridViewState,
} from "./FlightBookingParticipantsGridView.types";

const leavereasons = () => [
  {
    key: "FIN_CONTRAT",
    text: "Fin de contrat",
  },
  {
    key: "RAPATRIMENT",
    text: "Rapatriment",
  },
];

export class FlightBookingParticipantsGridViewComponent extends React.Component<
  IFlightBookingParticipantsGridViewProps,
  IFlightBookingParticipantsGridViewState
> {
  private arrivalColumns = (
    isPassportValid: (x: IParticipantNameRecordModel) => boolean
  ): IGridColumn<any>[] => {
    return [
      {
        key: "passport",
        name: "messages:assisted:newdeparture:passport",
        minWidth: 150,
        onRenderCell: (item) => (
          <Stack tokens={{ childrenGap: 5 }}>
            {isPassportValid(item) ? (
              <Text>{item.passport ?? "-"}</Text>
            ) : (
              <>
                <TooltipHost
                  content={
                    <MessageBar messageBarType={MessageBarType.info}>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <Text variant="xLarge">Info</Text>
                        <Text>
                          {i18n.t(
                            "messages:assisted:newdeparture:passport:invalid:instructions"
                          )}
                        </Text>
                        {/*
                        <Link
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            document.dispatchEvent(new CustomEvent("chat"));
                            return false;
                          }}
                        >
                          {i18n.t("chat:panel:title")}
                        </Link>
                        */}
                        <Text>
                          {i18n.t(
                            "messages:assisted:newdeparture:passport:invalid:instructions:arrival"
                          )}
                        </Text>
                      </Stack>
                    </MessageBar>
                  }
                >
                  <Text
                    styles={{
                      root: {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.passport ?? "-"}
                  </Text>
                  <MessageBar
                    messageBarType={MessageBarType.blocked}
                    styles={{
                      root: {
                        cursor: "pointer",
                        maxWidth: "150px",
                      },
                    }}
                  >
                    {i18n.t("messages:assisted:newdeparture:passport:invalid")}
                  </MessageBar>
                </TooltipHost>
              </>
            )}
          </Stack>
        ),
      },
      {
        key: "estimatedArrival",
        name: "transports:flights:participants:estimatedarrival",
        minWidth: 160,
      },
    ];
  };

  private departureColumns = (
    isPassportValid: (x: IParticipantNameRecordModel) => boolean
  ): IGridColumn<any>[] => {
    const { model, participants } = this.props;

    /*
    const unknown: IDropdownOption = {
      key: "-1",
      text: i18n.t("messages:assisted:newdeparture:unknown"),
    };
    */
    const recalls = model
      ? model.recalls.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "0").toString(),
            text: x.name,
          };
          return i;
        })
      : [];

    return [
      {
        key: "passport",
        name: "messages:assisted:newdeparture:passport",
        minWidth: 120,
        maxWidth: 120,
        onRenderCell: (item) => (
          <Stack tokens={{ childrenGap: 5 }}>
            {!isPassportValid(item) ? (
              <>
                <TooltipHost
                  content={
                    <MessageBar messageBarType={MessageBarType.info}>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <Text variant="xLarge">Info</Text>
                        <Text>
                          {i18n.t(
                            "messages:assisted:newdeparture:passport:invalid:instructions"
                          )}
                        </Text>
                        {/*
                        <Link
                          href="#"
                          onClick={(ev) => {
                            ev.preventDefault();
                            ev.stopPropagation();
                            document.dispatchEvent(new CustomEvent("chat"));
                            return false;
                          }}
                        >
                          {i18n.t("chat:panel:title")}
                        </Link>
                        */}
                        <Text>
                          {i18n.t(
                            "messages:assisted:newdeparture:passport:invalid:instructions:departure"
                          )}
                        </Text>
                      </Stack>
                    </MessageBar>
                  }
                >
                  <Text
                    styles={{
                      root: {
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.passport ?? "-"}
                  </Text>
                  <MessageBar
                    messageBarType={MessageBarType.blocked}
                    styles={{
                      root: {
                        cursor: "pointer",
                        maxWidth: "150px",
                      },
                    }}
                  >
                    {i18n.t("messages:assisted:newdeparture:passport:invalid")}
                  </MessageBar>
                </TooltipHost>
              </>
            ) : (
              <Text>{item.passport ?? "-"}</Text>
            )}
          </Stack>
        ),
      },
      {
        key: "leavereason",
        name: "transports:flights:departurereason",
        visible: false, // this.props.arrival ? false : undefined,
        minWidth: 140,
        maxWidth: 140,
        onRenderCell: (item: IParticipantNameRecordModel) => {
          return item?.selected ? (
            <Dropdown
              options={leavereasons()}
              selectedKey={
                item?.leaveReason === undefined || item.leaveReason === null
                  ? undefined
                  : item.leaveReason.toString()
              }
              disabled={!isPassportValid(item)}
              errorMessage={item.errors ? item.errors.leaveReason : undefined}
              onChange={(
                ev?: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => {
                ev?.preventDefault &&
                  typeof ev?.preventDefault === "function" &&
                  ev?.preventDefault();
                ev?.stopPropagation &&
                  typeof ev?.stopPropagation === "function" &&
                  ev?.stopPropagation();
                option !== undefined &&
                  this.props.onUpdate(
                    participants.map((x) => {
                      if (x.id === item.id) {
                        x.leaveReason = option.key.toString();
                        x.selected = true;
                      }
                      return x;
                    })
                  );
              }}
              placeholder={i18n.t("transports:flights:departurereason:select")}
            />
          ) : (
            <Text>{"-"}</Text>
          );
        },
      },
      {
        key: "recall",
        name: "messages:assisted:newdeparture:recall",
        minWidth: 248,
        maxWidth: 248,
        onRenderCell: (item: IParticipantNameRecordModel) => {
          return item?.selected ? (
            <Dropdown
              options={recalls}
              selectedKey={
                item?.recall === undefined || item.recall === null
                  ? undefined
                  : item.recall.toString()
              }
              styles={{
                root: {
                  maxWidth: "248px",
                },
              }}
              disabled={!isPassportValid(item)}
              errorMessage={item.errors ? item.errors.recall : undefined}
              onChange={(
                ev: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => {
                ev?.preventDefault &&
                  typeof ev?.preventDefault === "function" &&
                  ev?.preventDefault();
                ev?.stopPropagation &&
                  typeof ev?.stopPropagation === "function" &&
                  ev?.stopPropagation();

                ev?.nativeEvent?.preventDefault &&
                  typeof ev?.nativeEvent?.preventDefault === "function" &&
                  ev?.nativeEvent?.preventDefault();
                ev?.nativeEvent?.stopPropagation &&
                  typeof ev?.nativeEvent?.stopPropagation === "function" &&
                  ev?.nativeEvent?.stopPropagation();

                option !== undefined &&
                  this.props.onUpdate(
                    participants.map((x) => {
                      if (x.id === item.id) {
                        x.recall = parseInt(option.key.toString());
                        x.selected = true;
                      }
                      return x;
                    })
                  );
              }}
              placeholder={i18n.t("messages:assisted:newdeparture:select")}
            />
          ) : (
            <Text>{"-"}</Text>
          );
        },
      },
      {
        key: "reason",
        name: "messages:assisted:newdeparture:reasonrecall",
        minWidth: 160,
        onRenderCell: (item: IParticipantNameRecordModel) => {
          return item.selected ? (
            <TextField
              maxLength={100}
              disabled={!isPassportValid(item)}
              value={item.reason ?? ""}
              errorMessage={item.errors ? item.errors.reason : undefined}
              onChange={(
                ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                ev?.preventDefault &&
                  typeof ev?.preventDefault === "function" &&
                  ev?.preventDefault();
                ev?.stopPropagation &&
                  typeof ev?.stopPropagation === "function" &&
                  ev?.stopPropagation();

                ev?.nativeEvent?.preventDefault &&
                  typeof ev?.nativeEvent?.preventDefault === "function" &&
                  ev?.nativeEvent?.preventDefault();
                ev?.nativeEvent?.stopPropagation &&
                  typeof ev?.nativeEvent?.stopPropagation === "function" &&
                  ev?.nativeEvent?.stopPropagation();

                item.reason = newValue ?? "";
                participants.forEach((x) => {
                  if (x.id === item.id) {
                    x.reason = newValue ?? "";
                  }
                });
                this.props.onUpdate(participants);
              }}
            />
          ) : (
            <Text>{"-"}</Text>
          );
        },
      },
    ];
  };

  render(): JSX.Element {
    const {
      styles,
      errors,
      criteria,
      mobile,
      arrival,
      request,
      participants,
      model,
      offer,
    } = this.props;
    const [classNames, subComponentStyles] =
      getFlightBookingParticipantsGridViewClassNames(styles!, {
        ...this.props,
        ...this.state,
      });

    const isDatePassportValid = (x: IParticipantNameRecordModel) =>
      x.passportExpiration !== undefined &&
      x.passportExpiration != null &&
      moment(x.passportExpiration).toDate() >
        moment(offer.departureDate).toDate();

    const isPassportValid = (x: IParticipantNameRecordModel) =>
      !this.props.arrival &&
      x.passport !== undefined &&
      x.passport != null &&
      x.passport.length > 0 &&
      isDatePassportValid(x);

    const onItemInvoked = (item: any) => {
      if (!item.selected) {
        const elements = participants.map((x) =>
          x.id === item.id ? { ...x, selected: true } : x
        );
        this.props.onParticipantsSelected(
          elements.filter((x) => x.selected ?? false)
        );
      }
    };

    const nameColumn: IGridColumn<any> = {
      key: "name",
      name: "messages:assisted:newdeparture:participant",
      minWidth: undefined,
      onRenderCell: (item: IParticipantNameRecordModel) => {
        return (
          <Stack
            onClick={() => onItemInvoked(item)}
            tokens={{ childrenGap: 2 }}
          >
            <Text>{`${item.lastName.toUpperCase()}, ${item.firstName}`}</Text>
            <Text variant={"small"}>
              {arrival ? item.estimatedArrival : item.estimatedDeparture}
            </Text>
          </Stack>
        );
      },
    };

    const standardColumns = [
      {
        key: "selection",
        name: "",
        minWidth: 60,
        maxWidth: 60,
        onRenderCell: (item: any) => {
          return (
            <Checkbox
              checked={item.selected}
              onChange={(ev, checked) => {
                console.log("checkbox", ev, item);
                ev?.preventDefault();
                ev?.stopPropagation();
                const elements = participants.map((x) =>
                  x.id === item.id ? { ...x, selected: checked ?? false } : x
                );
                console.log(elements);
                this.props.onParticipantsSelected(
                  elements.filter((x) => x.selected ?? false)
                );
              }}
            />
          );
        },
      },
      nameColumn,
      {
        key: "code",
        name: "messages:assisted:newdeparture:code",
        minWidth: 60,
        maxWidth: 60,
      },
      /*
      {
        key: this.props.arrival ? "estimatedArrival" : "estimatedDeparture",
        name: this.props.arrival
          ? "messages:assisted:newdeparture:estimatedarrivaldate"
          : "messages:assisted:newdeparture:estimateddeparturedate",
        minWidth: this.props.arrival ? 140 : undefined,
      },
      */
    ];

    return (
      <Stack className={classNames.root} tokens={{ childrenGap: 10 }}>
        <Text style={{ fontWeight: "bold" }}>
          {i18n.t("messages:assisted:participants")}
        </Text>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <CountryIcon
            {...this.props}
            styles={undefined}
            country={request.country}
          />
          <LabelField
            {...this.props}
            styles={undefined}
            label={request.name}
            content={request.versionTitle}
          />
          <IconButton
            iconProps={{ iconName: "Undo" }}
            onClick={() => {
              this.props.onCancel();
            }}
          />
        </Stack>
        <Stack tokens={{ childrenGap: 10 }}>
          <Text>{`${i18n.t(
            "transports:flights:participants:selection"
          )}: ${participants.filter((x) => x.selected).length.toString()}/${
            criteria.passengers
          }`}</Text>
          <Text>{`${i18n.t(
            "transports:flights:participants:count"
          )}: ${participants.length.toString()}`}</Text>
        </Stack>
        {errors.participants !== undefined && (
          <MessageBar messageBarType={MessageBarType.error}>
            {i18n.t("messages:assisted:newdeparture:participants:required")}
          </MessageBar>
        )}
        <GridView
          {...this.props}
          styles={
            mobile
              ? {
                  root: {
                    marginLeft: "-12px",
                    marginRight: "-12px",
                  },
                }
              : undefined
          }
          onItemInvoked={onItemInvoked}
          embedded={true}
          isItemSelectable={(item) =>
            this.props.arrival || isPassportValid(item)
          }
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
          isSelectedOnFocus={true}
          columns={standardColumns.concat(
            arrival
              ? this.arrivalColumns(() => true)
              : this.departureColumns(isPassportValid)
          )}
          onRenderTile={(
            item: IParticipantNameRecordModel,
            columnWidth: number,
            selection: Selection,
            _callback: (sel: Selection) => void
          ): JSX.Element => {
            return (
              <FlightBookingParticipantCard
                {...this.props}
                styles={subComponentStyles?.gridCard}
                arrival={arrival}
                item={item}
                reasons={leavereasons()}
                selection={selection}
                recalls={model ? model.recalls : []}
                columnWidth={columnWidth}
                onRecallChanged={(newRecallId: number, _recallText: string) => {
                  this.props.onUpdate(
                    participants.map((x) => {
                      if (x.id === item.id) {
                        x.recall = newRecallId;
                      }
                      return x;
                    })
                  );
                  this.forceUpdate();
                }}
                onReasonChanged={(reason: string) => {
                  item.leaveReason = reason;
                  participants.forEach((x) => {
                    if (x.id === item.id) {
                      x.leaveReason = reason;
                    }
                  });
                  this.props.onUpdate(participants);
                }}
                onStatusReasonChanged={(reason: string) => {
                  item.reason = reason ?? "";
                  participants.forEach((x) => {
                    if (x.id === item.id) {
                      x.reason = reason ?? "";
                    }
                  });
                  this.props.onUpdate(participants);
                }}
                onSelected={(
                  item: IParticipantNameRecordModel,
                  checked?: boolean
                ) => {
                  const elements = participants.map((x) =>
                    x.id === item.id ? { ...x, selected: checked ?? true } : x
                  );
                  this.props.onParticipantsSelected(
                    elements.filter((x) => x.selected ?? false)
                  );
                }}
              />
            );
          }}
          onDataPaging={() => {}}
          options={GridViewOptionsBuilder.getOptions(
            GridViewMode.list,
            "name",
            false,
            true
          )}
          emptyMessage={i18n.t(
            participants.length === 0
              ? arrival
                ? "messages:assisted:participants:arrivee:noparticipants"
                : "messages:assisted:participants:depart:noparticipants"
              : "messages:assisted:participants:emptymessage"
          )}
          items={participants}
          isItemSelected={(item: IParticipantNameRecordModel) => {
            return item.selected;
          }}
        />
        <div style={{ height: "20px", clear: "both" }}></div>
      </Stack>
    );
  }
}
