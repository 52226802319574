import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IParticipantPositionCardStyleProps,
  IParticipantPositionCardStyles,
} from "../ParticipantPositionCard";

export interface IParticipantPositionsControlSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IParticipantPositionCardStyleProps,
    IParticipantPositionCardStyles
  >;
}

export interface IParticipantPositionsControlStyles
  extends IAmigoBaseStyles<IParticipantPositionsControlSubComponentStyles> {}

export interface IParticipantPositionsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantPositionsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantPositionsControlStyleProps,
    IParticipantPositionsControlStyles
  >,
  props?: IParticipantPositionsControlStyleProps
): [
  IProcessedStyleSet<IParticipantPositionsControlStyles>,
  IParticipantPositionsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantPositionsControlStyleProps,
  IParticipantPositionsControlStyles
> = (
  _props: IParticipantPositionsControlStyleProps
): IParticipantPositionsControlStyles => {
  return {
    root: ["amigo-ParticipantPositionsControl", {}],
  };
};
