import { styled } from "@fluentui/react/lib/Utilities";
import { IMessagesPageProps } from "./MessagesPage.types";
import {
  getStyles,
  IMessagesPageStyleProps,
  IMessagesPageStyles,
} from "./MessagesPage.styles";
import { MessagesPageComponent } from "./MessagesPage.base";

/**
 * MessagesPage description
 */
export const MessagesPage = styled<
  IMessagesPageProps,
  IMessagesPageStyleProps,
  IMessagesPageStyles
>(MessagesPageComponent, getStyles);
