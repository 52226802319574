import { Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getRequestParticipantEndParticipationClassNames } from "./RequestParticipantEndParticipation.styles";
import {
  IRequestParticipantEndParticipationProps,
  IRequestParticipantEndParticipationState,
} from "./RequestParticipantEndParticipation.types";

export class RequestParticipantEndParticipationComponent extends React.Component<
  IRequestParticipantEndParticipationProps,
  IRequestParticipantEndParticipationState
> {
  constructor(props: IRequestParticipantEndParticipationProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames, subComponentStyles] =
      getRequestParticipantEndParticipationClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { endparticipation } = this.props.participant;
    return (
      <Stack
        className={classNames.root}
        horizontal={!this.props.mobile}
        tokens={{ childrenGap: mobile ? 10 : 20 }}
      >
        <StackItem>
          <LabelField
            {...this.props}
            styles={subComponentStyles?.label}
            label={i18n.t("requests:participants:item:end:type")}
            content={
              (endparticipation.type ?? "").length === 0 ? (
                <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }}></div>
              ) : (
                endparticipation.type
              )
            }
          />
          <LabelField
            {...this.props}
            styles={subComponentStyles?.label}
            label={i18n.t("requests:participants:item:end:recall:status")}
            content={
              (endparticipation.status ?? "").length === 0 ? (
                <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }}></div>
              ) : (
                endparticipation.status
              )
            }
          />
        </StackItem>
        <StackItem>
          <LabelField
            {...this.props}
            styles={subComponentStyles?.label}
            label={i18n.t("requests:participants:item:end:notes")}
            content={
              (endparticipation.notes ?? "").length === 0 ? (
                <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }}></div>
              ) : (
                endparticipation.notes
              )
            }
          />
          <LabelField
            {...this.props}
            styles={subComponentStyles?.label}
            label={i18n.t("requests:participants:item:end:recall:reason")}
            content={
              (endparticipation.reason ?? "").length === 0 ? (
                <div dangerouslySetInnerHTML={{ __html: "&nbsp;" }}></div>
              ) : (
                endparticipation.reason
              )
            }
          />
        </StackItem>
      </Stack>
    );
  }
}
