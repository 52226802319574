import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IBillingControlSubComponentStyles {}

export interface IBillingControlStyles
  extends IAmigoBaseStyles<IBillingControlSubComponentStyles> {}

export interface IBillingControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getBillingControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IBillingControlStyleProps,
    IBillingControlStyles
  >,
  props?: IBillingControlStyleProps
): [
  IProcessedStyleSet<IBillingControlStyles>,
  IBillingControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IBillingControlStyleProps,
  IBillingControlStyles
> = (_props: IBillingControlStyleProps): IBillingControlStyles => {
  return {};
};
