import { ReactComponent as ManRun } from "../../_styles/images/man-run-100.svg"
import { ReactComponent as Contract } from "../../_styles/images/contract-100.svg"

export function WorkerEscapedRedSVG(): JSX.Element {
  return (
    <ManRun fill='red' style={{ width: "30px", marginTop: "6px" }}></ManRun>
  );
};

export function WorkerEscapedGreenSVG(): JSX.Element {
  return (
    <ManRun fill='green' style={{ width: "30px", marginTop: "6px" }}></ManRun>
  );
};

export function WorkerEscapedWhiteSVG(): JSX.Element {
  return (
    <ManRun fill='white' style={{ width: "30px", marginTop: "6px" }}></ManRun>
  );
};

export function ContractRenewdRedSVG(): JSX.Element {
  return (
    <Contract fill="red" style={{ width: "25px", marginTop: "10px" }}></Contract>
  );
};

export function ContractRenewdGreenSVG(): JSX.Element {
  return (
    <Contract fill="green" style={{ width: "25px", marginTop: "10px" }}></Contract>
  );
};

export function ContractRenewdWhiteSVG(): JSX.Element {
  return (
    <Contract fill="white" style={{ width: "25px", marginTop: "10px" }}></Contract>
  );
};