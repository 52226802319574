import { styled } from "@fluentui/react/lib/Utilities";
import { ITravelWarningsSectionProps } from "./TravelWarningsSection.types";
import {
  getStyles,
  ITravelWarningsSectionStyleProps,
  ITravelWarningsSectionStyles,
} from "./TravelWarningsSection.styles";
import { TravelWarningsSectionComponent } from "./TravelWarningsSection.base";

/**
 * TravelWarningsSection
 */
export const TravelWarningsSection = styled<
  ITravelWarningsSectionProps,
  ITravelWarningsSectionStyleProps,
  ITravelWarningsSectionStyles
>(TravelWarningsSectionComponent, getStyles);
