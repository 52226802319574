import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantHistoryControlProps } from "./ParticipantHistoryControl.types";
import {
  getStyles,
  IParticipantHistoryControlStyleProps,
  IParticipantHistoryControlStyles,
} from "./ParticipantHistoryControl.styles";
import { ParticipantHistoryControlComponent } from "./ParticipantHistoryControl.base";

/**
 * ParticipantHistoryControl
 */
export const ParticipantHistoryControl = styled<
  IParticipantHistoryControlProps,
  IParticipantHistoryControlStyleProps,
  IParticipantHistoryControlStyles
>(ParticipantHistoryControlComponent, getStyles);
