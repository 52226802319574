import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportRequestsDepartureSectionSubComponentStyles {}

export interface ITransportRequestsDepartureSectionStyles
  extends IAmigoBaseStyles<ITransportRequestsDepartureSectionSubComponentStyles> {}

export interface ITransportRequestsDepartureSectionStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportRequestsDepartureSectionClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportRequestsDepartureSectionStyleProps,
    ITransportRequestsDepartureSectionStyles
  >,
  props?: ITransportRequestsDepartureSectionStyleProps
): [
  IProcessedStyleSet<ITransportRequestsDepartureSectionStyles>,
  ITransportRequestsDepartureSectionSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportRequestsDepartureSectionStyleProps,
  ITransportRequestsDepartureSectionStyles
> = (
  _props: ITransportRequestsDepartureSectionStyleProps
): ITransportRequestsDepartureSectionStyles => {
  return {
    root: ["amigo-TransportRequestsDepartureSection", {}],
  };
};
