import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IRequestParticipantPathwayTimelineSubComponentStyles {}

export interface IRequestParticipantPathwayTimelineStyles
  extends IAmigoBaseStyles<IRequestParticipantPathwayTimelineSubComponentStyles> {}

export interface IRequestParticipantPathwayTimelineStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantPathwayTimelineClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantPathwayTimelineStyleProps,
    IRequestParticipantPathwayTimelineStyles
  >,
  props?: IRequestParticipantPathwayTimelineStyleProps
): [
  IProcessedStyleSet<IRequestParticipantPathwayTimelineStyles>,
  IRequestParticipantPathwayTimelineSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantPathwayTimelineStyleProps,
  IRequestParticipantPathwayTimelineStyles
> = (
  _props: IRequestParticipantPathwayTimelineStyleProps
): IRequestParticipantPathwayTimelineStyles => {
  return {
    root: ["amigo-RequestParticipantPathwayTimeline", {}],
  };
};
