import {
  ContextualMenuItemType,
  Icon,
  IconButton,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import i18n from "../../services/i18n";
import { MessageNotification } from "../MessageNotification";
import { getNavBarClassNames } from "./NavBar.styles";
import { INavBarProps, INavBarState } from "./NavBar.types";

export class NavBarComponent extends React.Component<
  INavBarProps,
  INavBarState
> {
  constructor(props: INavBarProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, nav, route } = this.props;
    const [classNames] = getNavBarClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack className={classNames.root}>
        <Stack horizontal>
          <StackItem>
            <Stack>
              <div style={{ textAlign: "right" }}>
                <Text as="h3" className={classNames.appTitle}>
                  {this.props.tokens?.appTitle}
                </Text>
                <Icon
                  {...this.props.environment}
                  className={classNames.environment}
                />
              </div>
              {this.props.accountDisplayName && !this.props.mobile && (
                <Text as="h4" className={classNames.accountName}>
                  {this.props.accountDisplayName}
                </Text>
              )}
            </Stack>
          </StackItem>
          <StackItem>
            <MessageNotification
              {...this.props}
              styles={undefined}
              onClick={() => {
                if (this.props.onDismiss !== undefined) {
                  this.props.onDismiss();
                }
                route.history.push("/messages");
              }}
            />
          </StackItem>

          {nav && <StackItem>{nav(route)}</StackItem>}
          <StackItem>
            <TooltipHost content={i18n.t("profile:language")}>
              <IconButton
                data-automation-id="nvx:language"
                className={classNames.iconButton}
                iconProps={{
                  iconName: "LocaleLanguage",
                  className: classNames.iconButton,
                }}
                menuIconProps={{
                  style: { display: "none" },
                  iconName: undefined,
                }}
                menuProps={{
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "english",
                      text: i18n.t("language:en"),
                      "data-automation-id": "nav:language:en",
                      className: classNames.english,
                      itemProps: {
                        className: classNames.english,
                      },
                      onClick: () => {
                        if (this.props.onDismiss !== undefined) {
                          this.props.onDismiss();
                        }
                        this.props.onLanguageChanged("en");
                        route.history.push(
                          "/reload",
                          Object.assign(
                            { location: route.location.pathname },
                            route.location.state
                          )
                        );
                      },
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: "french",
                      text: i18n.t("language:fr"),
                      "data-automation-id": "nav:language:fr",
                      className: classNames.french,
                      itemProps: {
                        className: classNames.french,
                      },
                      onClick: () => {
                        if (this.props.onDismiss !== undefined) {
                          this.props.onDismiss();
                        }
                        this.props.onLanguageChanged("fr");
                        route.history.push(
                          "/reload",
                          Object.assign(
                            { location: route.location.pathname },
                            route.location.state
                          )
                        );
                      },
                    },
                    {
                      key: "divider_2",
                      itemType: ContextualMenuItemType.Divider,
                    } /*,
                        {
                            key: 'spanish',
                            text: i18n.t('language:es'),
                            style: {
                                fontWeight: this.props.language === 'es' ? 'bold' : 'normal'
                            },
                            onClick: () => this.props.onLanguageChanged('es')
                        } */,
                  ],
                }}
              />
            </TooltipHost>
          </StackItem>
          <StackItem>
            <TooltipHost content={i18n.t("menu:profile")}>
              <IconButton
                data-automation-id="nav:profile"
                className={classNames.iconButton}
                iconProps={{
                  iconName: "Signin",
                  className: classNames.iconButton,
                }}
                menuIconProps={{
                  style: { display: "none" },
                  iconName: undefined,
                }}
                menuProps={{
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "profile",
                      "data-automation-id": "nav:profile:link",
                      text: i18n.t("menu:profile"),
                      onClick: (_s, _e) => {
                        if (this.props.onDismiss !== undefined) {
                          this.props.onDismiss();
                        }
                        route.history.push("/profile");
                      },
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: "logout",
                      "data-automation-id": "nav:profile:logout",
                      text: i18n.t("menu:logout"),
                      onClick: (_s, _e) => {
                        if (this.props.onDismiss !== undefined) {
                          this.props.onDismiss();
                        }
                        route.history.push("/deconnexion");
                      },
                    },
                  ],
                }}
              />
            </TooltipHost>
          </StackItem>
        </Stack>
        {this.props.accountDisplayName && this.props.mobile && (
          <Text as="h4" className={classNames.accountName}>
            {this.props.accountDisplayName}
          </Text>
        )}
      </Stack>
    );
  }
}
