import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";
import {
  IPeriodPositionCountTextStyleProps,
  IPeriodPositionCountTextStyles,
} from "../PeriodPositionCountText";

export interface IRequestPeriodsControlSubComponentStyles {
  participantCount?: IStyleFunctionOrObject<
    IPeriodPositionCountTextStyleProps,
    IPeriodPositionCountTextStyles
  >;
}

export interface IRequestPeriodsControlStyles
  extends IAmigoBaseStyles<IRequestPeriodsControlSubComponentStyles> {
  requestPeriodCard?: IStyle;
  msGridRow?: IStyle;
}

export interface IRequestPeriodsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestPeriodsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestPeriodsControlStyleProps,
    IRequestPeriodsControlStyles
  >,
  props?: IRequestPeriodsControlStyleProps
): [
  IProcessedStyleSet<IRequestPeriodsControlStyles>,
  IRequestPeriodsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestPeriodsControlStyleProps,
  IRequestPeriodsControlStyles
> = (props: IRequestPeriodsControlStyleProps): IRequestPeriodsControlStyles => {
  return {
    requestPeriodCard: [
      "amigo-RequestPeriod-Card",
      {
        float: "left",
        height: "120px",
        width: "32%",
        padding: "5px",
        marginBottom: "20px",
        selectors: {
          [MediaQueries.tablet]: {
            width: "100%",
          },
          [MediaQueries.mobile]: {
            width: "100%",
          },
        },
      },
    ],
    msGridRow: props.tokens.msGridRow,
  };
};
