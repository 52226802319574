import {
  ICommandBarStyleProps,
  ICommandBarStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IMessageReplyCommandBarSubComponentStyles {
  commandBar?: IStyleFunctionOrObject<ICommandBarStyleProps, ICommandBarStyles>;
}

export interface IMessageReplyCommandBarStyles
  extends IAmigoBaseStyles<IMessageReplyCommandBarSubComponentStyles> {}

export interface IMessageReplyCommandBarStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessageReplyCommandBarClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessageReplyCommandBarStyleProps,
    IMessageReplyCommandBarStyles
  >,
  props?: IMessageReplyCommandBarStyleProps
): [
  IProcessedStyleSet<IMessageReplyCommandBarStyles>,
  IMessageReplyCommandBarSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessageReplyCommandBarStyleProps,
  IMessageReplyCommandBarStyles
> = (
  _props: IMessageReplyCommandBarStyleProps
): IMessageReplyCommandBarStyles => {
  return {
    root: ["amigo-MessageReplyCommandBar", {}],
    subComponentStyles: {
      commandBar: {
        root: {
          minWidth: "210px",
          paddingLeft: "0px",
          paddingRight: "0px",
        },
      },
    },
  };
};
