import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { FontWeights } from "@fluentui/react/lib/Styling";

export interface IEnterpriseDocumentCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IEnterpriseDocumentCardStyles
  extends IAmigoBaseStyles<IEnterpriseDocumentCardSubComponentStyles> {}

export interface IEnterpriseDocumentCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseDocumentCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseDocumentCardStyleProps,
    IEnterpriseDocumentCardStyles
  >,
  props?: IEnterpriseDocumentCardStyleProps
): [
  IProcessedStyleSet<IEnterpriseDocumentCardStyles>,
  IEnterpriseDocumentCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles
> = (
  props: IEnterpriseDocumentCardStyleProps
): IEnterpriseDocumentCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
      gridViewCard: {
        subComponentStyles: {
          title: {
            root: {
              color: "#201f1e",
              fontSize: "12px",
              fontWeight: FontWeights.semibold,
            },
          },
        },
      },
    },
  };
};
