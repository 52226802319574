import { Grid } from "fluentui-react-grid";
import * as React from "react";
import { LabelField } from "../../../components/LabelField";
import i18n from "../../../services/i18n";
import { getPositionPanelClassNames } from "./PositionPanel.styles";
import {
  IPositionPanelProps,
  IPositionPanelState,
} from "./PositionPanel.types";

export class PositionPanelComponent extends React.Component<
  IPositionPanelProps,
  IPositionPanelState
> {
  constructor(props: IPositionPanelProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, position } = this.props;
    const [classNames] = getPositionPanelClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return position === undefined ? (
      <div></div>
    ) : (
      <Grid dir="ltr" className={classNames.root}>
        <Grid.Row>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:title")}
              content={position.title}
            />
          </Grid.Col>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:cnp")}
              content={position.cnp}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:skills")}
              content={
                position.skills === undefined ||
                position.skills.length === 0 ? (
                  i18n.t("requests:positions:noskills")
                ) : (
                  <div>
                    {position.skills.map((x) => (
                      <div>{x}</div>
                    ))}
                  </div>
                )
              }
            />
          </Grid.Col>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:otherskills")}
              content={position.otherskills}
            />
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:rate")}
              content={position.hourlyRate}
            />
          </Grid.Col>
          <Grid.Col sizeMd={6} sizeSm={12}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("requests:positions:desc")}
              content={position.functionDescription}
            />
          </Grid.Col>
        </Grid.Row>
      </Grid>
    );
  }
}
