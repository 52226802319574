import axios from "axios";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import { IParticipantHistory } from "../../models/Participants/IParticipantHistory";
import AuthService from "../AuthService";

class ParticipantHistorysService {
  private _participantHistorys?: IParticipantHistory[] = undefined;
  private _participantHistorysPages: number = 1;

  public init(): void {
    this._participantHistorys = undefined;
    this._participantHistorysPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("enterpriseName", "LibelleEmployeur")
      .replace("requestNumber", "NumeroDemande")
      .replace("requestId", "DemandeId")
      .replace("arrivalDate", "DateArrivee")
      .replace("departureDate", "DateDepart")
      .replace("name", "Titre");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async getParticipantHistory(
    enterpriseId: number,
    participantId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IParticipantHistory[]> {
    const _this = this;
    let currentPage = this._participantHistorysPages;
    let combine: boolean;
    if (next && _this._participantHistorys !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/participants/${participantId}/history`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._participantHistorys !== undefined) {
        _this._participantHistorysPages = currentPage;
        _this._participantHistorys = _this._participantHistorys.concat(items);
      } else {
        _this._participantHistorysPages = 1;
        _this._participantHistorys = this._participantHistorys = items;
      }
      return _this._participantHistorys ?? [];
    } catch (ex) {
      console.log("error");
      return _this._participantHistorys ?? [];
    }
  }
}

export default new ParticipantHistorysService();
