import {
  FontWeights,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextStyles,
} from "@fluentui/react";
import { ICardSectionStyles } from "@fluentui/react-cards";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface IFlightSearchCriteriaSubComponentStyles {
  siteTextStyles?: ITextStyles;
  descriptionTextStyles?: ITextStyles;
  helpfulTextStyles?: ITextStyles;
  footerCardSectionStyles?: ICardSectionStyles;
  iconStyles?: IIconStyles;
}

export interface IFlightSearchCriteriaStyles
  extends IAmigoBaseStyles<IFlightSearchCriteriaSubComponentStyles> {}

export interface IFlightSearchCriteriaStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchCriteriaClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchCriteriaStyleProps,
    IFlightSearchCriteriaStyles
  >,
  props?: IFlightSearchCriteriaStyleProps
): [
  IProcessedStyleSet<IFlightSearchCriteriaStyles>,
  IFlightSearchCriteriaSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchCriteriaStyleProps,
  IFlightSearchCriteriaStyles
> = (props: IFlightSearchCriteriaStyleProps): IFlightSearchCriteriaStyles => {
  return {
    root: [
      "amigo-FlightSearchCriteria",
      {
        width: "100%",
        [MediaQueries.mobile]: {
          padding: 0,
          width: "100%",
        },
      },
    ],
    subComponentStyles: {
      siteTextStyles: {
        root: {
          color: props.theme.palette.themePrimary,
          fontWeight: FontWeights.semibold,
        },
      },
      descriptionTextStyles: {
        root: {
          color: "#333333",
          fontWeight: FontWeights.regular,
          maxWidth: "548px",
        },
      },
      helpfulTextStyles: {
        root: {
          color: "#333333",
          fontWeight: FontWeights.regular,
        },
      },
      footerCardSectionStyles: {
        root: {
          alignSelf: "stretch",
          borderLeft: "1px solid #F3F2F1",
        },
      },
      iconStyles: {
        root: {
          color: "#0078D4",
          fontSize: 16,
          fontWeight: FontWeights.regular,
        },
      },
    },
  };
};
