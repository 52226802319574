import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IRequestsParticipantsCardStyleProps,
  IRequestsParticipantsCardStyles,
} from "../RequestsParticipantsCard";

export interface IRequestPeriodParticipantsControlSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IRequestsParticipantsCardStyleProps,
    IRequestsParticipantsCardStyles
  >;
}

export interface IRequestPeriodParticipantsControlStyles
  extends IAmigoBaseStyles<IRequestPeriodParticipantsControlSubComponentStyles> {}

export interface IRequestPeriodParticipantsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestPeriodParticipantsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestPeriodParticipantsControlStyleProps,
    IRequestPeriodParticipantsControlStyles
  >,
  props?: IRequestPeriodParticipantsControlStyleProps
): [
  IProcessedStyleSet<IRequestPeriodParticipantsControlStyles>,
  IRequestPeriodParticipantsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestPeriodParticipantsControlStyleProps,
  IRequestPeriodParticipantsControlStyles
> = (
  _props: IRequestPeriodParticipantsControlStyleProps
): IRequestPeriodParticipantsControlStyles => {
  return {
    root: ["amigo-RequestPeriodParticipantsControl", {}],
  };
};
