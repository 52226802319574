import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantFormProps } from "./RequestParticipantForm.types";
import {
  getStyles,
  IRequestParticipantFormStyleProps,
  IRequestParticipantFormStyles,
} from "./RequestParticipantForm.styles";
import { RequestParticipantFormComponent } from "./RequestParticipantForm.base";

/**
 * RequestParticipantForm description
 */
export const RequestParticipantForm = styled<
  IRequestParticipantFormProps,
  IRequestParticipantFormStyleProps,
  IRequestParticipantFormStyles
>(RequestParticipantFormComponent, getStyles);
