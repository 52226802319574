import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantCardProps } from "./ParticipantCard.types";
import {
  getStyles,
  IParticipantCardStyleProps,
  IParticipantCardStyles,
} from "./ParticipantCard.styles";
import { ParticipantCardComponent } from "./ParticipantCard.base";

/**
 * ParticipantCard description
 */
export const ParticipantCard = styled<
  IParticipantCardProps,
  IParticipantCardStyleProps,
  IParticipantCardStyles
>(ParticipantCardComponent, getStyles);
