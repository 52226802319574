import * as React from "react";
import { TextField } from "@fluentui/react";
import i18n from "../../../services/i18n";

export interface ICreditCardNameHolderFieldProps {
  value: string;
  onChange: (newValue: string) => void;
  onFocus: () => void;
  errorMessage: string | JSX.Element | undefined;
}

export interface ICreditCardNameHolderFieldState {}

export class CreditCardNameHolderField extends React.Component<
  ICreditCardNameHolderFieldProps,
  ICreditCardNameHolderFieldState
> {
  constructor(props: ICreditCardNameHolderFieldProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div style={{ marginTop: "20px" }}>
        <TextField
          required
          onFocus={this.props.onFocus}
          errorMessage={this.props.errorMessage}
          label={i18n.t("enterprises:paymentmethods:name")}
          value={this.props.value}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            _newValue?: string
          ) => {
            this.props.onChange((event.target as any).value);
          }}
        />
      </div>
    );
  }
}
