import { IDocumentCategory } from "../../models/Documents/IDocumentCategory";
import { IDocumentSection } from "../../models/Documents/IDocumentSection";
import axios from "axios";
import AuthService from "../AuthService";

class DocumentCategoryService {
  public init(): void {
    /* nothing to init yet */
  }

  private resolveIconName(item: IDocumentCategory): string {
    if (item.code.indexOf("ADRES") !== -1) return "MapPin";
    if (item.code.indexOf("DOS EMPL") !== -1) return "ProfileSearch";
    if (item.code.indexOf("PAIE") !== -1) return "PaymentCard";
    if (item.code.indexOf("PERM COND") !== -1) return "Car";
    if (item.code.indexOf("PERM COND") !== -1) return "Car";
    if (item.code.indexOf("4 ANS") !== -1) return "ScheduleEventAction";
    if (item.code.indexOf("SAL") !== -1) return "Money";
    if (item.code.indexOf("RVER") !== -1) return "Money";
    if (item.code.indexOf("TARIF") !== -1) return "AllCurrency";
    if (item.code.indexOf("RAMQ") !== -1) return "Health";
    if (item.code.indexOf("TRANS") !== -1) return "Airplane";
    if (item.code.indexOf("NAS") !== -1) return "SecurityGroup";
    if (item.code.indexOf("BIO_") !== -1) return "Chart";
    return "Documentation";
  }

  public getDocumentCategories(): Promise<IDocumentCategory[]> {
    return axios
      .post("/api/documents/categories", {}, AuthService.getAuthHeader())
      .then((x) => {
        const elements: IDocumentCategory[] = x.data as any;
        elements.forEach((x) => {
          x.iconName = this.resolveIconName(x);
        });
        return elements;
      });
  }

  public getDocumentSections(
    type: string,
    categoryId: number
  ): Promise<IDocumentSection[]> {
    return axios
      .post(
        `/api/documents/t/sections/${type}/${categoryId}`,
        {},
        AuthService.getAuthHeader()
      )
      .then((x) => x.data as any);
  }
}

export default new DocumentCategoryService();
