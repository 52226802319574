import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IFlightDirectOffersSubComponentStyles {}

export interface IFlightDirectOffersStyles
  extends IAmigoBaseStyles<IFlightDirectOffersSubComponentStyles> {}

export interface IFlightDirectOffersStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightDirectOffersClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightDirectOffersStyleProps,
    IFlightDirectOffersStyles
  >,
  props?: IFlightDirectOffersStyleProps
): [
  IProcessedStyleSet<IFlightDirectOffersStyles>,
  IFlightDirectOffersSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightDirectOffersStyleProps,
  IFlightDirectOffersStyles
> = (_props: IFlightDirectOffersStyleProps): IFlightDirectOffersStyles => {
  return {
    root: ["amigo-FlightDirectOffers", {}],
  };
};
