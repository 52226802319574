import { Card, ICardTokens } from "@fluentui/react-cards";
import {
  DetailsRowCheck,
  FontIcon,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { getGridViewCardClassNames } from "./GridViewCard.styles";
import { IGridViewCardProps, IGridViewCardState } from "./GridViewCard.types";

export class GridViewCardComponent extends React.Component<
  IGridViewCardProps,
  IGridViewCardState
> {
  constructor(props: IGridViewCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const calloutProps = { gapSpace: 0 };

    const cardTokens: ICardTokens = Object.assign(
      this.props.cardTokens ?? this.props.tokens?.defaultCardTokens ?? {},
      {
        minWidth: this.props.columnWidth,
      }
    );
    const _this = this;
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getGridViewCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
        boxed: this.props.boxed,
      }
    );
    return (
      <Card
        className={classNames.root}
        horizontal
        onClick={(ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
          !(ev?.isDefaultPrevented() ?? false) &&
            _this.props.onClick &&
            _this.props.onClick();
        }}
        tokens={cardTokens}
      >
        <Card.Section grow>
          {typeof this.props.title === "string" ? (
            <Text styles={subComponentStyles?.title}>{this.props.title}</Text>
          ) : (
            <>{this.props.title}</>
          )}
          <div style={{ clear: "both" }}></div>
          {this.props.children}
          {(this.props.footerText || this.props.footerIconName) && (
            <>
              <div style={{ height: "16px" }}></div>
              <div className={classNames.footerContainer}>
                <div className={classNames.footerIconContainer}>
                  <TooltipHost content={this.props.footerText}>
                    <FontIcon iconName={this.props.footerIconName ?? "Info"} />
                  </TooltipHost>
                </div>
                <TooltipHost
                  hostClassName={classNames.footerTextContainer}
                  content={this.props.footerText}
                  calloutProps={calloutProps}
                >
                  <Text variant="small" styles={subComponentStyles?.footer}>
                    {this.props.footerText}
                  </Text>
                </TooltipHost>
              </div>
            </>
          )}
        </Card.Section>
        <Card.Section
          styles={subComponentStyles?.iconSection}
          tokens={this.props.tokens?.iconCardSectionTokens}
        >
          {this.props.onSelected && (
            <DetailsRowCheck
              style={this.props.selectorStyle}
              onClick={(ev?: React.MouseEvent<HTMLElement, MouseEvent>) => {
                ev?.preventDefault();
                ev?.stopPropagation();
                this.props.onSelected && this.props.onSelected();
              }}
              checkClassName={classNames.selector}
              className={classNames.selector}
              styles={subComponentStyles?.selectorComponent}
              canSelect={true}
              selected={this.props.selected}
            />
          )}
          {this.props.icons}
          <Stack.Item grow={1}>
            <span />
          </Stack.Item>
        </Card.Section>
      </Card>
    );
  }
}
