import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import {
  ApplicationInsights,
  DistributedTracingModes,
  ICustomProperties,
  IEventTelemetry,
} from "@microsoft/applicationinsights-web";
// import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

class TelemetryIntitializer {
  private reactPlugin: any | null = null;
  private appInsights: ApplicationInsights | null = null;

  private initialize = (instrumentationKey: string, browserHistory: any) => {
    if (!browserHistory) {
      throw new Error("Could not initialize Telemetry Service");
    }
    if (!instrumentationKey) {
      return;
      /*
      throw new Error(
        "Instrumentation key not provided in {telemetry-provider}"
      );
      */
    }

    this.reactPlugin = new ReactPlugin();

    this.appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        maxBatchInterval: 0,
        enableAutoRouteTracking: true,
        isCookieUseDisabled: true,
        enableSessionStorageBuffer: true,
        enableCorsCorrelation: true,
        enableResponseHeaderTracking: true,
        disableFetchTracking: false,
        disableAjaxTracking: false,
        disableCorrelationHeaders: false,
        disableTelemetry: false,
        distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
        extensions: [this.reactPlugin],
        extensionConfig: {
          [this.reactPlugin.identifier]: {
            history: browserHistory,
          },
        },
      },
    });
    this.appInsights &&
      this.appInsights != null &&
      this.appInsights.loadAppInsights();
  };

  /**
   * Create the App Insights Telemetry Service
   * @return {{reactPlugin: ReactPlugin, appInsights: Object, initialize: Function}} - Object
   */
  private createTelemetryService = () => {
    /**
     * Initialize the Application Insights class
     * @param {string} instrumentationKey - Application Insights Instrumentation Key
     * @param {Object} browserHistory - client's browser history, supplied by the withRouter HOC
     * @return {void}
     */

    const { reactPlugin, appInsights, initialize } = this;
    return { reactPlugin, appInsights, initialize };
  };

  public trackEvent = (
    event: IEventTelemetry,
    customProperties?: ICustomProperties
  ) => {
    this.ai.appInsights !== undefined &&
      this.ai.appInsights != null &&
      this.ai.appInsights.trackEvent(event, customProperties);
  };

  private ai = this.createTelemetryService();
  public getAppInsights = () => this.appInsights;
}

export default new TelemetryIntitializer();
