import { Dialog, Text } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import i18n from "src/services/i18n";
import { FlightSearchOffsetOffers } from "../FlightSearchOffsetOffers";
import { FlightSearchResults } from "../FlightSearchResults";
import { getFlightSearchResultsContainerClassNames } from "./FlightSearchResultsContainer.styles";
import {
  IFlightSearchResultsContainerProps,
  IFlightSearchResultsContainerState,
} from "./FlightSearchResultsContainer.types";

export class FlightSearchResultsContainerComponent extends React.Component<
  IFlightSearchResultsContainerProps,
  IFlightSearchResultsContainerState
> {
  constructor(props: IFlightSearchResultsContainerProps) {
    super(props);
    this.state = {
      baggageinfo: false,
    };
  }

  render(): JSX.Element {
    const { styles, mobile, searching, data, criteria, flexibles } = this.props;
    const { baggageinfo } = this.state;
    const [classNames] = getFlightSearchResultsContainerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const empty: boolean =
      searching || !data
        ? false
        : data?.filter((x) => x.itineraryGroups[0].itineraries.length > 0)
            .length === 0 ?? false;
    return (
      <div className={classNames.root}>
        <div></div>
        <Text>
          {i18n.formatString(
            i18n.t(
              criteria.passengers > 1
                ? "transports:flights:totalpricewarning:plural"
                : "transports:flights:totalpricewarning:singular"
            ),
            criteria.passengers.toString()
          )}
        </Text>
        <div style={{ height: "5px" }}></div>
        {/*
        <Link
          onClick={() => {
            this.setState({ baggageinfo: true });
            return false;
          }}
        >
          {i18n.t("transports:flights:totalpricewarning:baggagelink")}
        </Link>
        */}
        {!searching && flexibles && (
          <FlightSearchOffsetOffers
            {...this.props}
            styles={undefined}
            flexibles={flexibles}
            criteria={criteria}
          />
        )}
        {!searching && empty && (
          <Card
            styles={{
              root: {
                width: "100%",
                maxWidth: "100%",
                marginTop: mobile ? "10px" : "20px",
                backgroundColor: this.props.theme.palette.white,
              },
            }}
            tokens={{ childrenGap: 10 }}
          >
            <Card.Section
              styles={{ root: { width: "100%", maxWidth: "100%" } }}
              grow
            >
              <h3 style={{ marginLeft: "5px" }}>
                {i18n.t("transports:flights:notfound")}
              </h3>
            </Card.Section>
          </Card>
        )}
        <FlightSearchResults {...this.props} styles={undefined} />
        <Dialog
          minWidth={mobile ? undefined : 800}
          isOpen={baggageinfo}
          isBlocking={true}
          isDarkOverlay={true}
          modalProps={{
            isOpen: baggageinfo,
            isBlocking: true,
            isDarkOverlay: true,
            onDismiss: () => this.setState({ baggageinfo: false }),
          }}
          onDismiss={() => this.setState({ baggageinfo: false })}
        >
          <img src="/img/baggage.png" />
        </Dialog>
      </div>
    );
  }
}
