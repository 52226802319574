import { Icon, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getEnterpriseDocumentCardClassNames } from "./EnterpriseDocumentCard.styles";
import {
  IEnterpriseDocumentCardProps,
  IEnterpriseDocumentCardState,
} from "./EnterpriseDocumentCard.types";
import Moment from "moment";
import DateText from "src/components/DateText";

export class EnterpriseDocumentCardComponent extends React.Component<
  IEnterpriseDocumentCardProps,
  IEnterpriseDocumentCardState
> {
  constructor(props: IEnterpriseDocumentCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName, onDownload } =
      this.props;
    const [classNames] = getEnterpriseDocumentCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={undefined}
        boxed={true}
        title={
          <div
            style={{
              color: "#201f1e",
              fontSize: "12px",
              height: "32px",
              fontWeight: "bold",
            }}
          >
            {item.name.replace(/_/g, " ")}
          </div>
        }
        columnWidth={columnWidth}
        footerText={Moment(item.createdOn).format("YYYY-MM-DD HH:mm")}
        footerIconName={footerIconName ?? "Calendar"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            <TooltipHost content={item.name}>
              <Icon iconName="DocLibrary" />
            </TooltipHost>
            <TooltipHost content={item.name}>
              <Icon
                iconName="CloudDownload"
                styles={{ root: { cursor: "pointer" } }}
                onClick={(ev) => {
                  ev.preventDefault();
                  ev.stopPropagation();
                  onDownload();
                }}
              />
            </TooltipHost>
          </>
        }
      >
        <div className={classNames.root} style={{ marginTop: "-5px" }}>
          {item.processedOn ? (
            <div>
              <Text
                variant="small"
                styles={this.props.tokens.defaultCardTextStyle}
              >
                {`${i18n.t("dossierimmigration:documents:processedon")} `}
              </Text>
              <DateText date={item.processedOn} />
            </div>
          ) : (
            <div>
              <Text>{i18n.t("enterprises:documents:noprocess")}</Text>
            </div>
          )}
        </div>
        {/*
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.createdBy}
        </Text>
        */}
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.size}
        </Text>
      </GridViewCard>
    );
  }
}
