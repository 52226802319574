import { CommandBarButton, Selection, Spinner } from "@fluentui/react";
import * as React from "react";
import { EntityPanel } from "src/components/EntityPanel";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { IDossierImmigrationDocument } from "src/models/DossierImmigration/IDossierImmigrationDocument";
import i18n from "src/services/i18n";
import DocumentDossierImmigrationService from "src/services/DossierImmigration/DocumentDossierImmigrationService";
import PreferencesService from "src/services/PreferencesService";
import { DossierImmigrationDocumentCard } from "../DossierImmigrationDocumentCard";
import { getDossierImmigrationDocumentsControlClassNames } from "./DossierImmigrationDocumentsControl.styles";
import {
  IDossierImmigrationDocumentsControlProps,
  IDossierImmigrationDocumentsControlState,
} from "./DossierImmigrationDocumentsControl.types";
import DocumentService from "src/services/Documents/DocumentService";
import { Icon } from "@fluentui/react";
import { UploadFilesDialogComponent } from "src/controls/UploadFilesDialog/UploadFilesDialog.base";
import { UploadFilesDialog } from "src/controls/UploadFilesDialog";
import { IAttachmentModel } from "src/models/Messages/IMessageSummary";
import { objectId } from "src/models/IObjectReference";

export class DossierImmigrationDocumentsControlComponent extends React.Component<
  IDossierImmigrationDocumentsControlProps,
  IDossierImmigrationDocumentsControlState
> {
  private uploadRef: React.RefObject<UploadFilesDialogComponent>;
  constructor(props: IDossierImmigrationDocumentsControlProps) {
    super(props);
    this.uploadRef = React.createRef();
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "dossierimmigrationdocuments",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "dossierimmigrationdocuments",
        false,
        () => []
      ),
      items: [],
      downloadProgress: "0",
    };
    DocumentDossierImmigrationService.getDocuments(
      objectId(this.props.enterprise),
      this.props.dossierimmigrationId,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private download = (item: IDossierImmigrationDocument): Promise<void> => {
    this.setState({
      downloadProgress: item.key,
    });
    return DocumentDossierImmigrationService.downloadDossierImmigrationDocument(
      objectId(this.props.enterprise),
      this.props.dossierimmigrationId,
      item
    )
      .then(() => {
        this.setState({
          downloadProgress: "0",
        });
      })
      .catch((_x) => {
        this.setState({
          downloadProgress: "0",
        });
      });
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getDossierImmigrationDocumentsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root} style={{ marginBottom: "10px" }}>
        <UploadFilesDialog
          {...this.props}
          styles={undefined}
          componentRef={this.uploadRef}
          onUpload={(attachments: IAttachmentModel[]) => {
            return DocumentDossierImmigrationService.uploadDocuments(
              objectId(this.props.enterprise),
              this.props.dossierimmigrationId,
              attachments
            );
          }}
        />
        <GridView
          {...this.props}
          styles={undefined}
          viewPortOffset={30}
          fullTextEnabled={false}
          gridTitle={i18n.t("menu:documents")}
          onItemInvoked={(item: IDossierImmigrationDocument) => {
            this.setState({
              document: item,
            });
          }}
          onRenderTile={(
            item: IDossierImmigrationDocument,
            columnWidth: number,
            selection: Selection,
            callback: (sel: Selection) => void
          ): JSX.Element => {
            return (
              <DossierImmigrationDocumentCard
                {...this.props}
                styles={subComponentStyles?.gridCard}
                item={item}
                columnWidth={columnWidth}
                selection={selection}
                onSelected={(item: IDossierImmigrationDocument) => {
                  ensureSelection(selection, item);
                  selection.setKeySelected(
                    item.key,
                    selection.getSelection().filter((x) => x.key === item.key)
                      .length === 0,
                    false
                  );
                  callback(selection);
                }}
                onClick={(item: IDossierImmigrationDocument) => {
                  this.setState({ document: item });
                }}
              />
            );
          }}
          onSort={(fieldName: string, desc: boolean) => {
            DocumentDossierImmigrationService.init();
            DocumentDossierImmigrationService.getDocuments(
              objectId(this.props.enterprise),
              this.props.dossierimmigrationId,
              fieldName,
              desc,
              this.state.filters,
              false
            ).then((x) => {
              this.setState({
                options: PreferencesService.asCommittedOptions(
                  GridViewOptionsBuilder.getOptions(
                    this.state.options.mode,
                    fieldName,
                    desc,
                    true
                  ),
                  "dossierimmigrationdocuments"
                ),
                items: x,
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          options={this.state.options}
          onOptionsChanged={(options: IGridViewOptions) => {
            this.setState({
              options: PreferencesService.asCommittedOptions(
                options,
                "dossierimmigrationdocuments"
              ),
            });
          }}
          columns={[
            {
              key: "key",
              name: "",
              minWidth: 60,
              maxWidth: 60,
              onRenderCell: (item: IDossierImmigrationDocument) => {
                const iconName = DocumentService.getIconFromExtension(
                  item.name
                );
                return (
                  <Icon
                    onClick={() => {
                      this.setState({
                        document: item,
                      });
                    }}
                    styles={{
                      root: {
                        cursor: "pointer",
                        fontSize: "17px",
                      },
                    }}
                    iconName={iconName}
                  />
                );
              },
            },
            {
              key: "name",
              name: "documents:name",
              minWidth: undefined,
            },
            {
              key: "size",
              name: "documents:size",
              minWidth: 90,
            },
            {
              key: "createdOn",
              name: "documents:createdOn",
              minWidth: 120,
            },
            {
              key: "createdBy",
              name: "documents:createdBy",
              minWidth: 150,
            },
            {
              key: "id",
              name: "documents:download",
              minWidth: 190,
              maxWidth: 190,
              onRenderCell: (item: IDossierImmigrationDocument) => {
                return this.state.downloadProgress === item.key ? (
                  <Spinner
                    style={{ width: "190px" }}
                    label={i18n.t("documents:download:progress")}
                    labelPosition={"right"}
                  />
                ) : (
                  <CommandBarButton
                    style={{ backgroundColor: "transparent" }}
                    iconProps={{ iconName: "CloudDownload" }}
                    onClick={() => this.download(item)}
                    text={i18n.t("documents:download")}
                  />
                );
              },
            },
          ]}
          onDataPaging={() => {
            DocumentDossierImmigrationService.getDocuments(
              objectId(this.props.enterprise),
              this.props.dossierimmigrationId,
              this.state.options.sortBy,
              this.state.options.desc,
              this.state.filters,
              true
            ).then((x) => {
              this.setState({
                items: x,
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
          }}
          onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
            DocumentDossierImmigrationService.init();
            DocumentDossierImmigrationService.getDocuments(
              objectId(this.props.enterprise),
              this.props.dossierimmigrationId,
              this.state.options.sortBy,
              this.state.options.desc,
              filters,
              undefined
            ).then((x) => {
              callback();
              this.setState({
                items: x,
                filters: PreferencesService.asCommittedFilters(
                  filters,
                  "dossierimmigrationdocuments"
                ),
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          onQueryFilters={(reset: boolean) => {
            return new Promise<IQueryFilter[]>((resolve) => {
              resolve(
                PreferencesService.getFiltersOrDefault(
                  "dossierimmigrationdocuments",
                  reset,
                  () => [
                    {
                      name: "documents:name",
                      fieldName: "name",
                      type: "text",
                      value: "",
                    },
                    {
                      name: "documents:processedOn",
                      fieldName: "processedOn",
                      type: "date",
                      value: null,
                    },
                    {
                      name: "documents:createdOn",
                      fieldName: "createdOn",
                      type: "date",
                      value: null,
                    },
                  ]
                )
              );
            });
          }}
          items={this.state.items}
          commands={[
            {
              key: "upload",
              icon: "BulkUpload",
              name: i18n.t("global:upload"),
              selectionRequired: false,
              confirmTitle: undefined,
              confirmMessage: (_items: any) => {
                return "";
              },
              onClick: () => {
                this.uploadRef.current?.open();
              },
            },
          ]}
        />
        <div style={{ height: "40px" }}></div>
        <EntityPanel
          {...this.props}
          styles={undefined}
          data-automation-id="nvx:dossierimmigrations:documents:itempanel"
          isOpen={this.state.document !== undefined}
          onDismiss={() => {
            this.setState({
              document: undefined,
            });
          }}
          elementName={i18n.t("document")}
          title={this.state.document?.name}
        >
          {this.state.document === undefined ? (
            <span></span>
          ) : (
            <div>
              <div style={{ height: "40px" }}></div>
              <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={i18n.t("documents:name")}
                content={this.state.document?.name}
              />
              <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={i18n.t("documents:size")}
                content={this.state.document?.size}
              />
              <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={i18n.t("documents:processedOn")}
                content={this.state.document?.processedOn ?? ""}
              />
              <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={i18n.t("documents:createdOn")}
                content={this.state.document?.createdOn}
              />
              {/*
              <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={i18n.t("documents:createdBy")}
                content={this.state.document?.createdBy}
              />
              */}
            </div>
          )}
        </EntityPanel>
      </div>
    );
  }
}
