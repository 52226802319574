import { IGridViewOptions, GridViewMode } from "./GridView.types";

class GridViewOptionsBuilder {
  public fetched(component: any): void {
    const { options } = component.state;
    options.fetched = true;
    component.setState({ options: options });
  }

  public unfetched(component: any): void {
    const { options } = component.state;
    options.fetched = false;
    component.setState({ options: options });
  }

  public getFetched(component: any): IGridViewOptions {
    const { options } = component.state;
    options.fetched = true;
    return options;
  }

  public getUnfetched(component: any): IGridViewOptions {
    const { options } = component.state;
    options.fetched = true;
    return options;
  }

  public getOptions(
    mode: GridViewMode,
    sortBy: string,
    desc: boolean,
    fetched?: boolean
  ): IGridViewOptions {
    switch (mode) {
      case GridViewMode.tiles:
        return {
          sortBy: sortBy,
          desc: desc,
          mode: mode,
          iconName: "Tiles",
          fetched: fetched ?? false,
          title: "grid:gridview",
        };
      case GridViewMode.list:
        return {
          sortBy: sortBy,
          desc: desc,
          mode: mode,
          fetched: fetched ?? false,
          iconName: "GroupedList",
          title: "grid:list",
        };
    }

    return {
      sortBy: sortBy,
      desc: desc,
      mode: mode,
      fetched: fetched ?? false,
      iconName: "ClipboardList",
      title: "grid:summary",
    };
  }
}

export default new GridViewOptionsBuilder();
