import { Stack } from "@fluentui/react";
import * as React from "react";
import { MediaQueries } from "src/components/MediaQueries";
import { objectId } from "src/models/IObjectReference";
import {
  FlightCriteriaSchema,
  IFlightCriteria,
} from "src/models/Transports/IFlightCriteria";
import i18n from "src/services/i18n";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { validate } from "src/utils/validator";
import { FlightDirectOffers } from "../FlightDirectOffers";
import { FlightSearchCriteria } from "../FlightSearchCriteria";
import { FlightSearchResultsContainer } from "../FlightSearchResultsContainer";
import { getFlightSearchPageClassNames } from "./FlightSearchPage.styles";
import {
  IFlightSearchPageProps,
  IFlightSearchPageState,
} from "./FlightSearchPage.types";

export const invalidatePrices = (
  source: IFlightCriteria,
  target: IFlightCriteria
) => {
  return (
    source.arrival !== target.arrival ||
    source.from !== target.from ||
    source.to !== target.to ||
    source.passengers !== target.passengers
  );
};

export class FlightSearchPageComponent extends React.Component<
  IFlightSearchPageProps,
  IFlightSearchPageState
> {
  constructor(props: IFlightSearchPageProps) {
    super(props);
    this.state =
      props.route.location.state === undefined
        ? {
            searching: false,
            defaultAirports: {},
            errors: [],
            criteria: {
              from: undefined,
              to: undefined,
              passengers: 1,
              date: undefined,
              searchDate: undefined,
              market: true,
              charter: true,
              flexible: false,
              arrival: true,
            },
            marketEnabled: true,
            charterEnabled: true,
            interiorAirports: [],
            exteriorAirports: [],
            arrival: true,
          }
        : (props.route.location.state as any);

    if (props.route.location.state === undefined) {
      TransportFlightSearchService.getAirports(
        objectId(this.props.enterprise)
      ).then((y) => {
        TransportFlightSearchService.getDefaultAirport(
          objectId(props.enterprise)
        ).then((x) => {
          if (x && !this.state.criteria.from) {
            const { criteria } = this.state;
            this.setState({
              marketEnabled: y.marketEnabled,
              charterEnabled: y.charterEnabled,
              defaultAirports: x,
              interiorAirports: y.interiors,
              exteriorAirports: y.exteriors,
              criteria: {
                ...criteria,
                from: criteria.arrival
                  ? x.remote?.[0] ?? "MEX"
                  : x.local?.[0] ?? "YUL",
                to: criteria.arrival
                  ? x.local?.[0] ?? "YUL"
                  : x.remote?.[0] ?? "YUL",
              },
            });
          }
        });
      });
    }
  }

  private onSearch = (): void => {
    const { criteria /* , marketEnabled, charterEnabled */ } = this.state;
    const errors = validate(FlightCriteriaSchema, criteria) ?? {};
    if (
      criteria.dates?.start === undefined ||
      criteria.dates.end === undefined
    ) {
      errors.date = i18n.t("transports:flightcriteria:date:error");
    }

    if (!errors || Object.keys(errors).length === 0) {
      TransportFlightSearchService.searchFlights(criteria)
        .then((x) => {
          this.setState({
            data: x.results,
            flexibles: x.flexibles,
            offers: TransportFlightSearchService.getOffers(x.results),
            arrival: criteria.arrival,
            searching: false,
            criteria: {
              ...criteria,
              searchDates: criteria.dates,
            },
          });
          setTimeout(() => {
            const obj = document.getElementById("flight_search_results");
            if (obj && obj != null) {
              obj.scrollIntoView({
                behavior: "smooth",
              });
            }
          }, 2800);
        })
        .catch((x) => {
          this.setState({
            data: [],
            flexibles: [],
            offers: TransportFlightSearchService.getOffers(x.results),
            arrival: criteria.arrival,
            searching: false,
            criteria: {
              ...criteria,
              searchDates: criteria.dates,
            },
          });
        });

      this.setState({
        data: undefined,
        offers: undefined,
        searching: true,
        errors: errors as any,
        criteria: {
          ...criteria,
          searchDates: criteria.dates,
        },
      });
    } else {
      this.setState({
        data: undefined,
        offers: undefined,
        searching: false,
        errors: errors as any,
        criteria: {
          ...criteria,
          searchDates: criteria.dates,
        },
      });
    }
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames] = getFlightSearchPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { data, criteria, offers, flexibles, searching } = this.state;
    const routeState = this.props.route.location.state as any;
    return (
      <div className={classNames.root}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              paddingLeft: "40px",
              paddingRight: "50px",
              [MediaQueries.mobile]: {
                paddingLeft: "10px",
                paddingRight: "10px",
              },
            },
          }}
        >
          <FlightSearchCriteria
            {...this.props}
            {...this.state}
            horizontal={
              !mobile &&
              !tablet &&
              window.innerWidth > 1487 &&
              (searching || data !== undefined)
            }
            onSearch={this.onSearch}
            onCriteriaChanged={(criteria: IFlightCriteria) => {
              if (invalidatePrices(this.state.criteria, criteria)) {
                this.setState({
                  criteria: criteria,
                  data: undefined,
                  offers: undefined,
                  flexibles: undefined,
                });
              } else this.setState({ criteria });
            }}
            styles={undefined}
          />
          {searching || (data && offers) ? (
            <FlightSearchResultsContainer
              {...this.props}
              data={data ?? []}
              offers={offers ?? []}
              flexibles={flexibles ?? []}
              criteria={criteria}
              searching={searching}
              styles={undefined}
              onOfferSelected={(
                offer,
                serviceIndex,
                offerIndex,
                flexible: boolean
              ) => {
                this.props.route.history.push(
                  "/transports/billetterie/sommaire",
                  {
                    ...routeState,
                    ...this.state,
                    criteria: {
                      ...this.state.criteria,
                      arrival: this.state.arrival,
                    },
                    offer: offer,
                    flexible: flexible,
                    offerIndex: offerIndex,
                    serviceIndex: serviceIndex,
                  }
                );
              }}
            />
          ) : this.props.mobile ? (
            <div></div>
          ) : (
            <FlightDirectOffers
              {...this.props}
              arrival={criteria.arrival}
              enterpriseId={objectId(this.props.enterprise)}
            />
          )}
          <div style={{ height: "30px" }}></div>
        </Stack>
      </div>
    );
  }
}
