/**
 * Flags Images Map
 */
export default {
  Canada: "/img/flags/canada.flag.png",
  Mexique: "/img/flags/mexico.flag.png",
  Guatemala: "/img/flags/guatemala.flag.png",
  Jamaïque: "/img/flags/jamaica.flag.png",
  Honduras: "/img/flags/honduras.flag.png",
  Grenade: "/img/flags/granada.flag.png",
  "Sainte Lucie": "/img/flags/saint_lucia.flag.png",
  Salvador: "/img/flags/el_salvador.flag.png",
  "Trinidad et Tobago": "/img/flags/trinidad_and_tobago.flag.png",
  Barbades: "/img/flags/barbados.flag.png",
  Panama: "/img/flags/panama.flag.png",
  "Costa Rica": "/img/flags/costa_rica.flag.png",
  Antilles: "/img/flags/antigua_and_barbuda.flag.png",
  "République Dominicaine": "/img/flags/dominican_republic.flag.png",
  Nicaragua: "/img/flags/nicaragua.flag.png",
  "Saint-Vincent": "/img/flags/saint_vincent_and_the_grenadines.flag.png",
  "États-Unis": "/img/flags/united_states.flag.png",
  Tunisie: "/img/flags/tunesia.flag.png",
  France: "/img/flags/france.flag.png",
  Italie: "/img/flags/italy.flag.png",
  Algérie: "/img/flags/algeria.flag.png",
  Madagascar: "/img/flags/madagascar.flag.png",
  Colombie: "/img/flags/colombia.flag.png",
  Maroc: "/img/flags/morocco.flag.png",
};
