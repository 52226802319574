import { Icon, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getAlertCardClassNames } from "./AlertCard.styles";
import { IAlertCardProps, IAlertCardState } from "./AlertCard.types";

export class AlertCardComponent extends React.Component<
  IAlertCardProps,
  IAlertCardState
> {
  constructor(props: IAlertCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getAlertCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={item.name}
        columnWidth={columnWidth}
        footerText={item.description}
        footerIconName={footerIconName ?? "WorkItemAlert"}
        selected={selected}
        onClick={
          this.props.onClick
            ? () => {
                this.props.onClick && this.props.onClick(item);
              }
            : undefined
        }
        onSelected={
          this.props.onSelected
            ? () => {
                this.props.onSelected && this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            <TooltipHost content={item.type}>
              <Icon
                iconName="WorkItemAlert"
                styles={subComponentStyles?.topIcon}
              />
            </TooltipHost>
            {item.seenOn.length === 0 && (
              <TooltipHost content={i18n.t("alerts:newalert")}>
                <Icon
                  iconName="NewMail"
                  styles={subComponentStyles?.newAlertIcon}
                />
              </TooltipHost>
            )}
          </>
        }
      >
        <Text
          className={classNames.root}
          variant="small"
          styles={subComponentStyles?.createdOnLabel}
        >
          {item.createdOn}
        </Text>
        <div style={{ height: "1px" }}></div>
        <Text variant="small" styles={subComponentStyles?.seenOnLabel}>
          {item.type && item.type === item.name ? (
            item.seenOn !== undefined &&
            item.seenOn !== null &&
            item.seenOn.length !== 0 ? (
              `${i18n.t("alerts:seenOn")} ${item.seenOn}`
            ) : (
              <span style={{ color: "#fff" }}>_</span>
            )
          ) : (
            item.type
          )}
        </Text>
      </GridViewCard>
    );
  }
}
