import { styled } from "@fluentui/react/lib/Utilities";
import { INewMessageCommandBarProps } from "./NewMessageCommandBar.types";
import {
  getStyles,
  INewMessageCommandBarStyleProps,
  INewMessageCommandBarStyles,
} from "./NewMessageCommandBar.styles";
import { NewMessageCommandBarComponent } from "./NewMessageCommandBar.base";

/**
 * NewMessageCommandBar
 */
export const NewMessageCommandBar = styled<
  INewMessageCommandBarProps,
  INewMessageCommandBarStyleProps,
  INewMessageCommandBarStyles
>(NewMessageCommandBarComponent, getStyles);
