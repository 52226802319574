import * as React from "react";
import { UnauthenticatedLayout } from "src/controls/UnauthenticatedLayout";
import { ResetPasswordControl } from "../ResetPasswordControl";
import { getResetPasswordPageClassNames } from "./ResetPasswordPage.styles";
import {
  IResetPasswordPageProps,
  IResetPasswordPageState,
} from "./ResetPasswordPage.types";

export class ResetPasswordPageComponent extends React.Component<
  IResetPasswordPageProps,
  IResetPasswordPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getResetPasswordPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <UnauthenticatedLayout
        {...this.props}
        className={classNames.root}
        styles={subComponentStyles?.layout}
      >
        <ResetPasswordControl
          {...this.props}
          onLanguageChanged={
            this.props.onLanguageChanged === undefined
              ? (window as any).onLanguageChanged
              : this.props.onLanguageChanged
          }
        />
      </UnauthenticatedLayout>
    );
  }
}
