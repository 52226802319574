import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterprisesPageProps } from "./EnterprisesPage.types";
import {
  getStyles,
  IEnterprisesPageStyleProps,
  IEnterprisesPageStyles,
} from "./EnterprisesPage.styles";
import { EnterprisesPageComponent } from "./EnterprisesPage.base";

/**
 * EnterprisesPage description
 */
export const EnterprisesPage = styled<
  IEnterprisesPageProps,
  IEnterprisesPageStyleProps,
  IEnterprisesPageStyles
>(EnterprisesPageComponent, getStyles);
