import {
  DefaultPalette,
  Icon,
  List,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  Spinner,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { LabelField } from "src/components/LabelField";
import { UserCallout } from "src/controls/UserCallout";
import { IEnterpriseContact } from "src/models/Enterprises/IEnterpriseContact";
import { objectId } from "src/models/IObjectReference";
import { EnterpriseContactItem } from "src/pages/Enterprises/EnterpriseContactItem";
import BrowserService from "src/services/BrowserService";
import i18n from "src/services/i18n";
import RequestsService from "src/services/Requests/RequestsService";
import { uniqueId } from "src/utils/uniqueId";
import { RequestDocumentsControl } from "../RequestDocumentsControl";
import { RequestHistoryControl } from "../RequestHistoryControl";
import { RequestParticipantsSection } from "../RequestParticipantsSection";
import { RequestPeriodsControl } from "../RequestPeriodsControl";
import { RequestReportsPanel } from "../RequestReportsPanel";
import { getRequestDetailPageClassNames } from "./RequestDetailPage.styles";
import {
  IRequestDetailPageProps,
  IRequestDetailPageState,
} from "./RequestDetailPage.types";

const themeIndex: number = 1; // Math.floor(Math.random() * 4);

export class RequestDetailPageComponent extends React.Component<
  IRequestDetailPageProps,
  IRequestDetailPageState
> {
  constructor(props: IRequestDetailPageProps) {
    super(props);
    const route = props.route;
    const hstate = route.location.state;
    this.state = {
      tab: hstate !== undefined ? (hstate as any).tab : this.props.tab,
      periods: [],
      requestId: parseInt(route.match?.params.id),
      printing: false,
    };
    if (this.props.enterprise.key === undefined)
      throw new Error("No Enterprise");
    RequestsService.getRequestReference(
      parseInt(this.props.enterprise.key.toString()),
      this.state.requestId,
      route.match?.params.version
        ? parseInt(route.match.params.version)
        : undefined
    ).then((x) => {
      this.setState({
        request: x,
        periods: x.periods,
        enterprise: x.enterprise,
      });
    });
  }

  render(): JSX.Element {
    const { styles, route, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] = getRequestDetailPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    const id: string = uniqueId("nvx_gridview_viewport_");
    const tab: string =
      route.location.pathname.indexOf("/periodes") !== -1
        ? "1"
        : this.state.tab;
    const enterpriseSize: PersonaSize = BrowserService.isMobile()
      ? PersonaSize.size56
      : PersonaSize.size100;
    const themeColor = [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
    ];
    const themeImageSize = [
      "cover",
      tablet || mobile ? "cover" : "100% 170%",
      "100% 115%",
      mobile ? "cover" : "cover",
    ];
    const themeOpacity: string[] = ["1.0", "0.4", "0.5", "0.5"];
    const sectorValue: string =
      this.state.request?.sector.replace(":", "<br />") ?? "";

    return (
      <div className={classNames.root}>
        <RequestReportsPanel
          {...this.props}
          requestId={this.state.requestId}
          versionNumber={parseInt(this.state.request?.version ?? "-1")}
          isOpen={this.state.printing}
          onDismiss={() => this.setState({ printing: false })}
          onPrint={(
            type: number,
            language: string,
            date: Date | undefined,
            callback: () => void
          ) => {
            if (this.state.request) {
              RequestsService.print(
                type,
                objectId(this.props.enterprise),
                this.state.requestId,
                parseInt(this.state.request.version),
                date,
                language
              )
                .then(() => {
                  callback();
                  this.setState({ printing: false });
                })
                .catch(() => {
                  callback();
                  this.setState({ printing: false });
                });
            }
          }}
        />
        <Stack grow verticalFill>
          <div
            style={{
              minHeight: "180px",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              className="EnterpriseBackground"
              style={{
                opacity: themeOpacity[themeIndex],
                backgroundImage:
                  "url('/img/banner_" + (themeIndex + 1).toString() + ".jpg')",
                backgroundSize: themeImageSize[themeIndex],
                backgroundRepeat: "no-repeat",
              }}
            ></div>
            <div
              className={themeIndex === 1 ? undefined : "WhitePersona"}
              style={{
                position: "absolute",
                padding: "10px 10px",
                width: "95%",
                left: 0,
                bottom: 0,
              }}
            >
              <Persona
                initialsColor={"rgb(122, 117, 116)"}
                text={"Demande " + (this.state.request?.name ?? "")}
                styles={{
                  primaryText: {
                    fontWeight: "bold",
                    color: themeColor[themeIndex],
                  },
                  secondaryText: {
                    fontWeight: "bold",
                    color: themeColor[themeIndex],
                  },
                  tertiaryText: {
                    fontWeight: "bold",
                    color: themeColor[themeIndex],
                  },
                }}
                coinProps={{
                  styles: {
                    coin: {
                      display: "none",
                    },
                  },
                }}
                imageInitials={(this.state.request?.name ?? "").substr(0, 2)}
                color={themeColor[themeIndex]}
                secondaryText={this.state.request?.enterprise.name}
                size={enterpriseSize}
              />
            </div>
            <div
              data-note="top-right-section"
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                padding: "4px",
              }}
            ></div>
            <div></div>
          </div>
          <Stack
            grow
            verticalFill
            style={{
              width: "100%",
              backgroundColor: DefaultPalette.neutralLighter,
            }}
          >
            <Pivot
              styles={subComponentStyles?.pivot}
              style={{ backgroundColor: DefaultPalette.neutralLighter }}
              selectedKey={tab}
              onLinkClick={(item?: PivotItem) => {
                const newTab =
                  item?.props.itemKey === undefined ? "0" : item.props.itemKey;
                if (newTab === "99") {
                  if (route.match?.params.version) {
                    route.history.push(`/demandes/${this.state.requestId}`);
                  } else {
                    route.history.push("/demandes");
                  }
                } else {
                  this.setState({
                    tab: newTab,
                  });
                }
              }}
            >
              <PivotItem
                headerText={i18n.t("requests:request")}
                itemKey={"0"}
                key={"0"}
              >
                <div
                  className="ms-Grid"
                  style={{ paddingTop: "10px" }}
                  dir="ltr"
                >
                  <div className={classNames.msGridRow}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4">
                      <div
                        style={{
                          backgroundColor: DefaultPalette.white,
                          margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                        }}
                        className="ms-depth-16"
                      >
                        <Stack horizontal tokens={{ childrenGap: 10 }}>
                          <StackItem>
                            <TooltipHost content={i18n.t("requests:info")}>
                              <Icon
                                style={{
                                  fontSize: "22px",
                                  padding: "20px 10px",
                                }}
                                iconName="UserOptional"
                              />
                            </TooltipHost>
                          </StackItem>
                          <StackItem grow>
                            <h2>{i18n.t("requests:info")}</h2>
                          </StackItem>
                          <StackItem>
                            <TooltipHost content={i18n.t("requests:print")}>
                              <Icon
                                iconName={"Print"}
                                style={{
                                  fontSize: "22px",
                                  cursor: "pointer",
                                  padding: "20px 10px 0 10px",
                                }}
                                onClick={() => {
                                  if (this.state.request) {
                                    this.setState({
                                      printing: true,
                                    });
                                  }
                                }}
                              />
                            </TooltipHost>
                          </StackItem>
                        </Stack>
                        <div>
                          <div
                            style={{
                              padding: tablet ? "0px 10px" : "0px 20px",
                            }}
                          >
                            {this.state.request === undefined && (
                              <Stack
                                styles={{
                                  root: {
                                    margin: "40px",
                                    ".ms-Spinner-label": {
                                      fontWeight: 600,
                                    },
                                  },
                                }}
                              >
                                <Spinner label={i18n.t("global:loading")} />
                              </Stack>
                            )}
                            {this.state.request !== undefined && (
                              <Stack horizontal tokens={{ childrenGap: 10 }}>
                                <StackItem grow tokens={{ margin: 10 }}>
                                  <Stack tokens={{ childrenGap: 10 }} grow>
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{
                                        iconName: "WaitlistConfirm",
                                      }}
                                      label={i18n.t("requests:status")}
                                      content={this.state.request?.status ?? ""}
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{ iconName: "Headset" }}
                                      label={i18n.t("requests:agent")}
                                      content={
                                        this.state.request ? (
                                          <UserCallout
                                            {...this.props}
                                            styles={{
                                              root: {
                                                marginTop: "3px",
                                              },
                                            }}
                                            user={this.state.request.agent}
                                          />
                                        ) : (
                                          <div></div>
                                        )
                                      }
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{ iconName: "UserFollowed" }}
                                      label={i18n.t(
                                        "requests:participantslabel"
                                      )}
                                      content={`${
                                        (this.state.request
                                          ?.participantConfirmed ?? 0) > 0
                                          ? this.state.request
                                              ?.participantConfirmed + "/"
                                          : ""
                                      }${
                                        this.state.request?.participantCount
                                      } participants`}
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={undefined}
                                      iconProps={{ iconName: "World" }}
                                      label={i18n.t("requests:country")}
                                      content={
                                        <Stack
                                          horizontal
                                          tokens={{ childrenGap: 10 }}
                                        >
                                          <CountryIcon
                                            {...this.props}
                                            styles={undefined}
                                            country={
                                              this.state.request?.country ?? ""
                                            }
                                          />
                                          <Text>
                                            {this.state.request?.country ?? (
                                              <div></div>
                                            )}
                                          </Text>
                                        </Stack>
                                      }
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={undefined}
                                      iconProps={{ iconName: "NumberSymbol" }}
                                      label={i18n.t("requests:request")}
                                      content={
                                        this.state.request?.name ?? <div></div>
                                      }
                                    />
                                  </Stack>
                                </StackItem>
                                <StackItem grow tokens={{ margin: 10 }}>
                                  <Stack tokens={{ childrenGap: 10 }} grow>
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{ iconName: "Certificate" }}
                                      label={i18n.t("requests:offptas")}
                                      content={
                                        this.state.request?.offPTAS
                                          ? i18n.t("global:yes")
                                          : i18n.t("global:no")
                                      }
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{
                                        iconName: "WebAppBuilderFragment",
                                      }}
                                      label={i18n.t("requests:sector")}
                                      content={
                                        <span
                                          dangerouslySetInnerHTML={{
                                            __html: sectorValue,
                                          }}
                                        ></span>
                                      }
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={subComponentStyles?.label}
                                      iconProps={{
                                        iconName: "CityNext",
                                      }}
                                      label={i18n.t("requests:thirdParty")}
                                      content={
                                        this.state.request?.thirdParty ?? ""
                                      }
                                    />
                                    <LabelField
                                      {...this.props}
                                      styles={undefined}
                                      iconProps={{ iconName: "DateTime" }}
                                      label={i18n.t("requests:year")}
                                      content={
                                        this.state.request?.year.toString() ?? (
                                          <div></div>
                                        )
                                      }
                                    />
                                  </Stack>
                                </StackItem>
                              </Stack>
                            )}
                            <div>
                              <div
                                style={{ height: "20px" }}
                                className="clear-both"
                              ></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4">
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: DefaultPalette.white,
                          margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                        }}
                        className="ms-depth-16"
                      >
                        <div>
                          <div
                            style={{
                              float: "left",
                              paddingLeft: "15px",
                              paddingRight: "10px",
                            }}
                          >
                            <h2>
                              <Icon
                                style={{ fontSize: "22px" }}
                                iconName="BranchMerge"
                              />
                            </h2>
                          </div>
                          <div style={{ float: "left" }}>
                            <h2>{i18n.t("requests:version_")}</h2>
                          </div>
                          <div className="clear-both"></div>
                        </div>
                        <div
                          style={{
                            padding: tablet ? "0px 10px" : "0px 30px",
                          }}
                        >
                          <Stack tokens={{ childrenGap: 10 }}>
                            <StackItem grow>
                              <LabelField
                                {...this.props}
                                styles={undefined}
                                iconProps={{ iconName: "EntitlementPolicy" }}
                                label={i18n.t("requests:title")}
                                content={
                                  this.state.request?.versionTitle ?? (
                                    <div></div>
                                  )
                                }
                              />

                              <LabelField
                                {...this.props}
                                styles={undefined}
                                iconProps={{ iconName: "EventDate" }}
                                label={i18n.t("requests:creationdate")}
                                content={
                                  this.state.request?.versionDate ?? <div></div>
                                }
                              />
                              <LabelField
                                {...this.props}
                                styles={undefined}
                                iconProps={{ iconName: "NumberSymbol" }}
                                label={i18n.t("requests:version_")}
                                content={
                                  this.state.request?.version ?? <div></div>
                                }
                              />
                              <div style={{ height: "20px" }}></div>
                            </StackItem>
                          </Stack>
                          <div style={{ height: "20px" }}></div>
                        </div>
                      </div>
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: DefaultPalette.white,
                          margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                        }}
                        className="ms-depth-16"
                      >
                        <div>
                          <div
                            style={{
                              float: "left",
                              paddingLeft: "15px",
                              paddingRight: "10px",
                            }}
                          >
                            <h2>
                              <Icon
                                style={{ fontSize: "22px" }}
                                iconName="MapPin"
                              />
                            </h2>
                          </div>
                          <div style={{ float: "left" }}>
                            <h2>{i18n.t("enterprise:addresses:title")}</h2>
                          </div>
                          <div className="clear-both"></div>
                        </div>
                        {this.state.request === undefined && (
                          <Stack
                            styles={{
                              root: {
                                margin: "50px",
                                ".ms-Spinner-label": {
                                  fontWeight: 600,
                                },
                              },
                            }}
                          >
                            <Spinner label={i18n.t("global:loading")} />
                            <div style={{ height: "50px" }}></div>
                          </Stack>
                        )}
                        {this.state.request !== undefined && (
                          <>
                            <div
                              style={{
                                padding: tablet
                                  ? "0px 10px 0px 10px"
                                  : "0px 30px 0px 30px",
                              }}
                            >
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t(
                                  "enterprise:addresses:mainaddress"
                                )}
                                content={
                                  <div>
                                    <div>
                                      {this.state.enterprise?.address.street}
                                    </div>
                                    <div>
                                      {this.state.enterprise?.address.city} (
                                      {this.state.enterprise?.address.state})
                                    </div>
                                    <div>
                                      {
                                        this.state.enterprise?.address
                                          .postalcode
                                      }
                                    </div>
                                    <div>
                                      {this.state.enterprise?.address.country}
                                    </div>
                                  </div>
                                }
                              />
                            </div>
                            {this.state.request?.branch !== undefined &&
                              this.state.request?.branch !== null && (
                                <div
                                  style={{
                                    padding: tablet ? "10px" : "5px 30px",
                                  }}
                                >
                                  <h4>
                                    {i18n.t("enterprise:addresses:branches")}
                                  </h4>
                                  <div>{this.state.request.branch.name}</div>
                                  <LabelField
                                    {...this.props}
                                    styles={subComponentStyles?.label}
                                    label={i18n.t(
                                      "enterprise:addresses:address"
                                    )}
                                    content={
                                      <div>
                                        <div>
                                          {
                                            this.state.request.branch.address
                                              .street
                                          }
                                        </div>
                                        <div>
                                          {
                                            this.state.request.branch.address
                                              .city
                                          }{" "}
                                          (
                                          {
                                            this.state.request.branch.address
                                              .state
                                          }
                                          )
                                        </div>
                                        <div>
                                          {
                                            this.state.request.branch.address
                                              .postalcode
                                          }
                                        </div>
                                        <div>
                                          {
                                            this.state.request.branch.address
                                              .country
                                          }
                                        </div>
                                      </div>
                                    }
                                  />
                                  <div style={{ height: "20px" }}></div>
                                </div>
                              )}
                          </>
                        )}
                      </div>
                    </div>

                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4">
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: DefaultPalette.white,
                          margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
                        }}
                        className="ms-depth-16"
                      >
                        <div>
                          <div
                            style={{
                              float: "left",
                              paddingLeft: "15px",
                              paddingRight: "10px",
                            }}
                          >
                            <h2>
                              <Icon
                                style={{ fontSize: "22px" }}
                                iconName="Family"
                              />
                            </h2>
                          </div>
                          <div style={{ float: "left" }}>
                            <h2>{i18n.t("requests:contacts")}</h2>
                          </div>
                          <div className="clear-both"></div>
                        </div>
                        <div
                          style={{
                            padding: tablet ? "0px 10px" : "0px 20px",
                          }}
                        >
                          <div
                            style={{
                              marginBottom: this.props.mobile
                                ? "5px 10px"
                                : "5px 20px",
                            }}
                          >
                            <List
                              items={this.state.request?.contacts ?? []}
                              onRenderCell={(item?: IEnterpriseContact) => {
                                return (
                                  <div
                                    style={{
                                      float: "left",
                                      padding: "8px",
                                    }}
                                  >
                                    {item && (
                                      <EnterpriseContactItem
                                        {...this.props}
                                        styles={subComponentStyles?.contact}
                                        contact={item}
                                      />
                                    )}
                                  </div>
                                );
                              }}
                            />
                            <div className="clear-both"></div>
                          </div>
                          <div style={{ height: "20px" }}></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </PivotItem>
              <PivotItem
                headerText={i18n.t("requests:periods")}
                itemKey={"1"}
                key={"1"}
              >
                <div style={{ padding: this.props.mobile ? "10px" : "30px" }}>
                  <RequestPeriodsControl
                    {...this.props}
                    styles={subComponentStyles?.periodControl}
                    periods={this.state.periods}
                    requestId={this.state.requestId}
                  />
                </div>
              </PivotItem>
              <PivotItem
                headerText={i18n.t("requests:positions")}
                itemKey={"2"}
                key={"2"}
              >
                <div>
                  <RequestParticipantsSection
                    {...this.state}
                    {...this.props}
                    styles={subComponentStyles?.periodSection}
                  />
                </div>
              </PivotItem>
              {this.state.requestId > 0 &&
                this.state.request !== undefined &&
                !this.state.request.versionned && (
                  <PivotItem
                    itemKey={"3"}
                    key={"3"}
                    headerText={i18n.t("requests:history:header")}
                  >
                    {/*
                    <div className="ms-Grid" dir="ltr">
                      <div className={classNames.msGridRow}>
                        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12">
                          <div
                            style={{
                              backgroundColor: DefaultPalette.white,
                              margin:
                                tablet || mobile
                                  ? "0 0 10px 0"
                                  : "0 10px 10px 10px",
                            }}
                            className="ms-depth-16"
                          >
                            <IconHeader
                              {...this.props}
                              styles={subComponentStyles?.iconHeader}
                              iconName={"VersionControlPush"}
                              title={i18n.t("requests:history:header")}
                            />
                            <RequestHistoryControl
                              {...this.props}
                              requestId={this.state.requestId}
                              scrollableTargetId={id}
                              styles={subComponentStyles?.history}
                            />
                            <div></div>
                          </div>
                        </div>
                      </div>
                    </div>
                          */}
                    <RequestHistoryControl
                      {...this.props}
                      requestId={this.state.requestId}
                      scrollableTargetId={id}
                      styles={subComponentStyles?.history}
                    />
                  </PivotItem>
                )}

              <PivotItem
                key={"4"}
                itemKey={"4"}
                headerText={i18n.t("requests:documents")}
              >
                {tab === "4" && (
                  <RequestDocumentsControl
                    {...this.props}
                    styles={undefined}
                    scrollableTargetId={id}
                    requestId={this.state.requestId}
                  />
                )}
              </PivotItem>

              <PivotItem
                className={"ms-Pivot-PivotItem-Navigate"}
                itemIcon={"NavigateBack"}
                headerText={i18n.t(
                  route.match?.params.version
                    ? "requests:backtoriginal"
                    : "requests:backtolist"
                )}
                itemKey={"99"}
                key={"99"}
              ></PivotItem>
            </Pivot>
          </Stack>
        </Stack>
      </div>
    );
  }
}
