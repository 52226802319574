import { Text } from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getParticipantCardClassNames } from "./ParticipantCard.styles";
import {
  IParticipantCardProps,
  IParticipantCardState,
} from "./ParticipantCard.types";
import { LabelField } from "src/components/LabelField";

export class ParticipantCardComponent extends React.Component<
  IParticipantCardProps,
  IParticipantCardState
> {
  constructor(props: IParticipantCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getParticipantCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {item.code}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {item.name}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={item.country}
        footerIconName={footerIconName ?? "Globe"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            <CountryIcon
              {...this.props}
              styles={undefined}
              country={item.country}
            />
          </>
        }
      >
        <LabelField
          {...this.props}
          styles={undefined}
          label={`${i18n.t("participants:recall")}`}
          content={
            item.recall && item.recall.length > 0
              ? item.recall
              : i18n.t("requests:NONE")
          }
        />
        <Text
          className={classNames.root}
          styles={this.props.tokens.defaultCardTextStyle}
        >
          {item.age} {i18n.t("global:yearsold")}
        </Text>
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.birthDate}
        </Text>
      </GridViewCard>
    );
  }
}
