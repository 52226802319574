import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface INewMessageCommandBarSubComponentStyles {}

export interface INewMessageCommandBarStyles
  extends IAmigoBaseStyles<INewMessageCommandBarSubComponentStyles> {}

export interface INewMessageCommandBarStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewMessageCommandBarClassNames = (
  styles?: IStyleFunctionOrObject<
    INewMessageCommandBarStyleProps,
    INewMessageCommandBarStyles
  >,
  props?: INewMessageCommandBarStyleProps
): [
  IProcessedStyleSet<INewMessageCommandBarStyles>,
  INewMessageCommandBarSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewMessageCommandBarStyleProps,
  INewMessageCommandBarStyles
> = (_props: INewMessageCommandBarStyleProps): INewMessageCommandBarStyles => {
  return {
    root: ["amigo-NewMessageCommandBar", {}],
  };
};
