import { ISchemaObject } from "../../utils/validator";
import i18n from "src/services/i18n";

export interface IPaymentMethod {
  id: number;
  key: string;
  card: string;
  name: string;
  expirationMonth: number;
  expirationYear: number;
  enabled: boolean;
  createdOn: string;
  modifiedOn: string;
  createdBy: string;
  cardType: string;
  cvc?: string;
  secured?: boolean;
  billingAuthorized: boolean;
  transportAuthorized: boolean;
  saveOnVaultDB: boolean;
}

export const isCreditCardExpirationValid = (x: IPaymentMethod): boolean => {
  const exMonth = x.expirationMonth;
  const exYear = x.expirationYear;
  const today = new Date();
  const someday = new Date();
  someday.setFullYear(exYear, exMonth, 1);

  if (someday < today) {
    return false;
  }
  return true;
};

const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
const amexpRegEx = /^(?:3[47][0-9]{13})$/;
const nameRegEx = /^[a-zA-Z0-9À-ÿ.,' -]+$/;

export const PaymentMethodSchema = (cardType: string): ISchemaObject => {
  let pattern: string = "";
  switch (cardType) {
    case "Visa":
      pattern = visaRegEx.source;
      break;
    case "MasterCard":
      pattern = mastercardRegEx.source;
      break;
    case "American Express":
      pattern = amexpRegEx.source;
      break;
  }
  return {
    name: "paymentmethod",
    plural: "paymentmethods",
    label: "Payment Method",
    fields: {
      card: {
        name: "card",
        label: i18n.t("enterprises:paymentmethods:card"),
        required: true,
        pattern: pattern,
        errorMessage: i18n.t("enterprises:paymentmethods:card:error"),
        type: "string",
      },
      name: {
        name: "name",
        label: i18n.t("enterprises:paymentmethods:name:error"),
        required: true,
        pattern: nameRegEx.source,
        errorMessage: i18n.t("enterprises:paymentmethods:name:error"),
        type: "string",
      },
      expirationMonth: {
        name: "expirationMonth",
        required: true,
        pattern: "expirationYear",
        type: "expiry",
        label: i18n.t("enterprises:paymentmethods:expirationMonth"),
        errorMessage: i18n.t("enterprises:paymentmethods:expiry:error"),
      },
      expirationYear: {
        name: "expirationYear",
        required: true,
        pattern: "",
        type: "number",
        label: i18n.t("enterprises:paymentmethods:expirationYear"),
        errorMessage: i18n.t("enterprises:paymentmethods:expiryyear:error"),
      },
      cvc: {
        name: "cvc",
        label: i18n.t("enterprises:paymentmethods:cvc"),
        required: false,
        pattern: "",
        min: 0,
        errorMessage: i18n.t("enterprises:paymentmethods:cvc:error"),
        type: "string",
      },
      billingAuthorized: {
        name: "billingAuthorized",
        label: i18n.t("enterprises:paymentmethods:authorize:accept"),
        required: true,
        pattern: "",
        options: {
          values: [true],
        },
        errorMessage: i18n.t("enterprises:paymentmethods:authorize:error"),
        type: "checked",
      },
    },
  };
};

export const CompletePaymentMethodSchema = (
  cardType: string
): ISchemaObject => {
  let pattern: string = "";
  switch (cardType) {
    case "Visa":
      pattern = visaRegEx.source;
      break;
    case "MasterCard":
      pattern = mastercardRegEx.source;
      break;
    case "American Express":
      pattern = amexpRegEx.source;
      break;
  }
  return {
    name: "paymentmethod",
    plural: "paymentmethods",
    label: "Payment Method",
    fields: {
      card: {
        name: "card",
        label: i18n.t("enterprises:paymentmethods:card"),
        required: true,
        pattern: pattern,
        errorMessage: i18n.t("enterprises:paymentmethods:card:error"),
        type: "string",
      },
      name: {
        name: "name",
        label: i18n.t("enterprises:paymentmethods:name:error"),
        required: true,
        pattern: nameRegEx.source,
        length: [3, 50],
        min: 3,
        errorMessage: i18n.t("enterprises:paymentmethods:name:error"),
        type: "string",
      },
      expirationMonth: {
        name: "expirationMonth",
        required: true,
        pattern: "expirationYear",
        type: "expiry",
        label: i18n.t("enterprises:paymentmethods:expirationMonth"),
        errorMessage: i18n.t("enterprises:paymentmethods:expiry:error"),
      },
      expirationYear: {
        name: "expirationYear",
        required: true,
        pattern: "",
        type: "number",
        label: i18n.t("enterprises:paymentmethods:expirationYear"),
        errorMessage: i18n.t("enterprises:paymentmethods:expiryyear:error"),
      },
      cvc: {
        name: "cvc",
        label: i18n.t("enterprises:paymentmethods:cvc"),
        required: false,
        pattern: "[0-9]{3,4}",
        min: 0,
        errorMessage: i18n.t("enterprises:paymentmethods:cvc:error"),
        type: "string",
      },
    },
  };
};
