import { styled } from "@fluentui/react/lib/Utilities";
import { IBillingControlProps } from "./BillingControl.types";
import {
  getStyles,
  IBillingControlStyleProps,
  IBillingControlStyles,
} from "./BillingControl.styles";
import { BillingControlComponent } from "./BillingControl.base";

/**
 * BillingControl description
 */
export const BillingControl = styled<
  IBillingControlProps,
  IBillingControlStyleProps,
  IBillingControlStyles
>(BillingControlComponent, getStyles);
