class LINQ {
  public selectMany<TIn, TOut>(
    input: TIn[],
    selectListFn: (t: TIn) => TOut[]
  ): TOut[] {
    const empty: TOut[] = [];
    return input.reduce((out, inx) => {
      out.push(...selectListFn(inx));
      return out;
    }, empty);
  }

  public truncate(input: string, length: number): string {
    if (input.length > length) return input.substring(0, length) + "...";
    else return input;
  }

  public paginate<T>(array: T[], pageSize: number, pageNumber: number): T[] {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((pageNumber - 1) * pageSize, pageNumber * pageSize);
  }
}

export default new LINQ();
