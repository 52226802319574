import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IPaymentMethodDeleteMessageSubComponentStyles {}

export interface IPaymentMethodDeleteMessageStyles
  extends IAmigoBaseStyles<IPaymentMethodDeleteMessageSubComponentStyles> {}

export interface IPaymentMethodDeleteMessageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentMethodDeleteMessageClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentMethodDeleteMessageStyleProps,
    IPaymentMethodDeleteMessageStyles
  >,
  props?: IPaymentMethodDeleteMessageStyleProps
): [
  IProcessedStyleSet<IPaymentMethodDeleteMessageStyles>,
  IPaymentMethodDeleteMessageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentMethodDeleteMessageStyleProps,
  IPaymentMethodDeleteMessageStyles
> = (
  _props: IPaymentMethodDeleteMessageStyleProps
): IPaymentMethodDeleteMessageStyles => {
  return {};
};
