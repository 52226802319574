import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightBookingPassengersGridViewSubComponentStyles {}

export interface IFlightBookingPassengersGridViewStyles
  extends IAmigoBaseStyles<IFlightBookingPassengersGridViewSubComponentStyles> {}

export interface IFlightBookingPassengersGridViewStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingPassengersGridViewClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingPassengersGridViewStyleProps,
    IFlightBookingPassengersGridViewStyles
  >,
  props?: IFlightBookingPassengersGridViewStyleProps
): [
  IProcessedStyleSet<IFlightBookingPassengersGridViewStyles>,
  IFlightBookingPassengersGridViewSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingPassengersGridViewStyleProps,
  IFlightBookingPassengersGridViewStyles
> = (
  _props: IFlightBookingPassengersGridViewStyleProps
): IFlightBookingPassengersGridViewStyles => {
  return {
    root: ["amigo-FlightBookingPassengersGridView", {}],
  };
};
