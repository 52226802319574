import {
  DetailsList,
  IColumn,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import Moment from "moment";
import * as React from "react";
import { Redirect } from "react-router";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import { IRequestReference } from "src/models/Requests/IRequestReference";
import { FlightResultsResponse } from "src/models/Transports/FlightResultsResponse";
import { IFlightCriteria } from "src/models/Transports/IFlightCriteria";
import { IFlightOffer } from "src/models/Transports/IFlightOffer";
import { IFlightRequestDataModel } from "src/models/Transports/IFlightRequestDataModel";
import {
  FlightParticipantArrivalSchema,
  FlightParticipantDepartureSchema,
  IParticipantNameRecordModel,
} from "src/models/Transports/IPassengerNameRecordModel";
import i18n from "src/services/i18n";
import { TransportFlightBookingService } from "src/services/Transports/TransportFlightBookingService";
import { validate } from "src/utils/validator";
import { FlightBookingBreadcrumb } from "../FlightBookingBreadcrumb";
import { FlightBookingParticipantsGridView } from "./Controls/FlightBookingParticipantsGridView";
import { FlightBookingRequestsGridView } from "./Controls/FlightBookingRequestsGridView";
import { getFlightBookingParticipantsPageClassNames } from "./FlightBookingParticipantsPage.styles";
import {
  IFlightBookingParticipantsPageProps,
  IFlightBookingParticipantsPageState,
} from "./FlightBookingParticipantsPage.types";

export class FlightBookingParticipantsPageComponent extends React.Component<
  IFlightBookingParticipantsPageProps,
  IFlightBookingParticipantsPageState
> {
  constructor(props: IFlightBookingParticipantsPageProps) {
    super(props);

    const routeState = this.props.route.location.state as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    this.state = {
      participants: routeState.participants,
      request: routeState.request,
      errors: [],
      transit: false,
    };

    if (!this.state.participants && this.state.request) {
      TransportFlightBookingService.getParticipants(
        objectId(this.props.enterprise),
        this.state.request.id,
        criteria.arrival
      ).then((x) => {
        this.setState({
          participants: x,
        });
      });
    }
  }

  private getPrices = (offer: IFlightOffer): any[] => {
    return [
      {
        desc: "Total",
        value: i18n.currency(offer.totalPrice),
      },
    ];
  };

  private validateParticipants = (): void => {
    const routeState = this.props.route.location.state as any;
    if (!routeState) return;
    const offer: IFlightOffer = routeState.offer as any;
    const data: FlightResultsResponse = routeState.data as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    const reason: string = routeState.reason as any;
    const offerIndex: number = routeState.offerIndex as any;
    const serviceIndex: number = routeState.serviceIndex as any;
    const { participants, request } = this.state;

    const onValidate = (item: IParticipantNameRecordModel) => {
      const err =
        validate(
          criteria.arrival
            ? FlightParticipantArrivalSchema
            : FlightParticipantDepartureSchema,
          item
        ) ?? {};

      if (!criteria.arrival) {
        if (
          item.recall !== undefined &&
          item.recall !== 1 &&
          (item.reason ?? "").trim() === ""
        ) {
          err.reason = i18n.t("messages:assisted:newdeparture:reason:error");
        }
      }
      return err;
    };

    if (
      participants &&
      participants.filter((x) => x.selected).length === criteria.passengers
    ) {
      let valid: boolean = true;
      participants.forEach((x) => {
        x.errors = (x.selected ? onValidate(x) : []) as any;
        if (x.errors.length > 0 || Object.keys(x.errors).length > 0) {
          valid = false;
        }
      });

      if (
        participants.filter((x) => Object.keys(x.errors).length > 0).length > 0
      ) {
        valid = false;
      }

      if (valid) {
        const model: IFlightRequestDataModel = routeState.model as any;
        this.props.route.history.push("/transports/billetterie/passagers", {
          ...routeState,
          ...this.state,
          offer: offer,
          data: data,
          criteria: criteria,
          offerIndex: offerIndex,
          serviceIndex: serviceIndex,
          model: model,
          reason: reason,
          request: request,
          requestId: request?.id ?? -1,
          participants: participants,
        });
      } else {
        // error selected
        this.setState({
          participants: participants,
          errors: [],
        });
      }
    } else {
      // error selected
      this.setState({
        participants: participants,
        errors: [
          i18n.formatString(
            i18n.t("transports:flights:participants:selection:error"),
            criteria.passengers.toString()
          ),
        ],
      });
    }
  };

  private formatReason = (value: string): string => {
    if (!value) return "";
    switch (value) {
      case "DoubleArrivage":
        return "Double arrivage";
      case "Vacances":
        return value;
      case "FinContrat":
        return "Fin de contrat";
      case "Rapatriement":
        return value;
    }
    return value;
  };

  render(): JSX.Element {
    const { styles, enterprise, mobile, tablet } = this.props;
    const [classNames] = getFlightBookingParticipantsPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const routeState = this.props.route.location.state as any;
    if (!routeState) return <Redirect to={"/transports/billetterie"} />;
    const offer: IFlightOffer = routeState.offer as any;
    // const data: FlightResultsResponse = routeState.data as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    const reason: string = routeState.reason as any;
    const model: IFlightRequestDataModel = routeState.model as any;
    const { request } = this.state;
    const participants: IParticipantNameRecordModel[] | undefined =
      this.state.participants;
    const { arrival } = criteria;
    const { errors } = this.state;

    if (!offer) {
      return (
        <Redirect
          to={{
            pathname: "/transports/billetterie",
            state: routeState,
          }}
        />
      );
    }

    return (
      <div className={classNames.root}>
        <FlightBookingBreadcrumb
          {...this.props}
          styles={undefined}
          isCheckout={true}
          getState={() => {
            return {
              ...this.state,
              offer: offer,
              criteria: criteria,
              model: model,
              reason: reason,
              request: request,
              requestId: request?.id ?? -1,
              participants: participants,
            };
          }}
        />
        <Stack
          styles={{ root: { width: "100%", margin: "10px" } }}
          tokens={{ childrenGap: 20 }}
          horizontal={!mobile}
          grow
        >
          <StackItem
            styles={{
              root: {
                width: mobile ? "100%" : tablet ? "60%" : "75%",
                maxWidth: mobile ? "100%" : tablet ? "60%" : "75%",
              },
            }}
            grow={mobile ? true : 2}
          >
            {request && participants ? (
              <>
                {errors && errors.length > 0 && (
                  <div>
                    <ul>
                      {errors.map((x) => {
                        return (
                          <li style={{ color: this.props.theme.palette.red }}>
                            {x}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <FlightBookingParticipantsGridView
                  {...this.props}
                  styles={undefined}
                  errors={[]}
                  criteria={criteria}
                  offer={offer}
                  arrival={arrival}
                  request={request}
                  model={model}
                  onUpdate={(items: IParticipantNameRecordModel[]) => {
                    this.setState({ participants: items });
                  }}
                  participants={participants}
                  onCancel={() => {
                    this.setState({
                      participants: undefined,
                      request: undefined,
                    });
                  }}
                  onParticipantsSelected={(
                    items: IParticipantNameRecordModel[]
                  ) => {
                    const { participants } = this.state;
                    if (participants) {
                      participants.forEach((x) => {
                        if (items.filter((y) => y.id === x.id).length === 0) {
                          x.selected = false;
                        } else {
                          x.selected = true;
                        }
                      });
                      this.setState({ participants: participants });
                    }
                  }}
                />
              </>
            ) : (
              <FlightBookingRequestsGridView
                {...this.props}
                styles={undefined}
                errors={[]}
                arrival={arrival}
                requests={model.requests}
                onRequestSelected={(item: IRequestReference) => {
                  TransportFlightBookingService.getParticipants(
                    objectId(enterprise),
                    item.id,
                    criteria.arrival
                  ).then((x) => {
                    this.setState({
                      request: item,
                      participants: x,
                    });
                  });
                }}
              />
            )}
          </StackItem>
          <StackItem
            styles={{
              root: {
                width: mobile ? "100%" : tablet ? "40%" : "28%",
                maxWidth: mobile ? "100%" : tablet ? "40%" : "28%",
                marginRight: "20px",
              },
            }}
            grow={mobile ? true : 1}
          >
            <Card
              styles={{
                root: {
                  backgroundColor: this.props.theme.palette.white,
                  width: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              <Card.Section>
                <IconHeader
                  {...this.props}
                  styles={{ root: { marginLeft: "5px" } }}
                  iconName={"WorkforceManagement"}
                  title={i18n.t("transports:flights:booking:checkout")}
                />
                <div
                  style={{
                    borderTop: "1px solid lightgray",
                    padding: tablet ? "10px" : "20px",
                  }}
                >
                  {offer.departureDate !== undefined && (
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={"Date"}
                      content={Moment(offer.departureDate)
                        .locale(i18n.getLanguage())
                        .format("LL")}
                    />
                  )}
                  {!criteria.arrival && (
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      label={i18n.t("messages:assisted:newdeparture:reason")}
                      content={this.formatReason(reason)}
                    />
                  )}
                  <DetailsList
                    selectionMode={SelectionMode.none}
                    items={this.getPrices(offer)}
                    columns={[
                      {
                        key: "desc",
                        fieldName: "desc",
                        name: "",
                        minWidth: 170,
                        onRender: (
                          item?: any,
                          _index?: number,
                          _column?: IColumn
                        ) => {
                          return (
                            <Text
                              styles={{
                                root: {
                                  fontWeight:
                                    item.desc === "Total" ? 600 : "normal",
                                },
                              }}
                            >
                              {item.desc}
                            </Text>
                          );
                        },
                      },
                      {
                        key: "value",
                        fieldName: "value",
                        name: i18n.t("billing:payments:total"),
                        minWidth: 100,
                        styles: {
                          root: { textAlign: "right" },
                        },
                        onRender: (
                          item?: any,
                          _index?: number,
                          _column?: IColumn
                        ) => {
                          return (
                            <Text
                              styles={{
                                root: {
                                  textAlign: "right",
                                  fontWeight:
                                    item.desc === "Total" ? 600 : "normal",
                                },
                              }}
                            >
                              {item.value}
                            </Text>
                          );
                        },
                      },
                    ]}
                  />
                  <div style={{ height: "20px", clear: "both" }}></div>
                  <MessageBar messageBarType={MessageBarType.info}>
                    {i18n.t("transports:flights:currencywarning")}
                  </MessageBar>
                  <div style={{ height: "20px", clear: "both" }}></div>
                  <PrimaryButton
                    styles={{ root: { width: "100%" } }}
                    text={i18n.t("global:next")}
                    disabled={
                      !request ||
                      !participants ||
                      participants.filter((x) => x.selected).length === 0
                    }
                    onClick={() => {
                      this.validateParticipants();
                    }}
                  />
                </div>
              </Card.Section>
            </Card>
          </StackItem>
        </Stack>
        <div style={{ height: "80px" }}></div>
      </div>
    );
  }
}
