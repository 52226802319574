import AuthService from "./AuthService";
import Moment from "moment";

class MaintenanceService {
  public isOnMaintenance = (): boolean => {
    const session = AuthService.getUserInfo();
    if (session === null) return false; // not logged in
    if (session.maintenance === undefined) return false; // no maintenance TODO: should fire new maintenance
    if (session.maintenance === null) return false; // expired or no maintenance TODO: should fire new maintenance
    if (new Date(session.maintenance.end) < new Date()) return false; // expired maintenance TODO: should fire new maintenance
    const val = localStorage.getItem("ackmaintenance");
    if (val === undefined) return true;
    if (val === "") return true;
    if (val === Moment(new Date()).format("YYYY-MM-DD")) return false;
    else {
      localStorage.removeItem("ackmaintenance");
    }
    return true;
  };

  public acknowledge(): void {
    localStorage.setItem(
      "ackmaintenance",
      Moment(new Date()).format("YYYY-MM-DD")
    );
  }
}

export default new MaintenanceService();
