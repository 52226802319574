import {
  CommandBar,
  DefaultPalette,
  Dropdown,
  IDropdownOption,
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { IconHeader } from "src/components/IconHeader";
import { ParticipantAddressDetailsSchema } from "src/models/Participants/IParticipantDetails";
import i18n from "src/services/i18n";
import ParticipantsService from "src/services/Participants/ParticipantsService";
import { getFieldMaxLength } from "src/utils/validator";
import { getParticipantAddressPanelClassNames } from "./ParticipantAddressPanel.styles";
import {
  IParticipantAddressPanelProps,
  IParticipantAddressPanelState,
} from "./ParticipantAddressPanel.types";

export class ParticipantAddressPanelComponent extends React.Component<
  IParticipantAddressPanelProps,
  IParticipantAddressPanelState
> {
  constructor(props: IParticipantAddressPanelProps) {
    super(props);
    this.state = {
      states: [],
      errors: {},
      saving: false,
      address: {
        id: this.props.participant.address.id,
        street: this.props.participant.address.street,
        city: this.props.participant.address.city,
        state: this.props.participant.address.state,
        postalcode: this.props.participant.address.postalcode,
        cellphone: this.props.participant.cellphone,
        phone: this.props.participant.phone,
        email: this.props.participant.email,
        country: this.props.participant.address.country,
        countryId: this.props.participant.address.countryId,
      },
    };

    this.props.participant.address.countryId &&
      ParticipantsService.getProvinces(
        this.props.participant.address.countryId
      ).then((x) => {
        this.setState({
          states: x,
        });
      });
  }

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantAddressPanelClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { address, states, errors } = this.state;
    const options: IDropdownOption[] = states.map((x) => {
      return {
        key: x.id.toString(),
        id: x.id.toString(),
        text: x.name,
        selected: (address.state ?? "").toLowerCase() === x.name.toLowerCase(),
      };
    });

    const id: number | undefined =
      options.filter((x) => x.selected).length === 0
        ? undefined
        : parseInt(options.filter((x) => x.selected)[0].id ?? "-1");

    const canSave: boolean =
      Object.keys(errors).length === 0 ||
      (Object.keys(errors).length === 1 && errors.global !== undefined);

    return (
      <div className={classNames.root}>
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg10 ms-xl8 ms-xxl8">
          <div
            style={{
              position: "relative",
              backgroundColor: DefaultPalette.white,
              margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
            }}
            className="ms-depth-16"
          >
            <IconHeader
              {...this.props}
              styles={subComponentStyles?.iconHeader}
              iconName={"MapPin"}
              title={i18n.t("participants:address")}
            />
            {address && (
              <div>
                <CommandBar
                  styles={{
                    root: {
                      backgroundColor: DefaultPalette.neutralLighter,
                    },
                    primarySet: {
                      backgroundColor: DefaultPalette.neutralLighter,
                    },
                  }}
                  ariaLabel={i18n.t("participants:address")}
                  items={[
                    {
                      disabled: !canSave,
                      key: "save",
                      buttonStyles: {
                        root: {
                          backgroundColor: DefaultPalette.neutralLighter,
                        },
                      },
                      iconProps: {
                        iconName: "Save",
                      },
                      text: i18n.t("participants:save"),
                      onClick: () => {
                        this.props
                          .onSave(this.state.address)
                          .then(() => {
                            this.setState({ saving: false });
                          })
                          .catch((_x) => {
                            const z: any = { global: "participants:error:0" };
                            this.setState({
                              errors: z,
                            });
                          });
                        this.setState({ saving: true });
                      },
                    },
                    {
                      key: "cancel",
                      buttonStyles: {
                        root: {
                          backgroundColor: DefaultPalette.neutralLighter,
                        },
                      },
                      iconProps: {
                        iconName: "Cancel",
                      },
                      text: i18n.t("participants:cancel"),
                      onClick: () => {
                        this.props.onDismiss();
                      },
                    },
                  ]}
                />
                <div style={{ padding: "10px 10px 20px 20px" }}>
                  {errors && errors.length > 0 && errors.global != null && (
                    <MessageBar messageBarType={MessageBarType.error}>
                      {i18n.t(errors.global)}
                    </MessageBar>
                  )}

                  <Stack tokens={{ childrenGap: 10 }}>
                    <StackItem grow>
                      <TextField
                        label={i18n.t("participants:street")}
                        maxLength={100}
                        errorMessage={errors.street}
                        value={address.street}
                        onChange={(
                          _event: React.FormEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >,
                          newValue?: string
                        ) => {
                          const { address } = this.state;
                          address.street = newValue ?? "";
                          this.setState({ address: address });
                        }}
                      />
                    </StackItem>
                    <StackItem grow>
                      <Stack grow horizontal tokens={{ childrenGap: 10 }}>
                        <StackItem grow>
                          <div>
                            <TextField
                              label={i18n.t("participants:city")}
                              maxLength={getFieldMaxLength(
                                ParticipantAddressDetailsSchema,
                                "city",
                                100
                              )}
                              errorMessage={errors.city}
                              value={address.city}
                              onChange={(
                                _event: React.FormEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                                newValue?: string
                              ) => {
                                const { address } = this.state;
                                address.city = newValue ?? "";
                                this.setState({ address: address });
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "10px" }}>
                            <TextField
                              label={i18n.t("participants:postal")}
                              maxLength={getFieldMaxLength(
                                ParticipantAddressDetailsSchema,
                                "postalcode",
                                25
                              )}
                              errorMessage={errors.postalcode}
                              value={address.postalcode}
                              onChange={(
                                _event: React.FormEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                                newValue?: string
                              ) => {
                                const { address } = this.state;
                                address.postalcode = newValue ?? "";
                                this.setState({ address: address });
                              }}
                            />
                          </div>
                        </StackItem>
                        <StackItem grow>
                          <div>
                            <Dropdown
                              options={options}
                              selectedKey={id ? id?.toString() : undefined}
                              label={i18n.t("participants:state")}
                              errorMessage={errors.state}
                              onChange={(
                                _event: React.FormEvent<HTMLDivElement>,
                                option?: IDropdownOption,
                                _index?: number
                              ) => {
                                if (option) {
                                  const { address } = this.state;
                                  address.state = option.text;
                                  this.setState({ address: address });
                                }
                              }}
                            />
                          </div>
                          <div style={{ marginTop: "20px" }}>
                            <div>
                              <Text style={{ fontWeight: "bold" }}>
                                {i18n.t("participants:country")}
                              </Text>
                            </div>
                            <div style={{ marginTop: "10px" }}>
                              <Text>{address.country}</Text>
                              <CountryIcon
                                {...this.props}
                                styles={undefined}
                                country={address.country}
                              />
                            </div>
                          </div>
                        </StackItem>
                      </Stack>
                    </StackItem>
                    <StackItem grow>
                      <Stack
                        grow
                        horizontal={!mobile}
                        tokens={{ childrenGap: 10 }}
                      >
                        <StackItem grow>
                          <TextField
                            label={i18n.t("participants:email")}
                            maxLength={100}
                            value={address.email}
                            errorMessage={errors.email}
                            onChange={(
                              _event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string
                            ) => {
                              const { address } = this.state;
                              address.email = newValue ?? "";
                              this.setState({ address: address });
                            }}
                          />
                        </StackItem>
                        <StackItem grow>
                          <TextField
                            styles={subComponentStyles?.textbox}
                            maxLength={getFieldMaxLength(
                              ParticipantAddressDetailsSchema,
                              "phone",
                              100
                            )}
                            label={i18n.t("participants:phone")}
                            errorMessage={errors.phone}
                            value={address.phone}
                            onChange={(
                              _event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string
                            ) => {
                              const { address } = this.state;
                              address.phone = newValue ?? "";
                              this.setState({ address: address });
                            }}
                          />
                        </StackItem>
                        <StackItem grow>
                          <TextField
                            styles={subComponentStyles?.textbox}
                            maxLength={getFieldMaxLength(
                              ParticipantAddressDetailsSchema,
                              "cellphone",
                              100
                            )}
                            label={i18n.t("participants:cellphone")}
                            errorMessage={errors.cellphone}
                            value={address.cellphone}
                            onChange={(
                              _event: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string
                            ) => {
                              const { address } = this.state;
                              address.cellphone = newValue ?? "";
                              this.setState({ address: address });
                            }}
                          />
                        </StackItem>
                      </Stack>
                    </StackItem>
                  </Stack>
                </div>
                <div style={{ height: "20px" }}></div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
