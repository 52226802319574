import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ICountryIconStyleProps,
  ICountryIconStyles,
} from "src/components/CountryIcon";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IRequestCardStyleProps,
  IRequestCardStyles,
} from "src/pages/Requests/RequestCard";

export interface IFlightBookingRequestsGridViewSubComponentStyles {}

export interface IFlightBookingRequestsGridViewStyles
  extends IAmigoBaseStyles<IFlightBookingRequestsGridViewSubComponentStyles> {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<IRequestCardStyleProps, IRequestCardStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  country?: IStyleFunctionOrObject<ICountryIconStyleProps, ICountryIconStyles>;
}

export interface IFlightBookingRequestsGridViewStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingRequestsGridViewClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingRequestsGridViewStyleProps,
    IFlightBookingRequestsGridViewStyles
  >,
  props?: IFlightBookingRequestsGridViewStyleProps
): [
  IProcessedStyleSet<IFlightBookingRequestsGridViewStyles>,
  IFlightBookingRequestsGridViewSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingRequestsGridViewStyleProps,
  IFlightBookingRequestsGridViewStyles
> = (
  _props: IFlightBookingRequestsGridViewStyleProps
): IFlightBookingRequestsGridViewStyles => {
  return {
    root: ["amigo-FlightBookingRequestsGridView", {}],
  };
};
