import * as React from "react";
import { getCountryIconClassNames } from "./CountryIcon.styles";
import flags from "../../flags";
import { ICountryIconState, ICountryIconProps } from "./CountryIcon.types";
import { TooltipHost, Icon, mergeStyles } from "@fluentui/react";

export class CountryIconComponent extends React.Component<
  ICountryIconProps,
  ICountryIconState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getCountryIconClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <TooltipHost className={classNames.root} content={this.props.country}>
        <Icon
          imageProps={{
            src: `${flags[this.props.country]}`,
            width: this.props.width ?? "16px",
            height: this.props.height ?? "16px",
            className: mergeStyles(this.props.style),
          }}
        />
      </TooltipHost>
    );
  }
}
