import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "../../../../Base";

export interface IFlightSearchOffsetOfferCardSubComponentStyles {}

export interface IFlightSearchOffsetOfferCardStyles
  extends IAmigoBaseStyles<IFlightSearchOffsetOfferCardSubComponentStyles> {}

export interface IFlightSearchOffsetOfferCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchOffsetOfferCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchOffsetOfferCardStyleProps,
    IFlightSearchOffsetOfferCardStyles
  >,
  props?: IFlightSearchOffsetOfferCardStyleProps
): [
  IProcessedStyleSet<IFlightSearchOffsetOfferCardStyles>,
  IFlightSearchOffsetOfferCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchOffsetOfferCardStyleProps,
  IFlightSearchOffsetOfferCardStyles
> = (
  _props: IFlightSearchOffsetOfferCardStyleProps
): IFlightSearchOffsetOfferCardStyles => {
  return {
    root: ["amigo-FlightSearchOffsetOfferCard", {}],
  };
};
