import {
  DefaultPalette,
  Icon,
  PersonaCoin,
  PersonaSize,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import {
  Card,
  ICardSectionStyles,
  ICardSectionTokens,
} from "@fluentui/react-cards";
import * as React from "react";
import { MediaQueries } from "src/components/MediaQueries";
import i18n from "src/services/i18n";
import { PeriodPositionCountText } from "../PeriodPositionCountText";
import { getRequestPeriodsControlClassNames } from "./RequestPeriodsControl.styles";
import {
  IRequestPeriodsControlProps,
  IRequestPeriodsControlState,
} from "./RequestPeriodsControl.types";

export class RequestPeriodsControlComponent extends React.Component<
  IRequestPeriodsControlProps,
  IRequestPeriodsControlState
> {
  render(): JSX.Element {
    const { styles, route, mobile } = this.props;
    const [classNames, subComponentStyles] = getRequestPeriodsControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const footerCardSectionStyles: ICardSectionStyles = {
      root: {
        alignSelf: "stretch",
        borderLeft: "1px solid #F3F2F1",
      },
    };

    const footerCardSectionTokens: ICardSectionTokens = {
      padding: "0px 0px 0px 12px",
    };

    return (
      <div style={{ width: "100%", padding: mobile ? "0" : "15px" }}>
        {this.props.periods.map((x) => {
          let count: number = 0;
          x.positions.forEach((y) => {
            count += y.count;
          });

          return (
            <div
              key={`ws-nvx-${x.key}`}
              className={classNames.requestPeriodCard}
            >
              <Card
                key={x.key}
                horizontal
                onClick={() => {
                  route.history.push(
                    "/demandes/" +
                      this.props.requestId.toString() +
                      "/periodes/" +
                      x.key.toString()
                  );
                }}
                style={{
                  backgroundColor: DefaultPalette.white,
                }}
                tokens={this.props.tokens.defaultCardTokens}
              >
                {!mobile && (
                  <Card.Item fill>
                    <Icon
                      iconName="Code"
                      styles={{
                        root: {
                          fontSize: "56px",
                          margin: "15px 15px 15px 0",
                        },
                      }}
                    />
                  </Card.Item>
                )}
                <Card.Section
                  styles={{
                    root: {
                      flexGrow: 1,
                      [MediaQueries.mobile]: {
                        marginLeft: "10px",
                      },
                    },
                  }}
                >
                  <Text
                    variant="small"
                    styles={this.props.tokens.defaultCardTitleStyles}
                  >
                    {i18n.formatString(
                      i18n.t("requests:fromto"),
                      i18n.parseISOLocalString(x.startDate),
                      i18n.parseISOLocalString(x.endDate)
                    )}
                  </Text>
                  <TooltipHost content={x.EIMT}>
                    <Text styles={this.props.tokens.defaultCardTextStyle}>
                      {`${i18n.t("request:EIMT:number")} : ${
                        (x.EIMT ?? "").length > 25
                          ? x.EIMT.substr(0, 25) + " ..."
                          : x.EIMT
                      }`}
                    </Text>
                  </TooltipHost>
                  <PeriodPositionCountText
                    {...this.props}
                    styles={subComponentStyles?.participantCount}
                    onClick={() => this.forceUpdate()}
                    periodId={x.id}
                    maxPositions={this.resolveMaxPositions()}
                    {...x}
                    variant="small"
                  />
                </Card.Section>
                <Card.Section
                  styles={footerCardSectionStyles}
                  tokens={footerCardSectionTokens}
                >
                  <TooltipHost
                    content={`${count.toString()} ${i18n.t(
                      count > 1 ? "requests:postes" : "requests:poste"
                    )}`}
                  >
                    <PersonaCoin
                      initialsColor={"rgb(122, 117, 116)"}
                      imageAlt={`${count.toString()} ${i18n.t(
                        count > 1 ? "requests:postes" : "requests:poste"
                      )}`}
                      text={count.toString()}
                      coinSize={18}
                      onRenderInitials={() => {
                        return <span>{count.toString()}</span>;
                      }}
                      aria-label={`${count.toString()} ${i18n.t(
                        count > 1 ? "requests:postes" : "requests:poste"
                      )}`}
                      size={PersonaSize.size16}
                      style={{
                        fontSize: "14px",
                      }}
                    />
                  </TooltipHost>
                  <Stack.Item grow={1}>
                    <span />
                  </Stack.Item>
                </Card.Section>
              </Card>
            </div>
          );
        })}
      </div>
    );
  }

  private resolveMaxPositions = (): number => {
    let i = 0;
    this.props.periods.forEach((x) => {
      if (x.positions.length > i) {
        i = x.positions.length;
      }
    });
    return i;
  };
}
