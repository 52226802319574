import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "src/components/Base";
import { MediaQueries } from "src/components/MediaQueries";
import { INavBarStyleProps, INavBarStyles } from "../NavBar";
import {
  INavigationMenuPanelStyleProps,
  INavigationMenuPanelStyles,
} from "../NavigationMenuPanel";

export interface INavHeaderSubComponentStyles {
  navBar?: IStyleFunctionOrObject<INavBarStyleProps, INavBarStyles>;
  navigationMenuPanel?: IStyleFunctionOrObject<
    INavigationMenuPanelStyleProps,
    INavigationMenuPanelStyles
  >;
}

export interface INavHeaderStyles
  extends IComponentBaseStyles<INavHeaderSubComponentStyles> {
  logo?: IStyle;
  profileButton?: IStyle;
}

export interface INavHeaderStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getNavHeaderClassNames = (
  styles?: IStyleFunctionOrObject<INavHeaderStyleProps, INavHeaderStyles>,
  props?: INavHeaderStyleProps
): [
  IProcessedStyleSet<INavHeaderStyles>,
  INavHeaderSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<INavHeaderStyleProps, INavHeaderStyles> =
  (props: INavHeaderStyleProps): INavHeaderStyles => {
    return {
      root: [
        "ms-NavHeader",
        {
          height: "100%",
          display: "flex",
          alignItems: "center",
          padding: "0 0.5em",
          color: props.theme.palette.white,
          backgroundColor: props.theme.palette.white,
          justifyContent: "space-between",
          selectors: {
            [MediaQueries.mobile]: {
              boxShadow: "0 3px 6px rgba(0,0,0,.08)",
            },
          },
        },
      ],
      logo: [
        "ms-NavHeaderLogo",
        {
          cursor: "pointer",
          backgroundImage: props.tokens?.backgroundLogoImage,
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          width: "153px",
          height: "39px",
          backgroundPosition: "center",
        },
      ],
      profileButton: props.tokens?.defaultNavBarButton ?? { fontSize: "22px" },
    };
  };
