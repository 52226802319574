import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  ITextStyles,
  IDetailsRowCheckStyleProps,
  IDetailsRowCheckStyles,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";
import { ICardSectionStyles } from "@fluentui/react-cards";
import { MediaQueries } from "../MediaQueries";

export interface IGridViewCardSubComponentStyles {
  title?: ITextStyles;
  footer?: ITextStyles;
  selectorComponent?: IStyleFunctionOrObject<
    IDetailsRowCheckStyleProps,
    IDetailsRowCheckStyles
  >;
  iconSection?: ICardSectionStyles;
}

export interface IGridViewCardStyles
  extends IComponentBaseStyles<IGridViewCardSubComponentStyles> {
  selector?: IStyle;
  footerContainer?: IStyle;
  footerIconContainer?: IStyle;
  footerTextContainer?: IStyle;
}

export interface IGridViewCardStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  highlight?: boolean;
  boxed?: boolean;
  tablet: boolean;
  footerIcon?: boolean;
  embedded?: boolean;
}

export const getGridViewCardClassNames = (
  styles?: IStyleFunctionOrObject<IGridViewCardStyleProps, IGridViewCardStyles>,
  props?: IGridViewCardStyleProps
): [
  IProcessedStyleSet<IGridViewCardStyles>,
  IGridViewCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IGridViewCardStyleProps,
  IGridViewCardStyles
> = (props: IGridViewCardStyleProps): IGridViewCardStyles => {
  return {
    root: [
      "ms-GridViewCard",
      {
        position: "relative",
        backgroundColor: props.theme.palette.white,
        minWidth: "unset",
        cursor: "pointer",
        float: "left",
        margin: "10px",
        padding: "10px",
        minHeight: "130px",
        width: props.embedded ? "27%" : "23%",
        selectors: {
          ":focus": {
            border: "0px!important",
          },
          [MediaQueries.tablet]: {
            width: props.embedded ? "49%" : "46%",
          },
          [MediaQueries.mobile]: {
            width: props.boxed ? "calc(-55px + 100vw)" : "calc(-20px + 100vw)",
          },
        },
      },
      props.highlight
        ? {
            boxShadow:
              "rgba(201, 52, 51, 0.133) 0px 3.2px 7.2px 0px, rgba(202, 52, 51, 0.11) 0px 0.6px 1.8px 0px",
            backgroundColor: "rgba(245, 135, 145, 0.06)",
          }
        : undefined,
    ],
    selector: [
      "ms-GridCardView-Selector",
      {
        cursor: "pointer",
        color: props.theme.palette.themePrimary,
        margin: 0,
        padding: 0,
        width: "18px!important",
        height: "18px!important",
        selectors: {
          ":hover": {
            color: props.theme.palette.themePrimary,
            opacity: 1,
          },
        },
      },
    ],
    footerContainer: {
      position: "absolute",
      bottom: "4px",
    },
    footerIconContainer: {
      float: "left",
    },
    footerTextContainer: {
      float: "left",
      lineHeight: "16px",
      marginLeft: "0.7rem",
      maxWidth: props.mobile || props.tablet ? "236px" : "316px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      overflow: "hidden",
    },
    subComponentStyles: {
      title: props.tokens?.defaultCardTitleStyles,
      footer: props.tokens?.defaultCardLabelStyles,
      selectorComponent: {
        root: [
          "ms-GridViewCard-Selector",
          {
            cursor: "pointer",
            color: props.theme.palette.themePrimary,
            margin: 0,
            padding: 0,
            opacity: "1!important", // props.mobile ? 1 : 0,
            width: "18px!important",
            height: "18px!important",
            selectors: {
              [MediaQueries.mobile]: {
                cursor: "pointer",
                opacity: "1!important",
              },
              ":hover": {
                cursor: "pointer!important",
                color: props.theme.palette.themePrimary,
                opacity: "1!important",
              },
            },
          },
        ],
      },
      iconSection: props.tokens?.iconCardSectionStyles,
    },
  };
};
