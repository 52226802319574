import {
  Coachmark,
  DirectionalHint,
  PersonaSize,
  TeachingBubbleContent,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import i18n from "../../services/i18n";
import { NumberedPersonaCoin } from "../NumberedPersona/NumberedPersonaCoin";
import { getMessageNotificationClassNames } from "./MessageNotification.styles";
import {
  IMessageNotificationProps,
  IMessageNotificationState,
} from "./MessageNotification.types";

export class MessageNotificationComponent extends React.Component<
  IMessageNotificationProps,
  IMessageNotificationState
> {
  private _targetButton = React.createRef<HTMLDivElement>();

  constructor(props: IMessageNotificationProps) {
    super(props);
    this.state = {
      hasNotificationChanged: true,
      presenceTitle: "0",
      presenceDescription: `0 ${i18n.t("menu:newmessages")}`,
    };
    this.setTimer(() => {
      this.props
        .onGetNotification()
        .then((x) => {
          if (x === undefined || x === null) return;
          let timer;
          if (this.state.presenceTitle !== x.toString() && x !== 0) {
            timer = this.setDismissTimeout();
          }
          this.setState({
            timer: timer,
            hasNotificationChanged:
              this.state.presenceTitle !== x.toString() && x !== 0,
            presenceTitle: x.toString(),
            presenceDescription: `${x} ${i18n.t("menu:newmessages")}`,
          });
        })
        .catch((_x) => {});
    });
  }

  private setTimer = (run: () => void) => {
    run();
    setInterval(() => {
      run();
    }, 160000);
  };

  private setDismissTimeout = (): NodeJS.Timeout => {
    return setTimeout(() => {
      this.setState({
        hasNotificationChanged: false,
      });
    }, 160000);
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getMessageNotificationClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <TooltipHost
        className={classNames.tooltip}
        content={i18n.t("menu:messages")}
      >
        <div
          className={classNames.root}
          ref={this._targetButton}
          onMouseMove={() => {
            if (
              this.state.hasNotificationChanged &&
              this.state.timer !== undefined
            ) {
              clearTimeout(this.state.timer);
              this.setState({
                timer: undefined,
              });
            }
          }}
        >
          <NumberedPersonaCoin
            mobile={this.props.mobile}
            onClick={this.props.onClick}
            className={classNames.coin}
            size={PersonaSize.size32}
            presenceTitle={this.state.presenceTitle}
            hidePersonaDetails={true}
            imageAlt={this.state.presenceDescription}
          />
          {this.state.hasNotificationChanged && (
            <Coachmark
              className={classNames.coachmark}
              onMouseMove={() => {
                if (this.state.timer !== undefined) {
                  clearTimeout(this.state.timer);
                }
                this.setState({
                  timer: undefined,
                });
              }}
              target={this._targetButton.current}
              positioningContainerProps={{
                directionalHint:
                  this.props.directionalHint ?? DirectionalHint.bottomAutoEdge,
                doNotLayer: false,
              }}
            >
              <TeachingBubbleContent
                headline={i18n.t("menu:newmessages:bubble:title")}
                hasCloseButton={true}
                closeButtonAriaLabel={i18n.t("global:close")}
                primaryButtonProps={{
                  text: i18n.t("messages:gotoinbox"),
                  onClick: () => {
                    this.props.onClick();
                    if (this.state.timer !== undefined) {
                      clearTimeout(this.state.timer);
                    }
                    this.setState({
                      timer: undefined,
                      hasNotificationChanged: false,
                    });
                  },
                }}
                secondaryButtonProps={{
                  text: i18n.t("global:close"),
                  onClick: () => {
                    if (this.state.timer !== undefined) {
                      clearTimeout(this.state.timer);
                    }
                    this.setState({
                      timer: undefined,
                      hasNotificationChanged: false,
                    });
                  },
                }}
                onDismiss={() => {
                  if (this.state.timer !== undefined) {
                    clearTimeout(this.state.timer);
                  }
                  this.setState({
                    timer: undefined,
                    hasNotificationChanged: false,
                  });
                }}
                ariaDescribedBy={""}
                ariaLabelledBy={""}
              >
                {i18n
                  .t("menu:newmessages:bubble")
                  .replace("{0}", this.state.presenceTitle)}
              </TeachingBubbleContent>
            </Coachmark>
          )}
        </div>
      </TooltipHost>
    );
  }
}
