import * as React from "react";
import { getScrollToTopClassNames } from "./ScrollToTop.styles";
import { IScrollToTopState, IScrollToTopProps } from "./ScrollToTop.types";
import { FontIcon } from "@fluentui/react";

const DEFAULT_SCROLL_TO_TOP_ICON: string = "SkypeCircleArrow";

export class ScrollToTopComponent extends React.Component<
  IScrollToTopProps,
  IScrollToTopState
> {
  constructor(props: IScrollToTopProps) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  componentDidMount() {
    const _this = this;
    document.addEventListener("scroll", (e) => {
      _this.toggleVisibility(
        (e as any).detail ? (e as any).detail.scrollTop : undefined
      );
    });
  }

  toggleVisibility(offset: number | undefined) {
    if (offset && offset > 300) {
      this.setState({
        isVisible: true,
      });
    } else if (window.pageYOffset > 300) {
      this.setState({
        isVisible: true,
      });
    } else {
      this.setState({
        isVisible: false,
      });
    }
  }

  private scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    const containers = document.getElementsByClassName("ms-GridViewContainer");
    if (containers.length > 0) {
      containers[0].scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  render(): JSX.Element {
    const { isVisible } = this.state;
    const { styles, iconProps } = this.props;
    const [classNames] = getScrollToTopClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        {(this.props.isVisible ?? isVisible) && (
          <div onClick={this.scrollToTop}>
            <FontIcon
              {...(iconProps ?? {
                iconName: DEFAULT_SCROLL_TO_TOP_ICON,
              })}
              className={classNames.icon}
            />
          </div>
        )}
      </div>
    );
  }
}
