import * as React from "react";
import { getTransportArrivalUpdateAssistedControlClassNames } from "./TransportArrivalUpdateAssistedControl.styles";
import {
  ITransportArrivalUpdateAssistedControlState,
  ITransportArrivalUpdateAssistedControlProps,
} from "./TransportArrivalUpdateAssistedControl.types";

export class TransportArrivalUpdateAssistedControlComponent extends React.Component<
  ITransportArrivalUpdateAssistedControlProps,
  ITransportArrivalUpdateAssistedControlState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getTransportArrivalUpdateAssistedControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return <div className={classNames.root}></div>;
  }
}
