import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ISessionProfile } from "src/models/ISessionProfile";

export class TelemetryService {
  public static trackLogin(
    appInsights: ApplicationInsights | undefined,
    session: ISessionProfile
  ): void {
    appInsights?.setAuthenticatedUserContext(
      session.id,
      session.username,
      false
    );
  }
}
