import { styled } from "@fluentui/react/lib/Utilities";
import { IAssistedMessageEditionContainerProps } from "./AssistedMessageEditionContainer.types";
import {
  getStyles,
  IAssistedMessageEditionContainerStyleProps,
  IAssistedMessageEditionContainerStyles,
} from "./AssistedMessageEditionContainer.styles";
import { AssistedMessageEditionContainerComponent } from "./AssistedMessageEditionContainer.base";

/**
 * AssistedMessageEditionContainer
 */
export const AssistedMessageEditionContainer = styled<
  IAssistedMessageEditionContainerProps,
  IAssistedMessageEditionContainerStyleProps,
  IAssistedMessageEditionContainerStyles
>(AssistedMessageEditionContainerComponent, getStyles);
