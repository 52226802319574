import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightSearchPageSubComponentStyles {}

export interface IFlightSearchPageStyles
  extends IAmigoBaseStyles<IFlightSearchPageSubComponentStyles> {}

export interface IFlightSearchPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchPageStyleProps,
    IFlightSearchPageStyles
  >,
  props?: IFlightSearchPageStyleProps
): [
  IProcessedStyleSet<IFlightSearchPageStyles>,
  IFlightSearchPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchPageStyleProps,
  IFlightSearchPageStyles
> = (_props: IFlightSearchPageStyleProps): IFlightSearchPageStyles => {
  return {
    root: [
      "amigo-FlightSearchPage",
      {
        width: "100%",
      },
    ],
  };
};
