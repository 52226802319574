import { styled } from "@fluentui/react/lib/Utilities";
import { IPendingBillPanelProps } from "./PendingBillPanel.types";
import {
  getStyles,
  IPendingBillPanelStyleProps,
  IPendingBillPanelStyles,
} from "./PendingBillPanel.styles";
import { PendingBillPanelComponent } from "./PendingBillPanel.base";

/**
 * PendingBillPanel description
 */
export const PendingBillPanel = styled<
  IPendingBillPanelProps,
  IPendingBillPanelStyleProps,
  IPendingBillPanelStyles
>(PendingBillPanelComponent, getStyles);
