import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertDetailsProps } from "./AlertDetails.types";
import {
  getStyles,
  IAlertDetailsStyleProps,
  IAlertDetailsStyles,
} from "./AlertDetails.styles";
import { AlertDetailsComponent } from "./AlertDetails.base";

/**
 * AlertDetails description
 */
export const AlertDetails = styled<
  IAlertDetailsProps,
  IAlertDetailsStyleProps,
  IAlertDetailsStyles
>(AlertDetailsComponent, getStyles);
