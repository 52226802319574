import * as React from "react";
import { MaskedTextField } from "@fluentui/react";
import i18n from "../../../services/i18n";

export interface ICreditCardNumberFieldProps {
  value: string;
  errorMessage: string | JSX.Element | undefined;
  onChange: (newValue: string) => void;
  onFocus: () => void;
}

const maskFormat: { [key: string]: RegExp } = {
  "*": /[0-9]/,
};

export interface ICreditCardNumberFieldState {}

export class CreditCardNumberField extends React.Component<
  ICreditCardNumberFieldProps,
  ICreditCardNumberFieldState
> {
  constructor(props: ICreditCardNumberFieldProps) {
    super(props);

    this.state = {};
  }

  public render() {
    return (
      <div>
        <MaskedTextField
          onFocus={this.props.onFocus}
          label={i18n.t("enterprises:paymentmethods:card")}
          required
          errorMessage={this.props.errorMessage}
          mask={"**** **** **** ****"}
          maskFormat={maskFormat}
          value={this.props.value}
          iconProps={{
            iconName: "PaymentCard",
          }}
          onChange={(
            event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            _newValue?: string
          ) => {
            this.props.onChange((event.target as any).value);
          }}
        />
      </div>
    );
  }
}
