import { ITravelWarning } from "src/models/Transports/ITravelWarning";
import i18n from "../i18n";

export class TravelWarningService {
  public static getTravelWarnings(): Promise<ITravelWarning[]> {
    return new Promise<ITravelWarning[]>((resolve) => {
      resolve([
        {
          level: 1,
          name: i18n.t("transports:flightcriteria:generalwarning:name"),
          description: i18n.t(
            "transports:flightcriteria:generalwarning:description"
          ),
          subtext: i18n.t("transports:flightcriteria:generalwarning:subtext"),
        },
      ]);
    });
  }
}
