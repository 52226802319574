import * as React from "react";
import { getRequestParticipantsSectionClassNames } from "./RequestParticipantsSection.styles";
import {
  IRequestParticipantsSectionState,
  IRequestParticipantsSectionProps,
} from "./RequestParticipantsSection.types";
import RequestsService from "src/services/Requests/RequestsService";
import { RequestParticipantItem } from "../RequestParticipantItem";
import { RequestParticipantsControl } from "../RequestParticipantsControl";
import { IPeriodParticipant } from "src/models/Requests/IPeriodParticipant";
import { PanelType } from "@fluentui/react";
import { EntityPanel } from "src/components/EntityPanel";
import { PositionPanel } from "../PositionPanel";
import { objectId } from "src/models/IObjectReference";

export class RequestParticipantsSectionComponent extends React.Component<
  IRequestParticipantsSectionProps,
  IRequestParticipantsSectionState
> {
  constructor(props: IRequestParticipantsSectionProps) {
    super(props);
    this.state = {};
  }

  private onParticipantSelected = (participantId: number): void => {
    RequestsService.getRequestParticipantDetail(
      parseInt(this.props.enterprise.key?.toString() ?? "-1"),
      this.props.requestId,
      participantId
    ).then((x) => {
      this.setState({
        participant: x,
      });
    });
  };

  private onPositionSelected = (poste: number): void => {
    poste !== undefined &&
      RequestsService.getPosition(objectId(this.props.enterprise), poste).then(
        (x) =>
          this.setState({
            position: x,
          })
      );
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getRequestParticipantsSectionClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <>
        <div className={classNames.root}>
          {this.state.participant ? (
            <RequestParticipantItem
              {...this.props}
              styles={subComponentStyles?.participantItem}
              inline={false}
              onDismiss={() => this.setState({ participant: undefined })}
              participant={this.state.participant}
            />
          ) : (
            <RequestParticipantsControl
              {...this.props}
              styles={subComponentStyles?.participants}
              onParticipantSelected={(item: IPeriodParticipant) =>
                this.onParticipantSelected(item.key)
              }
              onPositionSelected={(item: IPeriodParticipant) => {
                item.poste !== undefined && this.onPositionSelected(item.poste);
              }}
            />
          )}
        </div>

        <EntityPanel
          {...this.props}
          panelType={PanelType.medium}
          styles={undefined}
          data-automation-id="nvx:requests:periods:positionpanel"
          isOpen={this.state.position !== undefined}
          onDismiss={() => {
            this.setState({
              position: undefined,
            });
          }}
          elementName={"Poste"}
          title={this.state.position?.title}
        >
          {this.state.position === undefined ? (
            <span></span>
          ) : (
            <PositionPanel
              {...this.state}
              {...this.props}
              styles={subComponentStyles?.participantItem}
              position={this.state.position}
            />
          )}
        </EntityPanel>
      </>
    );
  }
}
