import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantTransfertAssistedControlProps } from "./ParticipantTransfertAssistedControl.types";
import {
  getStyles,
  IParticipantTransfertAssistedControlStyleProps,
  IParticipantTransfertAssistedControlStyles,
} from "./ParticipantTransfertAssistedControl.styles";
import { ParticipantTransfertAssistedControlComponent } from "./ParticipantTransfertAssistedControl.base";

/**
 * ParticipantTransfertAssistedControl
 */
export const ParticipantTransfertAssistedControl = styled<
  IParticipantTransfertAssistedControlProps,
  IParticipantTransfertAssistedControlStyleProps,
  IParticipantTransfertAssistedControlStyles
>(ParticipantTransfertAssistedControlComponent, getStyles);
