import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IFlightPersonalizedOfferCardSubComponentStyles {}

export interface IFlightPersonalizedOfferCardStyles
  extends IAmigoBaseStyles<IFlightPersonalizedOfferCardSubComponentStyles> {}

export interface IFlightPersonalizedOfferCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightPersonalizedOfferCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightPersonalizedOfferCardStyleProps,
    IFlightPersonalizedOfferCardStyles
  >,
  props?: IFlightPersonalizedOfferCardStyleProps
): [
  IProcessedStyleSet<IFlightPersonalizedOfferCardStyles>,
  IFlightPersonalizedOfferCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightPersonalizedOfferCardStyleProps,
  IFlightPersonalizedOfferCardStyles
> = (
  _props: IFlightPersonalizedOfferCardStyleProps
): IFlightPersonalizedOfferCardStyles => {
  return {
    root: ["amigo-FlightPersonalizedOfferCard", {}],
  };
};
