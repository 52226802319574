import { styled } from "@fluentui/react/lib/Utilities";
import { IMessagesListControlProps } from "./MessagesListControl.types";
import {
  getStyles,
  IMessagesListControlStyleProps,
  IMessagesListControlStyles,
} from "./MessagesListControl.styles";
import { MessagesListControlComponent } from "./MessagesListControl.base";

/**
 * MessagesListControl description
 */
export const MessagesListControl = styled<
  IMessagesListControlProps,
  IMessagesListControlStyleProps,
  IMessagesListControlStyles
>(MessagesListControlComponent, getStyles);
