import { styled } from "@fluentui/react/lib/Utilities";
import { IContactModificationFormProps } from "./ContactModificationForm.types";
import {
  getStyles,
  IContactModificationFormStyleProps,
  IContactModificationFormStyles,
} from "./ContactModificationForm.styles";
import { ContactModificationFormComponent } from "./ContactModificationForm.base";

/**
 * ContactModificationForm description
 */
export const ContactModificationForm = styled<
  IContactModificationFormProps,
  IContactModificationFormStyleProps,
  IContactModificationFormStyles
>(ContactModificationFormComponent, getStyles);
