import { IFontStyles } from "@fluentui/react";
import { IComponentBaseStyleProps, IComponentBaseTokens } from "./Base";

export declare type KeyFontStyles = keyof IFontStyles;
export declare type FontStylePredicate = <
  TToken extends IComponentBaseTokens,
  TStyleProps extends IComponentBaseStyleProps<TToken>
>(
  props: TStyleProps
) => KeyFontStyles;

export const resolveFontStyles = <
  TToken extends IComponentBaseTokens,
  TStyleProps extends IComponentBaseStyleProps<TToken>
>(
  font: KeyFontStyles | FontStylePredicate,
  props: TStyleProps
): KeyFontStyles => {
  return typeof font === "function" ? font(props) : font;
};

export interface IDefaultFontStyles {
  xLarge: FontStylePredicate;
}

const xLarge__: FontStylePredicate = <
  TToken extends IComponentBaseTokens,
  TStyleProps extends IComponentBaseStyleProps<TToken>
>(
  props: TStyleProps
) => {
  return props.mobile
    ? props.tokens?.mobileHeaderTextFontStyle ?? "large"
    : props.tokens?.desktopHeaderTextFontStyle ?? "xLarge";
};

export const ResponsiveFontStyles = {
  xLarge: xLarge__,
};
