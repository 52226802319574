import * as React from "react";
import { getFlightBookingParticipantCardClassNames } from "./FlightBookingParticipantCard.styles";
import {
  IFlightBookingParticipantCardState,
  IFlightBookingParticipantCardProps,
} from "./FlightBookingParticipantCard.types";
import {
  IDropdownOption,
  TooltipHost,
  Icon,
  Stack,
  StackItem,
  Dropdown,
  TextField,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";

export class FlightBookingParticipantCardComponent extends React.Component<
  IFlightBookingParticipantCardProps,
  IFlightBookingParticipantCardState
> {
  render(): JSX.Element {
    const {
      styles,
      item,
      columnWidth,
      recalls,
      arrival,
      onRecallChanged,
      onStatusReasonChanged,
    } = this.props;
    const [classNames, subComponentStyles] =
      getFlightBookingParticipantCardClassNames(styles!, {
        ...this.props,
        ...this.state,
        hasErrors: item.errors.recall,
      });
    const reasons = recalls
      ? recalls.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "").toString(),
            text: x.name,
          };
          return i;
        })
      : [];

    const name: string = `${item.lastName.toUpperCase()}, ${item.firstName}`;
    return (
      <div className={classNames.root}>
        <GridViewCard
          {...this.props}
          styles={subComponentStyles?.gridViewCard}
          title={name}
          columnWidth={columnWidth}
          footerText={undefined}
          footerIconName={undefined}
          selected={item.selected}
          onClick={
            item.selected
              ? this.props.onClick
                ? () => {
                    this.props.onClick && this.props.onClick(item);
                  }
                : undefined
              : this.props.onSelected
              ? () => {
                  this.props.onSelected &&
                    this.props.onSelected(item, !item.selected);
                }
              : undefined
          }
          onSelected={
            this.props.onSelected
              ? () => {
                  this.props.onSelected &&
                    this.props.onSelected(item, !item.selected);
                }
              : undefined
          }
          icons={
            <>
              <TooltipHost content={name}>
                <Icon
                  iconName="UserFollowed"
                  styles={subComponentStyles?.topIcon}
                />
              </TooltipHost>
            </>
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("messages:assisted:newdeparture:code")}
                content={item.code}
              />
            </StackItem>
            <StackItem grow>
              {arrival ? (
                <LabelField
                  {...this.props}
                  styles={undefined}
                  label={i18n.t(
                    "transports:flights:participants:estimatedarrival"
                  )}
                  content={item.estimatedArrival}
                />
              ) : (
                <LabelField
                  {...this.props}
                  styles={undefined}
                  label={i18n.t(
                    "transports:flights:participants:estimateddeparture"
                  )}
                  content={item.estimatedDeparture}
                />
              )}
            </StackItem>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("transports:flights:participants:passport")}
                content={
                  item.passport ?? (
                    <span style={{ color: this.props.theme.palette.red }}>
                      {
                        "* Le numéro de passeport de ce participant n'est pas disponible."
                      }
                    </span>
                  )
                }
              />
            </StackItem>
            {!arrival && item.selected && (
              <>
                <StackItem grow>
                  <Stack grow>
                    {/*
                    <Dropdown
                      styles={{ root: { maxWidth: "260px" } }}
                      options={reasons}
                      errorMessage={item.errors.leaveReason}
                      selectedKey={item.leaveReason?.toString()}
                      label={i18n.t("transports:flights:departurereason")}
                      placeholder={i18n.t(
                        "transports:flights:departurereason:select"
                      )}
                      onChange={(
                        ev: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption
                      ) => {
                        ev?.preventDefault &&
                          typeof ev?.preventDefault === "function" &&
                          ev?.preventDefault();
                        ev?.stopPropagation &&
                          typeof ev?.stopPropagation === "function" &&
                          ev?.stopPropagation();
                        option !== undefined &&
                          onReasonChanged(option.key.toString());
                      }}
                    />
                    */}
                    <Dropdown
                      styles={{ root: { maxWidth: "260px" } }}
                      options={reasons}
                      errorMessage={item.errors.recall}
                      selectedKey={item.recall?.toString()}
                      label={i18n.t("messages:assisted:newdeparture:recall")}
                      placeholder={i18n.t(
                        "messages:assisted:newdeparture:select"
                      )}
                      onChange={(
                        ev: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption
                      ) => {
                        ev?.preventDefault &&
                          typeof ev?.preventDefault === "function" &&
                          ev?.preventDefault();
                        ev?.stopPropagation &&
                          typeof ev?.stopPropagation === "function" &&
                          ev?.stopPropagation();
                        option &&
                          onRecallChanged(
                            parseInt(option.key.toString()),
                            option.text
                          );
                      }}
                    />
                  </Stack>
                </StackItem>
                <StackItem grow>
                  <TextField
                    styles={{ root: { maxWidth: "260px" } }}
                    label={i18n.t(
                      "messages:assisted:newdeparture:reasonrecall"
                    )}
                    value={item.reason ?? ""}
                    onChange={(
                      ev: React.FormEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                      newValue?: string
                    ) => {
                      (ev.target as any).value = newValue ?? "";
                      onStatusReasonChanged(newValue ?? "");
                      this.forceUpdate();
                    }}
                  />
                </StackItem>
              </>
            )}
          </Stack>
        </GridViewCard>
      </div>
    );
  }
}
