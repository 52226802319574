import * as React from "react";
import { getParticipantHistoryControlClassNames } from "./ParticipantHistoryControl.styles";
import {
  IParticipantHistoryControlState,
  IParticipantHistoryControlProps,
} from "./ParticipantHistoryControl.types";
import { GridView } from "src/components/GridView";
import { SelectionMode, Selection, Stack } from "@fluentui/react";
import {
  IGridViewOptions,
  GridViewMode,
} from "src/components/GridView/GridView.types";
import { IParticipantHistory } from "src/models/Participants/IParticipantHistory";
import { ParticipantHistoryCard } from "../ParticipantHistoryCard";
import PreferencesService from "src/services/PreferencesService";
import ParticipantHistoryService from "src/services/Participants/ParticipantHistoryService";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import { objectId } from "src/models/IObjectReference";
import { Card } from "@fluentui/react-cards";

export class ParticipantHistoryControlComponent extends React.Component<
  IParticipantHistoryControlProps,
  IParticipantHistoryControlState
> {
  constructor(props: IParticipantHistoryControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "participanthistory",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "name",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "participanthistory",
        false,
        this.defaultFilters
      ),
      items: [],
    };
    ParticipantHistoryService.getParticipantHistory(
      objectId(props.enterprise),
      props.participantId,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private defaultFilters = () => {
    return [];
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantHistoryControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { items, options } = this.state;
    return (
      <div className={classNames.root}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : mobile || tablet
                    ? "10px"
                    : "20px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={undefined}
                options={options}
                selectionMode={SelectionMode.none}
                onOptionsChanged={(opt: IGridViewOptions) =>
                  this.setState({ options: opt })
                }
                onRenderTile={(
                  item: IParticipantHistory,
                  columnWidth: number,
                  selection: Selection,
                  _callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <ParticipantHistoryCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                    />
                  );
                }}
                columns={[
                  {
                    key: "name",
                    name: "participants:history:title",
                    minWidth: 150,
                  },
                  {
                    key: "recall",
                    name: "participants:history:recall",
                    minWidth: 190,
                  },
                  {
                    key: "endParticipation",
                    name: "participants:history:endParticipation",
                    minWidth: 190,
                  },
                  {
                    key: "arrivalDate",
                    name: "participants:history:arrivaldate",
                    minWidth: 120,
                  },
                  {
                    key: "departureDate",
                    name: "participants:history:departuredate",
                    minWidth: 120,
                  },
                ]}
                onDataPaging={() => {}}
                items={items}
              />
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }
}
