import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantAddressSummaryProps } from "./ParticipantAddressSummary.types";
import {
  getStyles,
  IParticipantAddressSummaryStyleProps,
  IParticipantAddressSummaryStyles,
} from "./ParticipantAddressSummary.styles";
import { ParticipantAddressSummaryComponent } from "./ParticipantAddressSummary.base";

/**
 * ParticipantAddressSummary description
 */
export const ParticipantAddressSummary = styled<
  IParticipantAddressSummaryProps,
  IParticipantAddressSummaryStyleProps,
  IParticipantAddressSummaryStyles
>(ParticipantAddressSummaryComponent, getStyles);
