import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IEnterpriseContactItemSubComponentStyles {}

export interface IEnterpriseContactItemStyles
  extends IAmigoBaseStyles<IEnterpriseContactItemSubComponentStyles> {}

export interface IEnterpriseContactItemStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseContactItemClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseContactItemStyleProps,
    IEnterpriseContactItemStyles
  >,
  props?: IEnterpriseContactItemStyleProps
): [
  IProcessedStyleSet<IEnterpriseContactItemStyles>,
  IEnterpriseContactItemSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseContactItemStyleProps,
  IEnterpriseContactItemStyles
> = (
  _props: IEnterpriseContactItemStyleProps
): IEnterpriseContactItemStyles => {
  return {};
};
