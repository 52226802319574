import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightBookingPassengersPageSubComponentStyles {}

export interface IFlightBookingPassengersPageStyles
  extends IAmigoBaseStyles<IFlightBookingPassengersPageSubComponentStyles> {}

export interface IFlightBookingPassengersPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingPassengersPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingPassengersPageStyleProps,
    IFlightBookingPassengersPageStyles
  >,
  props?: IFlightBookingPassengersPageStyleProps
): [
  IProcessedStyleSet<IFlightBookingPassengersPageStyles>,
  IFlightBookingPassengersPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingPassengersPageStyleProps,
  IFlightBookingPassengersPageStyles
> = (
  _props: IFlightBookingPassengersPageStyleProps
): IFlightBookingPassengersPageStyles => {
  return {
    root: ["amigo-FlightBookingPassengersPage", {}],
  };
};
