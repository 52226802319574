import { styled } from "@fluentui/react/lib/Utilities";
import { IMasterPageProps } from "./MasterPage.types";
import {
  getStyles,
  IMasterPageStyleProps,
  IMasterPageStyles,
} from "./MasterPage.styles";
import { MasterPageComponent } from "./MasterPage.base";

export const MasterPage = styled<
  IMasterPageProps,
  IMasterPageStyleProps,
  IMasterPageStyles
>(MasterPageComponent, getStyles);
