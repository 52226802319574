import { styled } from "@fluentui/react/lib/Utilities";
import { ITravelWarningProps } from "./TravelWarning.types";
import {
  getStyles,
  ITravelWarningStyleProps,
  ITravelWarningStyles,
} from "./TravelWarning.styles";
import { TravelWarningComponent } from "./TravelWarning.base";

/**
 * TravelWarning
 */
export const TravelWarning = styled<
  ITravelWarningProps,
  ITravelWarningStyleProps,
  ITravelWarningStyles
>(TravelWarningComponent, getStyles);
