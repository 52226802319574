import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentSummaryControlProps } from "./DocumentSummaryControl.types";
import {
  getStyles,
  IDocumentSummaryControlStyleProps,
  IDocumentSummaryControlStyles,
} from "./DocumentSummaryControl.styles";
import { DocumentSummaryControlComponent } from "./DocumentSummaryControl.base";

/**
 * DocumentSummaryControl description
 */
export const DocumentSummaryControl = styled<
  IDocumentSummaryControlProps,
  IDocumentSummaryControlStyleProps,
  IDocumentSummaryControlStyles
>(DocumentSummaryControlComponent, getStyles);
