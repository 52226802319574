import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IAlertSpotlightSubComponentStyles {}

export interface IAlertSpotlightStyles
  extends IAmigoBaseStyles<IAlertSpotlightSubComponentStyles> {}

export interface IAlertSpotlightStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAlertSpotlightClassNames = (
  styles?: IStyleFunctionOrObject<
    IAlertSpotlightStyleProps,
    IAlertSpotlightStyles
  >,
  props?: IAlertSpotlightStyleProps
): [
  IProcessedStyleSet<IAlertSpotlightStyles>,
  IAlertSpotlightSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAlertSpotlightStyleProps,
  IAlertSpotlightStyles
> = (_props: IAlertSpotlightStyleProps): IAlertSpotlightStyles => {
  return {
    root: [
      "amigo-AlertSpotlight",
      {
        maxWidth: "100%",
        margin: "7px",
      },
    ],
  };
};
