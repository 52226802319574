import { ISchemaObject } from "src/utils/validator";
import { IObjectReference } from "../IObjectReference";

export interface IArrivalRequestParticipant {
  id: number;
  key: string;
  workerId: number | undefined;
  name: string;
  code: string;
  date: string;
  recall?: number;
  recallName?: string;
  reason?: string;
  city?: number;
  cities: IObjectReference[];
  errors: any;
  selected: boolean;
}

export const ArrivalRequestParticipantSchema: ISchemaObject = {
  name: "departurerequestparticipant",
  plural: "departurerequestparticipants",
  label: "ArrivalRequestParticipant",
  fields: {
    /*
    recall: {
      name: "recall",
      label: i18n.t("messages:assisted:newdeparture:recall"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:newdeparture:recall:error"),
      type: "number",
    },
    */
  },
};
