import { Selection, Text } from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import { CreditCardIcon } from "src/components/CreditCardIcon";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { IPaymentMethod } from "src/models/Enterprises/IPaymentMethod";
import { objectId } from "src/models/IObjectReference";
import BrowserService from "src/services/BrowserService";
import PaymentMethodsService from "src/services/Enterprises/PaymentMethodsService";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import { NewPaymentMethodControl } from "../NewPaymentMethodControl";
import { PaymentMethodCard } from "../PaymentMethodCard";
import { PaymentMethodDeleteMessage } from "../PaymentMethodDeleteMessage";
import { getPaymentMethodsControlClassNames } from "./PaymentMethodsControl.styles";
import {
  IPaymentMethodsControlProps,
  IPaymentMethodsControlState,
} from "./PaymentMethodsControl.types";
import { Dialog } from '@fluentui/react';

export class PaymentMethodsControlComponent extends React.Component<
  IPaymentMethodsControlProps,
  IPaymentMethodsControlState
> {
  constructor(props: IPaymentMethodsControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "paymentmethods",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "paymentmethods",
        false,
        () => []
      ),
      items: [],
      downloadProgress: "0",
      new: false,
      showMonerisError: false,
    };
    PaymentMethodsService.getPaymentMethods(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getPaymentMethodsControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const expiry: string =
      this.state.selectedMethod === undefined
        ? ""
        : `${
            this.state.selectedMethod.expirationMonth < 10
              ? "0" + this.state.selectedMethod.expirationMonth.toString()
              : this.state.selectedMethod.expirationMonth.toString()
          }/${this.state.selectedMethod.expirationYear}`;
    return this.state.new ? (
      <NewPaymentMethodControl
        {...this.props}
        onDismiss={() => {
          PaymentMethodsService.getPaymentMethods(
            objectId(this.props.enterprise),
            this.state.options.sortBy,
            this.state.options.desc,
            this.state.filters,
            undefined
          ).then((x) => {
            this.setState({
              items: x,
              new: false,
            });
          }).catch((error) => {
            if (error.message === "FatalErrorFetchPaymentMethods")
              this.setState({
                showMonerisError: true
              });
          });
        }}
      />
    ) : (
      <div className={`${classNames.root} ms-PaymentMethodsControl`}>
        
        <Dialog
          hidden={!this.state.showMonerisError}
          dialogContentProps={{
            title: i18n.t("dialog:error:title"),
            subText: i18n.t("dialog:error:moneris"),
          }}
          modalProps={{
            isBlocking: true,
            styles: { main: { maxWidth: 450 } },
          }}/>

        <GridView
          {...this.props}
          styles={undefined}
          onItemInvoked={(item: IPaymentMethod) => {
            this.setState({
              selectedMethod: item,
            });
          }}
          onRenderTile={(
            item: IPaymentMethod,
            columnWidth: number,
            selection: Selection,
            callback: (sel: Selection) => void
          ): JSX.Element => {
            return (
              <PaymentMethodCard
                {...this.props}
                styles={undefined}
                item={item}
                columnWidth={columnWidth}
                selection={selection}
                onSelected={(item: IPaymentMethod) => {
                  ensureSelection(selection, item);
                  selection.setKeySelected(
                    item.key,
                    selection.getSelection().filter((x) => x.key === item.key)
                      .length === 0,
                    false
                  );
                  callback(selection);
                }}
                onClick={(item: IPaymentMethod) => {
                  this.setState({ selectedMethod: item });
                }}
              />
            );
          }}
          onSort={(fieldName: string, desc: boolean) => {
            PaymentMethodsService.init();
            PaymentMethodsService.getPaymentMethods(
              objectId(this.props.enterprise),
              fieldName,
              desc,
              this.state.filters,
              false
            ).then((x) => {
              this.setState({
                options: PreferencesService.asCommittedOptions(
                  GridViewOptionsBuilder.getOptions(
                    this.state.options.mode,
                    fieldName,
                    desc,
                    true
                  ),
                  "paymentmethods"
                ),
                items: x,
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          fullTextEnabled={false}
          options={this.state.options}
          onOptionsChanged={(options: IGridViewOptions) => {
            this.setState({
              options: PreferencesService.asCommittedOptions(
                options,
                "paymentmethods"
              ),
            });
          }}
          commands={[
            {
              key: "new",
              name: i18n.t("enterprises:paymentmethods:new"),
              icon: "Add",
              confirmMessage: (_items: IPaymentMethod[]) => {
                return "";
              },
              confirmTitle: undefined,
              onClick: () => {
                this.setState({ new: true });
              },
            },
            {
              key: "delete",
              name: i18n.t("enterprises:paymentmethods:delete"),
              icon: "Delete",
              selectionRequired: true,
              confirmMessage: (items: IPaymentMethod[]) => (
                <PaymentMethodDeleteMessage
                  {...this.props}
                  items={items}
                  multiple={items.length > 1}
                  lastcard={this.state.items.length - items.length === 0}
                />
              ),
              confirmTitle: i18n.t("enterprises:paymentmethods:deletetitle"),
              onClick: (
                payments: IPaymentMethod[],
                onCompleted: () => void
              ) => {
                PaymentMethodsService.deletePaymentMethods(
                  objectId(this.props.enterprise),
                  payments
                )
                  .then((_x) => {
                    PaymentMethodsService.init();
                    PaymentMethodsService.getPaymentMethods(
                      objectId(this.props.enterprise),
                      this.state.options.sortBy,
                      this.state.options.desc,
                      this.state.filters,
                      undefined
                    )
                      .then((x) => {
                        this.setState({
                          items: x,
                          options: GridViewOptionsBuilder.getFetched(this),
                        });
                        onCompleted();
                      })
                      .catch((_x) => {
                        /* TODO: Annonce error to client */
                        onCompleted();
                      });
                  })
                  .catch((_x) => {
                    /* TODO: Annonce error to client */
                    onCompleted();
                  });
              },
            },
          ]}
          columns={[
            {
              key: "cardIcon",
              name: "",
              iconName: "PaymentCard",
              minWidth: 48,
              maxWidth: 48,
              onRenderCell: (item: IPaymentMethod) => {
                return <CreditCardIcon card={item.cardType} />;
              },
            },
            {
              key: "cardType",
              name: "enterprises:paymentmethods:type",
              minWidth: 90,
              maxWidth: 90,
            },
            {
              key: "card",
              name: "enterprises:paymentmethods:card",
              minWidth: undefined,
            },
            {
              key: "name",
              name: "enterprises:paymentmethods:name",
              minWidth: undefined,
            },
            {
              key: "expirationMonth",
              name: "enterprises:paymentmethods:expiration",
              minWidth: 120,
              onRenderCell: (item: IPaymentMethod) => (
                <Text>{`${
                  item.expirationMonth < 10
                    ? "0" + item.expirationMonth.toString()
                    : item.expirationMonth.toString()
                }/${item.expirationYear}`}</Text>
              ),
            },
            {
              key: "createdOn",
              name: "enterprises:paymentmethods:createdon",
              minWidth: 120,
              visible: !BrowserService.isTabletOrMobile(),
            },
            {
              key: "createdBy",
              name: "enterprises:paymentmethods:createdby",
              minWidth: 150,
              visible: !BrowserService.isTabletOrMobile(),
            },
          ]}
          onDataPaging={() => {
            PaymentMethodsService.getPaymentMethods(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              this.state.filters,
              true
            ).then((x) => {
              this.setState({
                items: x,
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
          }}
          onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
            PaymentMethodsService.init();
            PaymentMethodsService.getPaymentMethods(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              filters,
              undefined
            ).then((x) => {
              callback();
              this.setState({
                items: x,
                filters: PreferencesService.asCommittedFilters(
                  filters,
                  "paymentmethods"
                ),
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          onQueryFilters={(reset: boolean) => {
            return new Promise<IQueryFilter[]>((resolve) => {
              resolve(
                PreferencesService.getFiltersOrDefault(
                  "paymentmethods",
                  reset,
                  () => [
                    {
                      name: "enterprises:paymentmethods:name",
                      fieldName: "name",
                      type: "text",
                      value: "",
                    },
                  ]
                )
              );
            });
          }}
          items={this.state.items}
          entityPanelProps={{
            ...this.props,
            styles: undefined,
            "data-automation-id": "nvx:enterprises:paymentmethods",
            isOpen: this.state.selectedMethod !== undefined,
            elementName: i18n.t("enterprises:paymentmethods:element"),
            title: this.state.selectedMethod?.cardType,
            onDismiss: () => {
              this.setState({
                selectedMethod: undefined,
              });
            },
            headerIconName: "WorkItemEnterpriseDocument",
            children:
              this.state.selectedMethod === undefined ? (
                <React.Fragment />
              ) : (
                <div>
                  <div style={{ height: "40px" }}></div>
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:name")}
                    content={this.state.selectedMethod.name}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:type")}
                    content={this.state.selectedMethod.cardType}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:card")}
                    content={this.state.selectedMethod.card}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:expiration")}
                    content={expiry}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:createdby")}
                    content={this.state.selectedMethod.createdBy}
                  />
                  <LabelField
                    {...this.props}
                    styles={subComponentStyles?.label}
                    label={i18n.t("enterprises:paymentmethods:createdon")}
                    content={this.state.selectedMethod.createdOn}
                  />
                  <div
                    style={{
                      marginTop: "20px",
                      marginBottom: "0",
                      width: "290px",
                    }}
                  >
                    <Cards
                      {...i18n.CreditCardStrings()}
                      cvc={this.state.selectedMethod.cvc ?? ""}
                      expiry={expiry}
                      focused={"number"}
                      name={this.state.selectedMethod.name ?? ""}
                      number={this.state.selectedMethod.card ?? ""}
                      preview={true}
                    />
                  </div>
                </div>
              ),
          }}
        />
        <div style={{ height: "40px" }}></div>
      </div>
    );
  }
}
