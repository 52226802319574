import * as React from "react";
import { GridView } from "src/components/GridView/GridView";
import { GridViewMode } from "src/components/GridView/GridView.types";
import GridViewOptionsBuilder from "src/components/GridView/GridViewOptionsBuilder";
import { FlightPersonalizedOfferCard } from "../FlightPersonalizedOfferCard/FlightPersonalizedOfferCard";
import { getFlightPersonalizedOffersClassNames } from "./FlightPersonalizedOffers.styles";
import {
  IFlightPersonalizedOffersState,
  IFlightPersonalizedOffersProps,
} from "./FlightPersonalizedOffers.types";

export class FlightPersonalizedOffersComponent extends React.Component<
  IFlightPersonalizedOffersProps,
  IFlightPersonalizedOffersState
> {
  constructor(props: IFlightPersonalizedOffersProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, items, onSelected } = this.props;
    const [classNames /* , subComponentStyles */] =
      getFlightPersonalizedOffersClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <GridView
          {...this.props}
          styles={undefined}
          items={items}
          columns={[]}
          options={GridViewOptionsBuilder.getOptions(
            GridViewMode.tiles,
            "",
            false,
            true
          )}
          onDataPaging={() => {}}
          onRenderTile={(item, columnWidth: number) => (
            <FlightPersonalizedOfferCard
              {...this.props}
              item={item}
              columnWidth={columnWidth}
              onSelected={(item) => onSelected(item)}
            />
          )}
        ></GridView>
      </div>
    );
  }
}
