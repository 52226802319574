import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface IFooterSubComponentStyles {}

export interface IFooterStyles
  extends IComponentBaseStyles<IFooterSubComponentStyles> {
  footerContainer?: IStyle;
  environment?: IStyle;
}

export interface IFooterStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getFooterClassNames = (
  styles?: IStyleFunctionOrObject<IFooterStyleProps, IFooterStyles>,
  props?: IFooterStyleProps
): [
  IProcessedStyleSet<IFooterStyles>,
  IFooterSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<IFooterStyleProps, IFooterStyles> = (
  props: IFooterStyleProps
): IFooterStyles => {
  return {
    root: [
      "ms-AppFooter",
      {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
      props.tokens.darkBackgroundStyle,
    ],
    footerContainer: [
      "ms-AppFooter-Container",
      {
        textAlign: "center",
        color: props.theme.palette.black,
      },
    ],
    environment: [
      "ms-AppFooter-Environment",
      {
        margin: "2px",
        color: props.theme.palette.black,
      },
    ],
  };
};
