import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";

export interface IEnterpriseAlertLevelSubComponentStyles {
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
}

export interface IEnterpriseAlertLevelStyles
  extends IAmigoBaseStyles<IEnterpriseAlertLevelSubComponentStyles> {
  inner?: IStyle;
}

export interface IEnterpriseAlertLevelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseAlertLevelClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseAlertLevelStyleProps,
    IEnterpriseAlertLevelStyles
  >,
  props?: IEnterpriseAlertLevelStyleProps
): [
  IProcessedStyleSet<IEnterpriseAlertLevelStyles>,
  IEnterpriseAlertLevelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseAlertLevelStyleProps,
  IEnterpriseAlertLevelStyles
> = (_props: IEnterpriseAlertLevelStyleProps): IEnterpriseAlertLevelStyles => {
  return {
    root: {
      width: "100%",
      padding: "10px",
    },
    inner: {
      paddingLeft: "20px",
      paddingRight: "20px",
    },
  };
};
