import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IRequestParticipantEndParticipationStyleProps,
  IRequestParticipantEndParticipationStyles,
} from "../RequestParticipantEndParticipation";
import {
  IRequestParticipantFormStyleProps,
  IRequestParticipantFormStyles,
} from "../RequestParticipantForm";

export interface IRequestParticipantItemSubComponentStyles {
  participantForm?: IStyleFunctionOrObject<
    IRequestParticipantFormStyleProps,
    IRequestParticipantFormStyles
  >;
  endParticipantion?: IStyleFunctionOrObject<
    IRequestParticipantEndParticipationStyleProps,
    IRequestParticipantEndParticipationStyles
  >;
}

export interface IRequestParticipantItemStyles
  extends IAmigoBaseStyles<IRequestParticipantItemSubComponentStyles> {
  msGridRow?: IStyle;
}

export interface IRequestParticipantItemStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantItemClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantItemStyleProps,
    IRequestParticipantItemStyles
  >,
  props?: IRequestParticipantItemStyleProps
): [
  IProcessedStyleSet<IRequestParticipantItemStyles>,
  IRequestParticipantItemSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantItemStyleProps,
  IRequestParticipantItemStyles
> = (
  props: IRequestParticipantItemStyleProps
): IRequestParticipantItemStyles => {
  return {
    msGridRow: props.tokens.msGridRow,
  };
};
