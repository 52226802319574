import {
  IconButton,
  MessageBar,
  MessageBarType,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import i18n from "src/services/i18n";
import { getAlertSpotlightClassNames } from "./AlertSpotlight.styles";
import {
  IAlertSpotlightProps,
  IAlertSpotlightState,
} from "./AlertSpotlight.types";

const dismissAlert = (id: number) =>
  localStorage.setItem("alertdismissed", id.toString());

const isDismiss = (id: number): boolean => {
  const value = localStorage.getItem("alertdismissed");
  return value === undefined || value == null ? false : value === id.toString();
};

export class AlertSpotlightComponent extends React.Component<
  IAlertSpotlightProps,
  IAlertSpotlightState
> {
  constructor(props: IAlertSpotlightProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, mobile, spotlight, onOpen, onDismiss } = this.props;
    const [classNames] = getAlertSpotlightClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <>
        {!isDismiss(spotlight.id) && (
          <Card
            onClick={(ev) => {
              ev?.preventDefault();
              ev?.stopPropagation();
              onOpen(spotlight);
            }}
            className={classNames.root}
            styles={{
              root: {
                maxWidth: "99%",
                cursor: "pointer",
                width: "99%",
                maxHeight: "340px",
                backgroundColor: "#fff",
                padding: mobile ? "10px" : "25px",
              },
            }}
          >
            <Card.Item>
              <Stack
                grow
                tokens={{ childrenGap: mobile ? 10 : 20 }}
                styles={{
                  root: {
                    maxHeight: "340px",
                    overflow: "auto",
                  },
                }}
              >
                <StackItem grow>
                  <Stack grow horizontal>
                    <StackItem align="start" grow>
                      <Text variant="xLarge">{spotlight.type}</Text>
                    </StackItem>
                    <StackItem align="end">
                      <IconButton
                        iconProps={{ iconName: "Cancel" }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          dismissAlert(spotlight.id);
                          onDismiss();
                        }}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>
                <MessageBar messageBarType={MessageBarType.success}>
                  {i18n.t("alerts:spotlight:intro")}
                </MessageBar>
                <Text variant="large">{spotlight.createdOn}</Text>
                <Text variant="mediumPlus">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: spotlight.comments.replace(/\r\n/g, "<br />"),
                    }}
                  ></span>
                </Text>
              </Stack>
            </Card.Item>
          </Card>
        )}
      </>
    );
  }
}
