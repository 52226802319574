import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IDossierImmigrationCardStyleProps,
  IDossierImmigrationCardStyles,
} from "../DossierImmigrationCard";

export interface IDossierImmigrationControlSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IDossierImmigrationCardStyleProps,
    IDossierImmigrationCardStyles
  >;
}

export interface IDossierImmigrationControlStyles
  extends IAmigoBaseStyles<IDossierImmigrationControlSubComponentStyles> {}

export interface IDossierImmigrationControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getImmigrationControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationControlStyleProps,
    IDossierImmigrationControlStyles
  >,
  props?: IDossierImmigrationControlStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationControlStyles>,
  IDossierImmigrationControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationControlStyleProps,
  IDossierImmigrationControlStyles
> = (
  _props: IDossierImmigrationControlStyleProps
): IDossierImmigrationControlStyles => {
  return {};
};
