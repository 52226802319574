import axios from "axios";
import {
  CommunicationMethod,
  IProfileCommunicationPreferences,
} from "src/models/IProfileCommunicationPreferences";
import AuthService from "./AuthService";

export class ProfileService {
  public static async updateCellphone(cellphone: string): Promise<boolean> {
    const x = await axios.post(
      `api/profile`,
      {
        cellphone: cellphone,
      },
      AuthService.getAuthHeader()
    );
    return x.status === 200;
  }

  public static async getPreferences(): Promise<IProfileCommunicationPreferences> {
    const x = await axios.get(
      `api/profile/communications/preferences`,
      AuthService.getAuthHeader()
    );
    return x.status === 200
      ? x.data
      : {
          alerts: CommunicationMethod.Email,
          chat: CommunicationMethod.None,
        };
  }

  public static async updatePreferences(
    preferences: IProfileCommunicationPreferences
  ): Promise<boolean> {
    const x = await axios.post(
      `api/profile/communications/preferences`,
      preferences,
      AuthService.getAuthHeader()
    );
    return x.status === 200;
  }
}
