import { Icon } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getFlightBookingParticipantCardClassNames } from "./FlightBookingParticipantCard.styles";
import {
  IFlightBookingParticipantCardProps,
  IFlightBookingParticipantCardState,
} from "./FlightBookingParticipantCard.types";

export class FlightBookingParticipantCardComponent extends React.Component<
  IFlightBookingParticipantCardProps,
  IFlightBookingParticipantCardState
> {
  render(): JSX.Element {
    const { styles, model, mobile, tablet, item, arrival, index } = this.props;
    const [classNames, subComponentStyles] =
      getFlightBookingParticipantCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const gender: string = item.gender
      ? item.gender === "F"
        ? "Female"
        : "Male"
      : "Male";

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <LabelField
              {...this.props}
              styles={undefined}
              label={"Firstname"}
              content={item.firstName}
            />
            <LabelField
              {...this.props}
              styles={undefined}
              label={"Lastname"}
              content={item.lastName.toUpperCase()}
            />
          </>
        }
        embedded={true}
        columnWidth={mobile ? 300 : tablet ? 450 : 500}
        footerText={`Passenger ${index + 1}`}
        footerIconName={"IDBadge"}
        selected={false}
        onClick={undefined}
        onSelected={undefined}
        icons={
          <>
            <Icon className={classNames.root} iconName={"AirTickets"} />
            <Icon iconName={"Arrivals"} />
          </>
        }
      >
        <LabelField
          {...this.props}
          styles={undefined}
          label={"Passport Number"}
          content={item.passport}
        />
        <LabelField
          {...this.props}
          styles={undefined}
          label={"Participant Code"}
          content={item.code}
        />
        <LabelField
          {...this.props}
          styles={undefined}
          label={"Gender"}
          content={gender}
        />
        <LabelField
          {...this.props}
          styles={undefined}
          label={"Birthdate"}
          content={item.dateOfBirth ? item.dateOfBirth : "Unknown"}
        />
        {arrival ? (
          <LabelField
            {...this.props}
            styles={undefined}
            label={i18n.t("transports:flights:participants:estimatedarrival")}
            content={item.estimatedArrival}
          />
        ) : (
          <LabelField
            {...this.props}
            styles={undefined}
            label={i18n.t("transports:flights:participants:estimateddeparture")}
            content={item.estimatedDeparture}
          />
        )}
        {!arrival && (
          <>
            <LabelField
              {...this.props}
              styles={undefined}
              label={`${i18n.t("participants:recall")}`}
              content={
                item.recall === undefined
                  ? ""
                  : (model.recalls as any[]).filter(
                      (x) => x.id === item.recall
                    )[0]?.name
              }
            />
            <LabelField
              {...this.props}
              styles={undefined}
              label={`${i18n.t("participants:reason")}`}
              content={
                item.reason === undefined || item.reason.length === 0
                  ? "_"
                  : item.reason
              }
            />
          </>
        )}
      </GridViewCard>
    );
  }
}
