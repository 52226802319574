import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseHeaderProps } from "./EnterpriseHeader.types";
import {
  getStyles,
  IEnterpriseHeaderStyleProps,
  IEnterpriseHeaderStyles,
} from "./EnterpriseHeader.styles";
import { EnterpriseHeaderComponent } from "./EnterpriseHeader.base";

/**
 * EnterpriseHeader description
 */
export const EnterpriseHeader = styled<
  IEnterpriseHeaderProps,
  IEnterpriseHeaderStyleProps,
  IEnterpriseHeaderStyles
>(EnterpriseHeaderComponent, getStyles);
