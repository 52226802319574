import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IFlightBookingParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IIconStyles;
}

export interface IFlightBookingParticipantCardStyles
  extends IAmigoBaseStyles<IFlightBookingParticipantCardSubComponentStyles> {}

export interface IFlightBookingParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  hasErrors: boolean;
}

export const getFlightBookingParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingParticipantCardStyleProps,
    IFlightBookingParticipantCardStyles
  >,
  props?: IFlightBookingParticipantCardStyleProps
): [
  IProcessedStyleSet<IFlightBookingParticipantCardStyles>,
  IFlightBookingParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingParticipantCardStyleProps,
  IFlightBookingParticipantCardStyles
> = (
  props: IFlightBookingParticipantCardStyleProps
): IFlightBookingParticipantCardStyles => {
  return {
    root: ["amigo-FlightBookingParticipantCard", {}],
    subComponentStyles: {
      topIcon: props.hasErrors
        ? {
            root: {
              color: props.theme.palette.redDark,
            },
          }
        : undefined,
      gridViewCard: {
        root: {
          width: "95%",
          maxWidth: "100%",
          selectors: {
            [MediaQueries.mobile]: {
              width: "95%",
            },
            [MediaQueries.small]: {
              width: "97%",
            },
          },
        },
      },
    },
  };
};
