import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDateRangePickerSubComponentStyles {}

export interface IDateRangePickerStyles
  extends IAmigoBaseStyles<IDateRangePickerSubComponentStyles> {}

export interface IDateRangePickerStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDateRangePickerClassNames = (
  styles?: IStyleFunctionOrObject<
    IDateRangePickerStyleProps,
    IDateRangePickerStyles
  >,
  props?: IDateRangePickerStyleProps
): [
  IProcessedStyleSet<IDateRangePickerStyles>,
  IDateRangePickerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDateRangePickerStyleProps,
  IDateRangePickerStyles
> = (_props: IDateRangePickerStyleProps): IDateRangePickerStyles => {
  return {
    root: ["amigo-DateRangePicker", {}],
  };
};
