import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextFieldStyleProps,
  ITextFieldStyles,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";

export interface IParticipantAddressPanelSubComponentStyles {
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
  textbox?: IStyleFunctionOrObject<ITextFieldStyleProps, ITextFieldStyles>;
}

export interface IParticipantAddressPanelStyles
  extends IAmigoBaseStyles<IParticipantAddressPanelSubComponentStyles> {}

export interface IParticipantAddressPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantAddressPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantAddressPanelStyleProps,
    IParticipantAddressPanelStyles
  >,
  props?: IParticipantAddressPanelStyleProps
): [
  IProcessedStyleSet<IParticipantAddressPanelStyles>,
  IParticipantAddressPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantAddressPanelStyleProps,
  IParticipantAddressPanelStyles
> = (
  _props: IParticipantAddressPanelStyleProps
): IParticipantAddressPanelStyles => {
  return {};
};
