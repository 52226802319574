import { Icon, IconButton, Stack, StackItem } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { FlightOfferDetails } from "../FlightOfferDetails";
import { getFlightOfferContainerClassNames } from "./FlightOfferContainer.styles";
import {
  IFlightOfferContainerProps,
  IFlightOfferContainerState,
} from "./FlightOfferContainer.types";

export class FlightOfferContainerComponent extends React.Component<
  IFlightOfferContainerProps,
  IFlightOfferContainerState
> {
  constructor(props: IFlightOfferContainerProps) {
    super(props);
    this.state = {
      opened: false,
    };
  }

  private adjusted = (adj: number | undefined): string => {
    return adj ? `+${adj}` : "";
  };

  render(): JSX.Element {
    const {
      styles,
      booking,
      criteria,
      offerIndex,
      offer,
      serviceIndex,
      mobile,
    } = this.props;
    const [classNames] = getFlightOfferContainerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { opened } = this.state;
    const { carrier, operating } = offer;
    return (
      <div className={classNames.root}>
        <Card
          styles={{
            root: {
              backgroundColor: this.props.theme.palette.white,
              width: "100%",
              textAlign: "left",
              maxWidth: "100%",
              padding: "8px",
            },
          }}
        >
          <Card.Section grow>
            <Stack
              onClick={
                opened
                  ? undefined
                  : () => {
                      this.setState({
                        opened: true,
                      });
                    }
              }
              styles={
                opened
                  ? undefined
                  : {
                      root: {
                        cursor: "pointer",
                      },
                    }
              }
              horizontal={!mobile}
              tokens={{ childrenGap: mobile ? 2 : 20 }}
              horizontalAlign={mobile ? undefined : "space-evenly"}
              grow
            >
              <StackItem
                align={"center"}
                styles={mobile ? { root: { width: "100%" } } : undefined}
              >
                {carrier === "" ||
                  (carrier === "multiple" && (
                    <Icon
                      iconName={"AirplaneSolid"}
                      style={{ fontSize: "32px" }}
                    />
                  ))}
                {carrier !== "" && carrier !== "multiple" && (
                  <img
                    src={TransportFlightSearchService.getCarrierImage(carrier)}
                    style={{ width: "32px", height: "32px" }}
                  />
                )}
              </StackItem>
              <StackItem
                grow
                styles={{ root: { width: mobile ? "100%" : "40%" } }}
              >
                <LabelField
                  {...this.props}
                  styles={{ root: { marginTop: "3px" } }}
                  label={`${offer.departureDate} ${offer.departure.substr(
                    0,
                    5
                  )} - ${offer.arrival.substr(0, 5)}${this.adjusted(
                    offer.adj
                  )}`}
                  content={
                    carrier !== operating
                      ? `${
                          carrier === "multiple"
                            ? this.props.offer.carriers
                                .map((y) =>
                                  TransportFlightSearchService.getCarrierName(y)
                                )
                                .join(",")
                            : TransportFlightSearchService.getCarrierName(
                                carrier
                              )
                        } ${i18n.t(
                          "transports:flights:operatedby"
                        )} ${TransportFlightSearchService.getCarrierName(
                          operating
                        )}`
                      : TransportFlightSearchService.getCarrierName(carrier)
                  }
                />
              </StackItem>
              {!mobile && (
                <StackItem
                  styles={mobile ? { root: { marginTop: 0 } } : undefined}
                >
                  <LabelField
                    {...this.props}
                    styles={{ root: { marginTop: "3px" } }}
                    label={offer.elapsedtime}
                    content={`${offer.from} - ${offer.to}`}
                  />
                </StackItem>
              )}
              {!mobile && (
                <StackItem
                  styles={{
                    root: {
                      minWidth: "100px",
                      marginTop: mobile ? 0 : undefined,
                    },
                  }}
                  grow
                >
                  <LabelField
                    {...this.props}
                    styles={{ root: { marginTop: "3px" } }}
                    label={
                      offer.schedules.length < 2
                        ? i18n.t("transports:flights:airport:nonstops")
                        : `${offer.schedules.length - 1} ${i18n.t(
                            "transports:flights:airport:stops"
                          )}`
                    }
                    content={
                      offer.schedules.length < 2
                        ? criteria.to ?? ""
                        : offer.schedules
                            .slice(1, offer.schedules.length)
                            .map((y) => y.from)
                            .join(", ")
                    }
                  />
                </StackItem>
              )}
              {!mobile && (
                <StackItem
                  align={"end"}
                  styles={{
                    root: {
                      minWidth: "120px",
                      marginTop: mobile ? 0 : undefined,
                    },
                  }}
                >
                  <LabelField
                    {...this.props}
                    styles={{
                      root: {
                        marginTop: "3px",
                        color:
                          offerIndex === 0
                            ? this.props.theme.palette.green
                            : this.props.theme.palette.black,
                      },
                    }}
                    label={`${
                      offer.totalPrice ? offer.totalPrice.toFixed(2) : "-"
                    } $`}
                    content={""}
                  />
                </StackItem>
              )}
              {mobile && (
                <StackItem
                  styles={{
                    root: {
                      marginTop: mobile ? 0 : undefined,
                    },
                  }}
                  grow
                >
                  <Stack
                    horizontal
                    tokens={{ childrenGap: 10 }}
                    horizontalAlign={"space-evenly"}
                    styles={{ root: { width: "100%" } }}
                  >
                    <StackItem
                      styles={mobile ? { root: { marginTop: 0 } } : undefined}
                    >
                      <LabelField
                        {...this.props}
                        styles={{ root: { marginTop: "3px" } }}
                        label={offer.elapsedtime}
                        content={`${offer.from} - ${offer.to}`}
                      />
                    </StackItem>
                    <StackItem
                      styles={{
                        root: {
                          minWidth: "100px",
                          marginTop: mobile ? 0 : undefined,
                        },
                      }}
                      grow
                    >
                      <LabelField
                        {...this.props}
                        styles={{ root: { marginTop: "3px" } }}
                        label={
                          offer.schedules.length < 2
                            ? i18n.t("transports:flights:airport:nonstops")
                            : `${offer.schedules.length - 1} ${i18n.t(
                                "transports:flights:airport:stops"
                              )}`
                        }
                        content={
                          offer.schedules.length < 2
                            ? criteria.to ?? ""
                            : offer.schedules
                                .slice(1, offer.schedules.length)
                                .map((y) => y.from)
                                .join(", ")
                        }
                      />
                    </StackItem>
                    <StackItem
                      align={"end"}
                      styles={{
                        root: {
                          minWidth: "120px",
                          marginTop: mobile ? 0 : undefined,
                        },
                      }}
                    >
                      <LabelField
                        {...this.props}
                        styles={{ root: { marginTop: "3px" } }}
                        label={`${
                          offer.totalPrice ? offer.totalPrice.toFixed(2) : "-"
                        } $`}
                        content={""}
                      />
                    </StackItem>
                  </Stack>
                </StackItem>
              )}

              <StackItem
                align={"end"}
                styles={mobile ? { root: { marginTop: 0 } } : undefined}
              >
                <IconButton
                  iconProps={{
                    iconName: "DrillDown",
                    styles: {
                      root: {
                        transform: opened ? "rotate(-180deg)" : undefined,
                        fontSize: "22px",
                      },
                    },
                  }}
                  onClick={() => {
                    this.setState({ opened: !opened });
                  }}
                />
              </StackItem>
            </Stack>
            {opened && (
              <FlightOfferDetails
                {...this.props}
                styles={undefined}
                booking={booking ?? false}
                serviceIndex={serviceIndex}
                flexibles={undefined}
                flexible={false}
              />
            )}
          </Card.Section>
        </Card>
      </div>
    );
  }
}
