import {
  IScrollablePaneStyleProps,
  IScrollablePaneStyles,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  mergeStyles,
  IProcessedStyleSet,
} from "@fluentui/react";
import { IFooterStyleProps, IFooterStyles } from "../Footer/Footer.styles";
import {
  IMaintenanceBarStyleProps,
  IMaintenanceBarStyles,
} from "../MaintenanceBar/MaintenanceBar.styles";
import {
  INavigationMenuStyleProps,
  INavigationMenuStyles,
} from "../NavigationMenu/NavigationMenu.styles";
import {
  IScrollToTopStyleProps,
  IScrollToTopStyles,
} from "../ScrollToTop/ScrollToTop.styles";
import {
  IComponentBaseStyles,
  IComponentBaseStyleProps,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";
import { MediaQueries } from "../MediaQueries";

export interface IMasterPageSubComponentStyles {
  scrollPaneComponent?: IStyleFunctionOrObject<
    IScrollablePaneStyleProps,
    IScrollablePaneStyles
  >;
  maintenanceBar?: IStyleFunctionOrObject<
    IMaintenanceBarStyleProps,
    IMaintenanceBarStyles
  >;
  nav?: IStyleFunctionOrObject<
    INavigationMenuStyleProps,
    INavigationMenuStyles
  >;
  footer?: IStyleFunctionOrObject<IFooterStyleProps, IFooterStyles>;
  scrollToTop?: IStyleFunctionOrObject<
    IScrollToTopStyleProps,
    IScrollToTopStyles
  >;
}

export interface IMasterPageStyles
  extends IComponentBaseStyles<IMasterPageSubComponentStyles> {
  scrollPane?: IStyle;
  header?: IStyle;
  body?: IStyle;
  content?: IStyle;
  sidebar?: IStyle;
  stickyFooter?: IStyle;
}

export interface IMasterPageStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  onmaintenance: boolean;
  menucollapsed: boolean;
}

export const getMasterPageClassNames = (
  styles?: IStyleFunctionOrObject<IMasterPageStyleProps, IMasterPageStyles>,
  props?: IMasterPageStyleProps
): [
  IProcessedStyleSet<IMasterPageStyles>,
  IMasterPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMasterPageStyleProps,
  IMasterPageStyles
> = (props: IMasterPageStyleProps): IMasterPageStyles => {
  return {
    scrollPane: [
      "ms-AppScrollPane",
      {
        overflowY: "hidden",
        background: `${props.theme.palette.neutralLighter}!important`,
      },
    ],
    body: [
      "ms-AppBody",
      {
        display: "flex",
        flexDirection: "row",
        minHeight: props.onmaintenance
          ? "calc(-139px + 100vh)"
          : "calc(-80px + 100vh)",
        background: `${props.theme.palette.neutralLighter}!important`,
      },
    ],
    content: [
      "ms-AppContent",
      {
        marginLeft: props.menucollapsed ? "42px" : "133px",
        width: "100%",
        maxWidth: props.menucollapsed
          ? "calc(-42px + 100vw)"
          : "calc(-133px + 100vw)",
        backgroundColor: props.theme.palette.neutralLighter,
        [MediaQueries.mobile]: {
          marginLeft: 0,
          maxWidth: "100%",
        },
      },
    ],
    sidebar: [
      "ms-AppSideBar",
      mergeStyles(
        {
          position: "fixed",
          order: -1,
          height: "100%",
          maxWidth: "12em",
          flexGrow: "0",
          flexShrink: "0",
          flexBasis: "12em",
          paddingTop: "0.2rem",
          zIndex: 3000,
          backgroundColor: props.theme.palette.neutralLighter,
        },
        props.menucollapsed
          ? {
              width: "42px",
              boxShadow:
                "rgba(0, 0, 0, 0.133) 0px 1.6px 3.6px 0px, rgba(0, 0, 0, 0.11) 0px 0.3px 0.9px 0px",
              cursor: "pointer",
              borderRadius: "2px",
              transition: "box-shadow 0.5s ease 0s",
              maxHeight: "630px",
            }
          : {}
      ),
    ],
    stickyFooter: [
      "ms-Sticky-Footer",
      {
        zIndex: -1,
      },
    ],
    subComponentStyles: {
      footer: {
        root: {
          height: "3em",
        },
      },
      scrollPaneComponent: {
        contentContainer: {
          overflowY: "hidden",
        },
      },
    },
  };
};
