import * as React from "react";
import { getTransportRequestsDepartureSectionClassNames } from "./TransportRequestsDepartureSection.styles";
import {
  ITransportRequestsDepartureSectionState,
  ITransportRequestsDepartureSectionProps,
} from "./TransportRequestsDepartureSection.types";
import { GridView } from "src/components/GridView";
import { ITransportDepartureRequest } from "src/models/Transports/ITransportDepartureRequest";
import {
  Text,
  SelectionMode,
  Stack,
  StackItem,
  TooltipHost,
  Icon,
  CommandBar,
  DefaultPalette,
  IconButton,
} from "@fluentui/react";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { objectId } from "src/models/IObjectReference";
import { TransportRequestsService } from "src/services/Transports/TransportRequestsService";
import { TransportRequestsLegend } from "../TransportRequestsLegend";
import {
  ITransportRequestType,
  getTransportRequestType,
} from "src/models/Transports/TransportRequestTypes";
import i18n from "src/services/i18n";
import { TransportRequestElement } from "../TransportRequestElement";
import { Card } from "@fluentui/react-cards";
import RequestsService from "src/services/Requests/RequestsService";
import { RequestReportsPanel } from "src/pages/Requests/RequestReportsPanel";

export class TransportRequestsDepartureSectionComponent extends React.Component<
  ITransportRequestsDepartureSectionProps,
  ITransportRequestsDepartureSectionState
> {
  constructor(props: ITransportRequestsDepartureSectionProps) {
    super(props);
    this.state = {
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.list,
        "eventDate",
        false
      ),
    };
    TransportRequestsService.getDepartureRequests(objectId(props.enterprise))
      .then((x) => {
        this.setState({
          items: x,
          options: GridViewOptionsBuilder.getFetched(this),
        });
      })
      .catch((_x) => {
        this.setState({
          items: [],
          options: GridViewOptionsBuilder.getFetched(this),
        });
      });
  }

  private onItemInvoked = (item: ITransportDepartureRequest): void => {
    TransportRequestsService.getDepartureRequestDetails(
      objectId(this.props.enterprise),
      item
    ).then((x) => {
      this.setState({
        request: x,
      });
    });
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames] = getTransportRequestsDepartureSectionClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { items, options } = this.state;
    const style: React.CSSProperties = { cursor: "pointer" };
    return (
      <>
        {this.state.printRequest !== undefined && (
          <RequestReportsPanel
            {...this.props}
            requestId={this.state.printRequest?.requestId ?? -1}
            versionNumber={this.state.printRequest?.requestVersionId ?? -1}
            isOpen={this.state.printRequest !== undefined}
            onDismiss={() =>
              this.setState({
                printRequest: undefined,
              })
            }
            onPrint={(
              type: number,
              language: string,
              date: Date | undefined,
              callback: () => void
            ) => {
              const { printRequest } = this.state;
              if (printRequest !== undefined) {
                RequestsService.print(
                  type,
                  objectId(this.props.enterprise),
                  printRequest.requestId,
                  printRequest.requestVersionId,
                  date,
                  language
                ).then(() => {
                  callback();
                  this.setState({
                    printRequest: undefined,
                  });
                });
              }
            }}
          />
        )}
        <div className={classNames.root}>
          <Stack
            grow
            horizontal={!mobile && !tablet}
            tokens={{ childrenGap: 20 }}
          >
            <StackItem
              grow
              styles={
                mobile || tablet
                  ? undefined
                  : {
                      root: {
                        maxWidth: "66vw",
                      },
                    }
              }
            >
              <Stack
                grow
                verticalFill
                styles={{
                  root: {
                    width: "100%",
                    padding: mobile ? "4px" : tablet ? "10px" : "20px",
                  },
                }}
              >
                <Card
                  styles={{
                    root: {
                      backgroundColor:
                        this.state.options.mode === GridViewMode.tiles
                          ? undefined
                          : "#fff",
                      padding:
                        this.state.options.mode === GridViewMode.tiles
                          ? undefined
                          : "0 10px 10px 10px",
                      height: "auto",
                      maxHeight: "unset",
                      width: "100%",
                      maxWidth: "unset",
                    },
                  }}
                >
                  <Card.Item>
                    <GridView
                      {...this.props}
                      styles={undefined}
                      options={options}
                      selectionMode={SelectionMode.none}
                      items={items ?? []}
                      onDataPaging={() => {}}
                      columns={
                        mobile
                          ? [
                              {
                                key: "reports",
                                name: "",
                                iconName: "Print",
                                minWidth: 60,
                                maxWidth: 60,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => (
                                  <div>
                                    <IconButton
                                      iconProps={{ iconName: "Print" }}
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({
                                          printRequest: item,
                                        });
                                      }}
                                    />
                                  </div>
                                ),
                              },
                              {
                                key: "requestNumber",
                                name: "transports:requests:requestnumber",
                                minWidth: 160,
                              },
                              {
                                key: "transportType",
                                name: "",
                                minWidth: 60,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => {
                                  const type:
                                    | ITransportRequestType
                                    | undefined = getTransportRequestType(
                                    item.transportType
                                  );
                                  return (
                                    <TooltipHost
                                      content={
                                        item.source === undefined ? (
                                          type === undefined ? (
                                            ""
                                          ) : (
                                            i18n.t(type.description)
                                          )
                                        ) : (
                                          <div>
                                            {item.source
                                              .split(";")
                                              .map((x, i) => (
                                                <div
                                                  key={i.toString()}
                                                  style={{
                                                    fontWeight:
                                                      i % 2 === 0 ? 600 : 400,
                                                  }}
                                                >
                                                  {x}
                                                </div>
                                              ))}
                                            <div>
                                              {type && i18n.t(type.description)}
                                            </div>
                                          </div>
                                        )
                                      }
                                    >
                                      <Icon
                                        onClick={() => {
                                          this.onItemInvoked(item);
                                        }}
                                        iconName={type?.iconName}
                                        color={type?.color}
                                        styles={{
                                          root: {
                                            cursor: "pointer",
                                            fontSize: "16px",
                                            color: type?.color,
                                          },
                                        }}
                                      />
                                    </TooltipHost>
                                  );
                                },
                              },
                              {
                                key: "eventDate",
                                name: "transports:requests:date",
                                minWidth: 120,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => {
                                  return (
                                    <Text
                                      style={style}
                                      onClick={() => {
                                        this.onItemInvoked(item);
                                      }}
                                    >
                                      {!item.confirmedDate ||
                                      item.confirmedDate === ""
                                        ? item.eventDate
                                        : item.confirmedDate}
                                    </Text>
                                  );
                                },
                              },
                            ]
                          : [
                              {
                                key: "reports",
                                name: "",
                                iconName: "Print",
                                minWidth: 60,
                                maxWidth: 60,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => (
                                  <div>
                                    <IconButton
                                      iconProps={{ iconName: "Print" }}
                                      onClick={(ev) => {
                                        ev.preventDefault();
                                        ev.stopPropagation();
                                        this.setState({
                                          printRequest: item,
                                        });
                                      }}
                                    />
                                  </div>
                                ),
                              },
                              {
                                key: "requestNumber",
                                name: "transports:requests:requestnumber",
                                minWidth: 160,
                              },
                              {
                                key: "typeDescription",
                                name: "transports:requests:type",
                                minWidth: 170,
                              },
                              {
                                key: "transportType",
                                name: "transports:requests:typeicon",
                                minWidth: 220,
                                maxWidth: 220,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => {
                                  const type:
                                    | ITransportRequestType
                                    | undefined = getTransportRequestType(
                                    item.transportType
                                  );
                                  return type ? (
                                    <Stack
                                      horizontal
                                      verticalAlign={"center"}
                                      tokens={{ childrenGap: 10 }}
                                    >
                                      <StackItem
                                        align={"center"}
                                        styles={{ root: { marginTop: "4px" } }}
                                      >
                                        <TooltipHost
                                          content={
                                            item.source === undefined ? (
                                              i18n.t(type.description)
                                            ) : (
                                              <div>
                                                {item.source
                                                  .split(";")
                                                  .map((x, i) => (
                                                    <div
                                                      key={i.toString()}
                                                      style={{
                                                        fontWeight:
                                                          i % 2 === 0
                                                            ? 600
                                                            : 400,
                                                      }}
                                                    >
                                                      {x}
                                                    </div>
                                                  ))}
                                                <div>
                                                  {i18n.t(type.description)}
                                                </div>
                                              </div>
                                            )
                                          }
                                        >
                                          <Icon
                                            onClick={() => {
                                              this.onItemInvoked(item);
                                            }}
                                            iconName={type?.iconName}
                                            color={type.color}
                                            styles={{
                                              root: {
                                                cursor: "pointer",
                                                fontSize: "16px",
                                                color: type.color,
                                              },
                                            }}
                                          />
                                        </TooltipHost>
                                      </StackItem>
                                      <StackItem align={"center"}>
                                        <TooltipHost
                                          content={
                                            item.source === undefined ? (
                                              i18n.t(type.description)
                                            ) : (
                                              <div>
                                                {item.source
                                                  .split(";")
                                                  .map((x, i) => (
                                                    <div
                                                      key={i.toString()}
                                                      style={{
                                                        fontWeight:
                                                          i % 2 === 0
                                                            ? 600
                                                            : 400,
                                                      }}
                                                    >
                                                      {x}
                                                    </div>
                                                  ))}
                                                <div>
                                                  {i18n.t(type.description)}
                                                </div>
                                              </div>
                                            )
                                          }
                                        >
                                          <Text
                                            style={style}
                                            onClick={() => {
                                              this.onItemInvoked(item);
                                            }}
                                          >
                                            {i18n.t(type.name)}
                                          </Text>
                                        </TooltipHost>
                                      </StackItem>
                                    </Stack>
                                  ) : (
                                    <Text></Text>
                                  );
                                },
                              },
                              {
                                key: "eventDate",
                                name: "transports:requests:date",
                                minWidth: 120,
                                onRenderCell: (
                                  item: ITransportDepartureRequest
                                ) => {
                                  return (
                                    <Text
                                      style={style}
                                      onClick={() => {
                                        this.onItemInvoked(item);
                                      }}
                                    >
                                      {!item.confirmedDate ||
                                      item.confirmedDate === ""
                                        ? item.eventDate
                                        : item.confirmedDate}
                                    </Text>
                                  );
                                },
                              },
                              {
                                key: "passengers",
                                name: "transports:requests:passengers",
                                minWidth: 140,
                              },
                            ]
                      }
                      onItemInvoked={this.onItemInvoked}
                      entityPanelProps={{
                        ...this.props,
                        styles: undefined,
                        isOpen: this.state.request !== undefined,
                        "data-automation-id":
                          "nvx:transports:request:itempanel",
                        elementName: i18n.t("transports:departures:element"),
                        headerIconName: "AirplaneSolid",
                        title: `${this.state.request?.requestNumber} : ${
                          this.state.request?.date
                            ? i18n.parseISOLocalDate(this.state.request.date)
                            : ""
                        }`,
                        onDismiss: () => {
                          this.setState({
                            request: undefined,
                          });
                        },
                        children: this.state.request ? (
                          <TransportRequestElement
                            {...this.props}
                            styles={undefined}
                            request={this.state.request}
                          />
                        ) : (
                          <div></div>
                        ),
                      }}
                    />
                  </Card.Item>
                </Card>
              </Stack>
            </StackItem>
            <StackItem grow>
              <div>
                {!mobile && !tablet && <div style={{ height: "10px" }}></div>}
                <CommandBar
                  styles={{
                    root: {
                      background: "transparent",
                      paddingLeft: 0,
                    },
                  }}
                  items={[
                    {
                      key: "export",
                      buttonStyles: {
                        root: {
                          background: "transparent",
                        },
                      },
                      name: i18n.t("grid:export"),
                      iconProps: {
                        styles: {
                          root: {
                            color: DefaultPalette.green,
                          },
                        },
                        iconName: "ExcelDocument",
                      },
                      onClick: () => {
                        TransportRequestsService.exportDepartures(
                          objectId(this.props.enterprise)
                        );
                      },
                    },
                  ]}
                />
              </div>
              <TransportRequestsLegend {...this.props} styles={undefined} />
            </StackItem>
          </Stack>
        </div>
      </>
    );
  }
}
