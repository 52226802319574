import { styled } from "@fluentui/react/lib/Utilities";
import { IArrivalAssistedParticipantCardProps } from "./ArrivalAssistedParticipantCard.types";
import {
  getStyles,
  IArrivalAssistedParticipantCardStyleProps,
  IArrivalAssistedParticipantCardStyles,
} from "./ArrivalAssistedParticipantCard.styles";
import { ArrivalAssistedParticipantCardComponent } from "./ArrivalAssistedParticipantCard.base";

/**
 * ArrivalAssistedParticipantCard
 */
export const ArrivalAssistedParticipantCard = styled<
  IArrivalAssistedParticipantCardProps,
  IArrivalAssistedParticipantCardStyleProps,
  IArrivalAssistedParticipantCardStyles
>(ArrivalAssistedParticipantCardComponent, getStyles);
