import {
  DefaultButton,
  Icon,
  IconButton,
  initializeComponentRef,
  Panel,
  PrimaryButton,
  ProgressIndicator,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { IAttachmentModel } from "src/models/Messages/IMessageSummary";
import { INewMessageControlSubComponentStyles } from "src/pages/Messages/NewMessageControl/NewMessageControl.styles";
import i18n from "src/services/i18n";
import { getUploadFilesDialogClassNames } from "./UploadFilesDialog.styles";
import {
  IUploadFilesDialogProps,
  IUploadFilesDialogState,
} from "./UploadFilesDialog.types";

export class UploadFilesDialogComponent extends React.Component<
  IUploadFilesDialogProps,
  IUploadFilesDialogState
> {
  constructor(props: IUploadFilesDialogProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {
      isOpen: false,
      attachments: [],
    };
  }

  private fileUploadRef: HTMLInputElement | null = null;

  public open = () => {
    this.fileUploadRef?.click();
  };

  private getBase64 = (
    id: number,
    file: File,
    cb: (id: number, item: string | ArrayBuffer | null) => void
  ): void => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(id, reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  };

  private handleFileUpload = (
    _ev: React.ChangeEvent<HTMLInputElement>
  ): void => {
    if (this.fileUploadRef) {
      const { files } = this.fileUploadRef;
      if (files && files.length > 0) {
        const { attachments } = this.state;
        for (let i = 0; i < files.length; i++) {
          const item = files.item(i);
          if (item) {
            const newId = -(attachments.length + 1);
            attachments.push({
              id: newId,
              name: item.name,
              type: "application/pdf",
              size: `${(item.size / 1024).toFixed(0)} kb`,
              data: undefined,
            });
          }
          this.setState({ attachments: attachments, isOpen: true });
          setTimeout(() => {
            for (let i = 0; i < files.length; i++) {
              const item = files.item(i);
              if (item) {
                const at = attachments[i];
                this.getBase64(
                  at.id,
                  item,
                  (id: number, x: string | ArrayBuffer | null) => {
                    const att = this.state.attachments;
                    attachments.filter((x) => x.id === id)[0].data = x;
                    this.setState({ attachments: att, isOpen: true });
                    if (this.fileUploadRef) {
                      this.fileUploadRef.value = "";
                    }
                  }
                );
              }
            }
          }, 1000);
        }
      }
    }
  };

  private upload = (): void => {
    // validation
    this.props
      .onUpload(this.state.attachments)
      .then((_x) =>
        this.setState({
          attachments: [],
          isOpen: false,
        })
      )
      .catch((_x) =>
        this.setState({
          attachments: [],
          isOpen: false,
        })
      );
  };

  private onRenderAttachment = (
    item: IAttachmentModel,
    subComponentStyles?: INewMessageControlSubComponentStyles
  ): JSX.Element => {
    return (
      <div
        key={item.id}
        style={{
          width: "100%",
          padding: "4px",
          marginTop: "10px",
          border: "1px solid " + this.props.theme.palette.neutralDark,
        }}
      >
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
          <TooltipHost content={item.name}>
            <Icon iconName="PDF" style={{ fontSize: "36px" }} />
          </TooltipHost>
          <StackItem grow>
            <Stack grow tokens={{ childrenGap: 4 }}>
              <StackItem grow>
                <TooltipHost content={item.name}>
                  <Text block nowrap>
                    {item.name}
                  </Text>
                </TooltipHost>
              </StackItem>
              <StackItem grow>
                <TooltipHost content={item.name}>
                  <Text variant={"small"}>{item.size}</Text>
                </TooltipHost>
              </StackItem>
              {item.data === undefined && (
                <ProgressIndicator label="Downloading..." description="" />
              )}
            </Stack>
          </StackItem>
          <StackItem align={"baseline"}>
            <Stack>
              <div style={{ height: "5px" }}></div>
              <TooltipHost content={item.name}>
                <IconButton
                  onClick={() => {
                    const { attachments } = this.state;
                    const results = attachments.filter((x) => x.id !== item.id);
                    this.setState({
                      attachments: results,
                      isOpen: results.length > 0,
                    });
                  }}
                  iconProps={{ iconName: "Delete" }}
                  styles={subComponentStyles?.iconStyles}
                />
              </TooltipHost>
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames, subComponentStyles] = getUploadFilesDialogClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { isOpen, attachments } = this.state;
    return (
      <div className={classNames.root}>
        <input
          ref={(ref) => (this.fileUploadRef = ref)}
          style={{ display: "none" }}
          type="file"
          multiple
          onChange={this.handleFileUpload}
        />
        <Panel
          headerText={""}
          isLightDismiss={false}
          isBlocking={true}
          onRenderHeader={() => (
            <IconHeader
              {...this.props}
              styles={{
                root: {
                  marginTop: mobile ? "-17px" : "-16px",
                  marginRight: "5px",
                },
              }}
              iconName={"CloudUpload"}
              title={i18n.t("global:upload")}
            />
          )}
          isFooterAtBottom={true}
          isOpen={isOpen}
          title={i18n.t("participants:upload")}
          onRenderFooter={() => {
            return (
              <Stack
                horizontal
                tokens={this.props.tokens?.defaultStackTokens}
                className={classNames.footerSection}
              >
                <PrimaryButton
                  data-automation-id="nvx:upload:apply"
                  text={i18n.t("global:upload")}
                  className={classNames.footerButton}
                  onClick={this.upload}
                />
                <DefaultButton
                  data-automation-id="nvx:upload:cancel"
                  text={i18n.t("global:cancel")}
                  className={classNames.footerButton}
                  onClick={() =>
                    this.setState({ isOpen: false, attachments: [] })
                  }
                />
              </Stack>
            );
          }}
        >
          <Stack tokens={{ childrenGap: 10 }}>
            {attachments.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {attachments.map((x) => {
                  return this.onRenderAttachment(x, subComponentStyles);
                })}
                <div className="clear-both"></div>
                <div style={{ height: "5px" }}></div>
              </div>
            )}
          </Stack>
        </Panel>
      </div>
    );
  }
}
