import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportRequestsLegendSubComponentStyles {}

export interface ITransportRequestsLegendStyles
  extends IAmigoBaseStyles<ITransportRequestsLegendSubComponentStyles> {}

export interface ITransportRequestsLegendStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportRequestsLegendClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportRequestsLegendStyleProps,
    ITransportRequestsLegendStyles
  >,
  props?: ITransportRequestsLegendStyleProps
): [
  IProcessedStyleSet<ITransportRequestsLegendStyles>,
  ITransportRequestsLegendSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportRequestsLegendStyleProps,
  ITransportRequestsLegendStyles
> = (
  _props: ITransportRequestsLegendStyleProps
): ITransportRequestsLegendStyles => {
  return {
    root: ["amigo-TransportRequestsLegend", {}],
  };
};
