import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface IIconHeaderSubComponentStyles {
  icon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IIconHeaderStyles
  extends IComponentBaseStyles<IIconHeaderSubComponentStyles> {
  iconContainer?: IStyle;
  icon?: IStyle;
  iconTooltip?: IStyle;
  textContainer?: IStyle;
  text?: IStyle;
  end?: IStyle;
}

export interface IIconHeaderStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getIconHeaderClassNames = (
  styles?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>,
  props?: IIconHeaderStyleProps
): [
  IProcessedStyleSet<IIconHeaderStyles>,
  IIconHeaderSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IIconHeaderStyleProps,
  IIconHeaderStyles
> = (_props: IIconHeaderStyleProps): IIconHeaderStyles => {
  return {
    root: ["ms-IconHeader", {}],
    iconContainer: {
      float: "left",
      paddingLeft: "5px",
      paddingRight: "10px",
    },
    textContainer: {
      float: "left",
    },
    end: {
      clear: "both",
    },
    icon: {
      marginTop: "2px",
      fontSize: "22px",
    },
  };
};
