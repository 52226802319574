import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationDocumentsControlProps } from "./DossierImmigrationDocumentsControl.types";
import {
  getStyles,
  IDossierImmigrationDocumentsControlStyleProps,
  IDossierImmigrationDocumentsControlStyles,
} from "./DossierImmigrationDocumentsControl.styles";
import { DossierImmigrationDocumentsControlComponent } from "./DossierImmigrationDocumentsControl.base";

/**
 * DossierImmigrationDocumentsControl description
 */
export const DossierImmigrationDocumentsControl = styled<
  IDossierImmigrationDocumentsControlProps,
  IDossierImmigrationDocumentsControlStyleProps,
  IDossierImmigrationDocumentsControlStyles
>(DossierImmigrationDocumentsControlComponent, getStyles);
