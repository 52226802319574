import {
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  ISelectableOption,
  Position,
  PrimaryButton,
  SpinButton,
  Stack,
  StackItem,
  Text,
  Toggle,
} from "@fluentui/react";
import { Card, ICardTokens } from "@fluentui/react-cards";
import * as React from "react";
import i18n from "src/services/i18n";
import { TransportFlightSearchService } from "src/services/Transports/TransportFlightSearchService";
import { AirportSelector } from "../AirportSelector";
import { TravelWarningsSection } from "../TravelWarningsSection";
import { DateRangePicker } from "./Controls/DateRangePicker";
import { getFlightSearchCriteriaClassNames } from "./FlightSearchCriteria.styles";
import {
  IFlightSearchCriteriaProps,
  IFlightSearchCriteriaState,
} from "./FlightSearchCriteria.types";

const flexibleEnabled = false;

/** Remove the suffix or any other text after the numbers, or return undefined if not a number */
const getNumericPart = (value: string): number | undefined => {
  const valueRegex = /^(\d+(\.\d+)?).*/;
  if (valueRegex.test(value)) {
    const numericValue = Number(value.replace(valueRegex, "$1"));
    return isNaN(numericValue) ? undefined : numericValue;
  }
  return undefined;
};

export class FlightSearchCriteriaComponent extends React.Component<
  IFlightSearchCriteriaProps,
  IFlightSearchCriteriaState
> {
  constructor(props: IFlightSearchCriteriaProps) {
    super(props);
    this.state = {
      passengers: [],
    };
    TransportFlightSearchService.getPassengersNumbers().then((x) => {
      this.setState({
        passengers: x,
      });
    });
  }

  render(): JSX.Element {
    const { styles, onSearch, errors, horizontal, mobile, charterEnabled } =
      this.props;
    const marketEnabled = false;
    const [classNames, subComponentStyles] = getFlightSearchCriteriaClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const suffix = i18n.t("transports:flightcriteria:passengers");
    const min = 1;
    const max = 200;
    /** Increment the value (or return nothing to keep the previous value if invalid) */
    const onIncrement = (
      value: string,
      _event?: React.SyntheticEvent<HTMLElement>
    ): string | void => {
      const numericValue = getNumericPart(value);
      if (numericValue !== undefined) {
        return `${String(Math.min(numericValue + 1, max))} ${suffix}`;
      }
    };

    /** Decrement the value (or return nothing to keep the previous value if invalid) */
    const onDecrement = (
      value: string,
      _event?: React.SyntheticEvent<HTMLElement>
    ): string | void => {
      const numericValue = getNumericPart(value);
      if (numericValue !== undefined) {
        return `${String(Math.max(numericValue - 1, min))} ${suffix}`;
      }
    };

    /**
     * Clamp the value within the valid range (or return nothing to keep the previous value
     * if there's not valid numeric input)
     */
    const onValidate = (
      value: string,
      _event?: React.SyntheticEvent<HTMLElement>
    ): string | void => {
      let numericValue = getNumericPart(value);
      if (numericValue !== undefined) {
        numericValue = Math.min(numericValue, max);
        numericValue = Math.max(numericValue, min);
        return `${String(numericValue)} ${suffix}`;
      }
    };

    /** This will be called after each change */
    const onChange = (
      _event: React.SyntheticEvent<HTMLElement>,
      value?: string
    ): void => {
      let numericValue = getNumericPart(value ?? "1");
      if (numericValue !== undefined) {
        numericValue = Math.min(numericValue, max);
        numericValue = Math.max(numericValue, min);
        this.props.onCriteriaChanged({
          ...this.props.criteria,
          passengers: numericValue,
        });
      }
    };
    const cardTokens: ICardTokens = { childrenMargin: mobile ? 0 : 12 };
    const { passengers } = this.state;
    return (
      <div className={classNames.root}>
        <Stack
          tokens={{ padding: mobile ? 0 : 8, childrenGap: 10 }}
          grow
          horizontalAlign={"center"}
          verticalAlign={"center"}
        >
          <StackItem grow align={"center"} styles={{ root: { width: "100%" } }}>
            <Card
              aria-label=""
              horizontal
              styles={{
                root: {
                  maxWidth: "100%",
                  paddingLeft: mobile ? "5px" : "5px",
                  width: "100%",
                  backgroundColor: this.props.theme.palette.white,
                },
              }}
              tokens={cardTokens}
            >
              {!mobile && (
                <Card.Item fill>
                  <Icon
                    iconName={"AirplaneSolid"}
                    styles={{
                      root: {
                        margin: horizontal ? "30px 20px" : "20px 15px",
                        fontSize: "48px",
                        color: this.props.theme.palette.themePrimary,
                      },
                    }}
                  />
                </Card.Item>
              )}
              <Card.Section
                styles={{
                  root: {
                    marginLeft: "0px",
                  },
                }}
              >
                <Stack>
                  <StackItem>
                    <ChoiceGroup
                      className={"amigo-FlightSearch-TypeSelection"}
                      styles={{ flexContainer: { display: "flex" } }}
                      selectedKey={
                        this.props.criteria.arrival ? "arrivals" : "departures"
                      }
                      options={[
                        {
                          key: "arrivals",
                          text: i18n.t("transports:requests:arrivals:header"),
                          styles: {
                            labelWrapper: {
                              marginRight: "5px",
                            },
                            choiceFieldWrapper: {
                              marginRight: "5px",
                            },
                          },
                        },
                        {
                          key: "departures",

                          text: i18n.t("transports:requests:departures:header"),
                          styles: {
                            labelWrapper: {
                              marginRight: "5px",
                            },
                            choiceFieldWrapper: {
                              marginRight: "5px",
                            },
                          },
                        },
                      ]}
                      onChange={(
                        _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                        option?: IChoiceGroupOption
                      ) => {
                        const { onCriteriaChanged, criteria } = this.props;
                        onCriteriaChanged({
                          ...criteria,
                          from: criteria.to,
                          to: criteria.from,
                          arrival: (option?.key ?? "") === "arrivals",
                        });
                      }}
                    />
                  </StackItem>
                  <StackItem>
                    <Text
                      variant={"large"}
                      styles={subComponentStyles?.siteTextStyles}
                    >
                      Recherche de vols
                    </Text>
                  </StackItem>
                </Stack>
                <Stack
                  horizontal={horizontal}
                  grow
                  tokens={{ childrenGap: 10 }}
                >
                  {!horizontal && (
                    <Text styles={subComponentStyles?.descriptionTextStyles}>
                      {this.props.criteria.arrival ? (
                        <span>
                          {i18n.t("transports:flightcriteria:instructions:1")}
                          {/* <b>
                            {i18n.t(
                              "transports:flightcriteria:instructions:arrivals"
                            )}
                            </b> */}
                          {i18n.t("transports:flightcriteria:instructions:2")}
                        </span>
                      ) : (
                        <span>
                          {i18n.t("transports:flightcriteria:instructions:1")}
                          {/* <b>
                            {i18n.t(
                              "transports:flightcriteria:instructions:departures"
                            )}
                            </b> */}
                          {i18n.t("transports:flightcriteria:instructions:2")}
                        </span>
                      )}
                    </Text>
                  )}
                  <Stack
                    grow={!horizontal}
                    horizontal={horizontal}
                    tokens={{ childrenGap: horizontal ? 5 : 0 }}
                    styles={{
                      root: {
                        maxWidth: horizontal
                          ? "1024px"
                          : mobile
                          ? "100%"
                          : "560px",
                        maxHeight: horizontal ? "56px" : undefined,
                      },
                    }}
                  >
                    <AirportSelector
                      {...this.props}
                      styles={undefined}
                      arrival={this.props.criteria.arrival}
                      origin={true}
                      error={errors?.from}
                      airports={
                        this.props.criteria.arrival
                          ? this.props.exteriorAirports
                          : this.props.interiorAirports
                      }
                      selectedAirport={this.props.criteria.from}
                      onAirportSelected={(id: string | undefined) => {
                        this.props.onCriteriaChanged({
                          ...this.props.criteria,
                          from: id,
                        });
                      }}
                      placeholder={i18n.t(
                        "transports:flights:airport:origin:placeholder"
                      )}
                    />
                    <AirportSelector
                      {...this.props}
                      styles={undefined}
                      arrival={!this.props.criteria.arrival}
                      airports={
                        this.props.criteria.arrival
                          ? this.props.interiorAirports
                          : this.props.exteriorAirports
                      }
                      origin={false}
                      error={errors?.to}
                      selectedAirport={this.props.criteria.to}
                      onAirportSelected={(id: string | undefined) => {
                        this.props.onCriteriaChanged({
                          ...this.props.criteria,
                          to: id,
                        });
                      }}
                      placeholder={i18n.t(
                        "transports:flights:airport:destination:placeholder"
                      )}
                    />
                  </Stack>
                  <Stack
                    horizontal={!mobile}
                    tokens={{ childrenGap: mobile ? 20 : 50 }}
                    horizontalAlign={"baseline"}
                    verticalAlign={"baseline"}
                  >
                    {!horizontal && (
                      <StackItem align={"baseline"} grow={3}>
                        <Stack
                          styles={{
                            root: {
                              marginLeft: horizontal ? "5px" : undefined,
                              minWidth: "140px!important",
                            },
                          }}
                        >
                          <DateRangePicker {...this.props} />
                        </Stack>
                      </StackItem>
                    )}
                    <StackItem align={"baseline"} grow={2}>
                      {marketEnabled ? (
                        <Dropdown
                          key={"passengers"}
                          required={true}
                          data-automation-id={
                            "nav:transports:flights:passengerscount"
                          }
                          placeholder={i18n.t(
                            "transports:flightcriteria:passengers"
                          )}
                          options={passengers}
                          label={i18n.t(
                            "transports:flightcriteria:passengersnumber"
                          )}
                          selectedKey={this.props.criteria.passengers}
                          errorMessage={errors?.passengers}
                          onRenderItem={(item?: ISelectableOption) => {
                            return (
                              <div>
                                <Text
                                  data-automation-id={`nav:transports:flights:passengerscount:${item?.key}`}
                                >
                                  {item?.text}
                                </Text>
                              </div>
                            );
                          }}
                          onChange={(
                            _ev: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                          ) => {
                            if (option) {
                              this.props.onCriteriaChanged({
                                ...this.props.criteria,
                                passengers: parseInt(option.key.toString()),
                              });
                            }
                          }}
                          styles={{
                            root: {
                              minWidth: "180px!important",
                            },
                            caretDownWrapper: horizontal
                              ? {
                                  marginTop: "8px",
                                }
                              : undefined,
                            title: horizontal
                              ? {
                                  paddingTop: "8px",
                                  minHeight: "47px",
                                  marginTop: "-4px",
                                }
                              : undefined,
                            dropdown: horizontal
                              ? {
                                  minHeight: "47px",
                                  marginTop: "-4px",
                                }
                              : undefined,
                          }}
                        />
                      ) : (
                        <SpinButton
                          min={1}
                          max={200}
                          labelPosition={Position.top}
                          defaultValue={`1 ${suffix}`}
                          onDecrement={onDecrement}
                          onIncrement={onIncrement}
                          onValidate={onValidate}
                          onChange={onChange}
                          styles={{
                            root: [
                              {
                                minWidth: "100px!important",
                              },
                            ],
                            spinButtonWrapper: horizontal
                              ? {
                                  minHeight: "47px",
                                  marginTop: "-4px",
                                }
                              : undefined,
                          }}
                          label={i18n.t(
                            "transports:flightcriteria:passengersnumber"
                          )}
                          placeholder={i18n.t(
                            "transports:flightcriteria:passengers"
                          )}
                        />
                      )}
                    </StackItem>
                  </Stack>

                  <Stack
                    horizontal
                    tokens={{ childrenGap: mobile ? 20 : 50 }}
                    horizontalAlign={"baseline"}
                    verticalAlign={"baseline"}
                  >
                    <StackItem align={"baseline"} grow>
                      <Stack
                        horizontal={!mobile}
                        horizontalAlign={"end"}
                        verticalAlign={"baseline"}
                        tokens={{ childrenGap: 24 }}
                      >
                        {flexibleEnabled && (mobile || !horizontal) && (
                          <Stack
                            horizontal
                            verticalFill
                            verticalAlign={mobile ? "end" : "start"}
                            horizontalAlign={"stretch"}
                            tokens={{ childrenGap: 10 }}
                          >
                            <Toggle
                              label={i18n.t(
                                "transports:flightcriteria:flexible"
                              )}
                              onChange={(
                                _event: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                              ) => {
                                this.props.onCriteriaChanged({
                                  ...this.props.criteria,
                                  flexible: checked ?? false,
                                });
                              }}
                              styles={{
                                root: { textAlign: "right", display: "block" },
                              }}
                              checked={this.props.criteria.flexible}
                            />
                            <Toggle
                              label={i18n.t("transports:flightcriteria:market")}
                              disabled={!marketEnabled}
                              onChange={(
                                _event: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                              ) => {
                                const val = checked ?? false;
                                this.props.onCriteriaChanged({
                                  ...this.props.criteria,
                                  market: val,
                                  charter: val
                                    ? this.props.criteria.charter
                                    : true,
                                });
                              }}
                              styles={{
                                root: { textAlign: "right", display: "none" },
                              }}
                              checked={
                                marketEnabled && this.props.criteria.market
                              }
                            />
                            <Toggle
                              label={i18n.t(
                                "transports:flightcriteria:charter"
                              )}
                              disabled={!charterEnabled}
                              onChange={(
                                _event: React.MouseEvent<HTMLElement>,
                                checked?: boolean
                              ) => {
                                const val = checked ?? false;
                                this.props.onCriteriaChanged({
                                  ...this.props.criteria,
                                  charter: val,
                                  market: val
                                    ? this.props.criteria.market
                                    : true,
                                });
                              }}
                              styles={{
                                root: { textAlign: "right", display: "none" },
                              }}
                              checked={
                                charterEnabled && this.props.criteria.charter
                              }
                            />
                          </Stack>
                        )}
                        <StackItem align={"end"} grow verticalFill>
                          <Stack
                            tokens={{ childrenGap: 5 }}
                            grow
                            horizontalAlign={"end"}
                          >
                            <div style={{ height: "15px" }}></div>
                            <PrimaryButton
                              text={i18n.t("global:search")}
                              style={{
                                marginLeft:
                                  horizontal && !mobile ? "15px" : undefined,
                                marginTop: horizontal ? "13px" : "8px",
                                height: horizontal ? "42px" : undefined,
                              }}
                              iconProps={{
                                iconName: "Search",
                              }}
                              onClick={onSearch}
                            />
                          </Stack>
                        </StackItem>
                      </Stack>
                      <div style={{ height: "25px" }}></div>
                    </StackItem>
                  </Stack>
                </Stack>
                {!mobile && horizontal && (
                  <Stack
                    horizontal
                    verticalFill
                    verticalAlign={mobile ? "end" : "start"}
                    horizontalAlign={"stretch"}
                    tokens={{ childrenGap: 20 }}
                  >
                    <StackItem align={"baseline"}>
                      <Stack
                        styles={{
                          root: {
                            marginLeft: horizontal ? "5px" : undefined,
                            minWidth: "140px!important",
                          },
                        }}
                      >
                        <DateRangePicker {...this.props} />
                      </Stack>
                    </StackItem>
                    {flexibleEnabled && (
                      <Toggle
                        label={i18n.t("transports:flightcriteria:flexible")}
                        onChange={(
                          _event: React.MouseEvent<HTMLElement>,
                          checked?: boolean
                        ) => {
                          this.props.onCriteriaChanged({
                            ...this.props.criteria,
                            flexible: checked ?? false,
                          });
                        }}
                        styles={{ root: { textAlign: "right" } }}
                        checked={this.props.criteria.flexible}
                      />
                    )}
                    <Toggle
                      label={i18n.t("transports:flightcriteria:market")}
                      disabled={!marketEnabled}
                      onChange={(
                        _event: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        const val = checked ?? false;
                        this.props.onCriteriaChanged({
                          ...this.props.criteria,
                          market: val,
                          charter: val ? this.props.criteria.charter : true,
                        });
                      }}
                      styles={{ root: { textAlign: "right", display: "none" } }}
                      checked={this.props.criteria.market}
                    />
                    <Toggle
                      label={i18n.t("transports:flightcriteria:charter")}
                      disabled={!charterEnabled}
                      onChange={(
                        _event: React.MouseEvent<HTMLElement>,
                        checked?: boolean
                      ) => {
                        const val = checked ?? false;
                        this.props.onCriteriaChanged({
                          ...this.props.criteria,
                          charter: val,
                          market: val ? this.props.criteria.market : true,
                        });
                      }}
                      styles={{ root: { textAlign: "right", display: "none" } }}
                      checked={this.props.criteria.charter}
                    />
                  </Stack>
                )}
              </Card.Section>
              <Card.Item fill>
                <div style={{ width: "48px" }}></div>
              </Card.Item>
            </Card>
            <TravelWarningsSection {...this.props} styles={undefined} />
          </StackItem>
        </Stack>
      </div>
    );
  }
}
