import { IColumn, IRectangle, Selection, SelectionMode } from "@fluentui/react";
import * as React from "react";
import i18n from "../../services/i18n";
import { IGridColumn, IGridViewOptions } from "./GridView.types";

class GridViewHelper {
  public init(_this: any): void {
    _this.state = {
      language: i18n.getLanguage(),
      filters: undefined,
      confirmingCommand: () => {},
      isConfirming: false,
      confirmTitle: "",
      confirmMessage: "",
      selection: new Selection({
        onSelectionChanged: _this._onSelectionChanged,
      }),
      selectionMode: SelectionMode.multiple,
      canSelect: "all",
      filtering: false,
    };

    _this.state.selection.setItems(_this.props.items, false);
    _this._toggleGridView = _this._toggleGridView.bind(_this);
    _this._resizeMode = _this._resizeMode.bind(_this);
    _this._onSelectionChanged = _this._onSelectionChanged.bind(_this);
    _this._sort = _this._sort.bind(_this);
    _this._queryFilters = _this._queryFilters.bind(_this);
    _this._applyFilters = _this._applyFilters.bind(_this);
    window.addEventListener("resize", _this._resizeMode);
  }

  public isDescending(key: string, options: IGridViewOptions): boolean {
    return options.sortBy === key ? !options.desc : false;
  }

  public getColumns<T>(
    columns: IGridColumn<T>[],
    options: IGridViewOptions,
    onSort?: (sortBy: string, desc: boolean) => void
  ): IColumn[] {
    return columns
      .filter((x) => x.visible ?? true)
      .map((x, index: number) => {
        return {
          minWidth: x.minWidth === undefined ? 220 : x.minWidth,
          key: `col_${index}_${x.key}`,
          isIconOnly: x.iconName ? true : undefined,
          iconName: x.iconName,
          onRender: x.onRenderCell
            ? (item?: any, _index?: number, _column?: IColumn) => {
                return item === undefined || x.onRenderCell === undefined
                  ? undefined
                  : x.onRenderCell(item);
              }
            : undefined,
          maxWidth: x.maxWidth
            ? x.maxWidth
            : x.minWidth === undefined
            ? 450
            : undefined,
          currentWidth: x.maxWidth,
          fieldName: x.key,
          name: i18n.t(x.name),
          isSorted: options.sortBy === x.key,
          isSortedDescending: options.desc,
          onColumnClick: onSort
            ? (_ev: React.MouseEvent<HTMLElement>, column: IColumn) => {
                column.fieldName &&
                  onSort(
                    column.fieldName,
                    options.sortBy === column.fieldName ? !options.desc : false
                  );
              }
            : undefined,
        };
      });
  }

  public getItemCountForPage(
    _itemIndex?: number,
    _visibleRect?: IRectangle
  ): number {
    return 20;
  }
}

export default new GridViewHelper();
