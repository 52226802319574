import { styled } from "@fluentui/react/lib/Utilities";
import { IProfileCommunicationPreferencesProps } from "./ProfileCommunicationPreferences.types";
import {
  getStyles,
  IProfileCommunicationPreferencesStyleProps,
  IProfileCommunicationPreferencesStyles,
} from "./ProfileCommunicationPreferences.styles";
import { ProfileCommunicationPreferencesComponent } from "./ProfileCommunicationPreferences.base";

/**
 * ProfileCommunicationPreferences
 */
export const ProfileCommunicationPreferences = styled<
  IProfileCommunicationPreferencesProps,
  IProfileCommunicationPreferencesStyleProps,
  IProfileCommunicationPreferencesStyles
>(ProfileCommunicationPreferencesComponent, getStyles);
