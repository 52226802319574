import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportArrivalUpdateAssistedControlSubComponentStyles {}

export interface ITransportArrivalUpdateAssistedControlStyles
  extends IAmigoBaseStyles<ITransportArrivalUpdateAssistedControlSubComponentStyles> {}

export interface ITransportArrivalUpdateAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportArrivalUpdateAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportArrivalUpdateAssistedControlStyleProps,
    ITransportArrivalUpdateAssistedControlStyles
  >,
  props?: ITransportArrivalUpdateAssistedControlStyleProps
): [
  IProcessedStyleSet<ITransportArrivalUpdateAssistedControlStyles>,
  ITransportArrivalUpdateAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportArrivalUpdateAssistedControlStyleProps,
  ITransportArrivalUpdateAssistedControlStyles
> = (
  _props: ITransportArrivalUpdateAssistedControlStyleProps
): ITransportArrivalUpdateAssistedControlStyles => {
  return {};
};
