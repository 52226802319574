import { IRectangle, List } from "@fluentui/react";
import * as React from "react";
import { ShimmeredCard } from "../ShimmeredCard";

export interface IShimmeredListProps {
  mobile: boolean;
  getItemCountForPage: (itemIndex?: number, surfaceRect?: IRectangle) => number;
}

export interface IShimmeredListState {}

export class ShimmeredList extends React.Component<
  IShimmeredListProps,
  IShimmeredListState
> {
  constructor(props: IShimmeredListProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const count = this.props.mobile ? 3 : 10;
    return (
      <List
        {...this.props}
        items={new Array(count)}
        getPageSpecification={() => {
          return { itemCount: count };
        }}
        onRenderCell={(_item?: any, _index?: number) => (
          <ShimmeredCard columnWidth={200} />
        )}
      />
    );
  }
}
