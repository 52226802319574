import { ISchemaObject } from "../utils/validator";
import i18n from "src/services/i18n";

export interface IChangePasswordModel {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export const ChangePasswordSchema: ISchemaObject = {
  name: "user",
  plural: "users",
  label: "User",
  fields: {
    currentPassword: {
      name: "currentPassword",
      label: i18n.t("changepwd:currentpassword"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("changepwd:currentpassword:error"),
      type: "string",
    },
    newPassword: {
      name: "newPassword",
      label: i18n.t("changepwd:newpassword"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("changepwd:newpassword:error"),
      type: "string",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: i18n.t("changepwd:confirmpassword"),
      required: true,
      pattern: "newPassword",
      errorMessage: (data: object, fieldName: string): string => {
        const value = data[fieldName];
        return value && value.length > 0
          ? i18n.t("changepwd:confirmpassword:mismatch")
          : i18n.t("changepwd:confirmpassword:error");
      },
      type: "compare",
    },
  },
};
