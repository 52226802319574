import { styled } from "@fluentui/react/lib/Utilities";
import { IProfilePageProps } from "./ProfilePage.types";
import {
  getStyles,
  IProfilePageStyleProps,
  IProfilePageStyles,
} from "./ProfilePage.styles";
import { ProfilePageComponent } from "./ProfilePage.base";

/**
 * ProfilePage description
 */
export const ProfilePage = styled<
  IProfilePageProps,
  IProfilePageStyleProps,
  IProfilePageStyles
>(ProfilePageComponent, getStyles);
