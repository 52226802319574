import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "../MediaQueries";

export interface IProgressPanelSubComponentStyles {}

export interface IProgressPanelStyles
  extends IAmigoBaseStyles<IProgressPanelSubComponentStyles> {
  rootAnimation?: IStyle;
  rootMessage?: IStyle;
  animationContainer?: IStyle;
  messageContainer?: IStyle;
  message?: IStyle;
}

export interface IProgressPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getProgressPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IProgressPanelStyleProps,
    IProgressPanelStyles
  >,
  props?: IProgressPanelStyleProps
): [
  IProcessedStyleSet<IProgressPanelStyles>,
  IProgressPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IProgressPanelStyleProps,
  IProgressPanelStyles
> = (_props: IProgressPanelStyleProps): IProgressPanelStyles => {
  return {
    root: {
      marginTop: "72px",
    },
    rootAnimation: {
      padding: "5px",
    },
    rootMessage: {
      marginLeft: "5px",
      float: "left",
      [MediaQueries.mobile]: {
        marginLeft: "10px",
        float: "unset",
      },
    },
    animationContainer: {
      [MediaQueries.mobile]: {
        width: "144px",
        marginLeft: "10px",
        float: "left",
      },
    },
  };
};
