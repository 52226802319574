import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IMessagesPageSubComponentStyles {}

export interface IMessagesPageStyles
  extends IAmigoBaseStyles<IMessagesPageSubComponentStyles> {}

export interface IMessagesPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessagesPageClassNames = (
  styles?: IStyleFunctionOrObject<IMessagesPageStyleProps, IMessagesPageStyles>,
  props?: IMessagesPageStyleProps
): [
  IProcessedStyleSet<IMessagesPageStyles>,
  IMessagesPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessagesPageStyleProps,
  IMessagesPageStyles
> = (_props: IMessagesPageStyleProps): IMessagesPageStyles => {
  return {};
};
