import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IParticipantTransfertAssistedControlSubComponentStyles {}

export interface IParticipantTransfertAssistedControlStyles
  extends IAmigoBaseStyles<IParticipantTransfertAssistedControlSubComponentStyles> {
  nameText?: IStyle;
}

export interface IParticipantTransfertAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantTransfertAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantTransfertAssistedControlStyleProps,
    IParticipantTransfertAssistedControlStyles
  >,
  props?: IParticipantTransfertAssistedControlStyleProps
): [
  IProcessedStyleSet<IParticipantTransfertAssistedControlStyles>,
  IParticipantTransfertAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantTransfertAssistedControlStyleProps,
  IParticipantTransfertAssistedControlStyles
> = (
  _props: IParticipantTransfertAssistedControlStyleProps
): IParticipantTransfertAssistedControlStyles => {
  return {
    root: ["amigo-ParticipantTransfertAssistedControl", {}],
  };
};
