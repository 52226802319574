import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertBannerProps } from "./AlertBanner.types";
import {
  getStyles,
  IAlertBannerStyleProps,
  IAlertBannerStyles,
} from "./AlertBanner.styles";
import { AlertBannerComponent } from "./AlertBanner.base";

/**
 * AlertBanner
 */
export const AlertBanner = styled<
  IAlertBannerProps,
  IAlertBannerStyleProps,
  IAlertBannerStyles
>(AlertBannerComponent, getStyles);
