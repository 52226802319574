import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightBookingPageSubComponentStyles {}

export interface IFlightBookingPageStyles
  extends IAmigoBaseStyles<IFlightBookingPageSubComponentStyles> {}

export interface IFlightBookingPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingPageStyleProps,
    IFlightBookingPageStyles
  >,
  props?: IFlightBookingPageStyleProps
): [
  IProcessedStyleSet<IFlightBookingPageStyles>,
  IFlightBookingPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingPageStyleProps,
  IFlightBookingPageStyles
> = (props: IFlightBookingPageStyleProps): IFlightBookingPageStyles => {
  return {
    root: [
      "amigo-FlightBookingPage",
      {
        padding: "8px",
        backgroundColor: props.theme.palette.neutralLighter,
      },
    ],
  };
};
