import {
  IPivotStyles,
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IParticipantDetailsPageSubComponentStyles {
  pivot?: IPivotStyles;
}

export interface IParticipantDetailsPageStyles
  extends IAmigoBaseStyles<IParticipantDetailsPageSubComponentStyles> {
  msGridRow?: IStyle;
}

export interface IParticipantDetailsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantDetailsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantDetailsPageStyleProps,
    IParticipantDetailsPageStyles
  >,
  props?: IParticipantDetailsPageStyleProps
): [
  IProcessedStyleSet<IParticipantDetailsPageStyles>,
  IParticipantDetailsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantDetailsPageStyleProps,
  IParticipantDetailsPageStyles
> = (
  props: IParticipantDetailsPageStyleProps
): IParticipantDetailsPageStyles => {
  return {
    root: ["amigo-ParticipantDetailPage", props.tokens.defaultPageRootStyle],
    msGridRow: props.tokens.msGridRow,
    subComponentStyles: {
      pivot: props.tokens.defaultPivotStyles,
    },
  };
};
