import {
  GroupedList,
  DetailsRow,
  IColumn,
  IGroup,
  Link,
  Spinner,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import * as React from "react";
import { IAlertRelation } from "src/models/Alerts/IAlertRelation";
import AlertsService from "src/services/Alerts/AlertsService";
import i18n from "src/services/i18n";
import { getAlertDetailsClassNames } from "./AlertDetails.styles";
import { IAlertDetailsProps, IAlertDetailsState } from "./AlertDetails.types";

export class AlertDetailsComponent extends React.Component<
  IAlertDetailsProps,
  IAlertDetailsState
> {
  protected _selection: Selection;
  constructor(props: IAlertDetailsProps) {
    super(props);
    this.state = {
      progress: true,
      items: [],
      groups: [],
      alertId: this.props.alertId,
    };
    this._selection = new Selection();
    this.refresh();
  }

  protected toGroups = (items: IAlertRelation[]): IGroup[] => {
    if (items.length === 0) return [];

    const groups = items.reduce((catsSoFar, { group, key }) => {
      if (group.startsWith("_")) group = i18n.t(`alerts:defaultgroup:${group}`);
      if (!catsSoFar[group]) catsSoFar[group] = [];
      catsSoFar[group].push(key);
      return catsSoFar;
    }, {});

    const results: IGroup[] = [];
    let i = 0;
    let len = 0;
    for (const e in groups) {
      const count = groups[e].length;
      results.push({
        key: "group" + i++,
        name: e,
        startIndex: len,
        level: 0,
        count: count,
      });
      len += count;
    }
    return results;
  };

  protected _onRenderCell = (
    nestingDepth?: number,
    item?: IAlertRelation,
    itemIndex?: number
  ): JSX.Element => {
    const { route, onEnterpriseChanged } = this.props;
    return (
      <DetailsRow
        styles={{
          root: {
            maxWidth: "100%",
          },
        }}
        columns={[
          {
            key: "name",
            minWidth: 160,
            fieldName: "name",
            name: "Element",
          },
        ]}
        groupNestingDepth={nestingDepth}
        item={item}
        onRenderItemColumn={(
          item?: any,
          _index?: number,
          _column?: IColumn
        ) => {
          return (
            <Link
              onClick={
                item.route
                  ? () => {
                      item.state?.enterpriseId !== undefined &&
                        item.state?.enterpriseId !== null &&
                        item.state.enterpriseId !== -1 &&
                        onEnterpriseChanged(item.state.enterpriseId);
                      route.history.push(
                        item.route,
                        Object.assign({ alert: this.state.alertId }, item.state)
                      );
                    }
                  : undefined
              }
            >
              {item.name}
            </Link>
          );
        }}
        itemIndex={itemIndex ?? 0}
        selection={this._selection}
        selectionMode={SelectionMode.none}
        compact={false}
      />
    );
  };

  protected refresh = (): void => {
    AlertsService.getAlertRelations(this.props.alertId).then((x) => {
      this.setState({
        items: x,
        groups: this.toGroups(x),
        alertId: this.props.alertId,
        progress: false,
      });
      this._selection.setItems(x);
    });
  };

  render(): JSX.Element {
    if (this.state.alertId !== this.props.alertId) {
      this.refresh();
    }
    const { styles } = this.props;
    const { progress } = this.state;
    const [classNames, subComponentStyles] = getAlertDetailsClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        {progress && (
          <div className={classNames.progress}>
            <Spinner
              label={i18n.t("global:pleasewait")}
              labelPosition={"right"}
              styles={subComponentStyles?.progressSpinner}
            />
          </div>
        )}
        {!progress && this.state.items.length > 0 && (
          <p>{i18n.t("alerts:affected")}</p>
        )}
        {!progress && this.state.items.length > 0 && (
          <GroupedList
            onRenderCell={this._onRenderCell}
            items={this.state.items}
            selection={this._selection}
            selectionMode={SelectionMode.none}
            groups={this.state.groups}
            compact={false}
          />
        )}
      </div>
    );
  }
}
