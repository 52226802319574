import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IPivotStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";

export interface IDossierImmigrationDetailPageSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
  pivot?: IPivotStyles;
}

export interface IDossierImmigrationDetailPageStyles
  extends IAmigoBaseStyles<IDossierImmigrationDetailPageSubComponentStyles> {}

export interface IDossierImmigrationDetailPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getImmigrationDetailPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationDetailPageStyleProps,
    IDossierImmigrationDetailPageStyles
  >,
  props?: IDossierImmigrationDetailPageStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationDetailPageStyles>,
  IDossierImmigrationDetailPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationDetailPageStyleProps,
  IDossierImmigrationDetailPageStyles
> = (
  props: IDossierImmigrationDetailPageStyleProps
): IDossierImmigrationDetailPageStyles => {
  return {
    root: [
      "amigo-DossierImmigrationDetailPage",
      props.tokens.defaultPageRootStyle,
    ],
    subComponentStyles: {
      pivot: props.tokens.defaultPivotStyles,
    },
  };
};
