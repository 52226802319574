import { styled } from "@fluentui/react/lib/Utilities";
import { IForgotPasswordControlProps } from "./ForgotPasswordControl.types";
import {
  getStyles,
  IForgotPasswordControlStyleProps,
  IForgotPasswordControlStyles,
} from "./ForgotPasswordControl.styles";
import { ForgotPasswordControlComponent } from "./ForgotPasswordControl.base";

/**
 * ForgotPasswordControl description
 */
export const ForgotPasswordControl = styled<
  IForgotPasswordControlProps,
  IForgotPasswordControlStyleProps,
  IForgotPasswordControlStyles
>(ForgotPasswordControlComponent, getStyles);
