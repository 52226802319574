import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportRequestsPageProps } from "./TransportRequestsPage.types";
import {
  getStyles,
  ITransportRequestsPageStyleProps,
  ITransportRequestsPageStyles,
} from "./TransportRequestsPage.styles";
import { TransportRequestsPageComponent } from "./TransportRequestsPage.base";

/**
 * TransportRequestsPage
 */
export const TransportRequestsPage = styled<
  ITransportRequestsPageProps,
  ITransportRequestsPageStyleProps,
  ITransportRequestsPageStyles
>(TransportRequestsPageComponent, getStyles);
