import axios from "axios";
import { IAddress } from "src/models/IAddress";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import { IParticipant } from "../../models/Participants/IParticipant";
import { IParticipantDetails } from "../../models/Participants/IParticipantDetails";
import AuthService from "../AuthService";
import CountriesService from "../CountriesService";
import DocumentService from "../Documents/DocumentService";
import i18n from "../i18n";
import PreferencesService from "../PreferencesService";

class ParticipantsService {
  private _participants?: IParticipant[] = undefined;
  private _participantsPages: number = 1;

  public init(): void {
    this._participants = undefined;
    this._participantsPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("country", "Pays")
      .replace("birthDate", "DateNaissance")
      .replace("age", "DateNaissance")
      .replace("recall", "DernierRappel")
      .replace("name", "NomComplet")
      .replace("code", "Code");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName)
                .replace(".Description", ".Id")
                .replace("Pays", "PaysId"),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public async countParticipants(
    enterpriseId: string,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ): Promise<number | undefined> {
    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/participants/$count`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 0,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      return (x.data as any)?.count;
    } catch {
      return undefined;
    }
  }

  public async getParticipants(
    enterpriseId: string,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IParticipant[]> {
    const _this = this;
    let currentPage = this._participantsPages;
    let combine: boolean;
    if (next && _this._participants !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/participants`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._participants !== undefined) {
        _this._participantsPages = currentPage;
        _this._participants = _this._participants.concat(items);
      } else {
        _this._participantsPages = 1;
        _this._participants = this._participants = items;
      }
      return _this._participants ?? [];
    } catch (ex) {
      console.log("error");
      return _this._participants ?? [];
    }
  }

  public async getParticipantTypes(): Promise<IChoiceValue[]> {
    const x = await axios.post(
      "/api/participants/types",
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getParticipant(
    enterpriseId: number,
    participantId: number
  ): Promise<IParticipantDetails> {
    const x = await axios.post(
      `api/enterprises/${enterpriseId}/participants/${participantId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getProvinces(countryId: number): Promise<IChoiceValue[]> {
    const x = await axios.post(
      `api/country/${countryId}/provinces`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getRecallStatuses(
    enterpriseId: number
  ): Promise<IChoiceValue[]> {
    const x = await axios.post(
      `api/enterprises/${enterpriseId}/recall-statuses`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async exportParticipants(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ): Promise<boolean> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/participants/export`,
      {
        sortBy: this.translateSortBy(sortBy),
        descending: desc,
        pageIndex: 0,
        pageSize: 1000,
        filters: this.transformFilters(filters),
      },
      AuthService.getAuthHeaderWithBlob()
    );
    if (x.status === 200) {
      DocumentService.openBlob(x);
      return true;
    }
    return false;
  }

  public async getFilters(
    enterpriseId: number,
    reset: boolean
  ): Promise<IQueryFilter[]> {
    const y = await CountriesService.getCountries(enterpriseId);
    const z = await this.getRecallStatuses(enterpriseId);
    const none: IChoiceValue = {
      id: "",
      name: i18n.t("requests:NONE"),
    };
    return PreferencesService.getFiltersOrDefault("participants", reset, () => [
      {
        name: "participants:name",
        fieldName: "name",
        type: "text",
        value: "",
      },
      {
        name: "participants:code",
        fieldName: "code",
        type: "text",
        value: "",
      },
      {
        name: "participants:recall",
        fieldName: "recall",
        type: "choice",
        value: [none].concat(z),
        choices: [none].concat(z),
      },
      {
        name: "participants:country",
        fieldName: "country",
        type: "choice",
        value: y,
        choices: y,
      },
      {
        name: "participants:birthdate:filter",
        fieldName: "birthDate",
        type: "date",
        value: null,
      },
    ]);
  }

  async saveAddress(
    enterpriseId: number,
    workerId: number,
    address: IAddress
  ): Promise<void> {
    const x = await axios.patch(
      `api/enterprises/${enterpriseId}/participants/${workerId}/address`,
      Object.assign(
        {
          enterpriseId: enterpriseId,
          workerId: workerId,
        },
        address
      ),
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }
}

export default new ParticipantsService();
