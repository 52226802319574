import {
  IBasePickerSuggestionsProps,
  IPersonaProps,
  NormalPeoplePicker,
  NormalPeoplePickerBase,
  Selection,
  Text,
  ValidationState,
} from "@fluentui/react";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getAirportSelectorClassNames } from "./AirportSelector.styles";
import {
  IAirportSelectorProps,
  IAirportSelectorState,
} from "./AirportSelector.types";

export class AirportSelectorComponent extends React.Component<
  IAirportSelectorProps,
  IAirportSelectorState
> {
  private picker: React.RefObject<NormalPeoplePickerBase>;

  private _onSelectionChanged = (): void => {};

  constructor(props: IAirportSelectorProps) {
    super(props);

    this.state = {
      selection: new Selection({
        onSelectionChanged: this._onSelectionChanged,
      }),
    };
    this.picker = React.createRef();
  }

  private getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  private validateInput = (input: string): ValidationState => {
    if (input.indexOf("@") !== -1) {
      return ValidationState.valid;
    } else if (input.length > 1) {
      return ValidationState.warning;
    } else {
      return ValidationState.invalid;
    }
  };

  /*
  private doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  private filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return this.props.airports.filter((item) =>
      this.doesTextStartWith(item.text as string, filterText)
    );
  };

  private filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  

  private removeDuplicates(
    personas: IPersonaProps[],
    possibleDupes: IPersonaProps[]
  ) {
    return personas.filter(
      (persona) => !this.listContainsPersona(persona, possibleDupes)
    );
  }

  

  private listContainsPersona = (
    persona: IPersonaProps,
    personas: IPersonaProps[]
  ): boolean => {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.text === persona.text).length > 0;
  };

  */

  private onFilterChanged = (
    _filterText: string,
    _currentPersonas?: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    /*
    if (filterText) {
      let filteredPersonas: IPersonaProps[] = this.filterPersonasByText(
        filterText
      );

      filteredPersonas = this.removeDuplicates(
        filteredPersonas,
        currentPersonas
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return this.filterPromise(filteredPersonas);
    } else {
      return [];
    }
    */
    return this.props.airports;
  };

  componentDidUpdate() {
    const { selectedAirport } = this.props;
    if (
      !selectedAirport &&
      this.picker.current &&
      this.picker.current.items &&
      this.picker.current.items.length > 0
    ) {
      this.setState({
        selection: new Selection({
          onSelectionChanged: this._onSelectionChanged,
        }),
      });
      this.picker.current.items.pop();
    }
  }

  /**
   * Takes in the picker input and modifies it in whichever way
   * the caller wants, i.e. parsing entries copied from Outlook (sample
   * input: "Aaron Reid <aaron>").
   *
   * @param input The text entered into the picker.
   */
  private onInputChange = (input: string): string => {
    const outlookRegEx = /<.*>/g;
    const emailAddress = outlookRegEx.exec(input);

    if (emailAddress && emailAddress[0]) {
      return emailAddress[0].substring(1, emailAddress[0].length - 1);
    }

    return input;
  };

  render(): JSX.Element {
    const {
      styles,
      mobile,
      placeholder,
      selectedAirport,
      onAirportSelected,
      origin,
      error,
      horizontal,
    } = this.props;
    this.picker = React.createRef();
    const [classNames] = getAirportSelectorClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const suggestionProps: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: i18n.t("transports:flights:airportavailable"),
      mostRecentlyUsedHeaderText: i18n.t("transports:flights:airportavailable"),
      noResultsFoundText: i18n.t("transports:flights:noresult"),
      loadingText: i18n.t("transports:flights:loading"),
      showRemoveButtons: false,
      showForceResolve: () => false,
      suggestionsAvailableAlertText: i18n.t(
        "transports:flights:airportavailable"
      ),
      suggestionsContainerAriaLabel: i18n.t(
        "transports:flights:airportavailable"
      ),
      resultsFooter: () => <></>,
      resultsFooterFull: () => <></>,
    };
    const selectedItems = selectedAirport
      ? this.props.airports.filter((x) => x.itemID === selectedAirport)
      : undefined;
    const content: JSX.Element = (
      <>
        <NormalPeoplePicker
          styles={{
            text: {
              minHeight: "47px",
              maxHeight: "47px",
              padding: "3px",
            },
          }}
          itemLimit={1}
          onResolveSuggestions={this.onFilterChanged}
          onEmptyInputFocus={() => this.props.airports}
          getTextFromItem={this.getTextFromItem}
          pickerSuggestionsProps={suggestionProps}
          className={
            origin
              ? "ms-PeoplePicker amigo-Airport-Origin"
              : "ms-PeoplePicker amigo-Airport-Destination"
          }
          data-automation-id={
            origin
              ? "nav:transports:flights:airport:origin"
              : "nav:transports:flights:airport:destination"
          }
          key={"normal"}
          pickerCalloutProps={{
            calloutMaxWidth: mobile ? window.innerWidth : 400,
          }}
          onRemoveSuggestion={undefined}
          onValidateInput={this.validateInput}
          removeButtonAriaLabel={undefined}
          selectedItems={selectedItems}
          inputProps={{
            onBlur: (_ev: React.FocusEvent<HTMLInputElement>) => {},
            onFocus: (_ev: React.FocusEvent<HTMLInputElement>) => {},
            "aria-label": i18n.t("transports:flights:airport:aria"),
            placeholder: this.props.selectedAirport ? undefined : placeholder,
            style: horizontal
              ? { maxHeight: "46px", position: "absolute" }
              : undefined,
          }}
          componentRef={this.picker}
          onInputChange={this.onInputChange}
          resolveDelay={200}
          onChange={(items?: IPersonaProps[]) => {
            const { selection } = this.state;
            const { current } = this.picker;
            if (items) {
              if (items.length === 0) {
                selection.setAllSelected(false);
                selection.setItems([], true);
                selection.setAllSelected(false);
                if (current) {
                  current.setState({ items: [] });
                }
                onAirportSelected(undefined);
              } else {
                const item = items[items.length - 1];
                if (item.itemID) {
                  selection.setAllSelected(false);
                  this.state.selection.setItems(
                    [{ ...item, key: item.itemID }],
                    true
                  );
                  this.state.selection.setAllSelected(true);
                  onAirportSelected(item.itemID);
                }
              }
            } else {
              selection.setAllSelected(false);
              this.state.selection.setItems([], true);
              this.state.selection.setAllSelected(false);
              if (current) {
                current.setState({ items: [] });
              }
              onAirportSelected(undefined);
            }
          }}
          disabled={false}
        />
        {error && (
          <Text
            style={{ color: this.props.theme.palette.redDark }}
            variant={"small"}
          >
            {error}
          </Text>
        )}
      </>
    );

    return (
      <div className={classNames.root}>
        <LabelField
          {...this.props}
          styles={undefined}
          label={
            origin
              ? i18n.t("transports:flights:airport:origin")
              : i18n.t("transports:flights:airport:destination")
          }
          content={content}
        />
      </div>
    );
  }
}
