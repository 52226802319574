import * as React from "react";
import { getImmigrationControlClassNames } from "./DossierImmigrationControl.styles";
import {
  IDossierImmigrationControlState,
  IDossierImmigrationControlProps,
} from "./DossierImmigrationControl.types";
import PreferencesService from "src/services/PreferencesService";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import DossierImmigrationService from "src/services/DossierImmigration/DossierImmigrationService";
import { objectId } from "src/models/IObjectReference";
import {
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import i18n from "src/services/i18n";
import { Selection, Text, MessageBar } from "@fluentui/react";
import { GridView } from "src/components/GridView";
import { IDossierImmigrationFile } from "src/models/DossierImmigration/IDossierImmigrationFile";
import CountriesService from "src/services/CountriesService";
import { IChoiceValue } from "src/models/IQueryFilter";
import { UserCallout } from "src/controls/UserCallout";
import { DossierImmigrationCard } from "../DossierImmigrationCard";
import { SelectionMode } from "@fluentui/react";

export class DossierImmigrationControlComponent extends React.Component<
  IDossierImmigrationControlProps,
  IDossierImmigrationControlState
> {
  constructor(props: IDossierImmigrationControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "immigration",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "immigration",
        false,
        this.defaultFilters
      ),
      items: [],
    };

    DossierImmigrationService.getDossierImmigrationFiles(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  protected getDefaultSelectedYears = (): IChoiceValue[] => {
    const defaultSelectedYears: IChoiceValue[] = [];
    for (let i = new Date().getFullYear(); i <= new Date().getFullYear(); i++) {
      defaultSelectedYears.push({ id: i, name: i.toString() });
    }
    return defaultSelectedYears;
  };

  protected getYears = (): IChoiceValue[] => {
    const numberYearsToShow = 6;

    const years: IChoiceValue[] = [];
    for (
      let i = new Date().getFullYear() - numberYearsToShow;
      i <= new Date().getFullYear();
      i++
    ) {
      years.push({ id: i, name: i.toString() });
    }
    return years;
  };

  private defaultFilters = (): IQueryFilter[] => {
    return [
      {
        name: "dossierimmigration:year",
        fieldName: "year",
        type: "choice",
        value: this.getDefaultSelectedYears(),
        choices: this.getYears(),
      },
    ];
  };

  render(): JSX.Element {
    const { className, styles, route } = this.props;
    const [classNames, subComponentStyles] = getImmigrationControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const message: string = i18n.t("dossierimmigration:warning");
    const beforeList: React.ReactNode = (
      <MessageBar isMultiline={true} ariaLabel="">
        {message}
      </MessageBar>
    );
    return (
      <div className={`${className} ${classNames.root} ms-ImmigrationControl`}>
        <GridView
          {...this.props}
          styles={undefined}
          viewPortOffset={32 /* BeforeList Height */}
          gridTitle="Immigration"
          beforeList={beforeList}
          onItemInvoked={(item: IDossierImmigrationFile) => {
            route.history.push(`/immigration/${item.key}`);
          }}
          fullTextEnabled={false}
          onRenderTile={(
            item: IDossierImmigrationFile,
            columnWidth: number,
            _selection: Selection,
            _callback: (sel: Selection) => void
          ): JSX.Element => {
            /* NOTE: if selection is required one day */
            /*
                selection={selection}
                onSelected={(item: IDossierImmigrationFile) => {
                  ensureSelection(selection, item);
                  selection.setKeySelected(
                    item.key,
                    selection.getSelection().filter((x) => x.key === item.key)
                      .length === 0,
                    false
                  );
                  callback(selection);
                }}

            */
            return (
              <DossierImmigrationCard
                {...this.props}
                styles={subComponentStyles?.gridCard}
                item={item}
                columnWidth={columnWidth}
                onClick={(item: IDossierImmigrationFile) => {
                  route.history.push(`/immigration/${item.key}`);
                }}
              />
            );
          }}
          onSort={(fieldName: string, desc: boolean) => {
            DossierImmigrationService.init();
            DossierImmigrationService.getDossierImmigrationFiles(
              objectId(this.props.enterprise),
              fieldName,
              desc,
              this.state.filters,
              false
            ).then((x) => {
              this.setState({
                options: PreferencesService.asCommittedOptions(
                  GridViewOptionsBuilder.getOptions(
                    this.state.options.mode,
                    fieldName,
                    desc,
                    true
                  ),
                  "immigration"
                ),
                items: x,
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          options={this.state.options}
          onOptionsChanged={(options: IGridViewOptions) => {
            this.setState({
              options: PreferencesService.asCommittedOptions(
                options,
                "immigration"
              ),
            });
          }}
          onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
            DossierImmigrationService.init();
            DossierImmigrationService.getDossierImmigrationFiles(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              filters,
              undefined
            ).then((x) => {
              callback();
              this.setState({
                items: x,
                filters: PreferencesService.asCommittedFilters(
                  filters,
                  "immigration"
                ),
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
            GridViewOptionsBuilder.unfetched(this);
          }}
          onQueryFilters={(reset: boolean) => {
            return CountriesService.getCountries(
              objectId(this.props.enterprise)
            ).then((y) => {
              return PreferencesService.getFiltersOrDefault(
                "immigration",
                reset,
                () => {
                  return [
                    {
                      name: "dossierimmigration:number",
                      fieldName: "name",
                      type: "text",
                      value: "",
                    },
                    {
                      name: "dossierimmigration:title",
                      fieldName: "title",
                      type: "text",
                      value: "",
                    },
                    {
                      name: "dossierimmigration:year",
                      fieldName: "year",
                      type: "choice",
                      value: this.getDefaultSelectedYears(),
                      choices: this.getYears(),
                    },
                    {
                      name: "dossierimmigration:country",
                      fieldName: "country",
                      type: "choice",
                      value: y,
                      choices: y,
                    },
                    {
                      name: "dossierimmigration:filecreation",
                      fieldName: "openedOn",
                      type: "date",
                      value: null,
                    },
                  ];
                }
              );
            });
          }}
          onExport={(filtered: boolean) =>
            DossierImmigrationService.export(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              filtered ? this.state.filters : []
            )
          }
          selectionMode={
            SelectionMode.none /* NOTE: if selection is required one day */
          }
          columns={[
            {
              key: "name",
              name: "dossierimmigration:number",
              minWidth: 55,
            },
            {
              key: "title",
              name: "dossierimmigration:title",
              minWidth: undefined,
            },
            {
              key: "agentLabel",
              name: "dossierimmigration:agent",
              minWidth: 160,
              onRenderCell: (item: IDossierImmigrationFile) => {
                return item.agent === null ? (
                  <Text>{item.agentLabel}</Text>
                ) : (
                  <UserCallout {...this.props} user={item.agent} />
                );
              },
            },
            {
              key: "openedOn",
              name: "dossierimmigration:filecreation",
              minWidth: 140,
            },
            {
              key: "namesRequestDate",
              name: "dossierimmigration:namesrequest",
              minWidth: 185,
            },
            {
              key: "namesReceptionDate",
              name: "dossierimmigration:namesreception",
              minWidth: 185,
            },
            {
              key: "transmissionDate",
              name: "dossierimmigration:transmission",
              minWidth: 155,
            },
            {
              key: "year",
              name: "dossierimmigration:year",
              minWidth: 55,
            },
          ]}
          onDataPaging={() => {
            DossierImmigrationService.getDossierImmigrationFiles(
              objectId(this.props.enterprise),
              this.state.options.sortBy,
              this.state.options.desc,
              this.state.filters,
              true
            ).then((x) => {
              this.setState({
                items: x,
                options: GridViewOptionsBuilder.getFetched(this),
              });
            });
          }}
          items={this.state.items}
          commands={[]}
        />
      </div>
    );
  }
}
