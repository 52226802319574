import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IPeriodPositionCountTextSubComponentStyles {}

export interface IPeriodPositionCountTextStyles
  extends IAmigoBaseStyles<IPeriodPositionCountTextSubComponentStyles> {}

export interface IPeriodPositionCountTextStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPeriodPositionCountTextClassNames = (
  styles?: IStyleFunctionOrObject<
    IPeriodPositionCountTextStyleProps,
    IPeriodPositionCountTextStyles
  >,
  props?: IPeriodPositionCountTextStyleProps
): [
  IProcessedStyleSet<IPeriodPositionCountTextStyles>,
  IPeriodPositionCountTextSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPeriodPositionCountTextStyleProps,
  IPeriodPositionCountTextStyles
> = (
  _props: IPeriodPositionCountTextStyleProps
): IPeriodPositionCountTextStyles => {
  return {};
};
