import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITravelStatusIconSubComponentStyles {}

export interface ITravelStatusIconStyles
  extends IAmigoBaseStyles<ITravelStatusIconSubComponentStyles> {}

export interface ITravelStatusIconStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTravelStatusIconClassNames = (
  styles?: IStyleFunctionOrObject<
    ITravelStatusIconStyleProps,
    ITravelStatusIconStyles
  >,
  props?: ITravelStatusIconStyleProps
): [
  IProcessedStyleSet<ITravelStatusIconStyles>,
  ITravelStatusIconSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITravelStatusIconStyleProps,
  ITravelStatusIconStyles
> = (_props: ITravelStatusIconStyleProps): ITravelStatusIconStyles => {
  return {
    root: ["amigo-TravelStatusIcon", {}],
  };
};
