import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestDetailPageProps } from "./RequestDetailPage.types";
import {
  getStyles,
  IRequestDetailPageStyleProps,
  IRequestDetailPageStyles,
} from "./RequestDetailPage.styles";
import { RequestDetailPageComponent } from "./RequestDetailPage.base";

/**
 * RequestDetailPage description
 */
export const RequestDetailPage = styled<
  IRequestDetailPageProps,
  IRequestDetailPageStyleProps,
  IRequestDetailPageStyles
>(RequestDetailPageComponent, getStyles);
