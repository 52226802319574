import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestsParticipantsCardProps } from "./RequestsParticipantsCard.types";
import {
  getStyles,
  IRequestsParticipantsCardStyleProps,
  IRequestsParticipantsCardStyles,
} from "./RequestsParticipantsCard.styles";
import { RequestsParticipantsCardComponent } from "./RequestsParticipantsCard.base";

/**
 * RequestsParticipantsCard description
 */
export const RequestsParticipantsCard = styled<
  IRequestsParticipantsCardProps,
  IRequestsParticipantsCardStyleProps,
  IRequestsParticipantsCardStyles
>(RequestsParticipantsCardComponent, getStyles);
