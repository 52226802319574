import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface INewPaymentSecureDialogSubComponentStyles {}

export interface INewPaymentSecureDialogStyles
  extends IAmigoBaseStyles<INewPaymentSecureDialogSubComponentStyles> {}

export interface INewPaymentSecureDialogStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentSecureDialogClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentSecureDialogStyleProps,
    INewPaymentSecureDialogStyles
  >,
  props?: INewPaymentSecureDialogStyleProps
): [
  IProcessedStyleSet<INewPaymentSecureDialogStyles>,
  INewPaymentSecureDialogSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentSecureDialogStyleProps,
  INewPaymentSecureDialogStyles
> = (
  _props: INewPaymentSecureDialogStyleProps
): INewPaymentSecureDialogStyles => {
  return {};
};
