import { Persona, PersonaSize } from "@fluentui/react";
import * as React from "react";
import { ISessionProfile } from "src/models/ISessionProfile";
import EnterprisesPageStyles from "src/pages/Enterprises/EnterprisesPage.styles";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import { getAlertBannerClassNames } from "./AlertBanner.styles";
import { IAlertBannerProps, IAlertBannerState } from "./AlertBanner.types";

const themeIndex: number = 6;

export class AlertBannerComponent extends React.Component<
  IAlertBannerProps,
  IAlertBannerState
> {
  private getPersonaSize = () => {
    return this.props.mobile ? PersonaSize.size56 : PersonaSize.size100;
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getAlertBannerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const session: ISessionProfile | null = AuthService.getUserInfo();
    if (session === null) return <div></div>;
    return (
      <div
        className={classNames.root}
        style={{
          position: "relative",
          display: "block",
          minHeight: mobile ? "160px" : "20vh",
        }}
      >
        <div
          className={`EnterpriseBackground amigo-Banner-${(
            themeIndex + 1
          ).toString()}`}
        ></div>
        <div
          className={
            themeIndex === 1 || themeIndex === 6 ? undefined : "WhitePersona"
          }
          style={{
            position: "absolute",
            padding: "10px 10px",
            width: "95%",
            left: 0,
            bottom: 0,
          }}
        >
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            text={i18n.t("menu:alerts")}
            styles={{
              primaryText: {
                fontWeight: "bold",
                fontSize: mobile ? "20px" : "28px",
                color: EnterprisesPageStyles.themeColor(themeIndex),
              },
              secondaryText: {
                fontWeight: "bold",
                color: EnterprisesPageStyles.themeColor(themeIndex),
              },
              tertiaryText: {
                color: EnterprisesPageStyles.themeColor(themeIndex),
              },
            }}
            coinProps={{
              styles: {
                coin: {
                  display: "none",
                },
              },
            }}
            color={EnterprisesPageStyles.themeColor(themeIndex)}
            secondaryText={`${session.firstName} ${session.lastName}`}
            tertiaryText={""}
            size={this.getPersonaSize()}
          />
        </div>
        <div></div>
      </div>
    );
  }
}
