import Moment from "moment";
import * as React from "react";
import { IObjectReference } from "../models/IObjectReference";
import { ISessionProfile } from "../models/ISessionProfile";
import AuthService from "../services/AuthService";
import i18n from "../services/i18n";
import MessageService from "../services/Messages/MessagesService";
import { IAppProps, IAppState } from "./App.types";

export class AppComponentImpl extends React.Component<IAppProps, IAppState> {
  constructor(props: IAppProps) {
    super(props);
    this.state = this.getAppState();
  }

  public getDefaultEnterprise(): IObjectReference {
    const session = AuthService.getUserInfo();
    if (session === null) return { key: "", name: "" };
    return session.enterprises[0];
  }

  public getAppState = (): IAppState => {
    const val = localStorage.getItem("enterprise");
    return {
      enterprise:
        val === undefined || val === null || val === "undefined"
          ? this.getDefaultEnterprise()
          : JSON.parse(val),
      language: i18n.getLanguage(),
    };
  };

  public maintenanceMessage = (): string => {
    const session = AuthService.getUserInfo();
    if (session === null) return "";
    if (session.maintenance === undefined) return "";
    if (session.maintenance === null) return "";
    const start: string = Moment(session.maintenance.start).format(
      "YYYY-MM-DD HH:mm"
    );
    const end: string = Moment(session.maintenance.end).format(
      "YYYY-MM-DD HH:mm"
    );
    return i18n.formatString(i18n.t("maintenance:message"), start, end);
  };

  /**
   * User Language Change Event
   * @param session
   * @param lang
   */
  private _handleLoggedInLanguageChange = (
    session: ISessionProfile,
    lang: string
  ) => {
    if (
      this.state.enterprise === undefined ||
      this.state.enterprise.key === ""
    ) {
      localStorage.setItem(
        "enterprise",
        JSON.stringify(session.enterprises[0])
      );
      this.setState({
        language: lang,
        enterprise: session.enterprises[0],
      });
    } else {
      this.setState({
        language: lang,
      });
    }
  };

  public onGetNotification(): Promise<number> {
    return MessageService.unread();
  }

  public onLanguageChanged = (lang: string) => {
    const session = AuthService.getUserInfo();
    const currentLanguage = i18n.getLanguage();
    i18n.setLanguage(lang);
    if (session != null) {
      if (currentLanguage !== undefined && currentLanguage === lang) {
        this.setState({
          language: lang,
        });
      } else {
        AuthService.updateLanguage(lang)
          .then((_x) => {
            this._handleLoggedInLanguageChange(session, lang);
          })
          .catch((_x) => {
            this._handleLoggedInLanguageChange(session, lang);
          });
      }
    } else {
      this.setState({
        language: lang,
      });
    }
  };

  public onEnterpriseChanged = (enterprise: IObjectReference) => {
    const e = enterprise ?? this.getDefaultEnterprise();

    localStorage.setItem("enterprise", JSON.stringify(e));
    this.setState({
      enterprise: e,
    });
    const session = AuthService.getUserInfo();
    if (session != null) {
      this._handleLoggedInLanguageChange(session, this.state.language);
    }
  };
}
