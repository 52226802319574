import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestHistoryCardProps } from "./RequestHistoryCard.types";
import {
  getStyles,
  IRequestHistoryCardStyleProps,
  IRequestHistoryCardStyles,
} from "./RequestHistoryCard.styles";
import { RequestHistoryCardComponent } from "./RequestHistoryCard.base";

/**
 * RequestHistoryCard description
 */
export const RequestHistoryCard = styled<
  IRequestHistoryCardProps,
  IRequestHistoryCardStyleProps,
  IRequestHistoryCardStyles
>(RequestHistoryCardComponent, getStyles);
