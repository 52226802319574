import { DefaultPalette, Persona, PersonaSize } from "@fluentui/react";
import * as React from "react";
import BrowserService from "src/services/BrowserService";
import { getGenericPageHeaderClassNames } from "./GenericPageHeader.styles";
import {
  IGenericPageHeaderProps,
  IGenericPageHeaderState,
} from "./GenericPageHeader.types";

const defaultThemeIndex = 1;

class EnterprisesPageStylesImpl {
  public themeColor = (index: number): string => {
    return [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
    ][index];
  };

  public themeImageSize = (index: number, mobile: boolean): string => {
    return [
      "cover",
      BrowserService.isTabletOrMobile() ? "cover" : "100% 170%",
      "cover",
      mobile ? "cover" : "cover",
    ][index];
  };

  public themeImagePosition = (
    index: number,
    _mobile: boolean
  ): string | undefined => {
    return ["", "", "center", "", "", "", "", ""][index];
  };

  public themeOpacity = (_index: number) => {
    return undefined;
    /*
    return ["1.0", "0.7", "0.5", "0.5", "0.7", "0.7", "0.7", "0.7", "0.7"][
      index
    ];
    */
  };
}

const EnterprisesPageStyles = new EnterprisesPageStylesImpl();

export class GenericPageHeaderComponent extends React.Component<
  IGenericPageHeaderProps,
  IGenericPageHeaderState
> {
  constructor(props: IGenericPageHeaderProps) {
    super(props);
    this.state = {};
  }

  private getPersonaSize = () => {
    return this.props.mobile ? PersonaSize.size56 : PersonaSize.size100;
  };

  render(): JSX.Element {
    const { styles, mobile, themeIndex } = this.props;
    const [classNames] = getGenericPageHeaderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const index = themeIndex ?? defaultThemeIndex;
    return (
      <div
        className={classNames.root}
        style={{
          position: "relative",
          display: "block",
          minHeight: mobile ? "165px" : "20vh",
        }}
      >
        <div
          className={`EnterpriseBackground amigo-Banner-${(
            index + 1
          ).toString()}`}
          style={{
            opacity: EnterprisesPageStyles.themeOpacity(index),
            backgroundImage:
              "url('/img/banner_" + (index + 1).toString() + ".jpg')",
            backgroundSize: EnterprisesPageStyles.themeImageSize(
              index,
              this.props.mobile
            ),
            backgroundPosition: EnterprisesPageStyles.themeImagePosition(
              index,
              this.props.mobile
            ),
            backgroundRepeat: "no-repeat",
            overflow: "hidden",
          }}
        ></div>
        <div
          className={index === 1 || index === 6 ? undefined : "WhitePersona"}
          style={{
            position: "absolute",
            padding: "10px 10px",
            width: "95%",
            left: 0,
            bottom: 0,
          }}
        >
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            text={this.props.title}
            styles={{
              primaryText: {
                fontWeight: "bold",
                fontSize: mobile ? "20px" : "28px",
                color: `${EnterprisesPageStyles.themeColor(index)}!important`,
                ":hover": {
                  color: "#000!important",
                },
              },
              secondaryText: {
                fontWeight: "bold",
                color: `${EnterprisesPageStyles.themeColor(index)}!important`,
              },
              tertiaryText: {
                color: EnterprisesPageStyles.themeColor(index),
              },
            }}
            coinProps={{
              styles: {
                coin: {
                  display: "none",
                },
              },
            }}
            color={EnterprisesPageStyles.themeColor(index)}
            secondaryText={
              this.props.enterprise === null ? "" : this.props.enterprise.name
            }
            tertiaryText={""}
            size={this.getPersonaSize()}
          />
        </div>
        <div></div>
      </div>
    );
  }
}
