import {
  MessageBar,
  MessageBarType,
  Selection,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { CreditCardIcon } from "src/components/CreditCardIcon";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import { IPaymentElement } from "src/models/Payments/IPaymentElement";
import PaymentsService from "src/services/Billing/PaymentsService";
import i18n from "src/services/i18n";
import PreferencesService from "src/services/PreferencesService";
import { PaymentElementCard } from "../PaymentElementCard";
import { getPaymentsElementsControlClassNames } from "./PaymentsElementsControl.styles";
import {
  IPaymentsElementsControlProps,
  IPaymentsElementsControlState,
} from "./PaymentsElementsControl.types";

export class PaymentsElementsControlComponent extends React.Component<
  IPaymentsElementsControlProps,
  IPaymentsElementsControlState
> {
  constructor(props: IPaymentsElementsControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "payments",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "paymentDate",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "payments",
        false,
        () => []
      ),
      items: [],
    };
    PaymentsService.init();
    PaymentsService.getPaymentsElements(
      objectId(this.props.enterprise),
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  render(): JSX.Element {
    const { styles, mobile, tablet, route } = this.props;
    const [classNames, subComponentStyles] =
      getPaymentsElementsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{
            root: {
              paddingLeft: mobile ? "4px" : "10px",
            },
          }}
        >
          {i18n.t("billing:warning:1")}
        </MessageBar>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : mobile || tablet
                    ? "10px"
                    : "20px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={undefined}
                summaryColumCount={2}
                selectionMode={SelectionMode.single}
                onItemInvoked={(item: IPaymentElement) => {
                  this.setState({
                    paymentElement: item,
                  });
                }}
                onRenderTile={(
                  item: IPaymentElement,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <PaymentElementCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onSelected={(item: IPaymentElement) => {
                        ensureSelection(selection, item);
                        selection.setKeySelected(
                          item.key,
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length === 0,
                          false
                        );
                        callback(selection);
                      }}
                      onClick={(item: IPaymentElement) => {
                        this.setState({ paymentElement: item });
                      }}
                    />
                  );
                }}
                onSort={(fieldName: string, desc: boolean) => {
                  PaymentsService.init();
                  PaymentsService.getPaymentsElements(
                    objectId(this.props.enterprise),
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: PreferencesService.asCommittedOptions(
                        GridViewOptionsBuilder.getOptions(
                          this.state.options.mode,
                          fieldName,
                          desc,
                          true
                        ),
                        "payments"
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                options={this.state.options}
                onOptionsChanged={(options: IGridViewOptions) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      options,
                      "payments"
                    ),
                  });
                }}
                columns={[
                  {
                    key: "name",
                    name: "billing:payments:paymentnumber",
                    minWidth: 180,
                    maxWidth: 180,
                  },
                  {
                    key: "paymentDate",
                    name: "billing:payments:paymentdate",
                    minWidth: 150,
                    maxWidth: 180,
                  },
                  {
                    key: "bills",
                    name: "billing:payments:bills",
                    minWidth: undefined,
                  },
                  {
                    key: "paymentType",
                    name: "billing:payments:paymenttype",
                    minWidth: 150,
                    onRenderCell: (item: IPaymentElement) => {
                      return (
                        <Stack
                          onClick={() => {
                            this.setState({
                              paymentElement: item,
                            });
                          }}
                          style={{
                            cursor: "pointer",
                            fontWeight: item.late ? "bold" : "normal",
                          }}
                          horizontal
                          tokens={{ childrenGap: 10 }}
                        >
                          <CreditCardIcon
                            marginTop={3}
                            card={item.paymentType}
                          />
                          <Text>{item.paymentType}</Text>
                        </Stack>
                      );
                    },
                  },
                  {
                    key: "confirmation",
                    name: "billing:payments:confirmation",
                    minWidth: 175,
                  },
                  {
                    key: "total",
                    name: "billing:payments:total",
                    minWidth: 125,
                  },
                ]}
                onDataPaging={() => {
                  PaymentsService.getPaymentsElements(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  PaymentsService.init();
                  PaymentsService.getPaymentsElements(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: PreferencesService.asCommittedFilters(
                        filters,
                        "payments"
                      ),
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                onQueryFilters={(reset: boolean) => {
                  return new Promise<IQueryFilter[]>((resolve) => {
                    resolve(
                      PreferencesService.getFiltersOrDefault(
                        "payments",
                        reset,
                        () => [
                          {
                            name: "billing:payments:paymentnumber",
                            fieldName: "name",
                            type: "text",
                            value: "",
                          },
                          {
                            name: "billing:payments:bills",
                            fieldName: "bills",
                            type: "text",
                            value: "",
                          },
                          {
                            name: "billing:payments:confirmation",
                            fieldName: "confirmation",
                            type: "text",
                            value: "",
                          },
                          {
                            name: "billing:payments:paymentdate",
                            fieldName: "paymentDate",
                            type: "date",
                            value: null,
                          },
                        ]
                      )
                    );
                  });
                }}
                items={this.state.items}
                commands={[
                  {
                    key: "new",
                    name: i18n.t("billing:payments:newpayment"),
                    icon: "Add",
                    confirmTitle: undefined,
                    confirmMessage: () => "",
                    onClick: () => {
                      route.history.push("/facturation/paiements/nouveau");
                    },
                  },
                  {
                    key: "details",
                    name: "Details",
                    icon: "EntitlementPolicy",
                    confirmTitle: undefined,
                    confirmMessage: () => "",
                    selectionRequired: true,
                    onClick: (items: any[]) => {
                      const { id } = items[0];
                      route.history.push(`/facturation/paiements/${id}`);
                    },
                  },
                ]}
                isDefaultOptions={(this.state.filters ?? []).length === 0}
                onExport={(filtered: boolean) =>
                  PaymentsService.exportPayments(
                    objectId(this.props.enterprise),
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filtered ? this.state.filters : []
                  )
                }
                openedItem={this.state.paymentElement}
                entityPanelProps={{
                  ...this.props,
                  styles: undefined,
                  isOpen: this.state.paymentElement !== undefined,
                  "data-automation-id": "nvx:payments:itempanel",
                  elementName: i18n.t("billing:payment:element"),
                  title: this.state.paymentElement?.name,
                  headerIconName: "PaymentCard",
                  onDismiss: () => {
                    this.setState({
                      paymentElement: undefined,
                    });
                  },
                  children: (
                    <>
                      {this.state.paymentElement !== undefined && (
                        <div style={{ marginTop: "30px" }}>
                          <Stack horizontal tokens={{ childrenGap: 10 }}>
                            <Stack grow>
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:paymentnumber")}
                                content={this.state.paymentElement?.name}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:paymentdate")}
                                content={this.state.paymentElement?.paymentDate}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:paymenttype")}
                                content={
                                  <Stack
                                    horizontal
                                    tokens={{ childrenGap: 10 }}
                                  >
                                    <CreditCardIcon
                                      marginTop={3}
                                      card={
                                        this.state.paymentElement?.paymentType
                                      }
                                    />
                                    <Text>
                                      {this.state.paymentElement?.paymentType}
                                    </Text>
                                  </Stack>
                                }
                              />
                            </Stack>
                            <Stack grow>
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:bills")}
                                content={this.state.paymentElement?.bills}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:total")}
                                content={this.state.paymentElement?.total}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t("billing:payments:confirmation")}
                                content={
                                  this.state.paymentElement?.confirmation
                                }
                              />
                            </Stack>
                          </Stack>
                        </div>
                      )}
                    </>
                  ),
                }}
              />
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }
}
