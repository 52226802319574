import {
  CommandBar,
  DefaultPalette,
  Icon,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
  DefaultButton,
} from "@fluentui/react";
import * as React from "react";
import { CreditCardIcon } from "src/components/CreditCardIcon";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { NewPaymentBillingSummary } from "../NewPaymentBillingSummary";
import { getPaymentConfirmationPageClassNames } from "../PaymentConfirmationPage/PaymentConfirmationPage.styles";
import {
  IPaymentErrorPageProps,
  IPaymentErrorPageState,
} from "./PaymentErrorPage.types";

export class PaymentErrorPageComponent extends React.Component<
  IPaymentErrorPageProps,
  IPaymentErrorPageState
> {
  render(): JSX.Element {
    const {
      styles,
      mobile,
      tablet,
      label,
      subtotal,
      tps,
      tvq,
      total,
      card,
      cardType,
      results,
    } = this.props;
    const [classNames] = getPaymentConfirmationPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    if (!results) return <div className={classNames.root}></div>;
    return (
      <div className={classNames.root}>
        <Stack horizontal={!mobile} tokens={{ childrenGap: 20 }}>
          <div>
            <div
              style={{ height: mobile ? "5px" : tablet ? "20px" : "30px" }}
            ></div>
            <Stack horizontal tokens={{ childrenGap: mobile ? 10 : 20 }}>
              <Icon
                iconName={"StatusErrorFull"}
                color={DefaultPalette.red}
                styles={{
                  root: {
                    fontSize: "42px",
                    color: DefaultPalette.red,
                  },
                }}
              />
              <Text variant={mobile ? "xLarge" : "xxLarge"}>
                {label ?? i18n.t("billing:payments:paymenterror:title")}
              </Text>
            </Stack>
            <div style={{ height: mobile ? "20px" : "40px" }}></div>
            <MessageBar messageBarType={MessageBarType.error}>
              {i18n.t("billing:payments:paymenterror:refused")}
            </MessageBar>
            <div style={{ height: mobile ? "20px" : "40px" }}></div>
            <Stack horizontal tokens={{ childrenGap: mobile ? 10 : 20 }}>
              <Stack tokens={{ childrenGap: mobile ? 10 : 20 }}>
                <LabelField
                  {...this.props}
                  styles={undefined}
                  label={i18n.t("billing:payments:confirmation:date")}
                  content={results.date}
                />
                <LabelField
                  {...this.props}
                  styles={undefined}
                  label={i18n.t("billing:payments:confirmation:transaction")}
                  content={results.transaction}
                />
              </Stack>
              {card && (
                <Stack tokens={{ childrenGap: mobile ? 10 : 20 }}>
                  <LabelField
                    {...this.props}
                    styles={undefined}
                    label={i18n.t("billing:payments:confirmation:card")}
                    content={
                      <Stack horizontal tokens={{ childrenGap: 10 }}>
                        <CreditCardIcon card={cardType} />
                        <Text>{card}</Text>
                      </Stack>
                    }
                  />
                </Stack>
              )}
            </Stack>
          </div>
          <div>
            {!mobile && (
              <div
                style={{ height: mobile ? "10px" : tablet ? "16px" : "26px" }}
              ></div>
            )}
            <NewPaymentBillingSummary
              {...this.props}
              styles={undefined}
              subtotal={subtotal}
              tps={tps}
              tvq={tvq}
              total={total}
            />
          </div>
        </Stack>
        {mobile && (
          <Stack>
            <DefaultButton
              key="newpayment"
              width="100%"
              iconProps={{ iconName: "Undo" }}
              text={i18n.t("billing:payments:retrypaymentagain")}
              onClick={() => {
                const { route } = this.props;
                route.history.push("/reload", {
                  location: "/facturation/paiements/nouveau",
                });
              }}
            />
            <DefaultButton
              key="billing"
              width="100%"
              iconProps={{ iconName: "Script" }}
              text={i18n.t("billing:payments:seebills")}
              onClick={() => {
                const { route } = this.props;
                route.history.push("/facturation/factures");
              }}
            />
            <DefaultButton
              key="payments"
              width="100%"
              iconProps={{ iconName: "PaymentCard" }}
              text={i18n.t("billing:payments:seepayments")}
              onClick={() => {
                const { route } = this.props;
                route.history.push("/facturation/paiements");
              }}
            />
          </Stack>
        )}
        {!mobile && (
          <Stack>
            <CommandBar
              styles={{
                root: {
                  paddingTop: "20px",
                  paddingLeft: "0",
                },
              }}
              items={[
                {
                  key: "billing",
                  iconProps: { iconName: "Script" },
                  name: i18n.t("billing:payments:seebills"),
                  onClick: () => {
                    const { route } = this.props;
                    route.history.push("/facturation/factures");
                  },
                },
                {
                  key: "payments",
                  iconProps: { iconName: "PaymentCard" },
                  name: i18n.t("billing:payments:seepayments"),
                  onClick: () => {
                    const { route } = this.props;
                    route.history.push("/facturation/paiements");
                  },
                },
                {
                  key: "newpayment",
                  iconProps: { iconName: "Add" },
                  name: i18n.t("billing:payments:retrypaymentagain"),
                  onClick: () => {
                    const { route } = this.props;
                    route.history.push("/reload", {
                      location: "/facturation/paiements/nouveau",
                    });
                  },
                },
              ]}
            />
          </Stack>
        )}
      </div>
    );
  }
}
