import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IDocumentsPageSubComponentStyles {}

export interface IDocumentsPageStyles
  extends IAmigoBaseStyles<IDocumentsPageSubComponentStyles> {}

export interface IDocumentsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDocumentsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentsPageStyleProps,
    IDocumentsPageStyles
  >,
  props?: IDocumentsPageStyleProps
): [
  IProcessedStyleSet<IDocumentsPageStyles>,
  IDocumentsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentsPageStyleProps,
  IDocumentsPageStyles
> = (props: IDocumentsPageStyleProps): IDocumentsPageStyles => {
  return {
    root: [
      "amigo-DocumentsPage",
      { backgroundColor: props.theme.palette.neutralLighter },
      props.tokens.defaultPageRootStyle,
    ],
  };
};
