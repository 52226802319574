import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantPositionCardProps } from "./ParticipantPositionCard.types";
import {
  getStyles,
  IParticipantPositionCardStyleProps,
  IParticipantPositionCardStyles,
} from "./ParticipantPositionCard.styles";
import { ParticipantPositionCardComponent } from "./ParticipantPositionCard.base";

/**
 * ParticipantPositionCard
 */
export const ParticipantPositionCard = styled<
  IParticipantPositionCardProps,
  IParticipantPositionCardStyleProps,
  IParticipantPositionCardStyles
>(ParticipantPositionCardComponent, getStyles);
