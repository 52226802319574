import { styled } from "@fluentui/react/lib/Utilities";
import { ITravelStatusIconProps } from "./TravelStatusIcon.types";
import {
  getStyles,
  ITravelStatusIconStyleProps,
  ITravelStatusIconStyles,
} from "./TravelStatusIcon.styles";
import { TravelStatusIconComponent } from "./TravelStatusIcon.base";

/**
 * TravelStatusIcon
 */
export const TravelStatusIcon = styled<
  ITravelStatusIconProps,
  ITravelStatusIconStyleProps,
  ITravelStatusIconStyles
>(TravelStatusIconComponent, getStyles);
