import { styled } from "@fluentui/react/lib/Utilities";
import { IDepartureAssistedParticipantCardProps } from "./DepartureAssistedParticipantCard.types";
import {
  getStyles,
  IDepartureAssistedParticipantCardStyleProps,
  IDepartureAssistedParticipantCardStyles,
} from "./DepartureAssistedParticipantCard.styles";
import { DepartureAssistedParticipantCardComponent } from "./DepartureAssistedParticipantCard.base";

/**
 * DepartureAssistedParticipantCard
 */
export const DepartureAssistedParticipantCard = styled<
  IDepartureAssistedParticipantCardProps,
  IDepartureAssistedParticipantCardStyleProps,
  IDepartureAssistedParticipantCardStyles
>(DepartureAssistedParticipantCardComponent, getStyles);
