import { IDepartureRequestParticipant } from "./IDepartureRequestParticipant";
import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface INewDepartureRequest {
  requestId?: number;
  requestNumber?: string;
  requestDate?: Date;
  returnDate?: Date;
  reason?: string;
  preauth: boolean;
  originCity?: number;
  originCityName?: string;
  landTransportIncluded: boolean;
  selectedFlight?: number;
  consentname?: string;
  participants: IDepartureRequestParticipant[];
}

export const NewDepartureRequestSchema: ISchemaObject = {
  name: "newdeparture",
  plural: "newdepartures",
  label: "NewDeparture",
  fields: {
    requestId: {
      name: "requestId",
      label: i18n.t("messages:assisted:newdeparture:requestnumber"),
      required: true,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:newdeparture:requestnumber:error"
      ),
      type: "number",
    },
    originCity: {
      name: "originCity",
      label: i18n.t("messages:assisted:newdeparture:origincity"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:newdeparture:origincity:error"),
      type: "number",
    },
    requestDate: {
      name: "requestDate",
      label: i18n.t("messages:assisted:newdeparture:requestdate"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:newdeparture:requestdate:error"),
      type: "string",
    },
  },
};
