import { Selection, SelectionMode, Stack, Text } from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { GridView } from "src/components/GridView";
import {
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { objectId } from "src/models/IObjectReference";
import { IParticipant } from "src/models/Participants/IParticipant";
import ParticipantsService from "src/services/Participants/ParticipantsService";
import PreferencesService from "src/services/PreferencesService";
import { ParticipantCard } from "../ParticipantCard";
import { getParticipantsControlClassNames } from "./ParticipantsControl.styles";
import {
  IParticipantsControlProps,
  IParticipantsControlState,
} from "./ParticipantsControl.types";

export class ParticipantsControlComponent extends React.Component<
  IParticipantsControlProps,
  IParticipantsControlState
> {
  constructor(props: IParticipantsControlProps) {
    super(props);
    ParticipantsService.init();
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "participants",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "name",
        false
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "participants",
        false,
        () => []
      ),
      items: [],
    };
    ParticipantsService.countParticipants(
      this.props.enterprise.key?.toString() ?? "",
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters
    ).then((x) => this.setState({ totalCount: x }));
    ParticipantsService.getParticipants(
      this.props.enterprise.key?.toString() ?? "",
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  render(): JSX.Element {
    const { className, styles, mobile, route } = this.props;
    const [classNames, subComponentStyles] = getParticipantsControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={`${className} ${classNames.root}`}>
        <Card
          styles={{
            root: {
              backgroundColor:
                this.state.options.mode === GridViewMode.tiles
                  ? undefined
                  : "#fff",
              margin:
                this.state.options.mode === GridViewMode.tiles
                  ? undefined
                  : "0 4px 10px 4px",
              padding:
                this.state.options.mode === GridViewMode.tiles
                  ? undefined
                  : "0 10px 10px 10px",
              height: "auto",
              maxHeight: "unset",
              width: "100%",
              maxWidth: "unset",
            },
          }}
        >
          <Card.Item>
            <GridView
              {...this.props}
              styles={subComponentStyles?.gridView}
              totalCount={this.state.totalCount}
              selectionMode={SelectionMode.none}
              onItemInvoked={(item: IParticipant) => {
                ParticipantsService.init();
                route.history.push(`/participants/${item.id}`);
              }}
              onRenderTile={(
                item: IParticipant,
                columnWidth: number,
                selection: Selection,
                _callback: (sel: Selection) => void
              ): JSX.Element => {
                return (
                  <ParticipantCard
                    {...this.props}
                    styles={subComponentStyles?.gridCard}
                    item={item}
                    columnWidth={columnWidth}
                    selection={selection}
                    onClick={(item: IParticipant) => {
                      route.history.push(`/participants/${item.id}`);
                    }}
                  />
                );
              }}
              onExport={(filtered) =>
                ParticipantsService.exportParticipants(
                  objectId(this.props.enterprise),
                  this.state.options.sortBy,
                  this.state.options.desc,
                  filtered ? this.state.filters : []
                )
              }
              onSort={(fieldName: string, desc: boolean) => {
                ParticipantsService.init();
                ParticipantsService.getParticipants(
                  this.props.enterprise.key?.toString() ?? "",
                  fieldName,
                  desc,
                  this.state.filters,
                  false
                ).then((x) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      GridViewOptionsBuilder.getOptions(
                        this.state.options.mode,
                        fieldName,
                        desc,
                        true
                      ),
                      "participants"
                    ),
                    items: x,
                  });
                });
                GridViewOptionsBuilder.unfetched(this);
              }}
              options={this.state.options}
              onOptionsChanged={(options: IGridViewOptions) => {
                this.setState({
                  options: PreferencesService.asCommittedOptions(
                    options,
                    "participants"
                  ),
                });
              }}
              columns={[
                {
                  key: "code",
                  name: "participants:code",
                  minWidth: mobile ? 65 : 120,
                  maxWidth: 120,
                },
                {
                  key: "name",
                  name: "participants:name",
                  minWidth: 300,
                },
                {
                  key: "country",
                  name: "participants:country",
                  minWidth: 150,
                  maxWidth: 190,
                  onRenderCell: (item: IParticipant) => {
                    return (
                      <Stack horizontal tokens={{ childrenGap: 3 }}>
                        <Text>{item.country}</Text>
                        <CountryIcon
                          {...this.props}
                          styles={undefined}
                          country={item.country}
                        />
                      </Stack>
                    );
                  },
                },
                {
                  key: "recall",
                  name: "participants:recall",
                  minWidth: 180,
                  maxWidth: 180,
                },
                {
                  key: "birthDate",
                  name: "participants:birthdate",
                  minWidth: 150,
                  maxWidth: 150,
                },
                {
                  key: "age",
                  name: "participants:age",
                  minWidth: 100,
                  maxWidth: 100,
                },
              ]}
              onDataPaging={() => {
                ParticipantsService.getParticipants(
                  this.props.enterprise.key?.toString() ?? "",
                  this.state.options.sortBy,
                  this.state.options.desc,
                  this.state.filters,
                  true
                ).then((x) => {
                  this.setState({
                    items: x,
                    options: GridViewOptionsBuilder.getFetched(this),
                  });
                });
              }}
              onApplyFilters={(
                filters: IQueryFilter[],
                callback: () => void
              ) => {
                ParticipantsService.init();
                ParticipantsService.countParticipants(
                  this.props.enterprise.key?.toString() ?? "",
                  this.state.options.sortBy,
                  this.state.options.desc,
                  filters
                ).then((x) => this.setState({ totalCount: x }));
                ParticipantsService.getParticipants(
                  this.props.enterprise.key?.toString() ?? "",
                  this.state.options.sortBy,
                  this.state.options.desc,
                  filters,
                  undefined
                ).then((x) => {
                  callback();
                  this.setState({
                    items: x,
                    filters: PreferencesService.asCommittedFilters(
                      filters,
                      "participants"
                    ),
                    options: GridViewOptionsBuilder.getFetched(this),
                  });
                });
                GridViewOptionsBuilder.unfetched(this);
              }}
              onQueryFilters={(reset: boolean) => {
                return ParticipantsService.getFilters(
                  objectId(this.props.enterprise),
                  reset
                );
              }}
              items={this.state.items}
              summaryColumStartIndex={0}
              summaryColumCount={2}
              commands={[]}
            />
          </Card.Item>
        </Card>
      </div>
    );
  }
}
