import { ContentState, EditorState } from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import { stateFromHTML } from "draft-js-import-html";

class RichTextService {
  public getHtml(state: EditorState): string {
    return stateToHTML(state.getCurrentContent() as any, {
      defaultBlockTag: "div",
    });
  }

  public getContentState(html: string): ContentState {
    return stateFromHTML(
      html.replace(/<div>/g, "<p>").replace(/<\/div>/g, "</p>")
    );
  }
}

export default new RichTextService();
