import { Icon, Stack, Text, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { Link } from "react-router-dom";
import { GridViewCard } from "src/components/GridViewCard";
import { getParticipantPositionCardClassNames } from "./ParticipantPositionCard.styles";
import {
  IParticipantPositionCardProps,
  IParticipantPositionCardState,
} from "./ParticipantPositionCard.types";

export class ParticipantPositionCardComponent extends React.Component<
  IParticipantPositionCardProps,
  IParticipantPositionCardState
> {
  constructor(props: IParticipantPositionCardProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantPositionCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <GridViewCard
          {...this.props}
          styles={subComponentStyles?.gridViewCard}
          title={item.name}
          columnWidth={columnWidth}
          footerText={item.enterpriseName}
          footerIconName={footerIconName ?? "WebAppBuilderModule"}
          selected={false}
          onClick={
            this.props.onClick
              ? () => {
                  this.props.onClick && this.props.onClick(item);
                }
              : undefined
          }
          onSelected={undefined}
          selectable={false}
          icons={
            <>
              <TooltipHost content={item.name}>
                <Icon iconName="Assign" styles={subComponentStyles?.topIcon} />
              </TooltipHost>
            </>
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <Link to={`/demandes/${item.requestId}`}>
              <Text>{item.requestNumber}</Text>
            </Link>
          </Stack>
        </GridViewCard>
      </div>
    );
  }
}
