import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightSearchResultsSubComponentStyles {}

export interface IFlightSearchResultsStyles
  extends IAmigoBaseStyles<IFlightSearchResultsSubComponentStyles> {}

export interface IFlightSearchResultsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchResultsClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchResultsStyleProps,
    IFlightSearchResultsStyles
  >,
  props?: IFlightSearchResultsStyleProps
): [
  IProcessedStyleSet<IFlightSearchResultsStyles>,
  IFlightSearchResultsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchResultsStyleProps,
  IFlightSearchResultsStyles
> = (_props: IFlightSearchResultsStyleProps): IFlightSearchResultsStyles => {
  return {
    root: [
      "amigo-FlightSearchResults",
      {
        width: "100%",
      },
    ],
  };
};
