import axios from "axios";
import { IPaymentElement } from "../../models/Payments/IPaymentElement";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";
import { IPendingBill } from "src/models/Billing/IPendingBill";
import { IPaymentOperationModel } from "src/models/Billing/IPaymentOperationModel";
import { IPaymentOperationResult } from "src/models/Billing/IPaymentOperationResult";
import DocumentService from "../Documents/DocumentService";
import { IPaymentDetails } from "src/models/Payments/IPaymentDetails";

class PaymentsService {
  private _payments?: IPaymentElement[] = undefined;
  private _paymentsPages: number = 1;

  public init(): void {
    this._payments = undefined;
    this._paymentsPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("name", "Numero")
      .replace("paymentDate", "DateEncaissement")
      .replace("paymentType", "TypePaiement")
      .replace("bills", "Factures")
      .replace("confirmation", "NumeroConfirmation")
      .replace("tvq", "TaxeProvinciale")
      .replace("total", "Montant");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public getPaymentsElements(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IPaymentElement[]> {
    const _this = this;
    let currentPage = this._paymentsPages;
    let combine: boolean;
    if (next && _this._payments !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `/api/enterprises/${enterpriseId}/payments`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._payments !== undefined) {
          _this._paymentsPages = currentPage;
          _this._payments = _this._payments.concat(items);
        } else {
          _this._paymentsPages = 1;
          _this._payments = this._payments = items;
        }
        return _this._payments ?? [];
      })
      .catch((_x) => {
        return _this._payments ?? [];
      });
  }

  public formatCreditCard(value: string) {
    const v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    const matches = v.match(/\d{4,16}/g);
    const match = (matches && matches[0]) || "";
    const parts = [];
    for (let i = 0, len = match.length; i < len; i += 4) {
      parts.push(match.substring(i, i + 4));
    }

   
      if (parts.length === 4) {
        return parts.join(" ");
      } else {
        return value;
      }
    
  }

  public async getPendingBills(enterpriseId: number): Promise<IPendingBill[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/billing/new/pending`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async pay(
    model: IPaymentOperationModel
  ): Promise<IPaymentOperationResult> {
    const x = await axios.post(
      `/api/enterprises/${model.enterpriseId}/payments/pay`,
      model,
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getPaymentDetail(
    enterpriseId: number,
    id: number
  ): Promise<IPaymentDetails> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/payments/${id}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public exportPayments(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ) {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/payments/reports/export-excel`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 1,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }
}

export default new PaymentsService();
