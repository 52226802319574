import { styled } from "@fluentui/react/lib/Utilities";
import { IProgressPanelProps } from "./ProgressPanel.types";
import {
  getStyles,
  IProgressPanelStyleProps,
  IProgressPanelStyles,
} from "./ProgressPanel.styles";
import { ProgressPanelComponent } from "./ProgressPanel.base";

/**
 * ProgressPanel description
 */
export const ProgressPanel = styled<
  IProgressPanelProps,
  IProgressPanelStyleProps,
  IProgressPanelStyles
>(ProgressPanelComponent, getStyles);
