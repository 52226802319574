import * as React from "react";
import { getParticipantTransfertRequestParticipantCardClassNames } from "./ParticipantTransfertRequestParticipantCard.styles";
import {
  IParticipantTransfertRequestParticipantCardState,
  IParticipantTransfertRequestParticipantCardProps,
} from "./ParticipantTransfertRequestParticipantCard.types";
import { GridViewCard } from "src/components/GridViewCard";
import {
  TooltipHost,
  Icon,
  Stack,
  StackItem,
  Dropdown,
  IDropdownOption,
  TextField,
} from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";

export class ParticipantTransfertRequestParticipantCardComponent extends React.Component<
  IParticipantTransfertRequestParticipantCardProps,
  IParticipantTransfertRequestParticipantCardState
> {
  constructor(props: IParticipantTransfertRequestParticipantCardProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const {
      styles,
      item,
      columnWidth,
      recalls,
      onRecallChanged,
      onReasonChanged,
    } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantTransfertRequestParticipantCardClassNames(styles!, {
        ...this.props,
        ...this.state,
        hasErrors: item.errors.recall,
      });

    const reasons = recalls
      ? recalls.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "").toString(),
            text: x.name,
          };
          return i;
        })
      : [];

    return (
      <div className={classNames.root}>
        <GridViewCard
          {...this.props}
          styles={subComponentStyles?.gridViewCard}
          title={item.name}
          columnWidth={columnWidth}
          footerText={undefined}
          footerIconName={undefined}
          selected={item.selected}
          onClick={
            item.selected
              ? this.props.onClick
                ? () => {
                    this.props.onClick && this.props.onClick(item);
                  }
                : undefined
              : this.props.onSelected
              ? () => {
                  this.props.onSelected && this.props.onSelected(item);
                }
              : undefined
          }
          onSelected={
            this.props.onSelected
              ? () => {
                  this.props.onSelected && this.props.onSelected(item);
                }
              : undefined
          }
          icons={
            <>
              <TooltipHost content={item.name}>
                <Icon
                  iconName="UserFollowed"
                  styles={subComponentStyles?.topIcon}
                />
              </TooltipHost>
            </>
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("messages:assisted:transfert:code")}
                content={item.code}
              />
            </StackItem>
            <StackItem grow>
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("messages:assisted:transfert:date")}
                content={item.date}
              />
            </StackItem>
            {!item.selected && (
              <StackItem grow>
                <div style={{ height: "201px" }}></div>
              </StackItem>
            )}
            {item.selected && (
              <>
                <StackItem grow>
                  <Dropdown
                    styles={{ root: { maxWidth: "260px" } }}
                    options={reasons}
                    errorMessage={item.errors.recall}
                    selectedKey={item.recall?.toString()}
                    label={i18n.t("messages:assisted:newdeparture:recall")}
                    placeholder={i18n.t(
                      "messages:assisted:newdeparture:select"
                    )}
                    onChange={(
                      ev: React.FormEvent<HTMLDivElement>,
                      option?: IDropdownOption
                    ) => {
                      ev?.preventDefault &&
                        typeof ev?.preventDefault === "function" &&
                        ev?.preventDefault();
                      ev?.stopPropagation &&
                        typeof ev?.stopPropagation === "function" &&
                        ev?.stopPropagation();
                      option &&
                        onRecallChanged(
                          parseInt(option.key.toString()),
                          option.text
                        );
                    }}
                  />
                </StackItem>
                <StackItem grow>
                  <TextField
                    styles={{ root: { maxWidth: "260px" } }}
                    label={i18n.t(
                      "messages:assisted:newdeparture:reasonrecall"
                    )}
                    value={item.reason}
                    onChange={(
                      _ev: React.FormEvent<
                        HTMLInputElement | HTMLTextAreaElement
                      >,
                      newValue?: string
                    ) => {
                      onReasonChanged(newValue ?? "");
                    }}
                  />
                </StackItem>
              </>
            )}
          </Stack>
        </GridViewCard>
      </div>
    );
  }
}
