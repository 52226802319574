import {
  ChoiceGroup,
  Dropdown,
  IChoiceGroupOption,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { IProfileCommunicationPreferences } from "src/models/IProfileCommunicationPreferences";
import * as React from "react";
import { CommunicationMethod } from "src/models/IProfileCommunicationPreferences";
import { ISessionProfile } from "src/models/ISessionProfile";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import { ProfileService } from "src/services/ProfileService";
import { getProfileCommunicationPreferencesClassNames } from "./ProfileCommunicationPreferences.styles";
import {
  IProfileCommunicationPreferencesState,
  IProfileCommunicationPreferencesProps,
} from "./ProfileCommunicationPreferences.types";

export class ProfileCommunicationPreferencesComponent extends React.Component<
  IProfileCommunicationPreferencesProps,
  IProfileCommunicationPreferencesState
> {
  constructor(props: IProfileCommunicationPreferencesProps) {
    super(props);
    this.state = {
      preferences: {
        alerts: 1,
        chat: 0,
      },
      changed: false,
      success: false,
    };

    ProfileService.getPreferences().then((x) =>
      this.setState({
        preferences: x,
      })
    );
  }

  private savePreferences = (preferences: IProfileCommunicationPreferences) => {
    ProfileService.updatePreferences(preferences).then((x) =>
      this.setState({
        success: x,
        changed: false,
      })
    );
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getProfileCommunicationPreferencesClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { preferences, changed, success } = this.state as any;
    const session: ISessionProfile | null = AuthService.getUserInfo();
    const cellphone = session?.cellphone;
    const email = {
      key: CommunicationMethod.Email.toString(),
      text: "Courriel",
    };

    const none = {
      key: CommunicationMethod.None.toString(),
      text: "Aucun",
    };
    const sms = {
      key: CommunicationMethod.Sms.toString(),
      text: "SMS - Messagerie texte",
      disabled:
        cellphone === undefined || cellphone == null || cellphone === "",
    };
    const alerts: IChoiceGroupOption[] = [none, email, sms];
    const chat: IChoiceGroupOption[] = [none, sms];
    return (
      <Stack
        grow
        className={classNames.root}
        tokens={{ childrenGap: 10 }}
        styles={{
          root: {
            margin: "0px 10px 10px 20px",
          },
        }}
      >
        <Text>{i18n.t("profile:preferences:instuctions")}</Text>
        {success && (
          <MessageBar messageBarType={MessageBarType.success}>
            {i18n.t("profile:preferences:success")}
          </MessageBar>
        )}
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
          <StackItem
            grow
            align="start"
            styles={mobile ? undefined : { root: { width: "280px" } }}
          >
            <div style={{ height: "3px" }}></div>
            <Text styles={{ root: { fontWeight: 600 } }}>
              {i18n.t("profile:communications:alerts")}
            </Text>
          </StackItem>
          <StackItem align="end" grow>
            {mobile ? (
              <Dropdown
                selectedKey={preferences.alerts}
                placeholder={i18n.t(
                  "profile:communications:methods:placeholder"
                )}
                onChange={(_ev, option) =>
                  this.setState({
                    changed: true,
                    success: false,
                    preferences: {
                      ...preferences,
                      alerts:
                        option === undefined
                          ? undefined
                          : parseInt(option.key.toString()),
                    },
                  })
                }
                options={alerts as any}
              />
            ) : (
              <Stack horizontal grow>
                <ChoiceGroup
                  selectedKey={preferences.alerts.toString()}
                  options={alerts}
                  onChange={(_ev, option) =>
                    this.setState({
                      changed: true,
                      success: false,
                      preferences: {
                        ...preferences,
                        alerts:
                          option === undefined
                            ? undefined
                            : parseInt(option.key),
                      },
                    })
                  }
                  label={i18n.t("profile:communications:methods")}
                />
              </Stack>
            )}
          </StackItem>
        </Stack>
        <Stack grow horizontal tokens={{ childrenGap: 10 }}>
          <StackItem
            grow
            align="start"
            styles={mobile ? undefined : { root: { width: "280px" } }}
          >
            <div style={{ height: "3px" }}></div>
            <Text styles={{ root: { fontWeight: 600 } }}>
              {i18n.t("profile:communications:chat")}
            </Text>
          </StackItem>
          <StackItem align="end" grow>
            {mobile ? (
              <Dropdown
                options={chat as any}
                selectedKey={preferences.chat.toString()}
                onChange={(_ev, option) =>
                  this.setState({
                    changed: true,
                    success: false,
                    preferences: {
                      ...preferences,
                      chat:
                        option === undefined
                          ? undefined
                          : parseInt((option?.key ?? "0").toString()),
                    },
                  })
                }
                label={i18n.t("profile:communications:methods")}
              />
            ) : (
              <Stack horizontal grow>
                <ChoiceGroup
                  selectedKey={preferences.chat.toString()}
                  options={chat}
                  onChange={(_ev, option) =>
                    this.setState({
                      changed: true,
                      success: false,
                      preferences: {
                        ...preferences,
                        chat:
                          option === undefined
                            ? undefined
                            : parseInt(option.key),
                      },
                    })
                  }
                  label={i18n.t("profile:communications:methods")}
                />
              </Stack>
            )}
          </StackItem>
        </Stack>
        <Stack grow horizontalAlign="end">
          <StackItem grow align="end">
            <PrimaryButton
              disabled={!changed}
              text={i18n.t("enterprises:neq:saveButton:title")}
              onClick={() =>
                this.savePreferences({
                  alerts: preferences.alerts ?? 1,
                  chat: preferences.chat ??
                        ((cellphone === undefined ||
                          cellphone == null ||
                          cellphone === "")
                          ? CommunicationMethod.None
                          : CommunicationMethod.Sms)
                })
              }
            />
          </StackItem>
        </Stack>
      </Stack>
    );
  }
}
