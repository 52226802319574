import { Icon, Link, Text } from "@fluentui/react";
import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getNewPaymentClientInfoClassNames } from "./NewPaymentClientInfo.styles";
import {
  INewPaymentClientInfoProps,
  INewPaymentClientInfoState,
} from "./NewPaymentClientInfo.types";

export class NewPaymentClientInfoComponent extends React.Component<
  INewPaymentClientInfoProps,
  INewPaymentClientInfoState
> {
  render(): JSX.Element {
    const { styles, delayed, mobile, enterprise } = this.props;
    const [classNames, subComponentStyles] = getNewPaymentClientInfoClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const withPhone = this.props.phone ?? true;
    const { fax, phone, name, address } = enterprise;
    const { street, city, state, postalcode } = address;

    return (
      <div className={classNames.root}>
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("billing:payments:newbillnumber:label")}
          content={
            delayed ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t("billing:payments:newbillnumber:delayed"),
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: i18n.t("billing:payments:newbillnumber"),
                }}
              ></span>
            )
          }
        />
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("billing:payments:employer")}
          content={name}
        />
        <LabelField
          {...this.props}
          styles={subComponentStyles?.label}
          label={i18n.t("enterprise:addresses:address")}
          iconProps={{ iconName: "MapPin" }}
          content={
            <div>
              <div>{street}</div>
              <div>
                {city} ({state})
              </div>
              <div>{postalcode}</div>
            </div>
          }
        />
        {withPhone && (
          <div>
            <div style={{ marginBottom: mobile ? "0" : "6px" }}>
              <div
                style={{
                  float: "left",
                  width: "32px",
                  paddingRight: "5px",
                }}
              >
                <Icon iconName="Phone" />
              </div>
              <div style={{ float: "left", width: "90px" }}>
                <Text>{i18n.t("enterprise:info:phone")}</Text>
              </div>
              <div style={{ float: "left" }}>
                <Link href={i18n.PhoneLink(phone)} className="ms-Link">
                  {phone}
                </Link>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        )}
        {withPhone && (
          <div>
            <div style={{ marginBottom: "6px" }}>
              <div
                style={{
                  float: "left",
                  width: "32px",
                  paddingRight: "5px",
                }}
              >
                <Icon iconName="Fax" />
              </div>
              <div style={{ float: "left", width: "90px" }}>
                <Text>{i18n.t("enterprise:info:fax")}</Text>
              </div>
              <div style={{ float: "left" }}>
                <Text>{fax}</Text>
              </div>
              <div className="clear-both"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

/* {enterprise.branches && enterprise.branches.length > 0 && <div>
                <LabelField
                {...this.props}
                styles={subComponentStyles?.label}
                label={"Établissement de facturation"}
                content={"Veuillez sélectionner un établissement de facturation"}
              />
              
              </div>} */
