import axios from "axios";
import { IChoiceValue } from "src/models/IQueryFilter";
import AuthService from "./AuthService";

class CountriesService {
  public async getCountries(enterpriseId: number): Promise<IChoiceValue[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/countries`,
      {},
      AuthService.getAuthHeader()
    );
    return (x.data as any).map((x: IChoiceValue) => {
      return {
        id: x.id,
        name: `country:${x.name}`,
      };
    });
  }
}

export default new CountriesService();
