import {
  DefaultPalette,
  PanelType,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  Selection,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { EntityPanel } from "src/components/EntityPanel";
import { GridViewMode } from "src/components/GridView/GridView.types";
import GridViewOptionsBuilder from "src/components/GridView/GridViewOptionsBuilder";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import { IPeriodParticipant } from "src/models/Requests/IPeriodParticipant";
import i18n from "src/services/i18n";
import RequestsService from "src/services/Requests/RequestsService";
import { PositionPanel } from "../PositionPanel";
import { PeriodPositionCountText } from "../PeriodPositionCountText";
import { RequestParticipantItem } from "../RequestParticipantItem";
import { RequestPeriodParticipantsControl } from "../RequestPeriodParticipantsControl";
import { getRequestPeriodPageClassNames } from "./RequestPeriodPage.styles";
import {
  IRequestPeriodPageProps,
  IRequestPeriodPageState,
} from "./RequestPeriodPage.types";

const themeIndex: number = 5; // Math.floor(Math.random() * 4);

export class RequestPeriodPageComponent extends React.Component<
  IRequestPeriodPageProps,
  IRequestPeriodPageState
> {
  private _selection: Selection;

  constructor(props: IRequestPeriodPageProps) {
    super(props);
    this._selection = new Selection();
    this.state = {
      tab: "0",
      confirmed: true,
      nonConfirmed: true,
      poste: undefined,
      options: GridViewOptionsBuilder.getOptions(
        this.props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "",
        false
      ),
      periodId: parseInt(this.props.route.match?.params.period),
      requestId: parseInt(this.props.route.match?.params.id),
      shuttleFilter: "all",
    };

    RequestsService.getRequestPeriod(
      objectId(this.props.enterprise),
      this.state.requestId,
      this.state.periodId,
      this.state.options.sortBy,
      this.state.options.desc
    ).then((x) => {
      this.setState({
        period: x,
      });
      this._selection.setItems(x.participants);
    });
  }

  private onParticipantSelected = (
    _this: RequestPeriodPageComponent,
    participantId: number
  ): void => {
    RequestsService.getRequestParticipantDetail(
      objectId(_this.props.enterprise),
      _this.state.requestId,
      participantId
    ).then((x) => {
      _this.setState({
        participant: x,
      });
    });
  };

  /*

  private _onRenderParticipant = (
    _this: RequestPeriodPageComponent,
    item?: IPeriodParticipant,
    index?: number
  ): JSX.Element => {
    const { mobile } = this.props;
    return (
      <div
        key={`ws-nvx-${item?.key}-${index}`}
        style={{
          width: "100%",
          float: "left",
        }}
      >
        <Card
          aria-label=""
          key={`${item?.key}-${index}`}
          horizontal
          style={{
            minWidth: "210px",
            margin: mobile ? "10px" : "16px",
            backgroundColor: DefaultPalette.white,
          }}
          onClick={() => {
            if (item) {
              _this.onParticipantSelected(_this, item.key);
            }
          }}
          tokens={this.props.tokens.defaultCardTokens}
        >
          <Card.Item fill styles={{ root: { display: "none" } }}>
            <PersonaCoin
              text={item?.lastName.toUpperCase()}
              size={mobile ? PersonaSize.size40 : PersonaSize.size56}
              style={{ padding: "20px 10px" }}
            />
          </Card.Item>
          <Card.Section
            styles={{
              root: {
                flexGrow: 1,
              },
            }}
          >
            <TooltipHost
              content={`${item?.lastName.toUpperCase()}, ${item?.firstName} : ${item?.participantNumber}`}
            >
              <Text
                nowrap
                block
                variant="small"
                styles={{
                  root: {
                    color: "#201f1e",
                    minWidth: mobile ? "200px" : "225px",
                    maxWidth: mobile ? "200px" : "225px",
                    fontSize: "15px",
                    fontWeight: FontWeights.semibold,
                  },
                }}
              >
                {`${item?.lastName.toUpperCase()}, ${item?.firstName}`}
              </Text>
            </TooltipHost>
            <Text styles={this.props.tokens.defaultCardTextStyle}>
              {item?.participantNumber}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTextStyle}
            >
              {i18n.t("requests:periods:arrival")}:{" "}
              {item?.arrivalType && item?.arrivalType != null
                ? i18n
                    .t(`requests:${item?.arrivalType ?? "NONE"}`)
                    .replace("requests:", "")
                : i18n.t("requests:NONE")}{" "}
              - {item?.arrivalDate}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTextStyle}
            >
              {i18n.t("requests:periods:departure")}:{" "}
              {item?.departureType && item?.departureType != null
                ? i18n
                    .t(`requests:${item?.departureType ?? "NONE"}`)
                    .replace("requests:", "")
                : i18n.t("requests:NONE")}{" "}
              - {item?.departureDate}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTextStyle}
            >
              {item?.title}
            </Text>
            <div>
              <div style={{ float: "left", height: "24px" }}>
                {(item?.participantType ?? []).length > 0 && (
                  <FontIcon iconName="Pin" />
                )}
              </div>
              <div className="ms-Card-bottom-container">
                <Text
                  variant="small"
                  styles={this.props.tokens.defaultCardTextStyle}
                >
                  {`${item?.participantType}`}
                </Text>
              </div>
            </div>
          </Card.Section>
          <Card.Section
            styles={footerCardSectionStyles}
            tokens={footerCardSectionTokens}
          >
            {this.props.mobile && (
              <Stack>
                <Text
                  style={{
                    width: "32px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  A
                </Text>
                <TravelStatusIcon status={item?.arrivalStatus ?? 0} />
                <Text
                  style={{
                    width: "32px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  D
                </Text>
                <TravelStatusIcon status={item?.departureStatus ?? 0} />
              </Stack>
            )}
            {!this.props.mobile && (
              <Stack>
                <Stack horizontal horizontalAlign={"center"}>
                  <Text
                    style={{
                      width: "32px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    A
                  </Text>
                  <Text
                    style={{
                      width: "32px",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    D
                  </Text>
                </Stack>
                <Stack horizontal horizontalAlign={"center"}>
                  <TravelStatusIcon status={item?.arrivalStatus ?? 0} />
                  <TravelStatusIcon status={item?.departureStatus ?? 0} />
                </Stack>
              </Stack>
            )}
            <Stack.Item grow={1}>
              <span />
            </Stack.Item>
          </Card.Section>
        </Card>
      </div>
    );
  };

  */

  private getParticipants = (): [IPeriodParticipant[], string] => {
    const s = this.props.route.location.state;
    const r: IPeriodParticipant[] = this.state.period?.participants ?? [];
    if (s) {
      const p = (s as any).position;
      if (p) {
        return [r.filter((x) => x.title.indexOf(p.toString()) !== -1), p ?? ""];
      }
    }
    return [r, ""];
  };

  render(): JSX.Element {
    const { styles, tablet, mobile } = this.props;
    const [classNames, subComponentStyles] = getRequestPeriodPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const [participants] = this.getParticipants();

    const enterpriseSize: PersonaSize = mobile
      ? PersonaSize.size56
      : PersonaSize.size100;

    const themeColor = [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
    ];
    const themeImageSize = [
      "cover",
      tablet || mobile ? "cover" : "100% 170%",
      "100% 115%",
      mobile ? "cover" : "cover",
      tablet || mobile ? "cover" : "100% 170%",
      tablet || mobile ? "cover" : "100% 120%",
    ];
    const themeOpacity = ["1.0", "0.4", "0.5", "0.5", "0.5", "1.0"];
    const fromto: string = this.state.period
      ? i18n.formatString(
          i18n.t("requests:fromto"),
          i18n.parseISOLocalString(this.state.period.startDate),
          i18n.parseISOLocalString(this.state.period.endDate)
        )
      : "";
    return (
      <Stack grow verticalFill className={classNames.root}>
        <div
          style={{
            minHeight: "180px",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            className="EnterpriseBackground"
            style={{
              opacity: themeOpacity[themeIndex],
              backgroundImage:
                "url('/img/banner_" + (themeIndex + 1).toString() + ".jpg')",
              backgroundSize: themeImageSize[themeIndex],
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className="WhitePersona"
            style={{
              position: "absolute",
              padding: "10px 10px",
              width: "95%",
              left: 0,
              bottom: 0,
            }}
          >
            <Persona
              initialsColor={"rgb(122, 117, 116)"}
              text={"Demande " + this.state.period?.requestName}
              imageInitials={this.state.period?.requestName.substr(0, 2)}
              styles={{
                primaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
                secondaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
                tertiaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
              }}
              coinProps={{
                styles: {
                  coin: {
                    display: "none",
                  },
                },
              }}
              color={themeColor[themeIndex]}
              secondaryText={this.props.enterprise.name}
              tertiaryText={fromto}
              size={enterpriseSize}
            />
          </div>
          <div
            data-note="top-right-section"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "4px",
            }}
          ></div>
          <div></div>
        </div>
        <Stack
          grow
          verticalFill
          style={{
            width: "100%",
            backgroundColor: DefaultPalette.neutralLighter,
          }}
        >
          <Pivot
            styles={subComponentStyles?.pivot}
            selectedKey={this.state.tab}
            onLinkClick={(item?: PivotItem) => {
              const newTab =
                item?.props.itemKey === undefined ? "0" : item.props.itemKey;
              if (newTab === "99") {
                this.props.route.history.push(
                  "/demandes/" + this.state.requestId.toString(),
                  { tab: "1" }
                );
              } else {
                this.setState({
                  tab: newTab,
                });
              }
            }}
          >
            <PivotItem
              headerText={i18n.t("requests:period")}
              itemKey={"0"}
              key={"0"}
            >
              <div
                className="ms-Grid"
                style={{ padding: "10px 0!important" }}
                dir="ltr"
              >
                <div className={classNames.msGridRow}>
                  <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg12 ms-xl12 ms-xxl12">
                    <div
                      style={{
                        backgroundColor: DefaultPalette.white,
                        margin: mobile
                          ? "10px 0"
                          : tablet
                          ? "10px 0 10px 0"
                          : "10px 10px 10px 10px",
                      }}
                      className="ms-depth-16"
                    >
                      <div
                        style={{
                          padding: mobile
                            ? "10px"
                            : tablet
                            ? "10px 0 10px 0"
                            : "10px 10px 10px 10px",
                        }}
                      >
                        <div style={{ float: "left" }}>
                          {this.state.period && (
                            <Text
                              style={{
                                fontWeight: "bold",
                                marginTop: "10px",
                                marginBottom: "10px",
                              }}
                              variant={mobile ? "medium" : "xLarge"}
                            >
                              {fromto}
                            </Text>
                          )}
                          <div style={{ paddingLeft: "0px" }}>
                            <Stack
                              horizontal
                              tokens={{
                                childrenGap: this.props.mobile ? 10 : 25,
                              }}
                            >
                              <LabelField
                                {...this.props}
                                styles={{
                                  root: {
                                    maxWidth: "340px",
                                  },
                                }}
                                label={i18n.t("requests:participants:eimt")}
                                content={this.state.period?.EIMT ?? " "}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t(
                                  "requests:participants:eimtexpiration"
                                )}
                                content={this.state.period?.expiration ?? " "}
                              />
                              <LabelField
                                {...this.props}
                                styles={subComponentStyles?.label}
                                label={i18n.t(
                                  (this.state.period?.positions ?? []).length >
                                    1
                                    ? "requests:postes:title"
                                    : "requests:poste:title"
                                )}
                                content={
                                  <PeriodPositionCountText
                                    {...this.props}
                                    styles={
                                      subComponentStyles?.participantCount
                                    }
                                    showRates={true}
                                    onClick={() => this.forceUpdate()}
                                    periodId={this.state.periodId}
                                    requestId={this.state.requestId}
                                    positions={
                                      this.state.period?.positions ?? []
                                    }
                                    variant="small"
                                  />
                                }
                              />
                            </Stack>
                          </div>

                          {mobile && (
                            <div style={{ paddingLeft: "10px" }}>
                              <h3>
                                {i18n.t(
                                  (this.state.period?.positions ?? []).length >
                                    1
                                    ? "requests:postes:title"
                                    : "requests:poste:title"
                                )}
                              </h3>
                              <PeriodPositionCountText
                                {...this.props}
                                styles={subComponentStyles?.participantCount}
                                showRates={true}
                                onClick={() => this.forceUpdate()}
                                periodId={this.state.periodId}
                                requestId={this.state.requestId}
                                positions={this.state.period?.positions ?? []}
                                variant="small"
                              />
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="clear-both"
                        style={{ height: "10px" }}
                      ></div>
                      {/*
                        <GroupedList
                          selection={this._selection}
                          selectionMode={SelectionMode.none}
                          groups={this.toGroups(participants)}
                          onRenderCell={() => (
                            <div
                              key={`nvx-group-grp-${i++}`}
                              style={{ clear: "both" }}
                            ></div>
                          )}
                          listProps={{
                            getItemCountForPage: () => {
                              return (
                                this.state.period?.participants.length ?? 10
                              );
                            },
                            className: "ms-GroupedList-Tiles",
                            style: { clear: "both" },
                            onRenderCell: (
                              item?: IPeriodParticipant,
                              index?: number
                            ) => _this._onRenderParticipant(_this, item, index),
                          }}
                          items={participants}
                        />
                      )
                      */}
                      <RequestPeriodParticipantsControl
                        {...this.props}
                        styles={undefined}
                        requestId={this.state.requestId}
                        periodId={this.state.periodId}
                        options={this.state.options}
                        participants={participants}
                        onToggleGrid={(mode) => {
                          this.setState({
                            options: {
                              ...this.state.options,
                              mode: mode,
                              title:
                                mode === GridViewMode.tiles
                                  ? "grid:gridview"
                                  : "grid:list",
                              iconName:
                                mode === GridViewMode.tiles
                                  ? "Tiles"
                                  : "GroupedList",
                            },
                          });
                        }}
                        onSort={(sortBy) => {
                          RequestsService.getRequestPeriod(
                            objectId(this.props.enterprise),
                            this.state.requestId,
                            this.state.periodId,
                            sortBy,
                            this.state.options.sortBy === sortBy
                              ? !this.state.options.desc
                              : false
                          ).then((x) => {
                            this.setState({
                              period: x,
                            });
                            this._selection.setItems(x.participants);
                          });

                          this.setState({
                            options: {
                              ...this.state.options,
                              sortBy: "",
                              desc:
                                this.state.options.sortBy === ""
                                  ? !this.state.options.desc
                                  : false,
                            },
                          });
                        }}
                        onParticipantSelected={(item) =>
                          this.onParticipantSelected(this, item.key)
                        }
                        onPositionSelected={(item: IPeriodParticipant) => {
                          item.poste !== undefined &&
                            RequestsService.getPosition(
                              objectId(this.props.enterprise),
                              item.poste
                            ).then((x) =>
                              this.setState({
                                position: x,
                              })
                            );
                        }}
                      />
                      <div
                        className="clear-both"
                        style={{ height: "30px" }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="clear-both"
                style={{
                  width: "200px",
                }}
              ></div>
            </PivotItem>
            <PivotItem
              itemIcon={"NavigateBack"}
              headerText={i18n.t("requests:backrequest")}
              itemKey={"99"}
              key={"99"}
            ></PivotItem>
          </Pivot>
          <EntityPanel
            {...this.props}
            styles={subComponentStyles?.entityPanel}
            panelType={PanelType.large}
            data-automation-id="nvx:requests:periods:itempanel"
            isOpen={this.state.participant !== undefined}
            onDismiss={() => {
              this.setState({
                participant: undefined,
              });
            }}
            elementName={"Participant"}
            title={this.state.participant?.name}
          >
            {this.state.participant === undefined ? (
              <span></span>
            ) : (
              <RequestParticipantItem
                inline={true}
                {...this.state}
                {...this.props}
                styles={subComponentStyles?.participantItem}
                participant={this.state.participant}
              />
            )}
          </EntityPanel>
          <EntityPanel
            {...this.props}
            panelType={PanelType.medium}
            styles={subComponentStyles?.entityPanel}
            data-automation-id="nvx:requests:periods:positionpanel"
            isOpen={this.state.position !== undefined}
            onDismiss={() => {
              this.setState({
                position: undefined,
              });
            }}
            elementName={"Poste"}
            title={this.state.position?.title}
          >
            {this.state.position === undefined ? (
              <span></span>
            ) : (
              <PositionPanel
                {...this.state}
                {...this.props}
                styles={subComponentStyles?.participantItem}
                position={this.state.position}
              />
            )}
          </EntityPanel>
        </Stack>
      </Stack>
    );
  }
}
