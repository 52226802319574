import axios from "axios";
import { IFlightRequestDataModel } from "src/models/Transports/IFlightRequestDataModel";
import AuthService from "../AuthService";
import {
  IParticipantNameRecordModel,
  IPassengerNameRecordModel,
} from "src/models/Transports/IPassengerNameRecordModel";
import { IFlightBookingResponse } from "src/models/Transports/IFlightBookingResponse";
import DocumentService from "../Documents/DocumentService";

export class TransportFlightBookingService {
  public static async bookAsync(
    model: IPassengerNameRecordModel
  ): Promise<IFlightBookingResponse> {
    model.participants.forEach((x) => (x.errors = []));
    const x = await axios.post(
      "/api/transports/flights/book",
      model,
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static async getDataModel(
    enterpriseId: number,
    iata: string | undefined,
    arrival: boolean
  ): Promise<IFlightRequestDataModel> {
    const route: string = arrival ? "arrivals" : "departures";
    const x = await axios.post(
      `/api/transports/${enterpriseId}/flights/${route}`,
      {
        iata,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static async getParticipants(
    enterpriseId: number,
    requestId: number,
    arrival: boolean
  ): Promise<IParticipantNameRecordModel[]> {
    const route: string = arrival ? "arrivals" : "departures";
    const x = await axios.post(
      `/api/transports/flights/${enterpriseId}/participants/${route}`,
      {
        enterpriseId: enterpriseId,
        requestId: requestId,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static async getReservationAsync(
    enterpriseId: number,
    reservationId: number,
    mimeType: string
  ) {
    const x = await axios.post(
      `api/transports/${enterpriseId}/flights/reservation`,
      {
        reservationId,
        mimeType,
      },
      AuthService.getAuthHeaderWithBlob()
    );

    if (x.status < 299) {
      DocumentService.openBlob(x);
    }
  }
}
