import {
  DatePicker,
  Dropdown,
  IDropdownOption,
  initializeComponentRef,
  MessageBar,
  MessageBarType,
  Selection,
  Separator,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import Moment from "moment";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
} from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { objectId } from "src/models/IObjectReference";
import {
  INewParticipantTransfertRequest,
  NewParticipantTransfertRequestSchema,
} from "src/models/Messages/INewParticipantTransfertRequest";
import {
  IParticipantTransfertRequestParticipant,
  ParticipantTransfertRequestParticipantSchema,
} from "src/models/Messages/IParticipantTransfertRequestParticipant";
import i18n from "src/services/i18n";
import { ParticipantTransfertService } from "src/services/Messages/ParticipantTransfertService";
import { validate } from "src/utils/validator";
import { ParticipantTransfertRequestParticipantCard } from "../ParticipantTransfertRequestParticipantCard";
import { getParticipantTransfertAssistedEditorClassNames } from "./ParticipantTransfertAssistedEditor.styles";
import {
  IParticipantTransfertAssistedEditorProps,
  IParticipantTransfertAssistedEditorState,
} from "./ParticipantTransfertAssistedEditor.types";

export class ParticipantTransfertAssistedEditorComponent extends React.Component<
  IParticipantTransfertAssistedEditorProps,
  IParticipantTransfertAssistedEditorState
> {
  constructor(props: IParticipantTransfertAssistedEditorProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {};
    const { inputMessage } = props;
    if (inputMessage) {
      ParticipantTransfertService.getParticipantTransfertRequestEditModel(
        objectId(props.enterprise),
        inputMessage.itemId
      )
        .then((x) => {
          const [inp, model] = x;
          const request: INewParticipantTransfertRequest = inp;

          if (request.requestId) {
            props.onDataModelUpdate(model);
            ParticipantTransfertService.getParticipants(
              objectId(props.enterprise),
              request.requestId
            ).then((y) => {
              props.onUpdate(
                {
                  ...request,
                  participants: y.map((z) => {
                    const pos = request.participants.filter(
                      (zz) => zz.id === z.id
                    );
                    return pos.length === 0 ? z : pos[0];
                  }),
                },
                true
              );
            });
          } else {
            props.onDataModelUpdate(model, request);
          }
        })
        .catch((x) => {
          console.log(x);
        });
    } else {
      ParticipantTransfertService.getDataModel(objectId(props.enterprise))
        .then((x) => {
          props.onDataModelUpdate(x);
        })
        .catch((_x) => {
          alert("error");
        });
    }
  }

  public validate = (): string[] => {
    const { request, onUpdate } = this.props;
    const { participants } = request;
    const errors = validate(NewParticipantTransfertRequestSchema, request);
    participants.forEach((x) => {
      x.errors = (
        x.selected
          ? validate(ParticipantTransfertRequestParticipantSchema, x)
          : []
      ) as any;
    });

    if (
      participants.filter((x) => x.selected && x.errors.length > 0).length > 0
    ) {
      errors["participants:item"] = "error";
    } else if (
      Object.keys(errors).filter((x) => x === "participants:item").length > 0
    ) {
      delete errors["participants:item"];
    }

    if (
      request.requestId &&
      participants.filter((x) => x.selected).length === 0
    ) {
      errors.participants = "error";
    }
    onUpdate({ ...request, participants: participants }, true, errors as any);
    return errors as any;
  };

  private onRequestChanged = (newRequestId?: number): void => {
    const { enterprise, model, request, onUpdate } = this.props;

    newRequestId &&
      onUpdate({
        ...request,
        requestId: newRequestId,
        requestNumber: model
          ? model.requests.filter((y) => y.id === newRequestId)[0].name
          : undefined,
      });

    if (!newRequestId) return;

    ParticipantTransfertService.getParticipants(
      objectId(enterprise),
      newRequestId
    ).then((x) => {
      onUpdate(
        {
          ...request,
          requestId: newRequestId,
          participants: x,
        },
        true
      );
    });
  };

  private onRequestDateChanged = (newRequestDate?: Date): void => {
    const { request, onUpdate } = this.props;

    onUpdate({
      ...request,
      requestDate: newRequestDate
        ? Moment(newRequestDate).format("YYYY-MM-DD")
        : undefined,
    });
  };

  render(): JSX.Element {
    const { styles, mobile, request, model, participantsFetched, errors } =
      this.props;
    const [classNames, subComponentStyles] =
      getParticipantTransfertAssistedEditorClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { requestDate, requestId, participants } = request;
    const requests = model
      ? model.requests
          .filter((x) => x.hasEnterpriseExchanges)
          .map((x) => {
            const i: IDropdownOption = {
              key: (x.key ?? "").toString(),
              text: x.name,
            };
            return i;
          })
      : [];

    const recalls = model
      ? model.recalls.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "").toString(),
            text: x.name,
          };
          return i;
        })
      : [];
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text style={{ fontWeight: 600 }}>
                {i18n.t("messages:assisted:participanttransfert:warning:title")}
              </Text>
              <Text style={mobile ? undefined : { maxWidth: "500px" }}>
                {i18n.t(
                  "messages:assisted:participanttransfert:warning:content"
                )}
              </Text>
            </Stack>
          </MessageBar>
          {requests.length === 0 ? (
            <MessageBar
              messageBarType={MessageBarType.blocked}
              styles={{ root: { marginBottom: "20px" } }}
            >
              <Stack tokens={{ childrenGap: 5 }}>
                <Text style={{ fontWeight: 600 }}>
                  {i18n.t(
                    "messages:assisted:participanttransfert:warning:norequest:title"
                  )}
                </Text>
                <Text style={mobile ? undefined : { maxWidth: "500px" }}>
                  {i18n.t("messages:assisted:participanttransfert:norequest")}
                </Text>
              </Stack>
            </MessageBar>
          ) : (
            <>
              <Stack horizontal tokens={{ childrenGap: mobile ? 10 : 50 }}>
                <Dropdown
                  styles={{ root: { minWidth: "192px" } }}
                  required
                  label={i18n.t(
                    "messages:assisted:participanttransfert:requestnumber"
                  )}
                  options={requests}
                  errorMessage={errors.requestId}
                  selectedKey={requestId ? requestId.toString() : undefined}
                  onChange={(
                    _ev: React.FormEvent<HTMLDivElement>,
                    option?: IDropdownOption
                  ) => {
                    this.onRequestChanged(
                      option ? parseInt(option.key.toString()) : undefined
                    );
                  }}
                  placeholder={i18n.t("messages:assisted:newdeparture:select")}
                />
              </Stack>
              <Separator />
              <Stack
                horizontal={!mobile}
                tokens={{ childrenGap: mobile ? 10 : 50 }}
              >
                <DatePicker
                  isRequired={true}
                  minDate={Moment(new Date()).subtract(1, "day").toDate()}
                  maxDate={new Date(2199, 1, 1)}
                  label={i18n.t(
                    "messages:assisted:participanttransfert:requestdate"
                  )}
                  title={""}
                  formatDate={(date?: Date) => {
                    if (!date) return "";
                    return Moment(date).locale(i18n.getLanguage()).format("LL");
                  }}
                  textField={{ errorMessage: errors.requestDate }}
                  showGoToToday={false}
                  strings={i18n.CalendarStrings()}
                  value={
                    requestDate ? i18n.parseISOLocal(requestDate) : undefined
                  }
                  onSelectDate={(date: Date | null | undefined) => {
                    this.onRequestDateChanged(date === null ? undefined : date);
                  }}
                />
                <TextField
                  required
                  label={i18n.t(
                    "messages:assisted:participanttransfert:transfertenterprise"
                  )}
                  errorMessage={errors.transfertEnterprise}
                  value={request.transfertEnterprise}
                  maxLength={250}
                  onChange={(
                    _ev: React.FormEvent<
                      HTMLInputElement | HTMLTextAreaElement
                    >,
                    newValue?: string
                  ) => {
                    this.props.onUpdate({
                      ...request,
                      transfertEnterprise: newValue,
                    });
                  }}
                />
              </Stack>
              <Separator />
              <Stack tokens={{ childrenGap: 10 }}>
                <Text style={{ fontWeight: "bold" }}>
                  {i18n.t("messages:assisted:participants")}
                </Text>
                {errors.participants && (
                  <MessageBar messageBarType={MessageBarType.error}>
                    {i18n.t(
                      "messages:assisted:newdeparture:participants:required"
                    )}
                  </MessageBar>
                )}
                <GridView
                  {...this.props}
                  styles={
                    mobile
                      ? {
                          root: {
                            marginLeft: "-12px",
                            marginRight: "-12px",
                          },
                        }
                      : undefined
                  }
                  embedded={true}
                  selectionPreservedOnEmptyClick={true}
                  onSelectionChanged={(
                    items: IParticipantTransfertRequestParticipant[],
                    selection?: Selection
                  ) => {
                    items.forEach((x) => (x.selected = true));
                    const { request, onUpdate } = this.props;
                    const { participants } = request;
                    const paths = (window.event as any).path;
                    if (
                      paths.filter((y: any) => {
                        const res =
                          (y.className ?? "").indexOf(
                            "ms-DetailsRow-fields"
                          ) !== -1 ||
                          (y.className ?? "").indexOf("ms-Dropdown-") !== -1 ||
                          (y.className ?? "").indexOf("ms-TextField-") !== -1;
                        return res;
                      }).length > 0
                    ) {
                      participants.forEach((x) => {
                        if (x.selected) {
                          selection?.setKeySelected(
                            x.id.toString(),
                            true,
                            false
                          );
                        }
                      });
                    } else {
                      participants.forEach((x) => {
                        x.selected =
                          items.filter((y) => y.id === x.id).length > 0;
                      });
                    }
                    onUpdate({ ...request, participants: participants });
                  }}
                  columns={[
                    {
                      key: "name",
                      name: "messages:assisted:newdeparture:participant",
                      minWidth: undefined,
                      onRenderCell: (
                        item: IParticipantTransfertRequestParticipant
                      ) => {
                        return (
                          <Stack tokens={{ childrenGap: 2 }}>
                            <Text>{item.name}</Text>
                            <Text variant={"small"}>{item.code}</Text>
                            <Text variant={"small"}>{item.date}</Text>
                          </Stack>
                        );
                      },
                    },
                    {
                      key: "recall",
                      name: "messages:assisted:newdeparture:recall",
                      minWidth: 220,
                      onRenderCell: (
                        item: IParticipantTransfertRequestParticipant
                      ) => {
                        return item?.selected ? (
                          <Dropdown
                            options={recalls}
                            selectedKey={
                              item?.recall === undefined || item.recall === null
                                ? undefined
                                : item.recall.toString()
                            }
                            errorMessage={
                              item.errors ? item.errors.recall : undefined
                            }
                            onChange={(
                              ev: React.FormEvent<HTMLDivElement>,
                              option?: IDropdownOption
                            ) => {
                              ev?.preventDefault &&
                                typeof ev?.preventDefault === "function" &&
                                ev?.preventDefault();
                              ev?.stopPropagation &&
                                typeof ev?.stopPropagation === "function" &&
                                ev?.stopPropagation();
                              const { request, onUpdate } = this.props;
                              const { participants } = request;
                              option &&
                                onUpdate({
                                  ...request,
                                  participants: participants.map((x) => {
                                    if (x.id === item.id) {
                                      x.recall = parseInt(
                                        option.key.toString()
                                      );
                                      x.recallName = option.text;
                                    }
                                    return x;
                                  }),
                                });
                            }}
                            placeholder={i18n.t(
                              "messages:assisted:newdeparture:select"
                            )}
                          />
                        ) : (
                          <Text>{"-"}</Text>
                        );
                      },
                    },
                    {
                      key: "reason",
                      name: "messages:assisted:newdeparture:reasonrecall",
                      minWidth: 160,
                      onRenderCell: (
                        item: IParticipantTransfertRequestParticipant
                      ) => {
                        return item.selected ? (
                          <TextField
                            maxLength={100}
                            value={item.reason}
                            onChange={(
                              _ev: React.FormEvent<
                                HTMLInputElement | HTMLTextAreaElement
                              >,
                              newValue?: string
                            ) => {
                              const { request, onUpdate } = this.props;
                              const { participants } = request;

                              onUpdate({
                                ...request,
                                participants: participants.map((x) => {
                                  if (x.id === item.id) {
                                    x.reason = item.reason = newValue ?? "";
                                  }
                                  return x;
                                }),
                              });
                            }}
                          />
                        ) : (
                          <Text>{"-"}</Text>
                        );
                      },
                    },
                  ]}
                  onRenderTile={(
                    item: IParticipantTransfertRequestParticipant,
                    columnWidth: number,
                    selection: Selection,
                    callback: (sel: Selection) => void
                  ): JSX.Element => {
                    return (
                      <ParticipantTransfertRequestParticipantCard
                        {...this.props}
                        styles={subComponentStyles?.gridCard}
                        item={item}
                        recalls={model ? model.recalls : []}
                        columnWidth={columnWidth}
                        selection={selection}
                        onRecallChanged={(
                          newRecallId: number,
                          recallText: string
                        ) => {
                          const { request, onUpdate } = this.props;
                          const { participants } = request;
                          onUpdate({
                            ...request,
                            participants: participants.map((x) => {
                              if (x.id === item.id) {
                                x.recall = newRecallId;
                                x.recallName = recallText;
                              }
                              return x;
                            }),
                          });
                        }}
                        onReasonChanged={(reason: string) => {
                          const { request, onUpdate } = this.props;
                          const { participants } = request;

                          onUpdate({
                            ...request,
                            participants: participants.map((x) => {
                              if (x.id === item.id) {
                                x.reason = item.reason = reason ?? "";
                              }
                              return x;
                            }),
                          });
                        }}
                        onSelected={(
                          item: IParticipantTransfertRequestParticipant
                        ) => {
                          ensureSelection(selection, item);
                          item.selected = !item.selected;
                          selection.setKeySelected(
                            item.key,
                            selection
                              .getSelection()
                              .filter((x) => x.key === item.key).length === 0,
                            false
                          );
                          callback(selection);
                        }}
                      />
                    );
                  }}
                  onDataPaging={() => {}}
                  options={GridViewOptionsBuilder.getOptions(
                    mobile ? GridViewMode.tiles : GridViewMode.list,
                    "name",
                    false,
                    !requestId || participantsFetched
                  )}
                  emptyMessage={i18n.t(
                    requestId &&
                      participantsFetched &&
                      participants.length === 0
                      ? "messages:assisted:participants:transfert:noparticipants"
                      : "messages:assisted:participants:emptymessage"
                  )}
                  items={participants}
                  isItemSelected={(
                    item: IParticipantTransfertRequestParticipant
                  ) => {
                    return item.selected;
                  }}
                />
                <div style={{ height: "20px" }}></div>
              </Stack>
            </>
          )}
        </Stack>
      </div>
    );
  }
}
