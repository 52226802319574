import * as React from "react";
import { getAuthorizationConsentPanelClassNames } from "./AuthorizationConsentPanel.styles";
import {
  IAuthorizationConsentPanelState,
  IAuthorizationConsentPanelProps,
} from "./AuthorizationConsentPanel.types";
import {
  Panel,
  Stack,
  Icon,
  MessageBar,
  MessageBarType,
  Text,
  TextField,
  StackItem,
  PrimaryButton,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import AuthService from "src/services/AuthService";

export class AuthorizationConsentPanelComponent extends React.Component<
  IAuthorizationConsentPanelProps,
  IAuthorizationConsentPanelState
> {
  constructor(props: IAuthorizationConsentPanelProps) {
    super(props);
    this.state = {
      consentname: undefined,
    };
  }

  render(): JSX.Element {
    const {
      styles,
      mobile,
      onConsent,
      onDismiss,
      onError,
      onconsent,
      consenterror,
    } = this.props;
    const [classNames] = getAuthorizationConsentPanelClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { consentname } = this.state;
    return (
      <div className={classNames.root}>
        <Panel
          isOpen={onconsent}
          headerText={i18n.t("messages:assisted:authorization:title")}
          isLightDismiss={false}
          isBlocking={true}
          hasCloseButton={true}
          onDismiss={onDismiss}
        >
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Icon iconName={"Info"} />
            <Text style={{ fontWeight: 600 }}>
              {i18n.t("messages:assisted:newdeparture:preauth:label")}
            </Text>
          </Stack>
          <div style={{ height: mobile ? "20px" : "40px" }}></div>
          <Stack tokens={{ childrenGap: 10 }}>
            <Text
              style={{
                textAlign: "justify",
                marginRight: mobile ? undefined : "-8px",
              }}
            >
              {i18n.t("messages:assisted:authorization")}
            </Text>
            <MessageBar messageBarType={MessageBarType.info}>
              {i18n.t("messages:assisted:authorization:instructions")}
            </MessageBar>
            <TextField
              required
              label={i18n.t("messages:assisted:authorization:label")}
              errorMessage={
                consenterror
                  ? i18n.formatString(
                      i18n.t(consenterror),
                      `${AuthService.getUserInfo()?.firstName ?? ""} ${
                        AuthService.getUserInfo()?.lastName ?? ""
                      }`
                    )
                  : undefined
              }
              value={consentname}
              onChange={(
                _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                newValue?: string
              ) => {
                this.setState({ consentname: newValue ?? "" });
              }}
            />
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <StackItem grow>
                <Stack grow>
                  <PrimaryButton
                    width="100%"
                    styles={{ root: { width: "100%" } }}
                    text={"Ok"}
                    onClick={() => {
                      const session = AuthService.getUserInfo();
                      if (
                        session &&
                        i18n.stripDiacritics(
                          `${session.firstName} ${session.lastName}`.toLowerCase()
                        ) ===
                          i18n
                            .stripDiacritics(this.state.consentname ?? "")
                            .toLowerCase()
                      ) {
                        onConsent(this.state.consentname ?? "");
                        this.setState({
                          consentname: undefined,
                        });
                      } else if ((this.state.consentname ?? "").length === 0) {
                        onError(
                          "messages:assisted:authorization:error:required"
                        );
                      } else {
                        onError(
                          "messages:assisted:authorization:error:invalid"
                        );
                      }
                    }}
                  />
                </Stack>
              </StackItem>
              <StackItem grow>
                <Stack grow>
                  <PrimaryButton
                    width="100%"
                    styles={{ root: { width: "100%" } }}
                    text={i18n.t("global:cancel")}
                    onClick={() => {
                      onDismiss();
                      this.setState({
                        consentname: undefined,
                      });
                    }}
                  />
                </Stack>
              </StackItem>
            </Stack>
          </Stack>
        </Panel>
      </div>
    );
  }
}
