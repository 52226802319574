import { styled } from "@fluentui/react/lib/Utilities";
import { INewMessageControlProps } from "./NewMessageControl.types";
import {
  getStyles,
  INewMessageControlStyleProps,
  INewMessageControlStyles,
} from "./NewMessageControl.styles";
import { NewMessageControlComponent } from "./NewMessageControl.base";

/**
 * NewMessageControl description
 */
export const NewMessageControl = styled<
  INewMessageControlProps,
  INewMessageControlStyleProps,
  INewMessageControlStyles
>(NewMessageControlComponent, getStyles);
