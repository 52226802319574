import {
  AnimationStyles,
  IStyle,
  IStyleFunction,
  mergeStyles,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface IScrollToTopSubComponentStyles {}

export interface IScrollToTopStyles
  extends IComponentBaseStyles<IScrollToTopSubComponentStyles> {
  icon?: IStyle;
}

export interface IScrollToTopStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getScrollToTopClassNames = (
  styles?: IStyleFunctionOrObject<IScrollToTopStyleProps, IScrollToTopStyles>,
  props?: IScrollToTopStyleProps
): [
  IProcessedStyleSet<IScrollToTopStyles>,
  IScrollToTopSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IScrollToTopStyleProps,
  IScrollToTopStyles
> = (props: IScrollToTopStyleProps): IScrollToTopStyles => {
  return {
    root: [
      "ms-AppScrollToTop",
      mergeStyles(
        {
          position: "fixed",
          bottom: "48px",
          right: "1.2rem",
          fontSize: "24px",
          cursor: "pointer",
        },
        AnimationStyles.fadeIn100
      ),
    ],
    icon: [
      "ms-AppScrollToTop-Icon",
      {
        transform: "rotate(90deg)",
        color: props.theme.palette.black,
      },
    ],
  };
};
