import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface IFlightDates {
  start: Date | undefined;
  end: Date | undefined;
}

export interface IFlightCriteria {
  from: string | undefined;
  to: string | undefined;
  passengers: number;
  dates: IFlightDates | undefined;
  searchDates: IFlightDates | undefined;
  charter: boolean;
  market: boolean;
  flexible: boolean;
  arrival: boolean;
}

export const FlightCriteriaSchema: ISchemaObject = {
  name: "flightcriteria",
  plural: "flightcriterias",
  label: "FlightCriteria",
  fields: {
    from: {
      name: "from",
      label: i18n.t("transports:flightcriteria:from"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flightcriteria:from:error"),
      type: "string",
    },
    to: {
      name: "to",
      label: i18n.t("transports:flightcriteria:to"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flightcriteria:to:error"),
      type: "string",
    },
    passengers: {
      name: "passengers",
      label: i18n.t("transports:flightcriteria:passengers"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flightcriteria:passengers:error"),
      type: "number",
    },
    /*
    date: {
      name: "date",
      label: i18n.t("transports:flightcriteria:date"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flightcriteria:date:error"),
      type: "string",
    },
    */
  },
};
