import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportArrivalAssistedControlProps } from "./TransportArrivalAssistedControl.types";
import {
  getStyles,
  ITransportArrivalAssistedControlStyleProps,
  ITransportArrivalAssistedControlStyles,
} from "./TransportArrivalAssistedControl.styles";
import { TransportArrivalAssistedControlComponent } from "./TransportArrivalAssistedControl.base";

/**
 * TransportArrivalAssistedControl description
 */
export const TransportArrivalAssistedControl = styled<
  ITransportArrivalAssistedControlProps,
  ITransportArrivalAssistedControlStyleProps,
  ITransportArrivalAssistedControlStyles
>(TransportArrivalAssistedControlComponent, getStyles);
