import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IArrivalAssistedParticipantCardStyleProps,
  IArrivalAssistedParticipantCardStyles,
} from "../ArrivalAssistedParticipantCard";

export interface ITransportArrivalAssistedRequestEditorSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IArrivalAssistedParticipantCardStyleProps,
    IArrivalAssistedParticipantCardStyles
  >;
}

export interface ITransportArrivalAssistedRequestEditorStyles
  extends IAmigoBaseStyles<ITransportArrivalAssistedRequestEditorSubComponentStyles> {}

export interface ITransportArrivalAssistedRequestEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportArrivalAssistedRequestEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportArrivalAssistedRequestEditorStyleProps,
    ITransportArrivalAssistedRequestEditorStyles
  >,
  props?: ITransportArrivalAssistedRequestEditorStyleProps
): [
  IProcessedStyleSet<ITransportArrivalAssistedRequestEditorStyles>,
  ITransportArrivalAssistedRequestEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportArrivalAssistedRequestEditorStyleProps,
  ITransportArrivalAssistedRequestEditorStyles
> = (
  _props: ITransportArrivalAssistedRequestEditorStyleProps
): ITransportArrivalAssistedRequestEditorStyles => {
  return {
    root: ["amigo-TransportArrivalAssistedRequestEditor", {}],
  };
};
