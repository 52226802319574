import { styled } from "@fluentui/react/lib/Utilities";
import { ICommunicationTypeSelectorProps } from "./CommunicationTypeSelector.types";
import {
  getStyles,
  ICommunicationTypeSelectorStyleProps,
  ICommunicationTypeSelectorStyles,
} from "./CommunicationTypeSelector.styles";
import { CommunicationTypeSelectorComponent } from "./CommunicationTypeSelector.base";

/**
 * CommunicationTypeSelector
 */
export const CommunicationTypeSelector = styled<
  ICommunicationTypeSelectorProps,
  ICommunicationTypeSelectorStyleProps,
  ICommunicationTypeSelectorStyles
>(CommunicationTypeSelectorComponent, getStyles);
