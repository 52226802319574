import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentPageProps } from "./NewPaymentPage.types";
import {
  getStyles,
  INewPaymentPageStyleProps,
  INewPaymentPageStyles,
} from "./NewPaymentPage.styles";
import { NewPaymentPageComponent } from "./NewPaymentPage.base";

/**
 * NewPaymentPage description
 */
export const NewPaymentPage = styled<
  INewPaymentPageProps,
  INewPaymentPageStyleProps,
  INewPaymentPageStyles
>(NewPaymentPageComponent, getStyles);
