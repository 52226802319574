import { styled } from "@fluentui/react/lib/Utilities";
import { IResetPasswordControlProps } from "./ResetPasswordControl.types";
import {
  getStyles,
  IResetPasswordControlStyleProps,
  IResetPasswordControlStyles,
} from "./ResetPasswordControl.styles";
import { ResetPasswordControlComponent } from "./ResetPasswordControl.base";

/**
 * ResetPasswordControl description
 */
export const ResetPasswordControl = styled<
  IResetPasswordControlProps,
  IResetPasswordControlStyleProps,
  IResetPasswordControlStyles
>(ResetPasswordControlComponent, getStyles);
