import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  ITransportSummaryCardStyleProps,
  ITransportSummaryCardStyles,
} from "../TransportSummaryCard";

export interface ITransportSummaryControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<
    ITransportSummaryCardStyleProps,
    ITransportSummaryCardStyles
  >;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface ITransportSummaryControlStyles
  extends IAmigoBaseStyles<ITransportSummaryControlSubComponentStyles> {}

export interface ITransportSummaryControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportSummaryControlClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportSummaryControlStyleProps,
    ITransportSummaryControlStyles
  >,
  props?: ITransportSummaryControlStyleProps
): [
  IProcessedStyleSet<ITransportSummaryControlStyles>,
  ITransportSummaryControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportSummaryControlStyleProps,
  ITransportSummaryControlStyles
> = (
  _props: ITransportSummaryControlStyleProps
): ITransportSummaryControlStyles => {
  return {
    root: ["amigo-TransportSummaryControl", {}],
  };
};
