import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertCardProps } from "./AlertCard.types";
import {
  getStyles,
  IAlertCardStyleProps,
  IAlertCardStyles,
} from "./AlertCard.styles";
import { AlertCardComponent } from "./AlertCard.base";

/**
 * AlertCard description
 */
export const AlertCard = styled<
  IAlertCardProps,
  IAlertCardStyleProps,
  IAlertCardStyles
>(AlertCardComponent, getStyles);
