import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IUserContactCardSubComponentStyles {}

export interface IUserContactCardStyles
  extends IAmigoBaseStyles<IUserContactCardSubComponentStyles> {
  header?: IStyle;
  inner?: IStyle;
  footer?: IStyle;
}

export interface IUserContactCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getUserContactCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IUserContactCardStyleProps,
    IUserContactCardStyles
  >,
  props?: IUserContactCardStyleProps
): [
  IProcessedStyleSet<IUserContactCardStyles>,
  IUserContactCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUserContactCardStyleProps,
  IUserContactCardStyles
> = (_props: IUserContactCardStyleProps): IUserContactCardStyles => {
  return {
    header: {
      padding: "18px 24px 12px",
    },
    inner: {
      height: "100%",
      padding: "0 24px 20px",
    },
    footer: {
      clear: "both",
      height: "20px",
    },
  };
};
