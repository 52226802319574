import * as React from "react";
import { AlertsControl } from "../AlertsControl/AlertsControl";
import { getAlertsPageClassNames } from "./AlertsPage.styles";
import { IAlertsPageProps, IAlertsPageState } from "./AlertsPage.types";
import { AlertBanner } from "../AlertBanner";
import { uniqueId } from "src/utils/uniqueId";

export class AlertsPageComponent extends React.Component<
  IAlertsPageProps,
  IAlertsPageState
> {
  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getAlertsPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        {!mobile && <AlertBanner {...this.props} styles={undefined} />}
        <AlertsControl
          {...this.props}
          scrollableTargetId={id}
          styles={undefined}
        />
      </div>
    );
  }
}
