import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IProfileInfoSubComponentStyles {}

export interface IProfileInfoStyles
  extends IAmigoBaseStyles<IProfileInfoSubComponentStyles> {}

export interface IProfileInfoStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getProfileInfoClassNames = (
  styles?: IStyleFunctionOrObject<IProfileInfoStyleProps, IProfileInfoStyles>,
  props?: IProfileInfoStyleProps
): [
  IProcessedStyleSet<IProfileInfoStyles>,
  IProfileInfoSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IProfileInfoStyleProps,
  IProfileInfoStyles
> = (props: IProfileInfoStyleProps): IProfileInfoStyles => {
  return {
    root: [
      "amigo-ProfileInfo",
      {
        padding: props.mobile ? "10px" : "20px",
      },
    ],
  };
};
