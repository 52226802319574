import axios from "axios";
import { IEnterpriseReference } from "../../models/Enterprises/IEnterpriseReference";
import { IEnterpriseContact } from "../../models/Enterprises/IEnterpriseContact";
import { IObjectReference } from "../../models/IObjectReference";
import AuthService from "../AuthService";

class EnterpriseService {
  public init() {
    /* nothing to initialize yet */
  }

  public async getEnterprise(
    source: IObjectReference
  ): Promise<IEnterpriseReference> {
    const x = await axios.post(
      `/api/enterprises/${source.key}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getEnterpriseContacts(
    enterpriseId: number
  ): Promise<IEnterpriseContact[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/contacts`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async saveContacts(
    enterpriseId: number,
    contacts: IEnterpriseContact[]
  ): Promise<any> {
    const x = await axios.patch(
      `/api/enterprises/${enterpriseId}/contacts`,
      contacts,
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async updateEnterpriseNumber(
    enterpriseId: number,
    newneq: string
  ): Promise<boolean> {
    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/update-neq`,
        { enterpriseId: enterpriseId, enterpriseNumber: newneq },
        AuthService.getAuthHeader()
      );
      return x.status < 299;
    } catch (ex) {
      return false;
    }
  }

  public async updateAlertDeltas(
    enterpriseId: number,
    deltas: number[]
  ): Promise<boolean> {
    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/update-alert-deltas`,
        { enterpriseId: enterpriseId, deltas: deltas },
        AuthService.getAuthHeader()
      );
      return x.status < 299;
    } catch (ex) {
      return false;
    }
  }
}

export default new EnterpriseService();
