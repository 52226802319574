import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightOfferContainerSubComponentStyles {}

export interface IFlightOfferContainerStyles
  extends IAmigoBaseStyles<IFlightOfferContainerSubComponentStyles> {}

export interface IFlightOfferContainerStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightOfferContainerClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightOfferContainerStyleProps,
    IFlightOfferContainerStyles
  >,
  props?: IFlightOfferContainerStyleProps
): [
  IProcessedStyleSet<IFlightOfferContainerStyles>,
  IFlightOfferContainerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightOfferContainerStyleProps,
  IFlightOfferContainerStyles
> = (_props: IFlightOfferContainerStyleProps): IFlightOfferContainerStyles => {
  return {
    root: [
      "amigo-FlightOfferContainer",
      {
        width: "100%",
      },
    ],
  };
};
