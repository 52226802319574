import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentMethodSelectorProps } from "./NewPaymentMethodSelector.types";
import {
  getStyles,
  INewPaymentMethodSelectorStyleProps,
  INewPaymentMethodSelectorStyles,
} from "./NewPaymentMethodSelector.styles";
import { NewPaymentMethodSelectorComponent } from "./NewPaymentMethodSelector.base";

/**
 * NewPaymentMethodSelector description
 */
export const NewPaymentMethodSelector = styled<
  INewPaymentMethodSelectorProps,
  INewPaymentMethodSelectorStyleProps,
  INewPaymentMethodSelectorStyles
>(NewPaymentMethodSelectorComponent, getStyles);
