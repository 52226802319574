import { ContextualMenuItemType, IconButton } from "@fluentui/react";
import * as React from "react";
import i18n from "../../services/i18n";
import { NavBar } from "../NavBar";
import { NavigationMenuPanel } from "../NavigationMenuPanel";
import { getNavHeaderClassNames } from "./NavHeader.styles";
import { INavHeaderProps, INavHeaderState } from "./NavHeader.types";

export class NavHeaderComponent extends React.Component<
  INavHeaderProps,
  INavHeaderState
> {
  constructor(props: INavHeaderProps) {
    super(props);
    this.state = {};
  }

  render() {
    const { styles, route, mobile } = this.props;
    const [classNames, subComponentStyles] = getNavHeaderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return mobile ? (
      <div className={classNames.root}>
        <NavigationMenuPanel
          {...this.props}
          styles={subComponentStyles?.navigationMenuPanel}
        />
        <div className={classNames.logo}></div>
        <div>
          <IconButton
            className={classNames.profileButton}
            data-automation-id="nav:profile"
            iconProps={{
              iconName: "Signin",
              className: classNames.profileButton,
            }}
            menuIconProps={{
              style: { display: "none" },
              iconName: undefined,
            }}
            menuProps={{
              shouldFocusOnMount: true,
              items: [
                {
                  key: "profile",
                  "data-automation-id": "nav:profile:profile",
                  text: i18n.t("menu:profile"),
                  onClick: (_s, _e) => {
                    route.history.push("/profile");
                  },
                },
                {
                  key: "divider_1",
                  itemType: ContextualMenuItemType.Divider,
                },
                {
                  key: "logout",
                  "data-automation-id": "nav:profile:logout",
                  text: i18n.t("menu:logout"),
                  onClick: (_s, _e) => {
                    route.history.push("/deconnexion");
                  },
                },
              ],
            }}
          />
        </div>
      </div>
    ) : (
      <div className={classNames.root}>
        <div
          onClick={() => route.history.push("/")}
          className={classNames.logo}
        ></div>
        <NavBar {...this.props} styles={subComponentStyles?.navBar} />
      </div>
    );
  }
}
