import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IArrivalAssistedControlSubComponentStyles {}

export interface IArrivalAssistedControlStyles
  extends IAmigoBaseStyles<IArrivalAssistedControlSubComponentStyles> {
  nameText?: IStyle;
}

export interface IArrivalAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getArrivalAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IArrivalAssistedControlStyleProps,
    IArrivalAssistedControlStyles
  >,
  props?: IArrivalAssistedControlStyleProps
): [
  IProcessedStyleSet<IArrivalAssistedControlStyles>,
  IArrivalAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IArrivalAssistedControlStyleProps,
  IArrivalAssistedControlStyles
> = (
  _props: IArrivalAssistedControlStyleProps
): IArrivalAssistedControlStyles => {
  return {
    root: ["amigo-ArrivalAssistedControl", {}],
  };
};
