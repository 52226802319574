import {
  IColumn,
  Icon,
  ITooltipHostStyles,
  Selection,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import moment from "moment";
import * as React from "react";
import {
  ensureSelection,
  GridViewOptionsBuilder,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { AlertSpotlight } from "src/pages/Alerts/AlertSpotlight/AlertSpotlight";
import { LabelField } from "src/components/LabelField";
import PreferencesService from "src/services/PreferencesService";
import { GridView } from "../../../components/GridView";
import { IAlert } from "../../../models/Alerts/IAlert";
import { IQueryFilter } from "../../../models/IQueryFilter";
import AlertsService from "../../../services/Alerts/AlertsService";
import i18n from "../../../services/i18n";
import { AlertCard } from "../AlertCard";
import { AlertDetails } from "../AlertDetails";
import { getAlertsControlClassNames } from "./AlertsControl.styles";
import {
  IAlertsControlProps,
  IAlertsControlState,
} from "./AlertsControl.types";
import { Card } from "@fluentui/react-cards";

const resolveSpotlight = (items: IAlert[]) => {
  const spotlights = items.filter(
    (x, i) => i < 14 && x.type === "Travailleurs prêts à voyager" && (x.seenOn ?? "") === ""
  );
  return spotlights.length === 0 ? undefined : spotlights[0];
};

export class AlertsControlComponent extends React.Component<
  IAlertsControlProps,
  IAlertsControlState
> {
  constructor(props: IAlertsControlProps) {
    super(props);
    this.state = {
      options: PreferencesService.getOptionsOrDefault(
        "alerts",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: PreferencesService.getFiltersOrDefault(
        "alerts",
        false,
        this.defaultFilters
      ),
      items: [],
    };
    AlertsService.countAlerts(
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters
    ).then((x) =>
      this.setState({
        totalCount: x,
      })
    );
    AlertsService.getAlerts(
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        spotlight: resolveSpotlight(x),
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private defaultFilters = (): IQueryFilter[] => {
    return [
      {
        name: "alerts:status",
        fieldName: "seenOn",
        type: "null",
        value: { id: 1, name: "alerts:all" },
        choices: [
          { id: 1, name: "alerts:all" },
          { id: 0, name: "alerts:unread" },
        ],
      },
    ];
  };

  /*

  private openAndAcknowledge = (item: IAlert): void => {
    if (item.seenOn.length === 0) {
      AlertsService.acknownledge(item.id).then((x) => {
        const { alert } = this.state;
        if (alert !== undefined) {
          alert.seenOn = Moment(new Date()).format("YYYY-MM-DD HH:mm");
          this.setState({
            alert: alert,
          });
        }
      });
    }
    this.setState({
      alert: item,
    });
  };

*/

  private onItemClick = (item: IAlert) => {
    if (item.seenOn.length === 0) {
      AlertsService.acknownledge(item.id)
        .then((_x) => {
          const newItems = this.state.items.map((x) => {
            return x.id === item.id
              ? {
                  ...item,
                  seenOn: moment(new Date()).format("yyyy-MM-DD HH:mm"),
                }
              : x;
          });
          this.setState({
            alert: item,
            items: newItems,
          });
        })
        .catch(() =>
          this.setState({
            alert: item,
          })
        );
    } else {
      this.setState({
        alert: item,
      });
    }
  };

  render() {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] = getAlertsControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { spotlight } = this.state;
    return (
      <>
        {spotlight && (
          <AlertSpotlight
            {...this.props}
            spotlight={spotlight}
            styles={undefined}
            onOpen={(item) =>
              this.setState({ alert: item, spotlight: undefined })
            }
            onDismiss={() => this.setState({ spotlight: undefined })}
          />
        )}
        <Card
          styles={{
            root: {
              backgroundColor:
                this.state.options.mode === GridViewMode.tiles
                  ? undefined
                  : "#fff",
              margin:
                this.state.options.mode === GridViewMode.tiles ||
                mobile ||
                tablet
                  ? undefined
                  : "0 4px 10px 4px",
              padding:
                this.state.options.mode === GridViewMode.tiles
                  ? undefined
                  : mobile
                  ? 0
                  : tablet
                  ? "0 10px 10px 10px"
                  : "0 30px 10px 30px",
              height: "auto",
              maxHeight: "unset",
              width: "100%",
              maxWidth: "unset",
            },
          }}
        >
          <Card.Item>
            <GridView
              {...this.props}
              styles={subComponentStyles?.gridView}
              totalCount={this.state.totalCount}
              onItemInvoked={(item: IAlert) => {
                this.setState({
                  alert: item,
                });
              }}
              fullTextEnabled={false}
              onRenderTile={(
                item: IAlert,
                columnWidth: number,
                selection: Selection,
                callback: (sel: Selection) => void
              ): JSX.Element => {
                return (
                  <AlertCard
                    {...this.props}
                    styles={subComponentStyles?.gridCard}
                    item={item}
                    columnWidth={columnWidth}
                    selection={selection}
                    onSelected={(item: IAlert) => {
                      ensureSelection(selection, item);
                      selection.setKeySelected(
                        item.key,
                        selection
                          .getSelection()
                          .filter((x) => x.key === item.key).length === 0,
                        false
                      );
                      callback(selection);
                    }}
                    onClick={this.onItemClick}
                  />
                );
              }}
              onSort={(fieldName: string, desc: boolean) => {
                AlertsService.init();
                AlertsService.getAlerts(
                  fieldName,
                  desc,
                  this.state.filters,
                  false
                ).then((x) => {
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      GridViewOptionsBuilder.getOptions(
                        this.state.options.mode,
                        fieldName,
                        desc,
                        true
                      ),
                      "alerts"
                    ),
                    items: x,
                  });
                });
                GridViewOptionsBuilder.unfetched(this);
              }}
              options={this.state.options}
              onOptionsChanged={(options: IGridViewOptions) => {
                this.setState({
                  options: PreferencesService.asCommittedOptions(
                    options,
                    "alerts"
                  ),
                });
              }}
              columns={[
                {
                  key: "name",
                  name: "alerts:title",
                  minWidth: undefined,
                },
                {
                  key: "type",
                  name: "alerts:type",
                  minWidth: undefined,
                },
                {
                  key: "createdOn",
                  name: "alerts:createdon",
                  minWidth: 150,
                },
                {
                  key: "seenOn",
                  name: "alerts:seenOn",
                  minWidth: 150,
                },
              ]}
              onRenderCell={(item?: any, index?: number, column?: IColumn) => {
                const calloutProps = { gapSpace: 0 };
                const hostStyles: Partial<ITooltipHostStyles> = {
                  root: { display: "inline-block" },
                };
                return column === undefined ||
                  column.fieldName === undefined ? (
                  <React.Fragment />
                ) : column.fieldName === "type" ? (
                  <TooltipHost
                    content={
                      (item.comments ?? "") === "" ? (
                        item.description
                      ) : (
                        <Stack tokens={{ childrenGap: 5 }}>
                          <Text>{item.description}</Text>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: item.comments.replace(/\r\n/g, "<br />"),
                            }}
                          ></span>
                        </Stack>
                      )
                    }
                    calloutProps={calloutProps}
                    styles={hostStyles}
                  >
                    <Text
                      onClick={() => this.onItemClick(item)}
                      data-automation-id={`nvx:alerts:items:${index}:${column.fieldName}`}
                      style={{
                        cursor: "pointer",
                        fontWeight:
                          item.seenOn !== undefined &&
                          item.seenOn != null &&
                          item.seenOn.length === 0
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {item[column.fieldName]}
                    </Text>
                    <Icon
                      className={classNames.typeColumIcon}
                      iconName="Info"
                    />
                  </TooltipHost>
                ) : (
                  <Text
                    data-automation-id={`nvx:alerts:items:${index}:${column.fieldName}`}
                    onClick={() => this.onItemClick(item)}
                    style={{
                      cursor: "pointer",
                      fontWeight:
                        item.seenOn !== undefined &&
                        item.seenOn != null &&
                        item.seenOn.length === 0
                          ? "bold"
                          : "normal",
                    }}
                  >
                    {item[column.fieldName]}
                  </Text>
                );
              }}
              onDataPaging={() => {
                AlertsService.getAlerts(
                  this.state.options.sortBy,
                  this.state.options.desc,
                  this.state.filters,
                  true
                ).then((x) => {
                  this.setState({
                    items: x,
                    options: GridViewOptionsBuilder.getFetched(this),
                  });
                });
              }}
              onApplyFilters={(
                filters: IQueryFilter[],
                callback: () => void
              ) => {
                AlertsService.init();
                AlertsService.countAlerts(
                  this.state.options.sortBy,
                  this.state.options.desc,
                  filters
                ).then((x) =>
                  this.setState({
                    totalCount: x,
                  })
                );
                AlertsService.getAlerts(
                  this.state.options.sortBy,
                  this.state.options.desc,
                  filters,
                  undefined
                ).then((x) => {
                  callback();
                  this.setState({
                    items: x,
                    filters: PreferencesService.asCommittedFilters(
                      filters,
                      "alerts"
                    ),
                    options: GridViewOptionsBuilder.getFetched(this),
                  });
                });
                GridViewOptionsBuilder.unfetched(this);
              }}
              onQueryFilters={(reset: boolean) => {
                return AlertsService.getAlertTypes().then((x) => {
                  return PreferencesService.getFiltersOrDefault(
                    "alerts",
                    reset,
                    () => {
                      return [
                        {
                          name: "alerts:title",
                          fieldName: "name",
                          type: "text",
                          value: "",
                        },
                        {
                          name: "alerts:status",
                          fieldName: "seenOn",
                          type: "null",
                          value: { id: 0, name: "alerts:unread" },
                          choices: [
                            { id: 1, name: "alerts:all" },
                            { id: 0, name: "alerts:unread" },
                          ],
                        },
                        {
                          name: "alerts:type",
                          fieldName: "type",
                          type: "choice",
                          value: x,
                          choices: x,
                        },
                        {
                          name: "alerts:createdon:filter",
                          fieldName: "createdOn",
                          type: "date",
                          value: null,
                        },
                      ];
                    }
                  );
                });
              }}
              items={this.state.items}
              openedItem={this.state.alert}
              commands={[
                {
                  key: "delete",
                  name: i18n.t("alerts:delete"),
                  icon: "Delete",
                  selectionRequired: true,
                  confirmMessage: (items: IAlert[]) => {
                    return items.length > 1
                      ? i18n.t("alerts:deletemessage:plural")
                      : i18n.t("alerts:deletemessage:simple");
                  },
                  confirmTitle: i18n.t("alerts:deletetitle"),
                  onClick: (alerts: IAlert[], onCompleted: () => void) => {
                    AlertsService.deleteAlerts(
                      alerts.length === 0
                        ? this.state.alert === undefined
                          ? []
                          : [this.state.alert]
                        : alerts
                    )
                      .then((_x) => {
                        AlertsService.init();
                        AlertsService.getAlerts(
                          this.state.options.sortBy,
                          this.state.options.desc,
                          this.state.filters,
                          undefined
                        )
                          .then((x) => {
                            this.setState({
                              items: x,
                            });
                            onCompleted();
                          })
                          .catch((_x) => {
                            /* TODO: Annonce error to client */
                            onCompleted();
                          });
                      })
                      .catch((_x) => {
                        /* TODO: Annonce error to client */
                        onCompleted();
                      });
                  },
                },
              ]}
              entityPanelProps={{
                ...this.props,
                styles: undefined,
                elementName: i18n.t("alert:element"),
                title: this.state.alert?.name,
                headerIconName: "WorkItemAlert",
                isOpen: this.state.alert !== undefined,
                "data-automation-id": "nvx:alerts:itempanel",
                onDismiss: () => {
                  this.setState({
                    alert: undefined,
                  });
                },
                children:
                  this.state.alert === undefined ? (
                    <span></span>
                  ) : (
                    <div>
                      <div style={{ height: "40px" }}></div>
                      <LabelField
                        {...this.props}
                        label={i18n.t("alerts:title")}
                        styles={subComponentStyles?.label}
                        content={this.state.alert?.name}
                      />
                      <LabelField
                        {...this.props}
                        label={i18n.t("alerts:type")}
                        styles={subComponentStyles?.label}
                        content={this.state.alert?.type}
                      />
                      <LabelField
                        {...this.props}
                        label={i18n.t("alerts:createdon")}
                        styles={subComponentStyles?.label}
                        content={this.state.alert?.createdOn}
                      />
                      <LabelField
                        {...this.props}
                        label={i18n.t("alerts:seenOn")}
                        styles={subComponentStyles?.label}
                        content={
                          this.state.alert?.seenOn.length === 0
                            ? i18n.t("global:justnow")
                            : this.state.alert?.seenOn
                        }
                      />
                      <LabelField
                        {...this.props}
                        maxWidth={this.props.mobile ? 298 : 368}
                        label={i18n.t("alerts:description")}
                        styles={subComponentStyles?.label}
                        content={this.state.alert?.description}
                      />
                      <div style={{ height: "10px" }}></div>
                      <AlertDetails
                        {...this.props}
                        styles={subComponentStyles?.alertDetails}
                        alertId={
                          this.state.alert === undefined
                            ? 0
                            : this.state.alert?.id
                        }
                      />
                    </div>
                  ),
              }}
            />
          </Card.Item>
        </Card>
      </>
    );
  }
}
