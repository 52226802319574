import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IMessagesListControlSubComponentStyles {}

export interface IMessagesListControlStyles
  extends IAmigoBaseStyles<IMessagesListControlSubComponentStyles> {
  container?: IStyle;
  itemContent?: IStyle;
  itemContentSelected?: IStyle;
  messageContainer?: IStyle;
}

export interface IMessagesListControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessagesListControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessagesListControlStyleProps,
    IMessagesListControlStyles
  >,
  props?: IMessagesListControlStyleProps
): [
  IProcessedStyleSet<IMessagesListControlStyles>,
  IMessagesListControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessagesListControlStyleProps,
  IMessagesListControlStyles
> = (props: IMessagesListControlStyleProps): IMessagesListControlStyles => {
  return {
    messageContainer: {
      position: "relative",
      cursor: "pointer",
      borderBottom: "1px solid " + props.theme.palette.neutralLighter,
    },
    container: {
      overflow: "auto",
      maxHeight: 500,
      marginTop: 20,
      selectors: {
        ".ms-List-cell:nth-child(odd)": {
          height: 50,
          lineHeight: 50,
          background: props.theme.palette.neutralLighter,
        },
        ".ms-List-cell:nth-child(even)": {
          height: 25,
          lineHeight: 25,
        },
      },
    },
    itemContent: [
      props.theme.fonts.medium,
      {
        position: "relative",
        display: "block",
        borderLeft: "3px solid " + props.theme.palette.themePrimary,
        paddingLeft: 2,
      },
    ],
    itemContentSelected: [
      props.theme.fonts.medium,
      {
        position: "relative",
        display: "block",
        borderLeft: "4px solid " + props.theme.palette.themePrimary,
        paddingLeft: 2,
        backgroundColor: "rgb(0, 120, 212, 0.1)",
      },
    ],
  };
};
