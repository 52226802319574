import * as React from "react";
import { getParticipantsPageClassNames } from "./ParticipantsPage.styles";
import {
  IParticipantsPageState,
  IParticipantsPageProps,
} from "./ParticipantsPage.types";
import { ParticipantsControl } from "../ParticipantsControl";
import { GenericPageHeader } from "src/components/GenericPageHeader/GenericPageHeader";
import { uniqueId } from "src/utils/uniqueId";

export class ParticipantsPageComponent extends React.Component<
  IParticipantsPageProps,
  IParticipantsPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getParticipantsPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        <GenericPageHeader
          title="Participants"
          {...this.props}
          styles={undefined}
          themeIndex={2}
        />
        <ParticipantsControl
          {...this.props}
          scrollableTargetId={id}
          styles={subComponentStyles?.control}
        />
      </div>
    );
  }
}
