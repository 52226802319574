import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IReloadPageSubComponentStyles {}

export interface IReloadPageStyles
  extends IAmigoBaseStyles<IReloadPageSubComponentStyles> {}

export interface IReloadPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getReloadPageClassNames = (
  styles?: IStyleFunctionOrObject<IReloadPageStyleProps, IReloadPageStyles>,
  props?: IReloadPageStyleProps
): [
  IProcessedStyleSet<IReloadPageStyles>,
  IReloadPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IReloadPageStyleProps,
  IReloadPageStyles
> = (_props: IReloadPageStyleProps): IReloadPageStyles => {
  return {};
};
