import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";

export interface INewPaymentBillsSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
}

export interface INewPaymentBillsStyles
  extends IAmigoBaseStyles<INewPaymentBillsSubComponentStyles> {}

export interface INewPaymentBillsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentBillsClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentBillsStyleProps,
    INewPaymentBillsStyles
  >,
  props?: INewPaymentBillsStyleProps
): [
  IProcessedStyleSet<INewPaymentBillsStyles>,
  INewPaymentBillsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentBillsStyleProps,
  INewPaymentBillsStyles
> = (_props: INewPaymentBillsStyleProps): INewPaymentBillsStyles => {
  return {};
};
