import { styled } from "@fluentui/react/lib/Utilities";
import { AppEnterpriseMenuComponent } from "./AppEnterpriseMenu.base";
import {
  getStyles,
  IAppEnterpriseMenuStyleProps,
  IAppEnterpriseMenuStyles,
} from "./AppEnterpriseMenu.styles";
import { IAppEnterpriseMenuProps } from "./AppEnterpriseMenu.types";

/**
 * AppEnterpriseMenu description
 */
export const AppEnterpriseMenu = styled<
  IAppEnterpriseMenuProps,
  IAppEnterpriseMenuStyleProps,
  IAppEnterpriseMenuStyles
>(AppEnterpriseMenuComponent, getStyles);
