import * as React from "react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getPendingBillPanelClassNames } from "./PendingBillPanel.styles";
import {
  IPendingBillPanelProps,
  IPendingBillPanelState,
} from "./PendingBillPanel.types";

export class PendingBillPanelComponent extends React.Component<
  IPendingBillPanelProps,
  IPendingBillPanelState
> {
  render(): JSX.Element {
    const { styles, billingElement } = this.props;
    const [classNames, subComponentStyles] = getPendingBillPanelClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        {billingElement !== undefined && (
          <div style={{ marginTop: "30px" }}>
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:billingnumber")}
              content={billingElement.billingNumber}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:billingdate")}
              content={billingElement.billingDate}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:status")}
              content={billingElement.status}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:subtotal")}
              content={billingElement.subtotal}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:tps")}
              content={billingElement.tps}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:tvq")}
              content={billingElement.tvq}
            />
            <LabelField
              {...this.props}
              styles={subComponentStyles?.label}
              label={i18n.t("billing:total")}
              content={billingElement.total}
            />
          </div>
        )}
      </div>
    );
  }
}
