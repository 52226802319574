import {
  INavStyleProps,
  INavStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";
import { MediaQueries } from "../MediaQueries";

export interface INavigationMenuSubComponentStyles {
  nav?: IStyleFunctionOrObject<INavStyleProps, INavStyles>;
}

export interface INavigationMenuStyles
  extends IComponentBaseStyles<INavigationMenuSubComponentStyles> {}

export interface INavigationMenuStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  collapsed?: boolean;
}

export const getNavigationMenuClassNames = (
  styles?: IStyleFunctionOrObject<
    INavigationMenuStyleProps,
    INavigationMenuStyles
  >,
  props?: INavigationMenuStyleProps
): [
  IProcessedStyleSet<INavigationMenuStyles>,
  INavigationMenuSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INavigationMenuStyleProps,
  INavigationMenuStyles
> = (_props: INavigationMenuStyleProps): INavigationMenuStyles => {
  return {
    root: [
      "ms-NavigationMenu",
      {
        marginLeft: "3px!important",
      },
    ],
    subComponentStyles: {
      nav: {
        root: {
          overflowX: "hidden",
        },
        link: {
          paddingRight: 0,
        },
        linkText: {
          selectors: {
            [MediaQueries.mobile]: {
              fontWeight: "bold",
              fontSize: "16px",
            },
          },
        },
      },
    },
  };
};
