import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IPaymentDetailInfoSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IPaymentDetailInfoStyles
  extends IAmigoBaseStyles<IPaymentDetailInfoSubComponentStyles> {}

export interface IPaymentDetailInfoStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentDetailInfoClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentDetailInfoStyleProps,
    IPaymentDetailInfoStyles
  >,
  props?: IPaymentDetailInfoStyleProps
): [
  IProcessedStyleSet<IPaymentDetailInfoStyles>,
  IPaymentDetailInfoSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentDetailInfoStyleProps,
  IPaymentDetailInfoStyles
> = (_props: IPaymentDetailInfoStyleProps): IPaymentDetailInfoStyles => {
  return {
    root: ["amigo-PaymentDetailInfo", {}],
  };
};
