import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IParticipantHistoryCardStyleProps,
  IParticipantHistoryCardStyles,
} from "../ParticipantHistoryCard";

export interface IParticipantHistoryControlSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IParticipantHistoryCardStyleProps,
    IParticipantHistoryCardStyles
  >;
}

export interface IParticipantHistoryControlStyles
  extends IAmigoBaseStyles<IParticipantHistoryControlSubComponentStyles> {}

export interface IParticipantHistoryControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantHistoryControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantHistoryControlStyleProps,
    IParticipantHistoryControlStyles
  >,
  props?: IParticipantHistoryControlStyleProps
): [
  IProcessedStyleSet<IParticipantHistoryControlStyles>,
  IParticipantHistoryControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantHistoryControlStyleProps,
  IParticipantHistoryControlStyles
> = (
  _props: IParticipantHistoryControlStyleProps
): IParticipantHistoryControlStyles => {
  return {
    root: ["amigo-ParticipantHistoryControl", {}],
  };
};
