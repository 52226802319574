import * as React from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { AnimatedSwitch } from "../components/AnimatedSwitch";
import { MasterPage } from "../components/MasterPage/MasterPage";
import { PrivateRoute } from "../components/PrivateRoute";
import {
  AlertsPage,
  BillingPage,
  DocumentsPage,
  EnterprisesPage,
  Error500Page,
  ForgotPasswordPage,
  DossierImmigrationDetailPage,
  DossierImmigrationPage,
  LoginPage,
  LogoutPage,
  MessagesPage,
  NewPaymentPage,
  ParticipantDetailsPage,
  ParticipantsPage,
  ProfilePage,
  ReloadPage,
  RequestDetailPage,
  RequestPeriodPage,
  RequestsPage,
  ResetPasswordPage,
  TransportsPage,
  PaymentDetailPage,
  TransportOfferPage,
  /*
  FlightSearchPage,
  FlightBookingPage,
  FlightBookingParticipantsPage,
  FlightCheckoutPage,
  FlightConfirmationPage,
  */
} from "../pages";
import { IAppRouterProps, IAppRouterState } from "./AppRouter.types";
import { getAppRouterClassNames } from "./AppRouter.styles";
import TelemetryProvider from "../services/telemetry-provider";
import TelemetryIntitializer from "src/services/TelemetryIntitializer";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import AuthService from "src/services/AuthService";
import { objectId } from "src/models/IObjectReference";
export class AppRouterComponent extends React.Component<
  IAppRouterProps,
  IAppRouterState
> {
  private appInsights: ApplicationInsights | null = null;
  constructor(props: IAppRouterProps) {
    super(props);
    (window as any).onLanguageChanged = this.props.onLanguageChanged;
  }

  render(): JSX.Element {
    const { styles, onEnterpriseChanged } = this.props;
    const [classNames, subComponentStyles] = getAppRouterClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Router>
        <Switch>
          <Route
            path="/"
            render={(route) => {
              return (
                <TelemetryProvider
                  {...route}
                  enabled
                  instrumentationKey={"b9b1f293-81de-4ecf-9bc7-503eae39e845"}
                  after={() => {
                    this.appInsights = TelemetryIntitializer.getAppInsights();
                  }}
                >
                  <span className={classNames.root}></span>
                  <Switch>
                    <Route
                      exact
                      path="/erreur/500"
                      render={(props) => {
                        return (
                          <Error500Page
                            {...this.props}
                            route={props}
                            styles={undefined}
                          />
                        );
                      }}
                    />
                    <Route
                      exact
                      path="/deconnexion"
                      render={(props) => {
                        return (
                          <LogoutPage
                            {...this.props}
                            route={props}
                            appInsights={
                              this.appInsights ?? (this.appInsights as any)
                            }
                            styles={undefined}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/oublie-mot-de-passe"
                      exact
                      render={(props) => {
                        return (
                          <ForgotPasswordPage
                            {...this.props}
                            route={props}
                            styles={undefined}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/reinitialisation-mot-de-passe"
                      strict
                      render={(props) => {
                        return (
                          <ResetPasswordPage
                            {...this.props}
                            route={props}
                            styles={undefined}
                          />
                        );
                      }}
                    />
                    <Route
                      path="/connexion"
                      strict
                      render={(props) => {
                        return (
                          <LoginPage
                            {...this.props}
                            route={props}
                            styles={undefined}
                            appInsights={
                              this.appInsights ?? (this.appInsights as any)
                            }
                          />
                        );
                      }}
                    />
                    <Route
                      path="/reload"
                      exact
                      render={(props) => {
                        return (
                          <ReloadPage
                            {...this.props}
                            route={props}
                            styles={undefined}
                          />
                        );
                      }}
                    />
                    <PrivateRoute
                      path="/"
                      render={(props) => {
                        return (
                          <MasterPage
                            {...this.props}
                            enterpriseId={
                              objectId(this.props.enterprise)?.toString() ?? ""
                            }
                            route={props}
                            styles={subComponentStyles?.masterPage}
                          >
                            <AnimatedSwitch {...this.props}>
                              <Route
                                path="/demandes/:id/periodes/:period"
                                render={(props) => {
                                  return (
                                    <RequestPeriodPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/demandes/:id/versions/:version"
                                render={(props) => {
                                  return (
                                    <RequestDetailPage
                                      versionned={true}
                                      tab={"0"}
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/demandes/:id"
                                render={(props) => {
                                  return (
                                    <RequestDetailPage
                                      versionned={false}
                                      tab={"0"}
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />

                              <Route
                                path="/demandes"
                                render={(props) => {
                                  return (
                                    <RequestsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/immigration/:id"
                                render={(props) => {
                                  return (
                                    <DossierImmigrationDetailPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/immigration"
                                render={(props) => {
                                  return (
                                    <DossierImmigrationPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/participants/:id"
                                render={(props) => {
                                  return (
                                    <ParticipantDetailsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/participants"
                                render={(props) => {
                                  return (
                                    <ParticipantsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/facturation/paiements/nouveau"
                                exact
                                render={(props) => {
                                  return (
                                    <NewPaymentPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/facturation/factures"
                                render={(props) => {
                                  return (
                                    <BillingPage
                                      routePath={"factures"}
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/facturation/paiements/:id"
                                render={(props) => {
                                  return (
                                    <PaymentDetailPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/facturation/paiements"
                                render={(props) => {
                                  return (
                                    <BillingPage
                                      routePath={"paiements"}
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/facturation"
                                exact
                                render={(_props) => {
                                  return (
                                    <Redirect to={"/facturation/factures"} />
                                  );
                                }}
                              />
                              <Route
                                path="/entreprises"
                                render={(props) => {
                                  return (
                                    <EnterprisesPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/offre/:id"
                                render={(props) => {
                                  return (
                                    <TransportOfferPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/demandes/departs"
                                exact
                                render={(props) => {
                                  return (
                                    <TransportsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      routePath={"departs"}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/demandes/arrivees"
                                exact
                                render={(props) => {
                                  return (
                                    <TransportsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      routePath={"arrivees"}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/billetterie"
                                render={(props) => {
                                  return (
                                    <TransportsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      routePath={"billetterie"}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/sommaire"
                                render={(props) => {
                                  return (
                                    <TransportsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      routePath={"summary"}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/transports/demandes"
                                exact
                                render={(_props) => {
                                  return (
                                    <Redirect
                                      to={"/transports/demandes/arrivees"}
                                    />
                                  );
                                }}
                              />

                              <Route
                                path="/transports"
                                exact
                                render={(_props) => {
                                  return (
                                    <Redirect to={"/transports/sommaire"} />
                                  );
                                }}
                              />
                              <Route
                                path="/documents"
                                render={(props) => {
                                  return (
                                    <DocumentsPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/messages"
                                render={(props) => {
                                  return (
                                    <MessagesPage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/profile"
                                render={(props) => {
                                  return (
                                    <ProfilePage
                                      {...this.props}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                              <Route
                                path="/"
                                strict
                                render={(props) => {
                                  return (
                                    <AlertsPage
                                      {...this.props}
                                      onEnterpriseChanged={(id: number) => {
                                        const user = AuthService.getUserInfo();
                                        if (user != null)
                                        {
                                          if (id !== objectId(this.props.enterprise))
                                          {
                                            const e = user.enterprises.filter(x => x.key === id)[0];
                                            onEnterpriseChanged(e);
                                          }
                                        }
                                      }}
                                      tokens={{
                                        ...this.props.tokens,
                                        menucollapsed: this.props.menucollapsed,
                                      }}
                                      route={props}
                                      styles={undefined}
                                    />
                                  );
                                }}
                              />
                            </AnimatedSwitch>
                          </MasterPage>
                        );
                      }}
                    ></PrivateRoute>
                  </Switch>
                </TelemetryProvider>
              );
            }}
          />
        </Switch>
      </Router>
    );
  }
}
