import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IPaymentMethodSelectorCardStyleProps,
  IPaymentMethodSelectorCardStyles,
} from "../PaymentMethodSelectorCard";
import { MediaQueries } from "src/components/MediaQueries";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";

export interface INewPaymentMethodSelectorSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  paymentCard?: IStyleFunctionOrObject<
    IPaymentMethodSelectorCardStyleProps,
    IPaymentMethodSelectorCardStyles
  >;
}

export interface INewPaymentMethodSelectorStyles
  extends IAmigoBaseStyles<INewPaymentMethodSelectorSubComponentStyles> {}

export interface INewPaymentMethodSelectorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentMethodSelectorClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentMethodSelectorStyleProps,
    INewPaymentMethodSelectorStyles
  >,
  props?: INewPaymentMethodSelectorStyleProps
): [
  IProcessedStyleSet<INewPaymentMethodSelectorStyles>,
  INewPaymentMethodSelectorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentMethodSelectorStyleProps,
  INewPaymentMethodSelectorStyles
> = (
  _props: INewPaymentMethodSelectorStyleProps
): INewPaymentMethodSelectorStyles => {
  return {
    root: [
      "amigo-NewPaymentMethodSelector",
      {
        marginTop: "-10px",
        selectors: {
          [MediaQueries.mobile]: {
            marginTop: "-1px",
          },
        },
      },
    ],
  };
};
