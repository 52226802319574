import { DefaultPalette } from "@fluentui/react";

export interface ITransportRequestType {
  name: string;
  iconName: string;
  color: string;
  description: string;
}

export interface ITransportRequestTypes {
  regular: ITransportRequestType;
  charter: ITransportRequestType;
  confirmed: ITransportRequestType;
  leaving: ITransportRequestType;
  requested: ITransportRequestType;
  currenttransfert: ITransportRequestType;
  transfert: ITransportRequestType;
  othercurrent: ITransportRequestType;
  none: ITransportRequestType;
}

export const getTransportRequestType = (
  id: number
): ITransportRequestType | undefined => {
  return {
    0: TransportRequestTypes.none,
    1: TransportRequestTypes.regular,
    2: TransportRequestTypes.charter,
    3: TransportRequestTypes.confirmed,
    4: TransportRequestTypes.leaving,
    5: TransportRequestTypes.requested,
    6: TransportRequestTypes.transfert,
    7: TransportRequestTypes.currenttransfert,
    9: TransportRequestTypes.othercurrent,
  }[id];
};

export const TransportRequestTypes: ITransportRequestTypes = {
  none: {
    name: "requests:NONE",
    iconName: "UnknownSolid",
    color: DefaultPalette.neutralDark,
    description: "requests:NONE",
  },
  regular: {
    name: "transports:requests:legend:regular",
    iconName: "AirplaneSolid",
    color: DefaultPalette.purple,
    description: "transports:requests:legend:regular:desc",
  },
  charter: {
    name: "transports:requests:legend:charter",
    iconName: "AirplaneSolid",
    color: DefaultPalette.blue,
    description: "transports:requests:legend:charter:desc",
  },
  confirmed: {
    name: "transports:requests:legend:confirmed",
    iconName: "AirplaneSolid",
    color: DefaultPalette.green,
    description: "transports:requests:legend:confirmed:desc",
  },
  leaving: {
    name: "transports:requests:legend:leaving",
    iconName: "AirplaneSolid",
    color: DefaultPalette.red,
    description: "transports:requests:legend:leaving:desc",
  },
  requested: {
    name: "transports:requests:legend:requested",
    iconName: "AirplaneSolid",
    color: DefaultPalette.yellow,
    description: "transports:requests:legend:requested:desc",
  },
  currenttransfert: {
    name: "transports:requests:legend:currenttransfert",
    iconName: "NavigateForward",
    color: DefaultPalette.red,
    description: "transports:requests:legend:currenttransfert:desc",
  },
  transfert: {
    name: "transports:requests:legend:transfert",
    iconName: "NavigateForward",
    color: DefaultPalette.yellow,
    description: "transports:requests:legend:transfert:desc",
  },
  othercurrent: {
    name: "transports:requests:legend:othercurrent",
    iconName: "Suitcase",
    color: DefaultPalette.green,
    description: "transports:requests:legend:othercurrent:desc",
  },
  /* ,
  other: {
    name: "transports:requests:legend:other",
    iconName: "Suitcase",
    color: DefaultPalette.yellow,
    description: "transports:requests:legend:other:desc",
  },
  */
};
