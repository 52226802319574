import { IParticipant } from "./IParticipant";
import { IAddress } from "../IAddress";
import i18n from "src/services/i18n";
import { ISchemaObject } from "src/utils/validator";

export interface IParticipantAddressModel extends IAddress {
  phone: string;
  cellphone: string;
  email: string;
}

export interface IParticipantDetails extends IParticipant {
  createdOn: string;
  gender: string;
  insurance: string;
  modifiedOn: string;
  status: boolean;
  recallStatus: string;
  marks: string;
  email: string;
  phone: string;
  cellphone: string;
  address: IAddress;
}

export const ParticipantAddressDetailsSchema: ISchemaObject = {
  name: "participantaddress",
  plural: "participantaddresses",
  label: "Participantaddress",
  fields: {
    street: {
      name: "street",
      label: i18n.t("participants:street"),
      required: false,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("participants:street:error"),
      type: "string",
    },
    city: {
      name: "city",
      label: i18n.t("participants:city"),
      required: false,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("participants:city:error"),
      type: "string",
    },
    postalcode: {
      name: "postalcode",
      label: i18n.t("participants:postalcode"),
      required: false,
      pattern: "",
      length: [25],
      errorMessage: i18n.t("participants:postalcode:error"),
      type: "string",
    },
    email: {
      name: "email",
      label: i18n.t("participants:email"),
      required: false,
      pattern: "",
      length: [250],
      errorMessage: i18n.t("participants:phone:error"),
      type: "email",
    },
    phone: {
      name: "phone",
      label: i18n.t("participants:phone"),
      required: false,
      length: [100],
      pattern: "",
      errorMessage: i18n.t("participants:phone:error"),
      type: "string",
    },
    cellphone: {
      name: "cellphone",
      label: i18n.t("participants:cellphone"),
      required: false,
      length: [100],
      pattern: "",
      errorMessage: i18n.t("participants:cellphone:error"),
      type: "string",
    },
  },
};
