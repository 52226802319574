import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";

export interface IMaintenanceBarSubComponentStyles {}

export interface IMaintenanceBarStyles
  extends IComponentBaseStyles<IMaintenanceBarSubComponentStyles> {
  messageBar?: IStyle;
}

export interface IMaintenanceBarStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getMaintenanceBarClassNames = (
  styles?: IStyleFunctionOrObject<
    IMaintenanceBarStyleProps,
    IMaintenanceBarStyles
  >,
  props?: IMaintenanceBarStyleProps
): [
  IProcessedStyleSet<IMaintenanceBarStyles>,
  IMaintenanceBarSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMaintenanceBarStyleProps,
  IMaintenanceBarStyles
> = (_props: IMaintenanceBarStyleProps): IMaintenanceBarStyles => {
  return {
    messageBar: {
      margin: "4px",
    },
  };
};
