import { Calendar, CommandBarButton, Stack, Text } from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import { objectId } from "src/models/IObjectReference";
import PaymentsService from "src/services/Billing/PaymentsService";
import i18n from "src/services/i18n";
import { PaymentDetailInfo } from "../PaymentDetailInfo";
import { PaymentDetailList } from "../PaymentDetailList";
import { getPaymentDetailPageClassNames } from "./PaymentDetailPage.styles";
import {
  IPaymentDetailPageProps,
  IPaymentDetailPageState,
} from "./PaymentDetailPage.types";

export class PaymentDetailPageComponent extends React.Component<
  IPaymentDetailPageProps,
  IPaymentDetailPageState
> {
  constructor(props: IPaymentDetailPageProps) {
    super(props);
    this.state = {};
    PaymentsService.getPaymentDetail(
      objectId(props.enterprise),
      parseInt(props.route.match.params.id)
    ).then((x) => {
      this.setState({ paymentDetail: x });
    });
  }

  render(): JSX.Element {
    const { styles, mobile, route } = this.props;
    const [classNames] = getPaymentDetailPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { paymentDetail } = this.state;
    if (!paymentDetail) return <div></div>;
    const { billDetails } = paymentDetail;
    const paymentDate: Date = new Date(
      paymentDetail.paymentDate.replace(" ", "T")
    );
    const { paymentMethod } = paymentDetail;
    const expiry: string =
      paymentMethod === undefined
        ? ""
        : `${
            paymentMethod.expirationMonth < 10
              ? "0" + paymentMethod.expirationMonth.toString()
              : paymentMethod.expirationMonth.toString()
          }/${paymentMethod.expirationYear}`;
    return (
      <div className={classNames.root}>
        <IconHeader
          {...this.props}
          styles={undefined}
          iconName={"PaymentCard"}
          title={i18n.t("billing:payment:details")}
        />

        <CommandBarButton
          iconProps={{ iconName: "NavigateBack" }}
          text={i18n.t("billing:payments:back")}
          onClick={() => {
            route.history.push("/facturation/paiements");
          }}
        />
        <div style={{ height: "15px" }}></div>
        <Stack
          horizontal={!mobile}
          style={{ width: "100%", padding: mobile ? "10px" : "0" }}
          tokens={{ childrenGap: 10 }}
        >
          {paymentDetail && (
            <PaymentDetailInfo
              {...this.props}
              styles={undefined}
              paymentDetails={paymentDetail}
            />
          )}
          <div>
            {!mobile && (
              <LabelField
                {...this.props}
                styles={undefined}
                label={i18n.t("billing:payments:confirmation")}
                content={paymentDetail.confirmation}
              />
            )}
            <Text style={{ fontWeight: 600 }}>
              {i18n.t("billing:payments:confirmation:date")}
            </Text>
            <Calendar
              strings={i18n.CalendarStrings()}
              isMonthPickerVisible={false}
              minDate={paymentDate}
              maxDate={paymentDate}
              isDayPickerVisible={true}
              value={paymentDate}
              today={paymentDate}
              showGoToToday={false}
              showCloseButton={false}
            />
          </div>
          <div>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("enterprises:paymentmethods:element")}
              content={
                <div style={{ marginTop: "20px" }}>
                  <Cards
                    cvc={""}
                    focused={"number"}
                    name={paymentMethod?.name ?? ""}
                    number={paymentMethod?.card ?? ""}
                    expiry={expiry}
                  />
                </div>
              }
            />
          </div>
        </Stack>
        <PaymentDetailList
          {...this.props}
          styles={undefined}
          billDetails={billDetails}
        />
      </div>
    );
  }
}
