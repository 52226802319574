import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantTransfertAssistedEditorProps } from "./ParticipantTransfertAssistedEditor.types";
import {
  getStyles,
  IParticipantTransfertAssistedEditorStyleProps,
  IParticipantTransfertAssistedEditorStyles,
} from "./ParticipantTransfertAssistedEditor.styles";
import { ParticipantTransfertAssistedEditorComponent } from "./ParticipantTransfertAssistedEditor.base";

/**
 * ParticipantTransfertAssistedEditor
 */
export const ParticipantTransfertAssistedEditor = styled<
  IParticipantTransfertAssistedEditorProps,
  IParticipantTransfertAssistedEditorStyleProps,
  IParticipantTransfertAssistedEditorStyles
>(ParticipantTransfertAssistedEditorComponent, getStyles);
