/* eslint-disable no-unused-vars */
// Password strengths
export enum PasswordCheckStrength {
  Empty,
  Short,
  Common,
  Weak,
  Ok,
  Strong,
}
