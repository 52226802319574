import { styled } from "@fluentui/react/lib/Utilities";
import { IMessageEditorProps } from "./MessageEditor.types";
import {
  getStyles,
  IMessageEditorStyleProps,
  IMessageEditorStyles,
} from "./MessageEditor.styles";
import { MessageEditorComponent } from "./MessageEditor.base";

/**
 * MessageEditor
 */
export const MessageEditor = styled<
  IMessageEditorProps,
  IMessageEditorStyleProps,
  IMessageEditorStyles
>(MessageEditorComponent, getStyles);
