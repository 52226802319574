import { styled } from "@fluentui/react/lib/Utilities";
import { IForgotPasswordPageProps } from "./ForgotPasswordPage.types";
import {
  getStyles,
  IForgotPasswordPageStyleProps,
  IForgotPasswordPageStyles,
} from "./ForgotPasswordPage.styles";
import { ForgotPasswordPageComponent } from "./ForgotPasswordPage.base";

/**
 * ForgotPasswordPage description
 */
export const ForgotPasswordPage = styled<
  IForgotPasswordPageProps,
  IForgotPasswordPageStyleProps,
  IForgotPasswordPageStyles
>(ForgotPasswordPageComponent, getStyles);
