import {
  IMessageSummary,
  INewMessageModel,
} from "../../models/Messages/IMessageSummary";
import { IUser } from "../../models/User";
import axios from "axios";
import AuthService from "../AuthService";
import DocumentService from "../Documents/DocumentService";
import { IQueryFilter, IChoiceValue } from "src/models/IQueryFilter";
import { IMessagesStats } from "src/models/Messages/IMessagesStats";
import { __chars, __codes } from "./MessageServices.chars";

class MessagesService {
  private folders: IMessageSummary[][] = [];
  private foldersPages: number[] = [];

  public async save(item: INewMessageModel): Promise<IMessageSummary> {
    const x = await axios.post(
      "/api/messages/save-draft",
      {
        ...item,
        draft: true,
      },
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async send(item: INewMessageModel): Promise<IMessageSummary> {
    item.attachments.forEach((x) => {
      if (x.id > 0) x.data = null;
    });
    const x = await axios.post(
      "/api/messages/send-message",
      {
        ...item,
        attachments: item.attachments,
        draft: false,
      },
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getRecentRecipients(): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/recents`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getDepartureRecipients(): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/departure`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getInspectionRecipients(): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/inspection`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getArrivalRecipients(): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/arrival`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getTransfertConctacts(entepriseId: number): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/transferts?id=${entepriseId}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public async getTransportRecipients(): Promise<IUser[]> {
    const x = await axios.post(
      `/api/recipients/transport`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public init(folder: string | undefined): void {
    if (folder === undefined) {
      this.folders = [];
      this.foldersPages = [];
    } else {
      this.folders[folder] = undefined;
      this.foldersPages[folder] = 1;
    }
    this.pageSize = 20;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("timestamp", "CreerLe")
      .replace("subject", "Item.Titre")
      .replace("message", "Item.Message")
      .replace("from", "Item.CreerPar")
      .replace("type", "Item.TypeCommunication.Code");
  };

  private htmlConvert(item: string) {
    for (let x = 0; x < __chars.length; x++) {
      for (let i = 0; i < item.length; i++) {
        item = item.replace(__chars[x], __codes[x]);
      }
    }
    return item;
  }

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue:
                x.type === "text"
                  ? x.value && x.fieldName === "message"
                    ? this.htmlConvert(x.value.toString())
                    : x.value
                  : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) =>
                      x.fieldName === "country" ? z.name : z.id.toString()
                    )
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public getMessages(
    folder: string,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[],
    next: boolean | undefined
  ): Promise<IMessageSummary[]> {
    const _this = this;
    let currentPage = this.foldersPages[folder] ?? 1;
    let combine: boolean;
    if (next && _this.folders[folder] !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `/api/messages/${folder.toLowerCase()}`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data;
        if (combine && _this.folders[folder] !== undefined) {
          _this.foldersPages[folder] = currentPage;
          _this.folders[folder] = _this.folders[folder].concat(items);
        } else {
          _this.foldersPages[folder] = 1;
          _this.folders[folder] = this.folders[folder] = items;
        }
        return _this.folders[folder] ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this.folders ?? [];
      });
  }

  public deleteMessage(item: IMessageSummary): Promise<IMessageSummary> {
    return axios
      .delete(`/api/messages/${item.id}`, {
        headers: AuthService.getAuthHeader().headers,
        data: {},
      })
      .then((_x) => {
        return item;
      });
  }

  public async unread(): Promise<number> {
    try {
      const x = await axios.post(
        "api/messages/unread",
        {},
        AuthService.getAuthHeader()
      );
      return x.data as any;
    } catch (ex) {
      return 0;
    }
  }

  public async getStatistics(): Promise<IMessagesStats> {
    try {
      const x = await axios.post(
        "api/messages/stats",
        {},
        AuthService.getAuthHeader()
      );
      return x.data as any;
    } catch (ex) {
      return { unread: 0, drafts: 0 };
    }
  }

  public printMessage(item: IMessageSummary): Promise<void> {
    return axios
      .post(
        `/api/messages/${item.id}/export`,
        {},
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(item)}`);
        // send error to server
        throw new Error(x);
      });
  }

  public downloadAttachment(messageId: number, id: number): Promise<void> {
    return axios
      .post(
        `/api/messages/${messageId}/attachments/${id}`,
        {},
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing : ${id}`);
        // send error to server
        throw new Error(x);
      });
  }

  public ackownledge(id: number): Promise<any> {
    return axios.post(
      `api/messages/acknowledge/${id}`,
      {},
      AuthService.getAuthHeader()
    );
  }
}

export default new MessagesService();
