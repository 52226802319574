import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";

export interface IEnterpriseAddressSubComponentStyles {
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
}

export interface IEnterpriseAddressStyles
  extends IAmigoBaseStyles<IEnterpriseAddressSubComponentStyles> {}

export interface IEnterpriseAddressStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseAddressClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseAddressStyleProps,
    IEnterpriseAddressStyles
  >,
  props?: IEnterpriseAddressStyleProps
): [
  IProcessedStyleSet<IEnterpriseAddressStyles>,
  IEnterpriseAddressSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseAddressStyleProps,
  IEnterpriseAddressStyles
> = (_props: IEnterpriseAddressStyleProps): IEnterpriseAddressStyles => {
  return {
    root: {
      width: "100%",
      padding: "10px",
    },
  };
};
