import * as React from "react";
import { DossierImmigrationControl } from "../DossierImmigrationControl";
import { getImmigrationPageClassNames as getDossierImmigrationPageClassNames } from "./DossierImmigrationPage.styles";
import {
  IDossierImmigrationPageProps,
  IDossierImmigrationPageState,
} from "./DossierImmigrationPage.types";

export class IDossierImmigrationPageComponent extends React.Component<
  IDossierImmigrationPageProps,
  IDossierImmigrationPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] =
      getDossierImmigrationPageClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <DossierImmigrationControl
        {...this.props}
        className={classNames.root}
        styles={subComponentStyles?.control}
      />
    );
  }
}
