import axios from "axios";
import {
  INewMessageModel,
  IMessageSummary,
} from "src/models/Messages/IMessageSummary";
import { INewParticipantTransfertRequest } from "src/models/Messages/INewParticipantTransfertRequest";
import AuthService from "../AuthService";
import { IParticipantTransfertRequestDataModel } from "src/models/Messages/IParticipantTransfertRequestDataModel";
import { IParticipantTransfertRequest } from "src/models/Messages/IParticipantTransfertRequest";
import { IParticipantTransfertRequestParticipant } from "src/models/Messages/IParticipantTransfertRequestParticipant";
import i18n from "../i18n";

export class ParticipantTransfertService {
  public static async getDataModel(
    enterpriseId: number
  ): Promise<IParticipantTransfertRequestDataModel> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/participanttransfertrequests/model`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public static async getParticipantTransfertRequest(
    enterpriseId: number,
    messageItemId: number
  ): Promise<IParticipantTransfertRequest> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/${messageItemId}/assisted/participanttransfertrequest`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static getParticipantTransfertRequestEditModel(
    enterpriseId: number,
    messageItemId: number
  ): Promise<
    [IParticipantTransfertRequest, IParticipantTransfertRequestDataModel]
  > {
    return axios
      .all([
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/${messageItemId}/assisted/participanttransfertrequest`,
          {},
          AuthService.getAuthHeaderWithLanguage()
        ),
        axios.post(
          `/api/enterprises/${enterpriseId}/messages/assisted/participanttransfertrequests/model`,
          {},
          AuthService.getAuthHeader()
        ),
      ])
      .then((x: any) => {
        return [x[0].data, x[1].data];
      });
  }

  public static async getParticipants(
    enterpriseId: number,
    requestId: number
  ): Promise<IParticipantTransfertRequestParticipant[]> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/messages/assisted/participanttransfertrequests/${requestId}/participants`,
      {},
      {
        headers: {
          Authorization: "Bearer " + AuthService.getUserInfo()?.token,
          "X-Message-Language": i18n.getLanguage(),
        },
        withCredentials: false,
      }
    );
    return x.data as any;
  }

  public static send(
    enterpriseId: number,
    message: INewMessageModel,
    request: INewParticipantTransfertRequest,
    draft: boolean
  ): Promise<IMessageSummary> {
    return axios.put(
      `/api/enterprises/${enterpriseId}/messages/assisted/participanttransfertrequests`,
      {
        message: {
          ...message,
          draft: draft,
        },
        request: {
          ...request,
          requestDate: request.requestDate
            ? i18n.parseISOLocal(request.requestDate)
            : null,
          draft: draft,
        },
        draft: draft,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
  }
}
