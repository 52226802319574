import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestPeriodParticipantsControlProps } from "./RequestPeriodParticipantsControl.types";
import {
  getStyles,
  IRequestPeriodParticipantsControlStyleProps,
  IRequestPeriodParticipantsControlStyles,
} from "./RequestPeriodParticipantsControl.styles";
import { RequestPeriodParticipantsControlComponent } from "./RequestPeriodParticipantsControl.base";

/**
 * RequestPeriodParticipantsControl
 */
export const RequestPeriodParticipantsControl = styled<
  IRequestPeriodParticipantsControlProps,
  IRequestPeriodParticipantsControlStyleProps,
  IRequestPeriodParticipantsControlStyles
>(RequestPeriodParticipantsControlComponent, getStyles);
