import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchOffsetOfferCardProps } from "./FlightSearchOffsetOfferCard.types";
import {
  getStyles,
  IFlightSearchOffsetOfferCardStyleProps,
  IFlightSearchOffsetOfferCardStyles,
} from "./FlightSearchOffsetOfferCard.styles";
import { FlightSearchOffsetOfferCardComponent } from "./FlightSearchOffsetOfferCard.base";

/**
 * FlightSearchOffsetOfferCard
 */
export const FlightSearchOffsetOfferCard = styled<
  IFlightSearchOffsetOfferCardProps,
  IFlightSearchOffsetOfferCardStyleProps,
  IFlightSearchOffsetOfferCardStyles
>(FlightSearchOffsetOfferCardComponent, getStyles);
