export default {
  "language:en": "Ingles",
  "language:fr": "Frances",
  "language:es": "Español",
  "global:pleasewait": "Favor de esperar...",

  "login:title": "AMIGO - de FERME Québec",
  "login:instructions": "Ingrese sus credenciales para iniciar una sesión",
  "login:username": "Usario",
  "login:password": "Contraseña",
  "login:logincommand": "Connectarse",

  "messages:title": "Communicaciones",
  "messages:drafts": "Borradores",
  "messages:sent": "Enviados",
  "messages:deleted": "Eliminados",
};
