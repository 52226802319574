import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IRequestParticipantEndParticipationSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IRequestParticipantEndParticipationStyles
  extends IAmigoBaseStyles<IRequestParticipantEndParticipationSubComponentStyles> {}

export interface IRequestParticipantEndParticipationStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantEndParticipationClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantEndParticipationStyleProps,
    IRequestParticipantEndParticipationStyles
  >,
  props?: IRequestParticipantEndParticipationStyleProps
): [
  IProcessedStyleSet<IRequestParticipantEndParticipationStyles>,
  IRequestParticipantEndParticipationSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantEndParticipationStyleProps,
  IRequestParticipantEndParticipationStyles
> = (
  _props: IRequestParticipantEndParticipationStyleProps
): IRequestParticipantEndParticipationStyles => {
  return {};
};
