import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAirportSelectorSubComponentStyles {}

export interface IAirportSelectorStyles
  extends IAmigoBaseStyles<IAirportSelectorSubComponentStyles> {}

export interface IAirportSelectorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  horizontal: boolean;
}

export const getAirportSelectorClassNames = (
  styles?: IStyleFunctionOrObject<
    IAirportSelectorStyleProps,
    IAirportSelectorStyles
  >,
  props?: IAirportSelectorStyleProps
): [
  IProcessedStyleSet<IAirportSelectorStyles>,
  IAirportSelectorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAirportSelectorStyleProps,
  IAirportSelectorStyles
> = (props: IAirportSelectorStyleProps): IAirportSelectorStyles => {
  return {
    root: [
      "amigo-AirportSelector",
      {
        width: props.horizontal ? "320px" : undefined,
      },
    ],
  };
};
