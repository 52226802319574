import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "src/components/IconHeader";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";

export interface IParticipantWorkInfoSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  iconHeader?: IStyleFunctionOrObject<IIconHeaderStyleProps, IIconHeaderStyles>;
}

export interface IParticipantWorkInfoStyles
  extends IAmigoBaseStyles<IParticipantWorkInfoSubComponentStyles> {}

export interface IParticipantWorkInfoStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantWorkInfoClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantWorkInfoStyleProps,
    IParticipantWorkInfoStyles
  >,
  props?: IParticipantWorkInfoStyleProps
): [
  IProcessedStyleSet<IParticipantWorkInfoStyles>,
  IParticipantWorkInfoSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantWorkInfoStyleProps,
  IParticipantWorkInfoStyles
> = (_props: IParticipantWorkInfoStyleProps): IParticipantWorkInfoStyles => {
  return {};
};
