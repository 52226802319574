import { IPaymentMethod } from "../Enterprises/IPaymentMethod";
import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface IParticipantNameRecordModel {
  id: number;
  key: string;
  workerId: number;
  firstName: string;
  lastName: string;
  gender: string;
  dateOfBirth: string;
  code: string;
  estimatedArrival: string;
  estimatedDeparture: string;
  leaveReason: string | undefined;
  recall: number | undefined;
  reason: string;
  cityId: number | undefined;
  requestId: number;
  requestNumber: string;
  errors: any;
  passport: string;
  passportExpiration: string;
  country: string;
  selected: boolean;
}

export interface IFlightSegementModel {
  id: number;
  departure: Date;
  arrival: Date;
  origin: string;
  destination: string;
  marketingCarrier: string;
  operatingCarrier: string;
  flightNumber: number;
  marketingFlightNumber: number;
}

export interface IPassengerNameRecordModel {
  index: number;
  id: number;
  requestDate: Date;
  requestId: number;
  serviceIndex: number;
  arrival: boolean;
  origin: string;
  destination: string;
  enterpriseId: number;
  immediate: boolean;
  paymentMethodId: number | undefined;
  cvc?: string;
  inlinePaymentMethod?: IPaymentMethod;
  totalPayment?: number;
  reason: string;
  segments: IFlightSegementModel[];
  participants: IParticipantNameRecordModel[];
}

export const FlightParticipantDepartureSchema: ISchemaObject = {
  name: "Flightparticipant",
  plural: "Flightparticipants",
  label: "FlightParticipant",
  fields: {
    recall: {
      name: "recall",
      label: i18n.t("messages:assisted:newdeparture:recall"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("messages:assisted:newdeparture:recall:error"),
      type: "number",
    },
    passport: {
      name: "passport",
      label: "passport",
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flights:missingpassport"),
      type: "string",
    },
    passportExpiration: {
      name: "passportExpiration",
      label: "passportExpiration",
      required: true,
      pattern: "",
      errorMessage: i18n.t("transports:flights:missingpassport"),
      type: "string",
    },
  },
};

export const FlightParticipantArrivalSchema: ISchemaObject = {
  name: "Flightparticipant",
  plural: "Flightparticipants",
  label: "FlightParticipant",
  fields: {
    passport: {
      name: "passport",
      label: "passport",
      required: false,
      pattern: "",
      errorMessage: i18n.t("transports:flights:missingpassport"),
      type: "string",
    },
    passportExpiration: {
      name: "passportExpiration",
      label: "passportExpiration",
      required: false,
      pattern: "",
      errorMessage: i18n.t("transports:flights:missingpassport"),
      type: "string",
    },
  },
};
