import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationPageComponent } from "./DossierImmigrationPage.base";
import {
  getStyles,
  IDossierImmigrationPageStyleProps,
  IDossierImmigrationPageStyles,
} from "./DossierImmigrationPage.styles";
import { IDossierImmigrationPageProps } from "./DossierImmigrationPage.types";

/**
 * ImmigrationPage description
 */
export const DossierImmigrationPage = styled<
  IDossierImmigrationPageProps,
  IDossierImmigrationPageStyleProps,
  IDossierImmigrationPageStyles
>(IDossierImmigrationPageComponent, getStyles);
