import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportRequestsLegendProps } from "./TransportRequestsLegend.types";
import {
  getStyles,
  ITransportRequestsLegendStyleProps,
  ITransportRequestsLegendStyles,
} from "./TransportRequestsLegend.styles";
import { TransportRequestsLegendComponent } from "./TransportRequestsLegend.base";

/**
 * TransportRequestsLegend
 */
export const TransportRequestsLegend = styled<
  ITransportRequestsLegendProps,
  ITransportRequestsLegendStyleProps,
  ITransportRequestsLegendStyles
>(TransportRequestsLegendComponent, getStyles);
