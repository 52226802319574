import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IMessagesFolderSubComponentStyles {}

export interface IMessagesFolderStyles
  extends IAmigoBaseStyles<IMessagesFolderSubComponentStyles> {}

export interface IMessagesFolderStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessagesFolderClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessagesFolderStyleProps,
    IMessagesFolderStyles
  >,
  props?: IMessagesFolderStyleProps
): [
  IProcessedStyleSet<IMessagesFolderStyles>,
  IMessagesFolderSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessagesFolderStyleProps,
  IMessagesFolderStyles
> = (_props: IMessagesFolderStyleProps): IMessagesFolderStyles => {
  return {};
};
