import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestDocumentsControlProps } from "./RequestDocumentsControl.types";
import {
  getStyles,
  IRequestDocumentsControlStyleProps,
  IRequestDocumentsControlStyles,
} from "./RequestDocumentsControl.styles";
import { RequestDocumentsControlComponent } from "./RequestDocumentsControl.base";

/**
 * RequestDocumentsControl
 */
export const RequestDocumentsControl = styled<
  IRequestDocumentsControlProps,
  IRequestDocumentsControlStyleProps,
  IRequestDocumentsControlStyles
>(RequestDocumentsControlComponent, getStyles);
