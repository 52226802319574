import * as React from "react";
import { Icon } from "@fluentui/react";

export interface ICreditCardIconProps {
  card: string;
  marginTop?: number | string | undefined;
}

export interface ICreditCardIconState {}

export class CreditCardIcon extends React.Component<
  ICreditCardIconProps,
  ICreditCardIconState
> {
  constructor(props: ICreditCardIconProps) {
    super(props);

    this.state = {};
  }

  private cardIcon = (image: string): JSX.Element => {
    return (
      <Icon
        style={
          this.props.marginTop ? { marginTop: this.props.marginTop } : undefined
        }
        imageProps={{
          src: `/img/cards/${image}.png`,
          width: "24px",
          height: "16px",
        }}
      />
    );
  };

  public render() {
    switch (this.props.card) {
      case "Visa":
        return this.cardIcon("visa");
      case "MasterCard":
        return this.cardIcon("mastercard");
      case "American Express":
        return this.cardIcon("amex");
      default:
        return (
          <Icon
            iconName="PaymentCard"
            style={
              this.props.marginTop
                ? { marginTop: this.props.marginTop }
                : undefined
            }
          />
        );
    }
  }
}
