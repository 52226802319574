import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  IAlertCardStyleProps,
  IAlertCardStyles,
} from "src/pages/Alerts/AlertCard";

export interface IPaymentsElementsControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<IAlertCardStyleProps, IAlertCardStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IPaymentsElementsControlStyles
  extends IAmigoBaseStyles<IPaymentsElementsControlSubComponentStyles> {}

export interface IPaymentsElementsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentsElementsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentsElementsControlStyleProps,
    IPaymentsElementsControlStyles
  >,
  props?: IPaymentsElementsControlStyleProps
): [
  IProcessedStyleSet<IPaymentsElementsControlStyles>,
  IPaymentsElementsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentsElementsControlStyleProps,
  IPaymentsElementsControlStyles
> = (
  _props: IPaymentsElementsControlStyleProps
): IPaymentsElementsControlStyles => {
  return {
    root: ["ms-paymentElementControl"],
  };
};
