import { Selection, SelectionMode, Stack, Text } from "@fluentui/react";
import * as React from "react";
import { GridView } from "src/components/GridView";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { IParticipantNameRecordModel } from "src/models/Transports/IPassengerNameRecordModel";
import i18n from "src/services/i18n";
import { FlightBookingParticipantCard } from "../FlightBookingParticipantCard";
import { getFlightBookingPassengersGridViewClassNames } from "./FlightBookingPassengersGridView.styles";
import {
  IFlightBookingPassengersGridViewProps,
  IFlightBookingPassengersGridViewState,
} from "./FlightBookingPassengersGridView.types";

export class FlightBookingPassengersGridViewComponent extends React.Component<
  IFlightBookingPassengersGridViewProps,
  IFlightBookingPassengersGridViewState
> {
  constructor(props: IFlightBookingPassengersGridViewProps) {
    super(props);
    this.state = {
      options: GridViewOptionsBuilder.getOptions(
        GridViewMode.list,
        "name",
        false,
        true
      ),
    };
  }

  render(): JSX.Element {
    const { styles, mobile, arrival, model, participants } = this.props;
    const [classNames] = getFlightBookingPassengersGridViewClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const { options } = this.state;
    /*
    const unknown: IDropdownOption = {
      key: "-1",
      text: i18n.t("messages:assisted:newdeparture:unknown"),
    };

    const none: IDropdownOption = {
      key: "-1",
      text: i18n.t("messages:assisted:newdeparture:none"),
    };
    
    const recalls = model
      ? model.recalls
          .map((x) => {
            const i: IDropdownOption = {
              key: (x.key ?? "").toString(),
              text: x.name,
            };
            return i;
          })
          .concat(unknown)
      : [unknown];
      */
    let i = 0;
    return (
      <GridView
        {...this.props}
        styles={
          mobile
            ? {
                root: [
                  classNames.root,
                  {
                    marginLeft: "-12px",
                    marginRight: "-12px",
                  },
                ],
              }
            : undefined
        }
        embedded={true}
        selectionPreservedOnEmptyClick={true}
        selectionMode={SelectionMode.none}
        columns={[
          {
            key: "name",
            name: "messages:assisted:newdeparture:participant",
            minWidth: undefined,
            onRenderCell: (item: IParticipantNameRecordModel) => {
              return (
                <Stack tokens={{ childrenGap: 2 }}>
                  <Text>{`${item.lastName.toUpperCase()}, ${
                    item.firstName
                  }`}</Text>
                  <Text variant={"small"}>
                    {arrival ? item.estimatedArrival : item.estimatedDeparture}
                  </Text>
                </Stack>
              );
            },
          },
          { key: "code", name: "Code", minWidth: 100 },
          {
            key: "passport",
            name: i18n.t("transports:flights:participants:passport"),
            minWidth: 160,
          },
          {
            key: "dateOfBirth",
            name: i18n.t("requests:participants:item:birthdate"),
            minWidth: 160,
          },
        ]}
        onRenderTile={(
          item: IParticipantNameRecordModel,
          columnWidth: number,
          _selection: Selection,
          _callback: (sel: Selection) => void
        ): JSX.Element => {
          return (
            <FlightBookingParticipantCard
              {...this.props}
              styles={undefined}
              model={model}
              item={item}
              arrival={arrival}
              index={i++}
              columnWidth={columnWidth}
            />
          );
        }}
        /*
            
          */
        onDataPaging={() => {}}
        options={options}
        onOptionsChanged={(value) =>
          this.setState({
            options: value,
          })
        }
        fullTextEnabled={false}
        emptyMessage={i18n.t(
          participants.length === 0
            ? arrival
              ? "messages:assisted:participants:arrivee:noparticipants"
              : "messages:assisted:participants:depart:noparticipants"
            : "messages:assisted:participants:emptymessage"
        )}
        items={participants}
        isItemSelected={(item: IParticipantNameRecordModel) => {
          return item.selected;
        }}
      />
    );
  }
}
