import { INavLinkGroup, INavLink } from "@fluentui/react";
import i18n from "./i18n";
import AuthService from "./AuthService";
import PreferencesService from "./PreferencesService";
import { IObjectReference, objectId } from "src/models/IObjectReference";
import { ISessionProfile } from "src/models/ISessionProfile";

class NavService {
  hasPermissionOf(key: string): boolean {
    switch (key) {
      case "/":
      case "/entreprises":
      case "/transports":
      case "/profile":
      case "/messages":
      case "/documents":
        return true;
      case "/demandes":
        return AuthService.hasPermissionOf("Demande_Empl_Consulter");
      case "/immigration":
        return (
          AuthService.hasPermissionOf("Demande_Empl_Consulter") &&
          this.DossierImmigrationVisible()
        );
      case "/participants":
        return AuthService.hasPermissionOf("Particip_Empl_Consulter");
      case "/facturation":
      case "/facturation/factures":
        return AuthService.hasPermissionOf("Facture_Hist_Consulter");
      default:
        return false;
    }
  }

  private DossierImmigrationVisible = (): boolean => {
    const val = localStorage.getItem("enterprise");
    const enterprise: IObjectReference =
      val === null ? this.getDefaultEnterprise() : JSON.parse(val);
    const enterpriseId = objectId(enterprise);

    if (enterpriseId === 1) return false;
    const userInfo = this.getUserInfo();

    if (userInfo === null || userInfo.enterpriseDossierImmigrations === null)
      return false;

    const employeurDossierImmigrations =
      userInfo.enterpriseDossierImmigrations.filter(
        (x) => x.employeurId === enterpriseId
      );
    if (employeurDossierImmigrations.length === 0) return false;

    return employeurDossierImmigrations[0].nbDossierImmigrations > 0;
  };

  private getDefaultEnterprise = (): IObjectReference => {
    const session = AuthService.getUserInfo();
    if (session === null) return { key: "", name: "" };
    return session.enterprises[0];
  };

  private getUserInfo = (): ISessionProfile | null => {
    const userInfo = localStorage.getItem("userInfo");
    if (userInfo === null) return null;
    return JSON.parse(userInfo);
  };

  public getHomeLink(forceUpdate: () => void): INavLink {
    const home: INavLink = {
      key: "collapse",
      name: "",
      url: "/",
      onClick: (ev?: React.MouseEvent<HTMLElement>, _item?: INavLink) => {
        ev?.preventDefault();
        ev?.stopPropagation();
        const collapsed = PreferencesService.menucollapsed();
        PreferencesService.setMenuCollapsed(!collapsed);
        forceUpdate();
      },
      iconProps: { iconName: "CollapseMenu" },
    };
    return home;
  }

  getMenu(): INavLinkGroup[] {
    return [
      {
        links: [
          {
            key: "/",
            name: i18n.t("menu:alerts"),
            url: "/",
            "data-automation-id": "menu:alerts",
            className: "nvx-SelectedNav",
            iconProps: {
              iconName: "WorkItemAlert",
              "aria-label": i18n.t("menu:alerts"),
            },
          },
          {
            key: "/entreprises",
            name: i18n.t("menu:enterprises"),
            url: "/entreprises",
            "data-automation-id": "menu:enterprises",
            style: { fontWeight: "bold" },
            iconProps: {
              iconName: "BusinessCenterLogo",
              "aria-label": i18n.t("menu:enterprises"),
            },
          },
          {
            key: "/demandes",
            name: i18n.t("menu:requests"),
            url: "/demandes",
            style: { fontWeight: "bold" },
            "data-automation-id": "menu:requests",
            iconProps: {
              iconName: "DocumentSet",
              "aria-label": i18n.t("menu:requests"),
            },
          } /*
          {
            key: "/immigration",
            name: i18n.t("menu:immigration"),
            url: "/immigration",
            "data-automation-id": "menu:immigration",
            iconProps: {
              iconName: "InternalInvestigation",
              "aria-label": i18n.t("menu:immigration"),
            },
          }, */,
          {
            key: "/participants",
            name: i18n.t("menu:participants"),
            url: "/participants",
            "data-automation-id": "menu:participants",
            iconProps: {
              iconName: "JoinOnlineMeeting",
              "aria-label": i18n.t("menu:participants"),
            },
          },
          {
            key: "/transports",
            name: i18n.t("menu:transports"),
            url: "/transports",
            "data-automation-id": "menu:transports",
            iconProps: {
              iconName: "Airplane",
              "aria-label": i18n.t("menu:transports"),
            },
          },
          {
            key: "/facturation",
            name: i18n.t("menu:factures"),
            url: "/facturation",
            "data-automation-id": "menu:factures",
            iconProps: {
              iconName: "PaymentCard",
              "aria-label": i18n.t("menu:factures"),
            },
          },
          {
            key: "/documents",
            name: i18n.t("menu:documents"),
            url: "/documents",
            "data-automation-id": "menu:documents",
            iconProps: {
              iconName: "WorkforceManagement",
              "aria-label": i18n.t("menu:documents"),
            },
          },
          {
            key: "/messages",
            name: i18n.t("menu:messages"),
            url: "/messages",
            "data-automation-id": "menu:messages",
            iconProps: {
              iconName: "Mail",
              "aria-label": i18n.t("menu:messages"),
            },
          },
          {
            key: "/profile",
            name: i18n.t("menu:profile"),
            url: "/profile",
            "data-automation-id": "menu:profile",
            iconProps: {
              iconName: "Settings",
              "aria-label": i18n.t("menu:profile"),
            },
          },
        ].filter((x) => this.hasPermissionOf(x.key)),
      },
    ];
  }
}

export default new NavService();
