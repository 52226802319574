import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestPeriodPageProps } from "./RequestPeriodPage.types";
import {
  getStyles,
  IRequestPeriodPageStyleProps,
  IRequestPeriodPageStyles,
} from "./RequestPeriodPage.styles";
import { RequestPeriodPageComponent } from "./RequestPeriodPage.base";

/**
 * RequestPeriodPage description
 */
export const RequestPeriodPage = styled<
  IRequestPeriodPageProps,
  IRequestPeriodPageStyleProps,
  IRequestPeriodPageStyles
>(RequestPeriodPageComponent, getStyles);
