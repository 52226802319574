import { styled } from "@fluentui/react/lib/Utilities";
import { IBillingElementsControlProps } from "./BillingElementsControl.types";
import {
  getStyles,
  IBillingElementsControlStyleProps,
  IBillingElementsControlStyles,
} from "./BillingElementsControl.styles";
import { BillingElementsControlComponent } from "./BillingElementsControl.base";

/**
 * BillingElementsControl description
 */
export const BillingElementsControl = styled<
  IBillingElementsControlProps,
  IBillingElementsControlStyleProps,
  IBillingElementsControlStyles
>(BillingElementsControlComponent, getStyles);
