import {
  Checkbox,
  ChoiceGroup,
  DatePicker,
  DefaultButton,
  Dropdown,
  DropdownMenuItemType,
  IChoiceGroupOption,
  IconButton,
  IDropdownOption,
  Label,
  Panel,
  PrimaryButton,
  Stack,
  StackItem,
  Text,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import {
  IChoiceValue,
  IDateRangeValue,
  IQueryFilter,
} from "../../models/IQueryFilter";
import i18n from "../../services/i18n";
import { getFilterPaneClassNames } from "./FilterPane.styles";
import { IFilterPaneProps, IFilterPaneState } from "./FilterPane.types";
import Moment from "moment";
import "moment/locale/fr";

export class FilterPaneComponent extends React.Component<
  IFilterPaneProps,
  IFilterPaneState
> {
  constructor(props: IFilterPaneProps) {
    super(props);

    this.state = {
      language: i18n.getLanguage(),
      filters: this.props.filters,
    };
  }

  /*

  private getClearedFilters = (filters: IQueryFilter[]): IQueryFilter[] => {
    const els = document.querySelectorAll(
      ".ms-SearchBox-filter .ms-SearchBox-field"
    );
    for (let i = 0; i < els.length; i++) {
      (els[i] as any).value = "";
    }

    filters.forEach((x) => {
      x.value = null;
    });

    (window as any).filtercleared = undefined;

    return filters;
  };

  */

  private clearFilters = (): void => {
    this.props.onResetFilters &&
      this.props.onResetFilters().then((x) => {
        this.setState({
          filters: x ?? this.state.filters,
        });
      });
  };

  private applyFilters = (): void => {
    if (this.props.onApplyFilters !== undefined) {
      this.props.onApplyFilters(this.state.filters);
    }
  };

  private renderFilter = (filter: IQueryFilter): JSX.Element => {
    switch (filter.type) {
      case "text":
        return this.renderTextFilter(filter);
      case "date":
        return this.renderDateFilter(filter);
      case "choice":
        return this.renderChoiceFilter(filter);
      case "null":
        return this.renderNullFilter(filter);
    }
    return <div></div>;
  };

  private renderTextFilter = (filter: IQueryFilter): JSX.Element => {
    return (
      <div key={filter.fieldName} style={{ marginTop: "5px" }}>
        <TextField
          label={i18n.t(filter.name)}
          title={""}
          data-automation-id={`nvx:grid:filter:${filter.fieldName.toLowerCase()}`}
          className="ms-SearchBox-filter"
          value={filter.value === null ? "" : filter.value?.toString()}
          onChange={(
            _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
            newValue?: string
          ) => {
            const { filters } = this.state;
            filters.forEach((x) => {
              if (x.name === filter.name) {
                x.value = newValue ?? null;
              }
            });
            this.setState({
              filters: filters,
            });
          }}
          placeholder={i18n.t("grid:filterby:placeholder")}
        />
      </div>
    );
  };

  private renderNullFilter = (filter: IQueryFilter): JSX.Element => {
    return (
      <div key={filter.fieldName} style={{ marginTop: "5px" }}>
        <ChoiceGroup
          title={""}
          label={i18n.t(filter.name)}
          data-automation-id={`nvx:grid:filter:${filter.fieldName.toLowerCase()}`}
          selectedKey={
            filter.value === undefined
              ? "0"
              : (filter.value as IChoiceValue).id.toString()
          }
          options={
            filter.choices === undefined
              ? []
              : filter.choices.map((x) => {
                  return {
                    key: x.id.toString(),
                    text: i18n.t(x.name),
                    itemID: x.name,
                    title: "",
                  };
                })
          }
          onChange={(
            _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
            option?: IChoiceGroupOption
          ): void => {
            const { filters } = this.state;
            if (!option || !filters) return;
            this.setState({
              filters: filters.map((x) => {
                if (x.name === filter.name) {
                  x.value = {
                    id: parseInt(option.key),
                    name: option?.itemID ?? "",
                  };
                }
                return x;
              }),
            });
          }}
          required={true}
        />
      </div>
    );
  };

  private renderChoiceFilter = (filter: IQueryFilter): JSX.Element => {
    const keys: string[] =
      !filter.value || filter.value === null
        ? filter.choices === undefined
          ? []
          : filter.choices.map((x) => x.id.toString())
        : (filter.value as IChoiceValue[]).map((x) => x.id.toString());
    return (
      <div key={filter.fieldName} style={{ marginTop: "5px" }}>
        <div style={{ float: "left" }}>
          <Label title={""}>{i18n.t(filter.name)}</Label>
        </div>
        <div style={{ float: "right" }}>
          <Stack horizontal tokens={{ childrenGap: 5 }}>
            <TooltipHost content={i18n.t("grid:selectall")}>
              <IconButton
                title={""}
                iconProps={{ iconName: "MultiSelect" }}
                style={{ color: "#00000", fontSize: "14px" }}
                onClick={() => {
                  const { filters } = this.state;
                  filters.forEach((y) => {
                    if (y.name === filter.name) {
                      y.value = y.choices ?? [];
                    }
                  });
                  this.setState({
                    filters: filters,
                  });
                }}
              />
            </TooltipHost>
            <TooltipHost content={i18n.t("grid:selectnone")}>
              <IconButton
                title={""}
                iconProps={{ iconName: "ClearSelection" }}
                style={{ color: "#00000", fontSize: "14px" }}
                onClick={() => {
                  const { filters } = this.state;
                  filters.forEach((y) => {
                    if (y.name === filter.name) {
                      y.value = [];
                    }
                  });
                  this.setState({
                    filters: filters,
                  });
                }}
              />
            </TooltipHost>
          </Stack>
        </div>

        <div style={{ clear: "both", height: "5px" }}></div>
        <Stack tokens={{ childrenGap: 10 }}>
          {filter.choices !== undefined && filter.choices.length > 6 && (
            <Dropdown
              data-automation-id={`nvx:grid:filter:${filter.fieldName.toLowerCase()}`}
              title={""}
              onChange={(
                _event: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption,
                _index?: number
              ) => {
                const { filters } = this.state;
                const checked: boolean = option?.selected ?? false;
                filters.forEach((y) => {
                  if (y.name === filter.name) {
                    const key: string = (option?.key ?? "-1").toString();
                    const x: IChoiceValue = {
                      id: key,
                      name: option?.data ?? "",
                    };
                    if (checked) {
                      if (y.value === null) {
                        y.value = [] as IChoiceValue[];
                      } else {
                        y.value = (y.value as IChoiceValue[])
                          .filter((z) => z.id.toString() !== key)
                          .concat([x]);
                      }
                    } else {
                      if (y.value === null) {
                        y.value = filter.choices
                          ? filter.choices.filter(
                              (z) => z.id.toString() !== key
                            )
                          : null;
                      } else {
                        y.value = (y.value as IChoiceValue[]).filter(
                          (z) => z.id.toString() !== key
                        );
                      }
                    }
                  }
                });
                this.setState({
                  filters: filters,
                });
              }}
              selectedKeys={keys}
              multiSelect={true}
              options={(
                [
                  {
                    key: filter.fieldName,
                    text: i18n.t(filter.name),
                    itemType: DropdownMenuItemType.Header,
                    data: null,
                  },
                ] as IDropdownOption[]
              ).concat(
                filter.choices.map((x) => {
                  const selected =
                    !filter.value || filter.value === null
                      ? true
                      : (filter.value as IChoiceValue[]).length !==
                        (filter.value as IChoiceValue[]).filter(
                          (z) => z.id !== x.id
                        ).length;
                  const opt = {
                    key: x.id.toString(),
                    id: x.id.toString(),
                    text: i18n.t(x.name),
                    title: i18n.t(x.name),
                    data: x.name,
                    isSelected: selected,
                    checked: selected,
                  } as IDropdownOption;
                  return opt;
                })
              )}
            />
          )}
          {filter.choices !== undefined &&
            filter.choices.length <= 6 &&
            filter.choices.map((x) => {
              return (
                <Checkbox
                  title={""}
                  data-automation-id={`nvx:grid:filter:${filter.fieldName.toLowerCase()}:${x.id.toString()}`}
                  key={filter.fieldName.toLowerCase() + x.id.toString()}
                  checked={
                    filter.value === null
                      ? true
                      : (filter.value as IChoiceValue[]).length !==
                        (filter.value as IChoiceValue[]).filter(
                          (z) => z.id !== x.id
                        ).length
                  }
                  label={i18n.t(x.name)}
                  onChange={(
                    _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
                    checked?: boolean
                  ) => {
                    const { filters } = this.state;
                    filters.forEach((y) => {
                      if (y.name === filter.name) {
                        if (checked) {
                          if (y.value === null) {
                            y.value = [x];
                          } else {
                            y.value = (y.value as IChoiceValue[])
                              .filter((z) => z.id !== x.id)
                              .concat([x]);
                          }
                        } else {
                          if (y.value === null) {
                            y.value = filter.choices
                              ? filter.choices.filter((z) => z.id !== x.id)
                              : null;
                          } else {
                            y.value = (y.value as IChoiceValue[]).filter(
                              (z) => z.id !== x.id
                            );
                          }
                        }
                      }
                    });
                    this.setState({
                      filters: filters,
                    });
                  }}
                />
              );
            })}
        </Stack>
      </div>
    );
  };

  private formatDate = (date: Date | null | undefined): Date | undefined => {
    if (!date) return undefined;
    if (date === null) return undefined;
    return typeof date === "string" ? new Date(date) : date;
  };

  private renderDateFilter = (filter: IQueryFilter): JSX.Element => {
    return (
      <div key={filter.fieldName} style={{ marginTop: "5px" }}>
        <Label title={""} style={{ marginBottom: "0" }}>
          {i18n.t(filter.name)}
        </Label>
        <Stack tokens={{ childrenGap: 10 }}>
          <Text variant={"small"}>{i18n.t("global:between")}</Text>
          <DatePicker
            title={""}
            formatDate={(date?: Date) => {
              if (!date) return "";
              return Moment(date).locale(i18n.getLanguage()).format("LL");
            }}
            showGoToToday={false}
            strings={i18n.CalendarStrings()}
            value={
              filter.value === null
                ? undefined
                : this.formatDate((filter.value as IDateRangeValue).start)
            }
            onSelectDate={(date: Date | null | undefined) => {
              const { filters } = this.state;
              filters.forEach((x) => {
                if (x.name === filter.name) {
                  if (x.value === null) {
                    x.value = { start: date ?? undefined, end: undefined };
                  } else {
                    const r: IDateRangeValue = x.value as IDateRangeValue;
                    r.start = date ?? undefined;
                    if (
                      r.start !== undefined &&
                      r.end !== undefined &&
                      r.start > r.end
                    ) {
                      r.end = r.start;
                    }
                  }
                }
              });
              this.setState({
                filters: filters,
              });
            }}
          />
          <Text variant={"small"}>{i18n.t("global:and")}</Text>
          <DatePicker
            title={""}
            strings={i18n.CalendarStrings()}
            value={
              filter.value === null
                ? undefined
                : this.formatDate((filter.value as IDateRangeValue).end)
            }
            showGoToToday={false}
            formatDate={(date?: Date) => {
              if (!date) return "";
              return Moment(date).locale(i18n.getLanguage()).format("LL");
            }}
            onSelectDate={(date: Date | null | undefined) => {
              const { filters } = this.state;
              filters.forEach((x) => {
                if (x.name === filter.name) {
                  if (x.value === null) {
                    x.value = { start: undefined, end: date ?? undefined };
                  } else {
                    const r: IDateRangeValue = x.value as IDateRangeValue;
                    r.end = date ?? undefined;
                    if (
                      r.start !== undefined &&
                      r.end !== undefined &&
                      r.start > r.end
                    ) {
                      r.start = r.end;
                    }
                  }
                }
              });
              this.setState({
                filters: filters,
              });
            }}
          />
        </Stack>
      </div>
    );
  };

  private queryFiltersRequired = (): boolean => {
    return (
      this.state.filters === undefined ||
      this.state.filters.length === 0 ||
      (this.state.filters.length === 1 &&
        this.state.filters.filter((x) => x.name === "fulltext").length === 1)
    );
  };

  private emptyFilters = (): boolean => {
    return (
      this.props.filters === undefined ||
      this.props.filters.length === 0 ||
      (this.props.filters.length === 1 &&
        this.props.filters.filter((x) => x.name === "fulltext").length === 1)
    );
  };

  componentDidUpdate() {
    if (
      this.state.language !== i18n.getLanguage() ||
      (this.queryFiltersRequired() && !this.emptyFilters())
    ) {
      this.setState({
        language: i18n.getLanguage(),
        filters: this.props.filters,
      });
    }
  }

  public render() {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getFilterPaneClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Panel
        styles={subComponentStyles?.panel}
        isFooterAtBottom={true}
        onRenderFooter={() => {
          return (
            <Stack
              horizontal
              tokens={this.props.tokens?.defaultStackTokens}
              className={classNames.footerSection}
            >
              <PrimaryButton
                data-automation-id="nvx:grid:filter:apply"
                text={i18n.t("global:apply")}
                className={classNames.footerButton}
                onClick={this.applyFilters}
              />
              <DefaultButton
                data-automation-id="nvx:grid:filter:cancel"
                text={i18n.t("global:cancel")}
                className={classNames.footerButton}
                onClick={this.props.onDismiss}
              />
            </Stack>
          );
        }}
        isOpen={this.props.enabled}
        onDismiss={this.props.onDismiss}
        title={i18n.t("grid:filter")}
        hasCloseButton={true}
        closeButtonAriaLabel={i18n.t("global:close")}
        onRenderHeader={() => {
          return (
            <Stack
              horizontal
              tokens={this.props.tokens?.defaultStackTokens}
              className={classNames.headerSection}
            >
              <StackItem grow>
                <Text variant={"xLarge"} className={classNames.filterTitle}>
                  {i18n.t("grid:filter")}
                </Text>
              </StackItem>
              <StackItem grow={false}>
                <TooltipHost content={i18n.t("grid:clearfilters")}>
                  <IconButton
                    title={""}
                    data-automation-id="nvx:grid:filter:clear"
                    iconProps={{ iconName: "ClearFilter" }}
                    onClick={this.clearFilters}
                  />
                </TooltipHost>
              </StackItem>
            </Stack>
          );
        }}
      >
        {this.state.filters.map((x) => this.renderFilter(x))}
      </Panel>
    );
  }
}
