import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentMethodCardProps } from "./PaymentMethodCard.types";
import {
  getStyles,
  IPaymentMethodCardStyleProps,
  IPaymentMethodCardStyles,
} from "./PaymentMethodCard.styles";
import { PaymentMethodCardComponent } from "./PaymentMethodCard.base";

/**
 * PaymentMethodCard description
 */
export const PaymentMethodCard = styled<
  IPaymentMethodCardProps,
  IPaymentMethodCardStyleProps,
  IPaymentMethodCardStyles
>(PaymentMethodCardComponent, getStyles);
