import { Icon, Stack, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getParticipantHistoryCardClassNames } from "./ParticipantHistoryCard.styles";
import {
  IParticipantHistoryCardProps,
  IParticipantHistoryCardState,
} from "./ParticipantHistoryCard.types";

export class ParticipantHistoryCardComponent extends React.Component<
  IParticipantHistoryCardProps,
  IParticipantHistoryCardState
> {
  render(): JSX.Element {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantHistoryCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <GridViewCard
          {...this.props}
          styles={subComponentStyles?.gridViewCard}
          title={item.name}
          columnWidth={columnWidth}
          footerText={item.enterpriseName}
          footerIconName={footerIconName ?? "WebAppBuilderModule"}
          selected={false}
          onClick={
            this.props.onClick
              ? () => {
                  this.props.onClick && this.props.onClick(item);
                }
              : undefined
          }
          onSelected={undefined}
          selectable={false}
          icons={
            <>
              <TooltipHost content={item.name}>
                <Icon iconName="Assign" styles={subComponentStyles?.topIcon} />
              </TooltipHost>
            </>
          }
        >
          <Stack tokens={{ childrenGap: 10 }}>
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("participants:history:recall")}
              content={item.recall}
            />
            <LabelField
              {...this.props}
              styles={undefined}
              label={i18n.t("participants:history:endParticipation")}
              content={item.endParticipation}
            />
          </Stack>
        </GridViewCard>
      </div>
    );
  }
}
