import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface INewPaymentBillingSummarySubComponentStyles {}

export interface INewPaymentBillingSummaryStyles
  extends IAmigoBaseStyles<INewPaymentBillingSummarySubComponentStyles> {}

export interface INewPaymentBillingSummaryStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentBillingSummaryClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentBillingSummaryStyleProps,
    INewPaymentBillingSummaryStyles
  >,
  props?: INewPaymentBillingSummaryStyleProps
): [
  IProcessedStyleSet<INewPaymentBillingSummaryStyles>,
  INewPaymentBillingSummarySubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentBillingSummaryStyleProps,
  INewPaymentBillingSummaryStyles
> = (
  _props: INewPaymentBillingSummaryStyleProps
): INewPaymentBillingSummaryStyles => {
  return {};
};
