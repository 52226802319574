import {
  ActivityItem,
  Icon,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getTransportRequestElementClassNames } from "./TransportRequestElement.styles";
import {
  ITransportRequestElementProps,
  ITransportRequestElementState,
} from "./TransportRequestElement.types";

export class TransportRequestElementComponent extends React.Component<
  ITransportRequestElementProps,
  ITransportRequestElementState
> {
  render(): JSX.Element {
    const { styles, request } = this.props;
    const [classNames] = getTransportRequestElementClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <MessageBar
          messageBarType={MessageBarType.info}
          styles={{
            root: {
              fontWeight: "600",
            },
          }}
        >
          {request.typeDescription &&
            i18n.formatString(
              i18n.t("transports:element:type"),
              i18n.getLanguage() === "fr"
                ? request.typeDescription?.toLowerCase()
                : request.typeDescription.replace("Aérien", "Aeral")
            )}
        </MessageBar>
        {request.source !== undefined && request.source !== "" && (
          <MessageBar messageBarType={MessageBarType.info}>
            {request.source.split(";").map((x, i) => (
              <div
                key={i.toString()}
                style={{
                  fontWeight: i % 2 === 0 ? 600 : 400,
                }}
              >
                {x}
              </div>
            ))}
          </MessageBar>
        )}
        <div style={{ height: "40px" }}></div>
        {request.participants
          .filter((x) => x.transportType === request.transportType)
          .map((x) => {
            return (
              <div key={x.key}>
                <ActivityItem
                  activityDescription={x.name}
                  styles={{
                    activityText: {
                      fontSize: "16px",
                      fontWeight: 600,
                    },
                  }}
                  activityIcon={<Icon iconName={"Contact"} />}
                  comments={x.enterpriseNotes}
                  timeStamp={
                    x.hasLandTransportation && (
                      <Stack horizontal tokens={{ childrenGap: 5 }}>
                        <Icon iconName={"Bus"} />
                        {x.originLandTransportation && (
                          <Text>{x.originLandTransportation}</Text>
                        )}
                        {x.destinationLandTransportation && (
                          <Icon iconName={"NavigateForward"} />
                        )}
                        {x.destinationLandTransportation && (
                          <Text>{x.destinationLandTransportation}</Text>
                        )}
                      </Stack>
                    )
                  }
                />
              </div>
            );
          })}
      </div>
    );
  }
}
