import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface INewPaymentMethodSummarySubComponentStyles {}

export interface INewPaymentMethodSummaryStyles
  extends IAmigoBaseStyles<INewPaymentMethodSummarySubComponentStyles> {}

export interface INewPaymentMethodSummaryStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentMethodSummaryClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentMethodSummaryStyleProps,
    INewPaymentMethodSummaryStyles
  >,
  props?: INewPaymentMethodSummaryStyleProps
): [
  IProcessedStyleSet<INewPaymentMethodSummaryStyles>,
  INewPaymentMethodSummarySubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentMethodSummaryStyleProps,
  INewPaymentMethodSummaryStyles
> = (
  _props: INewPaymentMethodSummaryStyleProps
): INewPaymentMethodSummaryStyles => {
  return {};
};
