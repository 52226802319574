import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IArrivalAssistedParticipantCardStyleProps,
  IArrivalAssistedParticipantCardStyles,
} from "../ArrivalAssistedParticipantCard";

export interface IArrivalAssistedEditorSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IArrivalAssistedParticipantCardStyleProps,
    IArrivalAssistedParticipantCardStyles
  >;
}

export interface IArrivalAssistedEditorStyles
  extends IAmigoBaseStyles<IArrivalAssistedEditorSubComponentStyles> {}

export interface IArrivalAssistedEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getArrivalAssistedEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    IArrivalAssistedEditorStyleProps,
    IArrivalAssistedEditorStyles
  >,
  props?: IArrivalAssistedEditorStyleProps
): [
  IProcessedStyleSet<IArrivalAssistedEditorStyles>,
  IArrivalAssistedEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IArrivalAssistedEditorStyleProps,
  IArrivalAssistedEditorStyles
> = (
  _props: IArrivalAssistedEditorStyleProps
): IArrivalAssistedEditorStyles => {
  return {
    root: ["amigo-ArrivalAssistedEditor", {}],
  };
};
