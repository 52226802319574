import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingPageProps } from "./FlightBookingPage.types";
import {
  getStyles,
  IFlightBookingPageStyleProps,
  IFlightBookingPageStyles,
} from "./FlightBookingPage.styles";
import { FlightBookingPageComponent } from "./FlightBookingPage.base";

/**
 * FlightBookingPage
 */
export const FlightBookingPage = styled<
  IFlightBookingPageProps,
  IFlightBookingPageStyleProps,
  IFlightBookingPageStyles
>(FlightBookingPageComponent, getStyles);
