import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IBillingElementCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IBillingElementCardStyles
  extends IAmigoBaseStyles<IBillingElementCardSubComponentStyles> {}

export interface IBillingElementCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  highlight: boolean;
  travel: boolean;
}

export const getBillingElementCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IBillingElementCardStyleProps,
    IBillingElementCardStyles
  >,
  props?: IBillingElementCardStyleProps
): [
  IProcessedStyleSet<IBillingElementCardStyles>,
  IBillingElementCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IBillingElementCardStyleProps,
  IBillingElementCardStyles
> = (props: IBillingElementCardStyleProps): IBillingElementCardStyles => {
  return {
    subComponentStyles: {
      gridViewCard: props.highlight
        ? {
            root: [
              "amigo-BillingElementCard",
              {
                color: props.travel ? "#2f96b4" : undefined,
                backgroundColor: "rgb(203, 81, 27, 0.12)",
                height: "200px",
              },
            ],
          }
        : {
            root: {
              height: "200px",
            },
          },
    },
  };
};
