import { styled } from "@fluentui/react/lib/Utilities";
import { INavigationMenuPanelProps } from "./NavigationMenuPanel.types";
import {
  getStyles,
  INavigationMenuPanelStyleProps,
  INavigationMenuPanelStyles,
} from "./NavigationMenuPanel.styles";
import { NavigationMenuPanelComponent } from "./NavigationMenuPanel.base";

/**
 * NavigationMenuPanel description
 */
export const NavigationMenuPanel = styled<
  INavigationMenuPanelProps,
  INavigationMenuPanelStyleProps,
  INavigationMenuPanelStyles
>(NavigationMenuPanelComponent, getStyles);
