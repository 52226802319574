import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportArrivalUpdateAssistedControlProps } from "./TransportArrivalUpdateAssistedControl.types";
import {
  getStyles,
  ITransportArrivalUpdateAssistedControlStyleProps,
  ITransportArrivalUpdateAssistedControlStyles,
} from "./TransportArrivalUpdateAssistedControl.styles";
import { TransportArrivalUpdateAssistedControlComponent } from "./TransportArrivalUpdateAssistedControl.base";

/**
 * TransportArrivalUpdateAssistedControl description
 */
export const TransportArrivalUpdateAssistedControl = styled<
  ITransportArrivalUpdateAssistedControlProps,
  ITransportArrivalUpdateAssistedControlStyleProps,
  ITransportArrivalUpdateAssistedControlStyles
>(TransportArrivalUpdateAssistedControlComponent, getStyles);
