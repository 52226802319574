import {
  DefaultPalette,
  IconButton,
  IProcessedStyleSet,
  Persona,
  PersonaSize,
  Selection,
  Stack,
  StackItem,
} from "@fluentui/react";
import * as React from "react";
import DateText from "src/components/DateText";
import { GridView } from "src/components/GridView";
import { IQueryFilter } from "src/components/GridView/GridView.imports";
import { IChoiceValue } from "src/models/IQueryFilter";
import { IMessageSummary } from "src/models/Messages/IMessageSummary";
import i18n from "src/services/i18n";
import {
  getMessagesListControlClassNames,
  IMessagesListControlStyles,
} from "./MessagesListControl.styles";
import {
  IMessagesListControlProps,
  IMessagesListControlState,
} from "./MessagesListControl.types";

export class MessagesListControlComponent extends React.Component<
  IMessagesListControlProps,
  IMessagesListControlState
> {
  constructor(props: IMessagesListControlProps) {
    super(props);
    this.state = {
      selectedIndex: -1,
    };
    this._onCellClicked.bind(this);
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getMessagesListControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    let i = 0;
    return (
      <div>
        <GridView
          {...this.props}
          viewPortOffset={82.5}
          styles={{
            root: {
              ".ms-List-page": {
                // maxWidth: "544px!important",
              },
            },
          }}
          options={this.props.options}
          fullTextEnabled={false}
          onApplyFilters={(filters: IQueryFilter[], callback: () => void) => {
            this.props.onApplyFilters(filters);
            callback();
          }}
          onQueryFilters={(_reset: boolean) => {
            return new Promise<IQueryFilter[]>((resolve) => {
              const typeChoices: IChoiceValue[] = [
                {
                  id: "STANDARD",
                  name: i18n.t("messages:type:standard"),
                },
                {
                  id: "DEMANDE_ARRIVEE",
                  name: i18n.t("messages:type:newarrival"),
                },
                {
                  id: "DEMANDE_DEPART",
                  name: i18n.t("messages:type:newdeparture"),
                },
                {
                  id: "DEMANDE_DEPART_MAJ",
                  name: i18n.t("messages:type:changedeparture"),
                },
                {
                  id: "TRANSPORT_ARRIVEE",
                  name: i18n.t("messages:type:newarrival:transport"),
                },
                {
                  id: "INSPECTION",
                  name: i18n.t("messages:type:inspection"),
                },
              ];
              resolve([
                {
                  name: "messages:sort:subject",
                  fieldName: "subject",
                  type: "text",
                  value: null,
                },
                {
                  name: "messages:element",
                  fieldName: "message",
                  type: "text",
                  value: null,
                },
                {
                  name: "messages:sort:type",
                  fieldName: "type",
                  type: "choice",
                  value: null,
                  choices: typeChoices,
                },
                {
                  name: "messages:sort:timestamp",
                  fieldName: "timestamp",
                  type: "date",
                  value: null,
                },
              ]);
            });
          }}
          onSort={this.props.onSort}
          commands={this.props.commands}
          columns={[
            {
              key: "timestamp",
              name: "messages:sort:timestamp",
              minWidth: 150,
            },
            {
              key: "subject",
              name: "messages:sort:subject",
              minWidth: 150,
            },
            {
              key: "type",
              name: "messages:sort:type",
              minWidth: 150,
            },
            {
              key: "from",
              name: "messages:sort:from",
              minWidth: 150,
            },
          ]}
          onRenderTile={(
            item: IMessageSummary,
            columnWidth: number,
            selection: Selection,
            callback: (sel: Selection) => void
          ): JSX.Element => {
            return this._onRenderCell(
              item,
              i++,
              columnWidth,
              selection,
              callback,
              classNames
            );
          }}
          items={this.props.messages}
          inboxStyled={true}
          onDataPaging={this.props.onDataPaging}
        />
      </div>
    );
  }

  private _onCellClicked(
    idx: number,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ): void {
    const els = document.querySelectorAll(".message-summary .selected-message");
    for (let i = 0; i < els.length; i++) {
      els[i].className = els[i].className
        .replace("selected-message", "")
        .trim();
    }
    this.setState({ selectedIndex: idx });
    (event.currentTarget as any).childNodes[0].className += " selected-message";

    if (this.props.messageSelected !== undefined) {
      this.props.messageSelected(this.props.messages[idx]);
    }

    if (window.pageYOffset > 0) {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    }
  }

  private commTypeToLabel = (value: string): string => {
    switch (value) {
      case "STANDARD":
        return i18n.t("messages:type:standard");
      case "GENERAL":
        return i18n.t("messages:type:general");
      case "DEMANDE_DEPART":
        return i18n.t("messages:type:newdeparture");
      case "DEMANDE_ARRIVEE":
        return i18n.t("messages:type:newarrival");
      case "TRANSPORT_ARRIVEE":
        return i18n.t("messages:type:newarrival:transport");
      case "DEMANDE_DEPART_MAJ":
        return i18n.t("messages:type:changedeparture");
      case "TRANSPORT_ARRIVEE_MAJ":
        return i18n.t("messages:type:newarrival:transport");
      case "INSPECTION":
        return i18n.t("messages:type:inspection");
    }
    return i18n.t("messages:type:standard");
  };

  private _onRenderCell = (
    item: IMessageSummary,
    index: number,
    _columnWidth: number,
    _selection: Selection,
    _callback: (sel: Selection) => void,
    classNames: IProcessedStyleSet<IMessagesListControlStyles>
  ): JSX.Element => {
    /*
      const className = index % 2 ? "message-summary odd" : "message-summary";
      const styleName =
        index === selectedIndex
          ? "selected-message " + styles.itemContentSelected
          : styles.itemContent;
    */
    const tos = item.to.concat(item.designations);
    const targetName: string =
      item.type?.code === undefined ||
      item.type.code.toUpperCase() === "INSPECTION" ||
      item.type.code.toUpperCase() === "STANDARD" ||
      item.type.code.toUpperCase() === "GENERAL"
        ? this.props.folder === "Sent" || this.props.folder === "Drafts"
          ? tos.length === 0
            ? ""
            : tos[0].name
          : item.from.name
        : i18n.t("messages:designations:dispatcher");
    const { mobile } = this.props;
    if (this.props.folder !== "Inbox") item.acknowledged = true;
    return (
      <div
        data-is-focusable={true}
        data-index={index}
        className={classNames.messageContainer}
        style={{
          backgroundColor:
            index === this.state.selectedIndex
              ? this.props.theme.palette.white
              : this.props.theme.palette.neutralLighter,
        }}
        onClick={(ev) => this._onCellClicked(index, ev)}
      >
        <div className={""}>
          <Stack horizontal>
            <StackItem
              className={
                this.props.folder !== "Inbox" || item.acknowledged
                  ? "StackItem-Persona Message-Persona-Normal"
                  : "StackItem-Persona Message-Persona"
              }
              styles={{ root: { marginTop: "5px", marginBottom: "10px" } }}
            >
              <Persona
                initialsColor={"rgb(122, 117, 116)"}
                styles={{
                  root: mobile
                    ? {
                        marginTop: "15px",
                      }
                    : undefined,
                  primaryText: {
                    fontWeight:
                      this.props.folder !== "Inbox" || item.acknowledged
                        ? 400
                        : "bold",
                  },
                  textContent: {
                    fontWeight:
                      this.props.folder !== "Inbox" || item.acknowledged
                        ? 400
                        : "bold",
                  },
                  secondaryText: {
                    color:
                      this.props.folder !== "Inbox" || item.acknowledged
                        ? DefaultPalette.black
                        : DefaultPalette.themePrimary,
                  },
                  tertiaryText: {
                    maxWidth: "325px",
                  },
                }}
                secondaryText={
                  item.type?.code
                    ? this.commTypeToLabel(item.type.code.toUpperCase())
                    : i18n.t("messages:type:standard")
                }
                tertiaryText={item.subject}
                coinProps={{
                  styles: {
                    coin: {
                      marginTop: "-48px",
                      marginRight: "-7px",
                    },
                  },
                  size: PersonaSize.size32,
                }}
                optionalText={item.timestamp.toString()}
                text={targetName}
                size={PersonaSize.size72}
              />
              <div className="message-actions">
                <DateText
                  variant="small"
                  tooltipFormat={"messages:sent:tooltip"}
                  style={{
                    marginTop: "10px!important",
                    marginLeft: "0px!important",
                    color:
                      this.props.folder !== "Inbox" || item.acknowledged
                        ? DefaultPalette.black
                        : DefaultPalette.black,
                  }}
                  className="message-datetime"
                  date={item.timestamp}
                />
                {item.attachments.length > 0 && (
                  <IconButton
                    iconProps={{ iconName: "Attach" }}
                    onClick={(ev) => ev.preventDefault()}
                  />
                )}
                <IconButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={(ev) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    this.props.onDeleteMessage(item);
                    return false;
                  }}
                />
              </div>
            </StackItem>
          </Stack>
        </div>
      </div>
    );
  };
}
