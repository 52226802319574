import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDocumentsCategoryItemsSubComponentStyles {}

export interface IDocumentsCategoryItemsStyles
  extends IAmigoBaseStyles<IDocumentsCategoryItemsSubComponentStyles> {}

export interface IDocumentsCategoryItemsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDocumentsCategoryItemsClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentsCategoryItemsStyleProps,
    IDocumentsCategoryItemsStyles
  >,
  props?: IDocumentsCategoryItemsStyleProps
): [
  IProcessedStyleSet<IDocumentsCategoryItemsStyles>,
  IDocumentsCategoryItemsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentsCategoryItemsStyleProps,
  IDocumentsCategoryItemsStyles
> = (
  _props: IDocumentsCategoryItemsStyleProps
): IDocumentsCategoryItemsStyles => {
  return {
    root: ["amigo-DocumentsCategoryItems", {}],
  };
};
