import { Stack, Text } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import { LabelField } from "src/components/LabelField";
import { TravelStatusIcon } from "src/pages/Requests/TravelStatusIcon";
import i18n from "src/services/i18n";
import { getTransportSummaryCardClassNames } from "./TransportSummaryCard.styles";
import {
  ITransportSummaryCardProps,
  ITransportSummaryCardState,
} from "./TransportSummaryCard.types";

export class TransportSummaryCardComponent extends React.Component<
  ITransportSummaryCardProps,
  ITransportSummaryCardState
> {
  constructor(props: ITransportSummaryCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] = getTransportSummaryCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={
          <>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {item.code}
            </Text>
            <Text
              variant="small"
              styles={this.props.tokens.defaultCardTitleStyles}
            >
              {item.name}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={item.request}
        footerIconName={footerIconName ?? "Globe"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            {/*
            <CountryIcon
              {...this.props}
              styles={undefined}
              country={"Mexique"}
            />
          */}
          </>
        }
      >
        <div className={classNames.root}></div>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <TravelStatusIcon
            {...this.props}
            requestId={-1}
            status={item.arrivalStatus}
            arrival={true}
            participant={item as any}
          />
          <LabelField
            {...this.props}
            styles={
              (item.nextEvent ?? "") === "A"
                ? {
                    value: { fontWeight: 600, borderBottom: `1px solid gray` },
                  }
                : undefined
            }
            label={i18n.t("requests:periods:grouping:arrivaldate")}
            content={item.arrivalDate}
          />
        </Stack>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <TravelStatusIcon
            {...this.props}
            requestId={-1}
            status={item.departureStatus}
            arrival={false}
            participant={item as any}
          />
          <LabelField
            {...this.props}
            styles={
              (item.nextEvent ?? "") === "D"
                ? {
                    value: {
                      fontWeight: 600,
                      borderBottom: `1px solid gray`,
                    },
                  }
                : undefined
            }
            label={i18n.t("requests:periods:grouping:departuredate")}
            content={item.departureDate}
          />
        </Stack>
      </GridViewCard>
    );
  }
}
