import { styled } from "@fluentui/react/lib/Utilities";
import { IResetPasswordPageProps } from "./ResetPasswordPage.types";
import {
  getStyles,
  IResetPasswordPageStyleProps,
  IResetPasswordPageStyles,
} from "./ResetPasswordPage.styles";
import { ResetPasswordPageComponent } from "./ResetPasswordPage.base";

/**
 * ResetPasswordPage description
 */
export const ResetPasswordPage = styled<
  IResetPasswordPageProps,
  IResetPasswordPageStyleProps,
  IResetPasswordPageStyles
>(ResetPasswordPageComponent, getStyles);
