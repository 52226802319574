import {
  CommandBar,
  ICommandBarItemProps,
  PrimaryButton,
  Stack,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getNewPaymentCommandBarClassNames } from "./NewPaymentCommandBar.styles";
import {
  INewPaymentCommandBarProps,
  INewPaymentCommandBarState,
} from "./NewPaymentCommandBar.types";

export class NewPaymentCommandBarComponent extends React.Component<
  INewPaymentCommandBarProps,
  INewPaymentCommandBarState
> {
  render(): JSX.Element {
    const {
      styles,
      onSubmit,
      onDismiss,
      confirmation,
      submitLabel,
      confirmationLabel,
      submitDisabled,
      dismissDisabled,
      commands,
    } = this.props;
    const [classNames, subComponentStyles] = getNewPaymentCommandBarClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    const submitProps = {
      key: "submit",
      disabled: submitDisabled,
      text: confirmation
        ? confirmationLabel ?? i18n.t("billing:payments:confirmpay")
        : submitLabel ?? i18n.t("billing:payments:submit"),
      iconProps: {
        iconName: "AirTickets",
      },
      styles: {
        label: {
          fontWeight: confirmation ? "bold" : "normal",
        },
      },
      onClick: onSubmit,
    };

    return (
      <div className={classNames.root}>
        <Stack grow tokens={{ childrenGap: 10 }}>
          <PrimaryButton {...submitProps} />
          <div>
            <CommandBar
              className={classNames.root}
              styles={subComponentStyles?.commandBar}
              items={(
                [
                  {
                    key: "cancel",
                    disabled: dismissDisabled,
                    text: i18n.t("billing:payments:cancel"),
                    iconProps: {
                      iconName: "Cancel",
                    },
                    buttonStyles: {
                      root: {
                        backgroundColor: "transparent",
                      },
                    },
                    onClick: onDismiss,
                  },
                ] as ICommandBarItemProps[]
              ).concat(commands ?? [])}
            />
          </div>
          <div style={{ height: "25px" }}></div>
        </Stack>
      </div>
    );
  }
}
