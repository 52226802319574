import { Label, MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { Editor } from "react-draft-wysiwyg";
import i18n from "src/services/i18n";
import RichTextService from "src/services/RichTextService";
import EditorMobileToolbar from "./EditorMobileToolbar";
import { getMessageEditorClassNames } from "./MessageEditor.styles";
import {
  IMessageEditorProps,
  IMessageEditorState,
} from "./MessageEditor.types";
export class MessageEditorComponent extends React.Component<
  IMessageEditorProps,
  IMessageEditorState
> {
  constructor(props: IMessageEditorProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const {
      styles,
      mobile,
      required,
      editorState,
      onChange,
      errorMessage,
      label,
    } = this.props;
    const [classNames] = getMessageEditorClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        {label && <Label required={required}>{label}</Label>}
        <div
          style={{
            border: `1px solid ${
              errorMessage
                ? this.props.theme.palette.red
                : this.props.theme.palette.neutralLighter
            }`,
          }}
        >
          <Editor
            placeholder={i18n.t("messages:assisted:inspection:message:placeholder")}
            wrapperClassName="demo-wrapper"
            editorClassName="demo-editor"
            localization={{ locale: i18n.getLanguage() }}
            toolbar={mobile ? EditorMobileToolbar : undefined}
            editorState={editorState as any}
            onEditorStateChange={(editorState: any) => {
              const html = RichTextService.getHtml(
                (editorState as any) ?? (this.props.editorState as any)
              );
              const el = document.createElement("div");
              el.innerHTML = html;
              const txt: string = el.innerHTML;
              onChange(editorState, txt);
            }}
          />
        </div>
        {errorMessage && (
          <MessageBar messageBarType={MessageBarType.error}>
            {errorMessage}
          </MessageBar>
        )}
      </div>
    );
  }
}
