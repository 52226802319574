import {
  FontWeights,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
  ITextStyles,
} from "@fluentui/react";
import { ICardSectionStyles } from "@fluentui/react-cards";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IMessageDisplayControlSubComponentStyles {
  iconStyles?: IIconStyles;
  footerCardSectionStyles?: ICardSectionStyles;
  dateTextStyles?: ITextStyles;
  subduedTextStyles?: ITextStyles;
}

export interface IMessageDisplayControlStyles
  extends IAmigoBaseStyles<IMessageDisplayControlSubComponentStyles> {}

export interface IMessageDisplayControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessageDisplayControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessageDisplayControlStyleProps,
    IMessageDisplayControlStyles
  >,
  props?: IMessageDisplayControlStyleProps
): [
  IProcessedStyleSet<IMessageDisplayControlStyles>,
  IMessageDisplayControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessageDisplayControlStyleProps,
  IMessageDisplayControlStyles
> = (
  _props: IMessageDisplayControlStyleProps
): IMessageDisplayControlStyles => {
  return {
    subComponentStyles: {
      iconStyles: {
        root: {
          color: "#0078D4",
          fontSize: 16,
          fontWeight: FontWeights.regular,
        },
      },
      footerCardSectionStyles: {
        root: {
          alignSelf: "stretch",
          borderTop: "1px solid #F3F2F1",
        },
      },
      dateTextStyles: {
        root: {
          color: "#505050",
          fontWeight: 600,
        },
      },
      subduedTextStyles: {
        root: {
          color: "#666666",
        },
      },
    },
  };
};
