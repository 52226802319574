import axios from "axios";
import {
  FlightResultsResponse,
  FlightResultsPayload,
} from "src/models/Transports/FlightResultsResponse";
import AuthService from "../AuthService";
import {
  IDropdownOption,
  DropdownMenuItemType,
  IPersonaProps,
} from "@fluentui/react";
import { IFlightCriteria } from "src/models/Transports/IFlightCriteria";
import {
  IFlightOffer,
  IFlightOfferSchedule,
} from "src/models/Transports/IFlightOffer";
import Moment from "moment";
import "moment-duration-format";
import { AircraftTypes } from "./AircraftTypes";
import { IAirport } from "src/models/Transports/IAirport";
import { IDefaultAirports } from "src/models/Transports/IDefaultAirports";
import i18n from "../i18n";

export interface IAirportResults {
  interiors: IPersonaProps[];
  exteriors: IPersonaProps[];
  charterEnabled: boolean;
  marketEnabled: boolean;
}

const formatDate = (v: string) =>
  v !== undefined && v != null && v.length >= 10 ? v.substring(0, 10) : v;

// true
const interiorOptions: IDropdownOption[] = [
  { key: "canada", text: "Canada", itemType: DropdownMenuItemType.Header },
  {
    key: "YUL",
    data: "Montreal, Canada",
    text: "YUL-Montreal Trudeau International Airport",
  },
  {
    key: "YQB",
    data: "Quebec City, Canada",
    text: "YQB-Québec City International Airport",
  },
  {
    key: "YYZ",
    data: "Toronto, Canada",
    text: "YYZ-Toronto Pearson International Airport",
  },
  {
    key: "YVR",
    data: "Vancouver, Canada",
    text: "YVR-Vancouver International Airport",
  },
  {
    key: "YYC",
    data: "Calgary, Canada",
    text: "YYC-Calgary International Airport",
  },
  {
    key: "YOW",
    data: "Ottawa, Canada",
    text: "YOW-Ottawa International Airport",
  },
  {
    key: "YEG",
    data: "Edmonton, Canada",
    text: "YEG-Edmonton International Airport",
  },
  {
    key: "YHZ",
    data: "Halifax, Canada",
    text: "YHZ-Halifax International Airport",
  },
  {
    key: "YWG",
    data: "Winninpeg, Canada",
    text: "YWG-Winnipeg International Airport",
  },
  {
    key: "YYJ",
    data: "Victoria, Canada",
    text: "YYJ-Victoria International Airport",
  },
  {
    key: "YLW",
    data: "Kelowna, Canada",
    text: "YLW-Kelowna International Airport",
  },
  {
    key: "YXE",
    data: "Saskatoon, Canada",
    text: "YXE-Saskatoon International Airport",
  },
];

interface Destination {
  key: string;
  data?: string;
  country: string;
  text: string;
  itemType?: DropdownMenuItemType;
}

const exteriorAirports: Destination[] = [
  {
    key: "mexico",
    data: "Mexico",
    text: "Mexico",
    country: "MX",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "MEX",
    data: "Mexico City, Mexico",
    country: "MX",
    text: "MEX-Benito Juárez International Airport",
  },
  {
    key: "CUN",
    data: "Cancún, Mexico",
    country: "MX",
    text: "MTY-Cancún International Airport",
  },
  {
    key: "MTY",
    data: "Monterrey, Mexico",
    country: "MX",
    text: "MTY-General Mariano Escobedo International Airport",
  },
  {
    key: "SLP",
    data: "San Luis Potosí, Mexico",
    country: "MX",
    text: "SLP-Ponciano Arriaga International Airport",
  },
  {
    key: "divider_1",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "guatemala",
    text: "Guatemala",
    country: "GT",
    data: undefined,
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "GUA",
    data: "Guatemala",
    country: "GT",
    text: "GUA-La Aurora International Airport",
  },
  {
    key: "divider_2",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "El Salvador",
    text: "El Salvador",
    country: "SV",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "SAL",
    data: "El Salvador",
    country: "SV",
    text: "SAL-El Salvador International Airport Saint Oscar Arnulfo Romero y Galdamez",
  },
  {
    key: "divider_3",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "Honduras",
    text: "Honduas",
    country: "HN",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "TGU",
    data: "Honduras",
    country: "HN",
    text: "TGU-Toncontín International Airport",
  },
  {
    key: "RTB",
    data: "Honduras",
    country: "HN",
    text: "RTB-Coxen Hole",
  },
  {
    key: "SAP",
    data: "Honduras",
    country: "HN",
    text: "SAP-San Pedro Sula International Airport",
  },
  {
    key: "divider_4",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "costarica",
    text: "Costa Rica",
    country: "CR",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "SJO",
    data: "Costa Rica",
    country: "CR",
    text: "SJO-Juan Santamaría International Airport",
  },
  {
    key: "divider_5",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "colombia",
    text: "Columbia",
    country: "COL",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "BOG",
    data: "Bogotá, Colombia",
    country: "COL",
    text: "BOG-El Dorado International Airport",
  },
  {
    key: "MDE",
    data: "Rionegro, Colombia",
    country: "COL",
    text: "MDE-José María Córdova International Airport",
  },
  {
    key: "CLO",
    data: "Palmira, Colombia",
    country: "COL",
    text: "CLO-Alfonso Bonilla Aragón International Airport",
  },
  {
    key: "divider_6",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "nicaragua",
    text: "Nicaragua",
    country: "NI",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "MGA",
    data: "Nicaragua",
    country: "NI",
    text: "MGA-Augusto C. Sandino International Airport",
  },
  {
    key: "divider_7",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "dominicanrepublic",
    text: "Dominican Republic",
    country: "DO",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "PUJ",
    data: "Punta Cana, Dominican Republic",
    country: "DO",
    text: "PUJ-Punta Cana International Airport",
  },
  {
    key: "divider_8",
    text: "-",
    country: "",
    itemType: DropdownMenuItemType.Divider,
  },
  {
    key: "panama",
    text: "Panama",
    country: "PA",
    itemType: DropdownMenuItemType.Header,
  },
  {
    key: "PTY",
    data: "Panama",
    country: "PA",
    text: "PTY-Tocumen International Airport",
  },
];
const countOptions: IDropdownOption[] = [];
for (let i = 0; i < 8; i++) {
  countOptions.push({
    key: i + 1,
    data: `nav:transports:flights:passengerscount:${(i + 1).toString()}`,
    text:
      (i + 1).toString() +
      " " +
      i18n.t("transports:flights:passengerscount:label") +
      (i === 0 ? "" : "s"),
  });
}

export class TransportFlightSearchService {
  private static toTitleCase(str: string): string {
    return str.replace(/\w\S*/g, (txt: string) => {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  public static getAirportName(code: string): string {
    const d1 = interiorOptions.filter((x) => x.key === code);
    if (d1.length === 0) {
      const d2 = exteriorAirports.filter((x) => x.key === code);
      return d2.length === 0 ? code : `${d2[0].text.substr(4)} (${code})`;
    }
    return `${d1[0].text.substr(4)} (${code})`;
  }

  public static async loadOffer(
    enterpriseId: number,
    trajetId: number,
    passengers: number,
    arrival: boolean
  ): Promise<any> {
    const x = await axios.post(
      `/api/transports/${enterpriseId}/flights/offer/${trajetId}`,
      {
        enterpriseId,
        trajetId,
        passengersCount: passengers,
        arrival: arrival,
      },
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.data as any;
  }

  public static getOffer(
    data: FlightResultsResponse,
    serviceIndex: number,
    index: number
  ): IFlightOffer {
    const root = data.itineraryGroups[0].itineraries[index];
    const items: IFlightOfferSchedule[] = [];
    let carrier: string = "";
    let operating: string = "";
    const carriers: string[] = [];
    let elapsedtime: number = 0;
    let lasttime: Moment.Moment | undefined;
    let adj: number = 0;
    let lays = 0;

    (root?.legs ?? []).forEach((r) => {
      const leg = data.legDescs.filter((d) => d.id === r.ref)[0];

      leg.schedules.forEach((ee) => {
        const schedules = data.scheduleDescs.filter((s) => s.id === ee.ref);
        schedules.forEach((x) => {
          if (carrier === "") {
            carrier = x.carrier.marketing;
          } else if (
            carrier !== "multiple" &&
            carrier !== x.carrier.marketing
          ) {
            carrier = "multiple";
          }

          if (carriers.filter((z) => z === x.carrier.marketing).length === 0) {
            carriers.push(x.carrier.marketing);
          }

          if (operating === "") {
            operating = x.carrier.operating;
          } else if (
            operating !== "multiple" &&
            operating !== x.carrier.operating
          ) {
            carrier = "multiple";
          }

          const currentDeparture = Moment(`2020-01-01 ${x.departure.time}`).add(
            (ee.departureDateAdjustment ?? 0) +
              (x.departure.dateAdjustment ?? 0),
            "days"
          );
          const lay: number = lasttime
            ? currentDeparture.diff(lasttime.toDate(), "minutes")
            : 0;
          lays += lay;
          const arrivalAdjustment =
            (ee.departureDateAdjustment ?? 0) + (x.arrival.dateAdjustment ?? 0);
          adj += x.arrival.dateAdjustment ?? 0;
          elapsedtime += x.elapsedTime;

          items.push({
            id: x.id,
            elapsedtime: Moment.duration(x.elapsedTime, "minutes").format(
              "h[h] m[m]"
            ),
            layover:
              lay === 0
                ? undefined
                : Moment.duration(lay, "minutes").format("h[h] m[m]"),
            carrier: x.carrier.marketing,
            operating: x.carrier.operating,
            from: x.departure.airport,
            to: x.arrival.airport,
            departureDate: x.departure.state
              ? formatDate(x.departure.state)
              : "",
            arrivalDate: x.arrival.state ? formatDate(x.arrival.state) : "",
            departure: x.departure.time,
            arrival: x.arrival.time,
            departureAjustment: ee.departureDateAdjustment,
            arrivalAdjustement:
              arrivalAdjustment === 0 ? undefined : arrivalAdjustment,
            equipment: x.carrier?.equipment?.code
              ? this.toTitleCase(
                  AircraftTypes.filter(
                    (y) => y.code === x.carrier.equipment.code
                  )[0].name
                )
              : "Airbus A332",
            className: "Economy class",
            flight: `${x.carrier.operating} ${x.carrier.marketingFlightNumber}`,
            marketingFlightNumber: x.carrier.marketingFlightNumber,
            flightNumber: x.carrier.operatingFlightNumber,
          });
          lasttime = Moment(`2020-01-01 ${x.arrival.time}`).add(
            (ee.departureDateAdjustment ?? 0) + (x.arrival.dateAdjustment ?? 0),
            "days"
          );
        });
      });
    });

    const departureDate = items[0].departureDate;
    const departure = items[0].departure;
    const arrival = items[items.length - 1].arrival;
    const from: string = items[0].from;
    const to: string = items[items.length - 1].to;
    const charges =
      root?.pricingInformation?.[0]?.fare?.ancillaryFeeGroup?.ancillaryFees?.map(
        (x) => ({
          name: x.details[0].description.split("@")[
            i18n.getLanguage() === "fr" ? 1 : 0
          ],
          amount: x.details[0].amount,
        })
      );
    return {
      id: root.id,
      serviceIndex: serviceIndex,
      from: from,
      to: to,
      passengersCount:
        root.pricingInformation[0].fare.passengerInfoList[0].passengerInfo
          .passengerNumber,
      adj: adj ? (adj === 0 ? undefined : adj) : undefined,
      carrier: carrier,
      carriers: carriers,
      operating: operating,
      elapsedtime: Moment.duration(elapsedtime + lays, "minutes")
        .format("h[h] m[m]")
        .replace(" 0m", ""),
      charges: root?.pricingInformation?.[0]?.fare?.totalFare?.baseFareAmount ? charges : [],
      subTotalPrice: root?.pricingInformation?.[0]?.fare?.totalFare
        ?.baseFareAmount
        ? root?.pricingInformation?.[0]?.fare?.totalFare?.baseFareAmount *
          root.pricingInformation[0].fare.passengerInfoList[0].passengerInfo
            .passengerNumber
        : undefined,
      totalPrice: root?.pricingInformation?.[0].fare?.totalFare?.totalPrice,
      totalTaxAmount:
        root?.pricingInformation?.[0].fare?.totalFare?.totalTaxAmount,
      bookingFeeAmount: root?.pricingInformation?.[0]?.fare?.totalFare
        ?.baseFareAmount
        ? undefined
        : root?.pricingInformation?.[0].fare?.totalFare?.bookingFeeAmount,
      schedules: items,
      departure: departure,
      departureDate: departureDate,
      arrival: arrival,
    };
  }

  public static async getDefaultAirport(
    enterpriseId: number
  ): Promise<IDefaultAirports> {
    const x = await axios.post(
      `/api/transports/flights/${enterpriseId}/defaults`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    const result = x.data;
    if (!result || result === null) return {};
    return result;
  }

  public static getOffers(source: FlightResultsResponse[]): IFlightOffer[] {
    const items: IFlightOffer[] = [];
    if (!source && source === null) return items;
    const sourceUsed: number[] = [];
    const hasMarket =
      source[1].itineraryGroups.length > 0 &&
      source[1].itineraryGroups[0].itineraries.length > 0;

    if (!hasMarket && source[0].itineraryGroups.length === 0) return [];

    source.forEach((x, rootIndex) => {
      if (
        (rootIndex === 0 && hasMarket) ||
        x.itineraryGroups.length === 0 ||
        x.itineraryGroups[0].itineraries.length === 0
      ) {
        return;
      }

      x.itineraryGroups[0].itineraries.forEach((y, index) => {
        if (y.pricingInformation.length === 0) return;
        if (rootIndex > 0) {
          const bet: number =
            y.pricingInformation?.[0]?.fare?.totalFare?.totalPrice;
          source[0].itineraryGroups[0].itineraries
            .filter(
              (uu) =>
                sourceUsed.filter((_x) => _x === uu.id).length === 0 &&
                uu.pricingInformation.length > 0 &&
                (bet === undefined ||
                  bet == null ||
                  uu.pricingInformation?.[0]?.fare?.totalFare?.totalPrice ===
                    undefined ||
                  uu.pricingInformation[0].fare.totalFare.totalPrice == null) &&
                uu.pricingInformation[0].fare.totalFare.totalPrice < bet
            )
            .forEach((tt, childIndex) => {
              sourceUsed.push(tt.id);
              items.push(this.getOffer(source[0], 0, childIndex));
            });
        }

        items.push(this.getOffer(x, rootIndex, index));
      });
    });
    return items;
  }

  public static getCountryFromIATA(code: string): string {
    const x = exteriorAirports.filter((x) => x.key === code);
    return x.length === 0 ? "MX" : x[0].country;
  }

  public static async getAirports(
    enterpriseId: number
  ): Promise<IAirportResults> {
    const x = await axios.post<IAirport[]>(
      `/api/transports/flights/${enterpriseId}/airports`,
      {} as any,
      AuthService.getAuthHeaderWithLanguage()
    );
    const marketEnabled: boolean =
      x.headers["x-amigo-marketEnabled"] === "true";
    const charterEnabled: boolean =
      x.headers["x-amigo-charterenabled"] === "true";

    /*
    console.log(
      x.data
        .filter((x) => !x.local)
        .sort((r, s) => (r.displayOrder < s.displayOrder ? -1 : 1))
    );
    */
    return {
      marketEnabled: marketEnabled,
      charterEnabled: charterEnabled,
      exteriors: x.data
        .filter((x) => !x.local)
        .sort((r, s) => (r.displayOrder < s.displayOrder ? -1 : 1))
        .map((x) => {
          return {
            styles: {
              root: {
                width: "100%",
              },
            },
            imageInitials: x.iata?.toString() ?? "",
            itemID: x.iata?.toString() ?? "",
            optionalText: "",
            className: `amigo-Airport-${x.iata}`,
            "data-automation-id": x.local
              ? `nav:transports:flights:airport:origin:${x.iata}`
              : `nav:transports:flights:airport:destination:${x.iata}`,
            presence: undefined,
            secondaryText: `${x.cityName}, ${x.state ?? x.country}`,
            showSecondaryText: true,
            tertiaryText: x.iata?.toString() ?? "",
            text: `${x.cityName} International Airport`,
          };
        }),
      interiors: x.data
        .filter((x) => x.local)
        .sort((r, s) => (r.displayOrder < s.displayOrder ? -1 : 1))
        .map((x) => {
          return {
            styles: {
              root: {
                width: "100%",
              },
            },
            imageInitials: x.iata?.toString() ?? "",
            itemID: x.iata?.toString() ?? "",
            optionalText: "",
            className: `amigo-Airport-${x.iata}`,
            "data-automation-id": x.local
              ? `nav:transports:flights:airport:origin:${x.iata}`
              : `nav:transports:flights:airport:destination:${x.iata}`,
            presence: undefined,
            secondaryText: `${x.cityName}, ${x.state ?? x.country}`,
            showSecondaryText: true,
            tertiaryText: x.iata?.toString() ?? "",
            text: `${x.cityName} International Airport`,
          };
        }),
    };
  }

  public static getPassengersNumbers(): Promise<IDropdownOption[]> {
    return new Promise<IDropdownOption[]>((resolve) => {
      resolve(countOptions);
    });
  }

  public static getCarrierImage = (carrier: string) => {
    switch (carrier) {
      case "fv":
        return "/img/fv.png";
      case "K":
        return "/img/fv.png";
      case "Ferme Voyage":
        return "/img/fv.png";
      case "4O":
        return "/img/interjet.png";
      case "AC":
        return "/img/aircanada.png";
      case "AM":
      case "MX":
        return "/img/aeromexico.png";
      case "AV":
        return "/img/avianca.png";
      case "CM":
        return "/img/copa.png";
      case "TS":
        return "/img/airtransat.png";
      case "TX":
        return "/img/aircaraibes.png";
      case "PB":
        return "/img/palairlines.png";
      case "UA":
        return "/img/united.png";
      case "AT":
        return "/img/airmaroc.png";
      case "LI":
        return "/img/liat.png";
      case "KX":
        return "/img/cayman.png";
      case "AN":
        return "/img/advancedair.png";
      case "VW":
        return "/img/aeromar.png";
      case "SW":
        return "/img/airnambia.png";
      case "WG":
        return "/img/sunwing.png";
      case "DL":
        return "/img/delta.png";
      case "AO":
        return "/img/airjuan.png";
      case "PD":
        return "/img/porter.png";
      case "YN":
        return "/img/aircreebec.png";
      case "BW":
        return "/img/caribbean.png";
      case "AA":
        return "/img/americanairlines.png";
      case "LA":
        return "/img/latam.png";
      case "WS":
        return "/img/westjet.png";
    }
    console.log(`${carrier} is missing`);
    return "/img/fv.png";
  };

  public static getCarrierName = (carrier: string) => {
    switch (carrier) {
      case "fv":
        return "Ferme Voyage";
      case "Ferme Voyage":
        return "Ferme Voyage";
      case "4O":
        return "Interjet";
      case "AC":
        return "Air Canada";
      case "AM":
      case "MX":
        return "Aeromexico";
      case "AV":
        return "Avianca";
      case "CM":
        return "Copa";
      case "TS":
        return "Air Transat";
      case "TX":
        return "Air Caraïbes";
      case "PB":
        return "PAL Airlines LTD";
      case "UA":
        return "United Airlines";
      case "AT":
        return "Royal Air Maroc";
      case "LI":
        return "LIAT Airlines";
      case "KX":
        return "Cayman Airways Limited";
      case "AN":
        return "Advanced Air LLC";
      case "VW":
        return "Aeromar";
      case "SW":
        return "Air Nambia";
      case "WG":
        return "Sunwing";
      case "DL":
        return "Delta";
      case "AO":
        return "Air Juan";
      case "PD":
        return "Porter Airlines";
      case "YN":
        return "Air Creebec";
      case "BW":
        return "Caribbean Airlines";
      case "AA":
        return "American Airlines";
      case "LA":
        return "Latam Airlines";
      case "WS":
        return "WestJet";
    }
    console.log(`${carrier} is missing`);
    return carrier;
  };

  public static async searchFlights(
    criteria: IFlightCriteria
  ): Promise<FlightResultsPayload> {
    const x = await axios.all([
      axios.post(
        "api/transports/flights/search",
        criteria,
        AuthService.getAuthHeaderWithLanguage()
      ),
      axios.post(
        "api/transports/flights/search/flexibles",
        criteria,
        AuthService.getAuthHeaderWithLanguage()
      ),
    ]);
    const result: FlightResultsPayload = {
      results: x[0].data as any,
      flexibles: x[1].data as any,
    };

    return result;
  }

  public static async getArrivalOffersAsync(
    enterpriseId: number
  ): Promise<any[]> {
    const x = await axios.post(
      `api/transports/flights/${enterpriseId}/offers/arrivals`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.status === 200 ? (x.data as any) : [];
  }

  public static async getDeparturesOffersAsync(
    enterpriseId: number
  ): Promise<any[]> {
    const x = await axios.post(
      `api/transports/flights/${enterpriseId}/offers/departures`,
      {},
      AuthService.getAuthHeaderWithLanguage()
    );
    return x.status === 200 ? (x.data as any) : [];
  }
}
