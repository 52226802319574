import { Text, TooltipHost, PersonaCoin, PersonaSize } from "@fluentui/react";
import * as React from "react";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getDossierImmigrationCardClassNames } from "./DossierImmigrationCard.styles";
import {
  IDossierImmigrationCardProps,
  IDossierImmigrationCardState,
} from "./DossierImmigrationCard.types";
import { CountryIcon } from "src/components/CountryIcon";
import { UserCallout } from "src/controls/UserCallout";

export class DossierImmigrationCardComponent extends React.Component<
  IDossierImmigrationCardProps,
  IDossierImmigrationCardState
> {
  constructor(props: IDossierImmigrationCardProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, item, columnWidth, footerIconName } = this.props;
    const [classNames, subComponentStyles] =
      getDossierImmigrationCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={`${item.name} - ${item.year}`}
        columnWidth={columnWidth}
        footerText={`${item.participantCount} participants`}
        footerIconName={footerIconName ?? "WorkforceManagement"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        icons={
          <>
            <CountryIcon
              {...this.props}
              styles={undefined}
              country={item.country}
            />
            <TooltipHost
              content={`${item.participantCount.toString()} participants`}
            >
              <PersonaCoin
                initialsColor={"rgb(122, 117, 116)"}
                text={item.participantCount.toString()}
                coinSize={18}
                onRenderInitials={() => {
                  return <span>{item.participantCount.toString()}</span>;
                }}
                size={PersonaSize.size16}
                style={{
                  fontSize: "14px",
                }}
              />
            </TooltipHost>
          </>
        }
      >
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.title}
        </Text>
        <div className={classNames.root} style={{ clear: "both" }}></div>
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.year.toString()}
        </Text>
        {item.agent != null && (
          <UserCallout {...this.props} user={item.agent} />
        )}
        {item.agent === null && (
          <Text style={{ height: "32px" }} variant="medium">
            {!item.agentLabel ||
            item.agentLabel === null ||
            item.agentLabel === ""
              ? i18n.t("global:noagent")
              : item.agentLabel}
          </Text>
        )}
      </GridViewCard>
    );
  }
}
