import * as React from "react";
import { getDocumentsSearchResultsClassNames } from "./DocumentsSearchResults.styles";
import {
  IDocumentsSearchResultsState,
  IDocumentsSearchResultsProps,
} from "./DocumentsSearchResults.types";
import { ActivityItem, Icon, CommandBar } from "@fluentui/react";
import i18n from "src/services/i18n";
import DocumentService from "src/services/Documents/DocumentService";

export class DocumentsSearchResultsComponent extends React.Component<
  IDocumentsSearchResultsProps,
  IDocumentsSearchResultsState
> {
  constructor(props: IDocumentsSearchResultsProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getDocumentsSearchResultsClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <CommandBar
          items={[
            {
              key: "1",
              name: i18n.t("documents:backcategories"),
              iconProps: { iconName: "NavigateBack" },
              onClick: () => this.props.onDismiss(),
              className: "NavigateBack",
              style: {
                fontWeight: "bold",
                backgroundColor: this.props.theme.palette.neutralLighter,
              },
            },
          ]}
          styles={{
            root: {
              backgroundColor: this.props.theme.palette.neutralLighter,
            },
          }}
        />
        <div style={{ height: "20px" }}></div>
        {this.props.results.map((x) => {
          return (
            <div
              key={x.key}
              style={{ padding: mobile ? "10px 5px" : "15px 20px" }}
              onClick={() => {
                x.sectionId &&
                  DocumentService.downloadFormsDocument(x.sectionId, x.key);
              }}
            >
              <ActivityItem
                styles={{
                  root: {
                    cursor: "pointer",
                  },
                }}
                title={x.name}
                activityDescription={x.name}
                timeStamp={x.size}
                activityIcon={<Icon iconName={x.iconName} />}
              />
            </div>
          );
        })}
      </div>
    );
  }
}
