import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentMethodsControlProps } from "./PaymentMethodsControl.types";
import {
  getStyles,
  IPaymentMethodsControlStyleProps,
  IPaymentMethodsControlStyles,
} from "./PaymentMethodsControl.styles";
import { PaymentMethodsControlComponent } from "./PaymentMethodsControl.base";

/**
 * PaymentMethodsControl description
 */
export const PaymentMethodsControl = styled<
  IPaymentMethodsControlProps,
  IPaymentMethodsControlStyleProps,
  IPaymentMethodsControlStyles
>(PaymentMethodsControlComponent, getStyles);
