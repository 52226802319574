import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantPathwayProps } from "./RequestParticipantPathway.types";
import {
  getStyles,
  IRequestParticipantPathwayStyleProps,
  IRequestParticipantPathwayStyles,
} from "./RequestParticipantPathway.styles";
import { RequestParticipantPathwayComponent } from "./RequestParticipantPathway.base";

/**
 * RequestParticipantPathway
 */
export const RequestParticipantPathway = styled<
  IRequestParticipantPathwayProps,
  IRequestParticipantPathwayStyleProps,
  IRequestParticipantPathwayStyles
>(RequestParticipantPathwayComponent, getStyles);
