import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IPanelStyleProps,
  IPanelStyles,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface IFilterPaneSubComponentStyles {
  panel?: IStyleFunctionOrObject<IPanelStyleProps, IPanelStyles>;
}

export interface IFilterPaneStyles
  extends IComponentBaseStyles<IFilterPaneSubComponentStyles> {
  footerSection?: IStyle;
  footerButton?: IStyle;
  headerSection?: IStyle;
  filterTitle?: IStyle;
  subComponentStyles?: IFilterPaneSubComponentStyles;
}

export interface IFilterPaneStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getFilterPaneClassNames = (
  styles?: IStyleFunctionOrObject<IFilterPaneStyleProps, IFilterPaneStyles>,
  props?: IFilterPaneStyleProps
): [
  IProcessedStyleSet<IFilterPaneStyles>,
  IFilterPaneSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFilterPaneStyleProps,
  IFilterPaneStyles
> = (_props: IFilterPaneStyleProps): IFilterPaneStyles => {
  return {
    footerSection: {
      padding: "10px",
      width: "100%",
      position: "absolute",
      bottom: 0,
    },
    footerButton: {
      width: "100%",
    },
    headerSection: {
      width: "100%",
    },
    filterTitle: {
      margin: "0 15px!important",
    },
  };
};
