import { styled } from "@fluentui/react/lib/Utilities";
import { INewPaymentSecureDialogProps } from "./NewPaymentSecureDialog.types";
import {
  getStyles,
  INewPaymentSecureDialogStyleProps,
  INewPaymentSecureDialogStyles,
} from "./NewPaymentSecureDialog.styles";
import { NewPaymentSecureDialogComponent } from "./NewPaymentSecureDialog.base";

/**
 * NewPaymentSecureDialog description
 */
export const NewPaymentSecureDialog = styled<
  INewPaymentSecureDialogProps,
  INewPaymentSecureDialogStyleProps,
  INewPaymentSecureDialogStyles
>(NewPaymentSecureDialogComponent, getStyles);
