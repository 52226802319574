import { styled } from "@fluentui/react/lib/Utilities";
import { IUnauthenticatedLayoutProps } from "./UnauthenticatedLayout.types";
import {
  getStyles,
  IUnauthenticatedLayoutStyleProps,
  IUnauthenticatedLayoutStyles,
} from "./UnauthenticatedLayout.styles";
import { UnauthenticatedLayoutComponent } from "./UnauthenticatedLayout.base";

/**
 * UnauthenticatedLayout description
 */
export const UnauthenticatedLayout = styled<
  IUnauthenticatedLayoutProps,
  IUnauthenticatedLayoutStyleProps,
  IUnauthenticatedLayoutStyles
>(UnauthenticatedLayoutComponent, getStyles);
