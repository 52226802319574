import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightSearchOffsetOffersSubComponentStyles {}

export interface IFlightSearchOffsetOffersStyles
  extends IAmigoBaseStyles<IFlightSearchOffsetOffersSubComponentStyles> {}

export interface IFlightSearchOffsetOffersStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightSearchOffsetOffersClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightSearchOffsetOffersStyleProps,
    IFlightSearchOffsetOffersStyles
  >,
  props?: IFlightSearchOffsetOffersStyleProps
): [
  IProcessedStyleSet<IFlightSearchOffsetOffersStyles>,
  IFlightSearchOffsetOffersSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightSearchOffsetOffersStyleProps,
  IFlightSearchOffsetOffersStyles
> = (
  _props: IFlightSearchOffsetOffersStyleProps
): IFlightSearchOffsetOffersStyles => {
  return {
    root: [
      "amigo-FlightSearchOffsetOffers",
      {
        width: "90.5wh",
      },
    ],
  };
};
