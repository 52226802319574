import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightBookingParticipantsPageSubComponentStyles {}

export interface IFlightBookingParticipantsPageStyles
  extends IAmigoBaseStyles<IFlightBookingParticipantsPageSubComponentStyles> {}

export interface IFlightBookingParticipantsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightBookingParticipantsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightBookingParticipantsPageStyleProps,
    IFlightBookingParticipantsPageStyles
  >,
  props?: IFlightBookingParticipantsPageStyleProps
): [
  IProcessedStyleSet<IFlightBookingParticipantsPageStyles>,
  IFlightBookingParticipantsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightBookingParticipantsPageStyleProps,
  IFlightBookingParticipantsPageStyles
> = (
  _props: IFlightBookingParticipantsPageStyleProps
): IFlightBookingParticipantsPageStyles => {
  return {
    root: [
      "amigo-FlightBookingParticipantsPage",
      {
        overflowX: "hidden",
      },
    ],
  };
};
