import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentElementCardProps } from "./PaymentElementCard.types";
import {
  getStyles,
  IPaymentElementCardStyleProps,
  IPaymentElementCardStyles,
} from "./PaymentElementCard.styles";
import { PaymentElementCardComponent } from "./PaymentElementCard.base";

/**
 * PaymentElementCard description
 */
export const PaymentElementCard = styled<
  IPaymentElementCardProps,
  IPaymentElementCardStyleProps,
  IPaymentElementCardStyles
>(PaymentElementCardComponent, getStyles);
