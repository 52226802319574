import { styled } from "@fluentui/react/lib/Utilities";
import { IMessagesFolderProps } from "./MessagesFolder.types";
import {
  getStyles,
  IMessagesFolderStyleProps,
  IMessagesFolderStyles,
} from "./MessagesFolder.styles";
import { MessagesFolderComponent } from "./MessagesFolder.base";

/**
 * MessagesFolder description
 */
export const MessagesFolder = styled<
  IMessagesFolderProps,
  IMessagesFolderStyleProps,
  IMessagesFolderStyles
>(MessagesFolderComponent, getStyles);
