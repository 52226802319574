import {
  ScrollablePane,
  ScrollbarVisibility,
  Sticky,
  StickyPositionType,
} from "@fluentui/react";
import * as React from "react";
import MaintenanceService from "src/services/MaintenanceService";
import { Footer } from "../Footer";
import { MaintenanceBar } from "../MaintenanceBar";
import { NavHeader } from "../NavHeader";
import { NavigationMenu } from "../NavigationMenu";
import { ScrollToTop } from "../ScrollToTop/ScrollToTop";
import { getMasterPageClassNames } from "./MasterPage.styles";
import { IMasterPageProps, IMasterPageState } from "./MasterPage.types";

export class MasterPageComponent extends React.Component<
  IMasterPageProps,
  IMasterPageState
> {
  constructor(props: IMasterPageProps) {
    super(props);
    this.state = {
      t: 0,
    };
  }

  render() {
    const _this = this;
    const { styles, menucollapsed, children } = this.props;
    const [classNames, subComponentStyles] = getMasterPageClassNames(styles!, {
      ...this.props,
      ...this.state,
      onmaintenance: MaintenanceService.isOnMaintenance(),
    });
    return (
      <ScrollablePane
        scrollbarVisibility={ScrollbarVisibility.auto}
        className={classNames.scrollPane}
        styles={subComponentStyles?.scrollPaneComponent}
      >
        <Sticky stickyPosition={StickyPositionType.Header}>
          <div className={classNames.header}>
            <NavHeader {...this.props} styles={undefined} />
          </div>
        </Sticky>
        <MaintenanceBar
          {...this.props}
          styles={subComponentStyles?.maintenanceBar}
          onDismiss={() => {
            _this.props.ackownledgeMaintenance();
            (window as any).nomaintenance = 1;
            _this.setState({
              t: 0,
            });
          }}
        />
        <div className={classNames.body}>
          <div id="main_content" className={classNames.content}>
            {children}
          </div>
          {this.props.mobile ? (
            <div></div>
          ) : (
            <div className={classNames.sidebar}>
              <NavigationMenu
                {...this.props}
                styles={subComponentStyles?.nav}
                groups={this.props.menu}
                collapsed={menucollapsed}
              />
            </div>
          )}
        </div>
        <Sticky
          stickyPosition={StickyPositionType.Footer}
          stickyClassName={classNames.stickyFooter}
        >
          <Footer {...this.props} styles={subComponentStyles?.footer} />
          <ScrollToTop
            {...this.props}
            isVisible={undefined}
            styles={subComponentStyles?.scrollToTop}
          />
        </Sticky>
      </ScrollablePane>
    );
  }
}
