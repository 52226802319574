import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightPersonalizedOffersProps } from "./FlightPersonalizedOffers.types";
import {
  getStyles,
  IFlightPersonalizedOffersStyleProps,
  IFlightPersonalizedOffersStyles,
} from "./FlightPersonalizedOffers.styles";
import { FlightPersonalizedOffersComponent } from "./FlightPersonalizedOffers.base";

/**
 * FlightPersonalizedOffers
 */
export const FlightPersonalizedOffers = styled<
  IFlightPersonalizedOffersProps,
  IFlightPersonalizedOffersStyleProps,
  IFlightPersonalizedOffersStyles
>(FlightPersonalizedOffersComponent, getStyles);
