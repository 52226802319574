import {
  CommandBar,
  CommandBarButton,
  ICommandBarItemProps,
  IOverflowSetItemProps,
  OverflowSet,
  Persona,
  PersonaPresence,
  PersonaSize,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getEnterpriseContactCardClassNames } from "./EnterpriseContactCard.styles";
import {
  IEnterpriseContactCardProps,
  IEnterpriseContactCardState,
} from "./EnterpriseContactCard.types";

export class EnterpriseContactCardComponent extends React.Component<
  IEnterpriseContactCardProps,
  IEnterpriseContactCardState
> {
  constructor(props: IEnterpriseContactCardProps) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  private _onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    const click: () => void =
      item.key === "expand"
        ? () => this.setState({ expanded: !this.state.expanded })
        : () => this.setState({ tootip: item.text });

    return (
      <CommandBarButton
        role="menuitem"
        alt={item.text}
        aria-label={item.text}
        onMouseOver={
          this.props.mobile || this.state.expanded
            ? undefined
            : () => this.setState({ tootip: item.text })
        }
        onMouseOut={
          this.props.mobile || this.state.expanded
            ? undefined
            : () => this.setState({ tootip: undefined })
        }
        onClick={click}
        styles={{ root: { padding: "10px" } }}
        iconProps={item.iconProps}
        href={item.href}
        text={this.props.mobile || this.state.expanded ? item.text : undefined}
      />
    );
  };

  render(): JSX.Element {
    const { styles, contact, route } = this.props;
    const [classNames] = getEnterpriseContactCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const roles: ICommandBarItemProps[] = [];
    if (!this.props.mobile && contact.isNewsletterMember)
      roles.push({
        key: "expand",
        iconProps: {
          iconName: this.state.expanded ? "SkypeCircleMinus" : "CircleAddition",
        },
        text: "...",
      });
    if (contact.isNewsletterMember)
      roles.push({
        key: "newsletter",
        iconProps: { iconName: "News" },
        text: i18n.t("enterprises:contacts:newsletter"),
      });
    if (contact.isBillingContact)
      roles.push({
        key: "billing",
        iconProps: { iconName: "PaymentCard" },
        text: i18n.t("enterprises:contacts:billing"),
      });
    if (contact.isRequestContact)
      roles.push({
        key: "request",
        iconProps: { iconName: "DocumentSet" },
        text: i18n.t("enterprises:contacts:request"),
      });
    if (contact.isFermeTravelContact)
      roles.push({
        key: "ferme",
        iconProps: { iconName: "AirplaneSolid" },
        text: i18n.t("enterprises:contacts:ferme"),
      });

    return (
      <div className={classNames.root}>
        <div className={classNames.header}>
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            primaryText={`${contact.firstName} ${contact.lastName}`}
            secondaryText={contact.title}
            title={`${contact.firstName} ${contact.lastName}`}
            tertiaryText={""}
            onRenderTertiaryText={() => {
              return (
                <Stack>
                  {contact.email && <Text>{contact.email}</Text>}
                  {contact.phone && <Text>{contact.phone}</Text>}
                  {contact.cellphone && <Text>{contact.cellphone}</Text>}
                </Stack>
              );
            }}
            size={PersonaSize.size72}
            presence={PersonaPresence.none}
            imageAlt={contact.name}
          />
        </div>
        <div className={classNames.inner}>
          <CommandBar
            styles={{ root: { paddingLeft: "2px" } }}
            items={[
              {
                key: "email",
                title: contact.email,
                tooltipHostProps: {
                  content: contact.email,
                },
                iconProps: { iconName: "Mail" },
                text: i18n.t("contacts:email"),
                href: `mailto:${contact.email}`,
              },
              {
                key: "conversation",
                iconProps: { iconName: "CommentAdd" },
                text: i18n.t("contacts:communication"),
                onClick: () => {
                  route.history.push("/messages", {
                    recipients: [contact],
                  });
                },
              },
              {
                key: "phone",
                title: contact.phone,
                iconProps: { iconName: "Phone" },
                text: i18n.t("contacts:phone"),
                href: i18n.PhoneLink(contact.phone),
              },
              {
                key: "cellphone",
                title: contact.cellphone,
                iconProps: { iconName: "CellPhone" },
                text: i18n.t("contacts:cellphone"),
                href: i18n.PhoneLink(contact.cellphone),
              },
            ]}
          />
          <div>
            <h3 style={{ marginLeft: "15px" }}>{i18n.t("contacts:roles")}</h3>
          </div>
          <div style={{ float: "left" }}>
            <OverflowSet
              vertical={this.props.mobile || this.state.expanded}
              onRenderItem={this._onRenderItem}
              onRenderOverflowButton={() => <React.Fragment />}
              items={roles}
            />
          </div>

          {!this.props.mobile && !this.state.expanded ? (
            <div
              style={{
                textAlign: "right",
                width: "195px",
                float: "right",
                marginTop: "6px",
              }}
            >
              <Text>{this.state.tootip}</Text>
            </div>
          ) : (
            <div
              style={{
                textAlign: "right",
                width: "143px",
                float: "right",
                marginTop: "6px",
              }}
            ></div>
          )}

          <div style={{ clear: "both", height: "20px" }}></div>
          <div></div>
        </div>
      </div>
    );
  }
}
