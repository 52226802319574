import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchPageProps } from "./FlightSearchPage.types";
import {
  getStyles,
  IFlightSearchPageStyleProps,
  IFlightSearchPageStyles,
} from "./FlightSearchPage.styles";
import { FlightSearchPageComponent } from "./FlightSearchPage.base";

/**
 * FlightSearchPage
 */
export const FlightSearchPage = styled<
  IFlightSearchPageProps,
  IFlightSearchPageStyleProps,
  IFlightSearchPageStyles
>(FlightSearchPageComponent, getStyles);
