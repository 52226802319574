import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IMessageEditorSubComponentStyles {}

export interface IMessageEditorStyles
  extends IAmigoBaseStyles<IMessageEditorSubComponentStyles> {}

export interface IMessageEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getMessageEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessageEditorStyleProps,
    IMessageEditorStyles
  >,
  props?: IMessageEditorStyleProps
): [
  IProcessedStyleSet<IMessageEditorStyles>,
  IMessageEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessageEditorStyleProps,
  IMessageEditorStyles
> = (_props: IMessageEditorStyleProps): IMessageEditorStyles => {
  return {
    root: ["amigo-MessageEditor", {}],
  };
};
