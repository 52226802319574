import axios from "axios";
import { IUser } from "../../models/User";
import AuthService from "../AuthService";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";

class UsersService {
  private _users?: IUser[] = undefined;
  private _usersPages: number = 1;

  public init(): void {
    this._users = undefined;
    this._usersPages = 1;
  }

  private pageSize: number = 500;

  private translateSortBy = (field: string): string => {
    return field
      .replace("id", "Id")
      .replace("name", "Prenom")
      .replace("email", "Courriel")
      .replace("role", "AspNetUserRoles");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public getUserTypes(): Promise<IChoiceValue[]> {
    return axios
      .post("/api/users/types", {}, AuthService.getAuthHeaderWithLanguage())
      .then((x) => {
        return x.data as any;
      })
      .catch((_x) => {
        return [];
      });
  }

  public getUsers(
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IUser[]> {
    const _this = this;
    let currentPage = this._usersPages;
    let combine: boolean;
    if (next && _this._users !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        "/api/users",
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._users !== undefined) {
          _this._usersPages = currentPage;
          _this._users = _this._users.concat(items);
        } else {
          _this._usersPages = 1;
          _this._users = this._users = items;
        }
        return _this._users ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this._users ?? [];
      });
  }
}

export default new UsersService();
