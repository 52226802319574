import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IPaymentDetailPageSubComponentStyles {}

export interface IPaymentDetailPageStyles
  extends IAmigoBaseStyles<IPaymentDetailPageSubComponentStyles> {}

export interface IPaymentDetailPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentDetailPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentDetailPageStyleProps,
    IPaymentDetailPageStyles
  >,
  props?: IPaymentDetailPageStyleProps
): [
  IProcessedStyleSet<IPaymentDetailPageStyles>,
  IPaymentDetailPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentDetailPageStyleProps,
  IPaymentDetailPageStyles
> = (props: IPaymentDetailPageStyleProps): IPaymentDetailPageStyles => {
  return {
    root: ["amigo-PaymentDetailPage", props.tokens.defaultPageRootStyle],
  };
};
