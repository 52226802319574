import { DefaultPalette } from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { getParticipantWorkInfoClassNames } from "./ParticipantWorkInfo.styles";
import {
  IParticipantWorkInfoProps,
  IParticipantWorkInfoState,
} from "./ParticipantWorkInfo.types";

export class ParticipantWorkInfoComponent extends React.Component<
  IParticipantWorkInfoProps,
  IParticipantWorkInfoState
> {
  render(): JSX.Element {
    const { styles, participant, tablet } = this.props;
    const [classNames, subComponentStyles] = getParticipantWorkInfoClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4 ms-xxl4">
          <div
            style={{
              position: "relative",
              backgroundColor: DefaultPalette.white,
              margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
            }}
            className="ms-depth-16"
          >
            <IconHeader
              {...this.props}
              styles={subComponentStyles?.iconHeader}
              iconName={"Work"}
              title={i18n.t("participants:workinfo")}
            />
            {participant && (
              <div style={{ padding: "10px 20px" }}>
                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  iconProps={{
                    iconName: "UserFollowed",
                  }}
                  content={i18n.t(
                    participant.status ? "global:active" : "global:inactive"
                  )}
                  label={i18n.t("participants:status")}
                />

                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  iconProps={{
                    iconName: "UserOptional",
                  }}
                  content={participant.marks}
                  label={i18n.t("participants:marks")}
                />

                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  iconProps={{
                    iconName: "EntitlementPolicy",
                  }}
                  content={participant.insurance}
                  label={i18n.t("participants:insurance")}
                />

                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  iconProps={{
                    iconName: "EventDate",
                  }}
                  content={participant.recallStatus}
                  label={i18n.t("participants:recall")}
                />

                <LabelField
                  {...this.props}
                  styles={subComponentStyles?.label}
                  iconProps={{
                    iconName: "EventDate",
                  }}
                  content={participant.modifiedOn}
                  label={i18n.t("participants:modifiedon")}
                />
              </div>
            )}
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
