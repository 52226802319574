import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";
import { MediaQueries } from "../MediaQueries";

export interface ICustomSpinnerSubComponentStyles {}

export interface ICustomSpinnerStyles
  extends IComponentBaseStyles<ICustomSpinnerSubComponentStyles> {
  loader?: IStyle;
  loaderContainer?: IStyle;
  loaderWrapper?: IStyle;
  labelWrapper?: IStyle;
  label?: IStyle;
  footer?: IStyle;
}

export interface ICustomSpinnerStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getCustomSpinnerClassNames = (
  styles?: IStyleFunctionOrObject<
    ICustomSpinnerStyleProps,
    ICustomSpinnerStyles
  >,
  props?: ICustomSpinnerStyleProps
): [
  IProcessedStyleSet<ICustomSpinnerStyles>,
  ICustomSpinnerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ICustomSpinnerStyleProps,
  ICustomSpinnerStyles
> = (_props: ICustomSpinnerStyleProps): ICustomSpinnerStyles => {
  return {
    root: {
      padding: "10px",
    },
    loaderContainer: {
      width: "180px",
      marginLeft: "10px",
      float: "left",
    },
    labelWrapper: {
      clear: "both",
      selectors: {
        [MediaQueries.mobile]: {
          clear: "unset",
          width: "180px",
          float: "left",
          textAlign: "center",
        },
      },
    },
    footer: {
      clear: "both",
    },
  };
};
