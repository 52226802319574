import { Stack } from "@fluentui/react";
import * as React from "react";
import { getProgressPanelClassNames } from "./ProgressPanel.styles";
import {
  IProgressPanelProps,
  IProgressPanelState,
} from "./ProgressPanel.types";

export class ProgressPanelComponent extends React.Component<
  IProgressPanelProps,
  IProgressPanelState
> {
  constructor(props: IProgressPanelProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, horizontal, horizontalAlign, mobile } = this.props;
    const [classNames] = getProgressPanelClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack
        className={classNames.root}
        horizontal={horizontal === undefined ? !mobile : horizontal}
        horizontalAlign={horizontalAlign || "center"}
      >
        <Stack.Item className={classNames.rootAnimation} align="center">
          <div className={classNames.animationContainer}>
            <div className="nvx-loader">
              <div className="nvx-loader"></div>
            </div>
          </div>
        </Stack.Item>
        <Stack.Item className={classNames.rootMessage} align="center">
          <div className={classNames.messageContainer}>
            <h2 className={classNames.message}>{this.props.message}</h2>
          </div>
        </Stack.Item>
      </Stack>
    );
  }
}
