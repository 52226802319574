import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightSearchOffsetOffersProps } from "./FlightSearchOffsetOffers.types";
import {
  getStyles,
  IFlightSearchOffsetOffersStyleProps,
  IFlightSearchOffsetOffersStyles,
} from "./FlightSearchOffsetOffers.styles";
import { FlightSearchOffsetOffersComponent } from "./FlightSearchOffsetOffers.base";

/**
 * FlightSearchOffsetOffers
 */
export const FlightSearchOffsetOffers = styled<
  IFlightSearchOffsetOffersProps,
  IFlightSearchOffsetOffersStyleProps,
  IFlightSearchOffsetOffersStyles
>(FlightSearchOffsetOffersComponent, getStyles);
