import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingRequestsGridViewProps } from "./FlightBookingRequestsGridView.types";
import {
  getStyles,
  IFlightBookingRequestsGridViewStyleProps,
  IFlightBookingRequestsGridViewStyles,
} from "./FlightBookingRequestsGridView.styles";
import { FlightBookingRequestsGridViewComponent } from "./FlightBookingRequestsGridView.base";

/**
 * FlightBookingRequestsGridView
 */
export const FlightBookingRequestsGridView = styled<
  IFlightBookingRequestsGridViewProps,
  IFlightBookingRequestsGridViewStyleProps,
  IFlightBookingRequestsGridViewStyles
>(FlightBookingRequestsGridViewComponent, getStyles);
