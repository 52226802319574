import { styled } from "@fluentui/react/lib/Utilities";
import { IIconHeaderProps } from "./IconHeader.types";
import {
  getStyles,
  IIconHeaderStyleProps,
  IIconHeaderStyles,
} from "./IconHeader.styles";
import { IconHeaderComponent } from "./IconHeader.base";

/**
 * IconHeader description
 */
export const IconHeader = styled<
  IIconHeaderProps,
  IIconHeaderStyleProps,
  IIconHeaderStyles
>(IconHeaderComponent, getStyles);
