import {
  IStyle,
  IStyleSet,
  IProcessedStyleSet,
} from "@fluentui/react/lib/Styling";
import {
  classNamesFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react/lib/Utilities";

export interface IComponentBaseStyles<T> {
  root?: IStyle;
  subComponentStyles?: T;
}

export const getComponentClassNames = <
  TStyleProps,
  TStyleSet extends IStyleSet<TStyleSet>,
  TSubComponentStyles
>(
  styles?: IStyleFunctionOrObject<TStyleProps, TStyleSet>,
  props?: TStyleProps
): [IProcessedStyleSet<TStyleSet>, TSubComponentStyles | undefined] => {
  const getClassNames = classNamesFunction<TStyleProps, TStyleSet>();
  const classNames = getClassNames(styles!, props);
  return [
    classNames,
    classNames.subComponentStyles as unknown as TSubComponentStyles,
  ];
};
