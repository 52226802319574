import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantPositionsControlProps } from "./ParticipantPositionsControl.types";
import {
  getStyles,
  IParticipantPositionsControlStyleProps,
  IParticipantPositionsControlStyles,
} from "./ParticipantPositionsControl.styles";
import { ParticipantPositionsControlComponent } from "./ParticipantPositionsControl.base";

/**
 * ParticipantPositionsControl
 */
export const ParticipantPositionsControl = styled<
  IParticipantPositionsControlProps,
  IParticipantPositionsControlStyleProps,
  IParticipantPositionsControlStyles
>(ParticipantPositionsControlComponent, getStyles);
