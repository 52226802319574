import {
  IStyle,
  IStyleFunction,
  IProcessedStyleSet,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";
import { MediaQueries } from "../MediaQueries";

export interface INavBarSubComponentStyles {}

export interface INavBarStyles
  extends IComponentBaseStyles<INavBarSubComponentStyles> {
  appTitle?: IStyle;
  accountName?: IStyle;
  iconButton?: IStyle;
  environment?: IStyle;
  french?: IStyle;
  english?: IStyle;
}

export interface INavBarStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  language: string;
}

export const getNavBarClassNames = (
  styles?: IStyleFunctionOrObject<INavBarStyleProps, INavBarStyles>,
  props?: INavBarStyleProps
): [
  IProcessedStyleSet<INavBarStyles>,
  INavBarSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<INavBarStyleProps, INavBarStyles> = (
  props: INavBarStyleProps
): INavBarStyles => {
  return {
    appTitle: [
      "ms-AppHeader-Title",
      {
        margin: "1px 0px",
        color: props.theme.palette.themePrimary,
        fontSize: "17px",
        fontWeight: "bold",
        textAlign: "right",
        [MediaQueries.mobile]: {
          margin: "1px 8px",
          textAlign: "left",
        },
      },
    ],
    accountName: [
      "ms-AppHeader-AccountName",
      {
        fontSize: "11px",
        margin: "1px 0px",
        color: props.theme.palette.black,
        textAlign: "right",
        [MediaQueries.mobile]: {
          margin: "1px 8px",
          textAlign: "left",
        },
      },
    ],
    environment: [
      "ms-AppHeader-Environment",
      {
        margin: "5px",
        color: props.theme.palette.themePrimary,
      },
    ],
    iconButton: [
      "ms-AppHeadder-IconButton",
      props.tokens?.defaultNavBarButton ?? {
        fontSize: "21px",
        selectors: {
          [MediaQueries.mobile]: {
            fontSize: "17px",
          },
          "&.ms-Button-icon": {
            fontSize: "21px",
            [MediaQueries.mobile]: {
              fontSize: "17px",
            },
          },
        },
      },
    ],
    english: [
      "ms-NavBar-English",
      {
        fontWeight: props.language === "en" ? "bold" : "normal",
        selectors: {
          ".ms-ContextualMenu-linkContent": {
            fontWeight: props.language === "en" ? "bold" : "normal",
          },
        },
      },
    ],
    french: [
      "ms-NavBar-French",
      {
        fontWeight: props.language === "fr" ? "bold" : "normal",
        selectors: {
          ".ms-ContextualMenu-linkContent": {
            fontWeight: props.language === "fr" ? "bold" : "normal",
          },
        },
      },
    ],
  };
};
