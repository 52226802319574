import {
  DefaultPalette,
  Icon,
  Stack,
  StackItem,
  Text,
  TooltipHost,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { LabelField } from "src/components/LabelField";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import { getParticipantAddressSummaryClassNames } from "./ParticipantAddressSummary.styles";
import {
  IParticipantAddressSummaryProps,
  IParticipantAddressSummaryState,
} from "./ParticipantAddressSummary.types";

export class ParticipantAddressSummaryComponent extends React.Component<
  IParticipantAddressSummaryProps,
  IParticipantAddressSummaryState
> {
  constructor(props: IParticipantAddressSummaryProps) {
    super(props);
    this.state = {};
  }

  /*

  private hasAddress = (address: IAddress): boolean => {
    return (
      address.street?.length > 0 &&
      address.city?.length > 0 &&
      address.postalcode?.length > 0
    );
  };

  */

  render(): JSX.Element {
    const { styles, participant, tablet } = this.props;
    const [classNames] = getParticipantAddressSummaryClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const candEdit: boolean = AuthService.hasEditPartcipantPermission();
    return (
      <div className={classNames.root}>
        <div>
          <div
            style={{
              position: "relative",
              backgroundColor: DefaultPalette.white,
              margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
            }}
          >
            <Stack
              horizontal
              tokens={{ childrenGap: 10 }}
              verticalAlign="center"
            >
              <StackItem>
                <TooltipHost content={i18n.t("participants:address")}>
                  <Icon
                    style={{
                      fontSize: "22px",
                      padding: "20px 10px",
                    }}
                    iconName="MapPin"
                  />
                </TooltipHost>
              </StackItem>
              <StackItem grow>
                {tablet ? (
                  <h4>{i18n.t("participants:address")}</h4>
                ) : (
                  <h3>{i18n.t("participants:address")}</h3>
                )}
              </StackItem>
              {candEdit && (
                <StackItem>
                  <TooltipHost content={i18n.t("participants:edit")}>
                    <Icon
                      iconName={"Edit"}
                      style={{
                        fontSize: tablet ? "18px" : "22px",
                        cursor: "pointer",
                        padding: "20px 10px 0 10px",
                      }}
                      onClick={() => this.props.onEditParticipant(participant)}
                    />
                  </TooltipHost>
                </StackItem>
              )}
            </Stack>

            {participant && (
              <div style={{ padding: "10px 10px 20px 10px" }}>
                <Stack tokens={{ childrenGap: 10 }}>
                  <div>
                    <div>{participant.address.street}</div>
                    <div>
                      {participant.address.city}{" "}
                      {participant.address.state != null &&
                      participant.address.state !== ""
                        ? "("
                        : ""}
                      {participant.address.state}
                      {participant.address.state != null &&
                      participant.address.state !== ""
                        ? ")"
                        : ""}
                    </div>
                    <div>{participant.address.postalcode}</div>
                    <div>
                      <Text style={{ marginRight: "2px" }}>
                        {participant.address.country}
                      </Text>
                      <CountryIcon
                        {...this.props}
                        styles={undefined}
                        country={participant.address.country}
                      />
                    </div>
                  </div>
                  {participant.phone && participant.phone.length > 0 && (
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      iconProps={{ iconName: "Phone" }}
                      label={i18n.t("participants:phone")}
                      content={participant.phone}
                    />
                  )}
                  {participant.cellphone &&
                    participant.cellphone.length > 0 && (
                      <LabelField
                        {...this.props}
                        styles={undefined}
                        iconProps={{ iconName: "CellPhone" }}
                        label={i18n.t("participants:cellphone")}
                        content={participant.cellphone}
                      />
                    )}
                  {participant.email && participant.email.length > 0 && (
                    <LabelField
                      {...this.props}
                      styles={undefined}
                      iconProps={{ iconName: "Mail" }}
                      label={i18n.t("participants:email")}
                      content={participant.email}
                    />
                  )}
                </Stack>
              </div>
            )}
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
