import { styled } from "@fluentui/react/lib/Utilities";
import { IBillingDetailsPanelContentProps } from "./BillingDetailsPanelContent.types";
import {
  getStyles,
  IBillingDetailsPanelContentStyleProps,
  IBillingDetailsPanelContentStyles,
} from "./BillingDetailsPanelContent.styles";
import { BillingDetailsPanelContentComponent } from "./BillingDetailsPanelContent.base";

/**
 * BillingDetailsPanelContent
 */
export const BillingDetailsPanelContent = styled<
  IBillingDetailsPanelContentProps,
  IBillingDetailsPanelContentStyleProps,
  IBillingDetailsPanelContentStyles
>(BillingDetailsPanelContentComponent, getStyles);
