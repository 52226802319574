import { SelectionMode, Selection } from "@fluentui/react/lib/Utilities";
import {
  Stack,
  TextField,
  MessageBarType,
  MessageBar,
  Text,
  DefaultPalette,
} from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import { GridView } from "src/components/GridView";
import {
  GridViewOptionsBuilder,
  ensureSelection,
} from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { IconHeader } from "src/components/IconHeader";
import {
  IPaymentMethod,
  isCreditCardExpirationValid,
} from "src/models/Enterprises/IPaymentMethod";
import { getNewPaymentMethodSelectorClassNames } from "./NewPaymentMethodSelector.styles";
import {
  INewPaymentMethodSelectorProps,
  INewPaymentMethodSelectorState,
} from "./NewPaymentMethodSelector.types";
import { PaymentMethodSelectorCard } from "../PaymentMethodSelectorCard";
import PaymentsService from "src/services/Billing/PaymentsService";
import i18n from "src/services/i18n";
import { StackItem } from "@fluentui/react";

export class NewPaymentMethodSelectorComponent extends React.Component<
  INewPaymentMethodSelectorProps,
  INewPaymentMethodSelectorState
> {
  constructor(props: INewPaymentMethodSelectorProps) {
    super(props);
    this.state = {
      securegate: false,
      clearNewCardSelection: false,
      clearExistingCardSelection: false
    };
  }

  onSelectionCleared= () => {
    this.setState({clearNewCardSelection: false})
  }

  render(): JSX.Element {
    const { styles, mobile, tablet, onDelete, paymentMethods, errors } =
      this.props;
    const [classNames, subComponentStyles] =
      getNewPaymentMethodSelectorClassNames(styles!, {
        ...this.props,
        ...this.state,
      });

    return (
      <div className={classNames.root}>
        <Stack horizontal={!mobile && !tablet} tokens={{ childrenGap: 10 }}>
          <StackItem grow={mobile || tablet ? true : 2}>
            <Stack>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <StackItem grow>
                  <IconHeader
                    {...this.props}
                    styles={{
                      text: mobile
                        ? { fontSize: "16px", marginTop: "15px" }
                        : undefined,
                    }}
                    iconName={"PaymentCard"}
                    title={i18n.t("billing:payments:newpayment:existing")}
                  />
                </StackItem>
                <StackItem grow>
                  <div style={{ height: "32px", marginBottom: "10px" }}>
                    {errors.paymentmethod ? (
                      <MessageBar messageBarType={MessageBarType.blocked}>
                        {i18n.t(
                          "billing:payments:newpayment:existing:required"
                        )}
                      </MessageBar>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </StackItem>
              </Stack>
              <GridView
                {...this.props}
                styles={undefined}
                selectionMode={SelectionMode.single}
                embedded={true}
                onDataPaging={() => {}}
                onRenderTile={(
                  target: IPaymentMethod,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  const item =
                    target.id === -1 && paymentMethods
                      ? paymentMethods.filter((x) => x.id === -1)[0]
                      : target;
                  const expiry: string =
                    item === undefined
                      ? ""
                      : `${
                          item.expirationMonth < 10
                            ? "0" + item.expirationMonth.toString()
                            : item.expirationMonth.toString()
                        }/${item.expirationYear}`;
                  return (
                    <PaymentMethodSelectorCard
                      {...this.props}
                      styles={subComponentStyles?.paymentCard}
                      paymentMethod={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      clearSelection={this.state.clearExistingCardSelection}
                      onDelete={onDelete}
                      onSelected={(item: IPaymentMethod) => {
                        this.setState({clearNewCardSelection: true})
                        this.setState({clearExistingCardSelection: false})
                        ensureSelection(selection, item, true);
                        selection.setKeySelected(
                          item.key,
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length === 0,
                          false
                        );
                        callback(selection);
                        this.props.onSelected(item);
                      }}
                    >
                      <Cards
                        {...i18n.CreditCardStrings()}
                        cvc={item.cvc ?? ""}
                        expiry={expiry}
                        focused={"number"}
                        name={item.name ?? ""}
                        number={PaymentsService.formatCreditCard(
                          item.card ?? ""
                        )}
                        preview ={true}
                      />
                      {this.state.securegate && (
                        <div>
                          <div style={{ float: "left" }}>
                            <Text style={{ fontWeight: "bold" }}>Cvc</Text>
                          </div>
                          <div style={{ float: "right" }}>
                            <TextField
                              size={5}
                              value={item.cvc ?? ""}
                              onChange={(
                                _event: React.FormEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                                newValue?: string
                              ) => {
                                item.cvc = newValue;
                                this.forceUpdate();
                              }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      )}
                    </PaymentMethodSelectorCard>
                  );
                }}
                options={GridViewOptionsBuilder.getOptions(
                  GridViewMode.tiles,
                  "createdOn",
                  true,
                  paymentMethods !== undefined
                )}
                items={
                  paymentMethods === undefined
                    ? []
                    : paymentMethods.filter(
                        (x) => x.id !== -1 && isCreditCardExpirationValid(x)
                      )
                }
                columns={[]}
              />
            </Stack>
          </StackItem>
          <StackItem
            grow={mobile || tablet ? true : 1}
            styles={{
              root: {
                backgroundColor: DefaultPalette.neutralLighter,
              },
            }}
          >
            <Stack>
              <IconHeader
                {...this.props}
                styles={{
                  root: {
                    backgroundColor: DefaultPalette.white,
                  },
                  text: mobile
                    ? { fontSize: "16px", marginTop: "15px" }
                    : undefined,
                }}
                iconName={"PaymentCard"}
                title={i18n.t("billing:payments:newpayment:newcard")}
              />
              <GridView
                {...this.props}
                styles={subComponentStyles?.gridView}
                selectionMode={SelectionMode.single}
                embedded={true}
                onDataPaging={() => {}}
                onRenderTile={(
                  target: IPaymentMethod,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  const item =
                    target.id === -1 && paymentMethods
                      ? paymentMethods.filter((x) => x.id === -1)[0]
                      : target;
                  const expiry: string =
                    item === undefined
                      ? ""
                      : `${
                          item.expirationMonth < 10
                            ? "0" + item.expirationMonth.toString()
                            : item.expirationMonth.toString()
                        }/${item.expirationYear}`;
                  return (
                    <PaymentMethodSelectorCard
                      {...this.props}
                      clearSelection = {this.state.clearNewCardSelection}
                      styles={subComponentStyles?.paymentCard}
                      paymentMethod={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onDelete={onDelete}
                      onSelected={(item: IPaymentMethod) => {
                        this.setState({clearNewCardSelection: false})
                        this.setState({clearExistingCardSelection: true})
                        ensureSelection(selection, item, true);
                        selection.setKeySelected(
                          item.key,
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length === 0,
                          false
                        );
                        callback(selection);
                        this.props.onSelected(item);
                      }}
                    >
                      <Cards
                        {...i18n.CreditCardStrings()}
                        cvc={item.cvc ?? ""}
                        expiry={expiry}
                        focused={"number"}
                        name={item.name ?? ""}
                        number={PaymentsService.formatCreditCard(
                          item.card ?? ""
                        )}
                      />
                      {this.state.securegate && (
                        <div>
                          <div style={{ float: "left" }}>
                            <Text style={{ fontWeight: "bold" }}>Cvc</Text>
                          </div>
                          <div style={{ float: "right" }}>
                            <TextField
                              size={5}
                              value={item.cvc ?? ""}
                              onChange={(
                                _event: React.FormEvent<
                                  HTMLInputElement | HTMLTextAreaElement
                                >,
                                newValue?: string
                              ) => {
                                item.cvc = newValue;
                                this.forceUpdate();
                              }}
                            />
                          </div>
                          <div style={{ clear: "both" }}></div>
                        </div>
                      )}
                    </PaymentMethodSelectorCard>
                  );
                }}
                options={GridViewOptionsBuilder.getOptions(
                  GridViewMode.tiles,
                  "createdOn",
                  true,
                  paymentMethods !== undefined
                )}
                items={
                  paymentMethods === undefined
                    ? []
                    : paymentMethods.filter((x) => x.id === -1)
                }
                columns={[]}
              />
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  }
}
