import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentMethodSelectorCardProps } from "./PaymentMethodSelectorCard.types";
import {
  getStyles,
  IPaymentMethodSelectorCardStyleProps,
  IPaymentMethodSelectorCardStyles,
} from "./PaymentMethodSelectorCard.styles";
import { PaymentMethodSelectorCardComponent } from "./PaymentMethodSelectorCard.base";

/**
 * PaymentMethodSelectorCard description
 */
export const PaymentMethodSelectorCard = styled<
  IPaymentMethodSelectorCardProps,
  IPaymentMethodSelectorCardStyleProps,
  IPaymentMethodSelectorCardStyles
>(PaymentMethodSelectorCardComponent, getStyles);
