import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IEnterpriseContactCardSubComponentStyles {}

export interface IEnterpriseContactCardStyles
  extends IAmigoBaseStyles<IEnterpriseContactCardSubComponentStyles> {
  header?: IStyle;
  inner?: IStyle;
}

export interface IEnterpriseContactCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterpriseContactCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterpriseContactCardStyleProps,
    IEnterpriseContactCardStyles
  >,
  props?: IEnterpriseContactCardStyleProps
): [
  IProcessedStyleSet<IEnterpriseContactCardStyles>,
  IEnterpriseContactCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterpriseContactCardStyleProps,
  IEnterpriseContactCardStyles
> = (
  _props: IEnterpriseContactCardStyleProps
): IEnterpriseContactCardStyles => {
  return {
    header: {
      padding: "18px 24px 12px",
    },
    inner: {
      height: "100%",
      padding: "0 24px 20px",
    },
  };
};
