import * as React from "react";
import { getFlightBookingBreadcrumbClassNames } from "./FlightBookingBreadcrumb.styles";
import {
  IFlightBookingBreadcrumbState,
  IFlightBookingBreadcrumbProps,
} from "./FlightBookingBreadcrumb.types";
import {
  Breadcrumb,
  IBreadcrumbItem,
  Text,
  CommandBarButton,
  Icon,
  Stack,
} from "@fluentui/react";
import i18n from "src/services/i18n";

export class FlightBookingBreadcrumbComponent extends React.Component<
  IFlightBookingBreadcrumbProps,
  IFlightBookingBreadcrumbState
> {
  constructor(props: IFlightBookingBreadcrumbProps) {
    super(props);
    this.state = {};
  }

  private onSearch = () => {
    const { getState } = this.props;
    const routeState = this.props.route.location.state as any;
    this.props.route.history.push("/transports/billetterie", {
      ...routeState,
      ...getState(),
    });
  };

  render(): JSX.Element {
    const { styles, getState, isCheckout, isSummary, isConfirmation } =
      this.props;
    const [classNames] = getFlightBookingBreadcrumbClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const routeState = this.props.route.location.state as any;
    return (
      <div className={classNames.root}>
        <Breadcrumb
          styles={{
            item: {
              fontSize: "14px",
              fontWeight: "normal",
            },
            itemLink: {
              fontSize: "14px",
              fontWeight: "normal",
            },
          }}
          onRenderItem={(item?: IBreadcrumbItem) => {
            return item?.onClick !== undefined ? (
              <CommandBarButton
                text={item.text}
                iconProps={{
                  iconName: item.data,
                }}
                styles={{
                  root: {
                    backgroundColor: this.props.theme.palette.neutralLighter,
                  },
                  textContainer: {
                    fontSize: "14px",
                    margin: "8px",
                  },
                }}
                onClick={(ev: React.MouseEvent<HTMLElement, MouseEvent>) => {
                  item?.onClick && item.onClick(ev, item);
                }}
              />
            ) : (
              <Stack horizontal verticalAlign="center">
                {item?.data !== undefined && (
                  <Icon
                    iconName={item.data}
                    styles={{
                      root: { color: this.props.theme.palette.themePrimary },
                    }}
                  />
                )}

                <Text
                  style={{
                    fontSize: "14px",
                    fontWeight:
                      item && (item.isCurrentItem ?? false) ? 600 : "normal",
                    padding: "5px",
                  }}
                >
                  {item?.text}
                </Text>
              </Stack>
            );
          }}
          items={[
            {
              text: i18n.t("transports:flights:booking:search"),
              key: "search",
              onClick: this.onSearch,
              isCurrentItem: false,
              data: "Search",
            },
            {
              text: i18n.t("transports:flights:booking:summary"),
              key: "summary",
              isCurrentItem: isSummary ?? false,
              onClick: isCheckout
                ? () => {
                    this.props.route.history.replace(
                      "/transports/billetterie/sommaire",
                      {
                        ...routeState,
                        ...getState(),
                      }
                    );
                  }
                : undefined,
              data: "Airplane",
            },
            {
              text: i18n.t("transports:flights:booking:checkout"),
              key: "checkout",
              isCurrentItem: isCheckout ?? false,
              data: "IDBadge",
              onClick: isConfirmation
                ? () => {
                    this.props.route.history.replace(
                      "/transports/billetterie/participants",
                      {
                        ...routeState,
                        ...getState(),
                      }
                    );
                  }
                : undefined,
            },
            {
              text: i18n.t("transports:flights:booking:confirmation"),
              key: "confirmation",
              isCurrentItem: isConfirmation ?? false,
            },
          ]}
        />
      </div>
    );
  }
}
