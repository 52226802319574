import { styled } from "@fluentui/react/lib/Utilities";
import { IDepartureRequestFlightSelectorProps } from "./DepartureRequestFlightSelector.types";
import {
  getStyles,
  IDepartureRequestFlightSelectorStyleProps,
  IDepartureRequestFlightSelectorStyles,
} from "./DepartureRequestFlightSelector.styles";
import { DepartureRequestFlightSelectorComponent } from "./DepartureRequestFlightSelector.base";

/**
 * DepartureRequestFlightSelector
 */
export const DepartureRequestFlightSelector = styled<
  IDepartureRequestFlightSelectorProps,
  IDepartureRequestFlightSelectorStyleProps,
  IDepartureRequestFlightSelectorStyles
>(DepartureRequestFlightSelectorComponent, getStyles);
