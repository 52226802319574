import * as React from "react";
import {
  Panel,
  PanelType,
  ChoiceGroup,
  IChoiceGroupOption,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { PanelEditorButtons } from "../../components/PanelEditorButtons";
import { IReportsConfirmationDatesModel } from "src/models/Requests/IReportsConfirmationDatesModel";
import RequestsService from "src/services/Requests/RequestsService";
import { IObjectReference, objectId } from "src/models/IObjectReference";

export interface IRequestReportsPanelProps {
  isOpen: boolean;
  enterprise: IObjectReference;
  requestId: number;
  versionNumber: number;
  onPrint: (
    type: number,
    language: string,
    date: Date | undefined,
    callback: () => void
  ) => void;
  onDismiss: () => void;
}

export interface IRequestReportsPanelState {
  type: number;
  language: string;
  date: string;
  dateModel?: IReportsConfirmationDatesModel;
  processing: boolean;
}

export class RequestReportsPanel extends React.Component<
  IRequestReportsPanelProps,
  IRequestReportsPanelState
> {
  constructor(props: IRequestReportsPanelProps) {
    super(props);

    this.state = {
      type: 1,
      date: "all",
      language: i18n.getLanguage(),
      processing: false,
    };
  }

  /*
  private enterpriseId = (): number => {
    return this.props.enterprise.key === undefined
      ? 0
      : parseInt(this.props.enterprise.key.toString());
  };
*/

  private dateOptions = (): IChoiceGroupOption[] => {
    if (this.state.type === 1) return [];
    const all: IChoiceGroupOption = {
      key: "all",
      text: i18n.t("requests:reports:type:date:all"),
    };
    if (this.state.dateModel !== undefined) {
      if (this.state.type === 2) {
        return [all].concat(
          this.state.dateModel.arrivals.map((x) => {
            return { key: x, text: x };
          })
        );
      } else {
        return [all].concat(
          this.state.dateModel.departures.map((x) => {
            return { key: x, text: x };
          })
        );
      }
    }
    return [all];
  };

  public render() {
    return (
      <Panel
        isOpen={this.props.isOpen}
        headerText={i18n.t("requests:print")}
        onDismiss={this.props.onDismiss}
        type={PanelType.smallFixedFar}
        isFooterAtBottom={true}
        onRenderFooter={() => {
          return (
            <PanelEditorButtons
              acceptLabel={i18n.t("global:print")}
              canAccept={true}
              disabled={this.state.processing}
              onAccept={() => {
                this.setState({
                  processing: true,
                });
                this.props.onPrint(
                  this.state.type,
                  this.state.language,
                  this.state.date === "all"
                    ? undefined
                    : i18n.parseISOLocal(this.state.date),
                  () =>
                    this.setState({
                      processing: false,
                    })
                );
              }}
              onDismiss={this.props.onDismiss}
            />
          );
        }}
      >
        <ChoiceGroup
          disabled={this.state.processing}
          selectedKey={this.state.type.toString()}
          label={i18n.t("requests:reports:types")}
          onChange={(
            _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
            option?: IChoiceGroupOption
          ) => {
            if (option) {
              const type: number = parseInt(option.key);
              if (type !== 1) {
                RequestsService.getDates(
                  objectId(this.props.enterprise),
                  this.props.requestId,
                  this.props.versionNumber
                )
                  .then((x) => {
                    this.setState({
                      dateModel: x,
                      type: parseInt(option.key),
                      language: "fr", // report only available in french for now
                    });
                  })
                  .catch((_x) => {
                    this.setState({
                      type: parseInt(option.key),
                      language: "fr", // report only available in french for now
                    });
                  });
              } else {
                this.setState({
                  type: parseInt(option.key),
                });
              }
            }
          }}
          options={[
            {
              key: "1",
              text: i18n.t("requests:reports:type:report"),
            },
            {
              key: "2",
              text: i18n.t("requests:reports:type:arrival"),
            },
            {
              key: "3",
              text: i18n.t("requests:reports:type:departure"),
            },
          ]}
        />
        {this.state.type !== 1 && (
          <ChoiceGroup
            disabled={this.state.processing}
            selectedKey={this.state.date}
            label={i18n.t(
              this.state.type === 2
                ? "requests:reports:type:arrival:date"
                : "requests:reports:type:departure:date"
            )}
            onChange={(
              _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
              option?: IChoiceGroupOption
            ) => {
              if (option) {
                this.setState({
                  date: option.key,
                });
              }
            }}
            options={this.dateOptions()}
          />
        )}
        {this.state.type === 1 && (
          <ChoiceGroup
            selectedKey={this.state.language}
            label={i18n.t("profile:language")}
            onChange={(
              _ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
              option?: IChoiceGroupOption
            ) => {
              if (option) {
                this.setState({
                  language: option.key,
                });
              }
            }}
            options={[
              {
                key: "en",
                text: i18n.t("language:en"),
              },
              {
                key: "fr",
                text: i18n.t("language:fr"),
              },
            ]}
          />
        )}
      </Panel>
    );
  }
}
