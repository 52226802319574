import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantsControlProps } from "./RequestParticipantsControl.types";
import {
  getStyles,
  IRequestParticipantsControlStyleProps,
  IRequestParticipantsControlStyles,
} from "./RequestParticipantsControl.styles";
import { RequestParticipantsControlComponent } from "./RequestParticipantsControl.base";

/**
 * RequestParticipantsControl description
 */
export const RequestParticipantsControl = styled<
  IRequestParticipantsControlProps,
  IRequestParticipantsControlStyleProps,
  IRequestParticipantsControlStyles
>(RequestParticipantsControlComponent, getStyles);
