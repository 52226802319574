import { ISchemaObject } from "../../utils/validator";
import i18n from "src/services/i18n";
import { IObjectWithKey } from "@fluentui/react";

export interface IEnterpriseContact extends IObjectWithKey {
  id: number;
  key: string;
  contactId: number;
  pending: boolean;
  firstName: string;
  lastName: string;
  name: string;
  title: string;
  email: string;
  phone: string;
  cellphone: string;
  isNewsletterMember: boolean;
  isRequestContact: boolean;
  isBillingContact: boolean;
  isFermeTravelContact: boolean;
}

export const EnterpriseContactSchema: ISchemaObject = {
  name: "user",
  plural: "users",
  label: "User",
  fields: {
    firstName: {
      name: "firstName",
      label: i18n.t("enterprises:contacts:firstname"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("enterprises:contacts:firstname:error"),
      type: "string",
    },
    lastName: {
      name: "lastName",
      label: i18n.t("enterprises:contacts:lastname"),
      required: true,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("enterprises:contacts:lastname:error"),
      type: "string",
    },
    email: {
      name: "email",
      label: i18n.t("enterprises:contacts:email"),
      required: true,
      pattern: "",
      length: [250],
      errorMessage: i18n.t("enterprises:contacts:email:error"),
      type: "email",
    },
    phone: {
      name: "phone",
      label: i18n.t("enterprises:contacts:phone"),
      required: false,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("enterprises:contacts:phone:error"),
      type: "string",
    },
    cellphone: {
      name: "cellphone",
      label: i18n.t("enterprises:contacts:cellphone"),
      required: false,
      pattern: "",
      length: [100],
      errorMessage: i18n.t("enterprises:contacts:cellphone:error"),
      type: "string",
    },
  },
};
