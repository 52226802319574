import { ISchemaObject } from "src/utils/validator";
import i18n from "src/services/i18n";

export interface IParticipantTransfertRequestParticipant {
  id: number;
  key: string;
  workerId: number | undefined;
  name: string;
  code: string;
  date: string;
  recall?: number;
  recallName?: string;
  reason?: string;
  errors: any;
  selected: boolean;
}

export const ParticipantTransfertRequestParticipantSchema: ISchemaObject = {
  name: "participanttransfertrequestparticipant",
  plural: "participanttransfertrequestparticipants",
  label: "ParticipantTransfertRequestParticipant",
  fields: {
    recall: {
      name: "recall",
      label: i18n.t("messages:assisted:participanttransfert:recall"),
      required: true,
      pattern: "",
      errorMessage: i18n.t(
        "messages:assisted:participanttransfert:recall:error"
      ),
      type: "number",
    },
  },
};
