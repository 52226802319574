import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantEndParticipationProps } from "./RequestParticipantEndParticipation.types";
import {
  getStyles,
  IRequestParticipantEndParticipationStyleProps,
  IRequestParticipantEndParticipationStyles,
} from "./RequestParticipantEndParticipation.styles";
import { RequestParticipantEndParticipationComponent } from "./RequestParticipantEndParticipation.base";

/**
 * RequestParticipantEndParticipation description
 */
export const RequestParticipantEndParticipation = styled<
  IRequestParticipantEndParticipationProps,
  IRequestParticipantEndParticipationStyleProps,
  IRequestParticipantEndParticipationStyles
>(RequestParticipantEndParticipationComponent, getStyles);
