import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ICountryIconStyleProps,
  ICountryIconStyles,
} from "src/components/CountryIcon";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import { IGridViewOptions } from "src/components/GridView/GridView.types";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import { IRequestCardStyleProps, IRequestCardStyles } from "../RequestCard";

export interface IRequestsControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<IRequestCardStyleProps, IRequestCardStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  country?: IStyleFunctionOrObject<ICountryIconStyleProps, ICountryIconStyles>;
}

export interface IRequestsControlStyles
  extends IAmigoBaseStyles<IRequestsControlSubComponentStyles> {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
}

export interface IRequestsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  options: IGridViewOptions;
}

export const getRequestsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestsControlStyleProps,
    IRequestsControlStyles
  >,
  props?: IRequestsControlStyleProps
): [
  IProcessedStyleSet<IRequestsControlStyles>,
  IRequestsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestsControlStyleProps,
  IRequestsControlStyles
> = (_props: IRequestsControlStyleProps): IRequestsControlStyles => {
  return {};
};
