/* eslint-disable no-unused-vars */
export enum CommunicationMethod {
  None = 0,
  Email = 1,
  Sms = 2,
}

export interface IProfileCommunicationPreferences {
  alerts?: CommunicationMethod;
  chat?: CommunicationMethod;
}
