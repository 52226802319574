import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightDirectOffersProps } from "./FlightDirectOffers.types";
import {
  getStyles,
  IFlightDirectOffersStyleProps,
  IFlightDirectOffersStyles,
} from "./FlightDirectOffers.styles";
import { FlightDirectOffersComponent } from "./FlightDirectOffers.base";

/**
 * FlightDirectOffers
 */
export const FlightDirectOffers = styled<
  IFlightDirectOffersProps,
  IFlightDirectOffersStyleProps,
  IFlightDirectOffersStyles
>(FlightDirectOffersComponent, getStyles);
