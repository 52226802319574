import * as React from "react";
import { IEntityNote } from "src/models/IEntityNote";
import { MessageBar, MessageBarType, ActivityItem } from "@fluentui/react";
import i18n from "src/services/i18n";

export interface IRequestParticipantNotesProps {
  notes: IEntityNote[];
}

export interface IRequestParticipantNotesState {}

export class RequestParticipantNotes extends React.Component<
  IRequestParticipantNotesProps,
  IRequestParticipantNotesState
> {
  constructor(props: IRequestParticipantNotesProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { notes } = this.props;
    return !notes || notes.length === 0 ? (
      <div style={{ padding: "10px" }}>
        <MessageBar messageBarType={MessageBarType.info}>
          {i18n.t("requests:participants:item:notes:empty")}
        </MessageBar>
      </div>
    ) : (
      <div style={{ padding: "20px" }}>
        {notes.map((x) => (
          <ActivityItem
            timeStamp={x.createdOn}
            commentText={x.name}
            activityPersonas={[
              {
                text: x.createdBy.name,
                secondaryText: x.createdBy.email,
              },
            ]}
          />
        ))}
      </div>
    );
  }
}
