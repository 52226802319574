import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestPeriodsControlProps } from "./RequestPeriodsControl.types";
import {
  getStyles,
  IRequestPeriodsControlStyleProps,
  IRequestPeriodsControlStyles,
} from "./RequestPeriodsControl.styles";
import { RequestPeriodsControlComponent } from "./RequestPeriodsControl.base";

/**
 * RequestPeriodsControl description
 */
export const RequestPeriodsControl = styled<
  IRequestPeriodsControlProps,
  IRequestPeriodsControlStyleProps,
  IRequestPeriodsControlStyles
>(RequestPeriodsControlComponent, getStyles);
