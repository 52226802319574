import { styled } from "@fluentui/react/lib/Utilities";
import { IDepartureAssistedControlProps } from "./DepartureAssistedControl.types";
import {
  getStyles,
  IDepartureAssistedControlStyleProps,
  IDepartureAssistedControlStyles,
} from "./DepartureAssistedControl.styles";
import { DepartureAssistedControlComponent } from "./DepartureAssistedControl.base";

/**
 * DepartureAssistedControl description
 */
export const DepartureAssistedControl = styled<
  IDepartureAssistedControlProps,
  IDepartureAssistedControlStyleProps,
  IDepartureAssistedControlStyles
>(DepartureAssistedControlComponent, getStyles);
