import {
  MessageBar,
  MessageBarType,
  Selection,
  SelectionMode,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
} from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { UserCallout } from "src/controls/UserCallout";
import { IRequestReference } from "src/models/Requests/IRequestReference";
import { RequestCard } from "src/pages/Requests/RequestCard";
import i18n from "src/services/i18n";
import { getFlightBookingRequestsGridViewClassNames } from "./FlightBookingRequestsGridView.styles";
import {
  IFlightBookingRequestsGridViewProps,
  IFlightBookingRequestsGridViewState,
} from "./FlightBookingRequestsGridView.types";

export class FlightBookingRequestsGridViewComponent extends React.Component<
  IFlightBookingRequestsGridViewProps,
  IFlightBookingRequestsGridViewState
> {
  render(): JSX.Element {
    const { styles, errors, mobile, arrival, requests } = this.props;
    const [classNames] = getFlightBookingRequestsGridViewClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack className={classNames.root} tokens={{ childrenGap: 10 }}>
        <Text style={{ fontSize: "18px", fontWeight: "bold" }}>
          {i18n.t("transports:flights:requests")}
        </Text>
        <Text style={{ fontWeight: "bold" }}>
          {i18n.t(
            arrival
              ? "transports:flights:request:instruction:arrival"
              : "transports:flights:request:instruction:departure"
          )}
        </Text>
        {/*
        <CommandBar
          styles={{
            root: {
              backgroundColor: this.props.theme.palette.neutralLighter,
            },
          }}
          items={[]}
          farItems={[
            {
              key: "next",
              name: i18n.t("global:next"),
              iconProps: { iconName: "Next" },
              onClick: () => {
                const selected = this.props.requests.filter((x) => x.selected);
                if (selected.length === 0) {
                  // error
                } else {
                  this.props.onRequestSelected(selected[0]);
                }
              },
            },
          ]}
        />
        */}
        {errors.requests && (
          <MessageBar messageBarType={MessageBarType.error}>
            {i18n.t("messages:assisted:newdeparture:requests:required")}
          </MessageBar>
        )}
        <GridView
          {...this.props}
          styles={
            mobile
              ? {
                  root: {
                    marginLeft: "-12px",
                    marginRight: "-12px",
                  },
                }
              : undefined
          }
          embedded={true}
          selectionPreservedOnEmptyClick={true}
          selectionMode={SelectionMode.none}
          onSelectionChanged={(
            items: IRequestReference[],
            selection?: Selection
          ) => {
            items.forEach((x) => (x.selected = true));
            const paths = (window.event as any).path;
            if (
              paths.filter((y: any) => {
                const res =
                  (y.className ?? "").indexOf("ms-DetailsRow-fields") !== -1 ||
                  (y.className ?? "").indexOf("ms-Dropdown-") !== -1 ||
                  (y.className ?? "").indexOf("ms-TextField-") !== -1;
                /*
              //NOTE:  Useful when introducing new row controls because it messes selection
              console.log(
                `${y.className}   ::::    ${res ? "yes" : "no"}`
              ) */

                return res;
              }).length > 0
            ) {
              requests.forEach((x) => {
                if (x.selected) {
                  selection?.setKeySelected(x.id.toString(), true, false);
                }
              });
            } else {
              requests.forEach((x) => {
                x.selected = items.filter((y) => y.id === x.id).length > 0;
              });
            }
          }}
          columns={[
            {
              key: "name",
              name: "requests:requestnumber",
              minWidth: 170,
            },
            {
              key: "agentLabel",
              name: "requests:agent",
              minWidth: 150,
              onRenderCell: (item: IRequestReference) => {
                return <UserCallout {...this.props} user={item.agent} />;
              },
            },
            {
              key: "country",
              name: "requests:country",
              minWidth: 120,
              onRenderCell: (item: IRequestReference) => {
                return (
                  <Stack horizontal tokens={{ childrenGap: 3 }}>
                    <Text>{item.country}</Text>
                    <CountryIcon
                      {...this.props}
                      styles={undefined}
                      country={item.country}
                    />
                  </Stack>
                );
              },
            },
            {
              key: "participantCount",
              name: "requests:participantcount",
              minWidth: 170,
            },
          ]}
          onRenderTile={(
            item: IRequestReference,
            columnWidth: number,
            selection: Selection,
            callback: (sel: Selection) => void
          ): JSX.Element => {
            return (
              <RequestCard
                {...this.props}
                selectorStyle={{
                  display: "none",
                }}
                styles={{
                  subComponentStyles: {
                    gridViewCard: {
                      root: {
                        width: "30%",
                        selectors: {
                          ":focus": {
                            borderWidth: 0,
                          },
                        },
                      },
                    },
                  },
                }}
                item={item}
                showEvent={false}
                selection={selection}
                columnWidth={columnWidth}
                onClick={(item) => this.props.onRequestSelected(item)}
                onSelected={(item: IRequestReference) => {
                  const isSelected: boolean =
                    selection.getSelection().filter((x) => x.key === item.key)
                      .length > 0;
                  ensureSelection(selection, item, true);
                  selection.setKeySelected(
                    item.key.toString(),
                    !isSelected,
                    false
                  );
                  callback(selection);
                }}
              />
            );
          }}
          onDataPaging={() => {}}
          options={GridViewOptionsBuilder.getOptions(
            GridViewMode.tiles,
            "name",
            false,
            true
          )}
          emptyMessage={i18n.t(
            requests.length === 0
              ? "messages:assisted:requests:norequests"
              : "messages:assisted:requests:emptymessage"
          )}
          items={requests}
          isItemSelected={(item: IRequestReference) => {
            return item.selected;
          }}
        />
        <div style={{ height: "20px" }}></div>
      </Stack>
    );
  }
}
