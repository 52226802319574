import { styled } from "@fluentui/react/lib/Utilities";
import { IBillingElementCardProps } from "./BillingElementCard.types";
import {
  getStyles,
  IBillingElementCardStyleProps,
  IBillingElementCardStyles,
} from "./BillingElementCard.styles";
import { BillingElementCardComponent } from "./BillingElementCard.base";

/**
 * BillingElementCard description
 */
export const BillingElementCard = styled<
  IBillingElementCardProps,
  IBillingElementCardStyleProps,
  IBillingElementCardStyles
>(BillingElementCardComponent, getStyles);
