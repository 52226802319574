import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";

export interface IMessageNotificationSubComponentStyles {}

export interface IMessageNotificationStyles
  extends IComponentBaseStyles<IMessageNotificationSubComponentStyles> {
  tooltip?: IStyle;
  coin?: IStyle;
  coachmark?: IStyle;
}

export interface IMessageNotificationStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  hasNotificationChanged: boolean;
}

export const getMessageNotificationClassNames = (
  styles?: IStyleFunctionOrObject<
    IMessageNotificationStyleProps,
    IMessageNotificationStyles
  >,
  props?: IMessageNotificationStyleProps
): [
  IProcessedStyleSet<IMessageNotificationStyles>,
  IMessageNotificationSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IMessageNotificationStyleProps,
  IMessageNotificationStyles
> = (props: IMessageNotificationStyleProps): IMessageNotificationStyles => {
  return {
    root: [
      "ms-MessageNotification",
      {
        selectors: {
          "@media query(max-width: 767px)": {
            marginBottom: props.hasNotificationChanged ? "70px" : undefined,
          },
        },
      },
    ],
    coin: [
      "ms-MessageNotificationCoin",
      {
        margin: "4px 10px 0 20px",
        cursor: "pointer",
        position: "relative",
        selectors: {
          "@media query(max-width: 767px)": {
            margin: "7px 10px 0 20px",
          },
        },
      },
    ],
  };
};
