import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { getMaintenanceBarClassNames } from "./MaintenanceBar.styles";
import {
  IMaintenanceBarProps,
  IMaintenanceBarState,
} from "./MaintenanceBar.types";

export class MaintenanceBarComponent extends React.Component<
  IMaintenanceBarProps,
  IMaintenanceBarState
> {
  constructor(props: IMaintenanceBarProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles, isOnMaintenance, onDismiss, maintenanceMessage } =
      this.props;
    const [classNames] = getMaintenanceBarClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    return (
      <div className={classNames.root}>
        {isOnMaintenance && (
          <MessageBar
            className={classNames.messageBar}
            messageBarType={MessageBarType.warning}
            onDismiss={onDismiss}
          >
            {maintenanceMessage}
          </MessageBar>
        )}
      </div>
    );
  }
}
