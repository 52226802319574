import * as React from "react";
import { getTransportRequestsLegendClassNames } from "./TransportRequestsLegend.styles";
import {
  ITransportRequestsLegendState,
  ITransportRequestsLegendProps,
} from "./TransportRequestsLegend.types";
import {
  MessageBar,
  MessageBarType,
  Text,
  Stack,
  StackItem,
} from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import {
  TransportRequestTypes,
  ITransportRequestType,
} from "src/models/Transports/TransportRequestTypes";
import i18n from "src/services/i18n";

export class TransportRequestsLegendComponent extends React.Component<
  ITransportRequestsLegendProps,
  ITransportRequestsLegendState
> {
  private label = (item: ITransportRequestType): JSX.Element => {
    return (
      <LabelField
        {...this.props}
        styles={undefined}
        iconProps={{
          iconName: item.iconName,
          styles: {
            root: {
              color: item.color,
            },
          },
        }}
        label={i18n.t(item.name)}
        content={i18n.t(item.description)}
      />
    );
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getTransportRequestsLegendClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <MessageBar messageBarType={MessageBarType.info}>
          <Text>{i18n.t("transports:requests:legend")}</Text>
          <div style={{ height: mobile ? "5px" : "20px" }}></div>
          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <StackItem grow>
              <Stack tokens={{ childrenGap: 10 }}>
                {this.label(TransportRequestTypes.regular)}
                {this.label(TransportRequestTypes.charter)}
                {this.label(TransportRequestTypes.confirmed)}
                {this.label(TransportRequestTypes.leaving)}
                {this.label(TransportRequestTypes.requested)}
              </Stack>
            </StackItem>
            <StackItem grow>
              <Stack tokens={{ childrenGap: 10 }}>
                {this.label(TransportRequestTypes.currenttransfert)}
                {this.label(TransportRequestTypes.othercurrent)}
                {this.label(TransportRequestTypes.none)}
              </Stack>
            </StackItem>
          </Stack>
          <div style={{ height: mobile ? "5px" : "20px" }}></div>
        </MessageBar>
      </div>
    );
  }
}
