import { DefaultPalette, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { ParticipantAddressSummary } from "../ParticipantAddressSummary";
import { getParticipantPersonalInfoClassNames } from "./ParticipantPersonalInfo.styles";
import {
  IParticipantPersonalInfoProps,
  IParticipantPersonalInfoState,
} from "./ParticipantPersonalInfo.types";

export class ParticipantPersonalInfoComponent extends React.Component<
  IParticipantPersonalInfoProps,
  IParticipantPersonalInfoState
> {
  constructor(props: IParticipantPersonalInfoProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, participant, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantPersonalInfoClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    return (
      <div className={classNames.root}>
        <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl4 ms-xxl4">
          <div
            style={{
              position: "relative",
              backgroundColor: DefaultPalette.white,
              margin: tablet ? "0 0 10px 0" : "0 10px 10px 10px",
            }}
            className="ms-depth-16"
          >
            <IconHeader
              {...this.props}
              styles={subComponentStyles?.iconHeader}
              iconName={"UserOptional"}
              title={i18n.t("participants:info")}
            />
            {participant && (
              <>
                <div style={{ padding: "10px 20px" }}>
                  <Stack horizontal={!mobile} tokens={{ childrenGap: 10 }}>
                    <StackItem>
                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        iconProps={{
                          iconName: "NumberSymbol",
                        }}
                        content={participant.code}
                        label={i18n.t("participants:code")}
                      />

                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        iconProps={{
                          iconName: "EntitlementPolicy",
                        }}
                        content={participant.lastName.toUpperCase()}
                        label={i18n.t("participants:name:lastname")}
                      />

                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        iconProps={{
                          iconName: "EventDate",
                        }}
                        content={participant.age}
                        label={i18n.t("participants:age")}
                      />
                    </StackItem>
                    <StackItem>
                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        label={i18n.t("requests:participants:item:gender")}
                        content={
                          participant.gender && participant.gender !== ""
                            ? i18n.t(
                                `requests:participants:item:gender:${participant.gender.toLowerCase()}`
                              )
                            : ""
                        }
                      />

                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        iconProps={{
                          iconName: "EntitlementPolicy",
                        }}
                        content={participant.firstName}
                        label={i18n.t("participants:name:firstname")}
                      />

                      <LabelField
                        {...this.props}
                        styles={subComponentStyles?.label}
                        iconProps={{
                          iconName: "BirthdayCake",
                        }}
                        content={participant.birthDate}
                        label={i18n.t("participants:birthdate")}
                      />
                    </StackItem>
                  </Stack>
                </div>
                <ParticipantAddressSummary
                  {...this.props}
                  styles={undefined}
                  participant={participant}
                />
              </>
            )}
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
      </div>
    );
  }
}
