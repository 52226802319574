import {
  DefaultButton,
  DefaultPalette,
  Icon,
  MessageBar,
  MessageBarType,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
  PrimaryButton,
  Spinner,
  Stack,
  Text,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import DateText from "src/components/DateText";
import { IconHeader } from "src/components/IconHeader";
import { PasswordStrengthIndicator } from "src/components/PasswordStrengthIndicator";
import { ChangePasswordSchema } from "src/models/IChangePasswordModel";
import { ISessionProfile } from "src/models/ISessionProfile";
import { PasswordCheckStrength } from "src/models/PasswordCheckStrength";
import AuthService from "src/services/AuthService";
import BrowserService from "src/services/BrowserService";
import i18n from "src/services/i18n";
import { PasswordCheckService } from "src/services/PasswordCheckService";
import { validate } from "src/utils/validator";
import { ProfileInfo } from "../ProfileInfo";
import { ProfileCommunicationPreferences } from "../ProfileCommunicationPreferences";
import { getProfilePageClassNames } from "./ProfilePage.styles";
import { IProfilePageProps, IProfilePageState } from "./ProfilePage.types";
import { MediaQueries } from "src/components/MediaQueries";

const themeIndex: number = 0; // Math.floor(Math.random() * 4);

export class ProfilePageComponent extends React.Component<
  IProfilePageProps,
  IProfilePageState
> {
  constructor(props: IProfilePageProps) {
    super(props);
    this.state = {
      tab: "0",
      message: false,
      success: false,
      progress: false,
      model: { currentPassword: "", newPassword: "", confirmPassword: "" },
      errors: {},
    };
  }

  render(): JSX.Element {
    const { styles, tablet, mobile } = this.props;
    const [classNames, subComponentStyles] = getProfilePageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    const enterpriseSize: PersonaSize = BrowserService.isMobile()
      ? PersonaSize.size56
      : PersonaSize.size100;

    const themeColor = [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
    ];
    const themeImageSize = [
      "cover",
      tablet || mobile ? "cover" : "100% 170%",
      "100% 115%",
      mobile ? "cover" : "cover",
    ];
    const themeOpacity = ["1.0", "0.7", "0.5", "0.5"];

    const session: ISessionProfile | null = AuthService.getUserInfo();

    if (session === null) return <div></div>;

    return (
      <div className={classNames.root}>
        <div
          style={{
            minHeight: "20vh",
            position: "relative",
            width: "100%",
            [MediaQueries.mobile]: {
              minHeight: "160px",
            },
          }}
        >
          <div
            className="EnterpriseBackground"
            style={{
              opacity: themeOpacity[themeIndex],
              backgroundImage:
                "url('/img/banner_" + (themeIndex + 1).toString() + ".jpg')",
              backgroundSize: themeImageSize[themeIndex],
              backgroundRepeat: "no-repeat",
              height: "365px",
              minHeight: "365px",
              [MediaQueries.mobile]: {
                minHeight: "160px",
              },
            }}
          ></div>
          <div
            className="WhitePersona"
            style={{
              position: "absolute",
              padding: "10px 10px",
              width: "95%",
              left: 0,
              bottom: 0,
            }}
          >
            <Persona
              initialsColor={"rgb(122, 117, 116)"}
              text={session.firstName + " " + session.lastName}
              styles={{
                primaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
                secondaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
              }}
              color={themeColor[themeIndex]}
              secondaryText={session.email}
              size={enterpriseSize}
            />
          </div>
          <div
            data-note="top-right-section"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "4px",
            }}
          ></div>
          <div></div>
        </div>
        <div className={classNames.content}>
          <Pivot
            styles={subComponentStyles?.pivot}
            style={{ backgroundColor: DefaultPalette.neutralLighter }}
            selectedKey={this.state.tab.toString()}
          >
            <PivotItem
              headerText={i18n.t("menu:profile")}
              itemKey={"0"}
              key={"0"}
            >
              <Stack
                grow
                verticalFill
                styles={{
                  root: {
                    backgroundColor: DefaultPalette.neutralLighter,
                  },
                }}
              >
                <div
                  className="ms-Grid"
                  style={{ paddingTop: "10px" }}
                  dir="ltr"
                >
                  <div className={classNames.msGridRow}>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl4">
                      <Stack tokens={{ childrenGap: 10 }}>
                        <div
                          style={{
                            backgroundColor: DefaultPalette.white,
                            margin:
                              tablet || mobile
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                          }}
                          className="ms-depth-16"
                        >
                          <IconHeader
                            {...this.props}
                            styles={undefined}
                            iconName="UserOptional"
                            title={i18n.t("profile:info")}
                          />

                          <ProfileInfo {...this.props} styles={undefined} />
                        </div>
                      </Stack>
                      <Stack tokens={{ childrenGap: 10 }}>
                        <div
                          style={{
                            backgroundColor: DefaultPalette.white,
                            margin:
                              tablet || mobile
                                ? "0 0 10px 0"
                                : "0 10px 10px 10px",
                          }}
                          className="ms-depth-16"
                        >
                          <IconHeader
                            {...this.props}
                            styles={undefined}
                            iconName="AlertSettings"
                            title={i18n.t(
                              "profile:communications:preferences:title"
                            )}
                          />

                          <ProfileCommunicationPreferences
                            {...this.props}
                            styles={undefined}
                          />
                        </div>
                      </Stack>
                    </div>
                    <div className="ms-Grid-col ms-sm12 ms-md12 ms-lg6 ms-xl6 ms-xxl8">
                      <div
                        style={{
                          position: "relative",
                          backgroundColor: DefaultPalette.white,
                          margin:
                            tablet || mobile ? "0 0 10px 0" : "0 10px 0px 10px",
                        }}
                        className="ms-depth-16"
                      >
                        {this.state.success ? (
                          <div>
                            <Stack verticalFill={true}>
                              <div>
                                <div
                                  style={{
                                    float: "left",
                                    paddingLeft: "15px",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <h2>
                                    <Icon
                                      style={{ fontSize: "22px" }}
                                      iconName="SignIn"
                                    />
                                  </h2>
                                </div>
                                <div style={{ float: "left" }}>
                                  <h2>{i18n.t("changepwd:title")}</h2>
                                </div>
                                <div className="clear-both"></div>
                              </div>
                              <div
                                style={{
                                  padding: "20px 40px",
                                  marginTop: "40px",
                                }}
                              >
                                <MessageBar
                                  messageBarType={MessageBarType.success}
                                >
                                  {i18n.t("changepwd:success")}
                                </MessageBar>
                                <DefaultButton
                                  style={{
                                    width: "90px",
                                    marginTop: "30px",
                                  }}
                                  data-automation-id="nvx:profile:command:cancel"
                                  onClick={() => {
                                    this.setState({
                                      success: false,
                                      tab: "0",
                                      message: false,
                                      progress: false,
                                      model: {
                                        currentPassword: "",
                                        newPassword: "",
                                        confirmPassword: "",
                                      },
                                      errors: {},
                                    });
                                  }}
                                  text={i18n.t("changepwd:back")}
                                />
                              </div>
                            </Stack>
                          </div>
                        ) : (
                          <div>
                            <div>
                              <div
                                style={{
                                  float: "left",
                                  paddingLeft: "15px",
                                  paddingRight: "10px",
                                }}
                              >
                                <h2>
                                  <Icon
                                    style={{ fontSize: "22px" }}
                                    iconName="SignIn"
                                  />
                                </h2>
                              </div>
                              <div style={{ float: "left" }}>
                                <h2>{i18n.t("changepwd:title")}</h2>
                              </div>
                              <div className="clear-both"></div>
                            </div>

                            <div style={{ padding: "10px 20px" }}>
                              <div style={{ marginBottom: "6px" }}>
                                <div
                                  style={{
                                    float: "left",
                                    width: "32px",
                                    paddingRight: "5px",
                                  }}
                                >
                                  <Icon iconName="DateTime2" />
                                </div>
                                <div
                                  style={{
                                    float: "left",
                                    paddingRight: "10px",
                                  }}
                                >
                                  <Text>{i18n.t("changepwd:lastreset")}</Text>
                                </div>
                                <div style={{ float: "left" }}>
                                  {session.lastReset !== undefined &&
                                  session.lastReset != null ? (
                                    <DateText
                                      variant="medium"
                                      date={session.lastReset}
                                    />
                                  ) : (
                                    i18n.t("global:never")
                                  )}
                                </div>
                                <div className="clear-both"></div>
                              </div>
                              <div style={{ marginBottom: "5px" }}>
                                <Text>{i18n.t("changepwd:instructions")}</Text>
                              </div>
                              {this.state.progress ? (
                                <Stack
                                  verticalFill={true}
                                  verticalAlign={"center"}
                                  horizontalAlign={"center"}
                                  style={{ height: "213px" }}
                                >
                                  <Spinner
                                    label={i18n.t("global:pleasewait")}
                                    ariaLive="assertive"
                                    labelPosition="right"
                                  />
                                </Stack>
                              ) : (
                                <div>
                                  {!this.state.message ? (
                                    <div style={{ height: "32px" }}></div>
                                  ) : (
                                    <MessageBar
                                      messageBarType={MessageBarType.error}
                                    >
                                      {i18n.t("changepwd:error")}
                                    </MessageBar>
                                  )}
                                  <TextField
                                    id="currentpasword"
                                    name="currentpassword"
                                    value={this.state.model.currentPassword}
                                    onChange={(
                                      _event: React.FormEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >,
                                      newValue?: string
                                    ) => {
                                      const model = this.state.model;
                                      model.currentPassword = newValue ?? "";
                                      this.setState({
                                        model: model,
                                      });
                                    }}
                                    label={i18n.t("changepwd:currentpassword")}
                                    type="password"
                                    required
                                    styles={{
                                      root: {
                                        maxWidth: "300px",
                                      },
                                    }}
                                    errorMessage={
                                      this.state.errors.currentPassword
                                    }
                                    iconProps={{
                                      iconName: "PasswordField",
                                    }}
                                  />
                                  <div style={{ height: "20px" }}></div>
                                  <TextField
                                    id="newpassword"
                                    name="newpassword"
                                    value={this.state.model.newPassword}
                                    onChange={(
                                      _event: React.FormEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >,
                                      newValue?: string
                                    ) => {
                                      const model = this.state.model;
                                      model.newPassword = newValue ?? "";
                                      this.setState({
                                        model: model,
                                      });
                                    }}
                                    label={i18n.t("changepwd:newpassword")}
                                    type="password"
                                    required
                                    styles={{
                                      root: {
                                        maxWidth: "300px",
                                      },
                                    }}
                                    errorMessage={this.state.errors.newPassword}
                                    iconProps={{
                                      iconName: "PasswordField",
                                    }}
                                  />
                                  <div style={{ maxWidth: "300px" }}>
                                    <PasswordStrengthIndicator
                                      {...this.props}
                                      styles={undefined}
                                      password={this.state.model.newPassword}
                                    />
                                  </div>
                                  <TextField
                                    id="confirmpassword"
                                    name="confirmpassword"
                                    value={this.state.model.confirmPassword}
                                    onChange={(
                                      _event: React.FormEvent<
                                        HTMLInputElement | HTMLTextAreaElement
                                      >,
                                      newValue?: string
                                    ) => {
                                      const model = this.state.model;
                                      model.confirmPassword = newValue ?? "";
                                      this.setState({
                                        model: model,
                                      });
                                    }}
                                    label={i18n.t("changepwd:confirmpassword")}
                                    type="password"
                                    required
                                    styles={{
                                      root: {
                                        maxWidth: "300px",
                                      },
                                    }}
                                    errorMessage={
                                      this.state.errors.confirmPassword
                                    }
                                    iconProps={{
                                      iconName: "PasswordField",
                                    }}
                                  />
                                </div>
                              )}
                              <div style={{ height: "20px" }}></div>
                              <PrimaryButton
                                data-automation-id="nvx:profile:command:changepassword"
                                disabled={this.state.progress}
                                text={i18n.t("changepwd:submitcommand")}
                                onClick={() => {
                                  const errors: any = validate(
                                    ChangePasswordSchema,
                                    _this.state.model
                                  );
                                  if (Object.keys(errors).length === 0) {
                                    // ensure password strength respected
                                    const strength: PasswordCheckStrength =
                                      PasswordCheckService.checkPasswordStrength(
                                        this.state.model.newPassword
                                      );
                                    if (strength < PasswordCheckStrength.Weak) {
                                      this.setState({
                                        errors: {
                                          ...errors,
                                          newPassword: i18n.t(
                                            "changepwd:newpassword:strength"
                                          ),
                                        },
                                        message: false,
                                        success: false,
                                        progress: false,
                                      });
                                    } else {
                                      AuthService.changePassword(
                                        _this.state.model
                                      )
                                        .then((_x) => {
                                          // show success
                                          _this.setState({
                                            progress: false,
                                            success: true,
                                            model: {
                                              currentPassword: "",
                                              newPassword: "",
                                              confirmPassword: "",
                                            },
                                          });
                                        })
                                        .catch((_x) => {
                                          // show error
                                          _this.setState({
                                            progress: false,
                                            success: false,
                                            message: true,
                                            model: {
                                              currentPassword: "",
                                              newPassword: "",
                                              confirmPassword: "",
                                            },
                                          });
                                        });
                                      _this.setState({
                                        errors: {},
                                        message: false,
                                        success: false,
                                        progress: true,
                                      });
                                    }
                                  } else {
                                    _this.setState({
                                      errors: errors,
                                      progress: false,
                                      success: false,
                                    });
                                  }
                                }}
                              />
                              <div style={{ height: "10px" }}></div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </Stack>
            </PivotItem>
          </Pivot>
        </div>
      </div>
    );
  }
}
