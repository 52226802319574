import {
  FocusZone,
  GroupedList,
  Icon,
  IGroup,
  Persona,
  PersonaSize,
  Selection,
  SelectionMode,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { MediaQueries } from "src/components/MediaQueries";
import { IDocumentCategory } from "src/models/Documents/IDocumentCategory";
import DocumentCategoryService from "src/services/Documents/DocumentCategoryService";
import i18n from "src/services/i18n";
import { getDocumentCategoriesClassNames } from "./DocumentCategories.styles";
import {
  IDocumentCategoriesProps,
  IDocumentCategoriesState,
} from "./DocumentCategories.types";

export class DocumentCategoriesComponent extends React.Component<
  IDocumentCategoriesProps,
  IDocumentCategoriesState
> {
  constructor(props: IDocumentCategoriesProps) {
    super(props);
    const selection = new Selection();
    selection.setItems([]);
    this.state = { documents: [], selection: selection };

    DocumentCategoryService.getDocumentCategories().then((x) => {
      this.setState({
        documents: x,
      });
    });
  }

  private toGroups = (items: IDocumentCategory[]): IGroup[] => {
    if (items.length === 0) return [];

    const groups = items.reduce((groupItems, { type, key }) => {
      const t = i18n.t(`documents:${type}`);
      if (!groupItems[t]) groupItems[t] = [];
      groupItems[t].push(key);
      return groupItems;
    }, {});

    const results: IGroup[] = [];
    let i = 0;
    let len = 0;
    for (const e in groups) {
      const count = groups[e].length;
      results.push({
        key: "group" + i++,
        name: e,
        startIndex: len,
        level: 0,
        count: count,
      });
      len += count;
    }
    return results;
  };

  private _onRenderCell = (
    item: IDocumentCategory,
    _index: number | undefined
  ): JSX.Element => {
    return (
      <Card
        key={`category:${item.key}`}
        onClick={() => {
          this.props.onSelected(item);
        }}
        styles={{
          root: {
            cursor: "pointer",
            padding: "8px",
            backgroundColor: this.props.theme.palette.white,
            margin: "10px",
            float: "left",
            width: this.props.mobile ? "100%" : "420px",
            maxWidth: this.props.mobile ? "100%" : "420px",
          },
        }}
      >
        <Card.Item>
          <Persona
            key={item.key}
            text={item.name}
            tertiaryText={`${i18n.t("documents:lastmodified")} ${
              item.modifiedOn
            }`}
            secondaryText={`${item.count} documents`}
            size={PersonaSize.size72}
            initialsColor={item.color}
            onRenderInitials={() => {
              return <Icon iconName={item.iconName} />;
            }}
          />
        </Card.Item>
      </Card>
    );
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getDocumentCategoriesClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    let i = 0;
    return (
      <div className={classNames.root}>
        <FocusZone>
          <GroupedList
            items={this.state.documents}
            groupProps={{
              headerProps: {
                styles: {
                  root: {
                    clear: "both",
                  },
                  title: {
                    fontSize: "28px",
                    fontWeight: 600,
                    [MediaQueries.mobile]: {
                      fontSize: "18px",
                    },
                  },
                  headerCount: {
                    display: "inline-block",
                  },
                },
              },
            }}
            listProps={{
              getItemCountForPage: () => {
                return 100;
              },
              style: { clear: "both" },
              className: "ms-GroupedList-Tiles",
              onRenderCell: this._onRenderCell,
            }}
            onRenderCell={() => (
              <div key={`nvx-group-grp-${i++}`} style={{ clear: "both" }}></div>
            )}
            selection={this.state.selection}
            selectionMode={SelectionMode.none}
            groups={this.toGroups(this.state.documents)}
          />
        </FocusZone>
      </div>
    );
  }
}
