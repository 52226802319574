import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import { DefaultPalette } from "@fluentui/react";
import BrowserService from "src/services/BrowserService";
import {
  IEnterpriseDocumentsControlStyleProps,
  IEnterpriseDocumentsControlStyles,
} from "../EnterpriseDocumentsControl";
import {
  IEnterpriseContactsControlStyleProps,
  IEnterpriseContactsControlStyles,
} from "../EnterpriseContactsControl";
import {
  IPaymentMethodsControlStyleProps,
  IPaymentMethodsControlStyles,
} from "../PaymentMethodsControl";
import {
  IEnterpriseHeaderStyleProps,
  IEnterpriseHeaderStyles,
} from "../EnterpriseHeader";
import {
  IEnterpriseAddressStyleProps,
  IEnterpriseAddressStyles,
} from "../EnterpriseAddress";
import {
  IEnterpriseContactInfoStyleProps,
  IEnterpriseContactInfoStyles,
} from "../EnterpriseContactInfo";
import {
  IEnterpriseAlertLevelStyleProps,
  IEnterpriseAlertLevelStyles,
} from "../EnterpriseAlertLevel";
import {
  IDocumentSummaryControlStyleProps,
  IDocumentSummaryControlStyles,
} from "../DocumentSummaryControl";
import { IPivotStyles } from "@fluentui/react";

class EnterprisesPageStylesImpl {
  public themeColor = (index: number): string => {
    return [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
    ][index];
  };

  public themeImageSize = (index: number, mobile: boolean): string => {
    return [
      "cover",
      BrowserService.isTabletOrMobile() ? "cover" : "100% 170%",
      "100% 115%",
      mobile ? "cover" : "cover",
    ][index];
  };

  public themeOpacity = (index: number) => {
    return ["1.0", "0.7", "0.5", "0.5"][index];
  };
}

export const EnterprisesPageStyles = new EnterprisesPageStylesImpl();

export interface IEnterprisesPageSubComponentStyles {
  pivot: IPivotStyles;
  header?: IStyleFunctionOrObject<
    IEnterpriseHeaderStyleProps,
    IEnterpriseHeaderStyles
  >;
  info?: IStyleFunctionOrObject<
    IEnterpriseContactInfoStyleProps,
    IEnterpriseContactInfoStyles
  >;
  address?: IStyleFunctionOrObject<
    IEnterpriseAddressStyleProps,
    IEnterpriseAddressStyles
  >;
  alertLevels?: IStyleFunctionOrObject<
    IEnterpriseAlertLevelStyleProps,
    IEnterpriseAlertLevelStyles
  >;
  documentsSummary?: IStyleFunctionOrObject<
    IDocumentSummaryControlStyleProps,
    IDocumentSummaryControlStyles
  >;
  documents?: IStyleFunctionOrObject<
    IEnterpriseDocumentsControlStyleProps,
    IEnterpriseDocumentsControlStyles
  >;
  contacts?: IStyleFunctionOrObject<
    IEnterpriseContactsControlStyleProps,
    IEnterpriseContactsControlStyles
  >;
  paymentMethods?: IStyleFunctionOrObject<
    IPaymentMethodsControlStyleProps,
    IPaymentMethodsControlStyles
  >;
}

export interface IEnterprisesPageStyles
  extends IAmigoBaseStyles<IEnterprisesPageSubComponentStyles> {
  msGridRow?: IStyle;
}

export interface IEnterprisesPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getEnterprisesPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IEnterprisesPageStyleProps,
    IEnterprisesPageStyles
  >,
  props?: IEnterprisesPageStyleProps
): [
  IProcessedStyleSet<IEnterprisesPageStyles>,
  IEnterprisesPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEnterprisesPageStyleProps,
  IEnterprisesPageStyles
> = (props: IEnterprisesPageStyleProps): IEnterprisesPageStyles => {
  return {
    root: ["amigo-EnterprisePage", props.tokens.defaultPageRootStyle],
    msGridRow: props.tokens.msGridRow,
    subComponentStyles: {
      pivot: props.tokens.defaultPivotStyles,
    },
  };
};
