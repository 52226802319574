import { styled } from "@fluentui/react/lib/Utilities";
import { IError500PageProps } from "./Error500Page.types";
import {
  getStyles,
  IError500PageStyleProps,
  IError500PageStyles,
} from "./Error500Page.styles";
import { Error500PageComponent } from "./Error500Page.base";

/**
 * Error500Page description
 */
export const Error500Page = styled<
  IError500PageProps,
  IError500PageStyleProps,
  IError500PageStyles
>(Error500PageComponent, getStyles);
