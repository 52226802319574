import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IPaymentMethodSelectorCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
}

export interface IPaymentMethodSelectorCardStyles
  extends IAmigoBaseStyles<IPaymentMethodSelectorCardSubComponentStyles> {}

export interface IPaymentMethodSelectorCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  saved: boolean;
}

export const getPaymentMethodSelectorCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentMethodSelectorCardStyleProps,
    IPaymentMethodSelectorCardStyles
  >,
  props?: IPaymentMethodSelectorCardStyleProps
): [
  IProcessedStyleSet<IPaymentMethodSelectorCardStyles>,
  IPaymentMethodSelectorCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentMethodSelectorCardStyleProps,
  IPaymentMethodSelectorCardStyles
> = (
  props: IPaymentMethodSelectorCardStyleProps
): IPaymentMethodSelectorCardStyles => {
  return {
    subComponentStyles: {
      gridViewCard: {
        root: [
          "amigo-NewPaymentMethodSelector-Card",
          {
            width: props.saved ? "47%" : "70%",
            selectors: {
              [MediaQueries.tablet]: {
                width: props.saved ? "40%" : "40%",
              },
            },
          },
        ],
      },
    },
  };
};
