import axios from "axios";
import { IBillingDetailedElement } from "src/models/Billing/IBillingDetailedElement";
import { IBillingElement } from "../../models/Billing/IBillingElement";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";
import DocumentService from "../Documents/DocumentService";
import i18n from "../i18n";

class BillingService {
  private _billing?: IBillingElement[] = undefined;
  private _billingPages: number = 1;

  public init(): void {
    this._billing = undefined;
    this._billingPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("travel", "EstImporte")
      .replace("billingDate", "DateFacturation")
      .replace("billingNumber", "Numero")
      .replace("status", "StatutExterne")
      .replace("subtotal", "MontantAvantTaxe")
      .replace("tps", "TaxeFederale")
      .replace("tvq", "TaxeProvinciale")
      .replace("total", "MontantTotal")
      .replace("balance", "SoldeApayer");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public exportBills(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined
  ) {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/billing/reports/export-excel`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: 1,
          pageSize: 1000,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }

  public getBillingElements(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IBillingElement[]> {
    const _this = this;
    let currentPage = this._billingPages;
    let combine: boolean;
    if (next && _this._billing !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `/api/enterprises/${enterpriseId}/billing`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._billing !== undefined) {
          _this._billingPages = currentPage;
          _this._billing = _this._billing.concat(items);
        } else {
          _this._billingPages = 1;
          _this._billing = this._billing = items;
        }
        return _this._billing ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this._billing ?? [];
      });
  }

  public async getBillingElement(
    enterpriseId: number,
    id: number
  ): Promise<IBillingDetailedElement> {
    const x = await axios.post(
      `/api/enterprises/${enterpriseId}/billing/${id}`,
      {},
      AuthService.getAuthHeader()
    );
    return x.data as any;
  }

  public printAccountStatement(enterpriseId: number): Promise<void> {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/billing/reports/account-statement`,
        {
          enterpriseId: enterpriseId,
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }

  public printBill(enterpriseId: number, billId: number): Promise<void> {
    return axios
      .post(
        `/api/enterprises/${enterpriseId}/billing/${billId}/billing-report`,
        {
          enterpriseId: enterpriseId,
          billId: billId,
          language: i18n.getLanguage(),
        },
        AuthService.getAuthHeaderWithBlob()
      )
      .then((x) => {
        DocumentService.openBlob(x);
      })
      .catch((x) => {
        console.log(`Document is missing ${JSON.stringify(x.data)}`);
      });
  }
}

export default new BillingService();
