import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IProfileCommunicationPreferencesSubComponentStyles {}

export interface IProfileCommunicationPreferencesStyles
  extends IAmigoBaseStyles<IProfileCommunicationPreferencesSubComponentStyles> {}

export interface IProfileCommunicationPreferencesStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getProfileCommunicationPreferencesClassNames = (
  styles?: IStyleFunctionOrObject<
    IProfileCommunicationPreferencesStyleProps,
    IProfileCommunicationPreferencesStyles
  >,
  props?: IProfileCommunicationPreferencesStyleProps
): [
  IProcessedStyleSet<IProfileCommunicationPreferencesStyles>,
  IProfileCommunicationPreferencesSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IProfileCommunicationPreferencesStyleProps,
  IProfileCommunicationPreferencesStyles
> = (
  _props: IProfileCommunicationPreferencesStyleProps
): IProfileCommunicationPreferencesStyles => {
  return {
    root: ["amigo-ProfileCommunicationPreferences", {}],
  };
};
