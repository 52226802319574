import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ITransportRequestsPageStyleProps,
  ITransportRequestsPageStyles,
} from "../TransportRequestsPage";

export interface ITransportsPageSubComponentStyles {
  land?: IStyleFunctionOrObject<
    ITransportRequestsPageStyleProps,
    ITransportRequestsPageStyles
  >;
}

export interface ITransportsPageStyles
  extends IAmigoBaseStyles<ITransportsPageSubComponentStyles> {}

export interface ITransportsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportsPageStyleProps,
    ITransportsPageStyles
  >,
  props?: ITransportsPageStyleProps
): [
  IProcessedStyleSet<ITransportsPageStyles>,
  ITransportsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportsPageStyleProps,
  ITransportsPageStyles
> = (props: ITransportsPageStyleProps): ITransportsPageStyles => {
  return {
    root: props.tokens.defaultPageRootStyle,
  };
};
