import { SelectionMode, Text } from "@fluentui/react";
import * as React from "react";
import { IPaymentBillDetail } from "src/models/Payments/IPaymentDetails";
import i18n from "src/services/i18n";
import { getPaymentDetailListClassNames } from "./PaymentDetailList.styles";
import {
  IPaymentDetailListProps,
  IPaymentDetailListState,
} from "./PaymentDetailList.types";
import { GridView } from "src/components/GridView";
import { GridViewMode } from "src/components/GridView/GridView.types";

export class PaymentDetailListComponent extends React.Component<
  IPaymentDetailListProps,
  IPaymentDetailListState
> {
  render(): JSX.Element {
    const { styles, mobile, billDetails } = this.props;
    const [classNames] = getPaymentDetailListClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const subtotal = billDetails.reduce(
      (sum, current) => sum + current.subtotal,
      0
    );
    const tps = billDetails.reduce((sum, current) => sum + current.tps, 0);
    const tvq = billDetails.reduce((sum, current) => sum + current.tvq, 0);
    const total = billDetails.reduce((sum, current) => sum + current.total, 0);
    const balance = billDetails.reduce(
      (sum, current) => sum + current.balance,
      0
    );
    const amount = billDetails.reduce(
      (sum, current) => sum + current.amount,
      0
    );

    return (
      <div className={classNames.root}>
        <div style={{ marginBottom: "10px" }}>
          <Text
            style={
              mobile ? { fontWeight: "bold", marginLeft: "10px" } : undefined
            }
            variant={mobile ? "large" : "xLarge"}
          >
            {i18n.t("billing:pendingbills:billsummary:title")}
          </Text>
        </div>
        <GridView
          {...this.props}
          styles={undefined}
          options={{
            title: "",
            iconName: "",
            mode: GridViewMode.list,
            sortBy: "",
            desc: false,
            fetched: billDetails && billDetails.length > 0,
          }}
          onDataPaging={() => {}}
          selectionMode={SelectionMode.none}
          items={billDetails.concat([
            {
              id: -1,
              key: "",
              late: false,
              travel: false,
              status: "",
              billingDate: "",
              billingNumber: "",
              subtotalFormatted: i18n.currency(subtotal),
              tpsFormatted: i18n.currency(tps),
              tvqFormatted: i18n.currency(tvq),
              totalFormatted: i18n.currency(total),
              balanceFormatted: i18n.currency(balance),
              amountFormatted: i18n.currency(amount),
              tps: tps,
              tvq: tvq,
              total: total,
              balance: balance,
              subtotal: subtotal,
              amount: amount,
            },
          ])}
          columns={
            mobile
              ? [
                  {
                    key: "billingNumber",
                    name: i18n.t("billing:billingnumber"),
                    minWidth: 150,
                    onRenderCell: (item: IPaymentBillDetail) => {
                      return item.id === -1 ? (
                        <Text style={{ fontWeight: "bold" }}>Total</Text>
                      ) : (
                        <Text>{item.billingNumber}</Text>
                      );
                    },
                  },
                  {
                    name: i18n.t("billing:total"),
                    key: "totalFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                  {
                    name: i18n.t("billing:balance"),
                    key: "balanceFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                ]
              : [
                  {
                    key: "billingDate",
                    name: i18n.t("billing:billingdate"),
                    minWidth: 150,
                    maxWidth: 150,
                    onRenderCell: (item: IPaymentBillDetail) => {
                      return item.id === -1 ? (
                        <Text style={{ fontWeight: "bold" }}>Total</Text>
                      ) : (
                        <Text>{item.billingDate}</Text>
                      );
                    },
                  },
                  {
                    key: "billingNumber",
                    name: i18n.t("billing:billingnumber"),
                    minWidth: 150,
                  },
                  {
                    key: "subtotalFormatted",
                    name: i18n.t("billing:subtotal"),
                    minWidth: 120,
                    maxWidth: 150,
                  },
                  {
                    name: i18n.t("billing:tps"),
                    key: "tpsFormatted",
                    minWidth: 120,
                    maxWidth: 150,
                  },
                  {
                    name: i18n.t("billing:tvq"),
                    key: "tvqFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                  {
                    name: i18n.t("billing:total"),
                    key: "totalFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                  {
                    name: i18n.t("billing:amount"),
                    key: "amountFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                  {
                    name: i18n.t("billing:balance"),
                    key: "balanceFormatted",
                    minWidth: 120,
                    maxWidth: 120,
                  },
                ]
          }
        />
      </div>
    );
  }
}
