import {
  DefaultButton,
  Dropdown,
  IDropdownOption,
  initializeComponentRef,
  Label,
  MaskedTextField,
  PrimaryButton,
  Separator,
  Stack,
  StackItem,
  Text,
  TextField,
  Toggle,
} from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { LabelField } from "src/components/LabelField";
import { IAddress } from "src/models/IAddress";
import { objectId } from "src/models/IObjectReference";
import {
  INewInspectionRequest,
  InspectionAddressSchema,
  NewInspectionRequestSchema,
} from "src/models/Messages/INewInspectionRequest";
import i18n from "src/services/i18n";
import { InspectionRequestService } from "src/services/Messages/InspectionRequestService";
import { validate } from "src/utils/validator";
import { getInspectionRequestEditorClassNames } from "./InspectionRequestEditor.styles";
import {
  IInspectionRequestEditorProps,
  IInspectionRequestEditorState,
} from "./InspectionRequestEditor.types";

export class InspectionRequestEditorComponent extends React.Component<
  IInspectionRequestEditorProps,
  IInspectionRequestEditorState
> {
  constructor(props: IInspectionRequestEditorProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {
      contacts: [],
      errors: [],
      isHousingOwnerAddress: [],
    };

    this.update();
  }

  private tryParseInt(str: string, defaultValue: number): number {
    let retValue = defaultValue;
    if (str != null) {
      if (str.length > 0) {
        if (!isNaN(str as any)) {
          retValue = parseInt(str);
        }
      }
    }
    return retValue;
  }

  private requestLoadRequired = () => {
    return (
      this.props.inputMessage &&
      (!this.props.request ||
        this.props.request.itemId !== this.props.inputMessage.itemId)
    );
  };

  private getAddress = (
    request: INewInspectionRequest | undefined,
    index: number
  ): IAddress | undefined => {
    return request?.addresses[index].address;
  };

  public validate = (): string[] => {
    const { request, onUpdate } = this.props;
    if (!request) return [];

    const errors = validate(NewInspectionRequestSchema, request);
    for (let index = 0; index < request.addresses.length; index++) {
      const address = this.getAddress(request, index);
      if (address) {
        const addressErrors = validate(InspectionAddressSchema, address);
        if (Object.keys(addressErrors).length > 0) {
          Object.keys(addressErrors).forEach((x) => {
            errors[`addresses[${index}].${x}`] = addressErrors[x];
          });
        }
      }
    }

    onUpdate(request, true, errors);
    return errors as any;
  };

  update = (): void => {
    if (!this.props.model) {
      if (this.requestLoadRequired()) {
        InspectionRequestService.getInspectionRequestAndModel(
          objectId(this.props.enterprise),
          this.props.inputMessage?.itemId ?? 0
        ).then((x) => {
          const [request, model] = x;
          if (request.addresses.length === 0) {
            request.addresses.push({
              id: 0,
              address: model.enterprise.address,
              buildingCount: 0,
              participantsCount: 0,
              isHousingOwner: false,
              bedCount: 0,
              isRentedToOtherEmployers: false,
              ownerFullName: "",
            });
          }
          this.props.onDataModelUpdate(model, {
            itemId: request.itemId,
            enterpriseId: objectId(this.props.enterprise),
            addresses: request.addresses,
            contactId: request.contactId,
            contactName: request.contactName,
            phone: request.phone,
            email: request.email,
            estimatedDeposit: request.estimatedDeposit,
            lastInspectionDate: request.lastInspectionDate,
            comments: "",
            takeForeignerWorkersOutOfFerme: false,
          });
        });
      } else {
        this.addDefaultAddress();
      }
    } else if (!this.props.request && this.props.inputMessage) {
      InspectionRequestService.getInspectionRequest(
        objectId(this.props.enterprise),
        this.props.inputMessage.itemId
      ).then((x) => {
        this.props.onUpdate({
          enterpriseId: objectId(this.props.enterprise),
          itemId: x.itemId,
          addresses: x.addresses,
          contactId: x.contactId,
          contactName: x.contactName,
          phone: x.phone,
          email: x.email,
          estimatedDeposit: x.estimatedDeposit,
          lastInspectionDate: x.lastInspectionDate,
          comments: "",
          takeForeignerWorkersOutOfFerme: false,
        });
      });
    }
  };

  componentDidUpdate() {
    this.update();
  }

  addDefaultAddress() {
    InspectionRequestService.getInspectionRequestEditModel(
      objectId(this.props.enterprise)
    ).then((x) => {
      const { request } = this.props;
      x.enterprise.address.street = "";
      if (request?.addresses.length === 0) {
        request.addresses.push({
          id: 0,
          address: x.enterprise.address,
          buildingCount: 0,
          participantsCount: 0,
          isHousingOwner: false,
          bedCount: 0,
          isRentedToOtherEmployers: false,
          ownerFullName: "",
        });
        this.props.onDataModelUpdate(x, request);
      } else {
        this.props.onDataModelUpdate(x);
      }
    });
  }

  render(): JSX.Element {
    const { styles, mobile, onUpdate, model, request, errors } = this.props;
    const [classNames] = getInspectionRequestEditorClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const contacts: IDropdownOption[] = (model?.contacts ?? [])
      .map((x) => {
        const option: IDropdownOption = {
          key: x.key,
          text: `${x.firstName} ${x.lastName}${
            x.title && x.title.length > 0 ? " (" + x.title + ")" : ""
          }`,
          data: `${x.firstName} ${x.lastName}`,
        };
        return option;
      })
      .concat([
        {
          key: "-1",
          text: i18n.t("messages:assisted:newdeparture:other"),
          data: "",
        },
      ]);

    const states: IDropdownOption[] = (model?.states ?? []).map((x) => {
      const option: IDropdownOption = {
        key: x.key?.toString() ?? "-1",
        text: x.name,
      };
      return option;
    });

    const branches: IDropdownOption[] = (model?.enterprise?.branches ?? []).map(
      (x) => {
        const option: IDropdownOption = {
          key: x.key,
          text: x.name,
        };
        return option;
      }
    );

    const labelQuestionStyle = {
      display: 'flex',
      alignItems: 'top',
      marginRight: '10px',
    };

    
    if(request?.addresses.length === 0)
      this.addDefaultAddress();

    return (
      <div className={classNames.root}>
        <Stack tokens={{ childrenGap: 10 }}>
          {request?.addresses.map((address, index) => (
            <>
              <Stack
                key={index.toString()}
                horizontal={!mobile}
                tokens={{ childrenGap: 10 }}
              >
                <StackItem grow={mobile ? true : 2}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <Stack horizontal>
                      <IconHeader
                        {...this.props}
                        as={"h3"}
                        styles={{
                          root: {
                            marginTop: 0,
                          },
                          text: {
                            marginTop: "10px!important",
                            marginBottom: "0!important",
                          },
                        }}
                        iconName="MapPin"
                        title={
                          request.addresses.length > 1
                            ? `${i18n.t(
                                "messages:assisted:inspection:address"
                              )} #${index + 1}`
                            : i18n.t("messages:assisted:inspection:address")
                        }
                      />
                      {index > 0 && (
                        <StackItem>
                          <DefaultButton
                            styles={{
                              root: {
                                margin: "10px",
                              },
                              textContainer: {
                                fontWeight: 600,
                              },
                            }}
                            onClick={() =>
                              onUpdate({
                                ...request,
                                addresses: request.addresses.filter(
                                  (_, i) => i !== index
                                ),
                              })
                            }
                            text="Retirer l'addresse"
                          />
                        </StackItem>
                      )}
                    </Stack>
                    <Stack
                      grow
                      horizontal={!mobile}
                      tokens={{ childrenGap: 10 }}
                    >
                      {index === 0 && (
                        <StackItem grow={1}>
                          <LabelField
                            {...this.props}
                            styles={{
                              root: {
                                marginTop: "5px",
                              },
                            }}
                            label={i18n.t(
                              "messages:assisted:inspection:enterprise"
                            )}
                            content={
                              <div style={{ marginTop: "4px" }}>
                                <Text>{`${
                                  this.props.model
                                    ? this.props.model.enterprise.memberid + " "
                                    : ""
                                }${this.props.enterprise.name}`}</Text>
                              </div>
                            }
                          />
                        </StackItem>
                      )}
                      <StackItem grow={2}>
                        <Dropdown
                          styles={
                            branches.length === 0
                              ? {
                                  root: {
                                    display: "none",
                                  },
                                }
                              : undefined
                          }
                          label={i18n.t("messages:assisted:inspection:branch")}
                          options={branches}
                          selectedKey={
                            address?.branchId
                              ? address.branchId.toString()
                              : undefined
                          }
                          placeholder={i18n.t(
                            "messages:assisted:inspection:select"
                          )}
                          onChange={(
                            _ev: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                          ) => {
                            if (option && request) {
                              const address =
                                model?.enterprise.branches.filter(
                                  (x) =>
                                    x.id === parseInt(option.key.toString())
                                )[0].address ?? model?.enterprise.address;
                              onUpdate({
                                ...request,
                                addresses: request.addresses.map((x, i) =>
                                  i === index
                                    ? {
                                        ...x,
                                        address: address ?? x.address,
                                        branchId: parseInt(
                                          option.key.toString()
                                        ),
                                      }
                                    : x
                                ),
                              });
                            }
                          }}
                        />
                      </StackItem>
                    </Stack>
                    <TextField
                      required
                      label={i18n.t("messages:assisted:inspection:street")}
                      value={address?.address.street}
                      maxLength={100}
                      errorMessage={errors[`addresses[${index}].street`]}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          onUpdate({
                            ...request,
                            addresses: request.addresses.map((x, i) =>
                              i === index
                                ? {
                                    ...x,
                                    address: {
                                      ...(address ?? x.address).address,
                                      street: newValue ?? "",
                                    },
                                  }
                                : x
                            ) as any,
                          });
                      }}
                    />
                    <Stack
                      horizontal={!mobile}
                      grow
                      tokens={{ childrenGap: 10 }}
                    >
                      <MaskedTextField
                        required
                        label={i18n.t(
                          "messages:assisted:inspection:postalcode"
                        )}
                        value={address?.address?.postalcode ?? ""}
                        errorMessage={errors[`addresses[${index}].postalcode`]}
                        maxLength={25}
                        mask="a9a 9a9"
                        onChange={(
                          _ev: React.FormEvent<
                            HTMLInputElement | HTMLTextAreaElement
                          >,
                          newValue?: string
                        ) => {
                          request &&
                            onUpdate({
                              ...request,
                              addresses: request.addresses.map((x, i) =>
                                i === index
                                  ? {
                                      ...x,
                                      ...x,
                                      address: {
                                        ...(address ?? x.address).address,
                                        postalcode: newValue ?? "",
                                      },
                                    }
                                  : x
                              ) as any,
                            });
                        }}
                      />
                      <StackItem grow>
                        <TextField
                          required
                          label={i18n.t("messages:assisted:inspection:city")}
                          maxLength={100}
                          errorMessage={errors[`addresses[${index}].city`]}
                          value={address?.address?.city ?? ""}
                          onChange={(
                            _ev: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            request &&
                              onUpdate({
                                ...request,
                                addresses: request.addresses.map((x, i) =>
                                  i === index
                                    ? {
                                        ...x,
                                        ...x,
                                        address: {
                                          ...(address ?? x.address).address,
                                          city: newValue ?? "",
                                        },
                                      }
                                    : x
                                ) as any,
                              });
                          }}
                        />
                      </StackItem>
                      <Dropdown
                        required
                        label={i18n.t("messages:assisted:inspection:state")}
                        options={states}
                        errorMessage={errors[`addresses[${index}].state`]}
                        selectedKey={
                          address?.address.state &&
                          states &&
                          states.filter((x) => x.text === address.address.state)
                            .length > 0
                            ? states.filter(
                                (x) => x.text === address.address.state
                              )[0].key
                            : undefined
                        }
                        placeholder={i18n.t(
                          "messages:assisted:inspection:select"
                        )}
                        onChange={(
                          _ev: React.FormEvent<HTMLDivElement>,
                          option?: IDropdownOption
                        ) => {
                          option &&
                            request &&
                            onUpdate({
                              ...request,
                              addresses: request.addresses.map((x, i) =>
                                i === index
                                  ? {
                                      ...x,
                                      ...x,
                                      address: {
                                        ...(address ?? x.address).address,
                                        state: option.text ?? "",
                                      },
                                    }
                                  : x
                              ) as any,
                            });
                        }}
                      />
                    </Stack>

                    <Stack>
                      <Stack horizontal>
                        <Label required  style={labelQuestionStyle}>{i18n.t("messages:assisted:inspection:askifishousingowner")}</Label>
                        <Toggle 
                                className="toggleVerticalCenter"
                                offText={ i18n.t("global:no")}
                                onText={ i18n.t("global:yes")}
                                onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                  
                                  // Reset values
                                  request.addresses[index].isHousingOwner = checked ?? false;
                                  request.addresses[index].ownerFullName = "";
                                  request.addresses[index].bedCount = 0;
                                  request.addresses[index].isRentedToOtherEmployers = false;

                                  // SetState to update UI
                                  this.state.isHousingOwnerAddress[index] = checked?? false;
                                  this.setState({
                                    isHousingOwnerAddress:  this.state.isHousingOwnerAddress
                                  });

                                }}
                        />
                      </Stack>
                    
                      <Stack horizontal style={{ display: this.state.isHousingOwnerAddress[index] ? "": "none" }}>
                        <Label required={request.addresses[index].isHousingOwner} style={labelQuestionStyle}>{i18n.t("messages:assisted:inspection:askbedcount")}</Label>
                        <TextField  required={request.addresses[index].isHousingOwner}
                                    disabled={!request.addresses[index].isHousingOwner}
                                    value={request.addresses[index].bedCount?.toString() ?? "0"}
                                    type={"number"}
                                    maxLength={2}
                                    min={0}
                                    max={990}
                                    onChange={(_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
                                    request && onUpdate({
                                      ...request,
                                      addresses: request.addresses.map((x, i) =>
                                        i === index
                                          ? {
                                              ...x,
                                              ...x,
                                              bedCount: this.tryParseInt(newValue ?? "", 0),
                                            }
                                          : x
                                      ) as any,
                                    });
                                    }}
                        /> 
                      </Stack>

                      <Stack horizontal style={{ display: this.state.isHousingOwnerAddress[index] ? "": "none" }}>
                        <Label style={labelQuestionStyle} >{i18n.t("messages:assisted:inspection:askifrenttootheremployer")}</Label>
                        <Toggle checked={request.addresses[index].isRentedToOtherEmployers}
                                className="toggleVerticalCenter"
                                offText={ i18n.t("global:no")}
                                onText={ i18n.t("global:yes")}
                                onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                                  checked && request && onUpdate({
                                    ...request,
                                    addresses: request.addresses.map((x, i) =>
                                      i === index
                                        ? {
                                            ...x,
                                            ...x,
                                            isRentedToOtherEmployers: checked ?? false,
                                          }
                                        : x
                                    ) as any,
                                  });
                                }}
                        />
                      </Stack>

                      <Stack horizontal style={{ display: !this.state.isHousingOwnerAddress[index] ? "": "none" }}>
                        <Label style={labelQuestionStyle} >{i18n.t("messages:assisted:inspection:askownerfullname")}</Label>
                        <TextField  value={request.addresses[index].ownerFullName ?? ""}
                                    maxLength={100}
                                    onChange={(
                                      _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
                                      newValue?: string
                                    ) => {
                                    console.log(request.addresses[index].isHousingOwner);
                                    request && onUpdate({
                                          ...request,
                                          addresses: request.addresses.map((x, i) =>
                                            i === index
                                              ? {
                                                  ...x,
                                                  ...x,
                                                  ownerFullName: newValue ?? "",
                                                }
                                              : x
                                          ) as any,
                                        });
                                    }}
                      />
                      </Stack>
                    </Stack>
                  </Stack>
                </StackItem>
                {mobile && <Separator />}
                <StackItem grow={mobile ? true : 1}>
                  <Stack tokens={{ childrenGap: 10 }}>
                    <IconHeader
                      {...this.props}
                      as={"h3"}
                      styles={{
                        root: {
                          marginTop: 0,
                        },
                        text: {
                          marginTop: "10px!important",
                          marginBottom: "0!important",
                        },
                      }}
                      iconName="ProductList"
                      title={i18n.t("messages:assisted:inspection:details")}
                    />
                    <TextField
                      required
                      type={"number"}
                      label={i18n.t("messages:assisted:inspection:building")}
                      value={(address?.buildingCount ?? 0).toString()}
                      errorMessage={errors[`addresses[${index}].buildingCount`]}
                      maxLength={3}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          onUpdate({
                            ...request,
                            addresses: request.addresses.map((x, i) =>
                              i === index
                                ? {
                                    ...x,
                                    buildingCount: this.tryParseInt(
                                      newValue ?? "0",
                                      0
                                    ),
                                  }
                                : x
                            ),
                          });
                      }}
                    />
                    <TextField
                      required
                      type={"number"}
                      label={i18n.t("messages:assisted:inspection:workers")}
                      value={(address?.participantsCount ?? 0).toString()}
                      maxLength={3}
                      errorMessage={
                        errors[`addresses[${index}].participantsCount`]
                      }
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          onUpdate({
                            ...request,
                            addresses: request.addresses.map((x, i) =>
                              i === index
                                ? {
                                    ...x,
                                    participantsCount: this.tryParseInt(
                                      newValue ?? "0",
                                      0
                                    ),
                                  }
                                : x
                            ),
                          });
                      }}
                    />
                    {!mobile && <div style={{ height: "59px" }}></div>}
                  </Stack>
                </StackItem>
              </Stack>
            </>
          ))}
          <Stack horizontal>
            <StackItem grow>
              <div></div>
            </StackItem>
            <StackItem>
              <PrimaryButton
                disabled={
                  (request?.addresses.filter((x) => {
                    const addressErrors = validate(
                      InspectionAddressSchema,
                      x.address
                    );
                    return Object.keys(addressErrors).length > 0;
                  }).length ?? 0) > 0
                }
                text="Ajouter un autre etablissement"
                onClick={() =>
                  request &&
                  onUpdate({
                    ...request,
                    addresses: (request?.addresses ?? []).concat([
                      {
                        id: 0,
                        buildingCount: 0,
                        participantsCount: 0,
                        address: {
                          id: 0,
                          street: "",
                          city: "",
                          postalcode: "",
                          state: "Quebec",
                          country: "Canada",
                          countryId: 1,
                        },
                        isHousingOwner: false,
                        bedCount: 0,
                        isRentedToOtherEmployers: false,
                        ownerFullName: "",
                      },
                    ]),
                  })
                }
              />
            </StackItem>
          </Stack>
          <Stack horizontal={!mobile} tokens={{ childrenGap: 10 }}>
            <StackItem grow={mobile ? true : 2}>
              <Stack tokens={{ childrenGap: 10 }}>
                <IconHeader
                  {...this.props}
                  as={"h3"}
                  styles={{
                    root: {
                      marginTop: mobile ? 0 : "-10px",
                    },
                    text: {
                      marginTop: "10px!important",
                      marginBottom: "0!important",
                    },
                  }}
                  iconName="ConnectContacts"
                  title={"Contact"}
                />
                <Stack horizontal={!mobile} grow tokens={{ childrenGap: 10 }}>
                  <StackItem grow>
                    <Dropdown
                      label={i18n.t("messages:assisted:inspection:contacts")}
                      options={contacts}
                      errorMessage={errors.contactName}
                      placeholder={i18n.t(
                        "messages:assisted:inspection:select"
                      )}
                      selectedKey={request?.contactId?.toString() ?? undefined}
                      styles={{
                        root: { marginBottom: "10px" },
                        callout: mobile
                          ? undefined
                          : {
                              minWidth: "420px",
                            },
                      }}
                      onChange={(
                        _ev: React.FormEvent<HTMLDivElement>,
                        option?: IDropdownOption
                      ) => {
                        option &&
                          request &&
                          onUpdate({
                            ...request,
                            contactId: parseInt(option.key.toString()),
                            contactName: option.key === "-1" ? "" : option.data,
                            phone:
                              option.key === "-1"
                                ? ""
                                : model?.contacts.filter(
                                    (y) =>
                                      y.id === parseInt(option.key.toString())
                                  )[0].phone,
                            email:
                              option.key === "-1"
                                ? ""
                                : model?.contacts.filter(
                                    (y) =>
                                      y.id === parseInt(option.key.toString())
                                  )[0].email,
                          });
                      }}
                    />
                    <TextField
                      required
                      label={i18n.t("messages:assisted:inspection:phone")}
                      errorMessage={errors.phone ?? undefined}
                      value={request?.phone ?? ""}
                      maxLength={100}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          newValue &&
                          onUpdate({
                            ...request,
                            phone: newValue,
                          });
                      }}
                      styles={{ root: { marginBottom: "10px" } }}
                    />
                  </StackItem>
                  <StackItem styles={{ root: { maxWidth: "340px" } }} grow>
                    <TextField
                      required
                      label={i18n.t("messages:assisted:inspection:contactname")}
                      errorMessage={errors.contactName}
                      value={request?.contactName ?? ""}
                      maxLength={200}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          newValue &&
                          onUpdate({
                            ...request,
                            contactName: newValue,
                          });
                      }}
                      styles={{ root: { marginBottom: "10px" } }}
                    />
                    <TextField
                      required
                      errorMessage={errors.email}
                      label={i18n.t("messages:assisted:inspection:email")}
                      styles={{ root: { marginBottom: "10px" } }}
                      value={request?.email ?? ""}
                      maxLength={200}
                      onChange={(
                        _ev: React.FormEvent<
                          HTMLInputElement | HTMLTextAreaElement
                        >,
                        newValue?: string
                      ) => {
                        request &&
                          newValue &&
                          onUpdate({
                            ...request,
                            email: newValue,
                          });
                      }}
                    />
                  </StackItem>
                </Stack>
              </Stack>
            </StackItem>
          </Stack>

          <Stack horizontal>
            <Label required style={labelQuestionStyle} >{i18n.t("messages:assisted:inspection:askiftakeworkerextraferme")}</Label>
            <Toggle 
                    className="toggleVerticalCenter"
                    offText={ i18n.t("global:no")}
                    onText={ i18n.t("global:yes")}
                    onChange={(_ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean) => {
                      checked && request && onUpdate({
                        ...request,
                        takeForeignerWorkersOutOfFerme: checked ?? false,
                      });
                    }}
              />
          </Stack>
          <div style={{ height: "20px" }}></div>
        </Stack>
      </div>
    );
  }
}
