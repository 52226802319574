import { ISchemaObject } from "../utils/validator";
import i18n from "src/services/i18n";

export interface IForgotPasswordModel {
  username: string;
}

export const ForgotPasswordSchema: ISchemaObject = {
  name: "user",
  plural: "users",
  label: "User",
  fields: {
    username: {
      name: "username",
      label: i18n.t("forgot:username"),
      required: true,
      pattern: "^[ a-zA-Z0-9,@#.-]+$",
      errorMessage: i18n.t("forgot:username:error"),
      type: "string",
    },
  },
};
