import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface INewPaymentMethodControlSubComponentStyles {}

export interface INewPaymentMethodControlStyles
  extends IAmigoBaseStyles<INewPaymentMethodControlSubComponentStyles> {}

export interface INewPaymentMethodControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewPaymentMethodControlClassNames = (
  styles?: IStyleFunctionOrObject<
    INewPaymentMethodControlStyleProps,
    INewPaymentMethodControlStyles
  >,
  props?: INewPaymentMethodControlStyleProps
): [
  IProcessedStyleSet<INewPaymentMethodControlStyles>,
  INewPaymentMethodControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewPaymentMethodControlStyleProps,
  INewPaymentMethodControlStyles
> = (
  _props: INewPaymentMethodControlStyleProps
): INewPaymentMethodControlStyles => {
  return {};
};
