import {
  CommandBar,
  Icon,
  Panel,
  PanelType,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { resolveFontStyles, ResponsiveFontStyles } from "../AppFontStyles";
import {
  getEntityPanelClassNames,
  IEntityPanelStyleProps,
} from "./EntityPanel.styles";
import { IEntityPanelProps, IEntityPanelState } from "./EntityPanel.types";

export class EntityPanelComponent extends React.Component<
  IEntityPanelProps,
  IEntityPanelState
> {
  constructor(props: IEntityPanelProps) {
    super(props);

    this.state = {};
  }

  public render() {
    const { styles } = this.props;
    const styleProps: IEntityPanelStyleProps = {
      ...this.props,
      ...this.state,
    };
    const [classNames] = getEntityPanelClassNames(styles!, styleProps);
    const variant = resolveFontStyles(
      this.props.headerVariant ?? ResponsiveFontStyles.xLarge,
      styleProps
    );
    return (
      <Panel
        className={classNames.root}
        isOpen={this.props.isOpen}
        data-automation-id="nvx:alerts:itempanel"
        isLightDismiss={true}
        onDismiss={this.props.onDismiss}
        isHiddenOnDismiss={true}
        onRenderHeader={() => {
          return (
            <Stack
              horizontal
              className={classNames.headerStack}
              style={{ marginRight: "10px" }}
            >
              <Icon
                iconName={this.props.headerIconName ?? "Info"}
                className={classNames.headerIcon}
              />
              <Stack
                className={classNames.headerTextStack}
                horizontal={!this.props.mobile}
              >
                {this.props.elementName !== undefined && (
                  <>
                    <Text
                      variant={variant}
                      className={classNames.headerTextElementName}
                    >
                      {this.props.elementName}
                    </Text>
                    {!this.props.mobile && (
                      <Text
                        variant={variant}
                        className={classNames.headerSeparator}
                      >
                        {" : "}
                      </Text>
                    )}
                  </>
                )}
                <Text variant={variant} className={classNames.headerTextTitle}>
                  {this.props.title}
                </Text>
              </Stack>
            </Stack>
          );
        }}
        type={this.props.panelType ?? PanelType.medium}
      >
        {this.props.commands && (
          <CommandBar
            styles={{
              root: {
                marginTop: "3px",
                paddingLeft: 0,
              },
            }}
            items={this.props.commands}
          />
        )}
        {this.props.children}
      </Panel>
    );
  }
}
