import {
  DatePicker,
  Dropdown,
  IconButton,
  IDropdownOption,
  initializeComponentRef,
  MessageBar,
  MessageBarType,
  Selection,
  Separator,
  Stack,
  TeachingBubble,
  Text,
  Toggle,
} from "@fluentui/react";
import Moment from "moment";
import "moment/locale/fr";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
} from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { objectId } from "src/models/IObjectReference";
import { IArrivalRequestFlight } from "src/models/Messages/IArrivalRequestFlight";
import {
  ArrivalRequestParticipantSchema,
  IArrivalRequestParticipant,
} from "src/models/Messages/IArrivalRequestParticipant";
import {
  INewArrivalRequest,
  NewArrivalRequestSchema,
} from "src/models/Messages/INewArrivalRequest";
import i18n from "src/services/i18n";
import { ArrivalRequestService } from "src/services/Messages/ArrivalRequestService";
import { validate } from "src/utils/validator";
import { ArrivalAssistedParticipantCard } from "../ArrivalAssistedParticipantCard";
import { AuthorizationConsentPanel } from "../Controls/AuthorizationConsentPanel";
import { AuthorizationWarningPanel } from "../Controls/AuthorizationWarningPanel";
import { DepartureRequestFlightSelector as ArrivalRequestFlightSelector } from "../DepartureRequestFlightSelector";
import { getArrivalAssistedEditorClassNames } from "./ArrivalAssistedEditor.styles";
import {
  IArrivalAssistedEditorProps,
  IArrivalAssistedEditorState,
} from "./ArrivalAssistedEditor.types";

const landTransportationEnabled: boolean = false;

export class ArrivalAssistedEditorComponent extends React.Component<
  IArrivalAssistedEditorProps,
  IArrivalAssistedEditorState
> {
  constructor(props: IArrivalAssistedEditorProps) {
    super(props);
    initializeComponentRef(this);
    this.state = {
      warning: false,
      onconsent: false,
      consent: false,
      datetip: false,
    };

    const { inputMessage, model } = props;
    if (inputMessage && !model) {
      ArrivalRequestService.getArrivalRequestEditModel(
        objectId(props.enterprise),
        inputMessage.itemId
      )
        .then((x) => {
          const [inp, model] = x;
          const request: INewArrivalRequest = {
            ...inp,
            reason: inp.reason,
          };

          if (request.originCity && request.requestDate && request.requestId) {
            props.onDataModelUpdate(model);
            ArrivalRequestService.getRequestedDataModel(
              objectId(props.enterprise),
              request.requestId,
              request.requestNumber ??
                model.requests.filter((z) => z.id === request.requestId)[0]
                  ?.name,
              request.originCityName ??
                model.cities.filter(
                  (z) => z.key?.toString() === request.originCity?.toString()
                )[0].name,
              request.requestDate,
              props.changing
            ).then((y) => {
              const [p, flights] = y;

              const participants = p.map((z) => {
                const pos = request.participants.filter((zz) => zz.id === z.id);
                return pos.length === 0 ? z : pos[0];
              });

              props.onFlightsUpdate(
                {
                  ...request,
                  participants: participants,
                },
                flights,
                true
              );
            });
          } else if (request.requestId) {
            props.onDataModelUpdate(model);
            ArrivalRequestService.getParticipants(
              objectId(props.enterprise),
              request.requestId,
              props.changing
            ).then((y) => {
              props.onUpdate(
                {
                  ...request,
                  participants: y.map((z) => {
                    const pos = request.participants.filter(
                      (zz) => zz.id === z.id
                    );
                    return pos.length === 0 ? z : pos[0];
                  }),
                },
                true
              );
            });
          } else {
            this.props.onDataModelUpdate(model, request);
          }
        })
        .catch((x) => {
          console.log(x);
        });
    } else if (!model) {
      ArrivalRequestService.getDataModel(objectId(props.enterprise))
        .then((x) => {
          props.onDataModelUpdate(x);
        })
        .catch((_x) => {
          console.log("error");
        });
    }
  }

  public validate = (): string[] => {
    const { request, onUpdate } = this.props;
    if (!request) return [];
    const { participants } = request;
    const errors = validate(NewArrivalRequestSchema, request);
    participants.forEach((x) => {
      x.errors = (
        x.selected ? validate(ArrivalRequestParticipantSchema, x) : []
      ) as any;
      if (x.errors.length > 0) {
        console.log(x.errors);
        // TODO: Review
      }
    });
    if (
      participants.filter((x) => x.selected && x.errors.length > 0).length > 0
    ) {
      errors["participants:item"] = "error";
    } else if (
      Object.keys(errors).filter((x) => x === "participants:item").length > 0
    ) {
      delete errors["participants:item"];
    }

    if (
      request.requestId &&
      participants.filter((x) => x.selected).length === 0
    ) {
      errors.participants =
        "messages:assisted:newdeparture:participants:required";
    }
    if (
      this.props.flights !== undefined &&
      request.selectedFlight !== undefined &&
      this.props.flights.filter((x) => x.id === request.selectedFlight)[0]
        .seatAvailable < participants.filter((x) => x.selected).length
    ) {
      errors.participants = i18n.t("messages:assisted:newarrival:maxseat");
    }
    onUpdate({ ...request, participants: participants }, true, errors as any);
    return errors as any;
  };

  private formatDate = (date: Date | null | undefined): Date | undefined => {
    if (!date) return undefined;
    if (date === null) return undefined;
    const d = typeof date === "string" ? i18n.parseISOLocal(date) : date;
    return d < Moment(new Date()).add(10, "day").toDate() ? undefined : d;
  };

  private onRequestChanged = (
    newRequestId?: number,
    requestNumber?: string
  ): void => {
    const { enterprise, model, request, onUpdate, changing } = this.props;

    newRequestId &&
      onUpdate({
        ...request,
        requestId: newRequestId,
        requestNumber: requestNumber,
      });

    if (!newRequestId) return;

    model && request.originCity
      ? ArrivalRequestService.getRequestedDataModel(
          objectId(enterprise),
          newRequestId,
          requestNumber ?? "0",
          model.cities.filter(
            (y) => y.key?.toString() === request.originCity?.toString()
          )[0].name,
          request.requestDate ?? new Date(),
          changing
        ).then((x) => {
          const { request, onFlightsUpdate } = this.props;
          const [participants, flights] = x;
          onFlightsUpdate(
            { ...request, participants: participants },
            flights,
            true
          );
        })
      : ArrivalRequestService.getParticipants(
          objectId(enterprise),
          newRequestId,
          this.props.changing
        ).then((x) => {
          onUpdate(
            {
              ...request,
              requestId: newRequestId,
              requestNumber: requestNumber,
              participants: x,
            },
            true
          );
        });
  };

  private onRequestDateChanged = (newRequestDate?: Date): void => {
    const { enterprise, model, request, onUpdate, onFlightsUpdate } =
      this.props;

    if (model && request.requestId && newRequestDate) {
      ArrivalRequestService.getFlights(
        objectId(enterprise),
        request.requestId,
        model.requests.filter((y) => y.id === request.requestId)[0].name,
        newRequestDate
      ).then((x) => {
        onFlightsUpdate(
          {
            ...request,
            requestDate: newRequestDate,
          },
          x
        );
      });
    } else {
      onUpdate({
        ...request,
        requestDate: newRequestDate,
      });
    }
  };

  private withCharterChoice = (): boolean => {
    return (
      this.props.model !== undefined &&
      this.props.request.requestId !== undefined &&
      this.props.request.requestDate !== undefined &&
      this.props.flights !== undefined &&
      this.props.flights.length !== 0
    );
  };

  render(): JSX.Element {
    const {
      styles,
      mobile,
      request,
      onUpdate,
      model,
      errors,
      participantsFetched,
    } = this.props;
    const [classNames, subComponentStyles] = getArrivalAssistedEditorClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { warning, onconsent, consenterror } = this.state;

    const { requestDate, preauth, requestId, participants } = request;

    const requests = model
      ? model.requests.map((x) => {
          const i: IDropdownOption = {
            key: (x.key ?? "").toString(),
            text: x.name,
          };
          return i;
        })
      : [];

    /*
    const other: IDropdownOption = {
      key: "Autre",
      text: "Autre",
    };
    
    const unknown: IDropdownOption = {
      key: "-1",
      text: i18n.t("messages:assisted:newdeparture:unknown"),
    };

    const none: IDropdownOption = {
      key: "-1",
      text: i18n.t("messages:assisted:newdeparture:none"),
    };
    */
    /*
    const recalls = model
      ? model.recalls
          .map((x) => {
            const i: IDropdownOption = {
              key: (x.key ?? "").toString(),
              text: x.name,
            };
            return i;
          })
          .concat(unknown)
      : [unknown];
      */

    /*
    const reasons = model
      ? model.reasons
          .map((x) => {
            const i: IDropdownOption = {
              key: (x.key ?? "").toString(),
              text: x.name,
            };
            return i;
          })
          .concat(other)
      : [other];

    const cities = model
      ? model.cities
          .map((x) => {
            const i: IDropdownOption = {
              key: (x.key ?? "").toString(),
              text: x.name,
            };
            return i;
          })
          .concat(none)
      : [none];
    */
    return (
      <div className={classNames.root}>
        <div style={{ height: "10px" }}></div>
        <Stack tokens={{ childrenGap: 10 }}>
          <MessageBar messageBarType={MessageBarType.warning}>
            <Stack tokens={{ childrenGap: 5 }}>
              <Text style={{ fontWeight: 600 }}>
                {i18n.t("messages:assisted:newdeparture:warning:title")}
              </Text>
              <Text style={mobile ? undefined : { maxWidth: "500px" }}>
                {i18n.t("messages:assisted:newarrival:warning:content")}
              </Text>
            </Stack>
          </MessageBar>
          <Stack horizontal tokens={{ childrenGap: mobile ? 10 : 50 }}>
            <Dropdown
              styles={{ root: { minWidth: "192px" } }}
              required
              label={i18n.t("messages:assisted:newdeparture:requestnumber")}
              options={requests}
              errorMessage={errors.requestId}
              selectedKey={requestId ? requestId.toString() : undefined}
              onChange={(
                _ev: React.FormEvent<HTMLDivElement>,
                option?: IDropdownOption
              ) => {
                this.onRequestChanged(
                  option ? parseInt(option.key.toString()) : undefined,
                  option ? option.text : undefined
                );
              }}
              placeholder={i18n.t("messages:assisted:newdeparture:select")}
            />
            <Stack tokens={{ childrenGap: 10 }}>
              <Stack horizontal tokens={{ childrenGap: mobile ? 2 : 7 }}>
                <Text nowrap style={{ fontWeight: 600, marginTop: "5px" }}>
                  {i18n.t("messages:assisted:newdeparture:preauth:label")}
                </Text>
                <IconButton
                  iconProps={{ iconName: "Info" }}
                  onClick={() => this.setState({ warning: true })}
                />
              </Stack>
              <Toggle
                styles={{ root: { marginTop: "3px!important" } }}
                checked={preauth}
                onChange={(
                  _event: React.MouseEvent<HTMLElement>,
                  checked?: boolean
                ) => {
                  const { request, onUpdate } = this.props;
                  if (checked ?? false) {
                    if (this.state.consent) {
                      onUpdate({ ...request, preauth: true });
                      this.setState({
                        onconsent: false,
                      });
                    } else {
                      onUpdate({ ...request, preauth: false });
                      this.setState({
                        onconsent: true,
                      });
                    }
                  } else {
                    onUpdate({ ...request, preauth: false });
                    this.setState({
                      onconsent: false,
                    });
                  }
                }}
              />
            </Stack>
          </Stack>
          <Separator />
          <Stack tokens={{ childrenGap: 10 }}>
            <Stack
              horizontal={!mobile}
              tokens={{ childrenGap: mobile ? 10 : 50 }}
            >
              {/*
              <Dropdown
                required
                styles={{ root: { minWidth: "192px" } }}
                selectedKey={request.originCity?.toString()}
                errorMessage={errors.originCity}
                onChange={(
                  _ev: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption
                ) => {
                  this.onCityChanged(
                    option ? parseInt(option.key.toString()) : undefined
                  );
                }}
                label={i18n.t("messages:assisted:newdeparture:origincity")}
                options={cities}
                placeholder={i18n.t("messages:assisted:newdeparture:select")}
              />
              
              <Dropdown
                styles={{ root: { minWidth: "192px" } }}
                label={i18n.t("messages:assisted:newdeparture:reason")}
                options={reasons}
                selectedKey={request.reason?.toString() ?? undefined}
                placeholder={i18n.t("messages:assisted:newdeparture:select")}
                onChange={(
                  _ev: React.FormEvent<HTMLDivElement>,
                  option?: IDropdownOption
                ) => {
                  const { request, onUpdate } = this.props;
                  option &&
                    onUpdate({
                      ...request,
                      reason: option.key.toString(),
                    });
                }}
              />
              */}
              {landTransportationEnabled && (
                <Toggle
                  label={i18n.t(
                    "messages:assisted:newdeparture:landrequested:toggle"
                  )}
                  checked={request.landTransportIncluded}
                  onChange={(
                    _event: React.MouseEvent<HTMLElement>,
                    checked?: boolean
                  ) => {
                    const { request, onUpdate } = this.props;
                    onUpdate({
                      ...request,
                      landTransportIncluded: checked ?? false,
                    });
                  }}
                />
              )}
            </Stack>
            {/* <Separator /> */}
            <Stack
              horizontal={!mobile}
              tokens={{ childrenGap: mobile ? 10 : 50 }}
            >
              <Stack horizontal>
                <DatePicker
                  isRequired={true}
                  label={i18n.t("messages:assisted:newdeparture:requestdate")}
                  title={
                    "La date de demande doit être comprise entre 10 et 90 jours après aujourd'hui"
                  }
                  ariaLabel={
                    "La date de demande doit être comprise entre 10 et 90 jours après aujourd'hui"
                  }
                  minDate={Moment(new Date()).add(10, "day").toDate()}
                  maxDate={Moment(new Date()).add(90, "day").toDate()}
                  formatDate={(date?: Date) => {
                    if (!date) return "";
                    return Moment(date).locale(i18n.getLanguage()).format("LL");
                  }}
                  textField={{ errorMessage: errors.requestDate }}
                  showGoToToday={false}
                  strings={i18n.CalendarStrings()}
                  initialPickerDate={this.formatDate(
                    Moment(new Date()).add(10, "day").toDate()
                  )}
                  value={requestDate ? this.formatDate(requestDate) : undefined}
                  onSelectDate={(date: Date | null | undefined) => {
                    this.onRequestDateChanged(date === null ? undefined : date);
                  }}
                />
                {this.state.datetip && (
                  <TeachingBubble
                    target={`#datetipa`}
                    hasCondensedHeadline={true}
                    onDismiss={() => this.setState({ datetip: false })}
                    headline="Attention"
                  >
                    <Text styles={{ root: { color: "#fff" } }}>
                      {
                        "La date de demande doit être comprise entre 10 et 90 jours après aujourd'hui."
                      }
                    </Text>
                  </TeachingBubble>
                )}
                <IconButton
                  id="datetipa"
                  iconProps={{ iconName: "Info" }}
                  onClick={() => this.setState({ datetip: true })}
                />
              </Stack>
              <ArrivalRequestFlightSelector
                {...this.props}
                styles={undefined}
                disabled={!this.withCharterChoice()}
                selectedFlight={
                  this.props.request.selectedFlight && this.props.flights
                    ? this.props.flights.filter(
                        (x) => x.id === this.props.request.selectedFlight
                      )[0]
                    : undefined
                }
                onFlightSelected={(item?: IArrivalRequestFlight) => {
                  const { request, onUpdate } = this.props;
                  onUpdate({
                    ...request,
                    selectedFlight: item ? item.id : undefined,
                  });
                }}
                onDismiss={() => {
                  /* Nothing to do for now */
                }}
              />
            </Stack>
            <Separator />
            <Stack tokens={{ childrenGap: 10 }}>
              <Text style={{ fontWeight: "bold" }}>
                {i18n.t("messages:assisted:participants")}
              </Text>
              {errors.participants && (
                <MessageBar messageBarType={MessageBarType.error}>
                  {i18n.t(errors.participants)}
                </MessageBar>
              )}
              <GridView
                {...this.props}
                styles={
                  mobile
                    ? {
                        root: {
                          marginLeft: "-12px",
                          marginRight: "-12px",
                        },
                      }
                    : undefined
                }
                embedded={true}
                selectionPreservedOnEmptyClick={true}
                onSelectionChanged={(
                  items: IArrivalRequestParticipant[],
                  selection?: Selection
                ) => {
                  items.forEach((x) => (x.selected = true));
                  const { request, onUpdate } = this.props;
                  const { participants } = request;
                  const paths = (window.event as any)?.path ?? [];
                  if (
                    paths.filter((y: any) => {
                      const res =
                        (y.className ?? "").indexOf("ms-DetailsRow-fields") !==
                          -1 ||
                        (y.className ?? "").indexOf("ms-Dropdown-") !== -1 ||
                        (y.className ?? "").indexOf("ms-TextField-") !== -1;
                      /*
                        //NOTE:  Useful when introducing new row controls because it messes selection
                        console.log(
                          `${y.className}   ::::    ${res ? "yes" : "no"}`
                        ) */

                      return res;
                    }).length > 0
                  ) {
                    participants.forEach((x) => {
                      if (x.selected) {
                        selection?.setKeySelected(x.id.toString(), true, false);
                      }
                    });
                  } else {
                    participants.forEach((x) => {
                      x.selected =
                        items.filter((y) => y.id === x.id).length > 0;
                    });
                  }
                  onUpdate({ ...request, participants: participants });
                }}
                columns={[
                  {
                    key: "name",
                    name: "messages:assisted:newdeparture:participant",
                    minWidth: undefined,
                    onRenderCell: (item: IArrivalRequestParticipant) => {
                      return (
                        <Stack tokens={{ childrenGap: 2 }}>
                          <Text>{item.name}</Text>
                          <Text variant={"small"}>{item.code}</Text>
                          <Text variant={"small"}>{item.date}</Text>
                        </Stack>
                      );
                    },
                  },
                  /*
                  {
                    key: "recall",
                    name: "messages:assisted:newdeparture:recall",
                    minWidth: 220,
                    onRenderCell: (item: IArrivalRequestParticipant) => {
                      return item?.selected ? (
                        <Dropdown
                          options={recalls}
                          selectedKey={
                            item?.recall === undefined || item.recall === null
                              ? undefined
                              : item.recall.toString()
                          }
                          errorMessage={
                            item.errors ? item.errors.recall : undefined
                          }
                          onChange={(
                            ev: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                          ) => {
                            ev?.preventDefault &&
                              typeof ev?.preventDefault === "function" &&
                              ev?.preventDefault();
                            ev?.stopPropagation &&
                              typeof ev?.stopPropagation === "function" &&
                              ev?.stopPropagation();
                            const { request, onUpdate } = this.props;
                            const { participants } = request;
                            option &&
                              onUpdate({
                                ...request,
                                participants: participants.map((x) => {
                                  if (x.id === item.id) {
                                    x.recall = parseInt(option.key.toString());
                                    x.recallName = option.text;
                                  }
                                  return x;
                                }),
                              });
                          }}
                          placeholder={i18n.t(
                            "messages:assisted:newdeparture:select"
                          )}
                        />
                      ) : (
                        <Text>{"-"}</Text>
                      );
                    },
                  },
                  {
                    key: "reason",
                    name: "messages:assisted:newdeparture:reasonrecall",
                    minWidth: 160,
                    onRenderCell: (item: IArrivalRequestParticipant) => {
                      return item.selected ? (
                        <TextField
                          maxLength={100}
                          value={item.reason ?? ""}
                          onChange={(
                            _ev: React.FormEvent<
                              HTMLInputElement | HTMLTextAreaElement
                            >,
                            newValue?: string
                          ) => {
                            const { request, onUpdate } = this.props;
                            const { participants } = request;
                            item.reason = newValue ?? "";
                            participants.forEach((x) => {
                              if (x.id === item.id) {
                                x.reason = newValue ?? "";
                              }
                            });
                            onUpdate({
                              ...request,
                              participants: participants,
                            });
                          }}
                        />
                      ) : (
                        <Text>{"-"}</Text>
                      );
                    },
                  },
                  {
                    key: "city",
                    name: "messages:assisted:newdeparture:destinationcity",
                    minWidth: 175,
                    onRenderCell: (item: IArrivalRequestParticipant) => {
                      const unknown: IDropdownOption = {
                        key: "-1",
                        text: i18n.t("messages:assisted:newdeparture:none"),
                      };
                      const destinations = item.cities
                        ? item.cities
                            .map((x) => {
                              const i: IDropdownOption = {
                                key: (x.key ?? "").toString(),
                                text: x.name,
                              };
                              return i;
                            })
                            .concat([unknown])
                        : [unknown];
                      return item.selected ? (
                        <Dropdown
                          onChange={(
                            ev: React.FormEvent<HTMLDivElement>,
                            option?: IDropdownOption
                          ) => {
                            ev?.preventDefault &&
                              typeof ev?.preventDefault === "function" &&
                              ev?.preventDefault();
                            ev?.stopPropagation &&
                              typeof ev?.stopPropagation === "function" &&
                              ev?.stopPropagation();
                            const { request, onUpdate } = this.props;
                            const { participants } = request;
                            option &&
                              onUpdate({
                                ...request,
                                participants: participants.map((x) => {
                                  if (x.id === item.id) {
                                    x.city = parseInt(option.key.toString());
                                  }
                                  return x;
                                }),
                              });
                          }}
                          selectedKey={
                            item.city === undefined || item.city === null
                              ? undefined
                              : item.city.toString()
                          }
                          options={destinations}
                          placeholder={i18n.t(
                            "messages:assisted:newdeparture:select"
                          )}
                        />
                      ) : (
                        <Text>{"-"}</Text>
                      );
                    },
                  }, */
                ]}
                onRenderTile={(
                  item: IArrivalRequestParticipant,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <ArrivalAssistedParticipantCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onSelected={(item: IArrivalRequestParticipant) => {
                        ensureSelection(selection, item);
                        item.selected = !item.selected;
                        selection.setKeySelected(
                          item.key,
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length === 0,
                          false
                        );
                        callback(selection);
                      }}
                    />
                  );
                }}
                onDataPaging={() => {}}
                options={GridViewOptionsBuilder.getOptions(
                  mobile ? GridViewMode.tiles : GridViewMode.list,
                  "name",
                  false,
                  !requestId || participantsFetched
                )}
                emptyMessage={i18n.t(
                  requestId && participantsFetched && participants.length === 0
                    ? "messages:assisted:participants:arrivee:noparticipants"
                    : "messages:assisted:participants:emptymessage"
                )}
                items={participants}
                isItemSelected={(item: IArrivalRequestParticipant) => {
                  return item.selected;
                }}
              />
              <div style={{ height: "20px" }}></div>
            </Stack>
          </Stack>
        </Stack>
        <AuthorizationWarningPanel
          {...this.props}
          warning={warning}
          onDismiss={() => this.setState({ warning: false })}
        />
        <AuthorizationConsentPanel
          {...this.props}
          styles={undefined}
          onconsent={onconsent}
          consenterror={consenterror}
          onConsent={(consentname: string) => {
            const { request, onUpdate } = this.props;
            onUpdate({ ...request, consentname: consentname, preauth: true });
            this.setState({
              consent: true,
              consenterror: undefined,
              onconsent: false,
            });
          }}
          onDismiss={() => {
            const { request } = this.props;
            onUpdate({ ...request, preauth: false });
            this.setState({
              onconsent: false,
              consenterror: undefined,
            });
          }}
          onError={(error: string) => {
            this.setState({
              consenterror: error,
            });
          }}
        />
      </div>
    );
  }
}
