import { styled } from "@fluentui/react/lib/Utilities";
import { IAlertsControlProps } from "./AlertsControl.types";
import {
  getStyles,
  IAlertsControlStyleProps,
  IAlertsControlStyles,
} from "./AlertsControl.styles";
import { AlertsControlComponent } from "./AlertsControl.base";

/**
 * AlertsControl description
 */
export const AlertsControl = styled<
  IAlertsControlProps,
  IAlertsControlStyleProps,
  IAlertsControlStyles
>(AlertsControlComponent, getStyles);
