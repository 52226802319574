import Moment from "moment";
import { Text, ITextProps, TooltipHost } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { IStyle } from "@fluentui/react";

export interface IDateTextProperties extends ITextProps {
  date: Date;
  tooltipFormat?: string;
  tooltipStyle?: IStyle;
}

class DateText extends React.Component<IDateTextProperties> {
  render(): JSX.Element {
    const { date, tooltipFormat, tooltipStyle } = this.props;
    return tooltipFormat ? (
      <TooltipHost
        styles={
          tooltipStyle
            ? {
                root: tooltipStyle,
              }
            : undefined
        }
        content={i18n.formatString(
          i18n.t(tooltipFormat),
          Moment(date).format("YYYY-MM-DD HH:mm")
        )}
      >
        <Text {...this.props}>{Moment(date).format("YYYY-MM-DD HH:mm")}</Text>
      </TooltipHost>
    ) : (
      <Text {...this.props}>{Moment(date).format("YYYY-MM-DD HH:mm")}</Text>
    );
  }
}

export default DateText;
