import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingParticipantsGridViewProps } from "./FlightBookingParticipantsGridView.types";
import {
  getStyles,
  IFlightBookingParticipantsGridViewStyleProps,
  IFlightBookingParticipantsGridViewStyles,
} from "./FlightBookingParticipantsGridView.styles";
import { FlightBookingParticipantsGridViewComponent } from "./FlightBookingParticipantsGridView.base";

/**
 * FlightBookingParticipantsGridView
 */
export const FlightBookingParticipantsGridView = styled<
  IFlightBookingParticipantsGridViewProps,
  IFlightBookingParticipantsGridViewStyleProps,
  IFlightBookingParticipantsGridViewStyles
>(FlightBookingParticipantsGridViewComponent, getStyles);
