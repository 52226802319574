import {
  CommandBar,
  ICommandBarItemProps,
  Persona,
  PersonaPresence,
  PersonaSize,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getUserContactCardClassNames } from "./UserContactCard.styles";
import {
  IUserContactCardProps,
  IUserContactCardState,
} from "./UserContactCard.types";

export class UserContactCardComponent extends React.Component<
  IUserContactCardProps,
  IUserContactCardState
> {
  constructor(props: IUserContactCardProps) {
    super(props);
    this.state = {
      expanded: false,
    };
  }

  /*
  private _onRenderItem = (item: IOverflowSetItemProps): JSX.Element => {
    const click: () => void =
      item.key === "expand"
        ? () => this.setState({ expanded: !this.state.expanded })
        : () => this.setState({ tootip: item.text });

    return (
      <CommandBarButton
        role="menuitem"
        alt={item.text}
        aria-label={item.text}
        onMouseOver={
          this.props.mobile || this.state.expanded
            ? undefined
            : () => this.setState({ tootip: item.text })
        }
        onMouseOut={
          this.props.mobile || this.state.expanded
            ? undefined
            : () => this.setState({ tootip: undefined })
        }
        onClick={click}
        styles={{ root: { padding: "10px" } }}
        iconProps={item.iconProps}
        href={item.href}
        text={this.props.mobile || this.state.expanded ? item.text : undefined}
      />
    );
  };
  */

  render(): JSX.Element {
    const { styles, user } = this.props;
    const [classNames] = getUserContactCardClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const items: ICommandBarItemProps[] = [];
    if (user.email && user.email.length > 0) {
      items.push({
        key: "email",
        iconProps: { iconName: "Mail" },
        text: i18n.t("contacts:email"),
        style: { display: "none" },
        href: `mailto:${user.email}`,
      });
      items.push({
        key: "conversation",
        iconProps: { iconName: "CommentAdd" },
        text: i18n.t("contacts:communication"),
        onClick: () => {
          this.props.route.history.push("/messages", {
            recipients: [user],
          });
        },
      });
    }

    return (
      <div className={classNames.root}>
        <div className={classNames.header}>
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            primaryText={user.name}
            secondaryText={user.email}
            title={""}
            tertiaryText={"Agent"}
            size={PersonaSize.size72}
            presence={PersonaPresence.none}
            imageAlt={user.name}
          />
        </div>
        <div className={classNames.inner}>
          <CommandBar styles={{ root: { paddingLeft: "2px" } }} items={items} />
          <div className={classNames.footer}></div>
          <div></div>
        </div>
      </div>
    );
  }
}
