import { styled } from "@fluentui/react/lib/Utilities";
import { IAuthorizationConsentPanelProps } from "./AuthorizationConsentPanel.types";
import {
  getStyles,
  IAuthorizationConsentPanelStyleProps,
  IAuthorizationConsentPanelStyles,
} from "./AuthorizationConsentPanel.styles";
import { AuthorizationConsentPanelComponent } from "./AuthorizationConsentPanel.base";

/**
 * AuthorizationConsentPanel
 */
export const AuthorizationConsentPanel = styled<
  IAuthorizationConsentPanelProps,
  IAuthorizationConsentPanelStyleProps,
  IAuthorizationConsentPanelStyles
>(AuthorizationConsentPanelComponent, getStyles);
