import {
  DefaultPalette,
  Persona,
  PersonaSize,
  Pivot,
  PivotItem,
} from "@fluentui/react";
import * as React from "react";
import { objectId } from "src/models/IObjectReference";
import { IParticipantAddressModel } from "src/models/Participants/IParticipantDetails";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import ParticipantsService from "src/services/Participants/ParticipantsService";
import { uniqueId } from "src/utils/uniqueId";
import { ParticipantAddressPanel } from "../ParticipantAddressPanel";
import { ParticipantDocumentsControl } from "../ParticipantDocumentsControl";
import { ParticipantHistoryControl } from "../ParticipantHistoryControl";
import { ParticipantPersonalInfo } from "../ParticipantPersonalInfo";
import { ParticipantPositionsControl } from "../ParticipantPositionsControl";
import { ParticipantWorkInfo } from "../ParticipantWorkInfo";
import { getParticipantDetailsPageClassNames } from "./ParticipantDetailsPage.styles";
import {
  IParticipantDetailsPageProps,
  IParticipantDetailsPageState,
} from "./ParticipantDetailsPage.types";

const themeIndex: number = 0;

export class ParticipantDetailsPageComponent extends React.Component<
  IParticipantDetailsPageProps,
  IParticipantDetailsPageState
> {
  constructor(props: IParticipantDetailsPageProps) {
    super(props);
    this.state = {
      tab: "0",
      participantId: parseInt(this.props.route.match?.params.id),
    };
    ParticipantsService.getParticipant(
      objectId(this.props.enterprise),
      this.state.participantId
    ).then((x) => {
      this.setState({
        participant: x,
      });
    });
  }

  /**
   * Trigger the save command for the participant address
   * @param `IAddress` address of the participant
   */
  public onSave = (address: IParticipantAddressModel): Promise<void> => {
    return ParticipantsService.saveAddress(
      objectId(this.props.enterprise),
      this.state.participantId,
      address
    ).then((_x) => {
      const { participant } = this.state;
      if (participant) {
        participant.address = address;
        participant.email = address.email;
        participant.phone = address.phone;
        participant.cellphone = address.cellphone;
        this.setState({
          participant: participant,
          tab: "0",
        });
      }
    });
  };

  render(): JSX.Element {
    const { styles, mobile, route } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantDetailsPageClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const enterpriseSize: PersonaSize = mobile
      ? PersonaSize.size56
      : PersonaSize.size100;

    const themeColor = [DefaultPalette.black];
    const themeImageSize = ["100% 150%"];
    const themeOpacity = ["0.7"];
    const candEdit: boolean = AuthService.hasEditPartcipantPermission();
    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        <div
          style={{
            minHeight: "20vh",
            position: "relative",
            width: "100%",
          }}
        >
          <div
            className="EnterpriseBackground"
            style={{
              opacity: themeOpacity[themeIndex],
              backgroundImage: "url('/img/banner_9.jpg')",
              backgroundSize: themeImageSize[themeIndex],
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div
            className="WhitePersona"
            style={{
              position: "absolute",
              padding: "10px 10px",
              width: "95%",
              left: 0,
              bottom: 0,
            }}
          >
            <Persona
              initialsColor={"rgb(122, 117, 116)"}
              text={this.state.participant?.name}
              styles={{
                primaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
                secondaryText: {
                  fontWeight: "bold",
                  color: themeColor[themeIndex],
                },
              }}
              color={themeColor[themeIndex]}
              secondaryText={this.state.participant?.code.toString()}
              size={enterpriseSize}
            />
          </div>
          <div
            data-note="top-right-section"
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              padding: "4px",
            }}
          ></div>
          <div></div>
        </div>
        <div
          style={{
            width: "100%",
            minHeight: "500px",
            backgroundColor: DefaultPalette.neutralLighter,
          }}
        >
          <Pivot
            styles={subComponentStyles?.pivot}
            selectedKey={this.state.tab.toString()}
            onLinkClick={(item?: PivotItem) => {
              const newTab =
                item?.props.itemKey === undefined ? "0" : item.props.itemKey;
              if (newTab === "99") route.history.push("/participants");
              else {
                this.setState({
                  tab: newTab,
                });
              }
            }}
          >
            <PivotItem
              headerText={i18n.t("participants:participant")}
              itemKey={"0"}
              key={"0"}
            >
              <div className="ms-Grid" style={{ paddingTop: "10px" }} dir="ltr">
                <div className={classNames.msGridRow}>
                  {this.state.participant && (
                    <>
                      <ParticipantPersonalInfo
                        {...this.props}
                        styles={undefined}
                        participant={this.state.participant}
                        onEditParticipant={() => this.setState({ tab: "1" })}
                      />
                      <ParticipantWorkInfo
                        {...this.props}
                        styles={undefined}
                        participant={this.state.participant}
                      />
                    </>
                  )}
                </div>
              </div>
            </PivotItem>

            {candEdit && (
              <PivotItem
                headerText={i18n.t("participants:address")}
                itemKey={"1"}
                key={"1"}
              >
                <div
                  className="ms-Grid"
                  style={{ marginTop: "10px" }}
                  dir="ltr"
                >
                  <div className={classNames.msGridRow}>
                    {this.state.participant && (
                      <ParticipantAddressPanel
                        {...this.props}
                        styles={undefined}
                        onSave={this.onSave}
                        onDismiss={() => this.setState({ tab: "0" })}
                        participant={this.state.participant}
                      />
                    )}
                  </div>
                </div>
              </PivotItem>
            )}

            <PivotItem
              headerText={i18n.t("participants:documents")}
              itemKey={"2"}
              key={"2"}
            >
              {this.state.tab.toString() === "2" && (
                <ParticipantDocumentsControl
                  {...this.props}
                  styles={undefined}
                  scrollableTargetId={id}
                  participantId={this.state.participantId}
                />
              )}
            </PivotItem>

            <PivotItem
              headerText={i18n.t("participants:positions")}
              itemKey={"3"}
              key={"3"}
            >
              {this.state.tab.toString() === "3" && (
                <ParticipantPositionsControl
                  {...this.props}
                  styles={undefined}
                  scrollableTargetId={id}
                  participantId={this.state.participantId}
                />
              )}
            </PivotItem>

            <PivotItem
              headerText={i18n.t("participants:history")}
              itemKey={"4"}
              key={"4"}
            >
              {this.state.tab.toString() === "4" && (
                <ParticipantHistoryControl
                  {...this.props}
                  styles={undefined}
                  scrollableTargetId={id}
                  participantId={this.state.participantId}
                />
              )}
            </PivotItem>

            <PivotItem
              headerText={i18n.t("participants:back")}
              itemKey={"99"}
              key={"99"}
            ></PivotItem>
          </Pivot>
        </div>
      </div>
    );
  }
}
