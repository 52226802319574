import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IDossierImmigrationCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IDossierImmigrationCardStyles
  extends IAmigoBaseStyles<IDossierImmigrationCardSubComponentStyles> {}

export interface IDossierImmigrationCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDossierImmigrationCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IDossierImmigrationCardStyleProps,
    IDossierImmigrationCardStyles
  >,
  props?: IDossierImmigrationCardStyleProps
): [
  IProcessedStyleSet<IDossierImmigrationCardStyles>,
  IDossierImmigrationCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDossierImmigrationCardStyleProps,
  IDossierImmigrationCardStyles
> = (
  props: IDossierImmigrationCardStyleProps
): IDossierImmigrationCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
    },
  };
};
