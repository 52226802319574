import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantAddressPanelProps } from "./ParticipantAddressPanel.types";
import {
  getStyles,
  IParticipantAddressPanelStyleProps,
  IParticipantAddressPanelStyles,
} from "./ParticipantAddressPanel.styles";
import { ParticipantAddressPanelComponent } from "./ParticipantAddressPanel.base";

/**
 * ParticipantAddressPanel description
 */
export const ParticipantAddressPanel = styled<
  IParticipantAddressPanelProps,
  IParticipantAddressPanelStyleProps,
  IParticipantAddressPanelStyles
>(ParticipantAddressPanelComponent, getStyles);
