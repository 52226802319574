import {
  CommandBarButton,
  DefaultPalette,
  Icon,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getError500PageClassNames } from "./Error500Page.styles";
import { IError500PageProps, IError500PageState } from "./Error500Page.types";

export class Error500PageComponent extends React.Component<
  IError500PageProps,
  IError500PageState
> {
  private getRouteId = (): string => {
    const { route } = this.props;
    if (route?.location?.state) {
      const st = route?.location?.state as any;
      if (st?.id) {
        return st.id;
      }
    }
    return "0x00000ff";
  };

  private getRouteMessage = () => {
    const { route } = this.props;
    if (route?.location?.state) {
      const st = route?.location?.state as any;
      if (st?.message) {
        return st.message;
      }
    }
    return i18n.t("error:500:message:default");
  };

  private getRouteDate = (): Date | string | undefined => {
    const { route } = this.props;
    if (route?.location?.state) {
      const st = route?.location?.state as any;
      if (st?.date) {
        return st.date;
      }
    }
    return new Date();
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getError500PageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <Stack
        className={classNames.root}
        verticalFill
        horizontal={!this.props.mobile}
        verticalAlign={"stretch"}
        horizontalAlign={"stretch"}
      >
        <Stack.Item verticalFill align={"center"} grow={1}>
          <div style={{ padding: this.props.mobile ? "30px" : "120px" }}>
            <h1>:(</h1>
            <h1>{i18n.t("error:500:title")}</h1>
            <br />
            <div>
              <Text>{`${i18n.t("error:500:id")}: ${this.getRouteId()}`}</Text>
            </div>
            <div>
              <Text>
                {`${i18n.t("error:500:message")}: ${this.getRouteMessage()}`}
              </Text>
            </div>
            <div>
              <Text>
                {`${i18n.t("error:500:date")}:${this.getRouteDate()}`}
              </Text>
            </div>
            <br />
            <CommandBarButton
              iconProps={{
                iconName: "NavigateBack",
                style: {
                  fontSize: "28px",
                },
              }}
              styles={{
                root: {
                  paddingTop: "2px",
                  fontSize: "28px",
                  color: DefaultPalette.themePrimary,
                },
                textContainer: {
                  fontSize: "26px",
                  marginLeft: "10px",
                },
              }}
              text={i18n.t("error:500:back")}
              onRenderText={() => {
                return <Text>{i18n.t("error:500:back")}</Text>;
              }}
              onClick={() => {
                document.location.href = "/";
              }}
            />
          </div>
        </Stack.Item>
        <Stack.Item verticalFill align={"center"} grow={1}>
          <div style={{ padding: this.props.mobile ? "30px" : "120px" }}>
            <Stack horizontal>
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
            </Stack>
            <Stack horizontal>
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
            </Stack>
            <Stack horizontal>
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
              <Icon iconName="error" className={classNames.icon} />
            </Stack>
          </div>
        </Stack.Item>
      </Stack>
    );
  }
}
