import { styled } from "@fluentui/react/lib/Utilities";
import { IDocumentsPageProps } from "./DocumentsPage.types";
import {
  getStyles,
  IDocumentsPageStyleProps,
  IDocumentsPageStyles,
} from "./DocumentsPage.styles";
import { DocumentsPageComponent } from "./DocumentsPage.base";

/**
 * DocumentsPage
 */
export const DocumentsPage = styled<
  IDocumentsPageProps,
  IDocumentsPageStyleProps,
  IDocumentsPageStyles
>(DocumentsPageComponent, getStyles);
