import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IAlertBannerSubComponentStyles {}

export interface IAlertBannerStyles
  extends IAmigoBaseStyles<IAlertBannerSubComponentStyles> {}

export interface IAlertBannerStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAlertBannerClassNames = (
  styles?: IStyleFunctionOrObject<IAlertBannerStyleProps, IAlertBannerStyles>,
  props?: IAlertBannerStyleProps
): [
  IProcessedStyleSet<IAlertBannerStyles>,
  IAlertBannerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAlertBannerStyleProps,
  IAlertBannerStyles
> = (_props: IAlertBannerStyleProps): IAlertBannerStyles => {
  return {
    root: ["amigo-AlertBanner", {}],
  };
};
