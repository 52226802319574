import { Icon, TooltipHost } from "@fluentui/react";
import * as React from "react";
import { getIconHeaderClassNames } from "./IconHeader.styles";
import { IIconHeaderProps, IIconHeaderState } from "./IconHeader.types";

export class IconHeaderComponent extends React.Component<
  IIconHeaderProps,
  IIconHeaderState
> {
  public render() {
    const { styles, as: RootType = "h2" } = this.props;
    const [classNames, subComponentStyles] = getIconHeaderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    // const as = this.props.as ?? <h2></h2>;

    return (
      <div className={classNames.root}>
        <div className={classNames.iconContainer}>
          <RootType className={classNames.text}>
            <TooltipHost
              className={classNames.iconTooltip}
              content={this.props.title}
            >
              <Icon
                className={classNames.icon}
                styles={subComponentStyles?.icon}
                iconName={this.props.iconName}
              />
            </TooltipHost>
          </RootType>
        </div>
        <div className={classNames.textContainer}>
          <h2 className={classNames.text}>{this.props.title}</h2>
        </div>
        <div className={classNames.end}></div>
      </div>
    );
  }
}
