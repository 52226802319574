import * as React from "react";
import { getAppEnterpriseMenuClassNames } from "./AppEnterpriseMenu.styles";
import {
  IAppEnterpriseMenuProps,
  IAppEnterpriseMenuState,
} from "./AppEnterpriseMenu.types";
import {
  TooltipHost,
  IconButton,
  IContextualMenuItem,
  ContextualMenuItemType,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import AuthService from "src/services/AuthService";

export class AppEnterpriseMenuComponent extends React.Component<
  IAppEnterpriseMenuProps,
  IAppEnterpriseMenuState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getAppEnterpriseMenuClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const _this = this;
    const session = AuthService.getUserInfo();
    if (session === null) return <div></div>;
    const enterprises: IContextualMenuItem[] = [];
    session.enterprises.forEach((x) => {
      enterprises.push({
        key: x.key ? x.key.toString() : "",
        text: x.name,
        iconProps: {
          iconName: "Work",
        },
        style: {
          fontWeight: this.props.enterprise.key === x.key ? "bold" : "normal",
        },
        onClick: () => {
          _this.props.onEnterpriseChanged(x);
          this.props.route.history.push("/");
          _this.setState({ redirect: true });
        },
      });
      enterprises.push({
        key: "divider_" + (x.key ? x.key.toString() : ""),
        itemType: ContextualMenuItemType.Divider,
      });
    });
    return (
      <>
        {session.enterprises.length > 1 && (
          <TooltipHost content={i18n.t("menu:enterprises")}>
            <IconButton
              data-automation-id="enterpriseSelector"
              className={classNames.iconButton}
              iconProps={{ iconName: "Work", className: classNames.iconButton }}
              menuIconProps={{
                style: { display: "none" },
                iconName: undefined,
              }}
              menuProps={{
                shouldFocusOnMount: true,
                items: enterprises,
              }}
            />
          </TooltipHost>
        )}
      </>
    );
  }
}
