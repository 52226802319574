import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IUnauthenticatedLayoutStyleProps,
  IUnauthenticatedLayoutStyles,
} from "src/controls/UnauthenticatedLayout";

export interface IResetPasswordPageSubComponentStyles {
  layout?: IStyleFunctionOrObject<
    IUnauthenticatedLayoutStyleProps,
    IUnauthenticatedLayoutStyles
  >;
}

export interface IResetPasswordPageStyles
  extends IAmigoBaseStyles<IResetPasswordPageSubComponentStyles> {}

export interface IResetPasswordPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getResetPasswordPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IResetPasswordPageStyleProps,
    IResetPasswordPageStyles
  >,
  props?: IResetPasswordPageStyleProps
): [
  IProcessedStyleSet<IResetPasswordPageStyles>,
  IResetPasswordPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IResetPasswordPageStyleProps,
  IResetPasswordPageStyles
> = (_props: IResetPasswordPageStyleProps): IResetPasswordPageStyles => {
  return {};
};
