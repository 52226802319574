import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import Cards from "react-credit-cards";
import PaymentsService from "src/services/Billing/PaymentsService";
import i18n from "src/services/i18n";
import { getNewPaymentMethodSummaryClassNames } from "./NewPaymentMethodSummary.styles";
import {
  INewPaymentMethodSummaryProps,
  INewPaymentMethodSummaryState,
} from "./NewPaymentMethodSummary.types";

export class NewPaymentMethodSummaryComponent extends React.Component<
  INewPaymentMethodSummaryProps,
  INewPaymentMethodSummaryState
> {
  render(): JSX.Element {
    const { styles, mobile, paymentMethod, payment } = this.props;
    const [classNames] = getNewPaymentMethodSummaryClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <h1 style={{ marginTop: 0 }}>
          {i18n.t("billing:payments:newpayment:confirm")}
        </h1>
        <MessageBar messageBarType={MessageBarType.info}>
          {i18n.formatString(
            i18n.t("billing:payments:newpayment:confirm:instructions"),
            payment.toFixed(2)
          )}
        </MessageBar>
        <div
          style={{ width: "320px", padding: mobile ? "10px 0" : "30px 5px" }}
        >
          <Cards
            {...i18n.CreditCardStrings()}
            cvc={""}
            preview ={true}
            expiry={
              paymentMethod === undefined
                ? ""
                : `${
                    paymentMethod.expirationMonth < 10
                      ? "0" + paymentMethod.expirationMonth.toString()
                      : paymentMethod.expirationMonth.toString()
                  }/${paymentMethod.expirationYear}`
            }
            focused={"number"}
            name={paymentMethod.name ?? ""}
            number={PaymentsService.formatCreditCard(paymentMethod.card ?? "")}
          />
        </div>
      </div>
    );
  }
}
