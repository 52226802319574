import * as React from "react";
import { ISessionProfile } from "src/models/ISessionProfile";
import AuthService from "src/services/AuthService";
import { getProfileInfoClassNames } from "./ProfileInfo.styles";
import { IProfileInfoState, IProfileInfoProps } from "./ProfileInfo.types";
import {
  Stack,
  StackItem,
  IconButton,
  Text,
  ContextualMenuItemType,
  TextField,
  TooltipHost,
} from "@fluentui/react";
import { LabelField } from "src/components/LabelField";
import i18n from "src/services/i18n";
import { ProfileService } from "src/services/ProfileService";

export class ProfileInfoComponent extends React.Component<
  IProfileInfoProps,
  IProfileInfoState
> {
  constructor(props: IProfileInfoProps) {
    super(props);
    this.state = {
      editcellphone: false,
    };
  }

  renderButtons() {
    const session: ISessionProfile | null = AuthService.getUserInfo();
    const cellphone = session?.cellphone ?? "";
    return (
      <>
        {this.state.editcellphone && (
          <TooltipHost content={i18n.t("enterprises:neq:saveButton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Save" }}
              styles={{
                root: {
                  margin: "2px 5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                if (this.state.newcell && this.state.newcell.length > 8) {
                  ProfileService.updateCellphone(this.state.newcell)
                    .then((_x) => {
                      if (this.state.newcell !== undefined) {
                        AuthService.updateCellphone(this.state.newcell);
                      }
                      this.setState({ editcellphone: false });
                    })
                    .catch((_x) => {
                      this.setState({ editcellphone: false });
                    });
                } else {
                  this.setState({ editcellphone: false });
                }
              }}
            />
          </TooltipHost>
        )}
        {this.state.editcellphone && (
          <TooltipHost content={i18n.t("enterprises:neq:cancelButton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Cancel" }}
              styles={{
                root: {
                  margin: "2px 5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                this.setState({ newcell: cellphone, editcellphone: false });
              }}
            />
          </TooltipHost>
        )}
        {!this.state.editcellphone && (
          <TooltipHost content={i18n.t("enterprises:neq:editBouton:title")}>
            <IconButton
              style={{
                marginLeft: "5px",
                padding: "0",
                height: "16px",
                width: "16px",
              }}
              iconProps={{ iconName: "Edit" }}
              styles={{
                root: {
                  marginLeft: "5px",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
                rootHovered: {
                  backgroundColor: "transparent",
                },
                flexContainer: {
                  margin: "0",
                  padding: "0",
                  height: "16px",
                  width: "16px",
                },
              }}
              onClick={() => {
                this.setState({ editcellphone: true });
              }}
            />
          </TooltipHost>
        )}
      </>
    );
  }

  private onCellphoneChanged = (
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    const maxLength = 25;
    if (!newValue || newValue.length <= maxLength) {
      this.setState({ newcell: newValue });
    } else {
      this.setState({ editcellphone: this.state.editcellphone });
    }
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getProfileInfoClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const session: ISessionProfile | null = AuthService.getUserInfo();
    const cellphone = session?.cellphone ?? "";
    if (session === null) return <div></div>;

    return (
      <div className={classNames.root}>
        <Stack horizontal tokens={{ childrenGap: 10 }}>
          <StackItem grow>
            <Stack tokens={{ childrenGap: 10 }}>
              <LabelField
                {...this.props}
                styles={undefined}
                iconProps={{ iconName: "UserFollowed" }}
                label={i18n.t("profile:name")}
                content={session.firstName + " " + session.lastName}
              />
              <LabelField
                {...this.props}
                styles={undefined}
                iconProps={{ iconName: "Mail" }}
                label={i18n.t("profile:email")}
                content={session.email}
              />
              <LabelField
                {...this.props}
                iconProps={{ iconName: "Phone" }}
                styles={undefined}
                label={i18n.t("contacts:cellphone")}
                content={
                  <>
                    {this.state.editcellphone && (
                      <Stack horizontal>
                        <TextField
                          styles={{ fieldGroup: { height: "24px" } }}
                          style={{ fontSize: "13px" }}
                          maxLength={15}
                          onChange={this.onCellphoneChanged}
                          value={this.state.newcell}
                          defaultValue={this.state.newcell ?? cellphone}
                        />
                        {this.renderButtons()}
                      </Stack>
                    )}
                    {!this.state.editcellphone && (
                      <Stack horizontal>
                        <Text style={{ width: "125px" }}>
                          {this.state.newcell ?? cellphone}
                        </Text>
                        {this.renderButtons()}
                      </Stack>
                    )}
                  </>
                }
              />
            </Stack>
          </StackItem>
          <StackItem grow>
            <Stack tokens={{ childrenGap: 10 }}>
              <LabelField
                {...this.props}
                styles={undefined}
                iconProps={{ iconName: "UserFollowed" }}
                label={i18n.t("profile:username")}
                content={session.username.toLowerCase()}
              />
              <LabelField
                {...this.props}
                styles={undefined}
                iconProps={{ iconName: "LocaleLanguage" }}
                label={i18n.t("profile:language")}
                content={
                  <Stack horizontal tokens={{ childrenGap: 10 }}>
                    <Text>{i18n.t("language:" + this.props.language)}</Text>
                    <IconButton
                      menuIconProps={{
                        iconName: undefined,
                        style: { display: "none" },
                      }}
                      menuProps={{
                        shouldFocusOnMount: true,
                        items: [
                          {
                            key: "english",
                            text: i18n.t("language:en"),
                            style: {
                              fontWeight:
                                this.props.language === "en"
                                  ? "bold"
                                  : "normal",
                            },
                            onClick: () => this.props.onLanguageChanged("en"),
                          },
                          {
                            key: "divider_1",
                            itemType: ContextualMenuItemType.Divider,
                          },
                          {
                            key: "french",
                            text: i18n.t("language:fr"),
                            style: {
                              fontWeight:
                                this.props.language === "fr"
                                  ? "bold"
                                  : "normal",
                            },
                            onClick: () => this.props.onLanguageChanged("fr"),
                          },
                          {
                            key: "divider_1",
                            itemType: ContextualMenuItemType.Divider,
                          } /*,
                              {
                                  key: 'spanish',
                                  text: i18n.t('language:es'),
                                  style: {
                                    fontWeight: this.props.language === "fr" ? "bold" : "normal"
                                  },
                                  onClick: () => this.onLanguageChanged('es')
                              } */,
                        ],
                      }}
                      iconProps={{ iconName: "Save" }}
                    />
                  </Stack>
                }
              />
            </Stack>
          </StackItem>
        </Stack>
      </div>
    );
  }
}
