import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IRequestParticipantItemStyleProps,
  IRequestParticipantItemStyles,
} from "../RequestParticipantItem";
import {
  IEntityPanelStyles,
  IEntityPanelStyleProps,
} from "src/components/EntityPanel";
import {
  IPeriodPositionCountTextStyleProps,
  IPeriodPositionCountTextStyles,
} from "../PeriodPositionCountText";
import { IPivotStyles } from "@fluentui/react";

export interface IRequestPeriodPageSubComponentStyles {
  pivot?: IPivotStyles;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  entityPanel?: IStyleFunctionOrObject<
    IEntityPanelStyleProps,
    IEntityPanelStyles
  >;
  participantCount?: IStyleFunctionOrObject<
    IPeriodPositionCountTextStyleProps,
    IPeriodPositionCountTextStyles
  >;
  participantItem?: IStyleFunctionOrObject<
    IRequestParticipantItemStyleProps,
    IRequestParticipantItemStyles
  >;
}

export interface IRequestPeriodPageStyles
  extends IAmigoBaseStyles<IRequestPeriodPageSubComponentStyles> {
  msGridRow?: IStyle;
  gridCard?: IStyle;
}

export interface IRequestPeriodPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestPeriodPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestPeriodPageStyleProps,
    IRequestPeriodPageStyles
  >,
  props?: IRequestPeriodPageStyleProps
): [
  IProcessedStyleSet<IRequestPeriodPageStyles>,
  IRequestPeriodPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestPeriodPageStyleProps,
  IRequestPeriodPageStyles
> = (props: IRequestPeriodPageStyleProps): IRequestPeriodPageStyles => {
  return {
    root: ["amigo-RequestPeriodPage", props.tokens.defaultPageRootStyle],
    msGridRow: props.tokens.msGridRow,
    subComponentStyles: {
      pivot: props.tokens.defaultPivotStyles,
    },
  };
};
