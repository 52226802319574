import { styled } from "@fluentui/react/lib/Utilities";
import { IReloadPageProps } from "./ReloadPage.types";
import {
  getStyles,
  IReloadPageStyleProps,
  IReloadPageStyles,
} from "./ReloadPage.styles";
import { ReloadPageComponent } from "./ReloadPage.base";

/**
 * ReloadPage description
 */
export const ReloadPage = styled<
  IReloadPageProps,
  IReloadPageStyleProps,
  IReloadPageStyles
>(ReloadPageComponent, getStyles);
