import { styled } from "@fluentui/react/lib/Utilities";
import { IUsersPickerDialogProps } from "./UsersPickerDialog.types";
import {
  getStyles,
  IUsersPickerDialogStyleProps,
  IUsersPickerDialogStyles,
} from "./UsersPickerDialog.styles";
import { UsersPickerDialogComponent } from "./UsersPickerDialog.base";

/**
 * UsersPickerDialog description
 */
export const UsersPickerDialog = styled<
  IUsersPickerDialogProps,
  IUsersPickerDialogStyleProps,
  IUsersPickerDialogStyles
>(UsersPickerDialogComponent, getStyles);
