import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseContactItemProps } from "./EnterpriseContactItem.types";
import {
  getStyles,
  IEnterpriseContactItemStyleProps,
  IEnterpriseContactItemStyles,
} from "./EnterpriseContactItem.styles";
import { EnterpriseContactItemComponent } from "./EnterpriseContactItem.base";

/**
 * EnterpriseContactItem description
 */
export const EnterpriseContactItem = styled<
  IEnterpriseContactItemProps,
  IEnterpriseContactItemStyleProps,
  IEnterpriseContactItemStyles
>(EnterpriseContactItemComponent, getStyles);
