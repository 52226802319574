import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IParticipantPositionCardSubComponentStyles {
  topIcon?: IIconStyles;
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
}

export interface IParticipantPositionCardStyles
  extends IAmigoBaseStyles<IParticipantPositionCardSubComponentStyles> {}

export interface IParticipantPositionCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantPositionCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantPositionCardStyleProps,
    IParticipantPositionCardStyles
  >,
  props?: IParticipantPositionCardStyleProps
): [
  IProcessedStyleSet<IParticipantPositionCardStyles>,
  IParticipantPositionCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantPositionCardStyleProps,
  IParticipantPositionCardStyles
> = (
  _props: IParticipantPositionCardStyleProps
): IParticipantPositionCardStyles => {
  return {
    root: ["amigo-ParticipantPositionCard", {}],
  };
};
