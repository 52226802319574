import { TooltipHost, Icon } from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import i18n from "src/services/i18n";
import { getEnterpriseAddressClassNames } from "./EnterpriseAddress.styles";
import {
  IEnterpriseAddressProps,
  IEnterpriseAddressState,
} from "./EnterpriseAddress.types";

export class EnterpriseAddressComponent extends React.Component<
  IEnterpriseAddressProps,
  IEnterpriseAddressState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getEnterpriseAddressClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={classNames.root}>
        <IconHeader
          {...this.props}
          styles={subComponentStyles?.iconHeader}
          iconName="MapPin"
          title={i18n.t("enterprise:addresses:title")}
        />
        <div>
          <div>
            <div>
              <b>{i18n.t("enterprise:addresses:mainaddress")}</b>
            </div>
            <div>
              <div>{this.props.enterprise.address.street}</div>
              <div>
                {this.props.enterprise.address.city} (
                {this.props.enterprise.address.state})
              </div>
              <div>{this.props.enterprise.address.postalcode}</div>
              <div>{this.props.enterprise.address.country}</div>
            </div>
            <div style={{ height: "20px" }}></div>
          </div>
        </div>
        {this.props.enterprise.branches.length > 0 && (
          <div>
            <div style={{ float: "left", paddingRight: "10px" }}>
              <TooltipHost content={i18n.t("enterprise:addresses:branches")}>
                <h2>
                  <Icon
                    style={{ fontSize: "18px", marginLeft: "3px" }}
                    iconName="BranchFork2"
                  />
                </h2>
              </TooltipHost>
            </div>
            <div style={{ float: "left" }}>
              <h3>{i18n.t("enterprise:addresses:branches")}</h3>
            </div>
            <div className="clear-both"></div>
          </div>
        )}
        {this.props.enterprise.branches
          .filter((x) => x.name || x.address)
          .map((x) => {
            return (
              <div
                key={`nvx:enterprises:branch:${x.id}`}
                style={{ float: "left", paddingRight: "15px" }}
              >
                <div>
                  <div>
                    <b>{x.name}</b>
                  </div>
                  {x.address && (
                    <div>
                      <div>{x.address.street}</div>
                      <div>{`${x.address.city ?? ""} ${
                        x.address.city && x.address.city != null ? "(" : ""
                      }${x.address.state}${
                        x.address.city && x.address.city != null ? ")" : ""
                      }`}</div>
                      <div>{x.address.postalcode}</div>
                      <div>{x.address.country ?? ""}</div>
                    </div>
                  )}
                  <div style={{ height: "20px" }}></div>
                </div>
              </div>
            );
          })}
        <div className="clear-both"></div>
      </div>
    );
  }
}
