import * as React from "react";
import { getBillingPageClassNames } from "./BillingPage.styles";
import { IBillingPageState, IBillingPageProps } from "./BillingPage.types";
import { BillingControl } from "../BillingControl";
import { GenericPageHeader } from "src/components/GenericPageHeader/GenericPageHeader";
import { uniqueId } from "src/utils/uniqueId";

export class BillingPageComponent extends React.Component<
  IBillingPageProps,
  IBillingPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getBillingPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        <GenericPageHeader
          title="Facturation"
          {...this.props}
          styles={undefined}
          themeIndex={6}
        />
        <BillingControl
          {...this.props}
          scrollableTargetId={id}
          styles={subComponentStyles?.control}
        />
      </div>
    );
  }
}
