import { List, PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { IconHeader } from "src/components/IconHeader";
import { IEnterpriseContact } from "src/models/Enterprises/IEnterpriseContact";
import AuthService from "src/services/AuthService";
import EnterpriseService from "src/services/Enterprises/EnterpriseService";
import i18n from "src/services/i18n";
import { ContactModificationPanel } from "../ContactModificationPanel";
import { EnterpriseContactItem } from "../EnterpriseContactItem";
import { getEnterpriseContactsControlClassNames } from "./EnterpriseContactsControl.styles";
import {
  IEnterpriseContactsControlProps,
  IEnterpriseContactsControlState,
} from "./EnterpriseContactsControl.types";

export class EnterpriseContactsControlComponent extends React.Component<
  IEnterpriseContactsControlProps,
  IEnterpriseContactsControlState
> {
  constructor(props: IEnterpriseContactsControlProps) {
    super(props);
    this.state = {
      edit: false,
      contacts: [],
    };
    EnterpriseService.getEnterpriseContacts(
      parseInt(this.props.enterprise.key?.toString() ?? "0")
    ).then((x) => {
      this.setState({
        contacts: x,
      });
    });
  }

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getEnterpriseContactsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const canEdit: boolean = AuthService.hasEditContactPermission();
    return (
      <div className={classNames.root}>
        <IconHeader
          {...this.props}
          styles={subComponentStyles?.iconHeader}
          iconName="ConnectContacts"
          title={i18n.t("enterprise:contacts")}
        />
        <div style={{ width: "100%", margin: "5px 5px 0 5px" }}>
          <List
            items={this.state.contacts.filter((x) => !x.pending)}
            onRenderCell={(item?: IEnterpriseContact) => {
              return (
                <div
                  style={
                    tablet || mobile
                      ? { padding: "8px" }
                      : { float: "left", padding: "8px" }
                  }
                >
                  {item !== undefined && (
                    <EnterpriseContactItem
                      {...this.props}
                      styles={subComponentStyles?.contact}
                      contact={item}
                    />
                  )}
                </div>
              );
            }}
          />
          <div
            style={{
              height: `${
                tablet || mobile ? 10 : 20 + (canEdit ? 20 : 0) + 20
              }px`,
            }}
          ></div>
        </div>
        {canEdit && (
          <div style={{ padding: "20px 15px", clear: "both" }}>
            <PrimaryButton
              text={i18n.t("enterprise:contacts:submit")}
              onClick={() => this.setState({ edit: true })}
            />
          </div>
        )}
        {canEdit ? (
          <ContactModificationPanel
            onDismiss={() => {
              EnterpriseService.getEnterpriseContacts(
                parseInt(this.props.enterprise.key?.toString() ?? "0")
              )
                .then((x) => {
                  this.setState({
                    contacts: x,
                    edit: false,
                  });
                })
                .catch((_x) => {
                  this.setState({
                    edit: false,
                  });
                });
            }}
            enabled={this.state.edit}
            {...this.props}
            {...this.state}
            styles={subComponentStyles?.panel}
          />
        ) : (
          <div
            style={{ clear: "both", height: mobile ? "20px" : "30px" }}
          ></div>
        )}
      </div>
    );
  }
}
