import {
  DefaultPalette,
  FontWeights,
  PersonaCoin,
  PersonaSize,
  Stack,
  Text,
  TooltipHost,
} from "@fluentui/react";
import moment from "moment";
import * as React from "react";
import { CountryIcon } from "src/components/CountryIcon/CountryIcon";
import { GridViewCard } from "src/components/GridViewCard";
import i18n from "src/services/i18n";
import { getFlightPersonalizedOfferCardClassNames } from "./FlightPersonalizedOfferCard.styles";
import {
  IFlightPersonalizedOfferCardProps,
  IFlightPersonalizedOfferCardState,
} from "./FlightPersonalizedOfferCard.types";

export class FlightPersonalizedOfferCardComponent extends React.Component<
  IFlightPersonalizedOfferCardProps,
  IFlightPersonalizedOfferCardState
> {
  constructor(props: IFlightPersonalizedOfferCardProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, item, arrival, columnWidth } = this.props;
    const [classNames /* , subComponentStyles */] =
      getFlightPersonalizedOfferCardClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const passengers = item.items[0].passengers ?? 1;
    const footerIconName = "Airplane";
    const _this = this;
    const country = arrival
      ? item.items[0].origin === "GUA"
        ? "Guatemala"
        : "Mexique"
      : item.items[0].destination === "GUA"
      ? "Guatemala"
      : "Mexique";

    return (
      <GridViewCard
        {...this.props}
        styles={undefined}
        title={
          <>
            <Text
              className={classNames.root}
              variant="small"
              styles={{
                root: {
                  color: DefaultPalette.themePrimary,
                  fontSize: "14px",
                  fontWeight: FontWeights.bold,
                },
              }}
            >
              {item.key}
            </Text>
          </>
        }
        columnWidth={columnWidth}
        footerText={i18n.t("transports:offers:waranty")}
        footerIconName={footerIconName ?? "Pin"}
        onClick={() => {
          _this.props.onSelected && _this.props.onSelected(item);
        }}
        selectable={false}
        selectorStyle={{
          display: "none",
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            <CountryIcon {...this.props} styles={undefined} country={country} />
            <TooltipHost
              className={classNames.root}
              content={`${passengers.toString()} ${i18n.t(
                passengers > 1
                  ? "transports:requests:passengers:plural"
                  : "transports:requests:passengers:singular"
              )}`}
            >
              <PersonaCoin
                initialsColor={"rgb(122, 117, 116)"}
                imageAlt={`${passengers.toString()} ${i18n.t(
                  passengers > 1
                    ? "transports:requests:passengers:plural"
                    : "transports:requests:passengers:singular"
                )}`}
                text={passengers.toString()}
                coinSize={18}
                onRenderInitials={() => {
                  return <span>{passengers.toString()}</span>;
                }}
                aria-label={`${passengers.toString()} ${i18n.t(
                  passengers > 1
                    ? "transports:requests:passengers:plural"
                    : "transports:requests:passengers:singular"
                )}`}
                size={PersonaSize.size16}
                style={{
                  fontSize: "14px",
                }}
              />
            </TooltipHost>
          </>
        }
      >
        <Stack tokens={{ childrenGap: 10 }}>
          <Text
            styles={{
              root: {
                fontSize: "14px",
                fontWeight: FontWeights.regular,
              },
            }}
          >
            {arrival
              ? `${i18n.t(
                  "transports:offers:arrivals:card:instructions"
                )}${moment(item.items[0].requestDate).format("YYYY-MM-DD")}`
              : `${i18n.t(
                  "transports:offers:departures:card:instructions"
                )}${moment(item.items[0].requestDate).format("YYYY-MM-DD")}`}
          </Text>
          <Text
            styles={{
              root: {
                fontSize: "14px",
                fontWeight: FontWeights.regular,
              },
            }}
          >
            <Stack horizontal tokens={{ childrenGap: 10 }}>
              <PersonaCoin
                initialsColor={"rgb(122, 117, 116)"}
                imageAlt={""}
                text={item.items.length.toString()}
                coinSize={18}
                onRenderInitials={() => {
                  return <span>{item.items.length.toString()}</span>;
                }}
                aria-label={""}
                size={PersonaSize.size24}
                style={{
                  fontSize: "14px",
                }}
              />
              <Text>
                {i18n.t(
                  item.items.length > 1
                    ? "transports:offers:itenarytext:plural"
                    : "transports:offers:itenarytext:singular"
                )}
              </Text>
            </Stack>
          </Text>
        </Stack>
      </GridViewCard>
    );
  }
}
