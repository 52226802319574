import { IProcessedStyleSet } from "@fluentui/react/lib/Styling";
import { IStyleFunctionOrObject } from "@fluentui/react/lib/Utilities";
import {
  IAmigoBaseStyleProps,
  getComponentClassNames,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IMasterPageStyleProps,
  IMasterPageStyles,
} from "src/components/MasterPage/MasterPage.styles";

export interface IAppRouterSubComponentStyles {
  masterPage?: IStyleFunctionOrObject<IMasterPageStyleProps, IMasterPageStyles>;
}

export interface IAppRouterStyles
  extends IAmigoBaseStyles<IAppRouterSubComponentStyles> {}

export interface IAppRouterStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAppRouterClassNames = (
  styles?: IStyleFunctionOrObject<IAppRouterStyleProps, IAppRouterStyles>,
  props?: IAppRouterStyleProps
): [
  IProcessedStyleSet<IAppRouterStyles>,
  IAppRouterSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles = (_props: IAppRouterStyleProps): IAppRouterStyles => {
  return {};
};
