import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentDetailPageProps } from "./PaymentDetailPage.types";
import {
  getStyles,
  IPaymentDetailPageStyleProps,
  IPaymentDetailPageStyles,
} from "./PaymentDetailPage.styles";
import { PaymentDetailPageComponent } from "./PaymentDetailPage.base";

/**
 * PaymentDetailPage
 */
export const PaymentDetailPage = styled<
  IPaymentDetailPageProps,
  IPaymentDetailPageStyleProps,
  IPaymentDetailPageStyles
>(PaymentDetailPageComponent, getStyles);
