import * as React from "react";
import { IconButton, TooltipHost } from "@fluentui/react";
import { GridViewCard } from "src/components/GridViewCard";
import { getPaymentMethodSelectorCardClassNames } from "./PaymentMethodSelectorCard.styles";
import {
  IPaymentMethodSelectorCardProps,
  IPaymentMethodSelectorCardState,
} from "./PaymentMethodSelectorCard.types";

export class PaymentMethodSelectorCardComponent extends React.Component<
  IPaymentMethodSelectorCardProps,
  IPaymentMethodSelectorCardState
> {

  render(): JSX.Element {
    const { styles, onDelete, paymentMethod, columnWidth, children } =
      this.props;
    const [classNames, subComponentStyles] =
      getPaymentMethodSelectorCardClassNames(styles!, {
        ...this.props,
        ...this.state,
        saved: paymentMethod.id !== -1,
      });
    const selected = !this.props.clearSelection &&
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === paymentMethod.key
      ).length > 0;
    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        title={<React.Fragment />}
        columnWidth={columnWidth}
        footerText={undefined}
        footerIconName={undefined}
        selected={selected}
        onClick={
          this.props.onSelected
            ? () => {
                this.props.onSelected && this.props.onSelected(paymentMethod);
              }
            : undefined
        }
        embedded={true}
        onSelected={
          this.props.onSelected
            ? () => {
                this.props.onSelected && this.props.onSelected(paymentMethod);
              }
            : undefined
        }
        selectorStyle={{ marginLeft: "5px" }}
        icons={
          <div className={classNames.root}>
            {paymentMethod.id !== -1 && (
              <TooltipHost content={"Delete"}>
                <IconButton
                  iconProps={{ iconName: "Delete" }}
                  onClick={(
                    ev: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => {
                    ev.preventDefault();
                    ev.stopPropagation();
                    onDelete && onDelete(paymentMethod);
                  }}
                />
              </TooltipHost>
            )}
          </div>
        }
      >
        {children}
      </GridViewCard>
    );
  }
}
