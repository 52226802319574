import { Redirect, Route, RouteProps } from "react-router";
import NavService from "../services/NavService";

export interface PrivateRouteProps extends RouteProps {}

export class PrivateRoute extends Route<PrivateRouteProps> {
  public render() {
    const returnUrl = encodeURIComponent(
      `${location.pathname}${location.search}`
    );
    if (localStorage.getItem("userInfo") === null) {
      return (
        <Redirect
          to={`/connexion${returnUrl === "/" ? "" : `?returnUrl=${returnUrl}`}`}
        />
      );
    } else {
      const path: string = this.props.location?.pathname ?? "/";
      if (path !== "/") {
        const index = path.substr(1).indexOf("/");
        if (index !== -1) {
          if (NavService.hasPermissionOf(path.substr(0, index))) {
            return <Redirect to={"/"} />;
          }
        } else {
          if (!NavService.hasPermissionOf(path)) {
            return <Redirect to={"/"} />;
          }
        }
      }
      return <Route {...this.props} />;
    }
  }
}
