import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightCheckoutPageProps } from "./FlightCheckoutPage.types";
import {
  getStyles,
  IFlightCheckoutPageStyleProps,
  IFlightCheckoutPageStyles,
} from "./FlightCheckoutPage.styles";
import { FlightCheckoutPageComponent } from "./FlightCheckoutPage.base";

/**
 * FlightCheckoutPage
 */
export const FlightCheckoutPage = styled<
  IFlightCheckoutPageProps,
  IFlightCheckoutPageStyleProps,
  IFlightCheckoutPageStyles
>(FlightCheckoutPageComponent, getStyles);
