const x: number = 767;

export const MediaQueries = {
  small:
    "@media only screen and (min-width: 360px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 3.0)",
  mobile: `@media only screen and (max-width: ${x}px)`,
  landscape: `@media only screen and (max-width: ${x}px) and (orientation: landscape)`,
  desktop: `@media only screen and (min-width: ${x}px)`,
  tablet: `@media only screen and (max-width: 1224px)`,
};
