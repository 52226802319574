import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "../../../Base";

export interface IPositionPanelSubComponentStyles {}

export interface IPositionPanelStyles
  extends IAmigoBaseStyles<IPositionPanelSubComponentStyles> {}

export interface IPositionPanelStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPositionPanelClassNames = (
  styles?: IStyleFunctionOrObject<
    IPositionPanelStyleProps,
    IPositionPanelStyles
  >,
  props?: IPositionPanelStyleProps
): [
  IProcessedStyleSet<IPositionPanelStyles>,
  IPositionPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPositionPanelStyleProps,
  IPositionPanelStyles
> = (_props: IPositionPanelStyleProps): IPositionPanelStyles => {
  return {
    root: ["amigo-PositionPanel", {}],
  };
};
