import { styled } from "@fluentui/react/lib/Utilities";
import { IGenericPageHeaderProps } from "./GenericPageHeader.types";
import {
  getStyles,
  IGenericPageHeaderStyleProps,
  IGenericPageHeaderStyles,
} from "./GenericPageHeader.styles";
import { GenericPageHeaderComponent } from "./GenericPageHeader.base";

/**
 * GenericPageHeader
 */
export const GenericPageHeader = styled<
  IGenericPageHeaderProps,
  IGenericPageHeaderStyleProps,
  IGenericPageHeaderStyles
>(GenericPageHeaderComponent, getStyles);
