import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";
import {
  IUserCalloutStyleProps,
  IUserCalloutStyles,
} from "src/controls/UserCallout";

export interface IRequestCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  userCallout?: IStyleFunctionOrObject<
    IUserCalloutStyleProps,
    IUserCalloutStyles
  >;
}

export interface IRequestCardStyles
  extends IAmigoBaseStyles<IRequestCardSubComponentStyles> {}

export interface IRequestCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestCardClassNames = (
  styles?: IStyleFunctionOrObject<IRequestCardStyleProps, IRequestCardStyles>,
  props?: IRequestCardStyleProps
): [
  IProcessedStyleSet<IRequestCardStyles>,
  IRequestCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestCardStyleProps,
  IRequestCardStyles
> = (_props: IRequestCardStyleProps): IRequestCardStyles => {
  return {
    subComponentStyles: {
      userCallout: {
        root: {
          maxWidth: "405px",
          textOverflow: "ellipsis",
          [MediaQueries.mobile]: {
            // maxWidth: "175px",
          },
        },
      },
    },
  };
};
