import { Stack } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getRequestParticipantPathwayTimelineClassNames } from "./RequestParticipantPathwayTimeline.styles";
import {
  IRequestParticipantPathwayTimelineState,
  IRequestParticipantPathwayTimelineProps,
} from "./RequestParticipantPathwayTimeline.types";
import "./timeline.css";

export class RequestParticipantPathwayTimelineComponent extends React.Component<
  IRequestParticipantPathwayTimelineProps,
  IRequestParticipantPathwayTimelineState
> {
  constructor(props: IRequestParticipantPathwayTimelineProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getRequestParticipantPathwayTimelineClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const { timeline } = this.props;

    return timeline === undefined || this.props.mobile ? (
      <div></div>
    ) : (
      <div className={classNames.root}>
        <ul>
          <li className={timeline.eimtDate == null ? "disabled" : "active"}>
            <a>
              <div>
                {i18n.t("requests:participants:item:pathway:eimt")}
                <br />
                {timeline.eimtDate}
              </div>
            </a>
          </li>
          <li className={timeline.depositDate == null ? "disabled" : "active"}>
            <a>
              <div>
                {i18n.t("requests:participants:item:pathway:deposit")}
                <br />
                {timeline.depositDate}
              </div>
            </a>
          </li>
          <li className={timeline.deliveryDate == null ? "disabled" : "active"}>
            <a>
              <div>
                {i18n.t("requests:participants:item:pathway:delivery")}
                <br />
                {timeline.deliveryDate}
              </div>
            </a>
          </li>
        </ul>
        <Stack
          horizontalAlign="center"
          style={{ width: "97%", textAlign: "center" }}
        >
          <ul style={{ width: "66%" }}>
            <li
              className={
                timeline.deliveryDelay == null ? "disabled long" : "active long"
              }
            >
              <a>
                <div>
                  {timeline.depositDelay == null
                    ? ""
                    : `${timeline.depositDelay} jours`}
                </div>
              </a>
            </li>
            <li
              className={
                timeline.deliveryDelay == null ? "disabled long" : "active long"
              }
            >
              <a>
                <div>
                  {timeline.deliveryDelay == null
                    ? ""
                    : `${timeline.deliveryDelay} jours`}
                </div>
              </a>
            </li>
          </ul>
        </Stack>
      </div>
    );
  }
}
