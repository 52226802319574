import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IDocumentSummaryControlSubComponentStyles {}

export interface IDocumentSummaryControlStyles
  extends IAmigoBaseStyles<IDocumentSummaryControlSubComponentStyles> {}

export interface IDocumentSummaryControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDocumentSummaryControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IDocumentSummaryControlStyleProps,
    IDocumentSummaryControlStyles
  >,
  props?: IDocumentSummaryControlStyleProps
): [
  IProcessedStyleSet<IDocumentSummaryControlStyles>,
  IDocumentSummaryControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDocumentSummaryControlStyleProps,
  IDocumentSummaryControlStyles
> = (
  _props: IDocumentSummaryControlStyleProps
): IDocumentSummaryControlStyles => {
  return {};
};
