import { styled } from "@fluentui/react/lib/Utilities";
import { IDossierImmigrationDetailPageProps } from "./DossierImmigrationDetailPage.types";
import {
  getStyles,
  IDossierImmigrationDetailPageStyleProps,
  IDossierImmigrationDetailPageStyles,
} from "./DossierImmigrationDetailPage.styles";
import { DossierImmigrationDetailPageComponent } from "./DossierImmigrationDetailPage.base";

/**
 * ImmigrationDetailPage description
 */
export const DossierImmigrationDetailPage = styled<
  IDossierImmigrationDetailPageProps,
  IDossierImmigrationDetailPageStyleProps,
  IDossierImmigrationDetailPageStyles
>(DossierImmigrationDetailPageComponent, getStyles);
