import { styled } from "@fluentui/react/lib/Utilities";
import { IAssistedMessageControlProps } from "./AssistedMessageControl.types";
import {
  getStyles,
  IAssistedMessageControlStyleProps,
  IAssistedMessageControlStyles,
} from "./AssistedMessageControl.styles";
import { AssistedMessageControlComponent } from "./AssistedMessageControl.base";

/**
 * AssistedMessageControl description
 */
export const AssistedMessageControl = styled<
  IAssistedMessageControlProps,
  IAssistedMessageControlStyleProps,
  IAssistedMessageControlStyles
>(AssistedMessageControlComponent, getStyles);
