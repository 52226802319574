/** imports */
import i18n from "../services/i18n";

/**
 * Describes field for validation
 * @interface ISchemaField
 */
export interface ISchemaField {
  name: string;
  label: string;
  required: boolean;
  pattern: string;
  errorMessage: ((data: object, fieldName: string) => string) | string;
  type: string;
  system?: boolean;
  relationship?: boolean;
  options?: any;
  enum?: any;
  length?: number[];
  min?: number;
}

/**
 * Describes fields for validation
 * @interface ISchemaField
 */
export interface ISchemaFields {
  [key: string]: ISchemaField;
}

/**
 * Describes an entity and related fields for validation.
 * @interface ISchemaField
 */
export interface ISchemaObject {
  fields: ISchemaFields;
  name: string;
  plural: string;
  label: string;
}

export const getFieldMaxLength = (
  schema: ISchemaObject,
  fieldName: string,
  defaultValue?: number
): number => {
  const field = schema.fields[fieldName];
  return field?.length ? field.length[0] : defaultValue ?? 100;
};

const getErrorMessage = (
  error: ((data: object, fieldName: string) => string) | string,
  data: object,
  fieldName: string
): string => {
  if (typeof error === "function") return error(data, fieldName);
  return error;
};

/**
 * Validate an entity according to a given schema.
 * @method validate
 */
export const validate = (schema: ISchemaObject, data: object): any => {
  const fields = Object.keys(schema.fields);
  return fields.reduce((errorObject, fieldName) => {
    const fieldDef = schema.fields[fieldName];
    const value = data[fieldName];
    // skip from validating if the field is system generated or it is a relation ship field
    if (fieldDef.system || fieldDef.relationship) {
      return errorObject;
    }
    // if data object does not contain a value for a required field submit errors
    if (fieldDef.type !== "boolean" && fieldDef.required && !value) {
      const errMsg =
        getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
        i18n.t("validator:mandatory:error");
      return setError(fieldDef, errorObject, fieldName, errMsg);
    }

    // array
    if (value && fieldDef.min && fieldDef.type === "array") {
      if (
        value.length < fieldDef.min &&
        value.filter((x: any) => x !== undefined && x != null).length <
          fieldDef.min
      ) {
        const errMsg = i18n.t("validator:validdate");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    if (fieldDef.type === "array") return errorObject;

    // validate for number
    if (value && fieldDef.type === "number") {
      if (!/^\d+$/.test(value)) {
        const errMsg =
          getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
          i18n.t("validator:number:error");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }

    // validate for email
    if (value && fieldDef.type === "email") {
      const emailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; // eslint-disable-line
      if (!emailRegex.test(value)) {
        const errMsg =
          getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
          i18n.t("validator:email:error");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }

    // validate for compare
    if (
      value !== undefined &&
      fieldDef.type === "compare" &&
      fieldDef.pattern !== undefined
    ) {
      const fieldCompare = data[fieldDef.pattern];
      if (fieldCompare && fieldCompare !== value) {
        const errMsg =
          getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
          i18n.t("validator:compare:error");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      } else return errorObject;
    }

    // validate for compare
    if (value && fieldDef.type === "expiry" && fieldDef.pattern !== undefined) {
      const fieldCompare = data[fieldDef.pattern];
      if (fieldCompare) {
        const exMonth = value;
        const exYear = fieldCompare;
        const today = new Date();
        const someday = new Date();
        someday.setFullYear(exYear, exMonth, 1);

        if (someday < today) {
          const errMsg =
            getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
            i18n.t("validator:compare:error");
          return setError(fieldDef, errorObject, fieldName, errMsg);
        } else return errorObject;
      } else return errorObject;
    }

    // validate for decimal
    if (value && fieldDef.type === "decimal") {
      if (!/^\d+(\.\d+)?$/.test(value)) {
        const errMsg =
          getErrorMessage(fieldDef.errorMessage, data, fieldName) ??
          i18n.t("validator:decimal:error");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    // pattern validation;
    if (
      value &&
      fieldDef.pattern &&
      fieldDef.pattern.length > 0 &&
      (!isUnicode(value) || isAsciiPattern(fieldDef.pattern))
    ) {
      const regexp = new RegExp(fieldDef.pattern);
      if (!regexp.test(value)) {
        const errMsg =
          getErrorMessage(fieldDef.errorMessage, data, fieldName) ||
          i18n.t("validator:invalid:error");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }

    // boolean field validation
    if (value && fieldDef.type === "boolean") {
      const values = fieldDef.options.values;
      const validValues = values.map((item: { value: any }) => {
        return item.value;
      });
      if (typeof value !== "boolean") {
        const errMsg = i18n.t("validator:only:allow") + validValues.join(", ");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    // Enum field validation
    if (value && fieldDef.type === "enum") {
      const values = fieldDef.enum.values;
      const validValues = values.map((enumObject: { key: any }) => {
        return enumObject.key;
      });
      if (validValues.indexOf(value) === -1) {
        const errMsg = i18n.t("validator:validvalue");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    // Timestamp validation
    if (value && fieldDef.type === "timestamp") {
      if (!/^-?\d+$/.test(value)) {
        const errMsg = i18n.t("validator:validdate");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    // Length validation (maxLength)
    if (
      value !== undefined &&
      fieldDef.length &&
      fieldDef.length.length === 1
    ) {
      const valueLength = value.length;
      const lengthDef = fieldDef.length.slice();
      if (valueLength > lengthDef[0]) {
        const errMsg =
          i18n.t("validator:length:maxlength") +
          fieldDef.length[0] +
          i18n.t("validator:characters");
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    // Check minus values
    if (value !== undefined && fieldDef.min) {
      if (value < fieldDef.min) {
        const errMsg = i18n.t("validator:length:between") + fieldDef.min;
        return setError(fieldDef, errorObject, fieldName, errMsg);
      }
    }
    return errorObject;
  }, {});
};
export const passwordValidate = (password: string) => {
  const hasNotEnoughLength = password.length < 8;
  const hasNoUpperCase = !/[A-Z]/.test(password);
  const hasNoLowerCase = !/[a-z]/.test(password);
  const hasNoNumbers = !/\d/.test(password);
  const hasNoNonAlphaNumeric = !/[!@#$%&*]/.test(password);
  const hasAnyOtherSymbol = /[^A-Za-z\d!@#$%&*]/.test(password);
  if (
    hasNotEnoughLength ||
    hasNoUpperCase ||
    hasNoLowerCase ||
    hasNoNumbers ||
    hasNoNonAlphaNumeric ||
    hasAnyOtherSymbol
  ) {
    return false;
  }

  return true;
};

const setError = (
  fieldDef: ISchemaField,
  errorObject: object,
  name: string,
  errMsg: string
) => {
  return {
    ...errorObject,
    [name]: fieldDef.errorMessage
      ? typeof fieldDef.errorMessage === "string"
        ? i18n.t(fieldDef.errorMessage)
        : i18n.t(fieldDef.errorMessage(fieldDef, fieldDef.name))
      : errMsg,
  };
};

const isUnicode = (text: string) => {
  for (let i = 0; i < text.length; i++) {
    if (text.charCodeAt(i) > 127) {
      return true;
    }
  }
  return false;
};

const isAsciiPattern = (pattern: string) => {
   return !pattern.includes('\\u') && !/\/u/.test(pattern)
}