import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IArrivalAssistedParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IIconStyles;
}

export interface IArrivalAssistedParticipantCardStyles
  extends IAmigoBaseStyles<IArrivalAssistedParticipantCardSubComponentStyles> {}

export interface IArrivalAssistedParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  hasErrors: boolean;
}

export const getArrivalAssistedParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IArrivalAssistedParticipantCardStyleProps,
    IArrivalAssistedParticipantCardStyles
  >,
  props?: IArrivalAssistedParticipantCardStyleProps
): [
  IProcessedStyleSet<IArrivalAssistedParticipantCardStyles>,
  IArrivalAssistedParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IArrivalAssistedParticipantCardStyleProps,
  IArrivalAssistedParticipantCardStyles
> = (
  props: IArrivalAssistedParticipantCardStyleProps
): IArrivalAssistedParticipantCardStyles => {
  return {
    root: ["amigo-ArrivalAssistedParticipantCard", {}],
    subComponentStyles: {
      topIcon: props.hasErrors
        ? {
            root: {
              color: props.theme.palette.redDark,
            },
          }
        : undefined,
      gridViewCard: {
        root: {
          width: "95%",
          [MediaQueries.mobile]: {
            width: "95%",
          },
          [MediaQueries.small]: {
            width: "97%",
          },
        },
      },
    },
  };
};
