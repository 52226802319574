import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightOfferDetailsSubComponentStyles {}

export interface IFlightOfferDetailsStyles
  extends IAmigoBaseStyles<IFlightOfferDetailsSubComponentStyles> {}

export interface IFlightOfferDetailsStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightOfferDetailsClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightOfferDetailsStyleProps,
    IFlightOfferDetailsStyles
  >,
  props?: IFlightOfferDetailsStyleProps
): [
  IProcessedStyleSet<IFlightOfferDetailsStyles>,
  IFlightOfferDetailsSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightOfferDetailsStyleProps,
  IFlightOfferDetailsStyles
> = (_props: IFlightOfferDetailsStyleProps): IFlightOfferDetailsStyles => {
  return {
    root: ["amigo-FlightOfferDetails", {}],
  };
};
