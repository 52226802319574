import axios from "axios";
import { IRequestVersion } from "src/models/Requests/IRequestVersion";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";

class VersoinRequestsService {
  private _requests?: IRequestVersion[] = undefined;
  private _requestsPages: number = 1;

  public init(): void {
    this._requests = undefined;
    this._requestsPages = 1;
    this.pageSize = 20;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("key", "Id")
      .replace("name", "Titre")
      .replace("createdOn", "CreerLe")
      .replace("createdByLabel", "CreerPar")
      .replace("createdBy", "CreerPar");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public getRequestVersions(
    enterpriseId: number,
    requestId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IRequestVersion[]> {
    const _this = this;
    let currentPage = this._requestsPages;
    let combine: boolean;
    if (next && _this._requests !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    return axios
      .post(
        `/api/enterprises/${enterpriseId}/requests/${requestId}/versions`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      )
      .then((x) => {
        const items = x.data as any;
        if (combine && _this._requests !== undefined) {
          _this._requestsPages = currentPage;
          _this._requests = _this._requests.concat(items);
        } else {
          _this._requestsPages = 1;
          _this._requests = this._requests = items;
        }
        return _this._requests ?? [];
      })
      .catch((_x) => {
        console.log("error");
        return _this._requests ?? [];
      });
  }
}

export default new VersoinRequestsService();
