import { styled } from "@fluentui/react/lib/Utilities";
import { MessageNotificationComponent } from "./MessageNotification.base";
import {
  getStyles,
  IMessageNotificationStyleProps,
  IMessageNotificationStyles,
} from "./MessageNotification.styles";
import { IMessageNotificationProps } from "./MessageNotification.types";

/**
 * MessageNotification description
 */
export const MessageNotification = styled<
  IMessageNotificationProps,
  IMessageNotificationStyleProps,
  IMessageNotificationStyles
>(MessageNotificationComponent, getStyles);
