import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IInspectionAssistedControlSubComponentStyles {}

export interface IInspectionAssistedControlStyles
  extends IAmigoBaseStyles<IInspectionAssistedControlSubComponentStyles> {}

export interface IInspectionAssistedControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getInspectionAssistedControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IInspectionAssistedControlStyleProps,
    IInspectionAssistedControlStyles
  >,
  props?: IInspectionAssistedControlStyleProps
): [
  IProcessedStyleSet<IInspectionAssistedControlStyles>,
  IInspectionAssistedControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IInspectionAssistedControlStyleProps,
  IInspectionAssistedControlStyles
> = (
  _props: IInspectionAssistedControlStyleProps
): IInspectionAssistedControlStyles => {
  return {};
};
