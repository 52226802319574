import {
  ActionButton,
  DetailsList,
  IColumn,
  Icon,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  SelectionMode,
  Stack,
  StackItem,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { Redirect } from "react-router";
import { IconHeader } from "src/components/IconHeader";
import { MediaQueries } from "src/components/MediaQueries";
import { FlightResultsResponse } from "src/models/Transports/FlightResultsResponse";
import { IFlightCriteria } from "src/models/Transports/IFlightCriteria";
import { IFlightOffer } from "src/models/Transports/IFlightOffer";
import { IFlightRequestDataModel } from "src/models/Transports/IFlightRequestDataModel";
import { IParticipantNameRecordModel } from "src/models/Transports/IPassengerNameRecordModel";
import i18n from "src/services/i18n";
import { FlightOfferContainer } from "../../FlightSearch/FlightOfferContainer";
import { FlightBookingBreadcrumb } from "../FlightBookingBreadcrumb";
import { FlightBookingPassengersGridView } from "./Controls/FlightBookingPassengersGridView";
import { getFlightBookingPassengersPageClassNames } from "./FlightBookingPassengersPage.styles";
import {
  IFlightBookingPassengersPageProps,
  IFlightBookingPassengersPageState,
} from "./FlightBookingPassengersPage.types";

export class FlightBookingPassengersPageComponent extends React.Component<
  IFlightBookingPassengersPageProps,
  IFlightBookingPassengersPageState
> {
  private airports = (offer: IFlightOffer): string[] => {
    const res: string[] = [];
    offer.schedules.forEach((x) => {
      if (res.filter((y) => y === x.from).length === 0) {
        res.push(x.from);
      }
      if (res.filter((y) => y === x.to).length === 0) {
        res.push(x.to);
      }
    });
    return res;
  };

  private getPrices = (offer: IFlightOffer): any[] => {
    return [
      {
        desc: `${offer.passengersCount} x ${i18n.t(
          `transports:flightbooking:adult${
            offer.passengersCount > 1 ? "s" : ""
          }`
        )}`,
        value: i18n.currency(offer.subTotalPrice),
      },
    ]

      .concat(
        offer.subTotalPrice
          ? offer.charges?.map((x) => ({
              desc: x.name,
              value: i18n.currency(x.amount),
            })) ?? []
          : []
      )
      .concat(
        offer.bookingFeeAmount && offer.subTotalPrice
          ? [
              {
                desc: "Frais de billetterie",
                value: i18n.currency(offer.bookingFeeAmount * offer.passengersCount),
              },
            ]
          : []
      )
      .concat([
        {
          desc: i18n.t("transports:flightbooking:taxes"),
          value: offer.totalTaxAmount
            ? offer.totalTaxAmount <= 0
              ? i18n.t("transports:flightbooking:taxes:included")
              : i18n.currency(offer.totalTaxAmount)
            : i18n.t("transports:flightbooking:taxes:included"),
        },
        {
          desc: "Total",
          value: i18n.currency(offer.totalPrice),
        },
      ]);
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames] = getFlightBookingPassengersPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const routeState = this.props.route.location.state as any;
    if (!routeState) return <Redirect to={"/transports/billetterie"} />;
    const offer: IFlightOffer = routeState.offer as any;
    const data: FlightResultsResponse[] = routeState.data as any;
    const criteria: IFlightCriteria = routeState.criteria as any;
    const offerIndex: number = routeState.offerIndex as any;
    const serviceIndex: number = routeState.serviceIndex as any;
    const model: IFlightRequestDataModel = routeState.model as any;
    const participants: IParticipantNameRecordModel[] =
      routeState.participants as any;
    if (!offer) return <Redirect to={"/transports/billetterie"} />;

    const conditionType =
      offer.operating?.toLowerCase() === "am" ? "am" : "standard";
    const rp = criteria.arrival ? "arrival" : "departure";
    const ap: string[] = this.airports(offer);
    return (
      <div className={classNames.root}>
        <FlightBookingBreadcrumb
          {...this.props}
          styles={undefined}
          isConfirmation={true}
          getState={() => {
            return {};
          }}
        />
        <Stack
          horizontal
          grow
          tokens={{ childrenGap: 20 }}
          styles={{ root: { width: "100%" } }}
          horizontalAlign={"center"}
          verticalAlign={"center"}
        >
          <StackItem>
            <Icon
              iconName={"CityNext"}
              styles={{ root: { fontSize: "24px", marginTop: "3px" } }}
            />
          </StackItem>
          {ap.map((x, index) => {
            return (
              <React.Fragment key={x}>
                <StackItem key={`${x}_text`}>
                  <h1>{x}</h1>
                </StackItem>
                <StackItem key={`${x}_divider`}>
                  <Icon
                    iconName={
                      index === 0
                        ? "Airplane"
                        : index === ap.length - 1
                        ? "Arrivals"
                        : "Clock"
                    }
                    styles={{ root: { fontSize: "24px", marginTop: "3px" } }}
                  />
                </StackItem>
              </React.Fragment>
            );
          })}
        </Stack>
        <Stack
          styles={{ root: { width: "100%", margin: "10px" } }}
          tokens={{ childrenGap: 20 }}
          horizontal={!mobile}
          grow
        >
          <StackItem
            styles={{
              root: {
                width: mobile ? "100%" : tablet ? "60%" : "66%",
                maxWidth: mobile ? "100%" : tablet ? "60%" : "66%",
              },
            }}
            grow={mobile ? true : 2}
          >
            <Card
              styles={{
                root: {
                  backgroundColor: this.props.theme.palette.white,
                  width: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              <Card.Section grow>
                <div>
                  <IconHeader
                    {...this.props}
                    styles={{ root: { marginLeft: "5px" } }}
                    iconName={"Airplane"}
                    title={i18n.t("transports:flights:tripsummary")}
                  />
                  <ActionButton
                    text={i18n.t("transports:flights:back:selection")}
                    onClick={() =>
                      this.props.route.history.push(
                        "/transports/billetterie/participants",
                        {
                          ...routeState,
                        }
                      )
                    }
                  />
                  <Stack
                    styles={{
                      root: {
                        width: "100%",
                        borderTop: "1px solid lightgray",
                        padding: "8px",
                        maxWidth: "90%",
                        [MediaQueries.mobile]: {
                          maxWidth: "100%",
                        },
                      },
                    }}
                  >
                    <Text
                      variant="large"
                      styles={{ root: { fontWeight: 600 } }}
                    >
                      {i18n.t(
                        `transports:flights:conditions:${rp}:standard:title`
                      )}
                    </Text>
                    <div>
                      <ul>
                        {[1, 2, 3, 4].map((x) => (
                          <li key={x.toString()}>
                            <Text variant="medium">
                              {i18n.t(
                                `transports:flights:conditions:${rp}:${conditionType}:${x}`
                              )}
                            </Text>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </Stack>
                </div>
                <div
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderTop: "1px solid lightgray",
                  }}
                >
                  <div>
                    <FlightOfferContainer
                      {...this.props}
                      styles={{
                        root: {
                          width: "92%",
                          margin: mobile ? "10px" : tablet ? "20px" : "40px",
                        },
                      }}
                      offer={offer}
                      data={data}
                      criteria={criteria}
                      offerIndex={offerIndex}
                      serviceIndex={serviceIndex}
                      booking={true}
                    />
                  </div>
                </div>
                <div style={{ height: "25px" }}></div>
                <div>
                  <FlightBookingPassengersGridView
                    {...this.props}
                    styles={undefined}
                    model={model}
                    arrival={criteria.arrival}
                    participants={participants.filter((x) => x.selected)}
                  />
                </div>
                <div style={{ height: "25px" }}></div>
              </Card.Section>
            </Card>
          </StackItem>
          <StackItem
            styles={{
              root: {
                width: mobile ? "100%" : tablet ? "40%" : "33%",
                maxWidth: mobile ? "100%" : tablet ? "40%" : "33%",
                marginRight: "20px",
              },
            }}
            grow={mobile ? true : 1}
          >
            <Card
              styles={{
                root: {
                  backgroundColor: this.props.theme.palette.white,
                  width: "100%",
                  maxWidth: "100%",
                },
              }}
            >
              <Card.Section>
                <IconHeader
                  {...this.props}
                  styles={{ root: { marginLeft: "5px" } }}
                  iconName={"Money"}
                  title={i18n.t("transports:summary:pricing")}
                />
                <div
                  style={{
                    borderTop: "1px solid lightgray",
                    padding: tablet ? "10px" : "20px",
                  }}
                >
                  <DetailsList
                    selectionMode={SelectionMode.none}
                    items={this.getPrices(offer)}
                    columns={[
                      {
                        key: "desc",
                        fieldName: "desc",
                        name: "",
                        minWidth: 170,
                        onRender: (
                          item?: any,
                          _index?: number,
                          _column?: IColumn
                        ) => {
                          return (
                            <Text
                              styles={{
                                root: {
                                  fontWeight:
                                    item.desc === "Total" ? 600 : "normal",
                                },
                              }}
                            >
                              {item.desc}
                            </Text>
                          );
                        },
                      },
                      {
                        key: "value",
                        fieldName: "value",
                        name: i18n.t("billing:payments:total"),
                        minWidth: 100,
                        styles: {
                          root: { textAlign: "right" },
                        },
                        onRender: (
                          item?: any,
                          _index?: number,
                          _column?: IColumn
                        ) => {
                          return (
                            <Text
                              styles={{
                                root: {
                                  textAlign: "right",
                                  fontWeight:
                                    item.desc === "Total" ? 600 : "normal",
                                },
                              }}
                            >
                              {item.value}
                            </Text>
                          );
                        },
                      },
                    ]}
                  />
                  <div style={{ height: "20px", clear: "both" }}></div>
                  <MessageBar messageBarType={MessageBarType.info}>
                    {i18n.t("transports:flights:currencywarning")}
                  </MessageBar>
                  <div style={{ height: "20px", clear: "both" }}></div>
                  <PrimaryButton
                    styles={{ root: { width: "100%" } }}
                    text={i18n.t(
                      serviceIndex === 0
                        ? "transports:flights:booknow"
                        : "transports:flights:buynow"
                    )}
                    onClick={() => {
                      this.props.route.history.push(
                        "/transports/billetterie/paiement",
                        {
                          ...routeState,
                        }
                      );
                    }}
                  />
                </div>
              </Card.Section>
            </Card>
          </StackItem>
        </Stack>
      </div>
    );
  }
}
