import * as React from "react";
import { getEnterpriseHeaderClassNames } from "./EnterpriseHeader.styles";
import {
  IEnterpriseHeaderState,
  IEnterpriseHeaderProps,
} from "./EnterpriseHeader.types";
import { PersonaSize, Persona } from "@fluentui/react";
import EnterprisesPageStyles from "../EnterprisesPage.styles";

export class EnterpriseHeaderComponent extends React.Component<
  IEnterpriseHeaderProps,
  IEnterpriseHeaderState
> {
  private getPersonaSize = () => {
    return this.props.mobile ? PersonaSize.size56 : PersonaSize.size100;
  };

  render(): JSX.Element {
    const { styles, mobile, themeIndex } = this.props;
    const [classNames] = getEnterpriseHeaderClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div
        className={classNames.root}
        style={{ position: "relative", display: "block", minHeight: "160px" }}
      >
        <div
          className="EnterpriseBackground"
          style={{
            opacity: EnterprisesPageStyles.themeOpacity(themeIndex),
            backgroundImage:
              "url('/img/banner_" + (themeIndex + 1).toString() + ".jpg')",
            backgroundSize: EnterprisesPageStyles.themeImageSize(
              themeIndex,
              this.props.mobile
            ),
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div
          className={
            themeIndex === 1 || themeIndex === 6 ? undefined : "WhitePersona"
          }
          style={{
            position: "absolute",
            padding: "10px 10px",
            width: "95%",
            left: 0,
            bottom: 0,
          }}
        >
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            text={
              this.props.enterprise === null ? "" : this.props.enterprise.name
            }
            coinProps={{
              styles: {
                coin: {
                  display: "none",
                },
              },
            }}
            styles={{
              primaryText: {
                fontWeight: "bold",
                fontSize: mobile ? "20px" : "28px",
                color: EnterprisesPageStyles.themeColor(themeIndex),
                ":hover": {
                  color: EnterprisesPageStyles.themeColor(themeIndex),
                },
              },
              secondaryText: {
                fontWeight: "bold",
                color: EnterprisesPageStyles.themeColor(themeIndex),
              },
              tertiaryText: {
                fontWeight: "bold",
                color: EnterprisesPageStyles.themeColor(themeIndex),
              },
            }}
            color={EnterprisesPageStyles.themeColor(themeIndex)}
            secondaryText={this.props.enterprise?.memberid}
            tertiaryText={""}
            size={this.getPersonaSize()}
          />
        </div>
        <div></div>
      </div>
    );
  }
}
