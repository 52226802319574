import {
  IProcessedStyleSet,
  IProgressIndicatorStyleProps,
  IProgressIndicatorStyles,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import { DefaultPalette } from "@fluentui/react/lib/Styling";
import { PasswordCheckStrength } from "src/models/PasswordCheckStrength";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";

const strengthToColor = (
  strength: PasswordCheckStrength
): string | undefined => {
  let color: string | undefined;
  switch (strength) {
    case PasswordCheckStrength.Empty:
      color = undefined;
      break;
    case PasswordCheckStrength.Short:
      color = DefaultPalette.redDark;
      break;
    case PasswordCheckStrength.Common:
      color = DefaultPalette.red;
      break;
    case PasswordCheckStrength.Weak:
      color = DefaultPalette.yellow;
      break;
    case PasswordCheckStrength.Ok:
      color = DefaultPalette.green;
      break;
    case PasswordCheckStrength.Strong:
      color = DefaultPalette.greenDark;
      break;
    default:
      color = undefined;
      break;
  }
  return color;
};

export interface IPasswordStrengthIndicatorSubComponentStyles {
  indicator?: IStyleFunctionOrObject<
    IProgressIndicatorStyleProps,
    IProgressIndicatorStyles
  >;
}

export interface IPasswordStrengthIndicatorStyles
  extends IComponentBaseStyles<IPasswordStrengthIndicatorSubComponentStyles> {}

export interface IPasswordStrengthIndicatorStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {
  strength: PasswordCheckStrength;
}

export const getPasswordStrengthIndicatorClassNames = (
  styles?: IStyleFunctionOrObject<
    IPasswordStrengthIndicatorStyleProps,
    IPasswordStrengthIndicatorStyles
  >,
  props?: IPasswordStrengthIndicatorStyleProps
): [
  IProcessedStyleSet<IPasswordStrengthIndicatorStyles>,
  IPasswordStrengthIndicatorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPasswordStrengthIndicatorStyleProps,
  IPasswordStrengthIndicatorStyles
> = (
  props: IPasswordStrengthIndicatorStyleProps
): IPasswordStrengthIndicatorStyles => {
  const strengthColor = strengthToColor(props.strength);
  return {
    subComponentStyles: {
      indicator: {
        progressBar: {
          backgroundColor: strengthColor,
        },
        root: {
          visibility:
            props.strength === PasswordCheckStrength.Empty
              ? "hidden"
              : "visible",
        },
      },
    },
  };
};
