import { styled } from "@fluentui/react/lib/Utilities";
import { IMessageReplyCommandBarProps } from "./MessageReplyCommandBar.types";
import {
  getStyles,
  IMessageReplyCommandBarStyleProps,
  IMessageReplyCommandBarStyles,
} from "./MessageReplyCommandBar.styles";
import { MessageReplyCommandBarComponent } from "./MessageReplyCommandBar.base";

/**
 * MessageReplyCommandBar
 */
export const MessageReplyCommandBar = styled<
  IMessageReplyCommandBarProps,
  IMessageReplyCommandBarStyleProps,
  IMessageReplyCommandBarStyles
>(MessageReplyCommandBarComponent, getStyles);
