import * as React from "react";
import { RequestsControl } from "../RequestsControl";
import { getRequestsPageClassNames } from "./RequestsPage.styles";
import { IRequestsPageProps, IRequestsPageState } from "./RequestsPage.types";
import { GenericPageHeader } from "src/components/GenericPageHeader";
import { uniqueId } from "src/utils/uniqueId";

export class RequestsPageComponent extends React.Component<
  IRequestsPageProps,
  IRequestsPageState
> {
  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames, subComponentStyles] = getRequestsPageClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );

    const id: string = uniqueId("nvx_gridview_viewport_");
    return (
      <div id={id} className={classNames.root}>
        <GenericPageHeader
          title="Demandes"
          {...this.props}
          styles={undefined}
        />
        <RequestsControl
          {...this.props}
          scrollableTargetId={id}
          styles={subComponentStyles?.control}
        />
      </div>
    );
  }
}
