import * as React from "react";
import { getMessageReplyCommandBarClassNames } from "./MessageReplyCommandBar.styles";
import {
  IMessageReplyCommandBarState,
  IMessageReplyCommandBarProps,
} from "./MessageReplyCommandBar.types";
import { CommandBar } from "@fluentui/react";
import i18n from "src/services/i18n";

export class MessageReplyCommandBarComponent extends React.Component<
  IMessageReplyCommandBarProps,
  IMessageReplyCommandBarState
> {
  constructor(props: IMessageReplyCommandBarProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, onReply /*, onReplyAll */, onForward } = this.props;
    const [classNames, subComponentStyles] =
      getMessageReplyCommandBarClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    /*
            {
              key: "replyall",
              name: i18n.t("messages:commands:replyall"),
              iconProps: {
                iconName: "MailReplyAll",
              },
              onClick: onReplyAll,
            } 
    */
    return (
      <div className={classNames.root}>
        <CommandBar
          styles={subComponentStyles?.commandBar}
          items={[
            {
              key: "reply",
              name: i18n.t("messages:commands:reply"),
              iconProps: {
                iconName: "MailReply",
              },
              onClick: onReply,
            },
            {
              key: "forward",
              name: i18n.t("messages:commands:forward"),
              iconProps: {
                iconName: "MailForward",
              },
              onClick: onForward,
            },
          ]}
        />
      </div>
    );
  }
}
