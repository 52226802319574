import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IFlightCheckoutErrorPageSubComponentStyles {}

export interface IFlightCheckoutErrorPageStyles
  extends IAmigoBaseStyles<IFlightCheckoutErrorPageSubComponentStyles> {}

export interface IFlightCheckoutErrorPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightCheckoutErrorPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightCheckoutErrorPageStyleProps,
    IFlightCheckoutErrorPageStyles
  >,
  props?: IFlightCheckoutErrorPageStyleProps
): [
  IProcessedStyleSet<IFlightCheckoutErrorPageStyles>,
  IFlightCheckoutErrorPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightCheckoutErrorPageStyleProps,
  IFlightCheckoutErrorPageStyles
> = (
  _props: IFlightCheckoutErrorPageStyleProps
): IFlightCheckoutErrorPageStyles => {
  return {
    root: ["amigo-FlightCheckoutErrorPage", {}],
  };
};
