import { Text, DatePicker, Stack, StackItem } from "@fluentui/react";
import * as React from "react";
import i18n from "src/services/i18n";
import { getDateRangePickerClassNames } from "./DateRangePicker.styles";
import {
  IDateRangePickerState,
  IDateRangePickerProps,
} from "./DateRangePicker.types";
import Moment from "moment";

export class DateRangePickerComponent extends React.Component<
  IDateRangePickerProps,
  IDateRangePickerState
> {
  constructor(props: IDateRangePickerProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, errors, mobile, tablet } = this.props;
    const [classNames /* , subComponentStyles */] =
      getDateRangePickerClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const horizontal = false;
    return (
      <>
        <Text styles={{ root: { fontWeight: 600, marginBottom: "4px" } }}>
          {i18n.t("flights:flightdate")}
        </Text>
        <Stack
          horizontal
          className={classNames.root}
          tokens={{ childrenGap: mobile || tablet ? 10 : 20 }}
        >
          <StackItem>
            <DatePicker
              textField={
                horizontal
                  ? {
                      styles: {
                        fieldGroup: {
                          minHeight: "47px",
                          marginTop: "-4px",
                        },
                      },
                    }
                  : undefined
              }
              allowTextInput={true}
              isRequired={true}
              title={""}
              formatDate={(date?: Date) => {
                if (!date) return "";
                return Moment(date).locale(i18n.getLanguage()).format("LL");
              }}
              minDate={Moment().add("d", 2).toDate()}
              showGoToToday={false}
              strings={i18n.CalendarStrings()}
              onSelectDate={(date: Date | null | undefined) => {
                const requestDate: Date | undefined =
                  date === null ? undefined : date;
                this.props.onCriteriaChanged({
                  ...this.props.criteria,
                  dates: {
                    start: requestDate,
                    end:
                      requestDate === undefined
                        ? this.props.criteria.dates?.end
                        : this.props.criteria.dates?.start === undefined
                        ? this.props.criteria.dates?.end
                        : this.props.criteria.dates?.end === undefined
                        ? undefined
                        : requestDate > this.props.criteria.dates.end
                        ? requestDate
                        : this.props.criteria.dates.end,
                  } as any,
                });
              }}
              value={this.props.criteria.dates?.start}
            />
          </StackItem>
          <StackItem>
            <DatePicker
              textField={
                horizontal
                  ? {
                      styles: {
                        fieldGroup: {
                          minHeight: "47px",
                          marginTop: "-4px",
                        },
                        subComponentStyles: {
                          label: {
                            root: {
                              visibility: "hidden",
                            },
                          },
                        },
                      },
                    }
                  : {
                      styles: {
                        subComponentStyles: {
                          label: {
                            root: {
                              visibility: "hidden",
                            },
                          },
                        },
                      },
                    }
              }
              allowTextInput={true}
              isRequired={true}
              title={""}
              formatDate={(date?: Date) => {
                if (!date) return "";
                return Moment(date).locale(i18n.getLanguage()).format("LL");
              }}
              minDate={
                this.props.criteria.dates?.start === undefined
                  ? Moment().add("d", 2).toDate()
                  : Moment(this.props.criteria.dates?.start).toDate()
              }
              showGoToToday={false}
              strings={i18n.CalendarStrings()}
              onSelectDate={(date: Date | null | undefined) => {
                const requestDate: Date | undefined =
                  date === null ? undefined : date;
                this.props.onCriteriaChanged({
                  ...this.props.criteria,
                  dates: {
                    end: requestDate,
                    start:
                      requestDate === undefined
                        ? this.props.criteria.dates?.start
                        : this.props.criteria.dates?.end === undefined
                        ? this.props.criteria.dates?.start
                        : this.props.criteria.dates?.start === undefined
                        ? undefined
                        : requestDate < this.props.criteria.dates.start
                        ? requestDate
                        : this.props.criteria.dates.start,
                  } as any,
                });
              }}
              value={this.props.criteria.dates?.end}
            />
          </StackItem>
        </Stack>

        {errors?.date && (
          <div>
            <Text
              styles={{
                root: { color: this.props.theme.palette.redDark },
              }}
            >
              {errors?.date}
            </Text>
          </div>
        )}
      </>
    );
  }
}
