import * as React from "react";
import { getUnauthenticatedLayoutClassNames } from "./UnauthenticatedLayout.styles";
import {
  IUnauthenticatedLayoutState,
  IUnauthenticatedLayoutProps,
} from "./UnauthenticatedLayout.types";

export class UnauthenticatedLayoutComponent extends React.Component<
  IUnauthenticatedLayoutProps,
  IUnauthenticatedLayoutState
> {
  render(): JSX.Element {
    const { styles, className } = this.props;
    const [classNames] = getUnauthenticatedLayoutClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={`${className} ${classNames.root}`}>
        {this.props.tokens?.logoImage !== undefined && (
          <img src={this.props.tokens.logoImage} className={classNames.logo} />
        )}
        <div>
          <div className={classNames.background} role="presentation">
            <div className={classNames.backgroundImage}></div>
          </div>
        </div>
        <div className={classNames.backgroundLayer}></div>
        <div className={classNames.pageControl}>{this.props.children}</div>
      </div>
    );
  }
}
