import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { ICustomSpinnerStyles } from "src/components/CustomSpinner";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import { MediaQueries } from "src/components/MediaQueries";
import {
  INewPaymentCommandBarStyleProps,
  INewPaymentCommandBarStyles,
} from "src/pages/Billing/Controls";

export interface IFlightCheckoutPageSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  commandBar?: IStyleFunctionOrObject<
    INewPaymentCommandBarStyleProps,
    INewPaymentCommandBarStyles
  >;
  spinner?: ICustomSpinnerStyles;
}

export interface IFlightCheckoutPageStyles
  extends IAmigoBaseStyles<IFlightCheckoutPageSubComponentStyles> {}

export interface IFlightCheckoutPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightCheckoutPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightCheckoutPageStyleProps,
    IFlightCheckoutPageStyles
  >,
  props?: IFlightCheckoutPageStyleProps
): [
  IProcessedStyleSet<IFlightCheckoutPageStyles>,
  IFlightCheckoutPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightCheckoutPageStyleProps,
  IFlightCheckoutPageStyles
> = (_props: IFlightCheckoutPageStyleProps): IFlightCheckoutPageStyles => {
  return {
    root: ["amigo-FlightCheckoutPage", {}],
    subComponentStyles: {
      commandBar: {
        root: {
          backgroundColor: "transparent",
        },
      },
      spinner: {
        root: {
          marginTop: "50px",
          [MediaQueries.mobile]: {
            marginTop: "10px",
          },
        },
      },
    },
  };
};
