import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import {
  IEnterpriseDocumentCardStyleProps,
  IEnterpriseDocumentCardStyles,
} from "src/pages/Enterprises/EnterpriseDocumentCard";

export interface IRequestDocumentsControlSubComponentStyles {
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
  gridCard?: IStyleFunctionOrObject<
    IEnterpriseDocumentCardStyleProps,
    IEnterpriseDocumentCardStyles
  >;
}

export interface IRequestDocumentsControlStyles
  extends IAmigoBaseStyles<IRequestDocumentsControlSubComponentStyles> {}

export interface IRequestDocumentsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestDocumentsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestDocumentsControlStyleProps,
    IRequestDocumentsControlStyles
  >,
  props?: IRequestDocumentsControlStyleProps
): [
  IProcessedStyleSet<IRequestDocumentsControlStyles>,
  IRequestDocumentsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestDocumentsControlStyleProps,
  IRequestDocumentsControlStyles
> = (
  _props: IRequestDocumentsControlStyleProps
): IRequestDocumentsControlStyles => {
  return {
    root: [
      "amigo-RequestDocumentsControl",
      {
        margin: "15px",
        boxShadow:
          "rgb(0 0 0 / 13%) 0px 1.6px 3.6px 0px, rgb(0 0 0 / 11%) 0px 0.3px 0.9px 0px",
      },
    ],
  };
};
