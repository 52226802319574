import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface IFlightConfirmationPageSubComponentStyles {}

export interface IFlightConfirmationPageStyles
  extends IAmigoBaseStyles<IFlightConfirmationPageSubComponentStyles> {}

export interface IFlightConfirmationPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getFlightConfirmationPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IFlightConfirmationPageStyleProps,
    IFlightConfirmationPageStyles
  >,
  props?: IFlightConfirmationPageStyleProps
): [
  IProcessedStyleSet<IFlightConfirmationPageStyles>,
  IFlightConfirmationPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IFlightConfirmationPageStyleProps,
  IFlightConfirmationPageStyles
> = (
  _props: IFlightConfirmationPageStyleProps
): IFlightConfirmationPageStyles => {
  return {
    root: [
      "amigo-FlightConfirmationPage",
      {
        marginLeft: "10px",
        [MediaQueries.mobile]: {
          marginLeft: "5px",
        },
      },
    ],
  };
};
