import { Text } from "@fluentui/react";
import * as React from "react";
import { CreditCardIcon } from "src/components/CreditCardIcon";
import { GridViewCard } from "src/components/GridViewCard";
import { getPaymentMethodCardClassNames } from "./PaymentMethodCard.styles";
import {
  IPaymentMethodCardProps,
  IPaymentMethodCardState,
} from "./PaymentMethodCard.types";

export class PaymentMethodCardComponent extends React.Component<
  IPaymentMethodCardProps,
  IPaymentMethodCardState
> {
  render(): JSX.Element {
    const { styles, item, footerIconName, columnWidth } = this.props;
    const [classNames, subComponentStyles] = getPaymentMethodCardClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    const _this = this;
    const selected =
      (this.props.selection?.getSelection() ?? []).filter(
        (x) => x.key === item.key
      ).length > 0;

    return (
      <GridViewCard
        {...this.props}
        styles={subComponentStyles?.gridViewCard}
        boxed={true}
        title={item.card}
        columnWidth={columnWidth}
        footerText={item.createdOn}
        footerIconName={footerIconName ?? "Calendar"}
        selected={selected}
        onClick={() => {
          _this.props.onClick && _this.props.onClick(item);
        }}
        onSelected={
          _this.props.onSelected
            ? () => {
                _this.props.onSelected && _this.props.onSelected(item);
              }
            : undefined
        }
        icons={
          <>
            <CreditCardIcon card={item.cardType} />
          </>
        }
      >
        <Text
          className={classNames.root}
          variant="small"
          styles={this.props.tokens.defaultCardTextStyle}
        >
          {item.cardType}
        </Text>
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.name}
        </Text>
        <Text variant="small" styles={this.props.tokens.defaultCardTextStyle}>
          {item.createdBy}
        </Text>
      </GridViewCard>
    );
  }
}
