import * as React from "react";
import { getMessagesPageClassNames } from "./MessagesPage.styles";
import { IMessagesPageState, IMessagesPageProps } from "./MessagesPage.types";
import i18n from "src/services/i18n";
import MessagesService from "src/services/Messages/MessagesService";
import { Pivot, PivotItem } from "@fluentui/react";
import { MessagesFolder } from "../MessagesFolder";

export class MessagesPageComponent extends React.Component<
  IMessagesPageProps,
  IMessagesPageState
> {
  constructor(props: IMessagesPageProps) {
    super(props);
    this.state = {
      folder: "Inbox",
    };
    MessagesService.init(undefined);
    this.getStats();
  }

  private getStats = (): void => {
    MessagesService.getStatistics()
      .then((x) => {
        this.setState({
          unread: !x || x.unread === 0 ? undefined : x.unread,
          drafts: !x || x.drafts === 0 ? undefined : x.drafts,
        });
      })
      .catch((_x) => {
        this.setState({
          unread: undefined,
          drafts: undefined,
        });
      });
  };

  render(): JSX.Element {
    const { styles, mobile } = this.props;
    const [classNames] = getMessagesPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <h1
          style={{
            margin: "0 5px 0 5px",
            padding: 0,
          }}
        >
          {i18n.t("messages:title")}
        </h1>
        <div>
          <Pivot
            onLinkClick={(item?: PivotItem) => {
              if (item?.props?.itemKey) {
                MessagesService.init(this.state.folder);
                this.setState({
                  folder: item.props.itemKey,
                });
              }
            }}
            aria-label={i18n.t("messages:title")}
            selectedKey={this.state.folder}
          >
            <PivotItem
              key={"Inbox"}
              itemKey={"Inbox"}
              headerText={i18n.t("messages:inbox")}
              itemCount={this.state.unread}
              itemIcon="Inbox"
            >
              <MessagesFolder
                {...this.props}
                styles={undefined}
                onUpdate={this.getStats}
                onDelete={this.getStats}
                folder="Inbox"
              />
            </PivotItem>
            <PivotItem
              key={"Drafts"}
              itemKey={"Drafts"}
              itemCount={this.state.drafts}
              headerText={mobile ? undefined : i18n.t("messages:drafts")}
              itemIcon="PageEdit"
            >
              <MessagesFolder
                {...this.props}
                styles={undefined}
                onUpdate={this.getStats}
                onDelete={this.getStats}
                folder="Drafts"
              />
            </PivotItem>
            <PivotItem
              key={"Sent"}
              itemKey={"Sent"}
              headerText={mobile ? "" : i18n.t("messages:sent")}
              itemIcon="Mail"
            >
              <MessagesFolder
                {...this.props}
                styles={undefined}
                onUpdate={this.getStats}
                onDelete={this.getStats}
                folder="Sent"
              />
            </PivotItem>
          </Pivot>
          <div></div>
          <div></div>
        </div>
      </div>
    );
  }
}
