import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IAssistedMessageEditionContainerSubComponentStyles {}

export interface IAssistedMessageEditionContainerStyles
  extends IAmigoBaseStyles<IAssistedMessageEditionContainerSubComponentStyles> {}

export interface IAssistedMessageEditionContainerStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getAssistedMessageEditionContainerClassNames = (
  styles?: IStyleFunctionOrObject<
    IAssistedMessageEditionContainerStyleProps,
    IAssistedMessageEditionContainerStyles
  >,
  props?: IAssistedMessageEditionContainerStyleProps
): [
  IProcessedStyleSet<IAssistedMessageEditionContainerStyles>,
  IAssistedMessageEditionContainerSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IAssistedMessageEditionContainerStyleProps,
  IAssistedMessageEditionContainerStyles
> = (
  _props: IAssistedMessageEditionContainerStyleProps
): IAssistedMessageEditionContainerStyles => {
  return {
    root: ["amigo-AssistedMessageEditionContainer", {}],
  };
};
