import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IUploadFilesDialogSubComponentStyles {
  iconStyles?: IIconStyles;
}

export interface IUploadFilesDialogStyles
  extends IAmigoBaseStyles<IUploadFilesDialogSubComponentStyles> {
  footerSection?: IStyle;
  footerButton?: IStyle;
}

export interface IUploadFilesDialogStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getUploadFilesDialogClassNames = (
  styles?: IStyleFunctionOrObject<
    IUploadFilesDialogStyleProps,
    IUploadFilesDialogStyles
  >,
  props?: IUploadFilesDialogStyleProps
): [
  IProcessedStyleSet<IUploadFilesDialogStyles>,
  IUploadFilesDialogSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUploadFilesDialogStyleProps,
  IUploadFilesDialogStyles
> = (_props: IUploadFilesDialogStyleProps): IUploadFilesDialogStyles => {
  return {
    root: ["amigo-UploadFilesDialog", {}],
    footerSection: {
      padding: "10px",
      width: "100%",
    },
    footerButton: {
      width: "100%",
    },
  };
};
