import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantDocumentsControlProps } from "./ParticipantDocumentsControl.types";
import {
  getStyles,
  IParticipantDocumentsControlStyleProps,
  IParticipantDocumentsControlStyles,
} from "./ParticipantDocumentsControl.styles";
import { ParticipantDocumentsControlComponent } from "./ParticipantDocumentsControl.base";

/**
 * ParticipantDocumentsControl
 */
export const ParticipantDocumentsControl = styled<
  IParticipantDocumentsControlProps,
  IParticipantDocumentsControlStyleProps,
  IParticipantDocumentsControlStyles
>(ParticipantDocumentsControlComponent, getStyles);
