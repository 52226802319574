import {
  IIconStyleProps,
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  newAlertIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
  createdOnLabel?: any /* IStylesFunction<
    ITextProps,
    ITextTokens,
    IComponentStyles<ITextSlots>
  >; */;
  seenOnLabel?: any /* IStylesFunction<
    ITextProps,
    ITextTokens,
    IComponentStyles<ITextSlots>
  >; */;
}

export interface IParticipantCardStyles
  extends IAmigoBaseStyles<IParticipantCardSubComponentStyles> {}

export interface IParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantCardStyleProps,
    IParticipantCardStyles
  >,
  props?: IParticipantCardStyleProps
): [
  IProcessedStyleSet<IParticipantCardStyles>,
  IParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantCardStyleProps,
  IParticipantCardStyles
> = (props: IParticipantCardStyleProps): IParticipantCardStyles => {
  return {
    subComponentStyles: {
      topIcon: props.tokens?.defaultIconStyles,
    },
  };
};
