import { IObjectWithKey, Selection } from "@fluentui/react";
import InfiniteScroll from "react-infinite-scroll-component";
import { IQueryFilter } from "src/models/IQueryFilter";
import { FilterPane } from "../FilterPane";
import { IGridViewProps, IGridViewState } from "./GridView.types";
import { GridViewCommandBar } from "./GridViewCommandBar";
import GridViewDialog from "./GridViewDialog";
import GridViewHelper from "./GridViewHelper";
import GridViewOptionsBuilder from "./GridViewOptionsBuilder";

const ensureSelection = (
  selection: Selection,
  item: IObjectWithKey,
  single?: boolean
): void => {
  if (single ?? false) {
    const items = selection.getItems();
    items.forEach((x) => {
      x.key && selection.setKeySelected(x.key.toString(), false, false);
    });
  }
  if (selection.getItems().filter((x) => x.key === item.key).length === 0) {
    const selected = selection.getSelection();
    selection.setItems([item].concat(selection.getItems()), false);
    selected.forEach((x) => {
      x.key && selection.setKeySelected(x.key.toString(), true, false);
    });
  }
};

export type { IQueryFilter, IGridViewProps, IGridViewState };
export {
  ensureSelection,
  InfiniteScroll,
  FilterPane,
  GridViewOptionsBuilder,
  GridViewHelper,
  GridViewCommandBar,
  GridViewDialog,
};
