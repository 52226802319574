import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
  getComponentClassNames,
} from "../Base";

export interface ICountryIconSubComponentStyles {}

export interface ICountryIconStyles
  extends IComponentBaseStyles<ICountryIconSubComponentStyles> {}

export interface ICountryIconStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getCountryIconClassNames = (
  styles?: IStyleFunctionOrObject<ICountryIconStyleProps, ICountryIconStyles>,
  props?: ICountryIconStyleProps
): [
  IProcessedStyleSet<ICountryIconStyles>,
  ICountryIconSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ICountryIconStyleProps,
  ICountryIconStyles
> = (_props: ICountryIconStyleProps): ICountryIconStyles => {
  return {};
};
