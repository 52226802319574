import { styled } from "@fluentui/react/lib/Utilities";
import { IPaymentErrorPageProps } from "./PaymentErrorPage.types";
import {
  getStyles,
  IPaymentErrorPageStyleProps,
  IPaymentErrorPageStyles,
} from "./PaymentErrorPage.styles";
import { PaymentErrorPageComponent } from "./PaymentErrorPage.base";

/**
 * PaymentErrorPage
 */
export const PaymentErrorPage = styled<
  IPaymentErrorPageProps,
  IPaymentErrorPageStyleProps,
  IPaymentErrorPageStyles
>(PaymentErrorPageComponent, getStyles);
