import { styled } from "@fluentui/react/lib/Utilities";
import { IAirportSelectorProps } from "./AirportSelector.types";
import {
  getStyles,
  IAirportSelectorStyleProps,
  IAirportSelectorStyles,
} from "./AirportSelector.styles";
import { AirportSelectorComponent } from "./AirportSelector.base";

/**
 * AirportSelector
 */
export const AirportSelector = styled<
  IAirportSelectorProps,
  IAirportSelectorStyleProps,
  IAirportSelectorStyles
>(AirportSelectorComponent, getStyles);
