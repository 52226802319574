import { styled } from "@fluentui/react/lib/Utilities";
import { IEnterpriseContactCardProps } from "./EnterpriseContactCard.types";
import {
  getStyles,
  IEnterpriseContactCardStyleProps,
  IEnterpriseContactCardStyles,
} from "./EnterpriseContactCard.styles";
import { EnterpriseContactCardComponent } from "./EnterpriseContactCard.base";

/**
 * EnterpriseContactCard description
 */
export const EnterpriseContactCard = styled<
  IEnterpriseContactCardProps,
  IEnterpriseContactCardStyleProps,
  IEnterpriseContactCardStyles
>(EnterpriseContactCardComponent, getStyles);
