import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IRequestsParticipantsCardStyleProps,
  IRequestsParticipantsCardStyles,
} from "../RequestsParticipantsCard/RequestsParticipantsCard.styles";

export interface IRequestParticipantsControlSubComponentStyles {
  gridCard?: IStyleFunctionOrObject<
    IRequestsParticipantsCardStyleProps,
    IRequestsParticipantsCardStyles
  >;
}

export interface IRequestParticipantsControlStyles
  extends IAmigoBaseStyles<IRequestParticipantsControlSubComponentStyles> {}

export interface IRequestParticipantsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getRequestParticipantsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IRequestParticipantsControlStyleProps,
    IRequestParticipantsControlStyles
  >,
  props?: IRequestParticipantsControlStyleProps
): [
  IProcessedStyleSet<IRequestParticipantsControlStyles>,
  IRequestParticipantsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IRequestParticipantsControlStyleProps,
  IRequestParticipantsControlStyles
> = (
  _props: IRequestParticipantsControlStyleProps
): IRequestParticipantsControlStyles => {
  return {};
};
