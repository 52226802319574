import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IIconStyleProps,
  IIconStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";

export interface IPaymentElementCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IStyleFunctionOrObject<IIconStyleProps, IIconStyles>;
}

export interface IPaymentElementCardStyles
  extends IAmigoBaseStyles<IPaymentElementCardSubComponentStyles> {}

export interface IPaymentElementCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentElementCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentElementCardStyleProps,
    IPaymentElementCardStyles
  >,
  props?: IPaymentElementCardStyleProps
): [
  IProcessedStyleSet<IPaymentElementCardStyles>,
  IPaymentElementCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentElementCardStyleProps,
  IPaymentElementCardStyles
> = (_props: IPaymentElementCardStyleProps): IPaymentElementCardStyles => {
  return {};
};
