import * as React from "react";
import { getCommunicationTypeSelectorClassNames } from "./CommunicationTypeSelector.styles";
import {
  ICommunicationTypeSelectorState,
  ICommunicationTypeSelectorProps,
} from "./CommunicationTypeSelector.types";
import {
  Dropdown,
  IDropdownOption,
  PanelType,
  Stack,
  Text,
  Icon,
  StackItem,
  IDropdownProps,
} from "@fluentui/react";
import i18n from "src/services/i18n";
import { CommunicationTypes } from "src/models/Messages/CommunicationTypes";

export class CommunicationTypeSelectorComponent extends React.Component<
  ICommunicationTypeSelectorProps,
  ICommunicationTypeSelectorState
> {
  private onRenderOption = (option?: IDropdownOption): JSX.Element => {
    return option === undefined ? (
      <div></div>
    ) : (
      <Stack horizontal tokens={{ childrenGap: 20 }}>
        {option.data && option.data.icon && (
          <Icon
            style={{ marginRight: "8px" }}
            iconName={option.data.icon}
            styles={{
              root: {
                fontSize: "22px",
              },
            }}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <StackItem>
          <Stack tokens={{ childrenGap: 1 }}>
            <Text>{option.text}</Text>
            <Text variant={"small"}>{option.data.subText}</Text>
          </Stack>
        </StackItem>
      </Stack>
    );
  };

  private onRenderPlaceholder = (props?: IDropdownProps): JSX.Element => {
    const { communicationType } = this.props;
    const option = props?.options.filter((x) => x.key === communicationType)[0];
    return (
      <div>
        <Icon
          style={{ marginRight: "8px" }}
          iconName={option?.data.icon}
          aria-hidden="true"
        />
        <Text>{props?.placeholder}</Text>
      </div>
    );
  };

  private onRenderTitle = (options?: IDropdownOption[]): JSX.Element => {
    const { communicationType } = this.props;
    const option = options?.filter((x) => x.key === communicationType)[0];
    return (
      <div>
        {option !== undefined && option.data && option.data.icon && (
          <Icon
            style={{ marginRight: "8px" }}
            iconName={option.data.icon}
            aria-hidden="true"
            title={option.data.icon}
          />
        )}
        <span>{option?.text}</span>
      </div>
    );
  };

  render(): JSX.Element {
    const { styles, mobile, disabled, communicationType, onChange } =
      this.props;
    const [classNames] = getCommunicationTypeSelectorClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <Dropdown
          label={i18n.t("messages:communicationtype")}
          disabled={disabled}
          required
          onChange={(
            _event: React.FormEvent<HTMLDivElement>,
            option?: IDropdownOption,
            _index?: number
          ) => {
            option && onChange(option.key?.toString() ?? "1");
          }}
          selectedKey={communicationType}
          onRenderOption={this.onRenderOption}
          onRenderTitle={this.onRenderTitle}
          panelProps={
            mobile
              ? {
                  type: PanelType.custom,
                  customWidth: "100vw",
                }
              : undefined
          }
          onRenderPlaceholder={this.onRenderPlaceholder}
          styles={{
            dropdownItem: {
              minHeight: mobile ? "112px" : "48px",
            },
            dropdownItemSelected: {
              minHeight: mobile ? "112px" : "48px",
            },
          }}
          options={[
            {
              key: CommunicationTypes.Standard,
              id: CommunicationTypes.Standard,
              text: i18n.t("messages:type:standard"),
              data: {
                icon: "Mail",
                subText: i18n.t("messages:type:standard:desc"),
              },
            },
            {
              key: CommunicationTypes.ArrivalRequest,
              id: CommunicationTypes.ArrivalRequest,
              text: i18n.t("messages:type:newarrival"),
              data: {
                icon: "Arrivals",
                subText: i18n.t("messages:type:newarrival:desc"),
              },
            },
            {
              key: CommunicationTypes.DepartureRequest,
              id: CommunicationTypes.DepartureRequest,
              text: i18n.t("messages:type:newdeparture"),
              data: {
                icon: "NavigateBack",
                subText: i18n.t("messages:type:newdeparture:desc"),
              },
            },
            {
              key: CommunicationTypes.ChangeDepartureRequest,
              id: CommunicationTypes.ChangeDepartureRequest,
              text: i18n.t("messages:type:changedeparture"),
              data: {
                icon: "PageEdit",
                subText: i18n.t("messages:type:changedeparture:desc"),
              },
            },
            {
              key: CommunicationTypes.TransportArrivalRequest,
              id: CommunicationTypes.TransportArrivalRequest,
              text: i18n.t("messages:type:newarrival:transport"),
              data: {
                icon: "Bus",
                subText: i18n.t("messages:type:newarrival:transport:desc"),
              },
            },
            {
              key: CommunicationTypes.InspectionRequest,
              id: CommunicationTypes.InspectionRequest,
              text: i18n.t("messages:type:inspection"),
              data: {
                icon: "ImageSearch",
                subText: i18n.t("messages:type:inspection:desc"),
              },
            },
          ]}
        />
      </div>
    );
  }
}
