import i18n from "src/services/i18n";
import { ISchemaObject } from "src/utils/validator";
import { IUser } from "../User";

export interface IAttachmentSummary {
  id: number;
  name: string;
  type: string;
  size: string;
}

export interface IMessageType {
  id: number;
  code: string;
  name: string;
}

export interface IMessageSummary {
  id: number;
  attachments: IAttachmentSummary[];
  acknowledged: boolean;
  message: string;
  subject: string;
  timestamp: Date;
  from: IUser;
  type: IMessageType;
  to: IUser[];
  designations: IUser[];
  itemId: number;
  response: boolean;
}

export interface IAttachmentModel extends IAttachmentSummary {
  data: any;
}

export interface INewMessageModel {
  id: number;
  itemId: number;
  enterpriseId: number;
  type: number;
  attachments: IAttachmentModel[];
  message: string;
  subject: string;
  recipients: string[];
  designations: string[];
}

export const NewMessageModelSchema = (
  withDesignations: boolean
): ISchemaObject => {
  return {
    name: "message",
    plural: "messages",
    label: "Message",
    fields: {
      recipients: {
        name: "recipients",
        label: i18n.t("messages:recipients"),
        required: !withDesignations,
        min: withDesignations ? 0 : 1,
        pattern: "",
        errorMessage: i18n.t("messages:recipients:error"),
        type: "array",
      },
      subject: {
        name: "subject",
        label: i18n.t("messages:subject"),
        required: true,
        pattern: "",
        errorMessage: i18n.t("messages:subject:error"),
        type: "string",
      },
      message: {
        name: "message",
        label: i18n.t("messages:message"),
        required: true,
        pattern: "",
        errorMessage: i18n.t("messages:message:error"),
        type: "string",
      },
    },
  };
};
