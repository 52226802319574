import * as React from "react";
import { getLogoutPageClassNames } from "./LogoutPage.styles";
import { ILogoutPageState, ILogoutPageProps } from "./LogoutPage.types";
import { IStackStyles, Stack } from "@fluentui/react";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import { Redirect } from "react-router";
import { MediaQueries } from "src/components/MediaQueries";

const stackStyles: IStackStyles = {
  root: {
    marginTop: "120px",
    marginBottom: 100,
    height: 380,
    [MediaQueries.mobile]: {
      height: 250,
      marginTop: "60px",
    },
  },
};

export class LogoutPageComponent extends React.Component<
  ILogoutPageProps,
  ILogoutPageState
> {
  constructor(props: ILogoutPageProps) {
    super(props);
    this.state = { loggedout: false };
    AuthService.logOut()
      .catch((_x) => {
        const _this = this;
        setTimeout(() => {
          _this.setState({ loggedout: true });
        }, 1200);
        if (this.props.appInsights != null)
          this.props.appInsights.clearAuthenticatedUserContext();
      })
      .then((_v) => {
        const _this = this;
        setTimeout(() => {
          _this.setState({ loggedout: true });
          if (this.props.appInsights != null)
            this.props.appInsights.clearAuthenticatedUserContext();
        }, 1200);
      });
  }

  spinner(): JSX.Element {
    return (
      <div style={{ padding: "5px" }}>
        <div style={{ height: "50px" }}></div>
        <div className="clear-both"></div>
        <div style={{ width: "180px", marginLeft: "10px", float: "left" }}>
          <div className="nvx-loader">
            <div className="nvx-loader"></div>
          </div>
        </div>
        {this.props.mobile ? (
          <div className="clear-both">
            <h2>{i18n.t("logout:message")}</h2>
          </div>
        ) : (
          <div style={{ float: "left", marginLeft: "10px" }}>
            <h1>{i18n.t("logout:message")}</h1>
          </div>
        )}

        <div className="clear-both"></div>
      </div>
    );
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getLogoutPageClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return this.state.loggedout ? (
      <Redirect
        to={{
          pathname: "/reload",
          state: { location: "/connexion" },
        }}
      />
    ) : (
      <>
        <div style={{ height: "50px" }}></div>
        <Stack
          className={classNames.root}
          styles={stackStyles}
          horizontalAlign="center"
          verticalAlign="center"
          verticalFill
        >
          {this.spinner()}
        </Stack>
      </>
    );
  }
}
