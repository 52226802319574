import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportRequestsDepartureSectionProps } from "./TransportRequestsDepartureSection.types";
import {
  getStyles,
  ITransportRequestsDepartureSectionStyleProps,
  ITransportRequestsDepartureSectionStyles,
} from "./TransportRequestsDepartureSection.styles";
import { TransportRequestsDepartureSectionComponent } from "./TransportRequestsDepartureSection.base";

/**
 * TransportRequestsDepartureSection
 */
export const TransportRequestsDepartureSection = styled<
  ITransportRequestsDepartureSectionProps,
  ITransportRequestsDepartureSectionStyleProps,
  ITransportRequestsDepartureSectionStyles
>(TransportRequestsDepartureSectionComponent, getStyles);
