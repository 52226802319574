import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IPaymentDetailListSubComponentStyles {}

export interface IPaymentDetailListStyles
  extends IAmigoBaseStyles<IPaymentDetailListSubComponentStyles> {}

export interface IPaymentDetailListStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentDetailListClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentDetailListStyleProps,
    IPaymentDetailListStyles
  >,
  props?: IPaymentDetailListStyleProps
): [
  IProcessedStyleSet<IPaymentDetailListStyles>,
  IPaymentDetailListSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentDetailListStyleProps,
  IPaymentDetailListStyles
> = (_props: IPaymentDetailListStyleProps): IPaymentDetailListStyles => {
  return {
    root: ["amigo-PaymentDetailList", {}],
  };
};
