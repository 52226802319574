export interface IAircraftType {
  code: string;
  name: string;
}

export const AircraftTypes: IAircraftType[] = [
  {
    code: "100",
    name: "FOKKER 100",
  },
  {
    code: "115",
    name: "CDN DND DASH 5",
  },
  {
    code: "130",
    name: "CDN DND LOCKHEED",
  },
  {
    code: "138",
    name: "CDN DND DASH 6",
  },
  {
    code: "140",
    name: "CDN DND",
  },
  {
    code: "141",
    name: "BRITISH AEROSPACE 141",
  },
  {
    code: "142",
    name: "BRITISH AEROSPACE",
  },
  {
    code: "143",
    name: "BRITISH AEROSPACE",
  },
  {
    code: "144",
    name: "CDN DND",
  },
  {
    code: "146",
    name: "BRITISH AEROSPACE 146",
  },
  {
    code: "150",
    name: "CDN DND AIRBUS",
  },
  {
    code: "223",
    name: "AIRBUS A220-300",
  },
  {
    code: "290",
    name: "EMBRAER 190 E2",
  },
  {
    code: "310",
    name: "AIRBUS 310",
  },
  {
    code: "312",
    name: "AIRBUS 312",
  },
  {
    code: "313",
    name: "AIRBUS 313",
  },
  {
    code: "318",
    name: "AIRBUS A318",
  },
  {
    code: "319",
    name: "AIRBUS A319",
  },
  {
    code: "320",
    name: "AIRBUS A320",
  },
  {
    code: "321",
    name: "AIRBUS A321",
  },
  {
    code: "32A",
    name: "AIRBUS A320 SHARKLETS",
  },
  {
    code: "32B",
    name: "AIRBUS A321 SHARKLETS",
  },
  {
    code: "32D",
    name: "AIRBUS A319",
  },
  {
    code: "32N",
    name: "AIRBUS A320NEO",
  },
  {
    code: "32Q",
    name: "AIRBUS A321NEO",
  },
  {
    code: "32S",
    name: "AIRBUS A321",
  },
  {
    code: "330",
    name: "AIRBUS A330",
  },
  {
    code: "332",
    name: "AIRBUS A330-200",
  },
  {
    code: "333",
    name: "AIRBUS 330-300",
  },
  {
    code: "339",
    name: "AIRBUS A330-900NEO",
  },
  {
    code: "340",
    name: "AIRBUS A340",
  },
  {
    code: "342",
    name: "AIRBUS A340",
  },
  {
    code: "343",
    name: "AIRBUS A340",
  },
  {
    code: "345",
    name: "AIRBUS A340-500",
  },
  {
    code: "346",
    name: "AIRBUS A340-600",
  },
  {
    code: "350",
    name: "AIRBUS A350",
  },
  {
    code: "351",
    name: "AIRBUS A351",
  },
  {
    code: "358",
    name: "AIRBUS A358",
  },
  {
    code: "359",
    name: "AIRBUS A359",
  },
  {
    code: "380",
    name: "AIRBUS A380-800",
  },
  {
    code: "388",
    name: "AIRBUS A380-800",
  },
  {
    code: "717",
    name: "BOEING 717",
  },
  {
    code: "722",
    name: "BOEING 727",
  },
  {
    code: "727",
    name: "BOEING 727",
  },
  {
    code: "72A",
    name: "BOEING 727 ADVANCED",
  },
  {
    code: "72B",
    name: "BOEING 727-100 MIXED CONFIG",
  },
  {
    code: "72M",
    name: "BOEING 727-100 PSGR-FRT",
  },
  {
    code: "72S",
    name: "BOEING 727 STRETCH",
  },
  {
    code: "732",
    name: "BOEING 737 200 PASSENGER",
  },
  {
    code: "733",
    name: "BOEING 737-300",
  },
  {
    code: "734",
    name: "BOEING 737-400",
  },
  {
    code: "735",
    name: "BOEING 737-500",
  },
  {
    code: "736",
    name: "BOEING 737-600",
  },
  {
    code: "737",
    name: "BOEING 737",
  },
  {
    code: "738",
    name: "BOEING 737-800",
  },
  {
    code: "739",
    name: "BOEING 737-900",
  },
  {
    code: "73A",
    name: "BOEING 737-200 ADVANCED",
  },
  {
    code: "73C",
    name: "BOEING 737-300",
  },
  {
    code: "73E",
    name: "BOEING 737-500 WINGLETS",
  },
  {
    code: "73G",
    name: "BOEING 737-700",
  },
  {
    code: "73H",
    name: "BOEING 737 800",
  },
  {
    code: "73J",
    name: "BOEING 737",
  },
  {
    code: "73L",
    name: "BOEING 737-200 MIXED CONFIGURATION",
  },
  {
    code: "73M",
    name: "BOEING 737",
  },
  {
    code: "73N",
    name: "BOEING 737-300",
  },
  {
    code: "73P",
    name: "BOEING 737-400 FREIGHTER",
  },
  {
    code: "73Q",
    name: "BOEING 737-400",
  },
  {
    code: "73R",
    name: "BOEING 737-700 MIXED CONFIG",
  },
  {
    code: "73S",
    name: "BOEING 737 STRETCH",
  },
  {
    code: "73W",
    name: "BOEING 737-700",
  },
  {
    code: "741",
    name: "BOEING 747 100",
  },
  {
    code: "742",
    name: "BOEING 747",
  },
  {
    code: "743",
    name: "BOEING 747 STRETCH UPPER DECK",
  },
  {
    code: "744",
    name: "BOEING 747",
  },
  {
    code: "747",
    name: "BOEING 747",
  },
  {
    code: "74C",
    name: "BOEING 747 MIXED CONFIG",
  },
  {
    code: "74D",
    name: "BOEING 747-300 STRETCHED UPPER DECKJET",
  },
  {
    code: "74E",
    name: "B747-400 COMBI 276",
  },
  {
    code: "74H",
    name: "BOEING 747-800",
  },
  {
    code: "74J",
    name: "BOEING 747-400",
  },
  {
    code: "74L",
    name: "BOEING 747SP LONGRANGE",
  },
  {
    code: "74M",
    name: "MIXED PSGR FREIGHTER JET",
  },
  {
    code: "74R",
    name: "BOEING 747SR",
  },
  {
    code: "752",
    name: "BOEING 757 200 SERIES",
  },
  {
    code: "753",
    name: "BOEING 757 300 SERIES",
  },
  {
    code: "757",
    name: "BOEING 757",
  },
  {
    code: "75M",
    name: "BOEING 757 MIXED",
  },
  {
    code: "75T",
    name: "BOEING 757 300 WINGLETS",
  },
  {
    code: "75W",
    name: "BOEING 757 200 WINGLETS",
  },
  {
    code: "762",
    name: "BOEING 767",
  },
  {
    code: "763",
    name: "BOEING 767",
  },
  {
    code: "764",
    name: "BOEING 767-400",
  },
  {
    code: "767",
    name: "BOEING 767",
  },
  {
    code: "76W",
    name: "BOEING 767-300",
  },
  {
    code: "772",
    name: "BOEING 777-200",
  },
  {
    code: "773",
    name: "BOEING 777-300",
  },
  {
    code: "777",
    name: "BOEING 777",
  },
  {
    code: "77L",
    name: "BOEING 777-200LR",
  },
  {
    code: "77W",
    name: "BOEING 777-300ER",
  },
  {
    code: "781",
    name: "BOEING DREAMLINER SERIES 10",
  },
  {
    code: "787",
    name: "BOEING 787",
  },
  {
    code: "788",
    name: "BOEING 787-8",
  },
  {
    code: "789",
    name: "BOEING 787-9",
  },
  {
    code: "7M7",
    name: "BOEING 737MAX 7 PASSENGER",
  },
  {
    code: "7M8",
    name: "BOEING 737MAX 8 PASSENGER",
  },
  {
    code: "7M9",
    name: "BOEING 737MAX 9 PASSENGER",
  },
  {
    code: "7S8",
    name: "BOEING 737-800 SCIMITAR WINGLETS",
  },
  {
    code: "A26",
    name: "ANTONOV AN 26",
  },
  {
    code: "A28",
    name: "ANTONOV A28",
  },
  {
    code: "A32",
    name: "A32 ANTONOV AN 32",
  },
  {
    code: "A38",
    name: "ANTONOV AN38",
  },
  {
    code: "A40",
    name: "ANTONOV 140",
  },
  {
    code: "A58",
    name: "ANTONOV AN58",
  },
  {
    code: "A5F",
    name: "ANTONOV AN-225",
  },
  {
    code: "A81",
    name: "N ANTONOV AN148-100",
  },
  {
    code: "AB3",
    name: "AIRBUS A300-B3",
  },
  {
    code: "AB4",
    name: "AIRBUS A300-B4",
  },
  {
    code: "AB6",
    name: "AIRBUS A300-600",
  },
  {
    code: "ACD",
    name: "ROCKWELL AERO COMMANDER",
  },
  {
    code: "AGH",
    name: "AGUSTA 102",
  },
  {
    code: "AN4",
    name: "ANTONOV AN24",
  },
  {
    code: "AN6",
    name: "ANTONOV AN26",
  },
  {
    code: "AN7",
    name: "ANTONOV 72 74",
  },
  {
    code: "AR1",
    name: "RJ400-REGIONAL",
  },
  {
    code: "AR7",
    name: "RJ70-REGIONAL",
  },
  {
    code: "AR8",
    name: "RJ85-REGIONAL",
  },
  {
    code: "ARJ",
    name: "BRITISH AEROSPACE RJ7",
  },
  {
    code: "AT3",
    name: "AEROSPATIALE ALENIA ATR42 300",
  },
  {
    code: "AT4",
    name: "AEROSPATIALE ALENIA ATR-42",
  },
  {
    code: "AT5",
    name: "AEROSPATIALE ALENIA ATR42-500",
  },
  {
    code: "AT7",
    name: "ATR TURBOPROP",
  },
  {
    code: "ATP",
    name: "BRITISH AEROSPACE ATP",
  },
  {
    code: "ATR",
    name: "ATR",
  },
  {
    code: "B14",
    name: "BRITISH AEROSPACE",
  },
  {
    code: "B15",
    name: "BAC 111-500",
  },
  {
    code: "BE1",
    name: "BEECHCRAFT 1900 1900C 1900D SERIES",
  },
  {
    code: "BE2",
    name: "BEECHCRAFT BE 200 SUPER KINGAIR",
  },
  {
    code: "BE4",
    name: "BEECHCRAFT RAYTHEON BEECHJET",
  },
  {
    code: "BE9",
    name: "BEECHCRAFT C99",
  },
  {
    code: "BEC",
    name: "BEECHCRAFT",
  },
  {
    code: "BEH",
    name: "BEECHCRAFT 1900D SERIES",
  },
  {
    code: "BES",
    name: "BEECHCRAFT B100 SERIES",
  },
  {
    code: "BET",
    name: "BEECHCRAFT",
  },
  {
    code: "BH2",
    name: "BELL JET RANGER",
  },
  {
    code: "BNI",
    name: "BRITTEN NORMAN ISLANDER",
  },
  {
    code: "BNT",
    name: "BRITTEN NORMAN TRISLANDER",
  },
  {
    code: "BUS",
    name: "BUS",
  },
  {
    code: "CCJ",
    name: "CANADAIR CHALLENGER",
  },
  {
    code: "CD2",
    name: "N22 N24 NOMAD",
  },
  {
    code: "CHG",
    name: "CHANGE IN GAUGE",
  },
  {
    code: "CL4",
    name: "CANADAIR CL44",
  },
  {
    code: "CN1",
    name: "CESSNA",
  },
  {
    code: "CN2",
    name: "CESSNA STATIONAIR 206",
  },
  {
    code: "CNA",
    name: "CESSNA",
  },
  {
    code: "CNC",
    name: "CESSNA SINGLE TURBOPROP ENGINE",
  },
  {
    code: "CNF",
    name: "CESSNA 208B FREIGHTER",
  },
  {
    code: "CNJ",
    name: "CESSNA CITATION",
  },
  {
    code: "CNT",
    name: "CESSNA STATIONAIR 207",
  },
  {
    code: "CR1",
    name: "CANADAIR",
  },
  {
    code: "CR2",
    name: "CANADIAN REGIONAL 200",
  },
  {
    code: "CR7",
    name: "CRJ-700 CANADAIR REGIONAL",
  },
  {
    code: "CR9",
    name: "CANADAIR REGIONAL",
  },
  {
    code: "CRA",
    name: "CANADAIR CRJ SERIES 705",
  },
  {
    code: "CRJ",
    name: "CRJ-CANADAIR REGIONAL",
  },
  {
    code: "CRK",
    name: "CANADAIR REGIONAL",
  },
  {
    code: "CRV",
    name: "CARAVELLE",
  },
  {
    code: "CS1",
    name: "BOMBARDIER CS 100",
  },
  {
    code: "CS2",
    name: "CASA C212 AVIOCAR",
  },
  {
    code: "CS3",
    name: "BOMBARDIER CS 300",
  },
  {
    code: "CS5",
    name: "CASA NUSANTARA CN-235",
  },
  {
    code: "CSB",
    name: "BOMBARDIER CS100",
  },
  {
    code: "CVF",
    name: "CONVAIR FREIGHTER",
  },
  {
    code: "CVR",
    name: "CONVAIR",
  },
  {
    code: "D10",
    name: "MCDONNELL DOUGLAS DC10",
  },
  {
    code: "D1C",
    name: "MCDONNELL DOUGLAS DC10",
  },
  {
    code: "D28",
    name: "DORNIER 228",
  },
  {
    code: "D38",
    name: "DORNIER 328",
  },
  {
    code: "D86",
    name: "MCDONNELL DOUGLAS DC-8-61",
  },
  {
    code: "D93",
    name: "MCDONNELL DOUGLAS DC9",
  },
  {
    code: "D94",
    name: "MCDONNELL DOUGLAS DC9-40",
  },
  {
    code: "D95",
    name: "MCDONNELL DOUGLAS DC9",
  },
  {
    code: "D9S",
    name: "MCDONNELL DOUGLAS DC9",
  },
  {
    code: "DC3",
    name: "MCDONNELL DOUGLAS DC3",
  },
  {
    code: "DC6",
    name: "MCDONNELL DOUGLAS DC6",
  },
  {
    code: "DC8",
    name: "MCDONNELL DOUGLAS DC8",
  },
  {
    code: "DC9",
    name: "MCDONNELL DOUGLAS DC9",
  },
  {
    code: "DF2",
    name: "DASSAULT FALCON 20",
  },
  {
    code: "DFL",
    name: "DESSAULT-BREGUET MYSTERE-FALCON",
  },
  {
    code: "DH1",
    name: "DASH1",
  },
  {
    code: "DH2",
    name: "DEHAVILLAND DASH8",
  },
  {
    code: "DH3",
    name: "DEHAVILLAND DASH 8",
  },
  {
    code: "DH4",
    name: "DEHAVILLAND DASH 8-400",
  },
  {
    code: "DH7",
    name: "DEHAVILLAND DASH 7",
  },
  {
    code: "DH8",
    name: "DEHAVILLAND DASH 8",
  },
  {
    code: "DHB",
    name: "DEHAVILLAND TURBO BEAVER",
  },
  {
    code: "DHC",
    name: "CALM AIR TWIN OTTER",
  },
  {
    code: "DHD",
    name: "BRITISH AEROSPACE DEHAVILLAND DOVE",
  },
  {
    code: "DHL",
    name: "DHC-3 TURBO OTTER",
  },
  {
    code: "DHO",
    name: "DEHAVILLAND OTTER",
  },
  {
    code: "DHP",
    name: "DEHAVILLAND BEAVER",
  },
  {
    code: "DHS",
    name: "DE HAVILLAND DHC-3 OTTER",
  },
  {
    code: "DHT",
    name: "DEHAVILLAND 6 TWIN OTTER",
  },
  {
    code: "DO8",
    name: "DORNIER 228",
  },
  {
    code: "E70",
    name: "EMBRAER 170",
  },
  {
    code: "E75",
    name: "EMBRAER EMB 175",
  },
  {
    code: "E7W",
    name: "EMBRAER 175",
  },
  {
    code: "E90",
    name: "EMBRAER EMB E90",
  },
  {
    code: "E95",
    name: "EMBRAER 195",
  },
  {
    code: "EC3",
    name: "EUROCOPTER EC130",
  },
  {
    code: "EM2",
    name: "EMBRAER 120",
  },
  {
    code: "EMB",
    name: "EMBRAER BANDEIRANTE",
  },
  {
    code: "EMJ",
    name: "EMBRAER EMB 190",
  },
  {
    code: "ER3",
    name: "ER3-EMBRAER RJ135",
  },
  {
    code: "ER4",
    name: "ER4-EMBRAER RJ145 AMAZON",
  },
  {
    code: "ERD",
    name: "EMBRAER RJ140",
  },
  {
    code: "ERJ",
    name: "EMBRAER",
  },
  {
    code: "F22",
    name: "FOKKER F28 FELLOWSHIP 2000",
  },
  {
    code: "F24",
    name: "FOKKER F28-4000 FELLOWSHIP",
  },
  {
    code: "F27",
    name: "FOKKER VFW F27 FRIENDSHIP",
  },
  {
    code: "F28",
    name: "FOKKER F28 FELLOWSHIP",
  },
  {
    code: "F50",
    name: "FOKKER 50",
  },
  {
    code: "F70",
    name: "FOKKER",
  },
  {
    code: "FK7",
    name: "FAIRCHILD HILLER FH227",
  },
  {
    code: "FRJ",
    name: "FAIRCHILD AEROSPACE",
  },
  {
    code: "FRT",
    name: "FREIGHTER",
  },
  {
    code: "GA8",
    name: "GIPPSLAND AIRVAN",
  },
  {
    code: "GJ2",
    name: "GULSTREAM 1159",
  },
  {
    code: "GJ5",
    name: "GULFSTREAM 500-550",
  },
  {
    code: "GRG",
    name: "GRUMMAN GOOSE",
  },
  {
    code: "GRM",
    name: "GRUMMAN MALLARD",
  },
  {
    code: "GRS",
    name: "GRUMMAN GULFSTREAM",
  },
  {
    code: "H25",
    name: "HAWKER 750",
  },
  {
    code: "HBA",
    name: "HAWKER BEECHCRAFT",
  },
  {
    code: "HEC",
    name: "HELIO COURIER",
  },
  {
    code: "HEL",
    name: "HELICOPTER",
  },
  {
    code: "HS7",
    name: "HAWKER SIDDELEY 748",
  },
  {
    code: "I14",
    name: "ILYUSHIN 114",
  },
  {
    code: "ICE",
    name: "INTER-CITY EXPRESS",
  },
  {
    code: "IL6",
    name: "ILYUSHIN IL62",
  },
  {
    code: "IL8",
    name: "IIYUSHIN II-18",
  },
  {
    code: "IL9",
    name: "ILYUSHIN IL96",
  },
  {
    code: "ILW",
    name: "ILYUSHIN IL86",
  },
  {
    code: "J31",
    name: "JETSTREAM 31",
  },
  {
    code: "J32",
    name: "JETSTREAM 32",
  },
  {
    code: "J41",
    name: "JETSTREAM 41",
  },
  {
    code: "JST",
    name: "JST BRITISH AEROSPACE JETSTREAM",
  },
  {
    code: "JU5",
    name: "JUNKERS JU52",
  },
  {
    code: "L10",
    name: "LOCKHEED L1011 TRISTAR",
  },
  {
    code: "L11",
    name: "L11 LOCKHEED L1011 TRISTAR",
  },
  {
    code: "L15",
    name: "LOCKHEED L1011-500 TRISTAR",
  },
  {
    code: "L4T",
    name: "LET L410 TURBOLET",
  },
  {
    code: "LCH",
    name: "LAUNCH-BOAT",
  },
  {
    code: "LMO",
    name: "LIMOUSINE",
  },
  {
    code: "LOH",
    name: "LOCKHEED L-382 HERCULES",
  },
  {
    code: "LOM",
    name: "LOCKHEED ELECTRA",
  },
  {
    code: "LRJ",
    name: "LRJ",
  },
  {
    code: "M11",
    name: "MCDONNELL DOUGLAS MD-11",
  },
  {
    code: "M1M",
    name: "MCDONNELL DOUGLAS MD-11",
  },
  {
    code: "M80",
    name: "MCDONNELL DOUGLAS MD-80",
  },
  {
    code: "M81",
    name: "MCDONNELL DOUGLAS MD-80",
  },
  {
    code: "M82",
    name: "MCDONNELL DOUGLAS MD-82",
  },
  {
    code: "M83",
    name: "MCDONNELL DOUGLAS MD-83",
  },
  {
    code: "M87",
    name: "MCDONNELL DOUGLAS MD-87",
  },
  {
    code: "M88",
    name: "MCDONNELL DOUGLAS MD-88",
  },
  {
    code: "M90",
    name: "MCDONNELL DOUGLAS MD-90",
  },
  {
    code: "MA6",
    name: "XIAN YUNSHUJI",
  },
  {
    code: "MBH",
    name: "EUROCOPTER BO105",
  },
  {
    code: "MD9",
    name: "MCDONNELL DOUGLAS 900",
  },
  {
    code: "MIH",
    name: "MIKHAIL MIL",
  },
  {
    code: "MTL",
    name: "METROLINER",
  },
  {
    code: "ND2",
    name: "NORD AVIATION 262",
  },
  {
    code: "NDC",
    name: "CORVETTE",
  },
  {
    code: "NDE",
    name: "AEROSPATIALE",
  },
  {
    code: "NDH",
    name: "DAUPHIN",
  },
  {
    code: "PA2",
    name: "PIPER TWIN",
  },
  {
    code: "PAG",
    name: "PIPER",
  },
  {
    code: "PL2",
    name: "PILATUS PC 12",
  },
  {
    code: "PR1",
    name: "HAWKER 390 PREMIER 1 1A",
  },
  {
    code: "PRO",
    name: "PROP",
  },
  {
    code: "PRP",
    name: "PROPELLER-UNDEFINED",
  },
  {
    code: "S20",
    name: "SAAB",
  },
  {
    code: "S61",
    name: "SIKORSKY S61",
  },
  {
    code: "S76",
    name: "SIKORSKY S76 HELICOPTER",
  },
  {
    code: "S80",
    name: "MCDONNELL DOUGLAS MD-80",
  },
  {
    code: "SF3",
    name: "SAAB 340",
  },
  {
    code: "SFA",
    name: "SAAB 340",
  },
  {
    code: "SFB",
    name: "SAAB 340B",
  },
  {
    code: "SH3",
    name: "SHORT BROS 330",
  },
  {
    code: "SH6",
    name: "SHORT BROS 360",
  },
  {
    code: "SHS",
    name: "SHORT BROS SKYVAN",
  },
  {
    code: "SU1",
    name: "SUKHOI SUPERJET 100",
  },
  {
    code: "SU9",
    name: "SUKHOI SUPERJET 100-95",
  },
  {
    code: "SWM",
    name: "FAIRCHILD",
  },
  {
    code: "T20",
    name: "TUPOLEV 204J",
  },
  {
    code: "T34",
    name: "TUPOLEV TU334",
  },
  {
    code: "TBM",
    name: "SOCATA TBM-700",
  },
  {
    code: "TGV",
    name: "TRAIN A GRAND VITESSE",
  },
  {
    code: "THS",
    name: "HIGH SPEED TRAIN",
  },
  {
    code: "THT",
    name: "HOTEL TRAIN",
  },
  {
    code: "TRB",
    name: "TURBOPROP",
  },
  {
    code: "TRN",
    name: "TRAIN",
  },
  {
    code: "TRS",
    name: "TRAIN HIGH SPEED",
  },
  {
    code: "TU3",
    name: "TUPOLEV TU134",
  },
  {
    code: "TU5",
    name: "TUPOLEV TU154",
  },
  {
    code: "WWP",
    name: "ISRAEL AIRS 1124 WESTWIND",
  },
  {
    code: "YK2",
    name: "YAKOLEV YAK 42",
  },
  {
    code: "YK4",
    name: "YAKOLEV YAK 40",
  },
  {
    code: "YN2",
    name: "HARBIN YUNSHUJI",
  },
  {
    code: "YN7",
    name: "XIAN YUNSHUJI",
  },
  {
    code: "YS1",
    name: "NIHON YS11",
  },
];
