import { styled } from "@fluentui/react/lib/Utilities";
import { INavigationMenuProps } from "./NavigationMenu.types";
import {
  getStyles,
  INavigationMenuStyleProps,
  INavigationMenuStyles,
} from "./NavigationMenu.styles";
import { NavigationMenuComponent } from "./NavigationMenu.base";

/**
 * NavigationMenu description
 */
export const NavigationMenu = styled<
  INavigationMenuProps,
  INavigationMenuStyleProps,
  INavigationMenuStyles
>(NavigationMenuComponent, getStyles);
