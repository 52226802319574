import {
  CommandBarButton,
  IColumn,
  Selection,
  Stack,
  Text,
} from "@fluentui/react";
import { Card } from "@fluentui/react-cards";
import * as React from "react";
import { GridView } from "src/components/GridView";
import {
  ensureSelection,
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { UserCallout } from "src/controls/UserCallout";
import { objectId } from "src/models/IObjectReference";
import { IRequestVersion } from "src/models/Requests/IRequestVersion";
import i18n from "src/services/i18n";
import VersionRequestsService from "src/services/Requests/VersionRequestsService";
import { RequestHistoryCard } from "../RequestHistoryCard/RequestHistoryCard";
import { getRequestHistoryControlClassNames } from "./RequestHistoryControl.styles";
import {
  IRequestHistoryControlProps,
  IRequestHistoryControlState,
} from "./RequestHistoryControl.types";

export class RequestHistoryControlComponent extends React.Component<
  IRequestHistoryControlProps,
  IRequestHistoryControlState
> {
  constructor(props: IRequestHistoryControlProps) {
    super(props);
    this.state = {
      options: GridViewOptionsBuilder.getOptions(
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "createdOn",
        true
      ),
      filters: [],
      items: [],
    };
    VersionRequestsService.getRequestVersions(
      objectId(this.props.enterprise),
      this.props.requestId,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  private navigateToVersion = (item: IRequestVersion): void => {
    this.props.route.history.push(
      `/demandes/${this.props.requestId}/versions/${item.id}`
    );
  };

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] = getRequestHistoryControlClassNames(
      styles!,
      {
        ...this.props,
        ...this.state,
      }
    );
    return (
      <div className={`${classNames.root} ms-EnterpriseDocumentControl`}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : mobile || tablet
                    ? "10px"
                    : "20px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={subComponentStyles?.gridView}
                fullTextEnabled={false}
                onItemInvoked={(item: IRequestVersion) => {
                  this.setState({
                    version: item,
                  });
                }}
                onRenderTile={(
                  item: IRequestVersion,
                  columnWidth: number,
                  selection: Selection,
                  callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <RequestHistoryCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                      onSelected={(item: IRequestVersion) => {
                        ensureSelection(selection, item);
                        selection.setKeySelected(
                          item.key,
                          selection
                            .getSelection()
                            .filter((x) => x.key === item.key).length === 0,
                          false
                        );
                        callback(selection);
                      }}
                      onClick={(item: IRequestVersion) => {
                        this.setState({ version: item });
                      }}
                    />
                  );
                }}
                onSort={(fieldName: string, desc: boolean) => {
                  VersionRequestsService.init();
                  VersionRequestsService.getRequestVersions(
                    objectId(this.props.enterprise),
                    this.props.requestId,
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: GridViewOptionsBuilder.getOptions(
                        this.state.options.mode,
                        fieldName,
                        desc,
                        true
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                options={this.state.options}
                onOptionsChanged={(options: IGridViewOptions) => {
                  this.setState({
                    options: options,
                  });
                }}
                columns={[
                  {
                    key: "name",
                    name: i18n.t("requests:history:name"),
                    minWidth: undefined,
                  },
                  {
                    key: "createdOn",
                    name: i18n.t("requests:history:createdon"),
                    minWidth: 120,
                  },
                  {
                    key: "createdByLabel",
                    name: i18n.t("requests:history:createdby"),
                    minWidth: 150,
                    onRenderCell: (item: IRequestVersion) => {
                      return (
                        <UserCallout {...this.props} user={item.createdBy} />
                      );
                    },
                  },
                  {
                    key: "key",
                    name: i18n.t("requests:history:goto"),
                    minWidth: 150,
                    onRenderCell: (item: IRequestVersion) => {
                      return (
                        <CommandBarButton
                          style={{ backgroundColor: "transparent" }}
                          iconProps={{ iconName: "NavigateForward" }}
                          onClick={() => this.navigateToVersion(item)}
                          text={i18n.t("requests:history:goto:label")}
                        />
                      );
                    },
                  },
                ]}
                onRenderCell={(
                  item?: any,
                  _index?: number,
                  column?: IColumn
                ) => {
                  return <Text>{item[column?.fieldName ?? ""]}</Text>;
                }}
                onDataPaging={() => {
                  VersionRequestsService.getRequestVersions(
                    objectId(this.props.enterprise),
                    this.props.requestId,
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  VersionRequestsService.init();
                  VersionRequestsService.getRequestVersions(
                    objectId(this.props.enterprise),
                    this.props.requestId,
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: filters,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                onQueryFilters={(_reset: boolean) => {
                  return new Promise<IQueryFilter[]>((resolve) => {
                    resolve([
                      {
                        name: i18n.t("requests:history:name"),
                        fieldName: "name",
                        type: "text",
                        value: "",
                      },
                      {
                        name: i18n.t("requests:history:createdon"),
                        fieldName: "createdOn",
                        type: "date",
                        value: null,
                      },
                    ]);
                  });
                }}
                items={this.state.items}
                commands={[]}
                entityPanelProps={{
                  ...this.props,
                  styles: undefined,
                  elementName: i18n.t("document"),
                  title: this.state.version?.name,
                  headerIconName: "WorkItemEnterpriseDocument",
                  isOpen: this.state.version !== undefined,
                  "data-automation-id": "nvx:requestshistory:itempanel",
                  onDismiss: () => {
                    this.setState({
                      version: undefined,
                    });
                  },
                  children: (
                    <div>
                      <div style={{ height: "40px" }}></div>
                      <div>
                        <Text variant="medium">
                          {i18n.t("requests:history:name")}
                        </Text>
                      </div>
                      <div>
                        <Text variant="large" style={{ fontWeight: "bold" }}>
                          {this.state.version?.name}
                        </Text>
                      </div>
                      <div style={{ height: "10px" }}></div>
                      <div>
                        <Text variant="medium">
                          {i18n.t("requests:history:createdon")}
                        </Text>
                      </div>
                      <div>
                        <Text variant="large" style={{ fontWeight: "bold" }}>
                          {this.state.version?.createdOn}
                        </Text>
                      </div>
                      <div style={{ height: "10px" }}></div>
                      <div>
                        <Text variant="medium">
                          {i18n.t("requests:history:createdby")}
                        </Text>
                      </div>
                      {this.state.version?.createdBy && (
                        <div>
                          <UserCallout
                            {...this.props}
                            user={this.state.version?.createdBy}
                          />
                        </div>
                      )}
                      <div style={{ height: "10px" }}></div>
                    </div>
                  ),
                }}
              />
            </Card.Item>
          </Card>
        </Stack>
        <div style={{ height: "40px" }}></div>
      </div>
    );
  }
}
