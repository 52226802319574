import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IParticipantsControlStyleProps,
  IParticipantsControlStyles,
} from "../ParticipantsControl";

export interface IParticipantsPageSubComponentStyles {
  control?: IStyleFunctionOrObject<
    IParticipantsControlStyleProps,
    IParticipantsControlStyles
  >;
}

export interface IParticipantsPageStyles
  extends IAmigoBaseStyles<IParticipantsPageSubComponentStyles> {}

export interface IParticipantsPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getParticipantsPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantsPageStyleProps,
    IParticipantsPageStyles
  >,
  props?: IParticipantsPageStyleProps
): [
  IProcessedStyleSet<IParticipantsPageStyles>,
  IParticipantsPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantsPageStyleProps,
  IParticipantsPageStyles
> = (props: IParticipantsPageStyleProps): IParticipantsPageStyles => {
  const pageStyle = props.tokens.defaultPageRootStyle as any;
  return {
    root: {
      ...pageStyle,
      overflowX: "hidden",
    },
  };
};
