import { styled } from "@fluentui/react/lib/Utilities";
import { IFlightBookingPassengersPageProps } from "./FlightBookingPassengersPage.types";
import {
  getStyles,
  IFlightBookingPassengersPageStyleProps,
  IFlightBookingPassengersPageStyles,
} from "./FlightBookingPassengersPage.styles";
import { FlightBookingPassengersPageComponent } from "./FlightBookingPassengersPage.base";

/**
 * FlightBookingPassengersPage
 */
export const FlightBookingPassengersPage = styled<
  IFlightBookingPassengersPageProps,
  IFlightBookingPassengersPageStyleProps,
  IFlightBookingPassengersPageStyles
>(FlightBookingPassengersPageComponent, getStyles);
