import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import {
  ILabelFieldStyleProps,
  ILabelFieldStyles,
} from "src/components/LabelField";
import { IGridViewStyleProps, IGridViewStyles } from "src/components/GridView";
import {
  IAlertCardStyleProps,
  IAlertCardStyles,
} from "src/pages/Alerts/AlertCard";

export interface IBillingElementsControlSubComponentStyles {
  gridView?: IStyleFunctionOrObject<IGridViewStyleProps, IGridViewStyles>;
  gridCard?: IStyleFunctionOrObject<IAlertCardStyleProps, IAlertCardStyles>;
  label?: IStyleFunctionOrObject<ILabelFieldStyleProps, ILabelFieldStyles>;
}

export interface IBillingElementsControlStyles
  extends IAmigoBaseStyles<IBillingElementsControlSubComponentStyles> {}

export interface IBillingElementsControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getBillingElementsControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IBillingElementsControlStyleProps,
    IBillingElementsControlStyles
  >,
  props?: IBillingElementsControlStyleProps
): [
  IProcessedStyleSet<IBillingElementsControlStyles>,
  IBillingElementsControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IBillingElementsControlStyleProps,
  IBillingElementsControlStyles
> = (
  _props: IBillingElementsControlStyleProps
): IBillingElementsControlStyles => {
  return {};
};
