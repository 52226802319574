import { DefaultPalette } from "@fluentui/react";
import BrowserService from "src/services/BrowserService";

class EnterprisesPageStyles {
  public themeColor = (index: number): string => {
    return [
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.black,
      DefaultPalette.white,
      DefaultPalette.black,
      DefaultPalette.white,
    ][index];
  };

  public themeImageSize = (index: number, mobile: boolean): string => {
    return [
      "cover",
      BrowserService.isTabletOrMobile() ? "cover" : "100% 170%",
      "100% 115%",
      mobile ? "cover" : "cover",
    ][index];
  };

  public themeOpacity = (index: number) => {
    return ["1.0", "0.7", "0.5", "0.5"][index];
  };
}

export default new EnterprisesPageStyles();
