import { styled } from "@fluentui/react/lib/Utilities";
import { IParticipantDetailsPageProps } from "./ParticipantDetailsPage.types";
import {
  getStyles,
  IParticipantDetailsPageStyleProps,
  IParticipantDetailsPageStyles,
} from "./ParticipantDetailsPage.styles";
import { ParticipantDetailsPageComponent } from "./ParticipantDetailsPage.base";

/**
 * ParticipantDetailsPage description
 */
export const ParticipantDetailsPage = styled<
  IParticipantDetailsPageProps,
  IParticipantDetailsPageStyleProps,
  IParticipantDetailsPageStyles
>(ParticipantDetailsPageComponent, getStyles);
