import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IDepartureRequestFlightSelectorSubComponentStyles {}

export interface IDepartureRequestFlightSelectorStyles
  extends IAmigoBaseStyles<IDepartureRequestFlightSelectorSubComponentStyles> {}

export interface IDepartureRequestFlightSelectorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getDepartureRequestFlightSelectorClassNames = (
  styles?: IStyleFunctionOrObject<
    IDepartureRequestFlightSelectorStyleProps,
    IDepartureRequestFlightSelectorStyles
  >,
  props?: IDepartureRequestFlightSelectorStyleProps
): [
  IProcessedStyleSet<IDepartureRequestFlightSelectorStyles>,
  IDepartureRequestFlightSelectorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IDepartureRequestFlightSelectorStyleProps,
  IDepartureRequestFlightSelectorStyles
> = (
  _props: IDepartureRequestFlightSelectorStyleProps
): IDepartureRequestFlightSelectorStyles => {
  return {
    root: ["amigo-DepartureRequestFlightSelector", {}],
  };
};
