import {
  CompactPeoplePicker,
  IBasePicker,
  IBasePickerSuggestionsProps,
  IconButton,
  IPersonaProps,
  PersonaPresence,
  Stack,
  Text,
} from "@fluentui/react";
import * as React from "react";
import { IUser } from "src/models/User";
import i18n from "src/services/i18n";
import { UsersPickerDialog } from "../UsersPickerDialog";
import { getUsersPickerClassNames } from "./UsersPicker.styles";
import { IUsersPickerProps, IUsersPickerState } from "./UsersPicker.types";

export class UsersPickerComponent extends React.Component<
  IUsersPickerProps,
  IUsersPickerState
> {
  private picker: React.RefObject<IBasePicker<IPersonaProps>>;
  constructor(props: IUsersPickerProps) {
    super(props);
    this.state = {
      pickerEnabled: false,
      delayResults: true,
    };

    this.picker = React.createRef();
    this.returnMostRecentlyUsedWithLimit.bind(this);
  }

  private onFilterChanged = (
    filterText: string,
    currentPersonas?: IPersonaProps[],
    limitResults?: number
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (filterText !== undefined && currentPersonas !== undefined) {
      let filteredPersonas: IPersonaProps[] =
        this.filterPersonasByText(filterText);

      filteredPersonas = this.removeDuplicates(
        filteredPersonas,
        currentPersonas
      );
      filteredPersonas = limitResults
        ? filteredPersonas.slice(0, limitResults)
        : filteredPersonas;
      return this.filterPromise(filteredPersonas);
    } else {
      return [];
    }
  };

  private userToPersona(user: IUser): IPersonaProps {
    return {
      itemID: user.id,
      imageUrl: undefined,
      text: user.name,
      secondaryText: user.role,
      tertiaryText: undefined,
      optionalText: user.email,
      presence: PersonaPresence.none,
    };
  }

  private filterPersonasByText = (filterText: string): IPersonaProps[] => {
    return this.props.peopleList.filter((item) =>
      this.doesTextStartWith(item.text as string, filterText)
    );
  };

  private filterPromise = (
    personasToReturn: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return personasToReturn;
  };

  private returnMostRecentlyUsedWithLimit = (
    currentPersonas?: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    if (currentPersonas === undefined) return [];
    const items = this.removeDuplicates(
      this.props.mostRecentlyUsed,
      currentPersonas
    ).slice(0, 6);
    /*
    this.setState({
      mostRecentlyUsed: items,
    });
    */
    return this.filterPromise(items);
  };

  private onFilterChangedWithLimit = (
    filterText: string,
    currentPersonas?: IPersonaProps[]
  ): IPersonaProps[] | Promise<IPersonaProps[]> => {
    return this.onFilterChanged(filterText, currentPersonas ?? [], 7);
  };

  private doesTextStartWith(text: string, filterText: string): boolean {
    return text.toLowerCase().indexOf(filterText.toLowerCase()) === 0;
  }

  private removeDuplicates = (
    personas: IPersonaProps[],
    possibleDupes: IPersonaProps[]
  ) => {
    return personas.filter(
      (persona) => !this.listContainsPersona(persona, possibleDupes)
    );
  };

  private listContainsPersona = (
    persona: IPersonaProps,
    personas: IPersonaProps[]
  ) => {
    if (!personas || !personas.length || personas.length === 0) {
      return false;
    }
    return personas.filter((item) => item.itemID === persona.itemID).length > 0;
  };

  private getTextFromItem = (persona: IPersonaProps): string => {
    return persona.text as string;
  };

  private renderFooterText(): JSX.Element {
    return <div>No additional results</div>;
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getUsersPickerClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const suggestionProps: IBasePickerSuggestionsProps = {
      suggestionsHeaderText: i18n.t("messages:recipients:suggestedcontacts:1"),
      mostRecentlyUsedHeaderText: i18n.t(
        "messages:recipients:suggestedcontacts:2"
      ),
      noResultsFoundText: i18n.t(
        "messages:recipients:suggestedcontacts:noresults"
      ),
      loadingText: i18n.t("messages:recipients:suggestedcontacts:loading"),
      showRemoveButtons: false,
      suggestionsAvailableAlertText: "",
      suggestionsContainerAriaLabel: i18n.t(
        "messages:recipients:suggestedcontacts:2"
      ),
    };

    const limitedSearchAdditionalProps: IBasePickerSuggestionsProps = {
      searchForMoreText: "Load all Results",
      resultsMaximumNumber: 10,
      searchingText: i18n.t("global:searching"),
    };

    const limitedSearchSuggestionProps: IBasePickerSuggestionsProps = {
      ...limitedSearchAdditionalProps,
      ...suggestionProps,
      resultsFooter: this.renderFooterText,
    };

    return (
      <div className={classNames.root}>
        <Stack
          horizontal
          tokens={{
            maxWidth: "100%",
          }}
        >
          <Stack.Item grow={1}>
            <CompactPeoplePicker
              onResolveSuggestions={this.onFilterChangedWithLimit}
              onEmptyInputFocus={this.returnMostRecentlyUsedWithLimit}
              getTextFromItem={this.getTextFromItem}
              className={"ms-PeoplePicker"}
              onGetMoreResults={this.onFilterChanged}
              pickerSuggestionsProps={limitedSearchSuggestionProps}
              onRemoveSuggestion={undefined}
              selectedItems={this.props.selection}
              onChange={this.props.onSelectionChanged}
              inputProps={{
                onBlur: (_ev: React.FocusEvent<HTMLInputElement>) => {},
                onFocus: (_ev: React.FocusEvent<HTMLInputElement>) => {},
                "aria-label": "People Picker",
              }}
              componentRef={this.picker}
              resolveDelay={200}
              disabled={false}
            />
          </Stack.Item>
          <Stack.Item>
            <IconButton
              iconProps={{ iconName: "ProfileSearch" }}
              onClick={() => this.setState({ pickerEnabled: true })}
            />
          </Stack.Item>
        </Stack>
        {this.props.errorMessage ? (
          <Text className="nvx-errorMessage">{this.props.errorMessage}</Text>
        ) : (
          <div></div>
        )}
        <UsersPickerDialog
          {...this.props}
          onApplySelection={(items: IUser[]) => {
            const selected: IPersonaProps[] = this.props.selection.concat(
              this.removeDuplicates(
                items.map((x) => this.userToPersona(x)),
                this.props.selection
              )
            );
            this.setState({
              pickerEnabled: false,
            });
            this.props.onSelectionChanged(selected);
          }}
          enabled={this.state.pickerEnabled}
          onDismiss={() => this.setState({ pickerEnabled: false })}
        />
      </div>
    );
  }
}
