import {
  ContextualMenuItemType,
  DefaultButton,
  Dialog,
  DialogFooter,
  DialogType,
  MessageBar,
  MessageBarType,
  PrimaryButton,
  ResponsiveMode,
  Spinner,
  Stack,
  TextField,
} from "@fluentui/react";
import * as React from "react";
import { Redirect } from "react-router";
import { PasswordStrengthIndicator } from "src/components/PasswordStrengthIndicator";
import { ResetPasswordSchema } from "src/models/IResetPasswordModel";
import { PasswordCheckStrength } from "src/models/PasswordCheckStrength";
import AuthService from "src/services/AuthService";
import i18n from "src/services/i18n";
import { PasswordCheckService } from "src/services/PasswordCheckService";
import { validate } from "src/utils/validator";
import { getResetPasswordControlClassNames } from "./ResetPasswordControl.styles";
import {
  IResetPasswordControlProps,
  IResetPasswordControlState,
} from "./ResetPasswordControl.types";

export class ResetPasswordControlComponent extends React.Component<
  IResetPasswordControlProps,
  IResetPasswordControlState
> {
  constructor(props: IResetPasswordControlProps) {
    super(props);
    const resetId = localStorage.getItem("resetId");
    const resetSAS = localStorage.getItem("resetSasToken");
    if (resetId === null) throw new Error("ResetId Value cannot be null!");
    if (resetSAS === null)
      throw new Error("ResetSasToken Value cannot be null!");
    this.state = {
      model: {
        version: "1.0",
        source: "1",
        userId: resetId,
        token: resetSAS,
        newPassword: "",
        confirmPassword: "",
      },
      errors: [],
      message: "",
      success: false,
      progress: false,
      redirect: false,
    };
    this.onNewPasswordChange = this.onNewPasswordChange.bind(this);
    this.onConfirmPasswordChange = this.onConfirmPasswordChange.bind(this);
  }

  onNewPasswordChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    const { model } = this.state;
    model.newPassword = newValue ?? "";
    this.setState({ model: model });
  }

  onConfirmPasswordChange(
    _event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) {
    const { model } = this.state;
    model.confirmPassword = newValue ?? "";
    this.setState({ model: model });
  }

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getResetPasswordControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return !this.state.redirect ? (
      <div className={classNames.root}>
        <Dialog
          hidden={false}
          dialogContentProps={{
            type: DialogType.largeHeader,
            title: i18n.t("reset:title"),
            subText: this.state.success ? "" : i18n.t("reset:instructions"),
            styles: {
              subText: {
                marginBottom: "1px",
              },
            },
            showCloseButton: false,
            responsiveMode: ResponsiveMode.large,
            titleProps: {
              style: {},
            },
            topButtonsProps: [
              {
                iconProps: {
                  iconName: "LocaleLanguage",
                  className: "SignInButton",
                },
                menuIconProps: { iconName: undefined },
                menuProps: {
                  shouldFocusOnMount: true,
                  items: [
                    {
                      key: "english",
                      text: i18n.t("language:en"),
                      style: {
                        fontWeight:
                          this.props.language === "en" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("en"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    },
                    {
                      key: "french",
                      text: i18n.t("language:fr"),
                      style: {
                        fontWeight:
                          this.props.language === "fr" ? "bold" : "normal",
                      },
                      onClick: () => this.props.onLanguageChanged("fr"),
                    },
                    {
                      key: "divider_1",
                      itemType: ContextualMenuItemType.Divider,
                    } /*,
                {
                    key: 'spanish',
                    text: i18n.t('language:es'),
                    style: {
                    fontWeight:
                        this.props.language === "es" ? "bold" : "normal"
                    },
                    onClick: () => this.props.onLanguageChanged('es')
                } */,
                  ],
                },
              },
            ],
          }}
          modalProps={{
            titleAriaId: "1",
            subtitleAriaId: "3",
            isBlocking: true,
            containerClassName: classNames.root,
            styles: { main: { minWidth: 270 } },
            dragOptions: undefined,
            layerProps: {},
          }}
        >
          {this.state.success ? (
            <div>
              <Stack>
                <MessageBar messageBarType={MessageBarType.success}>
                  {i18n.t("reset:success")}
                </MessageBar>
              </Stack>
              <DialogFooter>
                <DefaultButton
                  data-automation-id="nvx:login:reset:back"
                  onClick={() => {
                    this.setState({
                      redirect: true,
                      success: false,
                    });
                  }}
                  text={i18n.t("reset:back")}
                />
              </DialogFooter>
            </div>
          ) : (
            <div>
              {this.state.progress ? (
                <Stack
                  horizontalAlign={"center"}
                  verticalAlign={"center"}
                  verticalFill={true}
                  style={{ height: "152px" }}
                >
                  <Spinner
                    label={i18n.t("global:pleasewait")}
                    ariaLive="assertive"
                    labelPosition="right"
                  />
                </Stack>
              ) : (
                <Stack>
                  {this.state.message.length === 0 ? (
                    <div style={{ height: "32px" }}></div>
                  ) : (
                    <MessageBar messageBarType={MessageBarType.error}>
                      {i18n.t(this.state.message)}
                    </MessageBar>
                  )}
                  <TextField
                    type="password"
                    label={i18n.t("reset:newpassword")}
                    data-automation-id="nvx:login:reset:newpassword"
                    name="newpassword"
                    maxLength={50}
                    iconProps={{ iconName: "PasswordField" }}
                    errorMessage={this.state.errors.newPassword}
                    value={this.state.model.newPassword}
                    onChange={this.onNewPasswordChange}
                    onKeyPress={(e: any) =>
                      e.key === "Enter" && this.submitReset__(e)
                    }
                  />
                  <PasswordStrengthIndicator
                    {...this.props}
                    styles={undefined}
                    password={this.state.model.newPassword}
                  />
                  <TextField
                    type="password"
                    label={i18n.t("reset:confirmpassword")}
                    data-automation-id="nvx:login:reset:confirmpassword"
                    name="confirmpassword"
                    maxLength={50}
                    iconProps={{ iconName: "PasswordField" }}
                    errorMessage={this.state.errors.confirmPassword}
                    value={this.state.model.confirmPassword}
                    onChange={this.onConfirmPasswordChange}
                    onKeyPress={(e: any) =>
                      e.key === "Enter" && this.submitReset__(e)
                    }
                  />
                </Stack>
              )}
              <DialogFooter>
                <PrimaryButton
                  data-automation-id="resetcommand"
                  disabled={this.state.progress}
                  onClick={this.submitReset}
                  text={i18n.t("reset:resetcommand")}
                />
                <DefaultButton
                  data-automation-id="cancelcommand"
                  disabled={this.state.progress}
                  onClick={() => {
                    this.setState({
                      redirect: true,
                    });
                  }}
                  text={i18n.t("reset:cancelcommand")}
                />
              </DialogFooter>
            </div>
          )}
        </Dialog>
      </div>
    ) : (
      <Redirect to="/" />
    );
  }

  private submitReset__ = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    e.preventDefault();
    this.executeResetPassword();
  };

  private submitReset = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ): void => {
    e.preventDefault();
    this.executeResetPassword();
  };

  private executeResetPassword = (): void => {
    const _this = this;
    const errors: any = validate(ResetPasswordSchema, this.state.model);
    if (Object.keys(errors).length === 0) {
      // ensure password strength respected
      const strength: PasswordCheckStrength =
        PasswordCheckService.checkPasswordStrength(
          this.state.model.newPassword
        );
      if (strength < PasswordCheckStrength.Weak) {
        this.setState({
          errors: {
            ...errors,
            newPassword: i18n.t("reset:newpassword:strength"),
          },
          message: "",
          success: false,
          progress: false,
        });
      } else {
        AuthService.resetPassword(this.state.model)
          .then((x) => {
            if (x.status === 200) {
              _this.setState({ success: true, progress: false });
            }
          })
          .catch(() => {
            this.setState({
              errors: [],
              message: "reset:error",
              success: false,
              progress: false,
            });
          });
        _this.setState({
          success: false,
          errors: [],
          message: "",
          progress: true,
        });
      }
    } else {
      this.setState({
        errors: errors,
        message: "",
        success: false,
        progress: false,
      });
    }
  };
}
