import {
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface IForgotPasswordControlSubComponentStyles {}

export interface IForgotPasswordControlStyles
  extends IAmigoBaseStyles<IForgotPasswordControlSubComponentStyles> {
  title?: IStyle;
  spinner?: IStyle;
}

export interface IForgotPasswordControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getForgotPasswordControlClassNames = (
  styles?: IStyleFunctionOrObject<
    IForgotPasswordControlStyleProps,
    IForgotPasswordControlStyles
  >,
  props?: IForgotPasswordControlStyleProps
): [
  IProcessedStyleSet<IForgotPasswordControlStyles>,
  IForgotPasswordControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IForgotPasswordControlStyleProps,
  IForgotPasswordControlStyles
> = (
  _props: IForgotPasswordControlStyleProps
): IForgotPasswordControlStyles => {
  return {
    root: [
      "amigo-ForgotPassword",
      {
        maxWidth: "100%",
        width: "420px",
        maxHeight: "100%",
        marginLeft: "50%",
        selectors: {
          "@media (min-width: 480px)": {
            maxWidth: "100%",
            width: "420px",
            maxHeight: "100%",
          },
          [MediaQueries.mobile]: {
            position: "absolute",
            right: 0,
            marginLeft: 0,
          },
          [MediaQueries.small]: {
            position: "absolute",
            left: 0,
            bottom: 0,
            marginLeft: 0,
          },
          [MediaQueries.tablet]: {
            position: "absolute",
            right: 0,
          },
        },
      },
    ],
    title: [
      "amigo-Login-Title",
      {
        maxWidth: "280px",
        marginRight: "15px",
      },
    ],
    spinner: [
      "amigo-Login-Spinner",
      {
        width: "152px",
      },
    ],
  };
};
