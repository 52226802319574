import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import { MediaQueries } from "src/components/MediaQueries";

export interface ITravelWarningsSectionSubComponentStyles {}

export interface ITravelWarningsSectionStyles
  extends IAmigoBaseStyles<ITravelWarningsSectionSubComponentStyles> {}

export interface ITravelWarningsSectionStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTravelWarningsSectionClassNames = (
  styles?: IStyleFunctionOrObject<
    ITravelWarningsSectionStyleProps,
    ITravelWarningsSectionStyles
  >,
  props?: ITravelWarningsSectionStyleProps
): [
  IProcessedStyleSet<ITravelWarningsSectionStyles>,
  ITravelWarningsSectionSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITravelWarningsSectionStyleProps,
  ITravelWarningsSectionStyles
> = (
  _props: ITravelWarningsSectionStyleProps
): ITravelWarningsSectionStyles => {
  return {
    root: [
      "amigo-TravelWarningsSection",
      {
        width: "100%",
        [MediaQueries.mobile]: {
          width: "100%",
        },
      },
    ],
  };
};
