import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
  IButtonStyles,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface INewMessageControlSubComponentStyles {
  iconStyles?: IButtonStyles;
}

export interface INewMessageControlStyles
  extends IAmigoBaseStyles<INewMessageControlSubComponentStyles> {}

export interface INewMessageControlStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getNewMessageControlClassNames = (
  styles?: IStyleFunctionOrObject<
    INewMessageControlStyleProps,
    INewMessageControlStyles
  >,
  props?: INewMessageControlStyleProps
): [
  IProcessedStyleSet<INewMessageControlStyles>,
  INewMessageControlSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  INewMessageControlStyleProps,
  INewMessageControlStyles
> = (_props: INewMessageControlStyleProps): INewMessageControlStyles => {
  return {};
};
