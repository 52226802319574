import * as React from "react";
import { getEnterpriseContactItemClassNames } from "./EnterpriseContactItem.styles";
import {
  IEnterpriseContactItemState,
  IEnterpriseContactItemProps,
} from "./EnterpriseContactItem.types";
import {
  PersonaSize,
  Persona,
  PersonaPresence,
  Callout,
  DefaultPalette,
  DirectionalHint,
  Panel,
  PanelType,
} from "@fluentui/react";
import { EnterpriseContactCard } from "../EnterpriseContactCard";

export class EnterpriseContactItemComponent extends React.Component<
  IEnterpriseContactItemProps,
  IEnterpriseContactItemState
> {
  private _element: React.RefObject<HTMLDivElement>;

  constructor(props: IEnterpriseContactItemProps) {
    super(props);
    this._element = React.createRef();
    this.state = {
      selected: false,
    };
  }

  render(): JSX.Element {
    const { styles, contact } = this.props;
    const [classNames] = getEnterpriseContactItemClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    const contactSize: PersonaSize = this.props.mobile
      ? PersonaSize.size24
      : PersonaSize.size32;
    return (
      <div className={classNames.root}>
        <div ref={this._element}>
          <Persona
            initialsColor={"rgb(122, 117, 116)"}
            onClick={() => {
              this.setState({
                selected: true,
              });
            }}
            styles={{
              root: {
                cursor: "pointer",
              },
            }}
            text={contact.name}
            secondaryText={contact.phone}
            size={contactSize}
            presence={PersonaPresence.none}
            imageAlt={contact.name}
          />
          {!this.props.mobile && this.state.selected && (
            <Callout
              role="alertdialog"
              gapSpace={4}
              styles={{
                root: {
                  backgroundColor: DefaultPalette.neutralLighter,
                },
              }}
              directionalHint={DirectionalHint.topAutoEdge}
              target={this._element.current}
              onDismiss={() => {
                this.setState({
                  selected: false,
                });
              }}
              setInitialFocus={true}
            >
              <EnterpriseContactCard {...this.props} contact={contact} />
            </Callout>
          )}
          {this.props.mobile && this.state.selected && (
            <Panel
              isOpen={true}
              hasCloseButton={true}
              isLightDismiss={true}
              isHiddenOnDismiss={true}
              headerText={this.props.enterprise.name}
              onDismiss={() => {
                this.setState({
                  selected: false,
                });
              }}
              type={PanelType.smallFluid}
            >
              <EnterpriseContactCard {...this.props} contact={contact} />
            </Panel>
          )}
        </div>
      </div>
    );
  }
}
