import {
  IProcessedStyleSet,
  IStyle,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IComponentBaseStyleProps,
  IComponentBaseStyles,
  IComponentBaseTokens,
} from "../Base";

export interface IEntityPanelSubComponentStyles {}

export interface IEntityPanelStyles
  extends IComponentBaseStyles<IEntityPanelSubComponentStyles> {
  headerIcon?: IStyle;
  headerStack?: IStyle;
  headerTextStack?: IStyle;
  headerTextElementName?: IStyle;
  headerSeparator?: IStyle;
  headerTextTitle?: IStyle;
}

export interface IEntityPanelStyleProps
  extends IComponentBaseStyleProps<IComponentBaseTokens> {}

export const getEntityPanelClassNames = (
  styles?: IStyleFunctionOrObject<IEntityPanelStyleProps, IEntityPanelStyles>,
  props?: IEntityPanelStyleProps
): [
  IProcessedStyleSet<IEntityPanelStyles>,
  IEntityPanelSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IEntityPanelStyleProps,
  IEntityPanelStyles
> = (_props: IEntityPanelStyleProps): IEntityPanelStyles => {
  return {
    headerIcon: {
      fontSize: "18px",
      margin: "5px 15px",
    },
    headerTextElementName: ["ms-EntityPanelHeaderElement", {}],
    headerSeparator: [
      "ms-EntityPanelHeaderElement",
      {
        margin: "0 3px 0 3px!important",
      },
    ],
  };
};
