import { styled } from "@fluentui/react/lib/Utilities";
import { IRequestParticipantPathwayTimelineProps } from "./RequestParticipantPathwayTimeline.types";
import {
  getStyles,
  IRequestParticipantPathwayTimelineStyleProps,
  IRequestParticipantPathwayTimelineStyles,
} from "./RequestParticipantPathwayTimeline.styles";
import { RequestParticipantPathwayTimelineComponent } from "./RequestParticipantPathwayTimeline.base";

/**
 * RequestParticipantPathwayTimeline
 */
export const RequestParticipantPathwayTimeline = styled<
  IRequestParticipantPathwayTimelineProps,
  IRequestParticipantPathwayTimelineStyleProps,
  IRequestParticipantPathwayTimelineStyles
>(RequestParticipantPathwayTimelineComponent, getStyles);
