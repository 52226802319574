import { styled } from "@fluentui/react/lib/Utilities";
import { ITransportsPageProps } from "./TransportsPage.types";
import {
  getStyles,
  ITransportsPageStyleProps,
  ITransportsPageStyles,
} from "./TransportsPage.styles";
import { TransportsPageComponent } from "./TransportsPage.base";

/**
 * TransportsPage description
 */
export const TransportsPage = styled<
  ITransportsPageProps,
  ITransportsPageStyleProps,
  ITransportsPageStyles
>(TransportsPageComponent, getStyles);
