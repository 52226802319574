import { ISchemaObject } from "../utils/validator";
import i18n from "../services/i18n";

export interface IResetPasswordModel {
  version: string;
  source: string;
  newPassword: string;
  confirmPassword: string;
  userId: string;
  token: string;
}

export const ResetPasswordSchema: ISchemaObject = {
  name: "user",
  plural: "users",
  label: "User",
  fields: {
    version: {
      name: "version",
      label: "version",
      required: true,
      pattern: "^[ a-zA-Z0-9,#.-]+$",
      errorMessage: "Error",
      type: "string",
    },
    source: {
      name: "source",
      label: "source",
      required: true,
      pattern: "^[ a-zA-Z0-9,#.-]+$",
      errorMessage: "Error",
      type: "string",
    },
    userId: {
      name: "userId",
      label: "userId",
      required: true,
      pattern: "^[ a-zA-Z0-9,#.-]+$",
      errorMessage: "Error",
      type: "string",
    },
    newPassword: {
      name: "newPassword",
      label: i18n.t("reset:newpassword"),
      required: true,
      pattern: "",
      errorMessage: i18n.t("reset:newpassword:error"),
      type: "string",
    },
    confirmPassword: {
      name: "confirmPassword",
      label: i18n.t("reset:confirmpassword"),
      required: true,
      pattern: "newPassword",
      errorMessage: (data: object, fieldName: string): string => {
        const value = data[fieldName];
        return value && value.length > 0
          ? i18n.t("reset:confirmpassword:mismatch")
          : i18n.t("reset:confirmpassword:error");
      },
      type: "compare",
    },
    token: {
      name: "token",
      label: "token",
      required: true,
      pattern: "",
      errorMessage: "Error",
      type: "string",
    },
  },
};
