import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface IInspectionRequestEditorSubComponentStyles {}

export interface IInspectionRequestEditorStyles
  extends IAmigoBaseStyles<IInspectionRequestEditorSubComponentStyles> {}

export interface IInspectionRequestEditorStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getInspectionRequestEditorClassNames = (
  styles?: IStyleFunctionOrObject<
    IInspectionRequestEditorStyleProps,
    IInspectionRequestEditorStyles
  >,
  props?: IInspectionRequestEditorStyleProps
): [
  IProcessedStyleSet<IInspectionRequestEditorStyles>,
  IInspectionRequestEditorSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IInspectionRequestEditorStyleProps,
  IInspectionRequestEditorStyles
> = (
  _props: IInspectionRequestEditorStyleProps
): IInspectionRequestEditorStyles => {
  return {
    root: ["amigo-InspectionRequestEditor", {}],
  };
};
