import * as React from "react";
import { getParticipantPositionsControlClassNames } from "./ParticipantPositionsControl.styles";
import {
  IParticipantPositionsControlState,
  IParticipantPositionsControlProps,
} from "./ParticipantPositionsControl.types";
import PreferencesService from "src/services/PreferencesService";
import {
  GridViewMode,
  IGridViewOptions,
} from "src/components/GridView/GridView.types";
import { GridView } from "src/components/GridView";
import { ParticipantPositionCard } from "../ParticipantPositionCard";
import { IParticipantPosition } from "src/models/Participants/IParticipantPosition";
import { Selection, SelectionMode, Stack, Text } from "@fluentui/react";
import ParticipantPositionsService from "src/services/Participants/ParticipantPositionsService";
import {
  GridViewOptionsBuilder,
  IQueryFilter,
} from "src/components/GridView/GridView.imports";
import { objectId } from "src/models/IObjectReference";
import { Link } from "react-router-dom";
import { Card } from "@fluentui/react-cards";

export class ParticipantPositionsControlComponent extends React.Component<
  IParticipantPositionsControlProps,
  IParticipantPositionsControlState
> {
  constructor(props: IParticipantPositionsControlProps) {
    super(props);
    this.state = {
      items: [],
      options: PreferencesService.getOptionsOrDefault(
        "participantpositions",
        props.mobile ? GridViewMode.tiles : GridViewMode.list,
        "name",
        false
      ),
    };
    ParticipantPositionsService.getParticipantPositions(
      objectId(this.props.enterprise),
      this.props.participantId,
      this.state.options.sortBy,
      this.state.options.desc,
      this.state.filters,
      undefined
    ).then((x) => {
      this.setState({
        items: x,
        options: GridViewOptionsBuilder.getFetched(this),
      });
    });
  }

  render(): JSX.Element {
    const { styles, mobile, tablet } = this.props;
    const [classNames, subComponentStyles] =
      getParticipantPositionsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { items, options } = this.state;
    return (
      <div className={classNames.root}>
        <Stack
          grow
          verticalFill
          styles={{
            root: {
              width: "100%",
              padding: mobile ? "4px" : tablet ? "10px" : "20px",
            },
          }}
        >
          <Card
            styles={{
              root: {
                backgroundColor:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : "#fff",
                padding:
                  this.state.options.mode === GridViewMode.tiles
                    ? undefined
                    : mobile || tablet
                    ? "10px"
                    : "20px",
                height: "auto",
                maxHeight: "unset",
                width: "100%",
                maxWidth: "unset",
              },
            }}
          >
            <Card.Item>
              <GridView
                {...this.props}
                styles={undefined}
                options={options}
                selectionMode={SelectionMode.none}
                onOptionsChanged={(opt: IGridViewOptions) =>
                  this.setState({
                    options: PreferencesService.asCommittedOptions(
                      opt,
                      "participantpositions"
                    ),
                  })
                }
                onRenderTile={(
                  item: IParticipantPosition,
                  columnWidth: number,
                  selection: Selection,
                  _callback: (sel: Selection) => void
                ): JSX.Element => {
                  return (
                    <ParticipantPositionCard
                      {...this.props}
                      styles={subComponentStyles?.gridCard}
                      item={item}
                      columnWidth={columnWidth}
                      selection={selection}
                    />
                  );
                }}
                columns={[
                  {
                    key: "name",
                    name: "participants:positions:title",
                    minWidth: 190,
                  },
                  {
                    key: "requestNumber",
                    name: "participants:positions:requestnumber",
                    minWidth: 150,
                    onRenderCell: (item: IParticipantPosition) => {
                      return (
                        <Link to={`/demandes/${item.requestId}`}>
                          <Text>{item.requestNumber}</Text>
                        </Link>
                      );
                    },
                  },
                  {
                    key: "arrivalDate",
                    name: "participants:positions:arrivaldate",
                    minWidth: 150,
                  },
                  {
                    key: "departureDate",
                    name: "participants:positions:departuredate",
                    minWidth: 150,
                  },
                ]}
                onDataPaging={() => {
                  ParticipantPositionsService.getParticipantPositions(
                    objectId(this.props.enterprise),
                    this.props.participantId,
                    this.state.options.sortBy,
                    this.state.options.desc,
                    this.state.filters,
                    true
                  ).then((x) => {
                    this.setState({
                      items: x,
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                }}
                onApplyFilters={(
                  filters: IQueryFilter[],
                  callback: () => void
                ) => {
                  ParticipantPositionsService.init();
                  ParticipantPositionsService.getParticipantPositions(
                    objectId(this.props.enterprise),
                    this.props.participantId,
                    this.state.options.sortBy,
                    this.state.options.desc,
                    filters,
                    undefined
                  ).then((x) => {
                    callback();
                    this.setState({
                      items: x,
                      filters: PreferencesService.asCommittedFilters(
                        filters,
                        "participantpositions"
                      ),
                      options: GridViewOptionsBuilder.getFetched(this),
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                onQueryFilters={(_reset: boolean) => {
                  return new Promise<IQueryFilter[]>((resolve) => {
                    resolve(
                      PreferencesService.getFiltersOrDefault(
                        "participantpositions",
                        true,
                        () => {
                          return [
                            {
                              name: "participants:positions:title",
                              fieldName: "name",
                              type: "text",
                              value: "",
                            },
                            {
                              name: "participants:positions:requestnumber",
                              fieldName: "requestNumber",
                              type: "text",
                              value: "",
                            },
                            {
                              name: "participants:positions:arrivaldate",
                              fieldName: "arrivalDate",
                              type: "date",
                              value: null,
                            },
                            {
                              name: "participants:positions:departuredate",
                              fieldName: "departureDate",
                              type: "date",
                              value: null,
                            },
                          ];
                        }
                      )
                    );
                  });
                }}
                onSort={(fieldName: string, desc: boolean) => {
                  ParticipantPositionsService.init();
                  ParticipantPositionsService.getParticipantPositions(
                    objectId(this.props.enterprise),
                    this.props.participantId,
                    fieldName,
                    desc,
                    this.state.filters,
                    false
                  ).then((x) => {
                    this.setState({
                      options: PreferencesService.asCommittedOptions(
                        GridViewOptionsBuilder.getOptions(
                          this.state.options.mode,
                          fieldName,
                          desc,
                          true
                        ),
                        "participantpositions"
                      ),
                      items: x,
                    });
                  });
                  GridViewOptionsBuilder.unfetched(this);
                }}
                items={items}
              />
            </Card.Item>
          </Card>
        </Stack>
      </div>
    );
  }
}
