import {
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";

export interface ITransportOfferPageSubComponentStyles {}

export interface ITransportOfferPageStyles
  extends IAmigoBaseStyles<ITransportOfferPageSubComponentStyles> {}

export interface ITransportOfferPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getTransportOfferPageClassNames = (
  styles?: IStyleFunctionOrObject<
    ITransportOfferPageStyleProps,
    ITransportOfferPageStyles
  >,
  props?: ITransportOfferPageStyleProps
): [
  IProcessedStyleSet<ITransportOfferPageStyles>,
  ITransportOfferPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  ITransportOfferPageStyleProps,
  ITransportOfferPageStyles
> = (_props: ITransportOfferPageStyleProps): ITransportOfferPageStyles => {
  return {
    root: ["amigo-TransportOfferPage", {}],
  };
};
