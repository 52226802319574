import { ActivityItem, Icon, Link, List, Text } from "@fluentui/react";
import * as React from "react";
import { IEnterpriseDocument } from "src/models/Enterprises/IEnterpriseDocument";
import DocumentEnterpriseService from "src/services/Enterprises/DocumentEnterpriseService";
import i18n from "src/services/i18n";
import { getDocumentSummaryControlClassNames } from "./DocumentSummaryControl.styles";
import {
  IDocumentSummaryControlProps,
  IDocumentSummaryControlState,
} from "./DocumentSummaryControl.types";

export class DocumentSummaryControlComponent extends React.Component<
  IDocumentSummaryControlProps,
  IDocumentSummaryControlState
> {
  constructor(props: IDocumentSummaryControlProps) {
    super(props);
    this.state = {
      documents: [],
    };
    const id: number = parseInt(
      this.props.enterprise === undefined
        ? "-1"
        : this.props.enterprise.key?.toString() ?? "-1"
    );
    DocumentEnterpriseService.getDocumentsSummmary(id).then((x) => {
      this.setState({
        documents: x,
      });
    });
  }

  private onRenderDocument = (document?: IEnterpriseDocument): JSX.Element => {
    return document === undefined ? (
      <div></div>
    ) : (
      <ActivityItem
        activityIcon={<Icon iconName="PDF" />}
        key={1}
        activityDescription={
          <Link
            key={1}
            style={{ fontWeight: "bold" }}
            onClick={() => {
              DocumentEnterpriseService.downloadEnterpriseDocument(
                this.props.enterprise.key?.toString() ?? "-1",
                document
              );
            }}
          >
            {document.name}
          </Link>
        }
        comments={[
          <span key={"document:" + document.key + ":size"}>
            {document.size}.
          </span>,
        ]}
        timeStamp={document.createdOn}
      />
    );
  };

  render(): JSX.Element {
    const { styles } = this.props;
    const [classNames] = getDocumentSummaryControlClassNames(styles!, {
      ...this.props,
      ...this.state,
    });
    return (
      <div className={classNames.root}>
        <List
          items={this.state.documents}
          onRenderCell={this.onRenderDocument}
        />
        {this.state.documents.length === 0 && (
          <div className="clear-both">
            <Text
              styles={{ root: { marginLeft: "28px", fontStyle: "italic" } }}
            >
              {i18n.t("enterprises:documents:empty")}
            </Text>
          </div>
        )}
        {this.state.documents.length > 0 && (
          <div
            className="clear-both"
            style={{ position: "absolute", bottom: "0px" }}
          >
            <ActivityItem
              activityIcon={<Icon iconName="More" />}
              key={1}
              activityDescription={
                <Link
                  key={1}
                  style={{ fontWeight: "bold" }}
                  onClick={() => {
                    this.props.onNavigateDocuments();
                    if (this.props.mobile) {
                      window.scrollTo({
                        top: 0,
                        behavior: "smooth",
                      });
                    }
                  }}
                >
                  {i18n.t("enterprises:documents:more")}
                </Link>
              }
            />
          </div>
        )}
        <div style={{ height: "20px" }}></div>
      </div>
    );
  }
}
