import { styled } from "@fluentui/react/lib/Utilities";
import { IUserContactCardProps } from "./UserContactCard.types";
import {
  getStyles,
  IUserContactCardStyleProps,
  IUserContactCardStyles,
} from "./UserContactCard.styles";
import { UserContactCardComponent } from "./UserContactCard.base";

/**
 * UserContactCard description
 */
export const UserContactCard = styled<
  IUserContactCardProps,
  IUserContactCardStyleProps,
  IUserContactCardStyles
>(UserContactCardComponent, getStyles);
