import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IUserCalloutSubComponentStyles {}

export interface IUserCalloutStyles
  extends IAmigoBaseStyles<IUserCalloutSubComponentStyles> {}

export interface IUserCalloutStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getUserCalloutClassNames = (
  styles?: IStyleFunctionOrObject<IUserCalloutStyleProps, IUserCalloutStyles>,
  props?: IUserCalloutStyleProps
): [
  IProcessedStyleSet<IUserCalloutStyles>,
  IUserCalloutSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IUserCalloutStyleProps,
  IUserCalloutStyles
> = (_props: IUserCalloutStyleProps): IUserCalloutStyles => {
  return {};
};
