import axios from "axios";
import { IPaymentMethod } from "../../models/Enterprises/IPaymentMethod";
import { IChoiceValue, IQueryFilter } from "../../models/IQueryFilter";
import AuthService from "../AuthService";

class PaymentMethodsService {
  private _paymentMethods?: IPaymentMethod[] = undefined;
  private _paymentMethodsPages: number = 1;

  public init(): void {
    this._paymentMethods = undefined;
    this._paymentMethodsPages = 1;
  }

  private pageSize: number = 20;

  private translateSortBy = (field: string): string => {
    return field
      .replace("cardIcon", "TypeCarteCredit.Description")
      .replace("cardType", "TypeCarteCredit.Description")
      .replace("card", "NumeroCarte")
      .replace("name", "NomSurCarte")
      .replace("expirationMonth", "MoisExpiration")
      .replace("createdBy", "CreerPar")
      .replace("createdOn", "CreerLe");
  };

  private transformFilters = (filters: IQueryFilter[] | undefined): any[] => {
    return filters === undefined
      ? []
      : filters
          .filter((x) => {
            return (
              (x.type === "text" &&
                x.value !== undefined &&
                x.value != null &&
                x.value.toString().length > 0) ||
              (x.type === "choice" &&
                x.value !== undefined &&
                x.choices !== undefined &&
                x.choices.length > (x.value as IChoiceValue[]).length) ||
              (x.type === "date" && x.value !== undefined) ||
              (x.type === "null" &&
                x.value !== undefined &&
                (x.value as IChoiceValue).id === 0)
            );
          })
          .map((x) => {
            return {
              fieldName: this.translateSortBy(x.fieldName).replace(
                ".Description",
                ".Id"
              ),
              name: x.name,
              type: x.type,
              textValue: x.type === "text" ? x.value : null,
              choiceValue:
                x.type === "null"
                  ? [(x.value as IChoiceValue).id.toString()]
                  : x.type === "choice"
                  ? (x.value as IChoiceValue[]).map((z) => z.id.toString())
                  : null,
              dateValue: x.type === "date" ? x.value : null,
            };
          });
  };

  public deletePaymentMethods(
    enterpriseId: number,
    items: IPaymentMethod[]
  ): Promise<void> {
    return axios.delete(`api/enterprises/${enterpriseId}/payment-methods`, {
      headers: AuthService.getAuthHeader().headers,
      data: {
        elements: items.map((x) => x.id),
      },
    });
  }

  public async getPaymentMethods(
    enterpriseId: number,
    sortBy: string,
    desc: boolean,
    filters: IQueryFilter[] | undefined,
    next: boolean | undefined
  ): Promise<IPaymentMethod[]> {
    const _this = this;
    let currentPage = this._paymentMethodsPages;
    let combine: boolean;
    if (next && _this._paymentMethods !== undefined) {
      currentPage++;
      combine = true;
    } else {
      combine = false;
    }

    try {
      const x = await axios.post(
        `api/enterprises/${enterpriseId}/payment-methods`,
        {
          sortBy: this.translateSortBy(sortBy),
          descending: desc,
          pageIndex: currentPage,
          pageSize: this.pageSize,
          filters: this.transformFilters(filters),
        },
        AuthService.getAuthHeader()
      );
      const items = x.data as any;
      if (combine && _this._paymentMethods !== undefined) {
        _this._paymentMethodsPages = currentPage;
        _this._paymentMethods = _this._paymentMethods.concat(items);
      } else {
        _this._paymentMethodsPages = 1;
        _this._paymentMethods = this._paymentMethods = items;
      }

      if(_this._paymentMethods === undefined)
        throw new Error("FatalErrorFetchPaymentMethods");

      return _this._paymentMethods ?? [];

    } catch (ex) {
      console.log("error");
      throw ex;
    }
  }

  public async addPaymentMethod(
    enterpriseId: string,
    item: IPaymentMethod
  ): Promise<any> {
    const x = await axios.patch(
      `api/enterprises/${enterpriseId}/payment-methods`,
      item,
      AuthService.getAuthHeader()
    );
    return x.data;
  }
}

export default new PaymentMethodsService();
