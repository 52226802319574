import {
  DefaultPalette,
  MessageBar,
  MessageBarType,
  Stack,
  Text,
} from "@fluentui/react";
import { SelectionMode } from "@fluentui/react/lib/Utilities";
import * as React from "react";
import NumberFormat from "react-number-format";
import { GridView } from "src/components/GridView";
import { GridViewOptionsBuilder } from "src/components/GridView/GridView.imports";
import { GridViewMode } from "src/components/GridView/GridView.types";
import { IconHeader } from "src/components/IconHeader";
import { IPendingBill } from "src/models/Billing/IPendingBill";
import i18n from "src/services/i18n";
import { PendingBillPanel } from "../PendingBillPanel";
import { getNewPaymentBillsClassNames } from "./NewPaymentBills.styles";
import {
  INewPaymentBillsProps,
  INewPaymentBillsState,
} from "./NewPaymentBills.types";

export class NewPaymentBillsComponent extends React.Component<
  INewPaymentBillsProps,
  INewPaymentBillsState
> {
  constructor(props: INewPaymentBillsProps) {
    super(props);
    this.state = {};
  }

  render(): JSX.Element {
    const { styles, mobile, bills, errors } = this.props;
    const [classNames] = getNewPaymentBillsClassNames(styles!, {
      ...this.props,
      ...this.state,
    });

    const error: boolean =
      errors.payment &&
      (bills ?? []).length > 0 &&
      (bills ?? []).reduce((sum, current) => sum + current.payment, 0) <= 0;

    return (
      <div className={classNames.root}>
        <Stack
          horizontal={!mobile}
          verticalAlign={"center"}
          tokens={{ childrenGap: 10 }}
        >
          <div>
            <IconHeader
              {...this.props}
              styles={{
                root: [
                  "ms-IconHeader",
                  {
                    marginTop: "0",
                  },
                ],
              }}
              iconName={"Script"}
              title={"Factures"}
            />
          </div>
          {error ? (
            <MessageBar messageBarType={MessageBarType.blocked}>
              {(bills ?? []).length > 1
                ? i18n.t("billing:payments:newpayment:amountrequired:plural")
                : i18n.t("billing:payments:newpayment:amountrequired:singular")}
            </MessageBar>
          ) : (
            <div></div>
          )}
        </Stack>
        <GridView
          {...this.props}
          styles={undefined}
          selectionMode={SelectionMode.none}
          onItemInvoked={(item: IPendingBill) => {
            if (item.billingNumber !== -1) {
              this.setState({ selectedBill: item });
            }
          }}
          embedded={true}
          onDataPaging={() => {}}
          onRenderTile={undefined}
          options={GridViewOptionsBuilder.getOptions(
            GridViewMode.list,
            "createdOn",
            true,
            bills !== undefined
          )}
          items={
            bills === undefined
              ? []
              : bills.concat([
                  {
                    id: -1,
                    key: "-1",
                    billingDate: "",
                    billingNumber: -1,
                    status: "",
                    formattedTotal:
                      bills
                        .reduce((sum, current) => sum + current.total, 0)
                        .toFixed(2) + " $",
                    total: bills.reduce(
                      (sum, current) => sum + current.total,
                      0
                    ),
                    formattedPaid:
                      bills
                        .reduce((sum, current) => sum + current.paid, 0)
                        .toFixed(2) + " $",
                    paid: bills.reduce((sum, current) => sum + current.paid, 0),
                    balance: bills.reduce(
                      (sum, current) => sum + current.balance,
                      0
                    ),
                    formattedBalance:
                      bills
                        .reduce((sum, current) => sum + current.balance, 0)
                        .toFixed(2) + " $",
                    paymentsCount: bills.reduce(
                      (sum, current) => sum + current.paymentsCount,
                      0
                    ),
                    payment: bills.reduce(
                      (sum, current) => sum + (current.payment ?? 0),
                      0
                    ),
                    subtotal: bills.reduce(
                      (sum, current) => sum + current.subtotal,
                      0
                    ),
                    tps: bills.reduce((sum, current) => sum + current.tps, 0),
                    tvq: bills.reduce((sum, current) => sum + current.tvq, 0),
                    penalities: [],
                  },
                ])
          }
          columns={
            mobile
              ? [
                  {
                    key: "billingNumber",
                    name: "billing:payments:pendingbill:bill",
                    minWidth: 110,
                    onRenderCell: (item: IPendingBill) => {
                      return item.billingNumber === -1 ? (
                        <Text style={{ fontWeight: "bold" }}>
                          {!item.billingNumber || item.billingNumber === -1
                            ? ""
                            : item.billingNumber}
                        </Text>
                      ) : (
                        <Text
                          onClick={
                            item.billingNumber === -1
                              ? undefined
                              : () => this.setState({ selectedBill: item })
                          }
                          style={
                            item.billingNumber === -1
                              ? undefined
                              : { cursor: "pointer" }
                          }
                        >
                          {!item.billingNumber || item.billingNumber === -1
                            ? ""
                            : item.billingNumber.toString()}
                        </Text>
                      );
                    },
                  },
                  {
                    key: "key",
                    name: "billing:payments:pendingbill:payment",
                    minWidth: 120,
                    onRenderCell: (item: IPendingBill) => {
                      if (item.billingNumber === -1)
                        return <Text>{`${item.payment.toFixed(2)} $`}</Text>;
                      return (
                        <NumberFormat
                          placeholder={"0.00"}
                          style={
                            error && item.payment === 0
                              ? {
                                  border: "1px solid " + DefaultPalette.red,
                                  width: "110px",
                                }
                              : {
                                  width: "110px",
                                }
                          }
                          decimalSeparator="."
                          max={item.balance}
                          allowNegative={false}
                          allowLeadingZeros={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          suffix={undefined}
                          value={item.payment}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            if (floatValue === undefined || isNaN(floatValue)) {
                              item.payment = 0;
                            } else if (floatValue > item.balance) {
                              item.payment = item.balance;
                            } else {
                              item.payment = floatValue;
                            }
                            this.props.onUpdate(item);
                          }}
                        />
                      );
                    },
                  },
                  {
                    key: "formattedBalance",
                    name: "billing:payments:pendingbill:balance",
                    minWidth: 100,
                    onRenderCell: (item: IPendingBill) => {
                      return (
                        <Text
                          onClick={
                            item.billingNumber === -1
                              ? undefined
                              : () => this.setState({ selectedBill: item })
                          }
                          style={
                            item.billingNumber === -1
                              ? { fontWeight: "bold" }
                              : { cursor: "pointer" }
                          }
                        >
                          {`${item.balance.toFixed(2)} $`}
                        </Text>
                      );
                    },
                  },
                ]
              : [
                  {
                    key: "billingDate",
                    name: "billing:payments:pendingbill:date",
                    minWidth: 88,
                    onRenderCell: (item: IPendingBill) => {
                      return item.billingNumber === -1 ? (
                        <Text style={{ fontWeight: "bold" }}>Total</Text>
                      ) : (
                        <Text
                          onClick={() => this.setState({ selectedBill: item })}
                          style={{ cursor: "pointer" }}
                        >
                          {item.billingDate}
                        </Text>
                      );
                    },
                  },
                  {
                    key: "billingNumber",
                    name: "billing:payments:pendingbill:bill",
                    minWidth: 110,
                    onRenderCell: (item: IPendingBill) => {
                      return item.billingNumber === -1 ? (
                        <Text style={{ fontWeight: "bold" }}>
                          {!item.billingNumber || item.billingNumber === -1
                            ? ""
                            : item.billingNumber}
                        </Text>
                      ) : (
                        <Text
                          onClick={
                            item.billingNumber === -1
                              ? undefined
                              : () => this.setState({ selectedBill: item })
                          }
                          style={
                            item.billingNumber === -1
                              ? undefined
                              : { cursor: "pointer" }
                          }
                        >
                          {!item.billingNumber || item.billingNumber === -1
                            ? ""
                            : item.billingNumber.toString()}
                        </Text>
                      );
                    },
                  },
                  {
                    key: "formattedTotal",
                    name: "billing:payments:pendingbill:total",
                    minWidth: 80,
                  },
                  {
                    key: "formattedPaid",
                    name: "billing:payments:pendingbill:received",
                    minWidth: 100,
                  },
                  {
                    key: "key",
                    name: "billing:payments:pendingbill:payment",
                    minWidth: 120,
                    onRenderCell: (item: IPendingBill) => {
                      if (item.billingNumber === -1)
                        return <Text>{`${item.payment.toFixed(2)} $`}</Text>;
                      return (
                        <NumberFormat
                          placeholder={"0.00"}
                          style={
                            error && item.payment === 0
                              ? {
                                  border: "1px solid " + DefaultPalette.red,
                                  width: "110px",
                                }
                              : {
                                  width: "110px",
                                }
                          }
                          decimalSeparator="."
                          max={item.balance}
                          allowNegative={false}
                          allowLeadingZeros={false}
                          decimalScale={2}
                          fixedDecimalScale={true}
                          thousandSeparator={true}
                          suffix={undefined}
                          value={item.payment}
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            if (floatValue === undefined || isNaN(floatValue)) {
                              item.payment = 0;
                            } else if (floatValue > item.balance) {
                              item.payment = item.balance;
                            } else {
                              item.payment = floatValue;
                            }
                            this.props.onUpdate(item);
                          }}
                        />
                      );
                    },
                  },
                  {
                    key: "formattedBalance",
                    name: "billing:payments:pendingbill:balance",
                    minWidth: 100,
                    onRenderCell: (item: IPendingBill) => {
                      return (
                        <Text
                          onClick={
                            item.billingNumber === -1
                              ? undefined
                              : () => this.setState({ selectedBill: item })
                          }
                          style={
                            item.billingNumber === -1
                              ? { fontWeight: "bold" }
                              : { cursor: "pointer" }
                          }
                        >
                          {`${item.balance.toFixed(2)} $`}
                        </Text>
                      );
                    },
                  },
                  {
                    key: "key",
                    name: "billing:payments:pendingbill:newbalance",
                    minWidth: 100,
                    onRenderCell: (item: IPendingBill) => {
                      return (
                        <Text
                          onClick={
                            item.billingNumber === -1
                              ? undefined
                              : () => this.setState({ selectedBill: item })
                          }
                          style={
                            item.billingNumber === -1
                              ? { fontWeight: "bold" }
                              : { cursor: "pointer" }
                          }
                        >
                          {`${(item.balance - item.payment).toFixed(2)} $`}
                        </Text>
                      );
                    },
                  },
                ]
          }
          entityPanelProps={{
            ...this.props,
            styles: undefined,
            isOpen: this.state.selectedBill !== undefined,
            "data-automation-id": "nvx:itempanel:pendingbill",
            onDismiss: () => this.setState({ selectedBill: undefined }),
            elementName: i18n.t("billing:element"),
            title:
              this.state.selectedBill === undefined ||
              this.state.selectedBill.billingNumber === undefined ||
              this.state.selectedBill.billingNumber === null
                ? ""
                : this.state.selectedBill.billingNumber?.toString(),
            children:
              this.state.selectedBill === undefined ? (
                <div></div>
              ) : (
                <PendingBillPanel
                  {...this.props}
                  styles={undefined}
                  billingElement={this.state.selectedBill}
                />
              ),
          }}
        />
      </div>
    );
  }
}
