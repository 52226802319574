import {
  IStyleFunction,
  IStyleFunctionOrObject,
  IProcessedStyleSet,
} from "@fluentui/react";
import {
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
  getComponentClassNames,
} from "src/Base";

export interface IPaymentConfirmationPageSubComponentStyles {}

export interface IPaymentConfirmationPageStyles
  extends IAmigoBaseStyles<IPaymentConfirmationPageSubComponentStyles> {}

export interface IPaymentConfirmationPageStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {}

export const getPaymentConfirmationPageClassNames = (
  styles?: IStyleFunctionOrObject<
    IPaymentConfirmationPageStyleProps,
    IPaymentConfirmationPageStyles
  >,
  props?: IPaymentConfirmationPageStyleProps
): [
  IProcessedStyleSet<IPaymentConfirmationPageStyles>,
  IPaymentConfirmationPageSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IPaymentConfirmationPageStyleProps,
  IPaymentConfirmationPageStyles
> = (
  _props: IPaymentConfirmationPageStyleProps
): IPaymentConfirmationPageStyles => {
  return {
    root: [
      "amigo-PaymentConfirmationPage",
      {
        padding: "10px",
      },
    ],
  };
};
