import {
  IIconStyles,
  IProcessedStyleSet,
  IStyleFunction,
  IStyleFunctionOrObject,
} from "@fluentui/react";
import {
  getComponentClassNames,
  IAmigoBaseStyleProps,
  IAmigoBaseStyles,
  IAmigoTokens,
} from "src/Base";
import {
  IGridViewCardStyleProps,
  IGridViewCardStyles,
} from "src/components/GridViewCard";
import { MediaQueries } from "src/components/MediaQueries";

export interface IParticipantTransfertRequestParticipantCardSubComponentStyles {
  gridViewCard?: IStyleFunctionOrObject<
    IGridViewCardStyleProps,
    IGridViewCardStyles
  >;
  topIcon?: IIconStyles;
}

export interface IParticipantTransfertRequestParticipantCardStyles
  extends IAmigoBaseStyles<IParticipantTransfertRequestParticipantCardSubComponentStyles> {}

export interface IParticipantTransfertRequestParticipantCardStyleProps
  extends IAmigoBaseStyleProps<IAmigoTokens> {
  hasErrors: boolean;
}

export const getParticipantTransfertRequestParticipantCardClassNames = (
  styles?: IStyleFunctionOrObject<
    IParticipantTransfertRequestParticipantCardStyleProps,
    IParticipantTransfertRequestParticipantCardStyles
  >,
  props?: IParticipantTransfertRequestParticipantCardStyleProps
): [
  IProcessedStyleSet<IParticipantTransfertRequestParticipantCardStyles>,
  IParticipantTransfertRequestParticipantCardSubComponentStyles | undefined
] => {
  return getComponentClassNames(styles, props);
};

export const getStyles: IStyleFunction<
  IParticipantTransfertRequestParticipantCardStyleProps,
  IParticipantTransfertRequestParticipantCardStyles
> = (
  props: IParticipantTransfertRequestParticipantCardStyleProps
): IParticipantTransfertRequestParticipantCardStyles => {
  return {
    root: ["amigo-ParticipantTransfertRequestParticipantCard", {}],
    subComponentStyles: {
      topIcon: props.hasErrors
        ? {
            root: {
              color: props.theme.palette.redDark,
            },
          }
        : undefined,
      gridViewCard: {
        root: {
          width: "95%",
          [MediaQueries.mobile]: {
            width: "95%",
          },
          [MediaQueries.small]: {
            width: "97%",
          },
        },
      },
    },
  };
};
