import {
  DefaultPalette,
  Icon,
  Selection,
  SelectionMode,
  Spinner,
  Stack,
  Text,
  Toggle,
} from "@fluentui/react";
import * as React from "react";
import { GridView } from "src/components/GridView";
import { GridViewCommandBar } from "src/components/GridView/GridViewCommandBar";
import { objectId } from "src/models/IObjectReference";
import i18n from "src/services/i18n";
import RequestsService from "src/services/Requests/RequestsService";
import { RequestsParticipantsCard } from "../RequestsParticipantsCard/RequestsParticipantsCard";
import { TravelStatusIcon } from "../TravelStatusIcon";
import { getRequestPeriodParticipantsControlClassNames } from "./RequestPeriodParticipantsControl.styles";
import {
  IRequestPeriodParticipantsControlProps,
  IRequestPeriodParticipantsControlState,
} from "./RequestPeriodParticipantsControl.types";

export class RequestPeriodParticipantsControlComponent extends React.Component<
  IRequestPeriodParticipantsControlProps,
  IRequestPeriodParticipantsControlState
> {
  private _selection: Selection;
  constructor(props: IRequestPeriodParticipantsControlProps) {
    super(props);
    this._selection = new Selection();
    this.state = {
      view: 1,
      withHistory: false,
    };

    this._selection.setItems(this.props.participants);
  }

  /*
  private onRenderHeader(props?: IGroupHeaderProps) {
    const headerCountStyle = { display: "none" };
    const checkButtonStyle = { display: "none" };

    const onToggleSelectGroup = () => {
      props?.group !== undefined && props?.onToggleCollapse?.(props?.group);
    };

    return (
      <GroupHeader
        styles={{
          root: {
            whiteSpace: "break-spaces!important",
            span: {
              whiteSpace: "break-spaces!important",
            },
          },
          check: checkButtonStyle,
          headerCount: headerCountStyle,
        }}
        {...props}
        onToggleSelectGroup={onToggleSelectGroup}
      />
    );
  }
  */

  /*
  private getReplacement = (
    items: IPeriodParticipant[],
    item: IPeriodParticipant
  ) => {
    return `${
      items.filter(
        (r) =>
          r.poste !== undefined && r.poste === item.poste && item.key !== r.key
      )[0]?.firstName
    } ${
      items.filter(
        (r) =>
          r.poste !== undefined && r.poste === item.poste && item.key !== r.key
      )[0]?.lastName
    }`;
  };
  */

  /*
  private toGroups = (items: IPeriodParticipant[], view: number): IGroup[] => {
    if (items.length === 0) return [];

    const groups =
      view === 1
        ? items.reduce((catsSoFar, { arrivalDate, departureDate, key }) => {
            const now = new Date();
            const groupDate =
              i18n.parseISOLocal(departureDate) > now
                ? i18n.t("requests:periods:departurefrom") + departureDate
                : i18n.parseISOLocal(arrivalDate) > now
                ? i18n.t("requests:periods:arrivalfrom") + " " + arrivalDate
                : i18n.t("requests:periods:completed");
            if (!catsSoFar[groupDate]) catsSoFar[groupDate] = [];
            catsSoFar[groupDate].push(key);
            return catsSoFar;
          }, {})
        : items.reduce((catsSoFar, { period, key }) => {
            const fromto: string =
              period === undefined
                ? ""
                : i18n.formatString(
                    i18n.t("requests:fromto:eimt"),
                    i18n.parseISOLocalString(period.startDate),
                    i18n.parseISOLocalString(period.endDate),
                    period.EIMT
                  );
            if (!catsSoFar[fromto]) catsSoFar[fromto] = [];
            catsSoFar[fromto].push(key);
            return catsSoFar;
          }, {});

    const results: IGroup[] = [];
    let i = 0;
    let len = 0;
    for (const e in groups) {
      const count = groups[e].length;
      results.push({
        key: "group" + i++,
        name: e,
        startIndex: len,
        level: 0,
        count: count,
      });
      len += count;
    }
    return results;
  };
  */

  render(): JSX.Element {
    const { styles, onSort } = this.props;
    const [classNames, subComponentStyles] =
      getRequestPeriodParticipantsControlClassNames(styles!, {
        ...this.props,
        ...this.state,
      });
    const { options, onToggleGrid } = this.props;
    // const withTiles: boolean = options.mode === GridViewMode.tiles;
    const _this = this;

    const items = this.props.participants?.filter(
      (x) => !x.cancelled && (x.enabled || this.state.withHistory)
    );

    let currentP: number | null = null;
    items.forEach((x) => {
      if (currentP === null || x.poste !== currentP) {
        currentP = x.poste ?? null;
      } else {
        x.title = "";
      }
    });
    const cols = [
      {
        key: "title",
        name: "requests:periods:participants:title",
        fieldName: "title",
        minWidth: 180,
        maxWidth: 220,
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("title")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.title}
            </Text>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onPositionSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.title}
            </Text>
          ),
      },
      {
        key: "participant",
        name: "requests:periods:participants:name",
        fieldName: "firstName",
        minWidth: 280,
        maxWidth: 280,
        styles: {
          root: {
            width: "380px",
          },
          cellName: {
            width: "380px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.firstName}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >{`${item.lastName.toUpperCase()}, ${item.firstName}`}</Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        marginTop: "10px!important",
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >{`${x.lastName.toUpperCase()}, ${x.firstName}`}</Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >{`${item.lastName.toUpperCase()}, ${item.firstName}`}</Text>
          ),
      },
      {
        key: "participantNumber",
        name: "requests:periods:participants:number",
        fieldName: "participantNumber",
        minWidth: 60,
        maxWidth: 60,
        styles: {
          root: {
            width: "60px",
          },
          cellName: {
            width: "60px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("participantNumber")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.participantNumber}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.participantNumber}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        marginTop: "10px!important",
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {item.participantNumber}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.participantNumber}
            </Text>
          ),
      },
      {
        key: "type",
        name: "requests:periods:participants:type",
        fieldName: "participantType",
        minWidth: 145,
        maxWidth: 145,
        styles: {
          root: {
            width: "145px",
          },
          cellName: {
            width: "145px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("participantType")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.participantType}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.participantType}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        marginTop: "10px!important",
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {x.participantType}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.participantType}
            </Text>
          ),
      },
      {
        key: "arrivalStatus",
        name: i18n.t("requests:periods:grouping:arrivalstatus"),
        fieldName: "arrivalStatus",
        minWidth: 100,
        maxWidth: 100,
        styles: {
          root: {
            width: "100px",
          },
          cellName: {
            width: "100px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("arrivalStatus")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "rgb(96, 94, 92)",
                },
              }}
            >
              {item.arrivalStatus}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <div style={{ width: "100px" }}>
                <TravelStatusIcon
                  {...this.props}
                  styles={undefined}
                  participant={item}
                  arrival={true}
                  status={item?.arrivalStatus ?? 0}
                />
              </div>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <div style={{ width: "100px" }}>
                    <TravelStatusIcon
                      {...this.props}
                      styles={undefined}
                      key={index.toString()}
                      participant={x}
                      arrival={true}
                      status={x?.arrivalStatus ?? 0}
                    />
                  </div>
                ))}
            </Stack>
          ) : (
            <div style={{ width: "100px" }}>
              <TravelStatusIcon
                {...this.props}
                styles={undefined}
                participant={item}
                arrival={true}
                status={item?.arrivalStatus ?? 0}
              />
            </div>
          ),
      },
      {
        key: "arrivalDate",
        name: i18n.t("requests:periods:grouping:arrivaldate"),
        fieldName: "arrivalDate",
        minWidth: 160,
        maxWidth: 160,
        styles: {
          root: {
            width: "160px",
          },
          cellName: {
            width: "160px",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("arrivalDate")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#333333",
                },
              }}
            >
              {item.arrivalDate}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.arrivalDate}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        marginTop: "10px!important",
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {x.arrivalDate}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  color: item.enabled ? "#333333" : DefaultPalette.themePrimary,
                  cursor: "pointer",
                },
              }}
            >
              {item.arrivalDate}
            </Text>
          ),
      },
      {
        key: "departureStatus",
        name: i18n.t("requests:periods:grouping:departurestatus"),
        fieldName: "departureStatus",
        minWidth: 120,
        maxWidth: 120,
        styles: {
          root: {
            width: "120px",
          },
          cellName: {
            width: "120px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("departureStatus")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                  color: "#333333",
                },
              }}
            >
              {item.departureStatus}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <div style={{ width: "100px" }}>
                <TravelStatusIcon
                  {...this.props}
                  styles={undefined}
                  participant={item}
                  arrival={false}
                  status={item?.departureStatus ?? 0}
                />
              </div>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <div style={{ width: "100px" }}>
                    <TravelStatusIcon
                      {...this.props}
                      styles={undefined}
                      participant={x}
                      key={index.toString()}
                      arrival={false}
                      status={x?.departureStatus ?? 0}
                    />
                  </div>
                ))}
            </Stack>
          ) : (
            <div style={{ width: "100px" }}>
              <TravelStatusIcon
                {...this.props}
                styles={undefined}
                participant={item}
                arrival={false}
                status={item?.departureStatus ?? 0}
              />
            </div>
          ),
      },
      {
        key: "departureDate",
        name: i18n.t("requests:periods:grouping:departuredate"),
        fieldName: "departureDate",
        minWidth: 200,
        maxWidth: 200,
        styles: {
          root: {
            width: "200px",
          },
          cellName: {
            width: "200px!important",
          },
        },
        onRenderCell: (item: any) =>
          item.isHeader ? (
            <Text
              onClick={() => onSort("departureDate")}
              styles={{
                root: {
                  cursor: "pointer",
                  fontSize: "12px",
                  fontWeight: 600,
                },
              }}
            >
              {item.departureDate}
            </Text>
          ) : this.state.withHistory ? (
            <Stack tokens={{ childrenGap: 5 }}>
              <Text
                onClick={() => {
                  if (item) {
                    _this.props.onParticipantSelected(item);
                  }
                }}
                styles={{
                  root: {
                    color: item.enabled
                      ? "#333333"
                      : DefaultPalette.themePrimary,
                    cursor: "pointer",
                  },
                }}
              >
                {item.departureDate}
              </Text>
              {item.history !== undefined &&
                item.history.map((x: any, index: number) => (
                  <Text
                    key={index.toString()}
                    styles={{
                      root: {
                        marginTop: "10px!important",
                        color: x.enabled
                          ? "#333333"
                          : DefaultPalette.themePrimary,
                        cursor: "pointer",
                      },
                    }}
                  >
                    {x.departureDate}
                  </Text>
                ))}
            </Stack>
          ) : (
            <Text
              onClick={() => {
                if (item) {
                  _this.props.onParticipantSelected(item);
                }
              }}
              styles={{
                root: {
                  cursor: "pointer",
                  color: "#333333",
                },
              }}
            >
              {item.departureDate}
            </Text>
          ),
      },
    ];
    return (
      <div className={classNames.root}>
        {/* <div className="clear-both" style={{ height: "50px" }}></div> */}
        <GridViewCommandBar
          {...this.props}
          menucollapsed={true}
          hasSelection={false}
          fullTextEnabled={false}
          columns={cols}
          commands={[
            {
              key: "export",
              name: "Exporter",
              icon: "ExcelDocument",
              selectionRequired: false,
              onClick: () => {
                RequestsService.exportRequestPeriod(
                  objectId(this.props.enterprise),
                  this.props.requestId,
                  this.props.periodId,
                  this.props.options.sortBy,
                  this.props.options.desc
                );
              },
            },
            {
              key: "history",
              name: i18n.t(
                this.state.withHistory
                  ? "requests:periods:grouping:withhistory"
                  : "requests:periods:grouping:withouthistory"
              ),
              icon: "FullHistory",
              selectionRequired: false,
              onRenderContent: () => (
                <Stack
                  horizontal
                  verticalAlign="center"
                  tokens={{ childrenGap: this.props.mobile ? 4 : 10 }}
                >
                  {!this.props.mobile && (
                    <Icon
                      iconName="FullHistory"
                      styles={{
                        root: {
                          fontSize: "18px",
                          marginLeft: "4px",
                          color: this.props.theme.palette.themePrimary,
                        },
                      }}
                    />
                  )}
                  <Toggle
                    aria-readonly
                    onChange={() => {
                      this.setState({
                        withHistory: !this.state.withHistory,
                      });
                    }}
                    checked={this.state.withHistory}
                    styles={{
                      root: {
                        height: "16px!important",
                        width: "42px!important",
                        marginTop: "4px",
                      },
                    }}
                  />
                  <Text>
                    {i18n.t(
                      this.state.withHistory
                        ? "requests:periods:grouping:withhistory"
                        : "requests:periods:grouping:withouthistory"
                    )}
                  </Text>
                </Stack>
              ),
            },
          ]}
          options={{
            ...options,
            fetched: true,
          }}
          onToggleGrid={onToggleGrid}
          onShowDialog={() => {}}
        />

        {items === undefined && (
          <Stack
            styles={{
              root: {
                margin: "40px",
                ".ms-Spinner-label": {
                  fontWeight: 600,
                },
              },
            }}
          >
            <Spinner label={i18n.t("global:loading")} />
          </Stack>
        )}
        {items !== undefined && (
          <GridView
            {...this.props}
            onSort={undefined}
            embedded
            styles={undefined}
            items={items}
            columns={cols}
            onRenderTile={(item) => (
              <>
                {item !== undefined ? (
                  <RequestsParticipantsCard
                    key={item.id}
                    title={item.title}
                    {...this.props}
                    onClick={() => {
                      if (item) {
                        _this.props.onParticipantSelected(item);
                      }
                    }}
                    styles={subComponentStyles?.gridCard}
                    columnWidth={200}
                    item={item}
                  />
                ) : (
                  <div></div>
                )}
              </>
            )}
            selectionMode={SelectionMode.none}
            fullTextEnabled={false}
            options={{
              ...options,
              fetched: true,
            }}
            onDataPaging={() => {}}
          />
        )}
        <div className="clear-both" style={{ height: "30px" }}></div>
      </div>
    );
  }
}
