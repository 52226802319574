import { styled } from "@fluentui/react/lib/Utilities";
import { IFilterPaneProps } from "./FilterPane.types";
import {
  getStyles,
  IFilterPaneStyleProps,
  IFilterPaneStyles,
} from "./FilterPane.styles";
import { FilterPaneComponent } from "./FilterPane.base";

/**
 * FilterPane description
 */
export const FilterPane = styled<
  IFilterPaneProps,
  IFilterPaneStyleProps,
  IFilterPaneStyles
>(FilterPaneComponent, getStyles);
