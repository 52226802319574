import { styled } from "@fluentui/react/lib/Utilities";
import { INavHeaderProps } from "./NavHeader.types";
import {
  getStyles,
  INavHeaderStyleProps,
  INavHeaderStyles,
} from "./NavHeader.styles";
import { NavHeaderComponent } from "./NavHeader.base";

export const NavHeader = styled<
  INavHeaderProps,
  INavHeaderStyleProps,
  INavHeaderStyles
>(NavHeaderComponent, getStyles);
